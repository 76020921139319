import React from 'react';
import { Chip } from '@material-ui/core';
const ChipAdq = props => {
	return (
		<>
			{props.adq &&
				props.adq.map(item =>
					item === 8 ? (
						<Chip
							label="ZOOP"
							style={{
								backgroundColor: '#FF6C0E',
								color: 'white',
								fontSize: 10,
								textTransform: 'uppercase',
								fontWeight: 700,
							}}
						/>
					) : (
						<Chip
							label="ADIQ"
							style={{
								backgroundColor: '#00DEB2',
								color: 'white',
								fontSize: 10,
								textTransform: 'uppercase',
								fontWeight: 700,
								margin: 5,
							}}
						/>
					)
				)}
		</>
	);
};

export default ChipAdq;
