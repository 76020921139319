import React from 'react';
import { Divider, Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
// import RecentActors from '@material-ui/icons/RecentActors';
import { StoreMallDirectory, Loop, Receipt, CompareArrows, RecordVoiceOver, TrendingUp } from '@material-ui/icons';
import Dashboard from '@material-ui/icons/Dashboard';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import AccountBox from '@material-ui/icons/AccountBox';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Replay from '@material-ui/icons/Replay';
import { useSelector } from 'react-redux';
import NovaTransferencia from '../Transferencias/NovaTransferencia';
import { FaChartLine, FaFileInvoiceDollar, FaClipboard, FaDollarSign, FaReceipt, FaFunnelDollar } from 'react-icons/fa';
import { Isset } from '../../utils';

const useStyles = makeStyles(theme => ({
	menu: {
		'& .MuiListItemIcon-root': {
			minWidth: 40,
		},
		'& .MuiTypography-body1': {
			fontSize: 14,
			fontWeight: 600,
		},
	},
	rotateIcon: {
		'& .MuiListItemIcon-root .MuiSvgIcon-root': {
			transition: theme.transitions.create(['all'], {
				duration: theme.transitions.duration.complex,
			}),
		},
		'&:hover .MuiListItemIcon-root .MuiSvgIcon-root': {
			transform: 'rotate(180deg)',
		},
	},
	collapseListItem: {
		paddingLeft: 36,
		'& .MuiListItemIcon-root': {
			minWidth: 30,
		},
	},
	linkStyle: {
		color: 'black',
		textDecoration: 'none',
	},
}));

const Menu = props => {
	const usuario = useSelector(store => store.auth.usuario);
	const classes = useStyles();

	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);

	const linkPagamento = useSelector(store =>
		Isset(() => store.system.params['11'].valor) ? store.system.params['11'].valor : null
	);

	const sellingCampaign = useSelector(store =>
		Isset(() => store.system.params['26'].valor) ? store.system.params['26'].valor : null
	);

	const operaAssinaturas = useSelector(store =>
		Isset(() => store.system.params['28'].valor) ? store.system.params['28'].valor : null
	);

	// const recorrencia = useSelector(store =>
	// 	Isset(() => store.system.params['13'].valor) ? store.system.params['13'].valor : null
	// );

	const usuarios = useSelector(store =>
		Isset(() => store.system.params['15'].valor) ? store.system.params['15'].valor : null
	);

	const [openTransferencia, setOpenTransferencia] = React.useState(false);
	const [open, setOpen] = React.useState({
		estabelecimento: false,
		recorrencia: false,
		transferencia: false,
		vendaDigitada: false,
	});

	const canView = {
		extrato: [1, 2, 3, 4, 10].includes(usuario.grupo_id),
		estabelecimentos: [1, 2, 4, 5, 7, 10].includes(usuario.grupo_id),
		assinaturas: [1, 2, 3, 4, 10].includes(usuario.grupo_id), // && recorrencia,
		usuarios: [1, 10].includes(usuario.grupo_id) || (usuarios && usuario.grupo_id === 2),
		clientes: [1, 2, 7, 10].includes(usuario.grupo_id),
		invoices: [1, 7, 10].includes(usuario.grupo_id),
		sellingCampaign: [1, 2, 3, 4, 10].includes(usuario.grupo_id) && sellingCampaign,
		linkPagamento: [1, 2, 3, 4, 10, 11].includes(usuario.grupo_id) && linkPagamento,
		vendas: [1, 2, 3, 4, 5, 7, 10, 11].includes(usuario.grupo_id),
		transferencias: [1, 2, 5, 7, 10].includes(usuario.grupo_id),
		planoVendas: [1, 5, 7, 10].includes(usuario.grupo_id),
		relatorios: [1, 2, 4, 5, 7, 10].includes(usuario.grupo_id),
		comissoesRepresentante: [1, 10].includes(+usuario.grupo_id) || (+usuario.grupo_id === 2 && operarMarketplace),
		// antecipacoes: [1].includes(usuario.grupo_id),
		recebiveis: [1, 10].includes(usuario.grupo_id),
		conciliacao: [1, 2, 3, 4, 5, 7, 10].includes(usuario.grupo_id),
	};

	function handleClick(value) {
		setOpen({
			...open,
			[value]: !open[value],
		});
	}

	return (
		<div className={classes.menu}>
			<Divider />
			<List>
				<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/">
					<ListItem button key={5}>
						<ListItemIcon>
							<Dashboard />
						</ListItemIcon>
						<ListItemText primary={'Dashboard'} />
					</ListItem>
				</Link>

				{canView.recebiveis && (
					//{canView.vendas && (
					<>
						<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/vendas/indicadores">
							<ListItem button key={4}>
								<ListItemIcon>
									<TrendingUp />
								</ListItemIcon>
								<ListItemText primary={'Indicadores de Vendas'} />
							</ListItem>
						</Link>
					</>
				)}

				{canView.extrato && (
					<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/extrato">
						<ListItem button key={5}>
							<ListItemIcon>
								<FaFileInvoiceDollar />
							</ListItemIcon>
							<ListItemText primary={'Extrato'} />
						</ListItem>
					</Link>
				)}

				{/* <Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/clientes">
					<ListItem button key={3}>
						<ListItemIcon>
							<RecentActors />
						</ListItemIcon>
						<ListItemText primary={'Clientes'} />
					</ListItem>
				</Link> */}
				{canView.estabelecimentos && operarMarketplace && (
					<>
						{[1, 7, 10].includes(usuario.grupo_id) ? (
							<React.Fragment>
								<ListItem button key={2} onClick={() => handleClick('estabelecimento')}>
									<ListItemIcon>
										<StoreMallDirectory />
									</ListItemIcon>
									<ListItemText primary={'Estabelecimentos'} />
									{open.estabelecimento ? <ExpandLess /> : <ExpandMore />}
								</ListItem>

								<Collapse in={open.estabelecimento} timeout="auto" unmountOnExit>
									<Link
										onClick={() => props.closeMenu()}
										className={classes.linkStyle}
										to="/estabelecimentos"
									>
										<List component="div" disablePadding>
											<ListItem button className={classes.collapseListItem}>
												<ListItemIcon>
													<KeyboardArrowRight />
												</ListItemIcon>
												<ListItemText primary="Todos" />
											</ListItem>
										</List>
									</Link>
									<Link
										onClick={() => props.closeMenu()}
										className={classes.linkStyle}
										to="/estabelecimentos/listar-pre-cadastro"
									>
										<List component="div" disablePadding>
											<ListItem button className={classes.collapseListItem}>
												<ListItemIcon>
													<KeyboardArrowRight />
												</ListItemIcon>
												<ListItemText primary="Pré Cadastro" />
											</ListItem>
										</List>
									</Link>
								</Collapse>
							</React.Fragment>
						) : (
							<Link
								onClick={() => props.closeMenu()}
								className={classes.linkStyle}
								to="/estabelecimentos"
							>
								<List component="div" disablePadding>
									<ListItem button>
										<ListItemIcon>
											<StoreMallDirectory />
										</ListItemIcon>
										<ListItemText primary="Estabelecimentos" />
									</ListItem>
								</List>
							</Link>
						)}
					</>
				)}

				{operarMarketplace && [1, 2, 7].includes(usuario.grupo_id) && (
					<React.Fragment>
						<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/cobranca-recorrente">
							<ListItem button className={classes.rotateIcon} key={2}>
								<ListItemIcon>
									<Loop />
								</ListItemIcon>
								<ListItemText primary={'Taxas Recorrentes'} />
							</ListItem>
						</Link>
					</React.Fragment>
				)}
				{canView.assinaturas && operaAssinaturas && (
					<React.Fragment>
						<ListItem button key={2} onClick={() => handleClick('recorrencia')}>
							<ListItemIcon>
								<Replay />
							</ListItemIcon>
							<ListItemText primary={'Assinaturas'} />
							{open.recorrencia ? <ExpandLess /> : <ExpandMore />}
						</ListItem>

						<Collapse in={open.recorrencia} timeout="auto" unmountOnExit>
							<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/planos">
								<List component="div" disablePadding>
									<ListItem button className={classes.collapseListItem}>
										<ListItemIcon>
											<KeyboardArrowRight />
										</ListItemIcon>
										<ListItemText primary="Planos" />
									</ListItem>
								</List>
							</Link>
							<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/assinaturas">
								<List component="div" disablePadding>
									<ListItem button className={classes.collapseListItem}>
										<ListItemIcon>
											<KeyboardArrowRight />
										</ListItemIcon>
										<ListItemText primary="Assinantes" />
									</ListItem>
								</List>
							</Link>
						</Collapse>
					</React.Fragment>
				)}

				{canView.usuarios && (
					<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/usuarios">
						<ListItem button key={1}>
							<ListItemIcon>
								<AccountBox />
							</ListItemIcon>
							<ListItemText primary={'Usuários'} />
						</ListItem>
					</Link>
				)}

				{canView.invoices && (
					<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/invoices">
						<ListItem button key={1}>
							<ListItemIcon>
								<Receipt />
							</ListItemIcon>
							<ListItemText primary={'Cobranças'} />
						</ListItem>
					</Link>
				)}

				{canView.conciliacao && (
					<React.Fragment>
						<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/conciliacao">
							<ListItem button key={1}>
								<ListItemIcon>
									<CompareArrows />
								</ListItemIcon>
								<ListItemText primary={'Recebíveis'} />
							</ListItem>
						</Link>
					</React.Fragment>
				)}

				{canView.clientes && (
					<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/clientes">
						<ListItem button key={1}>
							<ListItemIcon>
								<AccountBox />
							</ListItemIcon>
							<ListItemText primary={'Clientes'} />
						</ListItem>
					</Link>
				)}

				{canView.linkPagamento && (
					<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/venda-digitada/todas">
						<ListItem button key={2}>
							<ListItemIcon>
								<StoreMallDirectory />
							</ListItemIcon>
							<ListItemText primary={'Link de Pagamento'} />
							{/* {open.vendaDigitada ? <ExpandLess /> : <ExpandMore />} */}
						</ListItem>
					</Link>
				)}

				{canView.sellingCampaign && (
					<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/sellingCampaign">
						<ListItem button key={1}>
							<ListItemIcon>
								<RecordVoiceOver />
							</ListItemIcon>
							<ListItemText primary={'Campanhas'} />
						</ListItem>
					</Link>
				)}

				{canView.vendas && (
					<>
						{/* <Collapse in={open.vendaDigitada} timeout="auto" unmountOnExit>
								<Link
									onClick={() => props.closeMenu()}
									className={classes.linkStyle}
									to="/venda-digitada"
								>
									<List component="div" disablePadding>
										<ListItem button className={classes.collapseListItem}>
											<ListItemIcon>
												<KeyboardArrowRight />
											</ListItemIcon>
											<ListItemText primary="Gerar Link de Pagamento" />
										</ListItem>
									</List>
								</Link>
								<Link
									onClick={() => props.closeMenu()}
									className={classes.linkStyle}
									to="/venda-digitada/todas"
								>
									<List component="div" disablePadding>
										<ListItem button className={classes.collapseListItem}>
											<ListItemIcon>
												<KeyboardArrowRight />
											</ListItemIcon>
											<ListItemText primary="Listar todas" />
										</ListItem>
									</List>
								</Link>
							</Collapse> */}

						<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/vendas">
							<ListItem button key={4} className={classes.rotateIcon}>
								<ListItemIcon>
									<MonetizationOn />
								</ListItemIcon>
								<ListItemText primary={'Vendas'} />
							</ListItem>
						</Link>
					</>
				)}

				{canView.transferencias && (
					<React.Fragment>
						<ListItem button key={2} onClick={() => handleClick('transferencia')}>
							<ListItemIcon>
								<StoreMallDirectory />
							</ListItemIcon>
							<ListItemText primary={'Transferências'} />
							{open.transferencia ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={open.transferencia} timeout="auto" unmountOnExit>
							<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/transferencias">
								<List component="div" disablePadding>
									<ListItem button className={classes.collapseListItem}>
										<ListItemIcon>
											<KeyboardArrowRight />
										</ListItemIcon>
										<ListItemText primary="Todas" />
									</ListItem>
								</List>
							</Link>
							{![5, 7, 10].includes(usuario.grupo_id) && (
								<span
									onClick={() => {
										setOpenTransferencia(true);
										props.closeMenu();
									}}
									className={classes.linkStyle}
								>
									<List component="div" disablePadding>
										<ListItem button className={classes.collapseListItem}>
											<ListItemIcon>
												<KeyboardArrowRight />
											</ListItemIcon>
											<ListItemText primary="Criar Transferência" />
										</ListItem>
									</List>
								</span>
							)}

							<Link
								onClick={() => props.closeMenu()}
								className={classes.linkStyle}
								to="/transferencias/agendadas"
							>
								<List component="div" disablePadding>
									<ListItem button className={classes.collapseListItem}>
										<ListItemIcon>
											<KeyboardArrowRight />
										</ListItemIcon>
										<ListItemText primary="Agendadas" />
									</ListItem>
								</List>
							</Link>
						</Collapse>
					</React.Fragment>
				)}

				{canView.planoVendas && (
					<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/planos-de-vendas">
						<ListItem button key={1}>
							<ListItemIcon>
								<FaClipboard />
							</ListItemIcon>
							<ListItemText primary={'Planos de Vendas'} />
						</ListItem>
					</Link>
				)}

				{canView.comissoesRepresentante && (
					<Link
						onClick={() => props.closeMenu()}
						className={classes.linkStyle}
						to="/representantes/comissoes"
					>
						<ListItem button key={1}>
							<ListItemIcon>
								<FaDollarSign />
							</ListItemIcon>
							<ListItemText primary={'Comissões Representantes'} />
						</ListItem>
					</Link>
				)}

				{canView.relatorios && (
					<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/relatorios">
						<ListItem button key={1}>
							<ListItemIcon>
								<FaChartLine />
							</ListItemIcon>
							<ListItemText primary={'Relatórios'} />
						</ListItem>
					</Link>
				)}

				{canView.antecipacoes && (
					<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/antecipacoes">
						<ListItem button key={1}>
							<ListItemIcon>
								<FaReceipt />
							</ListItemIcon>
							<ListItemText primary={'Antecipações'} />
						</ListItem>
					</Link>
				)}

				{canView.recebiveis && (
					<Link onClick={() => props.closeMenu()} className={classes.linkStyle} to="/recebiveis">
						<ListItem button key={1}>
							<ListItemIcon>
								<FaFunnelDollar />
							</ListItemIcon>
							<ListItemText primary={'Pagamentos'} />
						</ListItem>
					</Link>
				)}
			</List>
			<Divider />

			{openTransferencia && <NovaTransferencia open={true} onClose={() => setOpenTransferencia(false)} />}
		</div>
	);
};

export default Menu;
