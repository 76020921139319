/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import clsx from 'clsx';

import { Isset } from '../../../utils';

import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, Chip } from '@material-ui/core';
import { FaTimes } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
	filtros: {
		position: 'relative',
		display: 'inline-flex',
	},
	filtroText: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		padding: '5px 10px',
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 30,
		marginLeft: 10,
		fontWeight: 700,
		fontSize: 12,
		color: theme.palette.primary.main,
	},
	filtroTextSelectedMultiple: {
		backgroundColor: `${theme.palette.primary.main}`,
		color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		'& .MuiSelect-select': {
			color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		},
		padding: '2px 10px',
		minHeight: 29,
		maxWidth: 340,
	},
	clearBtn: {
		marginLeft: 6,
	},
	filtroSelect: {
		fontWeight: 700,
		fontSize: 12,
		'&:before': {
			borderBottom: 'none !important',
		},
		'&:after': {
			borderBottom: 'none !important',
		},
		'& .MuiSelect-select': {
			padding: '2px 10px !important',
			marginRight: 10,
		},
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
		height: 16,
		maxWidth: 130,
		justifyContent: 'flex-start',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'& .MuiChip-label': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: 'inline-block',
		},
	},
	menuItem: {
		flexDirection: 'column',
	},
}));

const statusPreCadastros = [
	{ id: '0', name: 'Aguardando' },
	{ id: '1', name: 'Reprovado' },
	{ id: '2', name: 'Aprovado' },
];

const FiltroStatusPreCadastro = props => {
	const classes = useStyles();

	let value = Isset(() => props.multiple) && props.multiple ? [] : '';
	if (Isset(() => props.defaultValue) && props.defaultValue) {
		value = props.defaultValue;
	}

	const [statusPreCadastro, setStatusPreCadastro] = useState(value);

	const statusPreCadastroChange = e => {
		let value = e.target.value;
		if (Array.isArray(value)) {
			value = value.map(a => `${a}`).filter((v, i, self) => self.indexOf(v) === i);
		}
		setStatusPreCadastro(value);

		if (props.onChange) {
			props.onChange(value);
		}
	};

	const statusPreCadastroClear = e => {
		const isMultiple = Isset(() => props.multiple) ? props.multiple : false;
		e.stopPropagation();
		const value = isMultiple ? [] : '';
		setStatusPreCadastro(value);
		if (props.onChange) {
			props.onChange(value);
		}
	};

	return (
		<div className={classes.statusPreCadastro}>
			{statusPreCadastros && (
				<div
					className={clsx({
						[classes.filtroText]: true,
						[classes.filtroTextSelectedMultiple]: Array.isArray(statusPreCadastro)
							? statusPreCadastro.length > 0
							: statusPreCadastro,
					})}
				>
					<Select
						multiple={props.multiple || false}
						className={classes.filtroSelect}
						value={statusPreCadastro}
						onChange={statusPreCadastroChange}
						displayEmpty
						renderValue={selected => {
							if (!selected || selected.length === 0) {
								return <em>Status</em>;
							}

							const aux = statusPreCadastros.filter(a => selected.includes(a.id)).map(a => a.name);
							return (
								<div className={classes.chips}>
									{aux.map((value, i) => (
										<Chip key={`${value}${i}`} label={value} className={classes.chip} />
									))}
								</div>
							);
						}}
					>
						<MenuItem disabled value="">
							<em>Status</em>
						</MenuItem>
						{statusPreCadastros.map((statusPreCadastro, i) => (
							<MenuItem key={i} value={statusPreCadastro.id} className={classes.menuItem}>
								{statusPreCadastro.name}
							</MenuItem>
						))}
					</Select>
					{statusPreCadastro && <FaTimes className={classes.clearBtn} onClick={statusPreCadastroClear} />}
				</div>
			)}
		</div>
	);
};

export default FiltroStatusPreCadastro;
