const {
	REACT_APP_API_URL,
	REACT_APP_SISTEMA_URL,
	REACT_APP_BASENAME,
	REACT_APP_HOSTNAME,
	REACT_APP_API_V2_URL,
} = process.env;

const config = {
	apiUrl: REACT_APP_API_URL,
	sistemaUrl: REACT_APP_SISTEMA_URL,
	baseName: REACT_APP_BASENAME,
	hostName: REACT_APP_HOSTNAME || null,
	apiV2: REACT_APP_API_V2_URL,
};

export default config;
