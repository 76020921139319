/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import config from 'react-global-configuration';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import {
	Collapse,
	Grid,
	TextField,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	useMediaQuery,
	Dialog,
	DialogContent,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FaFileExcel } from 'react-icons/fa';
import { ZLoader } from '../system';
import FileDownload from 'js-file-download';
import { addMessage } from '../../store/alert';
import { Isset } from '../../utils';

const getFormaDePagamento = formaDePagamento => {
	switch (formaDePagamento) {
		case 'boleto':
			return 'Outros Meios';
		case 'credit':
			return 'Crédito';
		case 'debit':
			return 'Débito';
		case 'pix':
			return null;
		default:
			return null;
	}
};

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
		display: 'flex',
		justifyContent: 'space-around',
	},
	chevron: {
		position: 'absolute',
		right: 10,
		top: 10,
	},
	brandTitle: {
		textAlign: 'center',
		fontWeight: 700,
	},
	taxa: {
		position: 'relative',
		textAlign: 'center',
		cursor: 'pointer',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		marginTop: 5,
		borderRadius: 5,
		padding: 6,
		fontWeight: 700,
	},
	taxaDetalhes: {
		borderWidth: 1,
		borderColor: theme.palette.primary.main,
		borderStyle: 'solid',
		borderRadius: 5,
		padding: 6,
		margin: 'auto',
		marginTop: -5,
		paddingTop: 10,
	},
	form: {
		display: 'flex',
		flexDirection: 'row',
		marginLeft: 40,
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
	},
	select: {
		marginRight: 5,
	},
	input: {
		marginTop: 20,
	},
	rows: {
		marginBottom: 10,
		marginTop: 10,
		transition: '0.4s 0.3s',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},

	export: {
		position: 'absolute',
		top: 0,
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
}));

const PlanosDeVendasDetalhes = props => {
	const { id } = useParams();
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const [plano, setPlano] = useState();
	const [loading, setLoading] = useState(true);
	const [active, setActive] = useState(null);
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down('xs'));
	const dispatch = useDispatch();
	const [showExportMessage, setShowExportMessage] = useState(false);
	const [tipo, setTipo] = useState('Valor Liquido');
	const [valor, setValor] = useState();

	const getPlano = async () => {
		setLoading(true);
		let res = await axios.get(`${config.get('apiUrl')}planos_de_vendas/${id}`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			setPlano(res.data.plano);
		}

		setLoading(false);
	};

	useEffect(() => {
		getPlano();
	}, []);

	const handleCalc = (valor, tipo, taxa) => {
		if (valor) {
			if (tipo === 'Valor Liquido') {
				const result = parseFloat(valor) * ((100 - parseFloat(taxa)) / 100);
				return (
					<Grid xs={3} item style={{ textAlign: 'center' }}>
						{`R$${result.toFixed(2)}`}
					</Grid>
				);
			} else {
				const result = (parseFloat(valor) * 100) / (100 - parseFloat(taxa));
				return (
					<Grid xs={3} item style={{ textAlign: 'center' }}>
						{`R$${result.toFixed(2)}`}
					</Grid>
				);
			}
		}
	};

	const handleCalcBoleto = (taxa, tipo) => {
		if (valor) {
			if (tipo === 'Valor Liquido') {
				const result = parseFloat(valor) - parseFloat(taxa);
				return (
					<Grid xs={3} item style={{ textAlign: 'center' }}>
						{`R$${result.toFixed(2)}`}
					</Grid>
				);
			} else {
				const result = parseFloat(valor) + parseFloat(taxa);
				return (
					<Grid xs={3} item style={{ textAlign: 'center' }}>
						{`R$${result.toFixed(2)}`}
					</Grid>
				);
			}
		}
	};

	const handleChange = event => {
		setTipo(event.target.value);
	};
	const exportXls = async () => {
		setShowExportMessage(true);
		const result = await axios.get(`${config.get('apiUrl')}export/getPlanoDetails/${id}`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
		});

		if (result.status !== 200 || !Isset(() => result.data)) {
			setShowExportMessage(false);
			return dispatch(addMessage({ type: 'error', message: 'Erro ao exportar relatório.' }));
		}

		const { 'content-disposition': contentDisposition, 'content-type': contentType } = result.headers;
		let filename = 'performancePorEstabelecimento.xlsx';
		if (contentDisposition.indexOf('filename="') !== -1) {
			filename = contentDisposition.slice(
				contentDisposition.indexOf('filename="') + 10,
				contentDisposition.length - 1
			);
		}

		FileDownload(result.data, filename, contentType);
		setShowExportMessage(false);
	};

	const renderOutrosMeios = () => {
		const pixFee = 1;
		if (
			plano &&
			plano.fees &&
			plano.fees.boleto &&
			plano.fees.boleto.others &&
			plano.fees.boleto.others[1] &&
			plano.fees.boleto.others[1].fee
		) {
			const objeto = [
				{
					forma: 'Boleto',
					taxa: plano.fees.boleto.others[1].fee / 100,
				},
				{
					forma: 'Pix',
					taxa: pixFee,
				},
			];
			return objeto.map((item, index) => (
				<>
					<Grid xs={12} className={classes.brandTitle}>
						{item.forma}
					</Grid>
					<Grid className={classes.rows} key={`${item[index]}-${index}`} container>
						<Grid xs={3} item style={{ textAlign: 'center' }}>
							{` ${1}x`}
						</Grid>
						<Grid xs={5} item style={{ textAlign: 'center', marginLeft: 20 }}>
							{item.forma === 'Boleto' ? `R$${item.taxa}` : `${item.taxa}%`}
						</Grid>
						{item.forma === 'Boleto'
							? handleCalcBoleto(item.taxa, tipo)
							: handleCalc(valor, tipo, item.taxa)}
					</Grid>
				</>
			));
		}
	};
	return (
		<div>
			{loading && <ZLoader height={200} />}
			{plano && (
				<>
					<div className={classes.header}>
						Plano de Venda - {plano.name || 'Carregando...'}
						<FormControl className={classes.form} variant="standard" sx={{ m: 1, minWidth: 120 }}>
							<InputLabel id="demo-simple-select-standard-label">Tipo de Valor</InputLabel>
							<Select className={classes.select} value={tipo} onChange={handleChange} label="Tipo">
								<MenuItem value="Valor Liquido">Calcular Liquido</MenuItem>
								<MenuItem value="Valor Bruto">Calcular Bruto</MenuItem>
							</Select>
							<TextField
								placeholder="Insira o Valor"
								xs={12}
								type="number"
								className={classes.input}
								onChange={e => setValor(e.target.value)}
							/>
							<FaFileExcel className={classes.export} onClick={exportXls} />
						</FormControl>
					</div>
					<div className={classes.taxas}>
						{loading ? (
							<ZLoader height={200} />
						) : (
							<Grid container justifyContent="center" spacing={2} style={{ position: 'relative' }}>
								{Object.keys(plano.fees)
									.filter(feeKey => feeKey !== 'pix' && feeKey !== 'boleto')
									.map(feeKey => (
										<Grid item xs={12} sm={4} md={3} key={feeKey}>
											<div
												className={classes.taxa}
												onClick={() => setActive(active === feeKey ? null : feeKey)}
											>
												{getFormaDePagamento(feeKey)}
											</div>
											<Collapse in={isXs ? active === feeKey : true}>
												<div className={classes.taxaDetalhes}>
													{Object.keys(plano.fees[feeKey]).map(brand => {
														if (Object.keys(plano.fees[feeKey][brand]).length === 0) {
															return null;
														}
														const installments = plano.fees[feeKey][brand];
														return (
															<div key={brand}>
																<div className={classes.brandTitle}>{brand}</div>
																{Object.keys(installments).map(i => {
																	const installment = installments[i];
																	return (
																		<Grid
																			className={classes.rows}
																			key={`${brand}-${i}`}
																			container
																		>
																			<Grid
																				xs={3}
																				item
																				style={{ textAlign: 'center' }}
																			>
																				{` ${i}x`}
																			</Grid>
																			<Grid
																				xs={5}
																				item
																				style={{
																					textAlign: 'center',
																					marginLeft: 20,
																				}}
																			>
																				{`${(installment.fee / 100).toFixed(
																					2
																				)}%`}
																			</Grid>
																			{valor &&
																				handleCalc(
																					valor,
																					tipo,
																					installment.fee / 100
																				)}
																		</Grid>
																	);
																})}
															</div>
														);
													})}
												</div>
											</Collapse>
										</Grid>
									))}
								<Grid item xs={12} sm={4} md={3} key={'feeKey'}>
									<div
										key="Outros Meios"
										className={classes.taxa}
										onClick={() => setActive(active === 'Outros Meios' ? null : 'Outros Meios')}
									>
										Outros Meios
									</div>
									<Collapse in={isXs ? active === 'Outros Meios' : true}>
										<div className={classes.taxaDetalhes}>
											<Grid key={`Outros Meios`} container>
												{plano && renderOutrosMeios()}
											</Grid>
										</div>
									</Collapse>
								</Grid>
							</Grid>
						)}
					</div>

					<Dialog open={showExportMessage}>
						<DialogContent style={{ width: 200 }}>
							<ZLoader height={100} size={26} message={'Exportando'} />
						</DialogContent>
					</Dialog>
				</>
			)}
			{!plano && !loading && (
				<Grid container align="center" justifyContent="center">
					Esse plano não está mais disponível
				</Grid>
			)}
		</div>
	);
};

export default PlanosDeVendasDetalhes;
