/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid, Paper, TablePagination } from '@material-ui/core';
import { ZLoader, ZFiltros, ZEmpty } from '../system';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';
import { Isset, Formatter } from '../../utils/';
import FileDownload from 'js-file-download';
import { makeStyles } from '@material-ui/core/styles';
import { addMessage } from '../../store/alert';
import { FaFilter, FaFileExcel } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	export: {
		position: 'absolute',
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 18,
	},
	tableSubHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 16,
	},
	tableDateRow: {
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		backgroundColor: `${theme.palette.primary.main}aa`,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		paddingLeft: 20,
	},
	tableRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		marginBottom: 2,
		backgroundColor: `${theme.palette.primary.main}1a`,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	tableSubRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	tableRowSelected: {
		backgroundColor: `${theme.palette.primary.main}3c`,
	},
	vendas: {
		marginBottom: 10,
	},
}));

const RelatoriosVendasPorPOS = props => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const { history } = useReactRouter();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);

	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};

	const queryFilter = {
		data_venda: { de: new Date(), ate: new Date() },
		estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
	};

	const [filtros, setFiltros] = useState(queryFilter);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	const [pagination, setPagination] = useState({
		limit: 50,
		current: 0,
		totalRows: 0,
	});

	const filtrosChange = async data => {
		data.data_venda = {
			de: data.date.start,
			ate: data.date.end,
		};
		data.estabelecimentoId =
			Isset(() => data.estabelecimento) && Array.isArray(data.estabelecimento) ? [...data.estabelecimento] : [];

		// if (estabelecimentoId) {
		// 	data.estabelecimentoId.push(estabelecimentoId);
		// }

		setFiltros(data);
	};

	useEffect(() => {
		setFiltros({
			...filtros,
			estabelecimentoId,
		});
	}, [estabelecimentoId]);

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			setData([]);
			const filters = {};

			if (filtros.estabelecimentoId && filtros.estabelecimentoId.length > 0) {
				filters.estabelecimentoId = filtros.estabelecimentoId;
			}
			if (filtros.data_venda.de) {
				filters.startDate = filtros.data_venda.de;
			}
			if (filtros.data_venda.ate) {
				filters.endDate = filtros.data_venda.ate;
			}

			if (filtros.omniEstabelecimento) {
				filters.omniEstabelecimento = filtros.omniEstabelecimento;
			}

			const res = await axios.post(
				`${config.get('apiUrl')}graphql`,
				{
					query: `
						query vendasPorEC($filters: Filter, $pagination: Pagination) {
							vendasPorPOS(filters: $filters, pagination: $pagination){
								id, estabelecimento, identificationNumber, quantidadeVendas, valorTotal, chip
							}
							pagination(filters: $filters, pagination: $pagination){
								limit, current, totalRows
							}
						}
					`,
					variables: {
						filters,
						pagination,
					},
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (res.status === 200 && Isset(() => res.data.data.vendasPorPOS)) {
				const pos = res.data.data.vendasPorPOS;

				setData(pos);
				setPagination({ ...pagination, totalRows: res.data.data.pagination.totalRows });
			}
			setLoading(false);
		};

		getData();
	}, [pagination.limit, pagination.current, filtros]);

	const exportXls = async () => {
		const filters = {
			...filtros,
			onlyPOS: true,
			estabelecimentoId: filtros.estabelecimentoId,
		};

		const vendas = await axios.get(`${config.get('apiUrl')}export/vendas`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params: {
				filters,
			},
		});

		if (vendas.status !== 200 || !Isset(() => vendas.data)) {
			return dispatch(addMessage({ type: 'error', message: 'Erro ao exportar vendas.' }));
		}

		const { 'content-disposition': contentDisposition, 'content-type': contentType } = vendas.headers;
		let filename = 'vendasPorPOS.xlsx';
		if (contentDisposition.indexOf('filename="') !== -1) {
			filename = contentDisposition.slice(
				contentDisposition.indexOf('filename="') + 10,
				contentDisposition.length - 1
			);
		}

		FileDownload(vendas.data, filename, contentType);
	};

	const rowClick = identificationNumber => {
		history.push(
			`/vendas?pos=${identificationNumber}&startDate=${filtros.data_venda.de}&endData=${filtros.data_venda.ate}`
		);
	};

	return (
		<Grid className={classes.vendas}>
			<div className={classes.header}>Vendas Por POS</div>

			<Paper
				style={{
					position: 'relative',
					marginBottom: 10,
					borderRadius: 20,
					padding: '5px 10px',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<FaFilter />
				<ZFiltros
					onChange={filtrosChange}
					dateRange={{
						default: 'today',
						startDate: filtros.data_venda.de,
						endDate: filtros.data_venda.ate,
					}}
					omniEstabelecimento={
						![2, 3].includes(usuario.grupo_id)
							? {
									default: filtros.omniEstabelecimento,
							  }
							: false
					}
					// estabelecimento={{
					// 	default: filtros.estabelecimentoId,
					// 	multiple: true,
					// }}
				/>
				{usuario.grupo_id !== 7 && <FaFileExcel className={classes.export} onClick={exportXls} />}
			</Paper>

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper style={{ padding: 15 }}>
						{loading ? (
							<ZLoader height={200} />
						) : (
							<>
								{data.length > 0 ? (
									<Grid container className={classes.vendas}>
										<Grid container item className={classes.tableHeader}>
											<Grid item xs={4}>
												Estabelecimento
											</Grid>
											<Grid item xs={2}>
												POS
											</Grid>
											<Grid item xs={2}>
												Detalhe
											</Grid>
											<Grid item xs={1}>
												Quantidade
											</Grid>
											<Grid item xs={3}>
												Valor
											</Grid>
										</Grid>
										{data.map((pos, key) => (
											<React.Fragment key={key}>
												<Grid
													container
													item
													className={classes.tableRow}
													onClick={() => rowClick(pos.identificationNumber)}
												>
													<Grid item xs={4}>
														{pos.estabelecimento}
													</Grid>
													<Grid item xs={2}>
														{pos.identificationNumber}
													</Grid>
													<Grid item xs={2}>
														{pos.chip}
													</Grid>
													<Grid item xs={1}>
														{pos.quantidadeVendas}
													</Grid>
													<Grid item xs={3}>
														{Formatter.Real(pos.valorTotal)}
													</Grid>
												</Grid>
												{/* <Collapse in={pos.id === posSelected} style={{ width: '100%' }}>
													<Grid container item spacing={2} className={classes.vendas}>
														<Grid container item xs={12} className={classes.tableSubHeader}>
															<Grid item xs={2}>
																Data
															</Grid>
															<Grid item xs={4}>
																Cliente
															</Grid>
															<Grid item xs={2}>
																Forma de Pagamento
															</Grid>
															<Grid item xs={2}>
																Valor
															</Grid>
															<Grid item xs={2}>
																Valor Recebido
															</Grid>
														</Grid>
														{pos.vendas.map((venda, key) => (
															<Grid
																key={key}
																container
																item
																className={classes.tableSubRow}
																onClick={() => openVenda(venda.id)}
															>
																<Grid item xs={2}>
																	{format(venda.data, 'DD/MM/YYYY HH:mm')}
																</Grid>
																<Grid item xs={4}>
																	{venda.cliente}
																</Grid>
																<Grid item xs={2}>
																	{venda.formaPagamento}
																</Grid>
																<Grid item xs={2}>
																	{Formatter.Real(venda.valor)}
																</Grid>
																<Grid item xs={2}>
																	{Formatter.Real(venda.valorRecebido)}
																</Grid>
															</Grid>
														))}
													</Grid>
												</Collapse> */}
											</React.Fragment>
										))}
										<Grid item xs={12}>
											<TablePagination
												rowsPerPageOptions={[25, 50, 100]}
												component="div"
												count={pagination.totalRows}
												rowsPerPage={pagination.limit}
												page={pagination.current}
												onChangePage={(e, page) =>
													setPagination({ ...pagination, current: page })
												}
												onChangeRowsPerPage={e => {
													const totalRows = e.target.value * pagination.limit;
													if (totalRows > pagination.totalRows) {
														setPagination({
															...pagination,
															limit: e.target.value,
															current: 0,
														});
													} else {
														setPagination({ ...pagination, limit: e.target.value });
													}
												}}
												labelRowsPerPage={'Linhas por página:'}
												labelDisplayedRows={({ from, to, count }) =>
													`${from}-${to === -1 ? count : to} de ${count}`
												}
											/>
										</Grid>
									</Grid>
								) : (
									<ZEmpty height={200} text="Nenhuma Venda Encontrada." />
								)}
							</>
						)}
					</Paper>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default RelatoriosVendasPorPOS;
