import { Helmet } from 'react-helmet';
import React from 'react';

const Seo = ({ title, description, pathSlug, keywords }) => {
	const url = `https://dash.1pay.com.br/${pathSlug}`;
	return (
		<Helmet
			htmlAttributes={{ lang: 'pt-BR' }}
			title={title}
			meta={[
				{
					property: 'description',
					content: description,
				},
				{
					property: 'keywords',
					content: keywords,
				},
				{
					property: 'og:title',
					content: title,
				},
				{
					property: 'og:description',
					content: description,
				},
				{
					property: 'twitter:title',
					content: title,
				},
				{
					property: 'twitter:description',
					content: description,
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'twitter:card',
					content: 'summary_large_image',
				},
			]}
			links={[
				{
					rel: 'canonical',
					href: url,
				},
			]}
		/>
	);
};
export default Seo;
