/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid, Paper, TablePagination, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ZLoader, ZFiltros, ZEmpty } from '../system';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';
import { Isset, Formatter } from '../../utils/';
import FileDownload from 'js-file-download';
import { makeStyles } from '@material-ui/core/styles';
import { addMessage } from '../../store/alert';
import { useLocation } from 'react-router-dom';
import { FaFilter, FaFileExcel } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	export: {
		position: 'absolute',
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 18,
	},
	tableSubHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 16,
	},
	tableDateRow: {
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		backgroundColor: `${theme.palette.primary.main}aa`,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		paddingLeft: 20,
	},
	tableRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		marginBottom: 2,
		backgroundColor: `${theme.palette.primary.main}1a`,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	tableSubRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	tableRowSelected: {
		backgroundColor: `${theme.palette.primary.main}3c`,
	},
	vendas: {
		marginBottom: 10,
	},
}));

const RelatoriosVendasPorEstabelecimento = props => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const { history } = useReactRouter();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);

	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	let { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};

	const queryFilter = {
		data_venda: { de: new Date(), ate: new Date() },
		estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
		omniEstabelecimento: '',
		...filtersCache,
	};

	const [filtros, setFiltros] = useState(queryFilter);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	const filtrosChange = async data => {
		data.data_venda = {
			de: data.date.start,
			ate: data.date.end,
		};
		data.estabelecimentoId =
			Isset(() => data.estabelecimento) && Array.isArray(data.estabelecimento) && data.estabelecimento.length > 0
				? [...data.estabelecimento]
				: [];

		// if(data.omniEstabelecimento){
		// 	data.omniEstabeelciemnto = data.omniEstabelecimento;
		// }
		// if (estabelecimentoId) {
		// 	data.estabelecimentoId.push(estabelecimentoId);
		// }
		setPagination({
			...pagination,
			current: 0,
		});
		setFiltros(data);
	};

	const [pagination, setPagination] = useState({
		limit: 100,
		current: 0,
		totalRows: 0,
	});

	useEffect(() => {
		setFiltros({
			...filtros,
			estabelecimentoId,
		});
	}, [estabelecimentoId]);

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			setData([]);
			const filters = {};

			if (filtros.estabelecimentoId && filtros.estabelecimentoId.length > 0) {
				filters.estabelecimentoId = filtros.estabelecimentoId;
			}
			if (filtros.data_venda.de) {
				filters.startDate = filtros.data_venda.de;
			}
			if (filtros.data_venda.ate) {
				filters.endDate = filtros.data_venda.ate;
			}

			if (filtros.omniEstabelecimento) {
				filters.omniEstabelecimento = filtros.omniEstabelecimento;
			}

			const res = await axios.post(
				`${config.get('apiUrl')}graphql`,
				{
					query: `
                    query vendasPorEC($filters: Filter, $pagination: Pagination) {
                        vendas(filters: $filters, pagination: $pagination){
                            id, estabelecimentoId, estabelecimento, cliente, valor, valorRecebido, data, formaPagamento
                        },
						vendasPorEC(filters: $filters, pagination: $pagination) {
							id, nomeFantasia, quantidadeVendas, valorTotalVendas, valorTotalVendasRecebido
						},
						pagination(filters: $filters, pagination: $pagination){
							limit, current, totalRows
						}
					}
                `,
					variables: {
						filters,
						pagination,
					},
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (res.status === 200 && Isset(() => res.data.data.vendasPorEC)) {
				let ecs = res.data.data.vendasPorEC;
				let vendas = [];

				if (Isset(() => res.data.data.vendas)) {
					vendas = res.data.data.vendas;
				}

				ecs = ecs.map(a => {
					a.vendas = vendas.filter(b => +b.estabelecimentoId === +a.id);
					return a;
				});

				setData(ecs);
				setPagination({ ...pagination, totalRows: res.data.data.pagination.totalRows });
			}
			setLoading(false);
		};

		getData();
	}, [filtros, pagination.current, pagination.limit]);

	const exportXls = async () => {
		const filters = {};

		if (filtros.estabelecimentoId && filtros.estabelecimentoId.length > 0) {
			filters.estabelecimentoId = filtros.estabelecimentoId;
		}
		if (filtros.data_venda.de) {
			filters.startDate = filtros.data_venda.de;
		}
		if (filtros.data_venda.ate) {
			filters.endDate = filtros.data_venda.ate;
		}

		if (filtros.omniEstabelecimento) {
			filters.omniEstabelecimento = filtros.omniEstabelecimento;
		}

		const vendas = await axios.get(`${config.get('apiUrl')}export/relatorios/vendasPorEstabelecimento`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params: {
				filters,
			},
		});

		if (vendas.status !== 200 || !Isset(() => vendas.data)) {
			return dispatch(addMessage({ type: 'error', message: 'Erro ao exportar vendas.' }));
		}

		const { 'content-disposition': contentDisposition, 'content-type': contentType } = vendas.headers;
		let filename = 'vendasPorEstabelecimento.xlsx';
		if (contentDisposition.indexOf('filename="') !== -1) {
			filename = contentDisposition.slice(
				contentDisposition.indexOf('filename="') + 10,
				contentDisposition.length - 1
			);
		}

		FileDownload(vendas.data, filename, contentType);
	};

	return (
		<Grid className={classes.vendas}>
			<div className={classes.header}>Vendas Por Estabelecimento</div>

			<Paper
				style={{
					position: 'relative',
					marginBottom: 10,
					borderRadius: 20,
					padding: '5px 10px',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<FaFilter />
				<ZFiltros
					onChange={filtrosChange}
					dateRange={{
						default: 'custom',
						startDate: filtros.data_venda.de,
						endDate: filtros.data_venda.ate,
					}}
					// estabelecimento={{
					// 	default: filtros.estabelecimentoId,
					// 	multiple: true,
					// }}
					omniEstabelecimento={{
						default: filtros.omniEstabelecimento,
					}}
				/>
				{usuario.grupo_id !== 7 && <FaFileExcel className={classes.export} onClick={exportXls} />}
			</Paper>

			<Grid container>
				<Grid item xs={12}>
					<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 30px)' }}>
						{loading ? (
							<ZLoader height={200} />
						) : (
							<>
								{data.length > 0 ? (
									<Table>
										<TableHead>
											<TableRow>
												<TableCell style={{ minWidth: 180 }} align="center">
													Estabelecimento
												</TableCell>
												<TableCell style={{ minWidth: 180 }} align="center">
													Quantidade
												</TableCell>
												<TableCell style={{ minWidth: 180 }} align="center">
													Valor
												</TableCell>
												<TableCell style={{ minWidth: 180 }} align="center">
													Valor Recebido
												</TableCell>
											</TableRow>
										</TableHead>
										{data.map((ec, key) => (
											<React.Fragment key={key}>
												<TableBody
													onClick={() => {
														history.push(
															`/vendas?estabelecimentoNome=${encodeURIComponent(
																ec.nomeFantasia
															)}`
														);
													}}
												>
													<TableRow>
														<TableCell style={{ minWidth: 180 }}>
															{ec.nomeFantasia}
														</TableCell>
														<TableCell style={{ minWidth: 180 }}>
															{ec.quantidadeVendas}
														</TableCell>
														<TableCell style={{ minWidth: 180 }}>
															{Formatter.Real(ec.valorTotalVendas)}
														</TableCell>
														<TableCell style={{ minWidth: 180 }}>
															{Formatter.Real(ec.valorTotalVendasRecebido)}
														</TableCell>
													</TableRow>
												</TableBody>
											</React.Fragment>
										))}
										<Grid item xs={12}>
											<TablePagination
												rowsPerPageOptions={[25, 50, 100]}
												component="div"
												count={pagination.totalRows}
												rowsPerPage={pagination.limit}
												page={pagination.current}
												onChangePage={(e, page) =>
													setPagination({ ...pagination, current: page })
												}
												onChangeRowsPerPage={e => {
													const totalRows = e.target.value * pagination.limit;
													if (totalRows > pagination.totalRows) {
														setPagination({
															...pagination,
															limit: e.target.value,
															current: 0,
														});
													} else {
														setPagination({ ...pagination, limit: e.target.value });
													}
												}}
												labelRowsPerPage={'Linhas por página:'}
												labelDisplayedRows={({ from, to, count }) =>
													`${from}-${to === -1 ? count : to} de ${count}`
												}
											/>
										</Grid>
									</Table>
								) : (
									<ZEmpty height={200} text="Nenhuma Venda Encontrada." />
								)}
							</>
						)}
					</Paper>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default RelatoriosVendasPorEstabelecimento;
