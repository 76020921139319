import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	paper: {
		padding: 10,
	},
	sectionHeader: {
		textAlign: 'center',
		fontVariant: 'small-caps',
		fontSize: 16,
		fontWeight: 'bold',
	},
	field: {
		padding: 5,
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	label: {
		fontSize: 10,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
}));
