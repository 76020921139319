/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CheckCircle, Error, Info, Close, Warning } from '@material-ui/icons';
import { amber, green } from '@material-ui/core/colors';
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { addMessage } from '../../store/alert';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const variantIcon = {
	success: CheckCircle,
	warning: Warning,
	error: Error,
	info: Info,
};

const useStyles1 = makeStyles(theme => ({
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	info: {
		backgroundColor: theme.palette.primary.main,
	},
	warning: {
		backgroundColor: amber[700],
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1),
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
}));

function MySnackbarContentWrapper(props) {
	const classes = useStyles1();
	const { className, message, onClose, variant, ...other } = props;
	const Icon = variantIcon[variant];

	return (
		<SnackbarContent
			className={clsx(classes[variant], className)}
			aria-describedby="client-snackbar"
			message={
				<span id="client-snackbar" className={classes.message}>
					<Icon className={clsx(classes.icon, classes.iconVariant)} />
					{message}
				</span>
			}
			action={[
				<IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
					<Close className={classes.icon} />
				</IconButton>,
			]}
			{...other}
		/>
	);
}

MySnackbarContentWrapper.propTypes = {
	className: PropTypes.string,
	message: PropTypes.string,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

// const useStyles2 = makeStyles(theme => ({
//   margin: {
//     margin: theme.spacing(1),
//   },
// }));
function ZAlert(props) {
	// const classes = useStyles2();
	const [open, setOpen] = React.useState(props.open || true);

	useEffect(() => {
		setOpen(true);
	}, [props.time]);

	function handleClose(event, reason) {
		if (reason === 'clickaway') {
			return;
		}

		// props.addMessage({type: 'error', message: false});
		setOpen(false);
	}

	useEffect(() => {
		if (!open) {
			setOpen(true);
		}
	}, [props.message]);

	return (
		<div>
			<Snackbar
				anchorOrigin={{
					vertical: props.vertical || 'top',
					horizontal: 'center',
				}}
				open={open}
				autoHideDuration={4000}
				onClose={handleClose}
			>
				<MySnackbarContentWrapper onClose={handleClose} variant={props.type} message={props.message} />
			</Snackbar>
		</div>
	);
}

const mapDispatchToProps = dispatch => bindActionCreators({ addMessage }, dispatch);

export default connect(null, mapDispatchToProps)(ZAlert);
