/* eslint-disable no-unused-vars */
import React, { useState, useEffect, memo, useMemo } from 'react';
import { ZTitle, ZLoader, ZText, ZSelect, ZDatePicker, ZSwitch, ZSaveButton } from '../system';
import { Mask, ValidatorForm, Isset } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import config from 'react-global-configuration';
import axios from 'axios';
import useReactRouter from 'use-react-router';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Paper,
	Slide,
	Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import { useTheme } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	layout: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 900,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginBottom: theme.spacing(6),
			padding: theme.spacing(4),
		},
	},
	menuIcon: {
		fontSize: 24,
		cursor: 'pointer',
		marginRight: 4,
	},
	estabelecimento: {
		padding: '6px 6px',
		marginBottom: 10,
	},
	estabelecimentoValor: {
		'&.porcentagem input': {
			textAlign: 'right',
		},
	},
	estabelecimentoIcone: {
		border: 'solid 1px #abc',
		marginRight: 2,
		marginLeft: 2,
		marginTop: 16,
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}`,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	estabelecimentoError: {
		fontSize: 12,
		color: '#e53935',
	},
	label: {
		textAlign: 'center',
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	removeButton: {
		minHeight: 26,
		height: 26,
		width: 26,
		backgroundColor: '#e53935',
		color: 'white',
	},
	box: {
		textTransform: 'uppercase',
		fontSize: 12,
		position: 'relative',
		padding: 10,
		height: 110,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}55`,
		},
		'& .value': {
			fontWeight: 700,
			fontSize: 13,
		},
	},
	divider: {
		margin: 4,
	},
	span: {
		color: '#777',
		fontSize: 14,
	},
	removerButton: {
		backgroundColor: '#e53935',
		color: 'white',
	},
	statusButton: {
		backgroundColor: '#e53935',
		color: 'white',
		'&.ativar': {
			backgroundColor: 'green',
		},
	},
	divButton: {
		marginLeft: 'auto',
		marginTop: 64,
		[theme.breakpoints.down('xs')]: {
			margin: '64px auto 0',
			width: '100%',
		},
	},
	error: {
		padding: 8,
		borderRadius: 5,
		backgroundColor: '#e53935',
		color: 'white',
		marginBottom: 10,
	},
	subheader: {
		fontWeight: 700,
		fontSize: 18,
		fontVariant: 'small-caps',
		letterSpacing: '0.02em',
		margin: '20px 8px',
		marginTop: -30,
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ShareDialog = memo(props => {
	return (
		<Dialog
			open={props.open}
			TransitionComponent={Transition}
			keepMounted
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle id="alert-dialog-slide-title">{'Sucesso!'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
					Deseja acessar o endereço da Campanha?
				</DialogContentText>
			</DialogContent>
			<DialogActions style={{ textAlign: 'center' }}>
				<Button onClick={props.handleSuccess} color="primary">
					Sim
				</Button>
				<Button onClick={props.handleClose} color="primary">
					Não
				</Button>
			</DialogActions>
		</Dialog>
	);
});

export default props => {
	const classes = useStyles();
	const theme = useTheme();
	const { history, match } = useReactRouter();

	const { sellingCampaignId } = match.params;

	const dispatch = useDispatch();
	const token = useSelector(store => store.auth.token);

	const [sellingCampaign, setSellingCampaign] = useState(null);
	const loadingToken = false;
	const [loading, setLoading] = useState(false);
	const [onError, setOnError] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [showShareModal, setShowShareModal] = useState(false);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [successSellingCampaign, setsuccessSellingCampaign] = useState(null);
	var [data, setData] = useState({
		id: null,
		titulo: '',
		descricao: '',
		amount: 0.0,
		parcelamento_ate: 12,
		meio_pagamento: [1, 3, 5],
		pix: null,
		credit_card: null,
		bank_slip: null,
		status: true,
		installments_payer: true,
		data_expiracao: moment(),
		logo: false,
	});

	const validateFields = {
		titulo: { required: true },
		amount: { required: true, min: 10 },
		parcelamento_ate: { required: true },
	};

	var env = '';
	switch (process.env.REACT_APP_ENV) {
		case 'development':
			env = 'devpay';
			break;
		case 'qas':
			env = 'qaspay';
			break;
		case 'production':
			env = 'pay';
			break;
		default:
			env = 'pay';
			break;
	}

	let { estabelecimentoId } = match.params;

	if (!estabelecimentoId) {
		({ estabelecimentoId } = props);
	}

	useEffect(() => {
		setShowConfirmModal(true);
		getSellingCampaign();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getSellingCampaign = async () => {
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}sellingCampaign/${sellingCampaignId}`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		if (res.data.success) {
			setData({
				...data,
				titulo: res.data.sellingCampaign.name,
				status: res.data.sellingCampaign.status,
				installments_payer: res.data.sellingCampaign.installments_payer,
				descricao: res.data.sellingCampaign.description,
				amount: res.data.sellingCampaign.amount,
				data_expiracao: res.data.sellingCampaign.until,
				logo: res.data.sellingCampaign.logo,
				parcelamento_ate: res.data.sellingCampaign.installments,
				meio_pagamento: [
					res.data.sellingCampaign.bank_slip ? 1 : null,
					res.data.sellingCampaign.credit_card ? 3 : null,
					res.data.sellingCampaign.pix ? 5 : null,
				],
			});
		}
		setLoading(false);
	};

	const handleSubmitForm = async () => {
		setLoading(true);
		try {
			ValidatorForm(validateFields, data);
		} catch (erro) {
			dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário' }));
			setLoading(false);
			setOnError(true);
			setActiveStep(0);
			return false;
		}

		let res = '';
		let dataSellingCampaign = data;

		res = await axios.put(config.get('apiUrl') + 'sellingCampaign/' + sellingCampaignId, dataSellingCampaign, {
			headers: { Authorization: `Bearer ${token}` },
		});

		setLoading(false);

		if (res.data.error) {
			dispatch(addMessage({ type: 'error', message: 'Ocorreu um erro ao salvar' }));
		}

		if (Isset(() => res.data.success) && res.data.success === true) {
			dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
			setsuccessSellingCampaign(res.data.sellingCampaign);
			setShowShareModal(true);
		}
	};

	const handleChange = name => event => {
		var value = event.target.value;

		switch (name) {
			case 'amount':
				value = Mask.Real(value);
				break;
			case 'meio_pagamento':
				value.includes(1) ? (data.bank_slip = true) : (data.bank_slip = false);
				value.includes(3) ? (data.credit_card = true) : (data.credit_card = false);
				value.includes(5) ? (data.pix = true) : (data.pix = false);
				break;
			default:
				break;
		}
		setData({
			...data,
			[name]: value,
		});
	};

	const handleSuccess = () => {
		if (successSellingCampaign) {
			window.location.href = `https://${env}.1pay.com.br/${successSellingCampaign.token}`;
			// history.push('/checkout/' + successSellingCampaign.token);
		}
	};

	const parcelas = [];
	for (let i = 1; i <= 12; i++) {
		const text = i > 1 ? ' vezes' : ' vez';
		parcelas.push({ id: i, name: i + text });
	}

	if (loadingToken) {
		return <ZLoader height={200} />;
	}
	const handleNext = () => {
		setActiveStep(prevStep => prevStep + 1);
	};

	const handleBack = () => {
		setActiveStep(prevStep => prevStep - 1);
	};

	const formattedData = useMemo(() => {
		return {
			date: format(data.data_expiracao, 'DD/MM/YYYY'),
			amount: Mask.Real(data.amount / 100),
			logo: data.logo ? 'Sim' : 'Não',
			credit_card: data.credit_card ? 'Cartão de Crédito' : '',
			bank_slip: data.bank_slip ? 'Boleto Bancário' : '',
			pix: data.pix ? 'Pix' : '',
			payment: data.meio_pagamento === 3 ? 'Cartão de Crédito' : 'Boleto Bancário',
			parcel: data.parcelamento_ate === 1 ? `${data.parcelamento_ate} vez` : `${data.parcelamento_ate} vezes`,
			status: data.status ? 'Ativa' : 'Inativa',
			installments_payer: data.installments_payer ? 'Sim' : 'Não',
		};
	}, [data]);

	return (
		<Container maxWidth="lg">
			<Grid container>
				<ZTitle title={'Editar Campanha de Vendas'} textAlign={'center'} />

				<Grid item xs={12}>
					<Grid container item>
						<Grid item xs={12} md={12} lg={12}>
							<Paper className={classes.paper}>
								{activeStep === 0 && (
									<Grid container spacing={3}>
										<Grid item xs={12} md={6}>
											<ZText
												label="Titulo"
												onError={onError}
												required={true}
												value={data.titulo}
												onBlur={handleChange('titulo')}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<ZText
												label="Descrição"
												value={data.descricao}
												onBlur={handleChange('descricao')}
											/>
										</Grid>

										<Grid item xs={12} sm={6} md={6} style={{ marginTop: 15 }}>
											<ZDatePicker
												textField
												label="Data de expiração"
												disabled={data.id ? true : false}
												minDate={new Date()}
												date={data.data_expiracao}
											/>
										</Grid>

										<Grid item xs={12} md={6} style={{ marginTop: 15 }}>
											<ZSwitch
												onChange={() => setData({ ...data, status: !data.status })}
												label="Ativar Campanha?"
												checked={data.status}
											/>
											<ZSwitch
												onChange={() =>
													setData({ ...data, installments_payer: !data.installments_payer })
												}
												label="Juros por conta do cliente?"
												checked={data.installments_payer}
											/>
											<ZSwitch
												onChange={() => setData({ ...data, logo: !data.logo })}
												label="Exibir logo do estabelecimento?"
												checked={data.logo}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											<ZSelect
												options={[
													{
														id: 1,
														name: 'Boleto Bancário',
													},
													{
														id: 3,
														name: 'Cartão de Crédito',
													},
													{
														id: 5,
														name: 'Pix',
													},
												]}
												label="Meio de pagamento"
												disabled={data.id ? true : false}
												onError={onError}
												width="100%"
												multiple
												value={data.meio_pagamento}
												onChange={handleChange('meio_pagamento')}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											<ZText
												fullWidth
												label="Valor"
												margin="normal"
												realMask
												disabled={true}
												onError={onError}
												min={validateFields.amount.min}
												placeholder="Valor a ser pago"
												value={data.amount / 100}
												onBlur={e => {
													setData({ ...data, amount: Math.round(e.target.value * 100) });
												}}
											/>
										</Grid>

										<Grid item xs={12} sm={6} md={4}>
											<ZSelect
												disabled={!data.meio_pagamento.includes(3) || data.id ? true : false}
												options={parcelas}
												label="Máximo de parcelas"
												onError={onError}
												width="100%"
												value={
													data.parcelamento_ate > 1 && !data.meio_pagamento.includes(3)
														? (data.parcelamento_ate = 1)
														: data.parcelamento_ate
												}
												onBlur={handleChange('parcelamento_ate')}
											/>
										</Grid>
									</Grid>
								)}

								{activeStep === 1 && (
									<Grid container style={{ marginLeft: 32, position: 'relative' }}>
										<Grid item style={{ marginBottom: 16 }}>
											<Typography variant="h5" component="h5" gutterBottom>
												{data.id ? `ID - ${data.id} ` : `Resumo`}
											</Typography>
										</Grid>
										<Grid container spacing={2} item>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Titulo <br /> <span className={classes.span}>{data.titulo}</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Valor <br />{' '}
													<span className={classes.span}>{formattedData.amount}</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Descrição <br />{' '}
													<span className={classes.span}>{data.descricao}</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Meio de pagamento <br />{' '}
													<span className={classes.span}>
														{data.meio_pagamento.includes(1) ? ' Boleto Bancário ' : ''}
														{data.meio_pagamento.includes(3) ? ' Cartão de Crédito ' : ''}
														{data.meio_pagamento.includes(5) ? ' Pix ' : ''}
													</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Data de expiração <br />{' '}
													<span className={classes.span}>{formattedData.date}</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Exibir Logo <br />{' '}
													<span className={classes.span}>{formattedData.logo}</span>
												</Typography>
											</Grid>

											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Máximo de parcelas <br />{' '}
													<span className={classes.span}>{formattedData.parcel}</span>
												</Typography>
											</Grid>

											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Campanha Ativa <br />{' '}
													<span className={classes.span}>{formattedData.status}</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Juros por conta do cliente <br />{' '}
													<span className={classes.span}>
														{formattedData.installments_payer}
													</span>
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								)}
								<Grid container item spacing={3} xs={12} md={6} lg={6} className={classes.divButton}>
									<Grid item xs={12} md={6} lg={6}>
										<Button
											disabled={activeStep === 0}
											onClick={handleBack}
											variant="outlined"
											fullWidth
											color="primary"
										>
											Voltar
										</Button>
									</Grid>
									{activeStep < 1 && (
										<Grid item xs={12} md={6}>
											<Button
												onClick={handleNext}
												fullWidth
												color={'primary'}
												variant="contained"
											>
												Continuar
											</Button>
										</Grid>
									)}
									{activeStep === 1 && !data.id && (
										<Grid item xs={12} md={6}>
											<ZSaveButton
												onClick={() => handleSubmitForm()}
												color={'primary'}
												align="right"
												label={'Salvar'}
												required={true}
												loading={loading}
											/>
										</Grid>
									)}
									{activeStep === 1 && data.id && (
										<Grid item xs={12} md={6}>
											<ZSaveButton
												onClick={() =>
													window.open(
														`https://${env}.1pay.com.br/${sellingCampaign.token}`,
														'_blank'
													)
												}
												color={'primary'}
												align="right"
												label={'Visualizar tela de pagamento?'}
											/>
										</Grid>
									)}
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<ShareDialog
				open={showShareModal}
				handleClose={() => {
					setShowShareModal(false);
					history.push('/sellingCampaign/');
				}}
				handleSuccess={handleSuccess}
			/>
		</Container>
	);
};
