import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Paper, Grid, Typography, Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { FiInfo } from 'react-icons/fi';
import axios from 'axios';
import config from 'react-global-configuration';
import moment from 'moment';
import useReactRouter from 'use-react-router';
import { FaFilter } from 'react-icons/fa';
import { format } from 'date-fns';
import { useStyles } from './styles/CobrancaListagemStyle';
import { ZTitle, ZLoader, ZPaginationDiv, ZFiltros } from '../system';
import { Formatter } from '../../utils';

const CobrancaListagem = props => {
	const classes = useStyles();
	const { history } = useReactRouter();

	const token = useSelector(store => store.auth.token);
	const { id: estabelecimentoSelecionadoId } = useSelector(state => state.admin.estabelecimento) || {};

	const [pagamentos, setPagamentos] = useState([]);
	const [loading, setLoading] = useState(false);

	const [filtros, setFiltros] = useState({
		status: {
			id: 'status',
			label: 'Status',
			value: [],
			multiple: true,
			options: [
				{ id: '0', name: 'Reprovado' },
				{ id: '1', name: 'Aprovado' },
			],
		},
		tipo: {
			options: [
				{ id: 1, name: 'Aluguel de POS' },
				{ id: 2, name: 'Cobrança por transação' },
				{ id: 3, name: 'Pacote financeiro' },
				{ id: 4, name: 'Taxa administrativa' },
				{ id: 5, name: 'Taxa Transferência P2P' },
			],
		},
		created: {
			start: '',
			end: '',
			label: 'Data da Cobrança',
		},
		mes: format(new Date(), 'MM'),
		ano: format(new Date(), 'YYYY'),
	});

	//paginacao
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, rowsPerPage]);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtros, estabelecimentoSelecionadoId]);

	const getData = useCallback(async () => {
		setLoading(true);

		const dataFiltros = {
			status: filtros.status.value,
			tipo: filtros.tipoCobranca,
			mesReferente: `${filtros.mes}/${filtros.ano}`,
			estabelecimentoId: filtros.estabelecimento,
		};

		if (filtros.created.start) {
			dataFiltros.dataInicial = moment(filtros.created.start).format('YYYY-MM-DD');
			dataFiltros.dataFinal = moment(filtros.created.end).format('YYYY-MM-DD');
		}

		const params = {
			page,
			limit: rowsPerPage,
			...dataFiltros,
			estabelecimentoSelecionadoId,
		};

		const response = await axios.get(`${config.get('apiUrl')}cobranca_recorrente/pagamentos`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			params,
		});

		if (response.data.success && response.data.pagamentos) {
			setLoading(false);
			setPagamentos(response.data.pagamentos);
			setTotalRows(response.data.quantidade);
		}
	}, [page, rowsPerPage, token, filtros, estabelecimentoSelecionadoId]);

	const onChangePage = useCallback(newPage => {
		setPage(newPage);
	}, []);

	const onChangeRowsPerPage = useCallback(rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	}, []);

	const filtrosChange = useCallback(data => {
		setFiltros(filtros => ({ ...filtros, ...data }));
	}, []);

	const selectedChange = useCallback((id, data) => {
		const { value } = data.target;

		setFiltros(filtros => ({ ...filtros, [id]: { ...filtros[id], value } }));
	}, []);

	const dadosFormatados = useMemo(() => {
		return pagamentos.map(({ success, created_at }) => ({
			status: success === 1 ? 'Aprovado' : 'Reprovado',
			dataCriacao: moment(created_at).format('DD/MM/YYYY'),
		}));
	}, [pagamentos]);

	return (
		<React.Fragment>
			<Grid item xs={12} sm={12} style={{ margin: '8px 0' }}>
				<ZTitle title="Taxas" display="flex" textAlign="center" fontSize="45px" />

				<Grid item xs={12}>
					<Paper
						style={{
							marginBottom: 10,
							borderRadius: 20,
							padding: '5px 10px',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<FaFilter />
						<ZFiltros
							onChange={filtrosChange}
							onSelectChange={selectedChange}
							mes={{ defaultValue: filtros.mes }}
							ano={{ defaultValue: filtros.ano }}
							select={[{ ...filtros.status }]}
							created={{ ...filtros.created }}
							tipoCobranca={{
								defaultValue: filtros.tipoCobranca,
								tipo: filtros.tipo.options,
							}}
							estabelecimento={{ defaultValue: filtros.estabelecimento, multiple: true }}
						/>
					</Paper>
				</Grid>

				{loading ? (
					<ZLoader height={200} message="Carregando Pagamentos" />
				) : (
					<>
						{pagamentos.length === 0 && (
							<Grid container justifyContent="center" alignItems="center">
								<Typography variant="h5">Taxa não encontrada</Typography>
							</Grid>
						)}

						{pagamentos.map((pagamento, index) => (
							<Paper
								key={pagamento.id}
								className={classes.tableRow}
								onClick={() => {
									if (pagamento.transferencia_id)
										history.push(`/transferencias/detalhes/${pagamento.transferencia_id}`);
								}}
							>
								<Grid container style={{ padding: 20, marginTop: 24 }}>
									<Grid item xs={4} sm={1} className={classes.truncate}>
										<p className={classes.rowTitle} style={{ margin: 0 }}>
											ID
											<br />
											<span className={classes.rowText}>{pagamento.id}</span>
										</p>
									</Grid>
									<Grid item xs={6} sm={2} className={classes.truncate}>
										<p className={classes.rowTitle} style={{ margin: 0 }}>
											Tipo da Cobrança
											<br />
											<span className={classes.rowText}>
												{pagamento.cobranca_recorrente.tipo_cobranca_recorrente.name}
											</span>
										</p>
									</Grid>
									<Grid item xs={8} sm={2}>
										<p className={classes.rowTitle}>
											Estabelecimento <br />{' '}
											<span className={classes.rowText}>
												{pagamento.cobranca_recorrente.EstabelecimentoCobrado.nome_fantasia}
											</span>
										</p>
									</Grid>
									<Grid item xs={4} sm={1}>
										<p className={classes.rowTitle}>
											Status <br />{' '}
											<span className={classes.rowText}>{dadosFormatados[index].status}</span>
										</p>
									</Grid>
									<Grid item xs={6} sm={1}>
										<p className={classes.rowTitle}>
											Referente <br />
											<span className={classes.rowText}>{pagamento.referente}</span>
										</p>
									</Grid>
									<Grid item xs={6} sm={1} className={classes.hideOnXs}>
										<p className={classes.rowTitle}>
											Data
											<br />
											<span className={classes.rowText}>
												{dadosFormatados[index].dataCriacao}
											</span>
										</p>
									</Grid>
									<Grid item xs={6} sm={2} className={classes.truncate}>
										<Tooltip title={`${pagamento.observacao}`}>
											<p className={classes.rowTitle}>
												Valor <br />
												<span className={classes.rowText}>
													{Formatter.Real(pagamento.valor / 100)}
												</span>
											</p>
										</Tooltip>
									</Grid>
									<Grid item xs={6} sm={2} className={classes.truncate}>
										<Tooltip title={`${pagamento.observacao}`} style={{ marginTop: 5 }}>
											<p className={classes.rowTitle}>
												Comentário <br />
												<FiInfo size={20} color="#424242" />
												<br />
											</p>
										</Tooltip>
									</Grid>
								</Grid>
							</Paper>
						))}
					</>
				)}

				<ZPaginationDiv
					count={totalRows}
					rowsPerPage={rowsPerPage}
					page={page - 1}
					onChangePage={onChangePage}
					onChangeRowsPerPage={onChangeRowsPerPage}
					rowsPerPageOptions={[15, 30, 45, 60, 75, 90, 105]}
				/>
			</Grid>
		</React.Fragment>
	);
};
export default CobrancaListagem;
