/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ZLoader } from '../system';
import Axios from 'axios';
import config from 'react-global-configuration';
import { useSelector } from 'react-redux';
import { Grid, Button, Dialog, DialogContent } from '@material-ui/core';
import { format } from 'date-fns';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useStyles } from './styles/LastExportStyle';

const LastExport = props => {
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);

	const [loading, setLoading] = useState(true);
	const [lastExports, setLastExports] = useState([]);

	const getLastExports = async () => {
		setLoading(true);
		const res = await Axios.get(`${config.get('apiUrl')}export/getLastExports`, {
			headers: { Authorization: `Bearer ${token}` },
			params: { tipo: props.tipo },
		});

		if (res.data.success) {
			setLastExports(res.data.result);
		}
		setLoading(false);
	};

	useEffect(() => {
		getLastExports();

		const id = setInterval(getLastExports, 10000);

		return () => {
			clearInterval(id);
		};
	}, []);

	return (
		<Dialog open={props.show} onClose={props.onClose} maxWidth="sm" fullWidth>
			<DialogContent style={{ textAlign: 'center' }}>
				<Alert severity="warning">
					<AlertTitle>Atenção</AlertTitle>
					<p>
						Aqui você pode visualizar suas últimas exportações ou gerar uma <strong>Nova Exportação</strong>
					</p>
					<p>
						As exportações duram <strong>5</strong> dias.
					</p>
				</Alert>
				{loading ? (
					<ZLoader height={200} size={15} message={'Carregando'} />
				) : (
					<>
						<div className={classes.title}>Últimas Exportações</div>
						{lastExports.length > 0 ? (
							<>
								<div style={{ height: 300, overflowY: 'scroll' }}>
									{lastExports.map(e => (
										<Grid
											key={e.created}
											container
											justifyContent="center"
											alignItems="center"
											style={{ borderBottom: 'solid 1px #d2d2d2', textAlign: 'center' }}
										>
											<Grid item xs={6}>
												{format(e.created, 'DD/MM/YYYY HH:mm')}
											</Grid>
											<Grid item xs={6}>
												<Button
													className={classes.downloadButton}
													onClick={() => window.open(e.url)}
												>
													Download
												</Button>
											</Grid>
										</Grid>
									))}
								</div>
							</>
						) : (
							<div>Nenhuma Exportação Recente.</div>
						)}
					</>
				)}
				{props.newExport && (
					<Button className={classes.newExport} onClick={props.newExport}>
						Nova Exportação
					</Button>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default LastExport;
