import React from 'react';
import { Grid } from '@material-ui/core';
import { ZTitle } from '../system';
import ZFiltrosOptimal from '../system/ZFiltrosOptimal';

const Negociacao = () => {
	return (
		<>
			<Grid container direction="row" justifyContent="center" alignItems="center">
				<ZTitle title="Negociar" display="flex" textAlign="center" fontSize="70px" />
				<ZFiltrosOptimal />
			</Grid>
		</>
	);
};

export default Negociacao;
