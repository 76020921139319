import React, { useState, useCallback, useEffect } from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core';
import axios from 'axios';
import config from 'react-global-configuration';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ZMenuOptions, ZPagination, ZFiltros, ZLoader, ZTitle } from '../system';
import { FaFilter } from 'react-icons/fa';

const ClientesListagem = props => {
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const { pathname } = useLocation();
	const { history } = useReactRouter();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');
	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});
	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		clientes: [],
		quantidade: 0,
		paginas: 0,
	});
	const [filtros, setFiltros] = useState({
		omni: '',
		...filtersCache,
	});

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(30);
	const [totalRows, setTotalRows] = useState(0);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, rowsPerPage]);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtros, estabelecimentoId]);

	const getData = useCallback(async () => {
		setLoading(true);

		const response = await axios.get(`${config.get('apiUrl')}clientes`, {
			headers: { Authorization: `Bearer ${token}` },
			params: {
				page,
				limit: rowsPerPage,
				nome: filtros.omni,
				contato: filtros.omni2,
				documento: filtros.documento,
				filters: {
					estabelecimentoId,
				},
			},
		});

		if (response.data.success) {
			setData(response.data);

			setTotalRows(response.data.quantidade);
		}

		setLoading(false);
	}, [page, rowsPerPage, token, filtros, estabelecimentoId]);

	const handleFiltrosChange = useCallback(data => {
		setFiltros(filtros => ({ ...filtros, ...data }));
	}, []);

	const onChangePage = useCallback(newPage => {
		setPage(newPage);
	}, []);

	const onChangeRowsPerPage = useCallback(rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	}, []);

	return (
		<Grid>
			<ZTitle title="Clientes" display="flex" textAlign="center" fontSize="55px" grupo={usuario.grupo_id} />
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper
						style={{
							position: 'relative',
							marginBottom: 10,
							borderRadius: 20,
							padding: '5px 10px',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<FaFilter />
						<ZFiltros
							onChange={handleFiltrosChange}
							omni={{
								default: filtros.omni || '',
								placeholder: 'Nome/Email',
							}}
							omni2={{
								default: filtros.omni2 || '',
								placeholder: 'Celular',
							}}
							searchEstabelecimentoByCpfOrCNPJ={{
								value: filtros.documento || '',
								placeholder: 'CPF',
							}}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 45px)' }}>
						{loading ? (
							<ZLoader height={200} />
						) : (
							<Table>
								<TableHead>
									<TableRow>
										<TableCell style={{ minWidth: 150, textAlign: 'center' }}>Nome</TableCell>
										<TableCell style={{ minWidth: 230, textAlign: 'center' }}>E-mail</TableCell>
										<TableCell style={{ minWidth: 70, textAlign: 'center' }}>Sexo</TableCell>
										<TableCell style={{ minWidth: 150, textAlign: 'center' }}>Opções</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.clientes.map(cliente => (
										<TableRow key={cliente.id}>
											<TableCell component="th" scope="row">
												{cliente.nome}
											</TableCell>
											<TableCell align="center">{cliente.email}</TableCell>
											<TableCell align="center">{cliente.sexo}</TableCell>
											<TableCell align="center">
												{[1, 2, 7].includes(usuario.grupo_id) && (
													<ZMenuOptions
														data={[
															{
																title: 'Editar',
																onClick: () => {
																	history.push(`/cliente/editar/${cliente.id}`);
																},
															},
															{
																title: 'Nova Venda',
																onClick: () => {
																	history.push(
																		`/vendas/nova/${cliente.clientes_documentos[0].documento}`
																	);
																},
															},
														]}
													/>
												)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
								<ZPagination
									count={totalRows}
									rowsPerPage={rowsPerPage}
									page={page - 1}
									onChangePage={onChangePage}
									onChangeRowsPerPage={onChangeRowsPerPage}
								/>
							</Table>
						)}
					</Paper>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default ClientesListagem;
