/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import clsx from 'clsx';

import { Isset } from '../../../utils';

import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, Chip } from '@material-ui/core';
import { FaTimes } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
	filtros: {
		position: 'relative',
		display: 'inline-flex',
	},
	filtroText: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		padding: '5px 10px',
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 30,
		marginLeft: 10,
		fontWeight: 700,
		fontSize: 12,
		color: theme.palette.primary.main,
	},
	filtroTextSelectedMultiple: {
		backgroundColor: `${theme.palette.primary.main}`,
		color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		'& .MuiSelect-select': {
			color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		},
		padding: '2px 10px',
		minHeight: 29,
		maxWidth: 340,
	},
	clearBtn: {
		marginLeft: 6,
	},
	filtroSelect: {
		fontWeight: 700,
		fontSize: 12,
		'&:before': {
			borderBottom: 'none !important',
		},
		'&:after': {
			borderBottom: 'none !important',
		},
		'& .MuiSelect-select': {
			padding: '2px 10px !important',
			marginRight: 10,
		},
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
		height: 16,
		maxWidth: 130,
		justifyContent: 'flex-start',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'& .MuiChip-label': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: 'inline-block',
		},
	},
	menuItem: {
		flexDirection: 'column',
	},
}));

const formasPagamentos = [
	{ slug: 'boleto', name: 'Boleto' },
	{ slug: 'debito', name: 'Débito' },
	{ slug: 'creditoVista', name: 'Crédito à Vista' },
	{ slug: 'creditoParcelado', name: 'Crédito Parcelado' },
	{ slug: 'pix', name: 'Pix' },
];

const FormaPagamento = props => {
	const classes = useStyles();

	let value = Isset(() => props.multiple) && props.multiple ? [] : '';
	if (Isset(() => props.defaultValue) && props.defaultValue) {
		value = props.defaultValue;
	}

	const [formaPagamento, setFormaPagamento] = useState(value);

	const formaPagamentoChange = e => {
		let value = e.target.value;
		if (Array.isArray(value)) {
			value = value.map(a => `${a}`).filter((v, i, self) => self.indexOf(v) === i);
		}
		setFormaPagamento(value);
	};

	const formaPagamentoClose = e => {
		if (props.onChange) {
			props.onChange(formaPagamento);
		}
	};

	const formaPagamentoClear = e => {
		const isMultiple = Isset(() => props.multiple) ? props.multiple : false;
		e.stopPropagation();
		const value = isMultiple ? [] : '';
		setFormaPagamento(value);
		if (props.onChange) {
			props.onChange(value);
		}
	};

	return (
		<div className={classes.formaPagamento}>
			{formasPagamentos && (
				<div
					className={clsx({
						[classes.filtroText]: true,
						[classes.filtroTextSelectedMultiple]: Array.isArray(formaPagamento)
							? formaPagamento.length > 0
							: formaPagamento,
					})}
				>
					<Select
						multiple={props.multiple || false}
						className={classes.filtroSelect}
						value={formaPagamento}
						onChange={formaPagamentoChange}
						onClose={formaPagamentoClose}
						displayEmpty
						renderValue={selected => {
							if (!selected || selected.length === 0) {
								return <em>Forma de Pagamento</em>;
							}

							const aux = formasPagamentos.filter(a => selected.includes(a.slug)).map(a => a.name);
							return (
								<div className={classes.chips}>
									{aux.map((value, i) => (
										<Chip key={`${value}${i}`} label={value} className={classes.chip} />
									))}
								</div>
							);
						}}
					>
						<MenuItem disabled value="">
							<em>Forma de Pagamento</em>
						</MenuItem>
						{formasPagamentos.map((formaPagamento, i) => (
							<MenuItem key={i} value={formaPagamento.slug} className={classes.menuItem}>
								{formaPagamento.name}
							</MenuItem>
						))}
					</Select>
					{formaPagamento && <FaTimes className={classes.clearBtn} onClick={formaPagamentoClear} />}
				</div>
			)}
		</div>
	);
};

export default FormaPagamento;
