/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
// import { useSelector, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';
import { useLocation } from 'react-router-dom';
// import { Isset } from '../../utils';
import { makeStyles } from '@material-ui/core/styles';
import PreCadastrar from '../Estabelecimentos/PreCadastrar';

const useStyles = makeStyles(theme => ({
	indicador: {
		padding: 10,
		height: 120,
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	indicadorTitle: {
		fontVariant: 'small-caps',
		fontSize: 16,
		fontWeight: 'bold',
	},
	indicadorValue: {
		fontSize: 36,
		fontWeight: 'bold',
	},
	lista: {
		padding: 10,
		overflow: 'hidden',
		height: 350,
		textAlign: 'center',
	},
	listaTitle: {
		fontSize: 16,
		fontWeight: 'bold',
	},
	listaHeader: {
		marginTop: 10,
		fontWeight: 'bold',
		borderBottom: 'solid 1px #abc',
	},
	listagemWrapper: {
		marginTop: 10,
		width: '200%',
		minHeight: 280,
		overflow: 'hidden',
		position: 'relative',
	},
	scrollY: {
		height: 280,
		overflowY: 'scroll',
	},
	scrollY2: {
		height: 250,
		overflowY: 'scroll',
	},
	listagem: {
		position: 'absolute',
		top: 30,
		left: '-100%',
		width: '50%',
		textAlign: 'center',
		transition: theme.transitions.create(['left'], {
			duration: theme.transitions.duration.complex,
		}),
	},
	listaRow: {
		padding: 4,
		'&:not(:last-child)': {
			borderBottom: 'solid 1px #abc',
		},
	},
	active: {
		left: 0,
	},
	typeButton: {
		padding: 10,
		fontSize: 16,
		fontWeight: 'bold',
		cursor: 'pointer',
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
	},
	buttonActive: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	mesReferencia: {
		padding: 5,
		fontVariant: 'small-caps',
	},
	mesReferenciaAtual: {
		fontWeight: 'bold',
		fontSize: 16,
		cursor: 'pointer',
	},
	empty: {
		textAlign: 'center',
		fontSize: 18,
		fontWeight: 'bold',
	},
}));

const DashboardComercial = props => {
	const classes = useStyles();
	// const dispatch = useDispatch();

	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const token = useSelector(store => store.auth.token);
	const usuarioId = useSelector(store => store.auth.usuario.id);
	// const viewMarkup = useSelector(store =>
	// 	Isset(() => store.system.params['12'].valor) ? store.system.params['12'].valor : true
	// );

	// const inputMes = useRef(null);

	// const [ano, setAno] = useState(moment().format('YYYY'));
	// const [mes, setMes] = useState(moment().format('MM'));
	const [setLoading] = useState(true);
	const [setData] = useState(true);
	// const [typeSelected, setTypeSelected] = useState(1);
	// const [mesReferencia, setMesReferencia] = useState(`${mes} / ${ano}`);
	// const [mesReferenciaAtivo, setMesReferenciaAtivo] = useState(false);

	const [dataReferencia] = useState({
		start: new Date(),
		end: new Date(),
		label: 'Clique para selecionar a data',
		...filtersCache,
	});

	const getComissoes = async () => {
		setLoading(true);
		const res = await axios.get(
			`${config.get('apiUrl')}vendas/porRepresentante/${usuarioId}?start=${moment(dataReferencia.start).format(
				'YYYY-MM-DD'
			)}&end=${moment(dataReferencia.end).format('YYYY-MM-DD')}`,
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			setData(res.data.result);
		}
		setLoading(false);
	};

	useEffect(() => {
		getComissoes();
	}, [dataReferencia.start, dataReferencia.end]);

	// const onChangeMesReferencia = e => {
	// 	let { value } = e.target;
	// 	value = Mask.OnlyNumber(value);

	// 	const m = value.substr(0, 2);
	// 	const a = value.substr(2, 4);

	// 	if (a) {
	// 		setMesReferencia(`${m} / ${a}`);
	// 	} else {
	// 		setMesReferencia(`${m}`);
	// 	}
	// };

	// const onBlurMesReferencia = () => {
	// 	const value = Mask.OnlyNumber(mesReferencia);

	// 	const m = value.substr(0, 2);
	// 	const a = value.substr(2, 4);

	// 	if (a > 2010 && a < 3000 && m > 0 && m <= 12) {
	// 		setMes(m);
	// 		setAno(a);
	// 		setMesReferenciaAtivo(false);
	// 	}
	// };

	return (
		<div className={classes.dashboard}>
			<div>
				<PreCadastrar />
			</div>
		</div>
	);
};

export default DashboardComercial;
