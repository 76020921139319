/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Check } from '@material-ui/icons';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { makeStyles } from '@material-ui/styles';
import { Formatter } from '../../utils';
import { Button, Collapse, Grid, Paper, Tooltip } from '@material-ui/core';

const getFormaDePagamento = formaDePagamento => {
	switch (formaDePagamento) {
		case 'boleto':
			return 'Boleto';
		case 'credit':
			return 'Crédito';
		case 'debit':
			return 'Débito';
		default:
			return null;
	}
};

const useStyles = makeStyles(theme => ({
	plano: {
		position: 'relative',
		padding: 10,
	},
	title: {
		fontWeight: 'bold',
		fontSize: 16,
		cursor: 'pointer',
		':hover': {
			fontSize: 18,
		},
	},
	planoName: {
		maxWidth: 'calc(100% - 70px)',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflowX: 'hidden',
	},
	selected: {
		position: 'absolute',
		right: 10,
		top: 10,
		color: theme.palette.primary.main,
	},
	chevron: {
		position: 'absolute',
		right: 10,
		top: 10,
	},
	brandTitle: {
		textAlign: 'center',
		fontWeight: 700,
	},
	taxa: {
		position: 'relative',
		cursor: 'pointer',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		marginTop: 5,
		borderRadius: 5,
		padding: 6,
		fontWeight: 700,
	},
	taxaDetalhes: {
		width: '98%',
		borderWidth: 1,
		borderColor: theme.palette.primary.main,
		borderStyle: 'solid',
		borderRadius: 5,
		padding: 6,
		margin: 'auto',
		marginTop: -5,
		paddingTop: 10,
	},
	selecionarBtn: {
		position: 'absolute',
		fontSize: 10,
		right: 10,
		top: 5,
	},
}));

const PlanoDeVenda = props => {
	const classes = useStyles();

	const { plano, ativo, visible } = props;
	const [active] = useState(null);
	return (
		<Paper className={classes.plano}>
			{ativo ? (
				<Check className={classes.selected} />
			) : (
				<Button
					disabled={visible === 1 ? true : false}
					onClick={() => props.onSelect(plano.id)}
					className={classes.selecionarBtn}
				>
					Selecionar
				</Button>
			)}

			<div className={classes.title} onClick={() => props.onView(plano.id)}>
				<Tooltip title={<div style={{ fontSize: 14 }}>{plano.name}</div>}>
					<div className={classes.planoName}>{plano.name}</div>
				</Tooltip>
			</div>

			<div className={classes.taxas}>
				{Object.keys(plano.fees).map(feeKey => (
					<React.Fragment key={feeKey}>
						<div className={classes.taxa}>
							{getFormaDePagamento(feeKey)}
							<div className={classes.chevron}>
								{active === feeKey ? <FaChevronUp /> : <FaChevronDown />}
							</div>
						</div>

						<Collapse in={active === feeKey}>
							<div className={classes.taxaDetalhes}>
								{Object.keys(plano.fees[feeKey]).map(brand => {
									if (Object.keys(plano.fees[feeKey][brand]).length === 0) {
										return null;
									}

									const installments = plano.fees[feeKey][brand];

									return (
										<div key={brand}>
											{feeKey !== 'boleto' && (
												<div className={classes.brandTitle}>
													{brand === 'others' ? 'Outras Bandeiras' : brand}
												</div>
											)}

											{Object.keys(installments).map(i => {
												const installment = installments[i];

												return (
													<Grid key={`${brand}-${i}`} container>
														<Grid xs={6} item style={{ textAlign: 'center' }}>
															{i}x
														</Grid>
														<Grid xs={6} item style={{ textAlign: 'center' }}>
															{feeKey === 'boleto'
																? Formatter.Real(installment.fee / 100)
																: `${(installment.fee / 100).toFixed(2)}%`}
														</Grid>
													</Grid>
												);
											})}
										</div>
									);
								})}
							</div>
						</Collapse>
					</React.Fragment>
				))}
			</div>
		</Paper>
	);
};

export default PlanoDeVenda;
