/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Calendar } from 'react-date-range';
import { pt } from 'react-date-range/src/locale/index';
import { makeStyles } from '@material-ui/core/styles';
import { Grow, Paper, TextField } from '@material-ui/core';
import { format } from 'date-fns';
import useWindowSize from '@rooks/use-window-size';

const useStyles = makeStyles(theme => ({
	fullWidth: {
		width: '100%',
	},
	zDateRangePickerDiv: {
		position: 'relative',
		width: '100%',
		'&.margin': {
			margin: '0px 10px',
		},
	},
	selectedDate: {
		fontFamily: "'Exo 2', sans-serif",
		padding: 8,
		borderRadius: '20px',
		background: theme.palette.primary.main,
		color: 'white',
		cursor: 'pointer',
	},
	open: {
		position: 'absolute',
		top: 40,
		borderRadius: 10,
		padding: 10,
		zIndex: 1201,
		'&.right': {
			right: 0,
		},
	},
	closed: {
		display: 'none',
	},
	modal: {
		display: 'flex',
		'& .right': {
			right: 'initial',
		},
		'& .paperDatePicker': {
			position: 'fixed!important',
			left: 'calc(50% - 180px)',
			top: 'calc(50% - 180px)',
		},
	},
	backdrop: {
		position: 'fixed',
		zIndex: 1200,
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: 'rgba(0,0,0,0.3)',
	},
}));

const ZDatePicker = props => {
	const [date, setDate] = useState(props.date || new Date());
	const [open, setOpen] = useState(false);
	const [right, setRight] = useState(false);
	const [minDate, setMinDate] = useState(props.minDate || undefined);
	const [maxDate] = useState(props.maxDate || undefined);
	const calendarRef = useRef();
	const { innerWidth: windowWidth } = useWindowSize();

	const classes = useStyles();

	const handleSelect = date => {
		setDate(date);

		if (props.onChange) {
			props.onChange(date);
		}
	};

	const handleOpen = () => {
		setOpen(!open);
	};

	const handleBackdropClick = () => {
		setOpen(false);

		if (props.onClose) {
			props.onClose(date);
		}
	};

	useEffect(() => {
		if (props.date) {
			setDate(props.date);
		}
	}, [props.date]);

	useEffect(() => {
		if (props.minDate) {
			setMinDate(props.minDate);
		}
	}, [props.minDate]);

	useEffect(() => {
		if (calendarRef.current) {
			const { width, left } = calendarRef.current.getBoundingClientRect();
			if (width + left >= windowWidth) {
				setRight(true);
			}
		}
	}, [calendarRef.current]);

	return (
		<div className={clsx(classes.zDateRangePickerDiv, { margin: !props.textField, [classes.modal]: props.modal })}>
			{open && <div className={classes.backdrop} onClick={handleBackdropClick}></div>}

			{props.textField ? (
				<TextField
					className={classes.fullWidth}
					onClick={handleOpen}
					error={props.error || false}
					label={props.label || ' '}
					disabled={props.disabled || false}
					value={`${format(date, 'DD/MM/YYYY')}`}
				/>
			) : (
				<div className={classes.selectedDate} onClick={handleOpen}>
					{`${format(date, 'DD/MM/YYYY')}`}
				</div>
			)}
			<Grow in={open}>
				<Paper ref={calendarRef} className={clsx(classes.open, { right, paperDatePicker: true })}>
					<div>
						<Calendar
							locale={pt}
							onChange={handleSelect}
							dateDisplayFormat={'DD/MM/YYYY'}
							date={date}
							minDate={minDate}
							maxDate={maxDate}
						/>
					</div>
				</Paper>
			</Grow>
		</div>
	);
};

export default ZDatePicker;
