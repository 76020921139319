/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { green, red, yellow } from '@material-ui/core/colors';
import axios from 'axios';
import config from 'react-global-configuration';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { Formatter, Isset } from '../../../utils';
import { Button, Grid, Paper, Tooltip } from '@material-ui/core';
import { FaCheckCircle, FaTimesCircle, FaExclamationCircle, FaInfoCircle } from 'react-icons/fa';
import { ZLoader } from '../../system';
import { useStyles } from './styles/UltimasTransferenciasStyles';

const getTransferenciaIcon = statusId => {
	switch (+statusId) {
		case 3:
			return <FaCheckCircle style={{ color: green[500] }} />;
		case 4:
			return <FaTimesCircle style={{ color: red[700] }} />;
		case 1:
		case 2:
		default:
			return <FaExclamationCircle style={{ color: yellow[700] }} />;
	}
};

export default memo(props => {
	const { history } = useReactRouter();

	const classes = useStyles();
	const token = useSelector(store => store.auth.token);

	const [loading, setLoading] = useState(true);
	const [ultimasTransferencias, setUltimasTransferencias] = useState([]);

	useEffect(() => {
		const getUltimasTransferencias = async () => {
			setLoading(true);
			const transferencias = await axios.get(`${config.get('apiUrl')}dashboard/transferencias`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					limit: 20,
					filters: {
						estabelecimentoId: props.estabelecimentoId,
					},
				},
			});

			if (transferencias.data.success) {
				const result = [];
				let lastDate = null;
				transferencias.data.transferencias.forEach(v => {
					const date = format(v.created, 'YYYY-MM-DD');
					if (lastDate !== date) {
						lastDate = date;
						result.push({ type: 'date', date });
					}
					let para = '';
					if (Isset(() => v.ToEstabelecimento.nome_fantasia)) {
						para = v.ToEstabelecimento.nome_fantasia;
					} else if (Isset(() => v.conta_bancaria.nome_titular)) {
						para = v.conta_bancaria.nome_titular;
					}
					result.push({
						type: 'row',
						id: v.id,
						created: v.created,
						descricao: v.descricao,
						statusId: v.status_transferencia_id,
						valor: v.valor,
						de: v.FromEstabelecimento.nome_fantasia,
						status: v.status_transferencia.titulo,
						para,
					});
				});
				setUltimasTransferencias(result);
			}
			setLoading(false);
		};

		getUltimasTransferencias();
	}, [props.estabelecimentoId]);

	const viewTransferencia = transferenciaId => history.push(`/transferencias/detalhes/${transferenciaId}`);

	return (
		<Paper className={clsx(classes.box)}>
			<div className={classes.boxTitle}>Últimas Transferências</div>
			{loading ? (
				<ZLoader height={150} size={50} />
			) : (
				<>
					{ultimasTransferencias.length > 0 ? (
						<Grid container className={classes.ultimasTransferencias}>
							{ultimasTransferencias.map((transferencia, key) => (
								<React.Fragment key={key}>
									{transferencia.type === 'date' ? (
										<Grid container item className={classes.tableDateRow}>
											<Grid item xs>
												{format(transferencia.date, 'DD [de] MMMM [de] YYYY', {
													locale: pt,
												})}
											</Grid>
										</Grid>
									) : (
										<Grid
											container
											item
											className={classes.tableRow}
											onClick={() => viewTransferencia(transferencia.id)}
										>
											<Grid item xs={2}>
												{format(transferencia.created, 'HH:mm', { locale: pt })}
											</Grid>
											<Grid item xs={6} className={classes.transferenciaPara}>
												{transferencia.para}
											</Grid>
											<Grid item xs={3}>
												{Formatter.Real(transferencia.valor)}
											</Grid>
											<Grid item xs={1} style={{ display: 'flex' }}>
												<Tooltip
													title={transferencia.status}
													placement="bottom"
													style={{ marginRight: 4 }}
												>
													<div>{getTransferenciaIcon(transferencia.statusId)}</div>
												</Tooltip>
												{transferencia.descricao && (
													<Tooltip title={transferencia.descricao} placement="bottom">
														<div>
															<FaInfoCircle />
														</div>
													</Tooltip>
												)}
											</Grid>
										</Grid>
									)}
								</React.Fragment>
							))}
						</Grid>
					) : (
						<Grid
							container
							direction="row"
							justifyContent="center"
							alignItems="center"
							style={{ height: '300px' }}
						>
							<Grid item lg={6}>
								<h3>Nenhuma Transferência Encontrada.</h3>
							</Grid>
						</Grid>
					)}
				</>
			)}
			<div className={classes.ultimasTransferenciasFooter} onClick={() => history.push('/transferencias')}>
				<Button size="small">Ver todas as transferências</Button>
			</div>
		</Paper>
	);
});
