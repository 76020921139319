/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Paper,
} from '@material-ui/core';
import {
	FaChartBar,
	FaFilter,
	FaCreditCard,
	FaTags,
	FaColumns,
	FaCheck,
	FaGlobeAmericas,
	FaPercentage,
	FaTicketAlt,
	FaChartLine,
} from 'react-icons/fa';
import { green, red, yellow } from '@material-ui/core/colors/';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import clsx from 'clsx';
import { ZResume, ZFiltros, ZLoader, ZResumeOptimal, ZTitle } from '../system';
import axios from 'axios';
import config from 'react-global-configuration';
import { Mask } from '../../utils';
import { Isset, Formatter } from '../../utils/';

const useStyles = makeStyles(theme => ({
	label: {
		textTransform: 'uppercase',
		fontWeight: 700,
		textAlign: 'center',
	},
	valor: {
		fontSize: 16,
		fontWeight: 700,
	},
	qtdVendas: {
		fontSize: 28,
	},
	vendas: {
		width: '100%',
		flexGrow: 1,
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	filtros: {
		position: 'relative',
		borderRadius: 20,
		marginBottom: 30,
		border: `solid 1px ${theme.palette.primary.main}67`,
		padding: '5px 10px',
		display: 'flex',
		alignItems: 'center',
	},
	export: {
		position: 'absolute',
		right: 30,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	exportHistory: {
		position: 'absolute',
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#49bb57',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 18,
	},
	tableDateRow: {
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		marginTop: 16,
		borderRadius: 10,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	tableRow: {
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	rowTitle: {
		margin: 0,
		textAlign: 'center',
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			textAlign: 'left',
			marginTop: 24,
		},
	},
	rowText: {
		display: 'inline-block',
		fontWeight: 400,
		marginTop: 8,
	},
	truncate: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		alignItems: 'center',
	},
	hideOnXs: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	dataHoraXs: {
		[theme.breakpoints.down('xs')]: {
			fontSize: 10,
		},
	},
	paymentIcon: {
		fontSize: 20,
	},
	valorAprovado: {
		color: '#4caf50',
	},
	valorPendente: {
		color: '#ffc107',
	},
	valorCancelado: {
		color: '#f44336',
	},
}));

const IndicadoresVenda = props => {
	const classes = useStyles();
	const query = queryString.parse(useLocation().search);
	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');
	const axiosCancelSource = axios.CancelToken.source();

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const usuario = useSelector(store => store.auth.usuario);

	const token = useSelector(store => store.auth.token);
	// const localFilters = useSelector(store => store.system.filters[location.pathname] || {});
	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};
	const agruparTaxas = useSelector(store =>
		Isset(() => store.system.params['1'].valor) ? store.system.params['1'].valor : null
	);
	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);
	const queryFilter = {
		data_venda: { de: new Date().setHours(0, 0, 0, 0), ate: new Date() },
		estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
		status: [],
		omni: '',
		omni2: '',
		omni3: '',
		pedido_venda: '',
		...filtersCache,
	};
	const [arrTotalBruto, setDataArrTotalBruto] = useState({
		arrTotalBruto: [],
	});

	const [arrTotalBrutoOnline, setDataArrTotalBrutoOnline] = useState({
		arrTotalBrutoOnline: [],
	});

	const [arrTotalQuantidade, setDataArrTotalQuantidade] = useState({
		arrTotalQuantidade: [],
	});

	const [arrTotalQuantidadeOnline, setDataArrTotalQuantidadeOnline] = useState({
		arrTotalQuantidadeOnline: [],
	});

	const [arrBoleto, setDataArrBoleto] = useState({
		arrBoleto: [],
	});

	const [arrCredit, setDataArrCredit] = useState({
		arrCredit: [],
	});

	const [arrDebit, setDataArrDebit] = useState({
		arrDebit: [],
	});

	const [arrParcela, setDataArrParcela] = useState({
		arrParcela: [],
	});

	const [tMarkup, setDataTMarkup] = useState(Formatter.Real(0));
	const [tMarkupOnline, setDataTMarkupOnline] = useState(Formatter.Real(0));
	const [tTaxa, setDataTTaxa] = useState(Formatter.Real(0));
	const [tLiquido, setDataTLiquido] = useState(Formatter.Real(0));
	const [tTotalVendas, setDataTTotalVendas] = useState(Formatter.Real(0));
	const [tTotalVendasOnline, setDataTTotalVendasOnline] = useState(0);
	const [eEstab, setEEstab] = useState([]);
	const [eParceiros, setEParceiros] = useState([]);
	const [open, setOpen] = React.useState(false);
	const [dialogTitle, setDialogTitle] = React.useState('');
	const [dialogText, setDialogText] = React.useState('');
	const [loading, setLoading] = useState(true);

	const [payData, setPayData] = useState();
	const [check1, setCheck1] = useState(true);
	const [check2, setCheck2] = useState(true);
	const [qtyFlag, setQtyFlag] = useState([]);
	const [qtyDebit, setQtyDebit] = useState([]);

	let obj = [];
	const checkFlag = (flag, bruteValue, qty, markup, grpParcela, tipoPagamento) => {
		setQtyFlag(prev => [...prev, { flag, bruteValue, qty, markup, grpParcela, tipoPagamento }]);
	};
	const checkDebit = (flag, bruteValue, qty, markup, grpParcela, tipoPagamento) => {
		setQtyDebit(prev => [...prev, { flag, bruteValue, qty, markup, grpParcela, tipoPagamento }]);
	};

	let lastGroup = [];
	let middleGroup = [];
	let firstGroup = [];
	let debit = [];

	lastGroup = qtyFlag.filter(i => i.grpParcela === 712);
	middleGroup = qtyFlag.filter(i => i.grpParcela === 26);
	firstGroup = qtyFlag.filter(i => i.grpParcela === 1 && i.tipoPagamento === 'credito');
	debit = qtyDebit.filter(i => i.tipoPagamento === 'debito');
	const finalReducer = arr => {
		return arr.reduce(
			(results, current) => ({
				...results,
				[current.flag]: {
					qty: current.qty / 2 + (results[current.flag] ? results[current.flag].qty : 0),
					mkp: (current.markup / current.bruteValue) * 100,
					total: current.markup / 2 + (results[current.flag] ? results[current.flag].total : 0),
					tkt: current.bruteValue / current.qty,
				},
			}),
			{}
		);
	};

	const debitoReducer = arr => {
		return arr.reduce(
			(results, current) => ({
				...results,
				[current.flag]: {
					bruteValue: current.bruteValue / 2 + (results[current.flag] ? results[current.flag].bruteValue : 0),
				},
			}),
			{}
		);
	};
	let first = finalReducer(firstGroup);
	let second = finalReducer(middleGroup);
	let last = finalReducer(lastGroup);
	let debitoArr = debitoReducer(debit);
	obj.push(first, second, last, debitoArr);

	let newArray = [];
	let uniqueprojects = {};
	for (let i = 0; i < qtyFlag.length; i++) {
		if (!uniqueprojects[qtyFlag[i].flag]) {
			uniqueprojects[qtyFlag[i].flag] = 0;
		}
		uniqueprojects[qtyFlag[i].flag] += qtyFlag[i].bruteValue;
	}
	newArray = Object.keys(uniqueprojects).map(function(key) {
		return { flag: key, bruteValue: uniqueprojects[key].toFixed(2) / 2 };
	});
	let otherArray = [];
	let uniqueMarkup = {};
	for (let i = 0; i < qtyFlag.length; i++) {
		if (!uniqueMarkup[qtyFlag[i].flag]) {
			uniqueMarkup[qtyFlag[i].flag] = 0;
		}
		uniqueMarkup[qtyFlag[i].flag] += qtyFlag[i].markup;
	}
	otherArray = Object.keys(uniqueMarkup).map(function(key) {
		return { flag: key, markup: uniqueMarkup[key].toFixed(2) / 2 };
	});
	let finalArr = [];
	finalArr = qtyFlag.concat(qtyDebit);
	let tra = [];
	let uniqueTotal = {};
	for (let i = 0; i < finalArr.length; i++) {
		if (!uniqueTotal[finalArr[i].flag]) {
			uniqueTotal[finalArr[i].flag] = 0;
		}
		uniqueTotal[finalArr[i].flag] += finalArr[i].bruteValue;
	}
	tra = Object.keys(uniqueTotal).map(function(key) {
		return { flag: key, bruteValue: uniqueTotal[key].toFixed(2) / 2 };
	});
	const getData = async useOld => {
		setLoading(true);

		let resEstatisticas;

		if (useOld) {
			resEstatisticas = payData;
		} else {
			resEstatisticas = await axios.get(`${config.get('apiUrl')}vendas/estatisticas`, {
				headers: { Authorization: `Bearer ${token}` },
				params: {
					filters: {
						estabelecimentoId,
						...filtros,
					},
				},
			});
			if (resEstatisticas.data.success) setPayData(resEstatisticas);
		}

		// console.log(resEstatisticas.data)
		if (resEstatisticas.data.success) {
			// console.log(resEstatisticas.data.estabelecimentos)
			if (resEstatisticas.data.estabelecimentos) setEEstab(resEstatisticas.data.estabelecimentos);
			else setEEstab([]);
			if (resEstatisticas.data.parceiros) setEParceiros(resEstatisticas.data.parceiros);
			else setEParceiros([]);
			const DECIMAL_DIGITS = 2;
			const arrStat = resEstatisticas.data.estatisticas;
			const arrTotalBruto = new Array(11).fill(0);
			const arrTotalBrutoOnline = new Array(11).fill(0);
			const arrTotalQuantidade = new Array(11).fill(0);
			const arrTotalQuantidadeOnline = new Array(11).fill(0);

			const arrBoleto = new Array(11).fill(0); //0: aprovado, 1: quantidade, 2: markup, 3: porcentagem, 4: markup médio
			const arrCredit = new Array(11).fill(0); //0: aprovado, 1: quantidade, 2: markup, 3: porcentagem, 4: markup médio
			const arrDebit = new Array(11).fill(0); //0: aprovado, 1: quantidade, 2: markup, 3: porcentagem, 4: markup médio
			let tLiquido = 0;
			let tTaxa = 0;
			let tMarkup = 0;
			let tMarkupOnline = 0;
			const arrParcela = new Array(12).fill(0); //0: 1x bruto, 1: 1x markup, 2: 2-6x bruto, 3: 2-6x markup, 4: 7-12x bruto, 5: 7-12x markup,
			// 6: 1x qnt, 7: 2-6x qnt, 8: 7-12x qnt, 9: 1x qnt digital, 10: 2-6x qnt digital, 11: 7-12x qnt digital

			arrStat.forEach(obj => {
				if (!check1 && obj.marketplace_id === 0) return;
				if (!check2 && obj.marketplace_id === 8) return;
				arrTotalBruto[+obj.status_pedido_id] += +obj.valor_bruto;
				arrTotalBrutoOnline[+obj.status_pedido_id] += +obj.valor_bruto_digital;
				arrTotalQuantidadeOnline[+obj.status_pedido_id] += +obj.quantidade_digital;
				arrTotalQuantidade[+obj.status_pedido_id] += +obj.quantidade;
				if (obj.status_pedido_id === 2) {
					tLiquido += +obj.valor_liquido;
					tTaxa += +obj.valor_bruto - +obj.valor_liquido;
					if (obj.tipo_pagamento === 'boleto') tMarkup += +obj.quantidade * 1;
					// 1 real por boleto
					else {
						tMarkupOnline += +obj.markup_digital;
						tMarkup += +obj.markup;
					}

					switch (obj.tipo_pagamento) {
						case 'boleto':
							arrBoleto[0] += +obj.valor_bruto;
							arrBoleto[1] += +obj.quantidade;
							arrBoleto[2] += +obj.quantidade * 1;

							break;
						case 'credit':
							arrCredit[0] += +obj.valor_bruto;
							arrCredit[1] += +obj.quantidade;
							arrCredit[2] += +obj.markup;
							if (obj.grp_parcela === 1) {
								arrParcela[0] += +obj.valor_bruto;
								arrParcela[1] += +obj.markup;
								arrParcela[6] += +obj.quantidade;
								arrParcela[9] += +obj.quantidade_digital;
								checkFlag(
									obj.bandeira,
									+obj.valor_bruto,
									+obj.quantidade,
									+obj.markup,
									obj.grp_parcela,
									'credito'
								);
							} else if (obj.grp_parcela === 26) {
								arrParcela[2] += +obj.valor_bruto;
								arrParcela[3] += +obj.markup;
								arrParcela[7] += +obj.quantidade;
								arrParcela[10] += +obj.quantidade_digital;
								checkFlag(
									obj.bandeira,
									+obj.valor_bruto,
									+obj.quantidade,
									+obj.markup,
									obj.grp_parcela,
									'credito'
								);
							} else if (obj.grp_parcela === 712) {
								arrParcela[4] += +obj.valor_bruto;
								arrParcela[5] += +obj.markup;
								arrParcela[8] += +obj.quantidade;
								arrParcela[11] += +obj.quantidade_digital;
								checkFlag(
									obj.bandeira,
									+obj.valor_bruto,
									+obj.quantidade,
									+obj.markup,
									obj.grp_parcela,
									'credito'
								);
							}
							break;
						case 'debit':
							arrDebit[0] += +obj.valor_bruto;
							arrDebit[1] += +obj.quantidade;
							arrDebit[2] += +obj.markup;
							checkDebit(
								obj.bandeira,
								+obj.valor_bruto,
								+obj.quantidade,
								+obj.markup,
								obj.grp_parcela,
								'debito'
							);
							break;
						default:
							break;
					}
				}
			});
			arrBoleto[3] = (arrBoleto[2] / arrBoleto[0]) * 100;
			arrCredit[3] = (arrCredit[2] / arrCredit[0]) * 100;
			arrDebit[3] = (arrDebit[2] / arrDebit[0]) * 100;

			arrBoleto[4] = arrBoleto[2] / arrBoleto[1];
			arrCredit[4] = arrCredit[2] / arrCredit[1];
			arrDebit[4] = arrDebit[2] / arrDebit[1];

			for (var i = 0; i < 11; i++) {
				arrTotalBruto[i] = arrTotalBruto[i].toFixed(DECIMAL_DIGITS);
				arrTotalBrutoOnline[i] = arrTotalBrutoOnline[i].toFixed(DECIMAL_DIGITS);
				arrBoleto[i] = arrBoleto[i].toFixed(DECIMAL_DIGITS);
				arrCredit[i] = arrCredit[i].toFixed(DECIMAL_DIGITS);
				arrDebit[i] = arrDebit[i].toFixed(DECIMAL_DIGITS);
				arrParcela[i] = arrParcela[i].toFixed(DECIMAL_DIGITS);
			}
			let tTotalVendas = arrTotalQuantidade.reduce((a, b) => a + b, 0);
			let tTotalVendasOnline = arrTotalQuantidadeOnline.reduce((a, b) => a + b, 0);

			setDataArrParcela(arrParcela);
			setDataArrTotalBruto(arrTotalBruto);
			setDataArrTotalBrutoOnline(arrTotalBrutoOnline);
			setDataArrTotalQuantidade(arrTotalQuantidade);
			setDataArrTotalQuantidadeOnline(arrTotalQuantidadeOnline);
			setDataArrBoleto(arrBoleto);
			setDataArrCredit(arrCredit);
			setDataArrDebit(arrDebit);
			setDataTMarkup(tMarkup);
			setDataTMarkupOnline(tMarkupOnline);
			setDataTTaxa(tTaxa);
			setDataTLiquido(tLiquido);
			setDataTTotalVendas(tTotalVendas);
			setDataTTotalVendasOnline(tTotalVendasOnline);
			setLoading(false);
		}
	};
	const [filtros, setFiltros] = useState({
		...queryFilter,
		omni2: Isset(() => query.estabelecimentoNome) ? query.estabelecimentoNome : queryFilter.omni2,
		data_venda: {
			de: Isset(() => query.startDate) ? query.startDate : queryFilter.data_venda.de,
			ate: Isset(() => query.endDate) ? query.endDate : queryFilter.data_venda.ate,
		},
	});
	useEffect(() => {
		Isset(() => props.loading) && setLoading(props.loading);
		getData();
		// dispatch(setFilters(location.pathname, filtros));
		// eslint-disable-next-line react-hooks/exhaustive-deps
		return async () => {
			axiosCancelSource.cancel('Indicadores Unmounted.');
		};
	}, [filtros]);
	const filtrosChange = async data => {
		arrDebit[0] = [];
		arrDebit[1] = [];
		arrDebit[2] = [];
		data.data_venda = {
			de: data.date.start,
			ate: data.date.end,
		};

		data.estabelecimentoId = [estabelecimentoId];

		if (Array.isArray(data.estabelecimento)) {
			data.estabelecimentoId = [estabelecimentoId, ...data.estabelecimento];
		}

		if (Isset(() => query.estabelecimento) && query.estabelecimento !== '') {
			data.estabelecimentoId = [query.estabelecimento];
		}
		setFiltros(data);
	};
	useEffect(() => {
		setFiltros({
			...filtros,
			estabelecimentoId: Isset(() => query.estabelecimento) ? [query.estabelecimento] : [estabelecimentoId],
		});
	}, [estabelecimentoId]);

	const handleClickOpen = (title, text) => {
		setDialogTitle(title);
		setDialogText(text);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const statusToLabel = status => {
		switch (status) {
			case 1:
				return 'Em Aprovação';
			case 2:
				return 'Aprovado';
			case 3:
				return 'Inativo';
			default:
				return 'Desabilitado';
		}
	};
	const estabToTable = obj => {
		return (
			<div>
				<Grid container spacing={1}>
					<Grid item xs={2} style={{ fontWeight: 'bold' }}>
						{' '}
						id{' '}
					</Grid>
					<Grid item xs={4} style={{ fontWeight: 'bold' }}>
						{' '}
						Nome Fantasia{' '}
					</Grid>
					<Grid item xs={4} style={{ fontWeight: 'bold' }}>
						{' '}
						Razao Social{' '}
					</Grid>
					<Grid item xs={2} style={{ fontWeight: 'bold' }}>
						{' '}
						Status / Ativo{' '}
					</Grid>
				</Grid>
				{obj.map(e => (
					<Grid container spacing={1}>
						<Grid item xs={2}>
							{e.id}
						</Grid>
						<Grid item xs={4}>
							{e.nome_fantasia}
						</Grid>
						<Grid item xs={4}>
							{e.razao_social}
						</Grid>
						<Grid item xs={2}>
							{statusToLabel(e.status_estabelecimento_id)} / {e.ativo ? 'Ativo' : 'Inativo'}
						</Grid>
					</Grid>
				))}
			</div>
		);
	};
	const expandInfos = (
		<Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="lg">
			<DialogTitle id="scroll-dialog-title">{dialogTitle}</DialogTitle>
			<DialogContent dividers={true}>
				<DialogContentText tabIndex={-1}>{dialogText}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Fechar
				</Button>
			</DialogActions>
		</Dialog>
	);
	return (
		<Grid className={classes.vendas}>
			{expandInfos}

			<ZTitle title="Indicadores de Vendas" display="flex" textAlign="center" fontSize="40px" />

			<Paper style={{ marginBottom: 10, padding: '5px 10px' }}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={8} md={8}>
						<FaFilter />
						<ZFiltros
							onChange={filtrosChange}
							dateRange={{
								default: 'custom',
								startDate: filtros.data_venda.de,
								endDate: filtros.data_venda.ate,
							}}
							omni={
								![2, 3].includes(usuario.grupo_id)
									? {
											default: filtros.omni,
											placeholder: 'Pesquisa por CPF/CNPJ',
									  }
									: false
							}
							omni2={
								![2, 3].includes(usuario.grupo_id)
									? {
											default: filtros.omni2,
											placeholder: 'Pesquisa por Estabelecimento',
									  }
									: false
							}
							omni3={
								![2, 3].includes(usuario.grupo_id)
									? {
											default: filtros.omni3,
											placeholder: 'Pesquisa por Parceiro',
									  }
									: false
							}
						/>
					</Grid>
					<Grid item xs={12} sm={4} md={4}>
						<Checkbox
							checked={check1}
							onChange={() => setCheck1(!check1)}
							name="checkedB"
							color="primary"
						/>
						ADIQ
						<Checkbox
							checked={check2}
							onChange={() => setCheck2(!check2)}
							name="checkedB"
							color="primary"
						/>
						Zoop
						<Button variant="outlined" onClick={() => getData()} color="primary" style={{ marginLeft: 8 }}>
							Recalcular
						</Button>
					</Grid>
				</Grid>
			</Paper>

			{usuario.grupo_id !== 7 && (
				<Paper style={{ padding: 15, marginBottom: 10 }}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={3} md={3}>
							<Grid xs={12} justifyContent="center" className={classes.label}>
								Quantidade de Vendas
								<Grid className={clsx(classes.valor, classes.qtdVendas)}>
									{Formatter.Valor(Isset(() => tTotalVendas) ? tTotalVendas : 0, 0, ',', '.')}
								</Grid>
							</Grid>
							{!!eEstab.length && (
								<div
									className={classes.label}
									onClick={() => handleClickOpen('Lista Estabelecimentos', estabToTable(eEstab))}
								>
									Quantidade Estabelecimentos
									<div className={clsx(classes.valor, classes.qtdVendas)}>
										{Formatter.Valor(Isset(() => eEstab) ? eEstab.length : 0, 0, ',', '.')}
									</div>
								</div>
							)}
							{!!eParceiros.length && (
								<div
									className={classes.label}
									onClick={() =>
										handleClickOpen(
											'Lista Parceiros',
											eParceiros.map(e => <div>{e}</div>)
										)
									}
								>
									Quantidade Parceiros
									<div className={clsx(classes.valor, classes.qtdVendas)}>
										{Formatter.Valor(Isset(() => eParceiros) ? eParceiros.length : 0, 0, ',', '.')}
									</div>
								</div>
							)}
						</Grid>
						<Grid item sm={3} md={3} className={classes.hideOnXs} style={{ textAlign: 'right' }}>
							<div className={classes.label}>
								{`Aprovado `}
								<span className={clsx(classes.valor, classes.valorAprovado)}>
									{Formatter.Real(Isset(() => arrTotalBruto[2]) ? arrTotalBruto[2] : 0)}
								</span>
							</div>
							<div className={classes.label}>
								{`Pendente `}
								<span className={clsx(classes.valor, classes.valorPendente)}>
									{Formatter.Real(Isset(() => arrTotalBruto[1]) ? arrTotalBruto[1] : 0)}
								</span>
							</div>
							<div className={classes.label}>
								{`Falhado `}
								<span className={clsx(classes.valor, classes.valorCancelado)}>
									{Formatter.Real(Isset(() => arrTotalBruto[3]) ? arrTotalBruto[3] : 0)}
								</span>
							</div>
							<div className={classes.label}>
								{`Cancelado `}
								<span className={clsx(classes.valor, classes.valorCancelado)}>
									{Formatter.Real(Isset(() => arrTotalBruto[4]) ? arrTotalBruto[4] : 0)}
								</span>
							</div>
							<div className={classes.label}>
								{`Estornado `}
								<span className={clsx(classes.valor, classes.valorCancelado)}>
									{Formatter.Real(Isset(() => arrTotalBruto[5]) ? arrTotalBruto[5] : 0)}
								</span>
							</div>
						</Grid>
						<Grid item sm={3} md={3} className={classes.hideOnXs} style={{ textAlign: 'right' }}>
							{agruparTaxas ? (
								<div className={classes.label}>
									{`Taxa `}
									<span className={classes.valor}>{Formatter.Real(tTaxa)}</span>
								</div>
							) : (
								<>
									<div className={classes.label}>
										{`Taxa `}
										<span className={classes.valor}>{Formatter.Real(+tTaxa)}</span>
									</div>
								</>
							)}
							<div className={classes.label}>
								{`Líquido `}
								<span className={classes.valor}>
									{Formatter.Real(Isset(() => tLiquido) ? tLiquido : 0)}
								</span>
							</div>
						</Grid>
						<Grid item sm={3} md={3} className={classes.hideOnXs} style={{ textAlign: 'right' }}>
							{[1, 10].includes(usuario.grupo_id) && (
								<>
									<div className={classes.label}>
										{`Markup `}
										<span className={classes.valor}>
											{Formatter.Real(Isset(() => tMarkup) ? +tMarkup : 0)}
										</span>
									</div>
								</>
							)}
							{usuario.grupo_id === 2 && operarMarketplace && (
								<>
									<div className={classes.label}>
										{`Markup `}
										<span className={classes.valor}>{Formatter.Real(tMarkup)}</span>
									</div>
								</>
							)}
						</Grid>
					</Grid>
				</Paper>
			)}

			{loading ? (
				<ZLoader height={200} />
			) : (
				<Grid container spacing={3} justifyContent="center">
					{![1, 10].includes(usuario.grupo_id) ? (
						<></>
					) : (
						<>
							<Grid item xs={12} sm={6} md={3}>
								<ZResume
									title="Ticket Médio"
									amountColor={green[600]}
									classe={classes.label}
									icon={FaTicketAlt}
									color={'#3F4DB5'}
									amount={Formatter.Real(+arrTotalBruto[2] / +arrTotalQuantidade[2])}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<ZResume
									title="Falhas"
									classe={classes.label}
									icon={FaPercentage}
									color={'#3F4DB5'}
									amountColor={red[600]}
									amount={Mask.Porcentagem((+arrTotalQuantidade[3] / +tTotalVendas) * 100) + '%'}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={3}>
								<ZResume
									title="Ticket Médio Online"
									classe={classes.label}
									icon={FaTicketAlt}
									color={'#3F4DB5'}
									amountColor={green[600]}
									amount={Formatter.Real(+arrTotalBrutoOnline[2] / +arrTotalQuantidadeOnline[2])}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={3}>
								<ZResume
									title="Falhas Online"
									classe={classes.label}
									amountColor={red[600]}
									icon={FaPercentage}
									color={'#3F4DB5'}
									amount={
										Mask.Porcentagem((+arrTotalQuantidadeOnline[3] / +tTotalVendasOnline) * 100) +
										'%'
									}
								/>
							</Grid>
						</>
					)}
					<Grid item xs={12} sm={6} md={4}>
						<ZResume
							title="Vendas"
							amount={Formatter.Real(arrTotalBruto.reduce((a, b) => +a + +b, 0))}
							classe={classes.label}
							icon={FaCreditCard}
							color={'#3F4DB5'}
							titulo1="Aprovado"
							desc1={Formatter.Real(arrTotalBruto[2])}
							percent1={
								Mask.Porcentagem(
									(+arrTotalBruto[2] / arrTotalBruto.reduce((a, b) => +a + +b, 0)) * 100
								) + '%'
							}
							titulo2="Pendente"
							desc2={Formatter.Real(arrTotalBruto[1])}
							percent2={
								Mask.Porcentagem(
									(+arrTotalBruto[1] / arrTotalBruto.reduce((a, b) => +a + +b, 0)) * 100
								) + '%'
							}
							titulo3="Falhado"
							desc3={Formatter.Real(arrTotalBruto[3])}
							percent3={
								Mask.Porcentagem(
									(+arrTotalBruto[3] / arrTotalBruto.reduce((a, b) => +a + +b, 0)) * 100
								) + '%'
							}
							titulo4="Cancelado"
							desc4={Formatter.Real(arrTotalBruto[4])}
							percent4={
								Mask.Porcentagem(
									(+arrTotalBruto[4] / arrTotalBruto.reduce((a, b) => +a + +b, 0)) * 100
								) + '%'
							}
							titulo5="Estornado"
							desc5={Formatter.Real(arrTotalBruto[5])}
							percent5={
								Mask.Porcentagem(
									(+arrTotalBruto[5] / arrTotalBruto.reduce((a, b) => +a + +b, 0)) * 100
								) + '%'
							}
							desc1Color={green[600]}
							desc2Color={yellow[600]}
							desc3Color={red[600]}
							desc4Color={red[600]}
							desc5Color={red[600]}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<ZResume
							title="Quantidade de Vendas"
							amount={+tTotalVendas}
							classe={classes.label}
							icon={FaTags}
							color={'#3F4DB5'}
							titulo1="Aprovado"
							percent1={Mask.Porcentagem((+arrTotalQuantidade[2] / +tTotalVendas) * 100) + '%'}
							desc1={arrTotalQuantidade[2]}
							titulo2="Pendente"
							desc2={arrTotalQuantidade[1]}
							percent2={Mask.Porcentagem((+arrTotalQuantidade[1] / +tTotalVendas) * 100) + '%'}
							titulo3="Falhado"
							desc3={arrTotalQuantidade[3]}
							percent3={Mask.Porcentagem((+arrTotalQuantidade[3] / +tTotalVendas) * 100) + '%'}
							titulo4="Cancelado"
							desc4={arrTotalQuantidade[4]}
							percent4={Mask.Porcentagem((+arrTotalQuantidade[4] / +tTotalVendas) * 100) + '%'}
							titulo5="Estornado"
							desc5={arrTotalQuantidade[5]}
							percent5={Mask.Porcentagem((+arrTotalQuantidade[5] / +tTotalVendas) * 100) + '%'}
							desc1Color={green[600]}
							desc2Color={yellow[600]}
							desc3Color={red[600]}
							desc4Color={red[600]}
							desc5Color={red[600]}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<ZResume
							title="Vendas Online"
							amount={Formatter.Real(arrTotalBrutoOnline.reduce((a, b) => +a + +b, 0))}
							classe={classes.label}
							icon={FaGlobeAmericas}
							color={'#3F4DB5'}
							titulo1="Aprovado"
							desc1={Formatter.Real(arrTotalBrutoOnline[2])}
							percent1={
								Mask.Porcentagem(
									(+arrTotalBrutoOnline[2] / arrTotalBrutoOnline.reduce((a, b) => +a + +b, 0)) * 100
								) + '%'
							}
							titulo2="Pendente"
							desc2={Formatter.Real(arrTotalBrutoOnline[1])}
							percent2={
								Mask.Porcentagem(
									(+arrTotalBrutoOnline[1] / arrTotalBrutoOnline.reduce((a, b) => +a + +b, 0)) * 100
								) + '%'
							}
							titulo3="Falhado"
							desc3={Formatter.Real(arrTotalBrutoOnline[3])}
							percent3={
								Mask.Porcentagem(
									(+arrTotalBrutoOnline[3] / arrTotalBrutoOnline.reduce((a, b) => +a + +b, 0)) * 100
								) + '%'
							}
							titulo4="Cancelado"
							desc4={Formatter.Real(arrTotalBrutoOnline[4])}
							percent4={
								Mask.Porcentagem(
									(+arrTotalBrutoOnline[4] / arrTotalBrutoOnline.reduce((a, b) => +a + +b, 0)) * 100
								) + '%'
							}
							titulo5="Estornado"
							desc5={Formatter.Real(arrTotalBrutoOnline[5])}
							percent5={
								Mask.Porcentagem(
									(+arrTotalBrutoOnline[5] / arrTotalBrutoOnline.reduce((a, b) => +a + +b, 0)) * 100
								) + '%'
							}
							desc1Color={green[600]}
							desc2Color={yellow[600]}
							desc3Color={red[600]}
							desc4Color={red[600]}
							desc5Color={red[600]}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<ZResume
							title="Qnt. Vendas Online"
							amount={+tTotalVendasOnline}
							classe={classes.label}
							icon={FaTags}
							color={'#3F4DB5'}
							titulo1="Aprovado"
							desc1={+arrTotalQuantidadeOnline[2]}
							percent1={
								Mask.Porcentagem((+arrTotalQuantidadeOnline[2] / +tTotalVendasOnline) * 100) + '%'
							}
							titulo2="Pendente"
							desc2={arrTotalQuantidadeOnline[1]}
							percent2={
								Mask.Porcentagem((+arrTotalQuantidadeOnline[1] / +tTotalVendasOnline) * 100) + '%'
							}
							titulo3="Falhado"
							desc3={arrTotalQuantidadeOnline[3]}
							percent3={
								Mask.Porcentagem((+arrTotalQuantidadeOnline[3] / +tTotalVendasOnline) * 100) + '%'
							}
							titulo4="Cancelado"
							desc4={arrTotalQuantidadeOnline[4]}
							percent4={
								Mask.Porcentagem((+arrTotalQuantidadeOnline[4] / +tTotalVendasOnline) * 100) + '%'
							}
							titulo5="Estornado"
							desc5={+arrTotalQuantidadeOnline[5]}
							percent5={
								Mask.Porcentagem((+arrTotalQuantidadeOnline[5] / +tTotalVendasOnline) * 100) + '%'
							}
							desc1Color={green[600]}
							desc2Color={yellow[600]}
							desc3Color={red[600]}
							desc4Color={red[600]}
							desc5Color={red[600]}
						/>
					</Grid>
					{![1, 10].includes(usuario.grupo_id) ? (
						<></>
					) : (
						<>
							<Grid item xs={12} sm={6} md={4}>
								<ZResume
									title="Aprovadas Proporção Online"
									amount={+arrTotalQuantidadeOnline[2] + ' de ' + arrTotalQuantidade[2]}
									classe={classes.label}
									icon={FaPercentage}
									color={'#3F4DB5'}
									titulo1="Aprovadas ($)"
									desc1={Mask.Porcentagem((+arrTotalBrutoOnline[2] / +arrTotalBruto[2]) * 100) + '%'}
									titulo2="Qntidade (#)"
									desc2={
										Mask.Porcentagem(
											(+arrTotalQuantidadeOnline[2] / +arrTotalQuantidade[2]) * 100
										) + '%'
									}
									titulo3="Markup ($)"
									desc3={Mask.Porcentagem((+tMarkupOnline / +tMarkup) * 100) + '%'}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<ZResume
									title="Parcelado Proporção Online"
									amount={
										Mask.Porcentagem(
											(+arrTotalQuantidadeOnline[2] * 100) / +arrTotalQuantidade[2]
										) + '%'
									}
									classe={classes.label}
									icon={FaPercentage}
									color={'#3F4DB5'}
									titulo1="1x (#)"
									desc1={Mask.Porcentagem((+arrParcela[9] / +arrParcela[6]) * 100) + '%'}
									titulo2="2x até 6x (#)"
									desc2={Mask.Porcentagem((+arrParcela[10] / +arrParcela[7]) * 100) + '%'}
									titulo3="7x até 12x (#)"
									desc3={Mask.Porcentagem((+arrParcela[11] / +arrParcela[8]) * 100) + '%'}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<ZResume
									title="Parcelado Crédito"
									classe={classes.label}
									icon={FaColumns}
									amountColor={green[600]}
									color={'#3F4DB5'}
									amount={Formatter.Real(+arrParcela[0] + +arrParcela[2] + +arrParcela[4])}
									titulo1="1x"
									desc1={Formatter.Real(+arrParcela[0])}
									titulo2="2x até 6x"
									desc2={Formatter.Real(+arrParcela[2])}
									titulo3="7x até 12x"
									desc3={Formatter.Real(+arrParcela[4])}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<ZResume
									title="Markup Parcelado"
									classe={classes.label}
									icon={FaChartLine}
									amountColor={green[600]}
									color={'#3F4DB5'}
									amount={Formatter.Real(+arrParcela[1] + +arrParcela[3] + +arrParcela[5])}
									titulo1="1x"
									desc1={Formatter.Real(+arrParcela[1])}
									titulo2="2x até 6x"
									desc2={Formatter.Real(+arrParcela[3])}
									titulo3="7x até 12x"
									desc3={Formatter.Real(+arrParcela[5])}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<ZResume
									title="Qnt Vendas Aprovadas"
									amount={+arrBoleto[1] + +arrCredit[1] + +arrDebit[1]}
									classe={classes.label}
									icon={FaCheck}
									color={'#3F4DB5'}
									amountColor={green[600]}
									titulo1="Boleto"
									desc1={+arrBoleto[1]}
									percent1={
										Mask.Porcentagem(
											(+arrBoleto[1] / (+arrBoleto[1] + +arrCredit[1] + +arrDebit[1])) * 100
										) + '%'
									}
									titulo2="Crédito"
									desc2={+arrCredit[1]}
									percent2={
										Mask.Porcentagem(
											(+arrCredit[1] / (+arrBoleto[1] + +arrCredit[1] + +arrDebit[1])) * 100
										) + '%'
									}
									titulo3="Débito"
									desc3={+arrDebit[1]}
									percent3={
										Mask.Porcentagem(
											(+arrDebit[1] / (+arrBoleto[1] + +arrCredit[1] + +arrDebit[1])) * 100
										) + '%'
									}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<ZResume
									title="Valor Vendas Aprovadas"
									amount={Formatter.Real(+arrBoleto[0] + +arrCredit[0] + +arrDebit[0])}
									classe={classes.label}
									icon={FaCheck}
									color={'#3F4DB5'}
									amountColor={green[600]}
									titulo1="Boleto"
									desc1={Formatter.Real(+arrBoleto[0])}
									percent1={
										Mask.Porcentagem(
											(+arrBoleto[0] / (+arrBoleto[0] + +arrCredit[0] + +arrDebit[0])) * 100
										) + '%'
									}
									titulo2="Crédito"
									desc2={Formatter.Real(+arrCredit[0])}
									percent2={
										Mask.Porcentagem(
											(+arrCredit[0] / (+arrBoleto[0] + +arrCredit[0] + +arrDebit[0])) * 100
										) + '%'
									}
									titulo3="Débito"
									desc3={Formatter.Real(+arrDebit[0])}
									percent3={
										Mask.Porcentagem(
											(+arrDebit[0] / (+arrBoleto[0] + +arrCredit[0] + +arrDebit[0])) * 100
										) + '%'
									}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<ZResume
									title="Ticket Médio Aprovadas"
									amount={Formatter.Real(
										(+arrBoleto[0] / +arrBoleto[1] > 0 ? +arrBoleto[0] / +arrBoleto[1] : 0.001) +
											+arrCredit[0] / +arrCredit[1] +
											+arrDebit[0] / +arrDebit[1]
									)}
									classe={classes.label}
									icon={FaCheck}
									color={'#3F4DB5'}
									amountColor={green[600]}
									titulo1="Boleto"
									desc1={Formatter.Real(+arrBoleto[0] / +arrBoleto[1])}
									titulo2="Crédito"
									desc2={Formatter.Real(+arrCredit[0] / +arrCredit[1])}
									titulo3="Débito"
									desc3={Formatter.Real(+arrDebit[0] / +arrDebit[1])}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<ZResume
									title="Markup Total"
									amount={Formatter.Real(+tMarkup)}
									classe={classes.label}
									amountColor={green[600]}
									icon={FaChartLine}
									color={'#3F4DB5'}
									titulo1="Boleto"
									desc1={Formatter.Real(+arrBoleto[2])}
									percent1={Mask.Porcentagem((+arrBoleto[2] / +tMarkup) * 100) + '%'}
									titulo2="Crédito"
									desc2={Formatter.Real(+arrCredit[2])}
									percent2={Mask.Porcentagem((+arrCredit[2] / +tMarkup) * 100) + '%'}
									titulo3="Débito"
									desc3={Formatter.Real(+arrDebit[2])}
									percent3={Mask.Porcentagem((+arrDebit[2] / +tMarkup) * 100) + '%'}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<ZResume
									title="Markup Médio"
									amount="-"
									classe={classes.label}
									icon={FaChartBar}
									color={'#3F4DB5'}
									titulo1="Boleto"
									desc1={Formatter.Real(+arrBoleto[4])}
									titulo2="Crédito"
									desc2={Formatter.Real(+arrCredit[4])}
									titulo3="Débito"
									desc3={Formatter.Real(+arrDebit[4])}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<ZResume
									title="Markup"
									amount={`Spread: ${Mask.Porcentagem((+tMarkup / arrTotalBruto[2]) * 100)}%`}
									classe={classes.label}
									icon={FaPercentage}
									color={'#3F4DB5'}
									titulo1="Boleto"
									desc1="-"
									titulo2="Crédito"
									desc2={Mask.Porcentagem(arrCredit[3]) + '%'}
									titulo3="Débito"
									desc3={Mask.Porcentagem(arrDebit[3]) + '%'}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<ZResume
									title="POS"
									amount="-"
									classe={classes.label}
									icon={FaPercentage}
									color={'#3F4DB5'}
									titulo1="TPV"
									desc1={Formatter.Real(+arrTotalBruto[2] - arrBoleto[0] - arrTotalBrutoOnline[2])} //{`${arrTotalBruto[2]} - ${arrBoleto[0]} - ${arrTotalBrutoOnline[2]}`}
									titulo2="Markup"
									desc2={Formatter.Real(+tMarkup - tMarkupOnline - arrBoleto[2])} //{`${tMarkup} - ${tMarkupOnline} - ${+arrBoleto[2]}`}
									titulo3="Spread"
									desc3={
										Mask.Porcentagem(
											((+tMarkup - tMarkupOnline - arrBoleto[2]) /
												(+arrTotalBruto[2] - arrBoleto[0] - arrTotalBrutoOnline[2])) *
												100
										) + '%'
									}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<ZResume
									title="Online"
									amount="-"
									classe={classes.label}
									icon={FaPercentage}
									color={'#3F4DB5'}
									titulo1="TPV"
									desc1={Formatter.Real(arrTotalBrutoOnline[2])}
									titulo2="Markup"
									desc2={Formatter.Real(+tMarkupOnline)}
									titulo3="Spread"
									desc3={Mask.Porcentagem((tMarkupOnline / arrTotalBrutoOnline[2]) * 100) + '%'}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<ZResumeOptimal
									title="Débito por Bandeira"
									amount=""
									amountColor={green[600]}
									classe={classes.label}
									icon={FaPercentage}
									color={'#3F4DB5'}
									objeto={true}
									obj={{ debitoArr }}
									key1={'bruteValue'}
									placeholder={'total'}
									show={false}
									symbol1={'R$'}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<ZResumeOptimal
									title="Crédito Bruto Por Bandeira"
									amount=""
									classe={classes.label}
									icon={FaPercentage}
									color={'#3F4DB5'}
									titulo={newArray}
									hold={'flag'}
									value={'bruteValue'}
									objeto={false}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<ZResumeOptimal
									title="Total Transicionado Por Bandeira"
									amount=""
									classe={classes.label}
									icon={FaPercentage}
									color={'#3F4DB5'}
									titulo={tra}
									hold={'flag'}
									value={'bruteValue'}
									objeto={false}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<ZResumeOptimal
									title="Markup Por Bandeira"
									amount=""
									classe={classes.label}
									icon={FaPercentage}
									color={'#3F4DB5'}
									titulo={otherArray}
									hold={'flag'}
									value={'markup'}
									objeto={false}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<ZResumeOptimal
									title="% de Markup e Qtd de Vendas por Grupo de Parcela"
									amount="1x"
									amountColor={green[600]}
									classe={classes.label}
									icon={FaPercentage}
									color={'#3F4DB5'}
									objeto={true}
									obj={{ first }}
									key1={'qty'}
									key2={'mkp'}
									symbol1={' '}
									symbol2={'%'}
									show={true}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<ZResumeOptimal
									title="% de Markup e Qtd de Vendas por Grupo de Parcela"
									amount="2x a 6x"
									classe={classes.label}
									amountColor={green[600]}
									icon={FaPercentage}
									color={'#3F4DB5'}
									objeto={true}
									obj={{ second }}
									key1={'qty'}
									key2={'mkp'}
									symbol1={''}
									symbol2={'%'}
									show={true}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<ZResumeOptimal
									title="% de Markup e Qtd de Vendas por Grupo de Parcela"
									amount="7x a 12x"
									amountColor={green[600]}
									classe={classes.label}
									icon={FaPercentage}
									color={'#3F4DB5'}
									objeto={true}
									obj={{ last }}
									key1={'qty'}
									key2={'mkp'}
									symbol1={''}
									symbol2={'%'}
									show={true}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<ZResumeOptimal
									title="Markup Total e Ticket Medio por Grupo de Parcela"
									amount="1x"
									amountColor={green[600]}
									classe={classes.label}
									icon={FaPercentage}
									color={'#3F4DB5'}
									objeto={true}
									obj={{ first }}
									key1={'tkt'}
									key2={'total'}
									symbol1={'R$'}
									symbol2={''}
									real={true}
									show={true}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<ZResumeOptimal
									title="Markup Total e Ticket Medio por Grupo de Parcela"
									amount="2x a 6x"
									amountColor={green[600]}
									classe={classes.label}
									icon={FaPercentage}
									color={'#3F4DB5'}
									objeto={true}
									obj={{ second }}
									key1={'tkt'}
									key2={'total'}
									symbol1={'R$'}
									symbol2={''}
									real={true}
									show={true}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<ZResumeOptimal
									title="Markup Total e Ticket Medio por Grupo de Parcela"
									amount="7x a 12x"
									amountColor={green[600]}
									classe={classes.label}
									icon={FaPercentage}
									color={'#3F4DB5'}
									objeto={true}
									obj={{ last }}
									key1={'tkt'}
									key2={'total'}
									symbol1={'R$'}
									symbol2={''}
									show={true}
									real={true}
								/>
							</Grid>
						</>
					)}
				</Grid>
			)}
		</Grid>
	);
};

export default IndicadoresVenda;
