import React, { useState, useEffect } from 'react';
import { ResponsivePie } from 'nivo';
import { Formatter, Isset } from '../../../utils';
import { FaCircle, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles/VendasChartStyles';
const colors = ['#ea6061', '#005797', '#ffa550'];

const VendasChart = props => {
	const classes = useStyles();
	const [showValorTotal, setShowValorTotal] = useState(props.showValorTotal || true);
	const [valorTotal, setValorTotal] = useState(props.valorTotal || 0);
	const [quantidadeTotal, setQuantidadeTotal] = useState(props.quantidadeTotal || 0);
	const [data, setData] = useState([]);
	const [legendas, setLegendas] = useState([]);

	useEffect(() => {
		setValorTotal(props.valorTotal);
	}, [props.valorTotal]);

	useEffect(() => {
		setQuantidadeTotal(props.quantidadeTotal);
	}, [props.quantidadeTotal]);

	useEffect(() => {
		if (Isset(() => props.showValorTotal)) {
			setShowValorTotal(props.showValorTotal);
		}
	}, [props.showValorTotal]);

	useEffect(() => {
		const l = [];
		const d = props.data.map((forma, i) => {
			l.push({
				color: colors[i],
				label: forma.formaPagamento,
				value: valorTotal > 0 ? (+forma.valor * 100) / valorTotal : 0,
				percent: +forma.percent,
			});
			return {
				id: forma.formaPagamento,
				label: forma.formaPagamento,
				value: showValorTotal ? +forma.valor : +forma.quantidade,
			};
		});

		setLegendas(l);
		setData(d);
	}, [props.data, showValorTotal, valorTotal]);

	return (
		<Grid item className={classes.chart}>
			{data.length > 0 && (
				<React.Fragment>
					{showValorTotal ? (
						<div className={classes.valorTotal}>{Formatter.Real(valorTotal)}</div>
					) : (
						<div className={classes.valorTotal}>{quantidadeTotal}</div>
					)}
					<ResponsivePie
						data={data}
						margin={{ top: 0, right: 0, bottom: 4, left: 0 }}
						sortByValue={true}
						innerRadius={0.5}
						padAngle={1}
						colors={colors}
						borderWidth={1}
						enableRadialLabels={false}
						enableSlicesLabels={false}
						tooltipFormat={value => (showValorTotal ? Formatter.Real(value) : value)}
						slicesLabelsSkipAngle={15}
						slicesLabelsTextColor="#333333"
						animate={true}
						motionStiffness={90}
						motionDamping={15}
						legendas={[
							{
								anchor: 'bottom',
								direction: 'column',
								justify: false,
								translateX: 0,
								translateY: 0,
								itemWidth: 100,
								itemHeight: 20,
								itemsSpacing: 4,
								symbolSize: 16,
								symbolShape: 'circle',
								itemDirection: 'left-to-right',
								itemTextColor: '#777',
								effects: [
									{
										on: 'hover',
										style: {
											itemBackground: 'rgba(0, 0, 0, .03)',
											itemOpacity: 1,
										},
									},
								],
							},
						]}
					/>
					{legendas.length > 0 && (
						<Grid item className={classes.legendas}>
							{legendas.map((legenda, key) => (
								<Grid container key={key} className={classes.legenda}>
									<Grid item xs={6}>
										<FaCircle color={legenda.color} /> {legenda.label}
									</Grid>
									<Grid
										item
										xs={3}
										style={{ textAlign: 'right', fontWeight: 700 }}
									>{`${Formatter.Real(legenda.value, false)} %`}</Grid>
									<Grid item xs={3} className={classes.percent}>
										{legenda.percent > 0 && <FaArrowUp style={{ color: 'green' }} />}
										{legenda.percent < 0 && <FaArrowDown style={{ color: 'red' }} />}
										{legenda.percent === 0 && '-'} {`${Math.abs(legenda.percent)} %`}
									</Grid>
								</Grid>
							))}
						</Grid>
					)}
				</React.Fragment>
			)}
		</Grid>
	);
};

export default VendasChart;
