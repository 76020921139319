import axios from 'axios';
import config from 'react-global-configuration';

import { Isset } from '../utils';

// Action Types

export const Types = {
	SET_CONFIG: 'system/SET_CONFIG',

	UPDATE_SALDO: 'system/UPDATE_SALDO',
	SET_COLOR: 'system/SET_COLOR',
	SET_LOGO: 'system/SET_LOGO',
	SET_URL_WEBHOOK: 'system/SET_URL_WEBHOOK',

	SET_ESTABELECIMENTO: 'system/SET_ESTABELECIMENTO',
	SET_ESTABELECIMENTO_ID: 'system/SET_ESTABELECIMENTO_ID',

	SET_FILTERS: 'system/SET_FILTERS',
	RESET_FILTERS: 'system/RESET_FILTERS',

	SET_PARAMS: 'system/SET_PARAMS',

	SET_ONEPAY_COMUNICADO: 'system/SET_ONEPAY_COMUNICADO',
};

// Reducer

const initialState = {
	config: {
		nome: 'Carregando...',
		hostname: 'dash.1pay.com.br',
		backgroundColor: '#f7f7f7',
		logoUrl: 'https://onepay-bucket.s3-sa-east-1.amazonaws.com/files/system_config/logos/OnePay%2BNovo.png',
		faviconUrl: null,
		loaderLogoUrl: 'https://onepay-bucket.s3-sa-east-1.amazonaws.com/files/system_config/logos/OnePay%2BNovo.png',
		website: 'https://1pay.com.br',
	},

	updateSaldo: false,
	color: '#036596',
	logo: null,

	selectedEC: {
		id: null,
		nome: '',
		nomeComprovante: '',
	},

	filters: localStorage.getItem('1pay-filters') ? JSON.parse(localStorage.getItem('1pay-filters')) : {},

	params: {},

	onePayComunicado: true,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case Types.SET_CONFIG:
			return {
				...state,
				config: {
					...state.config,
					...action.config,
				},
			};

		case Types.UPDATE_SALDO:
			return {
				...state,
				updateSaldo: action.updateSaldo,
			};
		case Types.SET_COLOR:
			return {
				...state,
				color: action.color,
			};

		case Types.SET_LOGO:
			return {
				...state,
				logo: action.logo,
			};

		case Types.SET_URL_WEBHOOK:
			return {
				...state,
				url_webhook: action.url_webhook,
			};

		case Types.SET_ESTABELECIMENTO:
			return {
				...state,
				selectedEC: action.estabelecimento,
			};

		case Types.SET_FILTERS: {
			const filters = {
				...state.filters,
				[action.url]: action.filters,
			};

			localStorage.setItem('1pay-filters', JSON.stringify(filters));

			return {
				...state,
				filters,
			};
		}

		case Types.RESET_FILTERS: {
			localStorage.removeItem('1pay-filters');

			return {
				...state,
				filters: {},
			};
		}

		case Types.SET_PARAMS: {
			return {
				...state,
				params: action.params,
			};
		}

		case Types.SET_ONEPAY_COMUNICADO: {
			return {
				...state,
				onePayComunicado: action.value,
			};
		}

		default:
			return state;
	}
};

export default reducer;

// Actions

export const getConfig = estabelecimentoId => async dispatch => {
	let token = localStorage.getItem('token');
	if (token) {
		let res = await axios.get(`${config.get('apiUrl')}estabelecimentos/configuracoes/${estabelecimentoId}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (res.data.success) {
			const configuracoes = res.data.configuracoes;
			dispatch(setValue(Types.SET_COLOR, 'color', '#f7f7f7'));
			dispatch(setValue(Types.SET_LOGO, 'logo', ''));
			dispatch(setValue(Types.SET_URL_WEBHOOK, 'url_webhook', ''));
			configuracoes.forEach(c => {
				switch (c.slug) {
					case 'primary_color':
						dispatch(setValue(Types.SET_COLOR, 'color', c.valor));
						break;
					case 'logo':
						dispatch(setValue(Types.SET_LOGO, 'logo', c.valor));
						break;
					case 'url_webhook':
						dispatch(setValue(Types.SET_URL_WEBHOOK, 'url_webhook', c.valor));
						break;
					default:
				}
			});
		}
	} else {
		return false;
	}
};

export const setValue = (type, field, value) => ({
	type,
	[field]: value,
});

export const setColor = color => ({
	type: Types.SET_COLOR,
	color,
});

export const setLogo = logo => ({
	type: Types.SET_LOGO,
	logo,
});

export const updateSaldo = update => ({
	type: Types.UPDATE_SALDO,
	updateSaldo: update,
});

export const setSelectedECId = estabelecimentoId => async dispatch => {
	let token = localStorage.getItem('token');

	if (token) {
		let res = await axios.post(
			`${config.get('apiUrl')}graphql`,
			{
				query: `
                query Estabelecimentos {
                    estabelecimento(id: ${estabelecimentoId}) {
                        id, nomeFantasia, nomeComprovante
                    }
                }
            `,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		if (res.status === 200 && Isset(() => res.data.data.estabelecimento)) {
			const { id, nomeFantasia: nome, nomeComprovante } = res.data.data.estabelecimento;
			return dispatch(setSelectedEC({ id, nome, nomeComprovante }));
		}
	}
};

export const setSelectedEC = estabelecimento => ({
	type: Types.SET_ESTABELECIMENTO,
	estabelecimento,
});

export const resetSelectedEC = () => ({
	type: Types.SET_ESTABELECIMENTO,
	estabelecimento: {
		id: null,
		nome: '',
	},
});

export const setFilters = (url, filters) => ({
	type: Types.SET_FILTERS,
	url,
	filters,
});

export const resetFilters = () => ({
	type: Types.RESET_FILTERS,
});

export const setSystemConfig = config => {
	const keys = ['nome', 'hostname', 'backgroundColor', 'logoUrl', 'loaderLogoUrl', 'website', 'faviconUrl'];

	Object.keys(config).forEach(a => {
		if (!keys.includes(a)) {
			delete config[a];
		}
	});

	return {
		type: Types.SET_CONFIG,
		config,
	};
};

export const getUserParams = usuario => async dispatch => {
	let token = localStorage.getItem('token');

	if (token) {
		let res = await axios.get(`${config.get('apiUrl')}parametros/por_usuario/${usuario.id}`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			return dispatch(setParams(res.data.parametros));
		}
	}
	return false;
};

export const setParams = params => ({
	type: Types.SET_PARAMS,
	params,
});

export const setOnePayComunicado = value => ({
	type: Types.SET_ONEPAY_COMUNICADO,
	value,
});
