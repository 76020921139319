/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@material-ui/core';
import { ZLoader, ZFiltros, ZTitle } from '../system/';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import config from 'react-global-configuration';
import { Isset, Formatter } from '../../utils/';
import clsx from 'clsx';

import { FaFilter } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
		textAlign: 'center',
	},
	valor: {
		fontSize: 16,
		fontWeight: 700,
	},
	vendas: {
		width: '100%',
		flexGrow: 1,
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	filtros: {
		position: 'relative',
		borderRadius: 20,
		marginBottom: 30,
		border: `solid 1px ${theme.palette.primary.main}67`,
		padding: '5px 10px',
		display: 'flex',
		alignItems: 'center',
	},
	export: {
		position: 'absolute',
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 18,
	},
	tableDateRow: {
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		backgroundColor: `${theme.palette.primary.main}aa`,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	tableRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	truncate: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	hideOnXs: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	dataHoraXs: {
		[theme.breakpoints.down('xs')]: {
			fontSize: 10,
		},
	},
	paymentIcon: {
		fontSize: 20,
	},
	qtdTransferencias: {
		fontSize: 28,
	},
}));

const getTipoTransferencia = tipoTransferenciaId => {
	switch (tipoTransferenciaId) {
		case 1:
			return 'Conta Digital';
		case 2:
			return 'Conta Bancária';
		case 3:
			return 'Automática';
		default:
			return '';
	}
};

const ListarTransferencias = props => {
	const classes = useStyles();
	const history = useHistory();
	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const usuario = useSelector(store => store.auth.usuario);
	const token = useSelector(store => store.auth.token);
	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};

	const [data, setData] = useState({
		transferencias: [],
	});
	const [loading, setLoading] = useState(true);
	const [pagination, setPagination] = useState({
		limit: 200,
		current: 0,
		totalRows: 0,
	});

	const getData = async () => {
		setData({ transferencias: [] });

		setLoading(true);
		try {
			const res = await axios.get(`${config.get('apiUrl')}transferencias`, {
				headers: { Authorization: `Bearer ${token}` },
				params: {
					...pagination,
					filters: {
						estabelecimentoId,
						...filtros,
					},
				},
			});
			if (res.data.success) {
				// setTotais(data.transferencias);
				setData({ transferencias: res.data.transferencias });
				setPagination({ ...pagination, totalRows: res.data.totalRows });
			}
		} catch (error) {}
		setLoading(false);
	};

	const [filtros, setFiltros] = useState({
		dataTransferencia: { de: new Date(), ate: new Date() },
		status: [],
		tipo: [],
		omni: '',
		omni2: '',
		...filtersCache,
	});

	useEffect(() => {
		getData();
	}, [filtros, estabelecimentoId, pagination.current, pagination.limit]);

	const filtrosChange = async data => {
		setPagination({ ...pagination, current: 0 });
		data.dataTransferencia = {
			de: data.date.start,
			ate: data.date.end,
		};

		data.status = data.statusTransferencia;
		data.tipo = data.tipoTransferencia;

		setFiltros(data);
	};

	// const exportXls = async () => {
	// 	const vendas = await axios.get(`${config.get('apiUrl')}export/vendas`, {
	// 		headers: { Authorization: `Bearer ${token}` },
	// 		responseType: 'blob',
	// 		params: {
	// 			// filters: {
	// 			estabelecimentoId,
	// 			// ...filtros,
	// 			// },
	// 		},
	// 	});

	// 	if (vendas.status !== 200 || !Isset(() => vendas.data)) {
	// 		return dispatch(addMessage({ type: 'error', message: 'Erro ao exportar vendas.' }));
	// 	}

	// 	const { 'content-disposition': contentDisposition, 'content-type': contentType } = vendas.headers;
	// 	let filename = 'vendas.xlsx';
	// 	if (contentDisposition.indexOf('filename="') !== -1) {
	// 		filename = contentDisposition.slice(
	// 			contentDisposition.indexOf('filename="') + 10,
	// 			contentDisposition.length - 1
	// 		);
	// 	}

	// 	FileDownload(vendas.data, filename, contentType);
	// };

	return (
		<Grid className={classes.vendas}>
			<ZTitle title="Transferências" display="flex" textAlign="center" fontSize="40px" />
			<Paper style={{ marginBottom: 10, padding: '5px 10px' }}>
				<div
					style={{
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<FaFilter />
					<ZFiltros
						onChange={filtrosChange}
						dateRange={{
							default: 'custom',
							startDate: filtros.dataTransferencia.de,
							endDate: filtros.dataTransferencia.ate,
						}}
						statusTransferencia={{
							defaultValue: filtros.status,
							multiple: true,
						}}
						tipoTransferencia={{
							defaultValue: filtros.tipo,
							multiple: true,
						}}
						omni={
							![3].includes(usuario.grupo_id)
								? {
										default: filtros.omni,
										placeholder: 'Pesquisa por CPF/CNPJ',
								  }
								: false
						}
						omni2={
							![3].includes(usuario.grupo_id)
								? {
										default: filtros.omni2,
										placeholder: 'Pesquisa por Estabelecimento',
								  }
								: false
						}
					/>
				</div>
			</Paper>

			<Paper style={{ padding: 15, marginBottom: 10 }}>
				<Grid container spacing={3} justifyContent="center" alignContent="center" alignItems="center">
					<Grid item xs={6} sm={3} md={3}>
						<div className={classes.label}>
							Quantidade de Transferências
							<div className={clsx(classes.valor, classes.qtdTransferencias)}>
								{Formatter.Valor(
									Isset(() => pagination.totalRows) ? pagination.totalRows : 0,
									0,
									',',
									'.'
								)}
							</div>
						</div>
					</Grid>
				</Grid>
			</Paper>

			<Paper>
				{loading ? (
					<Grid container direction="row" justifyContent="center" alignItems="center">
						<Grid item xs={2}>
							<ZLoader height={200} />
						</Grid>
					</Grid>
				) : (
					<>
						<TableContainer className={classes.container}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										<TableCell
											style={{ minWidth: 100 }}
											// key={column.id}
											// align={column.align}
											// style={{ minWidth: column.minWidth }}
										>
											ID
										</TableCell>
										<TableCell style={{ minWidth: 100 }}>Tipo</TableCell>
										<TableCell style={{ minWidth: 150 }}>Estabelecimento</TableCell>
										<TableCell style={{ minWidth: 180 }}>Destino</TableCell>
										<TableCell style={{ minWidth: 100 }}>Valor</TableCell>
										<TableCell style={{ minWidth: 150 }}>Descrição</TableCell>
										<TableCell style={{ minWidth: 100 }}>Status</TableCell>
										<TableCell style={{ minWidth: 100 }}>Data</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.transferencias.map(row => {
										return (
											<TableRow
												hover
												role="checkbox"
												tabIndex={-1}
												key={row.id}
												onClick={() => history.push(`/transferencias/detalhes/${row.id}`)}
											>
												<TableCell>{row.id}</TableCell>
												<TableCell>{getTipoTransferencia(row.tipo_transferencia_id)}</TableCell>
												<TableCell>
													{row.FromEstabelecimento.nome_fantasia}
													<div style={{ fontSize: 10 }}>
														{`${row.FromEstabelecimento.razao_social}`}
													</div>
												</TableCell>
												<TableCell>
													<div style={{ fontSize: 12 }}>
														{Isset(() => row.conta_bancaria.agencia) && (
															<>
																Agência: {row.conta_bancaria.agencia} <br />
																Conta: {row.conta_bancaria.conta}
															</>
														)}

														{Isset(() => row.ToEstabelecimento.nome_fantasia)
															? row.ToEstabelecimento.nome_fantasia
															: ''}
													</div>
												</TableCell>
												<TableCell>{Formatter.Real(row.valor)}</TableCell>
												<TableCell>{row.descricao}</TableCell>
												<TableCell>{row.status_transferencia.titulo}</TableCell>
												<TableCell>{moment(row.created).format('DD/MM/YYYY HH:mm')}</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[25, 50, 100, 200]}
							component="div"
							count={pagination.totalRows}
							rowsPerPage={pagination.limit}
							page={pagination.current}
							labelRowsPerPage={'Linhas por página:'}
							labelDisplayedRows={({ from, to, count }) =>
								`${from}-${to === -1 ? count : to} de ${count}`
							}
							onChangePage={(e, newPage) => {
								setPagination({ ...pagination, current: newPage });
							}}
							onChangeRowsPerPage={e => {
								const totalRows = e.target.value * pagination.limit;
								if (totalRows > pagination.totalRows) {
									setPagination({ ...pagination, limit: e.target.value, current: 0 });
								} else {
									setPagination({ ...pagination, limit: e.target.value });
								}
							}}
						/>
					</>
				)}
			</Paper>

			{/* <TransferenciasList
				transferencias={data.transferencias}
				loading={loading}
				// totais={totais}
				rowClick={rowClick}
				agruparTaxas={agruparTaxas}
			/> */}
		</Grid>
	);
};

export default ListarTransferencias;
