import React from 'react';
import { Switch, FormControlLabel, FormGroup } from '@material-ui/core';

const ZSwitch = props => {
	const [checked, setChecked] = React.useState(props.checked || false);
	const labelPlacement = props.labelPlacement || 'end';

	const toggleChecked = () => {
		setChecked(prev => !prev);

		if (props.onChange) {
			props.onChange();
		}
	};

	React.useEffect(() => {
		// console.log(props);
		setChecked(props.checked);
	}, [props.checked]);

	return (
		<FormGroup>
			<FormControlLabel
				control={<Switch checked={checked} onChange={toggleChecked} />}
				label={props.label}
				disabled={props.disabled}
				labelPlacement={labelPlacement}
			/>
		</FormGroup>
	);
};

export default ZSwitch;
