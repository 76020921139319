import React from 'react';
import {
	AppBar,
	Toolbar,
	Typography,
	// Grid,
	// Link
} from '@material-ui/core';
// import {
//     Security,
//     Info,
//     Mail,
//     Phone,
//     Smartphone,
// } from "@material-ui/icons";
import moment from 'moment';

const Footer = () => (
	<>
		{/* <Grid container justifyContent="center" style={{minHeight: "150px"}}>
            <Grid container item sm={6} xs={12} justifyContent="space-between">
                <Grid item sm={5} xs={12}>
                    <Typography paragraph>
                    <p>
                    O nosso propósito é utilizar nosso conhecimento do mercado financeiro para desenvolver, operar e distribuir produtos e serviços entregando mais valor a população em termos de custos, acesso e inclusão.
                    </p>
                    </Typography>
                </Grid>
                <Grid item sm={5} xs={12}>
                    <Typography paragraph>
                        <p>
                        Rua do Rocio, 220 – 10 andar Vila Olímpia, São Paulo – Brasil
                        </p>
                        <p>
                        <Phone color="action" fontSize="small"/><Link href="" target="_blank">+55 11 2626.8036</Link>
                        <br/>
                        <Smartphone color="action" fontSize="small"/><Link href="" target="_blank">+55 11 2626.8036</Link>
                        </p>
                    </Typography>
                </Grid>
            </Grid>
        </Grid> */}
		<AppBar position="static" elevation={0} component="footer" color="transparent" style={{ maxHeight: '42px' }}>
			<Toolbar style={{ justifyContent: 'center' }}>
				<Typography variant="caption">
					Copyright ©{moment().format('YYYY')} OnePay. Todos os direitos reservados.
				</Typography>
			</Toolbar>
		</AppBar>
	</>
);

export default Footer;
