import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	planoTaxaPaper: {
		padding: 10,
	},
	dialogPVs: {
		position: 'absolute',
		top: 70,
		left: 10,
		zIndex: 1101,
	},
	dialogPVsPaper: {
		width: 240,
		minHeight: 150,
		padding: 10,
		backgroundColor: '#f1f1f1',
	},
	dialogPV: {
		padding: 2,
		fontWeight: 'bold',
		'&:not(:last-child)': {
			borderBottom: 'solid 1px #abc',
		},
	},
	backdrop: {
		position: 'fixed',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: 'black',
		opacity: 0.5,
		zIndex: 1100,
	},
	planosVendasEmpty: {
		fontVariant: 'small-caps',
		textAlign: 'center',
		fontSize: 16,
		fontWeight: 'bold',
	},
}));
