/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { addMessage } from '../../store/alert';
import { Grid, Grow, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import config from 'react-global-configuration';
import Check from '@material-ui/icons/Check';
import { ZText } from '../system';
import useReactRouter from 'use-react-router';
import queryString from 'query-string';
import { Isset, getGrupoPermission } from '../../utils';
import { useStyles } from './styles/ConfiguracoesStyle';

const tabsPermissions = {
	conta: ['all'],
};

const UsuariosConfiguracoes = props => {
	const dispatch = useDispatch();
	const { location } = useReactRouter();

	const token = useSelector(state => state.auth.token);
	const usuario = useSelector(state => state.auth.usuario);

	const query = queryString.parse(location.search);

	const [permissions, setPermissions] = useState({});

	const [senha, setSenha] = useState({
		current: '',
		new: '',
		confirm: '',
		errors: false,
	});

	const [slugUpdated] = useState(null);
	const [tabSelected, setTabSelected] = useState(0);

	const classes = useStyles();

	useEffect(() => {
		if (Isset(() => query.t)) {
			switch (query.t) {
				case 'conta':
					setTabSelected(0);
					break;
				default:
			}
		} else {
			setTabSelected(0);
		}
	}, []);

	const handleAtualizarClick = async (slug, value) => {
		switch (slug) {
			case 'password':
				if (senha.new === '' || senha.current === '' || senha.confirm === '') {
					setSenha({ ...senha, errors: ['Preencha todos os campos'] });
					return false;
				}

				if (senha.new.length < 6) {
					setSenha({ ...senha, errors: ['A senha precisa ter no mínimo 6 digitos'] });
					return false;
				}

				if (senha.new !== senha.confirm) {
					setSenha({ ...senha, errors: ['As senhas não coincidem'] });
					return false;
				}

				let res = await axios.put(`${config.get('apiUrl')}usuarios/alterar_senha/`, senha, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				let type = 'success';
				if (res.data.success !== true) {
					type = 'error';
				}

				if (res.data.success) {
					setSenha({
						errors: false,
						current: '',
						new: '',
						confirm: '',
					});
				}

				dispatch(addMessage({ type: type, message: res.data.message }));
				break;

			default:
			// console.log(`${slug} não encontrado.`);
		}
	};

	useEffect(() => {
		const usuarioPermission = getGrupoPermission(usuario.grupo_id);
		const auxPermissions = Object.keys(tabsPermissions).map(key => {
			const permission = tabsPermissions[key];
			return [key, permission.filter(value => usuarioPermission.includes(value)).length > 0];
		});

		const perm = {};
		auxPermissions.forEach(a => {
			perm[a[0]] = a[1];
		});

		if (!Isset(() => query.t)) {
			setTabSelected(Object.values(perm).findIndex(b => b));
		}
		setPermissions(perm);
	}, [usuario]);

	const handleChange = (e, value) => setTabSelected(value);

	return (
		<div className={classes.configuracoes}>
			<div className={classes.header}>Configurações - {usuario.nome}</div>

			<Tabs value={tabSelected} onChange={handleChange} centered>
				{permissions.conta && <Tab label="Conta" value={0} />}
			</Tabs>
			<div className={classes.tabPanels}>
				{permissions.conta && tabSelected === 0 && (
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Typography component="div" role="tabpanel">
								<Grid item xs={12}>
									<Paper>
										<div className={classes.configBox}>
											<div className={classes.updateCheck}>
												<Grow in={slugUpdated === 'primary_color'}>
													<Check className={classes.icon} />
												</Grow>
											</div>

											<div className={classes.configHeader}>Alterar senha</div>
											<Grid item xs={12}>
												<ZText
													label="Senha atual"
													onBlur={e => setSenha({ ...senha, current: e.target.value })}
													value={senha.current}
													type="password"
													id="current"
												/>
											</Grid>
											<Grid item xs={12}>
												<ZText
													label="Nova senha"
													onBlur={e => setSenha({ ...senha, new: e.target.value })}
													value={senha.new}
													type="password"
													id="new"
												/>
											</Grid>
											<Grid item xs={12}>
												<ZText
													label="Confirme a nova senha"
													onBlur={e => setSenha({ ...senha, confirm: e.target.value })}
													value={senha.confirm}
													type="password"
													id="confirm"
												/>
											</Grid>
											{senha.errors.length && (
												<Grid item align="center" xs={12}>
													<small style={{ color: 'red' }}> {senha.errors[0]}</small>
												</Grid>
											)}
										</div>
										<div
											className={classes.configUpdateBtn}
											onClick={() => handleAtualizarClick('password', senha)}
										>
											Alterar
										</div>
									</Paper>
								</Grid>
							</Typography>
						</Grid>
					</Grid>
				)}
			</div>
		</div>
	);
};

export default UsuariosConfiguracoes;
