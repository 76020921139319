import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Z4Loader from '../../assets/images/loader.gif';

const useStyles = makeStyles(theme => ({
	reload: {
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 1210,
	},
	backdrop: {
		position: 'fixed',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: 'rgba(0,0,0,0.2)',
		zIndex: 1200,
	},
}));

const ZReload = props => {
	const classes = useStyles();

	return (
		<>
			<div className={classes.backdrop}>
				<div className={classes.reload}>
					<img src={Z4Loader} style={{ maxWidth: '200px' }} alt="Logo 1 Pay" title="Logo 1pay"></img>
				</div>
			</div>
		</>
	);
};

export default ZReload;
