/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Paper, Divider, Button, Chip } from '@material-ui/core';
import { Formatter } from '../../utils';
import { ZModalConfirm, ZLoader } from '../system';
import { addMessage } from '../../store/alert';
import { useStyles } from './styles/InVoiceDetailsStyle';
import { useSelector, useDispatch } from 'react-redux';
import config from 'react-global-configuration';
import useReactRouter from 'use-react-router';
import { format } from 'date-fns';
import moment from 'moment';

const InvoicesDetails = props => {
	const { match } = useReactRouter();
	const { invoiceId } = match.params;
	const usuario = useSelector(store => store.auth.usuario);

	const token = useSelector(store => store.auth.token);

	const classes = useStyles();
	const dispatch = useDispatch();

	const [invoice, setInvoice] = useState(null);
	const [loading, setLoading] = useState(true);

	const [exemptInvoice, setExemptInvoice] = useState({
		invoiceId: null,
		open: false,
	});

	const [cancelInvoice, setCancelInvoice] = useState({
		invoiceId: null,
		open: false,
	});

	const [refundInvoice, setRefundInvoice] = useState({
		invoiceId: null,
		open: false,
	});

	useEffect(() => {
		getInvoice();
	}, []);

	const handleExemptInvoice = result => {
		const invoiceId = exemptInvoice.invoiceId;
		const data = { status: 4 };
		setExemptInvoice({ ...exemptInvoice, open: false, invoiceId: null });

		if (result) {
			axios
				.post(`${config.get('apiUrl')}invoices/changeStatus/${invoiceId}`, data, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Cobrança isenta com sucesso' }));
						getInvoice();
					}
				});
		}
	};

	const getInvoice = async () => {
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}invoices/${invoiceId}`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		if (res.data.success) {
			setInvoice(res.data.invoice);
		}
		setLoading(false);
	};

	const handleCancelInvoice = result => {
		const invoiceId = cancelInvoice.invoiceId;
		const data = { status: 3 };
		setCancelInvoice({ ...cancelInvoice, open: false, invoiceId: null });

		if (result) {
			axios
				.post(`${config.get('apiUrl')}invoices/changeStatus/${invoiceId}`, data, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Cobrança cancelada com sucesso' }));
						getInvoice();
					}
				});
		}
	};

	const handleRefundInvoice = result => {
		const invoiceId = refundInvoice.invoiceId;
		console.log(invoiceId);
		var data = '';
		setRefundInvoice({ ...refundInvoice, open: false, invoiceId: null });
		console.log(refundInvoice);

		if (result) {
			console.log(result);
			axios
				.post(`${config.get('apiUrl')}invoices/refundInvoice/${invoiceId}`, data, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})

				.then(res => res.data)
				.then(data => {
					if (data.success) {
						console.log(data.success);
						dispatch(addMessage({ type: 'warning', message: 'Processando sua requisição' }));
						setTimeout(function() {
							getInvoice();
							dispatch(addMessage({ type: 'success', message: 'Cobrança estornada com sucesso' }));
						}, 2000);
					}
				});
		}
	};
	const getStatusLabel = status => {
		switch (+status) {
			case 1:
				return (
					<Chip
						size="small"
						label="Pendente" // amarelo
						style={{
							backgroundColor: '#E9C46A',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
			case 2:
				return (
					<Chip
						size="small"
						label="Pago" // verde
						style={{
							backgroundColor: '#2A9D8F',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
			case 3:
				return (
					<Chip
						size="small"
						label="Cancelado" // vermelho
						style={{
							backgroundColor: '#DF5937',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
			case 4:
				return (
					<Chip
						size="small"
						label="Isento" // azul
						style={{
							backgroundColor: '#154FC7',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
			case 5:
				return (
					<Chip
						size="small"
						label="Estornado" // laranja
						style={{
							backgroundColor: '#F4A261',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
			case 6:
				return (
					<Chip
						size="small"
						label="Erro" // vermelhao
						style={{
							backgroundColor: '#DF5937',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
			default:
				return (
					<Chip
						size="small"
						label="Não Mapeado"
						style={{
							backgroundColor: '#000000',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
		}
	};

	return (
		<div className={classes.invoice}>
			{loading ? (
				<ZLoader height={200} />
			) : (
				<>
					{invoice === null ? (
						<div className={classes.notFound}>Cobrança não encontrada.</div>
					) : (
						<React.Fragment>
							<div className={classes.header}>Cobrança</div>

							<Grid container spacing={2} direction="row" justifyContent="center">
								<Grid item xs={12} md={10}>
									<Paper className={classes.paper}>
										<Grid container>
											<Grid item xs={12}>
												<div className={classes.field}>
													<div className={classes.label}>
														Valor{' '}
														<span className={classes.valor}>
															{Formatter.Real(invoice.amount)}
														</span>
													</div>
												</div>
											</Grid>
											<Divider style={{ width: '100%' }} />
											<Grid item xs={12} sm={3}>
												<div className={classes.field}>
													<div className={classes.label}>Fatura Criada em</div>
													<div>{format(invoice.created, 'DD/MM/YYYY HH:mm:ss')}</div>
												</div>
											</Grid>
											<Grid item xs={12} sm={3}>
												<div className={classes.field}>
													<div className={classes.label}>ID da Transação</div>
													<div>{invoice.receipt_id}</div>
												</div>
											</Grid>
											<Grid item xs={12} sm={3}>
												<div className={classes.field}>
													<div className={classes.label}>Status</div>
													<div>{getStatusLabel(invoice.status)}</div>
												</div>
											</Grid>
											<Grid item xs={12} sm={3}>
												<div className={classes.field}>
													<div className={classes.label}>Descrição</div>
													<div>{invoice.description}</div>
												</div>
											</Grid>
										</Grid>
									</Paper>
								</Grid>

								<Grid item xs={12} md={10}>
									<Grid
										container
										spacing={2}
										direction="row"
										justifyContent="center"
										alignItems="stretch"
									>
										<Grid item xs={12} sm={6}>
											<Paper className={classes.paper}>
												<div className={classes.subheader}>Estabelecimento</div>

												<Grid container>
													<Grid item xs={6} md={6}>
														<div className={classes.field}>
															<div className={classes.label}>Nome</div>
															<div>{invoice.estabelecimento.nome_fantasia}</div>
														</div>
													</Grid>
													<Grid item xs={6} md={6}>
														<div className={classes.field}>
															<div className={classes.label}>CNPJ/ CPF</div>
															<div>
																{invoice.estabelecimento.estabelecimentos_documentos[0]
																	.documento <= 11
																	? Formatter.Cpf(
																			invoice.estabelecimento
																				.estabelecimentos_documentos[0]
																				.documento
																	  )
																	: Formatter.Cnpj(
																			invoice.estabelecimento
																				.estabelecimentos_documentos[0]
																				.documento
																	  )}
															</div>
														</div>
													</Grid>
												</Grid>

												<Grid container>
													<Grid item xs={6} md={6}>
														<div className={classes.field}>
															<div className={classes.label}>E-mail</div>
															<div>
																{invoice.estabelecimento.estabelecimentos_contatos[1]
																	? invoice.estabelecimento
																			.estabelecimentos_contatos[1].contato
																	: ''}
															</div>
														</div>
													</Grid>
													<Grid item xs={6} md={6}>
														<div className={classes.field}>
															<div className={classes.label}>Telefone</div>
															<div>
																{Formatter.Telefone(
																	invoice.estabelecimento.estabelecimentos_contatos[0]
																		? invoice.estabelecimento
																				.estabelecimentos_contatos[0].contato
																		: 0
																)}
															</div>
														</div>
													</Grid>
												</Grid>
											</Paper>
										</Grid>

										<Grid item xs={12} sm={6}>
											<Paper className={classes.paper}>
												<div className={classes.subheader}>Cobrança</div>
												{
													<Grid container>
														<Grid item xs={12} sm={3}>
															<div className={classes.field}>
																<div className={classes.label}>Mês de Referência</div>
																<div>
																	{invoice.reference_month}/{invoice.reference_year}
																</div>
															</div>
														</Grid>
														<Grid item xs={12} sm={3}>
															<div className={classes.field}>
																<div className={classes.label}>
																	Cobrança Iniciada em
																</div>
																<div>
																	{invoice.chargestart_date
																		? moment(invoice.chargestart_date).format(
																				'DD/MM/YYYY'
																		  )
																		: 'Não Iniciado'}
																</div>
															</div>
														</Grid>
														<Grid item xs={12} sm={3}>
															<div className={classes.field}>
																<div className={classes.label}>Data de pagamento</div>
																<div>
																	{invoice.payment_date
																		? invoice.payment_date
																		: 'Não Paga'}
																</div>
															</div>
														</Grid>
														<Grid item xs={12} sm={3}>
															<div className={classes.field}>
																<div className={classes.label}>Total Pago</div>
																<div>R$ {invoice.settled}</div>
															</div>
														</Grid>
														<Grid item xs={12} sm={3}>
															<div className={classes.field}>
																<div className={classes.label}>
																	Termos e Condições Aceito
																</div>
																<div>
																	{invoice.estabelecimento.reference_revenue === 1
																		? 'Sim'
																		: 'Não'}
																</div>
															</div>
														</Grid>
														<Grid item xs={12} sm={3}>
															<div className={classes.field}>
																<div className={classes.label}>Quantidade POS</div>
																<div>{invoice.reference_pos}</div>
															</div>
														</Grid>
														<Grid item xs={12} sm={3}>
															<div className={classes.field}>
																<div className={classes.label}>Faturamento</div>
																<div>R$ {invoice.reference_revenue}</div>
															</div>
														</Grid>
														<Grid item xs={12} sm={3}>
															<div className={classes.field}>
																<div className={classes.label}>Desconto</div>
																<div>R$ {invoice.discount}</div>
															</div>
														</Grid>
													</Grid>
												}
											</Paper>
										</Grid>
									</Grid>

									<Grid container spacing={2} direction="row" justifyContent="center">
										{invoice.status === 1 &&
											+invoice.settled === 0 &&
											[1].includes(usuario.grupo_id) && [
												<Grid item xs={6} sm={6}>
													<Button
														className={classes.exemptButton}
														fullWidth
														onClick={() =>
															setExemptInvoice({
																...exemptInvoice,
																invoiceId: invoice.id,
																open: true,
															})
														}
													>
														Isentar Cobrança
													</Button>
												</Grid>,
												<Grid item xs={6} sm={6}>
													<Button
														className={classes.cancelButton}
														fullWidth
														onClick={() =>
															setCancelInvoice({
																...cancelInvoice,
																invoiceId: invoice.id,
																open: true,
															})
														}
													>
														Cancelar Cobrança
													</Button>
												</Grid>,
											]}
										{(invoice.status === 1 || invoice.status === 2) &&
											[1].includes(usuario.grupo_id) &&
											+invoice.settled > 0 && (
												<Grid item xs={6} sm={6}>
													<Button
														className={classes.refundButton}
														fullWidth
														onClick={() =>
															setRefundInvoice({
																...exemptInvoice,
																invoiceId: invoice.id,
																open: true,
															})
														}
													>
														Estornar Cobrança
													</Button>
												</Grid>
											)}
									</Grid>
								</Grid>
								<ZModalConfirm
									title={`Isentar Cobrança`}
									message={`Deseja ISENTAR essa cobrança?`}
									open={exemptInvoice.open}
									onClose={handleExemptInvoice}
								/>
								<ZModalConfirm
									title={`Cancelar Cobrança`}
									message={`Deseja CANCELAR essa cobrança?`}
									open={cancelInvoice.open}
									onClose={handleCancelInvoice}
								/>
								<ZModalConfirm
									title={`Estornar Cobrança`}
									message={`Deseja ESTORNAR essa cobrança?`}
									open={refundInvoice.open}
									onClose={handleRefundInvoice}
								/>
							</Grid>
						</React.Fragment>
					)}
				</>
			)}
		</div>
	);
};

export default InvoicesDetails;
