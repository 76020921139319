import React, { useState, useEffect, memo } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';

import { green, yellow, red } from '@material-ui/core/colors';

import axios from 'axios';
import config from 'react-global-configuration';

import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { Formatter, Isset } from '../../../utils';

import { useStyles } from './styles/UltimasVendasStyles';
import { Grid, Tooltip } from '@material-ui/core';
import {
	FaBarcode,
	FaCcVisa,
	FaCcMastercard,
	FaCcAmex,
	FaCreditCard,
	FaCheckCircle,
	FaTimesCircle,
	FaExclamationCircle,
	FaRegCreditCard,
	FaDollarSign,
} from 'react-icons/fa';

import LOGOPIX from '../../../assets/images/pix-256.png';

import ZLoader from '../../system/ZLoader';

const getStatusIcon = statusId => {
	let title = '';
	let icon = '';
	switch (+statusId) {
		case 2: {
			title = 'Aprovado';
			icon = <FaCheckCircle style={{ color: green[500] }} />;
			break;
		}
		case 3: {
			title = 'Falhado';
			icon = <FaTimesCircle style={{ color: red[700] }} />;
			break;
		}
		case 1:
		default: {
			title = 'Pendente';
			icon = <FaExclamationCircle style={{ color: yellow[700] }} />;
		}
	}

	return (
		<Tooltip title={title} placement="bottom">
			<div>{icon}</div>
		</Tooltip>
	);
};

const getPaymentIcon = (paymentType, brand) => {
	switch (paymentType) {
		case 1:
			return <FaBarcode alt="Boleto" title="Boleto" />;
		case 2:
			return <FaRegCreditCard alt="Débito" title="Débito" />;
		case 3:
			switch (brand) {
				case 'mastercard':
					return <FaCcMastercard alt="Cartão de Crédito" title="Cartão de Crédito" />;
				case 'visa':
					return <FaCcVisa alt="Cartão de Crédito" title="Cartão de Crédito" />;
				case 'amex':
					return <FaCcAmex alt="Cartão de Crédito" title="Cartão de Crédito" />;
				default:
					return <FaCreditCard alt="Cartão de Crédito" title="Cartão de Crédito" />;
			}
		case 4:
			return <FaDollarSign alt="Comissão" title="Comissão" />;
		case 5:
			return <img src={LOGOPIX} alt="Pix" title="Pix" style={{ maxWidth: '100%', maxHeight: '20px' }} />;
		default:
			return <FaExclamationCircle alt="Pendente" title="Pendente" />;
	}
};

export default memo(props => {
	const { history } = useReactRouter();

	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);

	const canViewEC = [1, 2, 4, 7, 10].includes(usuario.grupo_id);

	const [loading, setLoading] = useState(true);
	const [ultimasVendas, setUltimasVendas] = useState([]);

	useEffect(() => {
		const getUltimasVendas = async () => {
			setLoading(true);
			const vendas = await axios.get(`${config.get('apiUrl')}vendas/ultimasVendas`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					limit: 20,
					filters: {
						estabelecimentoId: props.estabelecimentoId,
						status: [1, 2],
					},
				},
			});

			if (vendas.data.success) {
				const result = [];
				let lastDate = null;
				vendas.data.vendas.forEach(v => {
					const date = format(v.created, 'YYYY-MM-DD');
					if (lastDate !== date) {
						lastDate = date;
						result.push({ type: 'date', date });
					}
					result.push({
						type: 'row',
						id: v.id,
						created: v.created,
						statusId: v.status,
						statusTitle: v.status.titulo,
						tipoPagamentoId: Isset(() => v.tipoPagamento.id) ? v.tipoPagamento.id : null,
						brand: Isset(() => v.tipoPagamento.cartaoCredito.bandeira)
							? v.tipoPagamento.cartaoCredito.bandeira.toLowerCase()
							: null,
						valor: v.valores.total,
						estabelecimento: v.estabelecimento.nome_fantasia,
					});
				});
				setUltimasVendas(result);
			}
			setLoading(false);
		};

		getUltimasVendas();
	}, [props.estabelecimentoId, token]);

	const viewVenda = vendaId => history.push(`/vendas/detalhes/${vendaId}`);

	return (
		<div>
			<div className={classes.gridHeader}>Últimas Vendas</div>
			{loading ? (
				<ZLoader height={200} size={50} />
			) : (
				<>
					{ultimasVendas.length > 0 ? (
						<Grid container className={classes.ultimasVendas}>
							{ultimasVendas.map((venda, key) => (
								<React.Fragment key={key}>
									{venda.type === 'date' ? (
										<Grid container item className={classes.tableDateRow}>
											<Grid item xs>
												{format(venda.date, 'DD [de] MMMM [de] YYYY', {
													locale: pt,
												})}
											</Grid>
										</Grid>
									) : (
										<Grid
											container
											item
											className={classes.tableRow}
											onClick={() => viewVenda(venda.id)}
										>
											<Grid item xs={2}>
												{format(venda.created, 'HH:mm', { locale: pt })}
											</Grid>
											<Grid item xs={1}>
												{getStatusIcon(venda.statusId)}
											</Grid>
											{canViewEC && (
												<Grid
													item
													xs
													style={{
														whiteSpace: 'nowrap',
														textOverflow: 'ellipsis',
														overflow: 'hidden',
													}}
												>
													{venda.estabelecimento.slice(0, 20)}
												</Grid>
											)}
											<Grid item xs>
												{Formatter.Real(venda.valor)}
											</Grid>
											<Grid item xs={1} className={classes.tipoPagamentoIcon}>
												{getPaymentIcon(venda.tipoPagamentoId, venda.brand)}
											</Grid>
										</Grid>
									)}
								</React.Fragment>
							))}
						</Grid>
					) : (
						<div className={clsx(classes.vendasEmpty, classes.empty)}>Nenhuma Venda Encontrada.</div>
					)}
				</>
			)}
		</div>
	);
});
