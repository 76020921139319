import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Typography, Grid, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Isset } from '../../utils';

const useStyles = makeStyles({
	container: {
		display: props => props.display,
		justifyContent: props => props.justifyContent,
		marginTop: 20,
		marginBottom: props => props.marginBottom || 35,
		textAlign: props => props.textAlign,
		color: props => props.color,
	},
	font: {
		fontSize: props => props.fontSize,
	},
});

function ZTitle(props) {
	const classes = useStyles(props);

	var buttonAdd = false;
	var colTitle = 12;
	if (typeof props.handleOnClick == 'function') {
		buttonAdd = true;
		colTitle = 10;
	}

	let grupo = true;
	if (Isset(() => props.grupo)) {
		grupo = props.grupo;
	}

	return (
		<Grid container className={classes.container}>
			<Grid item xs={colTitle} lg={colTitle} md={colTitle} style={props.style || null}>
				<Typography className={classes.font} variant="h4" component="h1" gutterBottom>
					{props.title}
				</Typography>
			</Grid>

			{buttonAdd && props.button === 'add' && grupo && (
				<Grid item xs={2} lg={2} md={2}>
					<Grid container justifyContent="flex-end">
						<Grid item>
							<Fab
								color="primary"
								onClick={() => {
									props.handleOnClick();
								}}
								size="medium"
								aria-label="Add"
							>
								<AddIcon />
							</Fab>
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
}

export default ZTitle;
