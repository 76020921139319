/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	relatorios: {},
	relatorioBtn: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 20,
		borderRadius: 10,
		border: 'solid 1px #abc',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		opacity: 0.8,
		cursor: 'pointer',
		height: 100,
		transition: theme.transitions.create(['opacity'], { duration: theme.transitions.duration.complex }),
		'&:hover': {
			opacity: 1,
		},
	},
	relatorioBtnText: {
		fontWeight: 700,
		fontSize: 20,
		fontVariant: 'small-caps',
		textAlign: 'center',
	},
}));

const Relatorios = () => {
	const classes = useStyles();
	const { history } = useReactRouter();

	const usuario = useSelector(store => store.auth.usuario);

	const isAdmin = usuario.grupo_id === 1;
	const isRepresentante = usuario.grupo_id === 5;
	const isEC = [2, 3].includes(usuario.grupo_id);

	const isFranchise = usuario.is_franchise !== null && usuario.is_franchise !== undefined ? usuario.is_franchise : 0;

	return (
		<div className={classes.relatorios}>
			<div className={classes.header}>Relatórios</div>

			<Grid container spacing={2} justifyContent="center">
				{!isEC && (
					<Grid item md={3} sm={6} xs={12}>
						<div
							className={classes.relatorioBtn}
							onClick={() => history.push('/relatorios/vendasPorEstabelecimento')}
						>
							<div className={classes.relatorioBtnText}>Vendas por Estabelecimento</div>
						</div>
					</Grid>
				)}
				<Grid item md={3} sm={6} xs={12}>
					<div className={classes.relatorioBtn} onClick={() => history.push('/relatorios/vendasPorPOS')}>
						<div className={classes.relatorioBtnText}>Vendas por POS</div>
					</div>
				</Grid>
				{isAdmin && (
					<Grid item md={3} sm={6} xs={12}>
						<div
							className={classes.relatorioBtn}
							onClick={() => history.push('/relatorios/markupPorEstabelecimento')}
						>
							<div className={classes.relatorioBtnText}>MarkUp Por Estabelecimento</div>
						</div>
					</Grid>
				)}
				{(isAdmin || isRepresentante) && (
					<Grid item md={3} sm={6} xs={12}>
						<div className={classes.relatorioBtn} onClick={() => history.push('/relatorios/performance')}>
							<div className={classes.relatorioBtnText}>Performance Por Estabelecimento</div>
						</div>
					</Grid>
				)}
				{isFranchise === 1 && (
					<Grid item md={3} sm={6} xs={12}>
						<div className={classes.relatorioBtn} onClick={() => history.push('/relatorios/performance')}>
							<div className={classes.relatorioBtnText}>Performance Por Estabelecimento</div>
						</div>
					</Grid>
				)}
				<Grid item md={3} sm={6} xs={12}>
					<div className={classes.relatorioBtn} onClick={() => history.push('/relatorios/vendasPorDia')}>
						<div className={classes.relatorioBtnText}>Vendas por Dia</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

// eslint-disable-next-line no-lone-blocks
{
	/* <ResponsiveBar
    data={melhoresECs}
    keys={['valorTotalVendas']}
    indexBy="nomeFantasia"
    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
    padding={0.3}
    colors={colors}
    colorBy="index" ssa
    tooltipFormat={value => Formatter.Real(value)}
    labelFormat={value => Formatter.Real(value)}
    axisTop={null}
    axisRight={null}
    axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -8,
    }}
    axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Valor Total de Vendas',
        // legendPosition: 'middle',
        legendOffset: -40
    }}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
/> */
}

export default Relatorios;
