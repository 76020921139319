/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Grid, Paper, Tooltip } from '@material-ui/core';
import axios from 'axios';
import config from 'react-global-configuration';
import moment from 'moment';
import { Formatter, Isset } from '../../utils';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import format from 'date-fns/format';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, AlertTitle } from '@material-ui/lab';
import 'react-credit-cards/es/styles-compiled.css';
import { addMessage } from '../../store/alert';
import { ZNotFound, ZLoader, ZModal, ZSaveButton } from '../system';
import {
	FaBarcode,
	FaCcVisa,
	FaCcMastercard,
	FaCcAmex,
	FaCreditCard,
	FaRegCreditCard,
	FaDollarSign,
	FaInfoCircle,
} from 'react-icons/fa';
import LOGOPIX from '../../assets/images/pix-256.png';
import ONEPAYBLACK from '../../assets/images/onepay_black.svg';
import { Chip } from '@material-ui/core';
import _ from 'lodash';
import clsx from 'clsx';
import QRCode from 'qrcode.react';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
		width: '100%',
	},
	subHeader: {
		position: 'absolute',
		fontWeight: 700,
		fontSize: 16,
		margin: 5,
		// width: '100%',
		top: -15,
		left: 5,
	},
	field: {
		padding: 5,
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	label: {
		fontSize: 14,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	dataVenda: {
		// position: 'absolute',
		// top: -10,
		// left: 10,
		fontWeight: 700,
	},
	estabelecimento: {
		// position: 'absolute',
		// top: -10,
		// right: 10,
		fontWeight: 700,
	},
	valorTotal: {
		fontSize: 24,
		fontWeight: 700,
	},
	valorSmall: {
		fontSize: 18,
	},
	markupCalculado: {
		fontSize: 12,
	},
	btnBoleto: {
		width: 100,
		padding: 6,
		textAlign: 'center',
		margin: 'auto',
		textTransform: 'uppercase',
		textDecoration: 'none',
		fontWeight: 700,
		borderRadius: 10,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	flex: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	flex2: {
		display: 'flex',
		alignItems: 'center',
	},
	flexCol: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		'@media(min-width: 960px)': {
			alignItems: 'flex-start',
		},
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 18,
	},
	tableRow: {
		textAlign: 'center',
		fontWeight: 700,
		minHeight: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	paymentIcon: {
		fontSize: 20,
		marginRight: 4,
	},
	paper: {
		position: 'relative',
	},
}));

const getCategoriaById = id => {
	switch (id) {
		case 1:
			return 'Regra de Split';
		case 2:
			return 'Plano de Taxa';
		case 3:
			return 'Regra no Link de Pagamento';
		case 4:
			return 'Regra na Venda';
		case 5:
			return 'Taxa de Venda Online';
		default:
			return '';
	}
};

const TooltipTitle = props => {
	const {
		taxas: { taxas, splits2 },
		isAdmin,
	} = props;
	const taxaZoop = Isset(() => taxas[1].taxa) ? +taxas[1].taxa : 0;
	const markupZoop = Isset(() => taxas[2].taxa) ? +taxas[2].taxa : 0;
	const spread = Isset(() => splits2[2].amount) ? +splits2[2].amount : 0;
	const taxaVendaOnline = Isset(() => splits2[5].amount) ? +splits2[5].amount : 0;
	return (
		<div style={{ fontSize: 14 }}>
			<div>
				<small>Taxa de Custo</small> {Formatter.Real(isAdmin ? taxaZoop : +taxaZoop + +markupZoop)}
			</div>
			{isAdmin && (
				<div>
					<small>Markup Zoop</small> {Formatter.Real(markupZoop)}
				</div>
			)}
			<div>
				<small>Tabela de Markup</small> {Formatter.Real(spread)}
			</div>
			{taxaVendaOnline > 0 && (
				<div>
					<small>Taxa de Venda Online</small> {Formatter.Real(taxaVendaOnline)}
				</div>
			)}
		</div>
	);
};

const TooltipTitle2 = props => {
	const {
		taxas: { taxaZoop, tabelaMarkup },
	} = props;
	return (
		<div style={{ fontSize: 14 }}>
			<div>
				<small>Taxa Zoop</small> {Formatter.Real(taxaZoop)}
			</div>
			<div>
				<small>Tabela de Markup</small> {Formatter.Real(tabelaMarkup)}
			</div>
		</div>
	);
};

const brlDate = dateStr => {
	if (Array.isArray(dateStr) && dateStr.length > 0) return brlDate(dateStr[0].created_at);
	else if (typeof dateStr !== 'string') return '';

	return format(dateStr.substr(0, 10), 'DD/MM/YYYY');
};

const proxDiaUtil = dateMmt => {
	let dayIncrement = 1;
	if (dateMmt.day() === 5) {
		dayIncrement = 3;
	} else if (dateMmt.day() === 6) {
		dayIncrement = 2;
	}
	return dateMmt.add(dayIncrement, 'd');
};
const getBandeira = bandeira => {
	switch (bandeira) {
		case 'american express':
			return 'American Express';
		case 'elo':
			return 'Elo';
		case 'maestro':
			return 'Maestro';
		case 'mastercard':
			return 'Mastercard';
		case 'visa':
			return 'Visa';
		case 'visa electron':
			return 'Visa Electron';
		case 'diners club':
			return 'Diners Club';
		case 'hiper':
			return 'Hiper';
		case 'hipercard':
			return 'HiperCard';
		case 'banescard':
			return 'Banescard';
		default:
			return bandeira;
	}
};

const getPaymentIcon = (paymentType, brand, className = null) => {
	brand = getBandeira(brand);
	if (paymentType === 1) {
		return <FaBarcode className={className} />;
	}

	if (paymentType === 2) {
		return <FaRegCreditCard className={className} />;
	}

	if (paymentType === 3) {
		switch (brand) {
			case 'Mastercard':
			case 'Maestro':
				return <FaCcMastercard className={className} />;
			case 'Visa':
			case 'Visa Electron':
				return <FaCcVisa className={className} />;
			case 'American Express':
				return <FaCcAmex className={className} />;
			default:
				return <FaCreditCard className={className} />;
		}
	}

	if (paymentType === 4) {
		return <FaDollarSign />;
	}

	if (paymentType === 5) {
		return <img src={LOGOPIX} alt="Pix" title="Pix" style={{ maxWidth: '100%', maxHeight: '20px' }} />;
	}
};

const VendasDetalhes = props => {
	const { match } = useReactRouter();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const isMaster = useSelector(store => store.auth.isMaster);
	// const estabelecimento = useSelector(store => store.auth.estabelecimento);
	let agruparTaxas = useSelector(store =>
		Isset(() => store.system.params['1'].valor) ? store.system.params['1'].valor : null
	);
	// const operarMarketplace = useSelector(store =>
	// 	Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	// );

	const theme = useTheme();
	const isRestrito = usuario.grupo_id === 11;
	const isXs = useMediaQuery(theme.breakpoints.down('xs'));
	const isAdmin = [1, 10].includes(usuario.grupo_id);
	// const isGerencial = usuario.grupo_id === 2;
	agruparTaxas = !agruparTaxas && isAdmin ? false : true;
	// const viewMarkupCalculado = true; // [1, 8, 9, 10].includes(usuario.marketplace.id);

	const classes = useStyles();
	const dispatch = useDispatch();

	const [data, setData] = useState(null);
	console.log(data);
	const [loading, setLoading] = useState(true);
	// const [isMine, setIsMine] = useState(false);

	const [isRefunded, setIsRefunded] = useState(false);
	const [cartaCancelamentoLoading, setCartaCancelamentoLoading] = useState(false);

	const getData = async () => {
		setData(null);
		setLoading(true);
		let req = await axios.get(`${config.get('apiUrl')}vendas/` + match.params.vendaId, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (req.data.success) {
			const venda = req.data.venda;

			// if (venda.estabelecimento.id === estabelecimento.id) {
			// 	setIsMine(true);
			// }

			if (venda.status.id === 6 || venda.status.id === 4) {
				setIsRefunded(true);
			}

			setData(venda);
		}
		setLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);

	const [dataEstornar, setDataEstornar] = useState({
		pedidoId: null,
		valorTotal: 0,
		open: false,
	});

	const [modalQrCode, setModalQrCode] = useState(false);

	const [dataExcluirBoleto, setDataExcluirBoleto] = useState({
		pedidoId: null,
		open: false,
	});

	// const getData = async () => {
	// 	setData(null);
	// 	let req = await axios.get(`${config.get('apiUrl')}vendas/` + match.params.vendaId, {
	// 		headers: { Authorization: `Bearer ${token}` },
	// 	});

	// 	if (req.data.success) {
	// 		const venda = req.data.venda;

	// 		if (venda.estabelecimento.id === estabelecimento.id) {
	// 			setIsMine(true);
	// 		}

	// 		setData(venda);
	// 	}
	// 	setLoading(false);
	// };

	const handleEstornar = async () => {
		setDataEstornar({ ...dataEstornar, open: false, pedidoId: null });

		setData(null);
		setLoading(true);

		const balance = await axios.get(`${config.get('apiUrl')}estabelecimentos/${data.estabelecimento.id}/saldo`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (
			+dataEstornar.valorTotal <= +balance.data.saldo.atual ||
			(Array.isArray(data.pagamentos) &&
				data.pagamentos.length > 0 &&
				data.pagamentos[0].statusPagamento &&
				data.pagamentos[0].statusPagamento.id === 1)
		) {
			await axios
				.post(
					`${config.get('apiUrl')}vendas/${match.params.vendaId}/estornar`,
					{
						amount: dataEstornar.valorTotal * 100,
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(res => res.data)
				.then(data => {
					let typeDispatch = 'success';
					if (!data.success) {
						typeDispatch = 'error';
					}
					dispatch(addMessage({ type: typeDispatch, message: data.message }));
					getData();
				});
		} else {
			let typeDispatch = 'error';
			dispatch(addMessage({ type: typeDispatch, message: 'Não há saldo o suficiente para realizar o estorno!' }));
		}
	};

	const handleExcluirBoleto = async () => {
		setDataEstornar({ ...dataEstornar, open: false, pedidoId: null });

		setData(null);
		setLoading(true);

		await axios
			.post(
				`${config.get('apiUrl')}vendas/${match.params.vendaId}/excluir-boleto`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(res => res.data)
			.then(data => {
				let typeDispatch = 'success';
				if (!data.success) {
					typeDispatch = 'error';
				}
				dispatch(addMessage({ type: typeDispatch, message: data.message }));
				getData();
			});
	};

	const getCartaCancelamento = async () => {
		setCartaCancelamentoLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}vendas/${match.params.vendaId}/carta-cancelamento`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			window.open(res.data.url);
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao emitir Carta de Cancelamento.' }));
		}
		setCartaCancelamentoLoading(false);
	};

	const reprocessarVenda = async () => {
		const res = await axios.get(`${config.get('apiUrl')}z1/vendas/${match.params.vendaId}/reprocessar`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			getData();
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao reprocessar venda.' }));
		}
	};

	if (
		Isset(() => data.status.id) &&
		data.status.id === 7
		// (Isset(() => data.id) && (!Isset(() => data.pagamentos) || data.pagamentos.length < 1))
	) {
		return <ZNotFound text="Venda Em Processamento" />;
	}

	const opTable = {
		authorization: {
			succeeded: {
				color: '#66CB01',
				label: 'Autorizada',
			},
			failed: {
				color: '#FF0000',
				label: 'Falha na captura',
			},
		},
		charged_back: {
			succeeded: {
				color: '#FF0000',
				label: 'Charged Back',
			},
			failed: {
				color: '#FF0000',
				label: 'Charged Back Failed',
			},
		},
		created: {
			succeeded: {
				color: '#66CB01',
				label: 'Criada',
			},
			failed: {
				color: '#FF0000',
				label: 'Falha ao criar',
			},
		},
		disputed: {
			succeeded: {
				color: '#FFC400',
				label: 'Disputa',
			},
			failed: {
				color: '#FF0000',
				label: 'Disputa',
				tooltip: 'Erro na captura',
			},
		},
		expiration: {
			succeeded: {
				color: '#FF0000',
				label: 'Expirado',
			},
			failed: {
				color: '#FF0000',
				label: 'Expirado',
			},
		},
		paid: {
			succeeded: {
				color: '#66CB01',
				label: 'Pago',
			},
			failed: {
				color: '#FF0000',
				label: 'Falha ao Pagar',
			},
		},
		reversal: {
			succeeded: {
				color: '#FF0000',
				label: 'Revertida',
			},
			failed: {
				color: '#FF0000',
				label: 'Revertida',
			},
		},
		void: {
			succeeded: {
				color: '#FF0000',
				label: 'Cancelamento Total',
			},
			failed: {
				color: '#FF0000',
				label: 'Falha no Cancelamento',
			},
		},
	};

	const opMask = (op, status) => {
		const aux = opTable[op];
		if (aux && aux[status]) {
			if (data.tipoPagamento && data.tipoPagamento.id === 1 && op === 'paid') {
				return Object.assign(aux[status], { label: 'Transferência para o estabelecimento' });
			}
			return aux[status];
		}
		return {
			color: '#777777',
			label: op,
		};
	};
	const isPos = Isset(() => data.pos) && data.pos !== null;

	return (
		<Grid container spacing={3}>
			<Grid item xs={4}>
				<div className={classes.header}>Detalhes da Transação</div>
				{Isset(() => data.zoop_transaction_id) && (
					<>
						<div>
							<small>
								ID da Transação: <strong>{data.zoop_transaction_id}</strong>
							</small>
						</div>

						<div>
							<small>
								Meio de entrada: <strong>{data.pos ? 'POS' : 'Gateway'}</strong>
							</small>
						</div>

						{data.pedido_venda && (
							<div>
								<small>
									Pedido Venda: <strong>{data.pedido_venda}</strong>
								</small>
							</div>
						)}

						<div>
							Data da Transação:{' '}
							<span className={classes.dataVenda}>{format(data.created, 'DD/MM/YYYY HH:mm')}</span>
						</div>
						<div className={classes.estabelecimento}>{data.estabelecimento.nome_fantasia}</div>
					</>
				)}
			</Grid>

			<Grid item xs={5} style={{ display: 'flex', justifyContent: 'center' }}>
				{data && data.historico && data.historico.length !== 0 && (
					<Grid container>
						<Grid item className={classes.header} style={{ textAlign: 'center' }}>
							Histórico
						</Grid>
						{data.historico.map((hist, key) => (
							<Grid container key={'hist' + key} style={{ display: 'flex', alignItems: 'center' }}>
								<Grid item xs={6} style={{ textAlign: 'right', paddingRight: 8 }}>
									{hist.created_at
										? data.tipoPagamento && data.tipoPagamento.id === 1
											? proxDiaUtil(moment(hist.created_at)).format('DD/MM/YYYY HH:mm')
											: moment(hist.created_at).format('DD/MM/YYYY HH:mm')
										: ''}
								</Grid>
								<Tooltip
									title={
										hist.response_message && hist.response_message.length < 80
											? hist.response_message
											: ''
									}
									placement="left"
								>
									<Grid item xs={6}>
										<Chip
											size="small"
											label={opMask(hist.operation_type, hist.status).label}
											style={{
												border: '1px solid ' + opMask(hist.operation_type, hist.status).color,
												color: opMask(hist.operation_type, hist.status).color,
												fontSize: 10,
												textTransform: 'uppercase',
												fontWeight: 700,
												margin: 4,
											}}
											variant="outlined"
										/>
										{hist.response_message && hist.response_message.length < 80 && (
											<FaInfoCircle style={{ marginLeft: 5, fontSize: 10 }} />
										)}
									</Grid>
								</Tooltip>
							</Grid>
						))}
					</Grid>
				)}
			</Grid>

			<Grid item xs={3} align={'right'}>
				{data &&
					data.tipoPagamento.id === 1 &&
					data.status.id === 1 &&
					!data.oculto &&
					[1, 2, 7, 10].includes(usuario.grupo_id) &&
					data.tipo_pedido_id === 1 && (
						<>
							<Button
								variant="contained"
								color="secondary"
								onClick={() =>
									setDataExcluirBoleto({ ...dataExcluirBoleto, pedido_id: data.id, open: true })
								}
								size="small"
							>
								Excluir boleto
							</Button>
							<ZModal
								title={`Excluir Boleto`}
								description={
									'Deseja excluir esse boleto da listagem de vendas? Essa ação não poderá ser desfeita.'
								}
								open={dataExcluirBoleto.open}
								// onClose={handleEstornar}
								handleClose={() => setDataExcluirBoleto({ ...dataExcluirBoleto, open: false })}
								handleOnSave={handleExcluirBoleto}
								content={
									<Grid container direction="row" justifyContent="center" alignItems="center">
										{/* <Grid item xs={12} md={6}>
											<ZText
												value={dataEstornar.valorTotal}
												label={'Valor a ser estornado'}
												onChange={value =>
													setDataEstornar({ ...dataEstornar, valorTotal: value })
												}
												realMask={true}
											/>
										</Grid> */}
									</Grid>
								}
							/>
						</>
					)}
				{data &&
					data.tipoPagamento.id === 3 &&
					data.status.id === 2 &&
					[1, 2, 7].includes(usuario.grupo_id) &&
					data.tipo_pedido_id === 1 && (
						<>
							<Button
								variant="contained"
								color="secondary"
								onClick={() =>
									setDataEstornar({ ...dataEstornar, valorTotal: data.valores.total, open: true })
								}
								size="small"
							>
								{Array.isArray(data.pagamentos) &&
								data.pagamentos.length > 0 &&
								data.pagamentos[0].statusPagamento &&
								data.pagamentos[0].statusPagamento.id === 1
									? `Cancelar Venda`
									: `Estornar venda`}
							</Button>
							<ZModal
								title={`Confirmação`}
								description={'Deseja estornar essa venda? Essa ação não poderá ser desfeita.'}
								open={dataEstornar.open}
								// onClose={handleEstornar}
								handleClose={() => setDataEstornar({ ...dataEstornar, open: false })}
								handleOnSave={handleEstornar}
								content={
									<Grid container direction="row" justifyContent="center" alignItems="center">
										{/* <Grid item xs={12} md={6}>
											<ZText
												value={dataEstornar.valorTotal}
												label={'Valor a ser estornado'}
												onChange={value =>
													setDataEstornar({ ...dataEstornar, valorTotal: value })
												}
												realMask={true}
											/>
										</Grid> */}
									</Grid>
								}
							/>
						</>
					)}

				{isRefunded && (
					<div style={{ width: 285 }}>
						<ZSaveButton
							onClick={getCartaCancelamento}
							color={'secondary'}
							align="right"
							label={'Emitir Carta de Cancelamento'}
							size="small"
							loading={cartaCancelamentoLoading}
						/>
					</div>
				)}

				{isMaster && (
					<div style={{ width: 285 }}>
						<Button
							style={{ background: '#4a6682' }}
							variant="contained"
							color="secondary"
							onClick={reprocessarVenda}
							size="small"
						>
							Reprocessar Venda
						</Button>
					</div>
				)}
			</Grid>

			{data === null ? (
				<>{loading ? <ZLoader height={200} /> : <ZNotFound text="Venda não encontrada." />}</>
			) : (
				<Grid item xs={12}>
					<Grid container spacing={3}>
						<Grid item xs={12} style={{ marginBottom: 12 }}>
							<Paper style={{ padding: '0px 10px' }}>
								{/* <Grid style={{ position: 'relative', marginBottom: 20 }} container>
								</Grid> */}
								<Grid justifyContent={'center'} container spacing={3}>
									{!data.parent_id ? (
										<>
											<Grid item xs={12} sm={6} md={2}>
												<div className={classes.field}>
													<div className={classes.label}>Valor da Transação</div>
													<div className={classes.valorTotal}>
														{Formatter.Real(data.valores.total)}
													</div>
												</div>
											</Grid>
											{/* {!isMine && ( */}
											{!agruparTaxas && (
												<Grid item xs={12} sm={6} md={2}>
													{isAdmin ? (
														<Tooltip
															title={
																<TooltipTitle taxas={data.valores} isAdmin={isAdmin} />
															}
															placement="top"
														>
															<div className={classes.field}>
																<div className={classes.label}>
																	Taxa{' '}
																	<FaInfoCircle
																		style={{ marginLeft: 5, fontSize: 10 }}
																	/>
																</div>
																<div className={classes.valorTotal}>
																	{Formatter.Real(
																		agruparTaxas
																			? +data.valores.taxaTotal +
																					+data.valores.splitNormal
																			: +data.valores.taxaTotal
																	)}
																</div>
															</div>
														</Tooltip>
													) : (
														<div className={classes.field}>
															<div className={classes.label}>Taxa</div>
															<div className={classes.valorTotal}>
																{Formatter.Real(
																	agruparTaxas
																		? +data.valores.taxaTotal +
																				+data.valores.splitNormal
																		: +data.valores.taxaTotal
																)}
															</div>
														</div>
													)}
												</Grid>
											)}
											{!agruparTaxas && (
												<Grid item xs={12} sm={6} md={2}>
													<div className={classes.field}>
														<div className={classes.label}>Split</div>
														<div className={classes.valorTotal}>
															{Formatter.Real(+data.valores.splitNormal)}
														</div>
													</div>
												</Grid>
											)}
											{!isRestrito && (
												<Grid item xs={12} sm={6} md={2}>
													<div className={classes.field}>
														<div className={classes.label}>Valor Líquido</div>
														<div className={classes.valorTotal}>
															{Formatter.Real(+data.valor_liquido)}
														</div>
													</div>
												</Grid>
											)}
										</>
									) : (
										<Grid item xs={12} sm={6} md={2}>
											<div className={classes.field}>
												<div className={classes.label}>Valor Transação</div>
												<div className={classes.valorTotal}>
													{Formatter.Real(+data.valores.liquido)}
												</div>
											</div>
										</Grid>
									)}
									{/* <Grid item xs={12} sm={6} md={2}>
										<div className={classes.field}>
											{isAdmin && data.status.id === 2 ? (
												<>
													<div className={clsx(classes.valorTotal, classes.valorSmall)}>
														<small>Valor Líquido</small>{' '}
														{Formatter.Real(data.valores.liquido)}
													</div>
													<div className={clsx(classes.valorTotal, classes.valorSmall)}>
														<small>Markup</small> {Formatter.Real(data.taxasFinais.markup)}
													</div>
													<div
														className={clsx(
															classes.valorTotal,
															classes.valorSmall,
															classes.markupCalculado
														)}
													>
														<small>Markup Filho</small>{' '}
														{Formatter.Real(data.taxasFinais.markupFilho)}
													</div>
												</>
											) : (
												<>
													{operarMarketplace ? (
														<>
															<div
																className={clsx(classes.valorTotal, classes.valorSmall)}
															>
																<small>Valor Líquido</small>{' '}
																{Formatter.Real(data.valores.liquido)}
															</div>
															<div
																className={clsx(classes.valorTotal, classes.valorSmall)}
															>
																<small>Markup</small>{' '}
																{Formatter.Real(data.taxasFinais.markupEC)}
															</div>
														</>
													) : (
														<>
															<div className={classes.label}>Valor Líquido</div>
															<div className={classes.valorTotal}>
																{Formatter.Real(data.valores.liquido)}
															</div>
														</>
													)}
												</>
											)}
										</div>
									</Grid> */}
									<Grid item xs={12} sm={6} md={2}>
										<div className={classes.field}>
											<div className={classes.label}>Forma de Pagamento</div>
											<div
												className={clsx(
													classes.valorTotal,
													classes.valorSmall,
													classes.flexCol
												)}
											>
												<span>
													{getPaymentIcon(
														data.tipoPagamento.id,
														data.bandeira,
														classes.paymentIcon
													)}
													{data.tipoPagamento.titulo}
												</span>

												{!!data && !!data.qrcode && (
													<div>
														<Button
															variant="contained"
															color="primary"
															onClick={() => setModalQrCode(true)}
															size="small"
														>
															QRCode
														</Button>
														<ZModal
															title={`QRCode`}
															open={modalQrCode}
															// onClose={handleEstornar}
															handleClose={() => setModalQrCode(false)}
															handleOnSave={() => setModalQrCode(false)}
															content={
																<QRCode
																	renderAs="svg"
																	value={data.qrcode}
																	size={256}
																	imageSettings={{
																		src: ONEPAYBLACK,
																		x: null,
																		y: null,
																		height: 48,
																		width: 48,
																		excavate: true,
																	}}
																/>
															}
														/>
													</div>
												)}
											</div>
										</div>
									</Grid>
									<Grid item xs={12} sm={6} md={2}>
										<div className={classes.field}>
											<div className={classes.label}>Status</div>
											<div className={classes.valorTotal}> {data.status.titulo}</div>
										</div>
									</Grid>
									{data.msg_erro && (
										<Grid item md={12} style={{ paddingLeft: 30, paddingRight: 30 }}>
											<Alert severity="error">
												<AlertTitle>Mensagem de erro</AlertTitle>
												{data.msg_erro}
											</Alert>
										</Grid>
									)}
								</Grid>
							</Paper>
						</Grid>
						<Grid container item alignItems="stretch" justifyContent="center" spacing={2}>
							{[1, 2, 3].includes(data.tipoPagamento.id) && (
								<Grid item xs={12} md={isPos ? 12 : 6}>
									{[2, 3].includes(data.tipoPagamento.id) &&
										Isset(() => data.pagamentos[0].pagamentoCartao) && (
											<Paper style={{ padding: 15, height: '100%' }} className={classes.paper}>
												<Grid justifyContent={'center'} container spacing={3}>
													<div className={classes.subHeader}>
														Dados do Cartão {isPos && <small>(Via POS)</small>}
													</div>
													<Grid item xs={12} sm={6} md={3}>
														<div className={classes.field}>
															<div className={classes.label}>Titular</div>
															<div>
																{Isset(
																	() =>
																		data.pagamentos[0].pagamentoCartao.nome_titular
																)
																	? data.pagamentos[0].pagamentoCartao.nome_titular
																	: '---'}
															</div>
														</div>
													</Grid>
													<Grid item xs={12} sm={6} md={3}>
														<div className={classes.field}>
															<div className={classes.label}>Bandeira</div>
															<div>
																{Isset(
																	() => data.pagamentos[0].pagamentoCartao.bandeira
																)
																	? data.pagamentos[0].pagamentoCartao.bandeira
																	: '---'}
															</div>
														</div>
													</Grid>

													<Grid item xs={12} sm={6} md={3}>
														<div className={classes.field}>
															<div className={classes.label}>Número</div>
															<div>
																**** **** ****{' '}
																{Isset(
																	() =>
																		data.pagamentos[0].pagamentoCartao
																			.ultimos_digitos
																)
																	? data.pagamentos[0].pagamentoCartao.ultimos_digitos
																	: '****'}
															</div>
														</div>
													</Grid>

													<Grid item xs={12} sm={6} md={3}>
														<div className={classes.field}>
															<div className={classes.label}>Vencimento</div>
															{Isset(
																() => data.pagamentos[0].pagamentoCartao.mes_expiracao
															) &&
															Isset(
																() => data.pagamentos[0].pagamentoCartao.ano_expiracao
															) ? (
																<div>
																	{data.pagamentos[0].pagamentoCartao.mes_expiracao
																		.toString()
																		.padStart(2, '0')}
																	/{data.pagamentos[0].pagamentoCartao.ano_expiracao}
																</div>
															) : (
																<div>{'--/--'}</div>
															)}
														</div>
													</Grid>
												</Grid>
											</Paper>
										)}

									{data.tipoPagamento.id === 1 && Isset(() => data.tipoPagamento.boleto.url) && (
										<Paper style={{ padding: 15, height: '100%' }} className={classes.paper}>
											<Grid container spacing={3}>
												<div className={classes.subHeader}>Dados do Boleto</div>

												<Grid item xs={12} sm={6} md={6}>
													<div className={classes.field}>
														<div className={classes.label}>Vencimento</div>
														<div>
															{moment(
																data.pagamentos[0].dataRecebimento,
																'YYYY-MM-DD'
															).format('DD/MM/YYYY')}
														</div>
													</div>
												</Grid>
												<Grid item xs={12} sm={6} md={6} className={classes.flex}>
													<a
														style={{ textDecoration: 'none' }}
														href={data.tipoPagamento.boleto.url}
														target="_blank"
														rel="noreferrer noopener"
													>
														<div className={classes.btnBoleto}>Ver Boleto</div>
													</a>
												</Grid>
											</Grid>
										</Paper>
									)}
								</Grid>
							)}
							{!isPos && data.cliente !== null && (
								<Grid item xs={12} md={6}>
									<Paper style={{ padding: 15, height: '100%' }} className={classes.paper}>
										<Grid
											container
											spacing={2}
											// justifyContent={'center'}
										>
											<div className={classes.subHeader}>Cliente</div>

											<Grid item xs={12} sm={6} md={4}>
												<div className={classes.field}>
													<div className={classes.label}>Nome</div>
													<div>{data.cliente.nome}</div>
												</div>
											</Grid>
											<Grid item xs={12} sm={6} md={4}>
												<div className={classes.field}>
													<div className={classes.label}>E-mail</div>
													<div>{data.cliente.email}</div>
												</div>
											</Grid>
											{Isset(() => data.cliente.clientes_documentos[0].documento) && (
												<Grid item xs={12} sm={6} md={4}>
													{data.cliente.clientes_documentos[0].documento.length === 14 ? (
														<div className={classes.field}>
															<div className={classes.label}>CNPJ</div>
															<div>
																{Formatter.Cnpj(
																	data.cliente.clientes_documentos[0].documento
																)}
															</div>
														</div>
													) : (
														<div className={classes.field}>
															<div className={classes.label}>CPF</div>
															<div>
																{Formatter.Cpf(
																	data.cliente.clientes_documentos[0].documento
																)}
															</div>
														</div>
													)}
												</Grid>
											)}
										</Grid>
									</Paper>
								</Grid>
							)}
						</Grid>
						{!isPos && data.cliente !== null && (
							<Grid item xs={12}>
								<Paper style={{ padding: 15 }} className={classes.paper}>
									<Grid container>
										<div className={classes.subHeader}>Dados do Cliente</div>

										<Grid
											container
											item
											xs={12}
											justifyContent="center"
											className={classes.tableHeader}
										>
											<Grid item xs={2}>
												Logradouro
											</Grid>
											<Grid item xs={1}>
												Número
											</Grid>
											<Grid item xs={3}>
												Complemento
											</Grid>
											<Grid item xs={2}>
												Bairro
											</Grid>
											<Grid item xs={2}>
												Cidade
											</Grid>
											<Grid item xs={1}>
												UF
											</Grid>
											<Grid item xs={1}>
												CEP
											</Grid>
										</Grid>
										<Grid container item xs={12} className={classes.tableRow}>
											<Grid item xs={2}>
												{_.has(data, 'cliente.endereco.logradouro')
													? data.cliente.endereco.logradouro
													: ''}
											</Grid>
											<Grid item xs={1}>
												{_.has(data, 'cliente.endereco.numero')
													? data.cliente.endereco.numero
													: ''}
											</Grid>
											<Grid item xs={3}>
												{_.has(data, 'cliente.endereco.complemento')
													? data.cliente.endereco.complemento
													: ''}
											</Grid>
											<Grid item xs={2}>
												{_.has(data, 'cliente.endereco.bairro')
													? data.cliente.endereco.bairro
													: ''}
											</Grid>
											<Grid item xs={2}>
												{_.has(data, 'cliente.endereco.cidade')
													? data.cliente.endereco.cidade
													: ''}
											</Grid>
											<Grid item xs={1}>
												{_.has(data, 'cliente.endereco.uf') ? data.cliente.endereco.uf : ''}
											</Grid>
											<Grid item xs={1}>
												{_.has(data, 'cliente.endereco.cep') ? data.cliente.endereco.cep : ''}
											</Grid>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						)}
						{data.splitsNormais.length > 0 && isAdmin && (
							<Grid item xs={12}>
								<Paper style={{ padding: 15 }} className={classes.paper}>
									<Grid container>
										<div className={classes.subHeader}>Splits</div>

										<Grid container item xs={12} className={classes.tableHeader}>
											<Grid item xs={6}>
												Estabelecimento
											</Grid>
											<Grid item xs={3}></Grid>
											{/* <Grid item xs={2}>
												Valor
											</Grid> */}
											<Grid item xs={3}>
												Valor
											</Grid>
										</Grid>
										{data.splitsNormais.map((split, key) => (
											<Grid container item xs={12} key={key} className={classes.tableRow}>
												<Grid item xs={6}>
													{Isset(() => split.estabelecimento.nome_fantasia)
														? split.estabelecimento.nome_fantasia
														: ''}
												</Grid>
												<Grid item xs={3}>
													{getCategoriaById(split.categoria)}
												</Grid>
												{/* <Grid item xs={2}>
													{Formatter.Real(split.zoop_gross_amount)}
												</Grid> */}
												<Grid item xs={3}>
													{Formatter.Real(split.zoop_amount)}
												</Grid>
											</Grid>
										))}
									</Grid>
								</Paper>
							</Grid>
						)}
						<Grid item xs={12}>
							{!isRestrito && (
								<Paper style={{ padding: 15 }} className={classes.paper}>
									<Grid container>
										<div className={classes.subHeader}>Recebíveis</div>

										<Grid
											container
											item
											xs={12}
											justifyContent="center"
											className={classes.tableHeader}
										>
											<Grid item xs={1}>
												{isXs ? '#' : 'Parcela'}
											</Grid>
											<Grid item xs={2}>
												Status
											</Grid>
											<Grid item xs={isXs ? 5 : 2}>
												Valor
											</Grid>
											{!isXs && !data.parent_id && (
												<>
													{agruparTaxas ? (
														<Grid item xs={2}>
															Taxa
														</Grid>
													) : (
														<>
															<Grid item xs={1}>
																Taxa
															</Grid>
															<Grid item xs={1}>
																Split
															</Grid>
														</>
													)}
													<Grid item xs={2}>
														Valor Líquido
													</Grid>
												</>
											)}
											<Grid item xs={2}>
												{data.tipoPagamento && data.tipoPagamento.id === 1
													? 'Boleto pago em:'
													: 'Recebimento'}
											</Grid>
										</Grid>

										{data.pagamentos.map((pagamento, key) => {
											return (
												<Grid
													container
													justifyContent="center"
													item
													xs={12}
													key={key}
													className={classes.tableRow}
												>
													<Grid item xs={1}>
														{pagamento.parcela}
													</Grid>
													<Grid item xs={2}>
														{pagamento?.statusPagamento?.titulo}
													</Grid>
													{!isXs ? (
														<Grid item xs={2}>
															<div>
																{Formatter.Real(
																	parseFloat(pagamento.valor)
																	// parseFloat(
																	// 	data.pagamentos.length - 1 === key
																	// 		? +data.splitParcelaFinal +
																	// 				+data.spreadParcelaFinal
																	// 		: +data.splitParcela +
																	// 				+data.spreadParcela
																	// )
																)}
															</div>
														</Grid>
													) : (
														<Grid item xs={5}>
															<div>
																Total{' '}
																{Formatter.Real(
																	parseFloat(pagamento.valor) +
																		parseFloat(
																			data.pagamentos.length - 1 === key
																				? +data.splitParcelaFinal +
																						+data.spreadParcelaFinal
																				: +data.splitParcela +
																						+data.spreadParcela
																		)
																)}
															</div>
															{agruparTaxas ? (
																<div>
																	Taxa{' '}
																	{Formatter.Real(
																		+pagamento.taxa +
																			+data.splitParcela +
																			+data.spreadParcela
																	)}
																</div>
															) : (
																<>
																	<div>
																		Taxa{' '}
																		{Formatter.Real(
																			+pagamento.taxa +
																				(data.pagamentos.length - 1 === key
																					? +data.spreadParcelaFinal
																					: +data.spreadParcela)
																		)}
																	</div>
																	<div>
																		Split{' '}
																		{Formatter.Real(
																			data.pagamentos.length - 1 === key
																				? data.splitParcelaFinal
																				: data.splitParcela
																		)}
																	</div>
																</>
															)}
															<div>Líquido {Formatter.Real(pagamento.valorRecebido)}</div>
														</Grid>
													)}
													{!isXs && !data.parent_id && (
														<>
															{agruparTaxas ? (
																<Grid item xs={2}>
																	{Formatter.Real(
																		+pagamento.taxa +
																			(data.pagamentos.length - 1 === key
																				? +data.splitParcelaFinal +
																				  +data.spreadParcelaFinal
																				: +data.splitParcela +
																				  +data.spreadParcela)
																	)}
																</Grid>
															) : (
																<>
																	{isAdmin ? (
																		<Tooltip
																			title={
																				<TooltipTitle2
																					taxas={{
																						taxaZoop: +pagamento.taxa,
																						tabelaMarkup:
																							data.pagamentos.length -
																								1 ===
																							key
																								? +data.spreadParcelaFinal
																								: +data.spreadParcela,
																					}}
																				/>
																			}
																			placement="top"
																		>
																			<Grid item xs={1}>
																				{Formatter.Real(
																					+pagamento.taxa +
																						(data.pagamentos.length - 1 ===
																						key
																							? +data.spreadParcelaFinal
																							: +data.spreadParcela)
																				)}
																				<FaInfoCircle
																					style={{
																						marginLeft: 5,
																						fontSize: 10,
																					}}
																				/>
																			</Grid>
																		</Tooltip>
																	) : (
																		<Grid item xs={1}>
																			{Formatter.Real(
																				+pagamento.taxa +
																					(data.pagamentos.length - 1 === key
																						? +data.spreadParcelaFinal
																						: +data.spreadParcela)
																			)}
																			<FaInfoCircle
																				style={{ marginLeft: 5, fontSize: 10 }}
																			/>
																		</Grid>
																	)}
																	<Grid item xs={1}>
																		{Formatter.Real(
																			data.pagamentos.length - 1 === key
																				? data.splitParcelaFinal
																				: data.splitParcela
																		)}
																	</Grid>
																</>
															)}
															<Grid item xs={2}>
																{Formatter.Real(pagamento.valorRecebido)}
															</Grid>
														</>
													)}
													{pagamento.dataRecebimento && (
														<Grid item xs={2}>
															{data.tipoPagamento && data.tipoPagamento.id === 1
																? brlDate(
																		data.historico.filter(
																			h => h.operation_type === 'paid'
																		)
																  )
																: brlDate(pagamento.dataRecebimento)}
														</Grid>
													)}
												</Grid>
											);
										})}
									</Grid>
								</Paper>
							)}
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

export default VendasDetalhes;
