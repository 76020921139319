import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	tableRow: {
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	rowTitle: {
		margin: 0,
		textAlign: 'center',
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			textAlign: 'left',
			marginTop: 24,
		},
	},
	rowText: {
		display: 'inline-block',
		fontWeight: 400,
		marginTop: 8,
	},
	truncate: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		alignItems: 'center',
	},
}));
