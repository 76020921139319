/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios';
import { Dialog, DialogContent, Grid, Paper } from '@material-ui/core';
import { ZLoader, ZFiltros, ZEmpty, ZPaginationDiv } from '../system';
import { useLocation } from 'react-router-dom';
import { FaFilter, FaFileExcel } from 'react-icons/fa';
import clsx from 'clsx';
import { Isset, Formatter } from '../../utils/';
import FileDownload from 'js-file-download';
import { addMessage } from '../../store/alert';
import config from 'react-global-configuration';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	export: {
		position: 'absolute',
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 18,
	},
	tableSubHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 16,
	},
	tableDateRow: {
		fontWeight: 700,
		// height: 30,
		padding: 4,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		backgroundColor: `${theme.palette.primary.main}aa`,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		paddingLeft: 20,
	},
	tableRow: {
		// textAlign: 'center',
		fontWeight: 700,
		padding: 5,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		marginBottom: 2,
		backgroundColor: `${theme.palette.primary.main}1a`,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	tableSubRow: {
		textAlign: 'center',
		fontWeight: 700,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	tableRowSelected: {
		backgroundColor: `${theme.palette.primary.main}3c`,
	},
	vendas: {
		marginBottom: 10,
	},
	noWrap: {
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	numberValue: {
		textAlign: 'right',
		paddingRight: 10,
	},
}));

const RelatoriosPerformancePorEstabelecimento = () => {
	const token = useSelector(store => store.auth.token);
	const classes = useStyles();

	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [showExportMessage, setShowExportMessage] = useState(false);
	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const queryFilter = {
		startDate: new Date(),
		endDate: new Date(),
		...filtersCache,
	};

	const [filtros, setFiltros] = useState(queryFilter);
	const filtrosChange = async data => {
		const f = {
			startDate: data.date.start,
			endDate: data.date.end,
			omni: data.omni,
			searchEstabelecimentoByCpfOrCNPJ: data.searchEstabelecimentoByCpfOrCNPJ,
			nomeComprovante: data.nomeComprovante,
		};

		setFiltros(f);
	};

	// Paginação

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(100);
	const [totalRows, setTotalRows] = useState(0);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	useEffect(() => {
		const getPerformance = async () => {
			setLoading(true);
			const res = await Axios.get(`${config.get('apiUrl')}relatorios/performancePorEstabelecimento`, {
				params: {
					...filtros,
					limit: rowsPerPage,
					page,
				},
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				setData(res.data.result);
				setTotalRows(res.data.totalRows);
			}
			setLoading(false);
		};

		getPerformance();
	}, [filtros, page, rowsPerPage]);

	const exportXls = async () => {
		setShowExportMessage(true);
		const result = await Axios.get(`${config.get('apiUrl')}export/relatorios/performancePorEstabelecimento`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params: {
				...filtros,
			},
		});

		if (result.status !== 200 || !Isset(() => result.data)) {
			setShowExportMessage(false);
			return dispatch(addMessage({ type: 'error', message: 'Erro ao exportar relatório.' }));
		}

		const { 'content-disposition': contentDisposition, 'content-type': contentType } = result.headers;
		let filename = 'performancePorEstabelecimento.xlsx';
		if (contentDisposition.indexOf('filename="') !== -1) {
			filename = contentDisposition.slice(
				contentDisposition.indexOf('filename="') + 10,
				contentDisposition.length - 1
			);
		}

		FileDownload(result.data, filename, contentType);
		setShowExportMessage(false);
	};

	return (
		<>
			<Grid className={classes.vendas}>
				<div className={classes.header}>Performance Por Estabelecimento</div>

				<Paper
					style={{
						position: 'relative',
						marginBottom: 10,
						borderRadius: 20,
						padding: '5px 10px',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<FaFilter />
					<ZFiltros
						onChange={filtrosChange}
						dateRange={{
							default: 'custom',
							startDate: filtros.startDate,
							endDate: filtros.endDate,
						}}
						omni={{
							default: filtros.omni,
							placeholder: 'Empresa',
						}}
						searchEstabelecimentoByCpfOrCNPJ={{
							value: filtros.searchEstabelecimentoByCpfOrCNPJ || '',
							placeholder: 'CPF/CNPJ',
						}}
						nomeComprovante={{
							value: filtros.nomeComprovante || '',
							placeholder: 'Nome do comprovante',
						}}
					/>
					<FaFileExcel className={classes.export} onClick={exportXls} />
				</Paper>

				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper style={{ padding: 15 }}>
							{loading ? (
								<ZLoader height={200} />
							) : (
								<>
									{data.length > 0 ? (
										<Grid container className={classes.vendas}>
											<Grid container item className={classes.tableHeader}>
												<Grid item xs={3}>
													Estabelecimento
												</Grid>
												<Grid item xs>
													Crédito
												</Grid>
												<Grid item xs>
													Débito
												</Grid>
												<Grid item xs>
													Pix
												</Grid>
												<Grid item xs>
													Aprovado
												</Grid>
												<Grid item xs>
													Quantidade Vendas
												</Grid>
												<Grid item xs>
													Ticket Médio
												</Grid>
												<Grid item xs>
													Quantidade POS
												</Grid>
												<Grid item xs={2}>
													Rep. Principal
												</Grid>
											</Grid>
											{data.map((estabelecimento, key) => (
												<React.Fragment key={key}>
													<Grid container item className={clsx(classes.tableRow)}>
														<Grid item xs={3}>
															<div className={classes.noWrap}>
																{estabelecimento.nomeFantasia}
															</div>
															<div className={classes.noWrap}>
																<small>{estabelecimento.nomeComprovante}</small>
															</div>
														</Grid>
														<Grid item xs className={classes.numberValue}>
															{Formatter.Real(estabelecimento.valorTotalCredito)}
														</Grid>
														<Grid item xs className={classes.numberValue}>
															{Formatter.Real(estabelecimento.valorTotalDebito)}
														</Grid>
														<Grid item xs className={classes.numberValue}>
															{Formatter.Real(estabelecimento.valorTotalPix)}
														</Grid>
														<Grid
															item
															xs
															style={{ color: '#4caf50' }}
															className={classes.numberValue}
														>
															{Formatter.Real(estabelecimento.valorTotalAprovado)}
														</Grid>
														<Grid item xs style={{ textAlign: 'center' }}>
															{estabelecimento.quantidadeTotalVendas}
														</Grid>
														<Grid
															item
															xs
															style={{ color: '#4caf50' }}
															className={classes.numberValue}
														>
															{Formatter.Real(estabelecimento.ticketMedio)}
														</Grid>
														<Grid item xs style={{ textAlign: 'center' }}>
															{estabelecimento.quantidadePOS}
														</Grid>
														<Grid item xs={2} className={classes.noWrap}>
															{estabelecimento.representantePrincipal}
														</Grid>
													</Grid>
												</React.Fragment>
											))}
										</Grid>
									) : (
										<ZEmpty height={200} text="Nenhum Resultado Encontrado." />
									)}
								</>
							)}
						</Paper>

						<ZPaginationDiv
							count={totalRows}
							rowsPerPage={rowsPerPage}
							page={page - 1}
							onChangePage={onChangePage}
							onChangeRowsPerPage={onChangeRowsPerPage}
							rowsPerPageOptions={[50, 100, 200]}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>
		</>
	);
};

export default RelatoriosPerformancePorEstabelecimento;
