import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	parametros: {},
	parametro: {
		position: 'relative',
		padding: 18,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
	},
	parametroTitulo: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		fontWeight: 'bold',
		textAlign: 'right',
	},
	actionButtons: {
		marginTop: 10,
		textAlign: 'center',
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 200,
	},
	moneyPercentValor: {
		'&.porcentagem input': {
			textAlign: 'right',
		},
	},
	moneyPercentIcone: {
		border: 'solid 1px #abc',
		marginRight: 2,
		marginLeft: 2,
		marginTop: 16,
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}`,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
}));
