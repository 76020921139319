import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	dashboard: {
		padding: 10,
	},
	box: {
		position: 'relative',
		padding: 10,
		height: '100%',
	},
	boxTitle: {
		fontWeight: 700,
		fontSize: 14,
		margin: 4,
	},
	boxFiltro: {
		position: 'absolute',
		top: 10,
		right: 5,
	},
	extrato: {
		height: 270,
	},
	extratoChart: {
		width: '100%',
		height: 160,
	},
	lancamentosChart: {
		width: '100%',
		height: 230,
	},
	extratoIO: {
		marginTop: 10,
		marginBottom: 20,
	},
	extratoFooter: {
		position: 'absolute',
		bottom: 4,
		right: 10,
		textAlign: 'right',
		cursor: 'pointer',
		'&:hover': {
			fontWeight: '700',
		},
	},
	progress: {
		height: 6,
		borderRadius: 6,
	},
	progressEntrada: {
		backgroundColor: `#ea606187`,
		'& .MuiLinearProgress-bar': {
			backgroundColor: `#ea6061FF`,
		},
	},
	progressSaida: {
		backgroundColor: `#00579787`,
		'& .MuiLinearProgress-bar': {
			backgroundColor: `#005797FF`,
		},
	},
	vendas: {
		minHeight: 435,
	},
	btnUltimasVendas: {
		[theme.breakpoints.down('md')]: {
			marginTop: 35,
		},
	},
	vendasChart: {
		width: '100%',
		height: 280,
		marginBottom: 10,
		[theme.breakpoints.up('md')]: {
			width: 300,
		},
	},
	webOnly: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'block',
		},
	},
	gridHeader: {
		fontWeight: 700,
		color: 'rgba(0,0,0,0.4)',
		height: 30,
	},
	ultimasVendas: {
		maxHeight: 300,
		overflowY: 'auto',
	},
	ultimasVendasFooter: {
		// position: 'absolute',
		bottom: 4,
		marginTop: 10,
		textAlign: 'right',
		cursor: 'pointer',
		'&:hover': {
			fontWeight: '700',
		},
	},
	ultimasTransferencias: {
		maxHeight: 210,
		overflowY: 'auto',
		marginBottom: 25,
	},
	ultimasTransferenciasFooter: {
		position: 'absolute',
		bottom: 4,
		right: 10,
		textAlign: 'right',
		cursor: 'pointer',
		'&:hover': {
			fontWeight: '700',
		},
	},
	tableDateRow: {
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		backgroundColor: `${theme.palette.primary.main}67`,
		paddingLeft: 20,
	},
	tableRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	tipoPagamentoIcon: {
		color: theme.palette.primary.main,
		fontSize: 20,
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
	},
	extratoEmpty: {
		height: 120,
	},
	vendasEmpty: {
		height: '100%',
	},
	lancamentosEmpty: {
		height: 230,
	},
	transferenciasEmpty: {
		height: '100%',
	},
	transferenciaDe: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	transferenciaPara: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		textAlign: 'left',
		overflow: 'hidden',
	},
	hide: {
		display: 'none !important',
	},
}));
