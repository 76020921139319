import React from 'react';
import { Card, CardContent, Grid, Avatar, Typography } from '@material-ui/core';

const Resume = props => (
	<Card {...props}>
		<CardContent>
			<Grid container spacing={3}>
				<Grid item xs={2} sm={2}>
					<Avatar
						style={{
							backgroundColor: props.color,
							height: 39,
							width: 39,
						}}
					>
						<props.icon />
					</Avatar>
				</Grid>
				<Grid item xs={10} sm={10}>
					<Typography style={{ textAlign: 'center' }} color="textPrimary" variant="h6">
						{props.title}
					</Typography>
					<Typography variant="h5" style={{ textAlign: 'center' }}>
						<span
							style={{
								color: props.amountColor,
							}}
						>
							{props.amount}
						</span>
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={1} justifyContent="flex-end" className={props.classe}>
				<Grid item xs={4} sm={4}>
					{props.titulo1}
				</Grid>
				<Grid item xs={4} sm={4}>
					<span style={{ color: props.desc1Color }}>{props.desc1}</span>
				</Grid>
				<Grid item xs={4} sm={4}>
					{props.percent1}
				</Grid>
			</Grid>
			<Grid container spacing={1} justifyContent="flex-end" className={props.classe}>
				<Grid item xs={4} sm={4}>
					{props.titulo2}
				</Grid>
				<Grid item xs={4} sm={4}>
					<span style={{ color: props.desc2Color }}>{props.desc2}</span>
				</Grid>
				<Grid item xs={4} sm={4}>
					{props.percent2}
				</Grid>
			</Grid>
			<Grid container spacing={1} justifyContent="flex-end" className={props.classe}>
				<Grid item xs={4} sm={4}>
					{props.titulo3}
				</Grid>
				<Grid item xs={4} sm={4}>
					<span style={{ color: props.desc3Color }}>{props.desc3}</span>
				</Grid>
				<Grid item xs={4} sm={4}>
					{props.percent3}
				</Grid>
			</Grid>
			<Grid container spacing={1} justifyContent="flex-end" className={props.classe}>
				<Grid item xs={4} sm={4}>
					{props.titulo4}
				</Grid>
				<Grid item xs={4} sm={4}>
					<span style={{ color: props.desc4Color }}>{props.desc4}</span>
				</Grid>
				<Grid item xs={4} sm={4}>
					{props.percent4}
				</Grid>
			</Grid>
			<Grid container spacing={1} justifyContent="flex-end" className={props.classe}>
				<Grid item xs={4} sm={4}>
					{props.titulo5}
				</Grid>
				<Grid item xs={4} sm={4}>
					<span style={{ color: props.desc5Color }}>{props.desc5}</span>
				</Grid>
				<Grid item xs={4} sm={4}>
					{props.percent5}
				</Grid>
			</Grid>
		</CardContent>
	</Card>
);

export default Resume;
