/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import Validator from 'validator';

function ZSelect(props) {
	const [value, setValue] = React.useState(props.value || false);
	const [error, setError] = React.useState(false);

	var width = props.width || '100%';

	function handleChange(event) {
		setError(false);
		setValue(event.target.value);
		handleValidation(event);
		if (typeof props.onChange === 'function') {
			props.onChange(event);
		}
	}

	React.useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	React.useEffect(() => {
		if (props.onError === true) {
			var event = {
				target: {
					value: value,
				},
			};

			handleValidation(event);
		}
	}, [props.onError]);

	const handleValidation = event => {
		if (props.required) {
			if (
				event.target.value < 1 ||
				event.target.value === false ||
				event.target.value === 0 ||
				event.target.value === ''
			) {
				setError('Escolha ao menos uma opção.');
				return false;
			}
		}

		if (props.email) {
			// console.log(Validator.isEmail(event.target.value));
			if (!Validator.isEmail(event.target.value)) {
				setError('E-mail inválido');
				return false;
			}
		}
	};

	const { onError, ...rest } = props;

	return (
		<FormControl style={{ marginTop: 15, width: width }}>
			<InputLabel htmlFor="age-simple">{props.label}</InputLabel>
			<Select
				{...rest}
				value={value}
				onChange={handleChange}
				inputProps={{
					name: 'gender',
					id: 'gender-simple',
				}}
			>
				{props.options.map((value, key) => {
					return (
						<MenuItem key={key} value={value.id}>
							{value.name}
						</MenuItem>
					);
				})}
			</Select>
			<div style={{ color: 'red' }}>{error}</div>
		</FormControl>
	);
}

export default ZSelect;
