import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	parametros: {},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	configBox: {
		position: 'relative',
		padding: 10,
	},
	configHeader: {
		fontWeight: 700,
		fontSize: 16,
		margin: 4,
	},
	configUpdateBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		paddingBottom: 15,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	configOption: {
		textAlign: 'center',
		margin: 10,
	},
	icon: {
		color: theme.palette.primary.main,
	},
	updateCheck: {
		position: 'absolute',
		top: 10,
		right: 10,
	},
	tabPanels: {
		paddingTop: 10,
	},
}));
