/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ZLoader, ZTable, ZPaginationDiv, ZFiltros, ZTitle } from '../system';
import config from 'react-global-configuration';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogContent, Paper, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { Isset } from '../../utils';
import FileDownload from 'js-file-download';
import { addMessage } from '../../store/alert';
import { FaFilter } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FiDownload } from 'react-icons/fi';

const useStyles = makeStyles(theme => ({
	tabela: {
		'& .MuiTableRow-root': {
			cursor: 'pointer',
		},
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	filtros: {
		position: 'relative',
		borderRadius: 20,
		marginBottom: 30,
		border: `solid 1px ${theme.palette.primary.main}67`,
		padding: '5px 10px',
		display: 'flex',
		alignItems: 'center',
	},
	export: {
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 25,
		margin: 5,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 28,
			opacity: 1,
		},
	},
	btn: {
		backgroundColor: `${theme.palette.primary.main}`,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		margin: 4,
		borderWidth: 'px',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}`,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
}));

const PlanosDeVendas = () => {
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const history = useHistory();
	const [planos, setPlanos] = useState([]);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const [showExportMessage, setShowExportMessage] = useState(false);

	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');
	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const canViewExportar = [1, 7, 10].includes(usuario.grupo_id);

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);

	const queryFilter = {
		omni: '',
		...filtersCache,
	};

	const [filtros, setFiltros] = useState({
		...queryFilter,
	});

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const filtrosChange = async data => {
		setPage(1);
		setFiltros(data);
	};

	const getPlanos = async () => {
		setLoading(true);
		let res = await axios.get(`${config.get('apiUrl')}planos_de_vendas`, {
			params: {
				limit: rowsPerPage,
				page,
				filtros,
			},
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			setPlanos(res.data.planos);
			setTotalRows(res.data.totalRows);
		}
		setLoading(false);
	};
	useEffect(() => {
		getPlanos();
	}, [filtros, page, rowsPerPage]);

	const exportXls = async () => {
		setShowExportMessage(true);
		const result = await axios.get(`${config.get('apiUrl')}export/getPlanos/`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
		});

		if (result.status !== 200 || !Isset(() => result.data)) {
			setShowExportMessage(false);
			return dispatch(addMessage({ type: 'error', message: 'Erro ao exportar relatório.' }));
		}

		const { 'content-disposition': contentDisposition, 'content-type': contentType } = result.headers;
		let filename = 'performancePorEstabelecimento.xlsx';
		if (contentDisposition.indexOf('filename="') !== -1) {
			filename = contentDisposition.slice(
				contentDisposition.indexOf('filename="') + 10,
				contentDisposition.length - 1
			);
		}

		FileDownload(result.data, filename, contentType);
		setShowExportMessage(false);
	};

	return (
		<div>
			<ZTitle title="Planos de Vendas" display="flex" textAlign="center" fontSize="50px" />
			<Grid>
				<Link style={{ textDecoration: 'none' }} to={`/planos-de-vendas/negociacao`}>
					<Button className={classes.btn}>Ir p/ Negociação</Button>
				</Link>
			</Grid>
			<Paper className={classes.tabela}>
				<div style={{ position: 'relative' }}>
					<Paper style={{ marginBottom: 10, padding: '5px 10px' }}>
						<div
							style={{
								position: 'relative',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<FaFilter />
							<ZFiltros
								onChange={filtrosChange}
								omni={{
									// default: filtros.omni,
									placeholder: 'Pesquisa por Plano de Venda',
								}}
							/>
							{canViewExportar && (
								<FiDownload fontSize="large" className={classes.export} onClick={exportXls} />
							)}
						</div>
					</Paper>

					{loading ? (
						<ZLoader height={200} />
					) : (
						<>
							<ZTable
								headCells={[
									{ id: 'id', numeric: false, disablePadding: true, label: 'ID' },
									{
										id: 'name',
										numeric: false,
										disablePadding: false,
										label: 'Título',
									},
									{
										id: 'description',
										numeric: false,
										disablePadding: false,
										label: 'Descrição',
									},
									{
										id: 'antecipado',
										numeric: false,
										disablePadding: false,
										label: 'Antecipado',
										filter: value => (value ? 'Sim' : 'Não'),
									},
								]}
								tableTitle=""
								toolBar={false}
								rows={planos}
								rowClick={plano => history.push(`/planos-de-vendas/${plano}`)}
								densePadding={false}
								checkBox={false}
								rowsPerPage={[15, 30, 60]}
								rowsPerPageDefault={15}
								pagination={false}
							/>

							<ZPaginationDiv
								count={totalRows}
								rowsPerPage={rowsPerPage}
								page={page - 1}
								onChangePage={onChangePage}
								onChangeRowsPerPage={onChangeRowsPerPage}
								rowsPerPageOptions={[15, 30, 60]}
							/>
						</>
					)}
				</div>
			</Paper>

			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default PlanosDeVendas;
