import React, { useState, useEffect } from 'react';
import { ResponsiveLine } from 'nivo';
import { makeStyles } from '@material-ui/core/styles';
import { Formatter } from '../../../utils';

const colors = ['#ea6061', '#005797', '#ffa550'];

const useStyles = makeStyles(theme => ({
	chart: {
		height: 180,
		width: '100%',
	},
}));

const LancamentosFuturosChart = props => {
	const classes = useStyles();
	const [data, setData] = useState([]);

	useEffect(() => {
		if (props.data.length > 0) {
			const d = [
				{
					id: 'Lançamentos',
					data: props.data.map(a => ({ x: a.mes, y: +a.value })),
				},
			];
			setData(d);
		}
	}, [props.data]);

	return (
		<div className={classes.chart}>
			{data.length > 0 && (
				<ResponsiveLine
					data={data}
					margin={{ top: 20, right: 20, bottom: 30, left: 20 }}
					xScale={{ type: 'point' }}
					yScale={{
						type: 'log',
						base: 10,
						max: 'auto',
					}}
					curve="natural"
					enableGridX={false}
					enableGridY={false}
					colors={colors}
					pointColor={{ theme: 'background' }}
					pointBorderWidth={2}
					pointBorderColor={{ from: 'serieColor', modifiers: [] }}
					tooltipFormat={value => Formatter.Real(value)}
					enableArea={true}
					areaOpacity={0.1}
					useMesh={true}
					axisLeft={null}
					motionStiffness={160}
				/>
			)}
		</div>
	);
};

export default LancamentosFuturosChart;
