import React, { useState } from 'react';
//import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { Tab, Tabs } from '@material-ui/core';

import PlanosTaxas from './PlanosTaxas';
import Parametros from './Parametros';
import SMTP from './SMTP';

const useStyles = makeStyles(theme => ({
	// configuracoes: {
	// 	fontFamily: "'Exo 2', sans-serif",
	// 	width: '100%',
	// 	flexGrow: 1,
	// },
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	tabPanels: {
		paddingTop: 10,
	},
	configBox: {
		position: 'relative',
		padding: 10,
		minHeight: 150,
	},
	configHeader: {
		fontWeight: 700,
		fontSize: 16,
		margin: 4,
	},
	configUpdateBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		paddingBottom: 15,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	colorPicker: {
		'& .MuiTextField-root': {
			width: '100% !important',
		},
	},
	icon: {
		color: theme.palette.primary.main,
	},
	updateCheck: {
		position: 'absolute',
		top: 10,
		right: 10,
	},
}));

const MarketplaceConfiguracoes = () => {
	const classes = useStyles();

	// const marketplace = useSelector(state => state.auth.usuario.marketplace);

	const [tabSelected, setTabSelected] = useState(0);

	const handleChange = (e, value) => setTabSelected(value);

	return (
		<div className={classes.configuracoes}>
			<div className={classes.header}>Configurações - Marketplace</div>

			<Tabs value={tabSelected} onChange={handleChange} centered>
				<Tab label="Parâmetros" value={0} />
				<Tab label="Tabelas de Markup" value={1} />
				<Tab label="SMTP" value={2} />
			</Tabs>
			<div className={classes.tabPanels}>
				{tabSelected === 0 && <Parametros marketplaceId={8} />}
				{tabSelected === 1 && <PlanosTaxas marketplaceId={8} />}
				{tabSelected === 2 && <SMTP />}
			</div>
		</div>
	);
};

export default MarketplaceConfiguracoes;
