import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	extrato: {
		width: '100%',
		flexGrow: 1,
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	filtros: {
		position: 'relative',
		borderRadius: 20,
		marginBottom: 30,
		border: `solid 1px ${theme.palette.primary.main}67`,
		padding: '5px 10px',
		display: 'flex',
		alignItems: 'center',
	},
	export: {
		position: 'absolute',
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 18,
	},
	tableDateRow: {
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		backgroundColor: `${theme.palette.primary.main}aa`,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		paddingLeft: 20,
	},
	tableRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	negativo: {
		color: `#ff5440 !important`,
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 200,
	},
	hideOnXs: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
}));
