/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { logout } from '../../store/auth';
import useReactRouter from 'use-react-router';
import { useDispatch } from 'react-redux';

const Logout = props => {
	const { history } = useReactRouter();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(logout());
		history.push('/login');
	}, []);

	return <div>Logout</div>;
};

export default Logout;
