/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Paper, Button, Grid, TextField } from '@material-ui/core';
import { Isset } from '../../utils';
import { addMessage } from '../../store/alert';
import axios from 'axios';
import config from 'react-global-configuration';
import { useStyles } from './styles/PlanosTaxasStyle';

const EstabelecimentoPlanosTaxasAdd = props => {
	const { estabelecimentoId } = useParams();
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const history = useHistory();
	const dispatch = useDispatch();

	const [titulo, setTitulo] = useState('');

	const btnAddClick = async () => {
		if (!titulo) {
			dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário.' }));
			return false;
		}

		const res = await axios.post(
			`${config.get('apiUrl')}planos_taxas/porMarketplaceFilho/${estabelecimentoId}`,
			{
				titulo,
			},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success && Isset(() => res.data.planoTaxa.id)) {
			history.push(`/estabelecimentos/${estabelecimentoId}/planos-taxas/${res.data.planoTaxa.id}`);
		}
	};

	return (
		<div className={classes.planosTaxasAdd}>
			<div className={classes.header}>Nova Tabela de Markup</div>

			<Paper className={classes.planoTaxaPaper}>
				<Grid container spacing={2} justifyContent="center" alignItems="center">
					<Grid item xs={3}>
						<TextField
							label="Título"
							value={titulo}
							onChange={e => setTitulo(e.target.value)}
							style={{ width: '100%' }}
						/>
					</Grid>
					<Grid item xs={3}>
						<Button variant="contained" color="primary" className={classes.btnAdd} onClick={btnAddClick}>
							Adicionar
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
};

export default EstabelecimentoPlanosTaxasAdd;
