import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FaExclamationTriangle } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
	notFound: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		flexDirection: 'column',
		height: 300,
		width: '100%',
	},
	icon: {
		fontSize: 50,
		color: '#ec4747',
	},
	text: {
		fontSize: 26,
		fontVariant: 'small-caps',
	},
}));

const ZNotFound = props => {
	const classes = useStyles();

	return (
		<div className={classes.notFound}>
			<FaExclamationTriangle className={classes.icon} />
			<div className={classes.text}>{props.text || 'Página não encontrada.'}</div>
		</div>
	);
};

export default ZNotFound;
