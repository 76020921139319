/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Button, Chip, Container, Grid, Paper, Typography } from '@material-ui/core';
import { Formatter, Mask } from '../../utils';
import { makeStyles } from '@material-ui/core/styles';
import { ZTitle, ZLoader, ZModalConfirm } from '../system';
import { addMessage } from '../../store/alert';
import { useSelector, useDispatch } from 'react-redux';
import config from 'react-global-configuration';
import useReactRouter from 'use-react-router';
import { format } from 'date-fns';
import { FaDownload } from 'react-icons/fa';
import QRCode from 'qrcode.react';
import saveSvgAsPng from 'save-svg-as-png';
import SellingCampaignDetailsList from './SellingCampaignDetailsList';

const useStyles = makeStyles(theme => ({
	layout: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 900,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		// marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			// marginTop: theme.spacing(6),
			marginBottom: theme.spacing(6),
			padding: theme.spacing(4),
		},
	},
	menuIcon: {
		fontSize: 24,
		cursor: 'pointer',
		marginRight: 4,
	},
	estabelecimento: {
		padding: '6px 6px',
		marginBottom: 10,
	},
	estabelecimentoValor: {
		'&.porcentagem input': {
			textAlign: 'right',
		},
	},
	estabelecimentoIcone: {
		border: 'solid 1px #abc',
		marginRight: 2,
		marginLeft: 2,
		marginTop: 16,
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}`,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	estabelecimentoError: {
		fontSize: 12,
		color: '#e53935',
	},
	label: {
		textAlign: 'center',
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	labelResumo: {
		// marginTop: 16,
	},
	removeButton: {
		minHeight: 26,
		height: 26,
		width: 26,
		backgroundColor: '#e53935',
		color: 'white',
	},
	novaRegraSplit: {
		textAlign: 'center',
		textTransform: 'uppercase',
		margin: 8,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	box: {
		textTransform: 'uppercase',
		fontSize: 12,
		position: 'relative',
		padding: 10,
		height: 110,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}55`,
		},
		'& .value': {
			fontWeight: 700,
			fontSize: 13,
		},
	},
	splitAtivo: {
		position: 'absolute',
		top: -8,
		right: 10,
		fontWeight: 700,
		color: '#e53935',
		'&.ativo': {
			color: 'green',
		},
	},
	divider: {
		margin: 4,
	},
	span: {
		color: '#777',
		fontSize: 14,
	},
	removerButton: {
		backgroundColor: '#e53935',
		color: 'white',
	},
	statusButton: {
		backgroundColor: '#e53935',
		color: 'white',
		'&.ativar': {
			backgroundColor: 'green',
		},
	},
	divButton: {
		marginLeft: 'auto',
		marginTop: 64,
		[theme.breakpoints.down('xs')]: {
			margin: '64px auto 0',
			width: '100%',
		},
	},
	error: {
		padding: 8,
		borderRadius: 5,
		backgroundColor: '#e53935',
		color: 'white',
		marginBottom: 10,
	},
	subheader: {
		fontWeight: 700,
		fontSize: 18,
		fontVariant: 'small-caps',
		letterSpacing: '0.02em',
		margin: '20px 8px',
		marginTop: -30,
	},
	valorLiquido: {
		'& .MuiFormControlLabel-label': {
			fontSize: 11,
			fontWeight: 'bold',
			textAlign: 'center',
		},
	},
	qrcodeHover: {
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		opacity: 0,
		height: '100%',
		position: 'absolute',
		width: '100%',
		background: '#fafafa',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 100,
		top: 0,
		left: 0,
		'&:hover': {
			opacity: 0.7,
		},
	},
	planosTaxas: {
		position: 'relative',
	},
	tiposPagamentos: {
		position: 'absolute',
		paddingTop: 50,
		paddingBottom: 52,
		border: 'solid 1px #abc',
		borderRadius: 10,
		padding: 10,
		width: 'calc(100% - 30px)',
	},
	planos: {
		position: 'relative',
		padding: 14,
		height: 50,
		fontSize: 14,
		fontWeight: 600,
		'& span': {
			fontWeight: 'bold',
			fontSize: 16,
		},
	},
	taxaBoletoDiv: {
		marginTop: 10,
		position: 'relative',
		padding: 10,
		height: 50,
	},
	tipoPagamento: {
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
		'&:not(:last-child)': {
			borderBottom: 'solid 1px #bcd',
		},
	},
	tipoPagamentoBoleto: {
		float: 'left',
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
	},
	bandeirasWrapper: {
		marginLeft: 120,
		width: 'calc(100% - 160px)',
		overflowX: 'auto',
	},
	bandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffffc0',
		height: 490,
		width: 105,
		marginRight: 10,
		float: 'left',
	},
	novaBandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffff',
		width: 160,
		marginRight: 10,
		float: 'left',
	},
	title: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 16,
		height: 22,
	},
	titleLong: {
		fontSize: 11,
	},
	deleteButton: {
		top: 2,
		right: 2,
		cursor: 'pointer',
		position: 'absolute',
		width: 20,
		height: 20,
		color: '#e53935',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		opacity: 0.5,
		'&:hover': {
			opacity: 1,
		},
	},
	fields: {
		marginTop: 8,
		marginBottom: 15,
		borderTop: 'solid 1px #bcd',
	},
	tipoPagamentoFields: {
		borderBottom: 'solid 1px #bcd',
	},
	taxaField: {
		padding: 7.45,
		'&::after': {
			content: '"%"',
			position: 'absolute',
			right: 4,
			fontSize: 12,
		},
		'& .MuiInputBase-input': {
			textAlign: 'right',
			height: '1em',
			padding: 0,
			paddingRight: 15,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -7,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
	},
	saveButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		marginTop: 5,
	},
	adicionarButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		marginTop: 15,
	},
	taxaFieldBoletoReadOnly: {
		marginLeft: 120,
		height: 30.8,
		fontWeight: 'bold',
		fontSize: 15,
		padding: 4,
	},
	taxaFieldReadOnly: {
		height: 30.8,
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 15,
		padding: 4,
	},
	btnCalculadora: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.primary.light,
		},
		marginRight: 10,
		'& .MuiButton-label': {
			display: 'flex',
			flexDirection: 'column',
		},
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 100,
	},
	pedidoRow: {
		borderBottom: 'solid 1px #abc',
	},
	notFound: {
		textAlign: 'center',
		fontSize: 20,
		fontWeight: 'bold',
		fontVariant: 'small-caps',
	},
	backButton: {
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		marginRight: 10,
	},
	checkout: {
		backgroundColor: '#E9C46A',
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
	inactivateButton: {
		backgroundColor: '#DF5937',
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
	activateButton: {
		backgroundColor: '#2A9D8F',
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
	editButton: {
		backgroundColor: '#2572E6',
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
}));

const SellingCampaignDetails = () => {
	const { history, match } = useReactRouter();
	const dispatch = useDispatch();

	const { sellingCampaignId } = match.params;

	const [editCampaign, setEditCampaign] = useState({
		sellingCampaignId: null,
		open: false,
	});
	const qrCodeRef = React.createRef();

	const [inactivateCampaign, setInactivateCampaign] = useState({
		sellingCampaignId: null,
		open: false,
	});

	const token = useSelector(store => store.auth.token);
	const classes = useStyles();

	const [sellingCampaign, setSellingCampaign] = useState(null);
	const [loading, setLoading] = useState(true);

	var env = '';
	switch (process.env.REACT_APP_ENV) {
		case 'development':
			env = 'devpay';
			break;
		case 'qas':
			env = 'qaspay';
			break;
		case 'production':
			env = 'pay';
			break;
		default:
			env = 'pay';
			break;
	}

	useEffect(() => {
		getSellingCampaign();
	}, []);

	const formattedData = useMemo(() => {
		if (sellingCampaign) {
			return {
				until: sellingCampaign.until ? format(sellingCampaign.until, 'DD/MM/YYYY') : '',
				amount: Mask.Real(sellingCampaign.amount / 100),
				logo: sellingCampaign.logo ? 'Sim' : 'Não',
				installments:
					sellingCampaign.installments === 1
						? `${sellingCampaign.installments} vez`
						: `${sellingCampaign.installments} vezes`,
				credit_card: sellingCampaign.credit_card ? 'Cartão de Crédito' : '',
				bank_slip: sellingCampaign.bank_slip ? 'Boleto' : '',
				pix: sellingCampaign.pix ? 'Pix' : '',
				juros_a_partir: sellingCampaign.juros_a_partir < 2 ? 'N/A' : `${sellingCampaign.juros_a_partir} vezes`,
				installments_payer: sellingCampaign.installments_payer ? 'Sim' : 'Não',
				created: format(sellingCampaign.created, 'DD/MM/YYYY HH:mm:ss'),
			};
		}
	}, [sellingCampaign]);

	const getSellingCampaign = async () => {
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}sellingCampaign/${sellingCampaignId}`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		if (res.data.success) {
			setSellingCampaign(res.data.sellingCampaign);
		}
		setLoading(false);
	};

	const getCountAmount = sellingCampaign => {
		let totalCount = 0;
		if (sellingCampaign.selling_campaign_pedidos) {
			for (let x = 0; x < sellingCampaign.selling_campaign_pedidos.length; x++) {
				if (
					sellingCampaign.selling_campaign_pedidos[x].pedido &&
					sellingCampaign.selling_campaign_pedidos[x].pedido.status_pedido_id === 2
				) {
					totalCount++;
				}
			}
		}
		return +totalCount;
	};

	const getTotalAmount = sellingCampaign => {
		let totalAmount = 0;
		if (sellingCampaign.selling_campaign_pedidos) {
			for (let x = 0; x < sellingCampaign.selling_campaign_pedidos.length; x++) {
				if (
					sellingCampaign.selling_campaign_pedidos[x].pedido &&
					sellingCampaign.selling_campaign_pedidos[x].pedido.status_pedido_id === 2
				) {
					totalAmount = totalAmount + sellingCampaign.amount / 100;
				}
			}
		}
		return Formatter.Real(+totalAmount);
	};

	const downloadQRCode = () => {
		const svg = qrCodeRef.current.getElementsByTagName('svg')[0];
		saveSvgAsPng.saveSvgAsPng(svg, 'qrcode.png', {
			scale: 5,
			encoderOptions: 1,
			backgroundColor: 'white',
		});
	};

	const handleEditCampaign = result => {
		setEditCampaign({ ...editCampaign, open: false, sellingCampaignId: null });

		if (result) {
			history.push('/sellingCampaign/edit/' + sellingCampaign.id);
		}
	};

	const handleInactivateCampaign = result => {
		var data = '';
		setInactivateCampaign({ ...inactivateCampaign, open: false, sellingCampaignId: null });

		if (result) {
			axios
				.post(`${config.get('apiUrl')}sellingCampaign/changeStatus/${sellingCampaignId}`, data, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						let statusCampaign = sellingCampaign.status ? 'inativa' : 'ativa';
						dispatch(addMessage({ type: 'warning', message: 'Processando sua requisição' }));
						setTimeout(function() {
							getSellingCampaign();
							dispatch(
								addMessage({ type: 'success', message: `A Campanha foi ${statusCampaign} com sucesso` })
							);
						}, 2000);
					}
				});
		}
	};

	const getStatusLabel = sellingCampaign => {
		switch (+sellingCampaign.status) {
			case 0:
				return (
					<Chip
						size="small"
						label="Inativo" // vermelho
						style={{
							backgroundColor: '#DF5937',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
			case 1:
				return (
					<Chip
						size="small"
						label="Ativo" // verde
						style={{
							backgroundColor: '#2A9D8F',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
			default:
				return (
					<Chip
						size="small"
						label="Não Mapeado"
						style={{
							backgroundColor: '#000000',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
		}
	};

	return (
		<div className={classes.sellingCampaign}>
			{loading ? (
				<ZLoader height={200} />
			) : (
				<>
					{sellingCampaign === null ? (
						<div className={classes.notFound}>Campanha de Vendas não encontrada.</div>
					) : (
						<React.Fragment>
							<Container maxWidth="lg">
								<Grid container>
									{!match.params.sellingCampaignId && (
										<ZTitle title={'Campanha de Vendas'} textAlign={'center'} />
									)}
									{match.params.sellingCampaignId && (
										<ZTitle
											title="Campanha de Vendas"
											button="add"
											handleOnClick={() => history.push(`/sellingCampaign/create`)}
										/>
									)}
									<Grid item xs={12}>
										<Grid container item>
											<Grid item xs={12} md={12} lg={12}>
												<Paper className={classes.paper}>
													<Grid container style={{ marginLeft: 32, position: 'relative' }}>
														<div
															ref={qrCodeRef}
															style={{
																position: 'absolute',
																right: 20,
																height: 128,
																cursor: 'pointer',
															}}
															onClick={downloadQRCode}
														>
															<QRCode
																renderAs="svg"
																value={`https://${env}.1pay.com.br/${sellingCampaign.token}`}
															/>
															<div className={classes.qrcodeHover}>
																<FaDownload />
															</div>
														</div>
														<Grid item style={{ marginBottom: 16 }}>
															<Typography variant="h5" component="h5" gutterBottom>
																{sellingCampaign.id
																	? `ID - ${sellingCampaign.id} `
																	: `Resumo`}
															</Typography>
														</Grid>
														<Grid container spacing={2} item>
															<Grid item md={6} xs={12} className={classes.labelResumo}>
																<Typography variant="body1" component="h5" gutterBottom>
																	Título <br />{' '}
																	<span className={classes.span}>
																		{sellingCampaign.name}
																	</span>
																</Typography>
															</Grid>
															<Grid item md={6} xs={12} className={classes.labelResumo}>
																<Typography variant="body1" component="h5" gutterBottom>
																	Valor <br />{' '}
																	<span className={classes.span}>
																		{formattedData.amount}
																	</span>
																</Typography>
															</Grid>
															<Grid item md={6} xs={12} className={classes.labelResumo}>
																<Typography variant="body1" component="h5" gutterBottom>
																	Descrição <br />{' '}
																	<span className={classes.span}>
																		{sellingCampaign.description}
																	</span>
																</Typography>
															</Grid>
															<Grid item md={6} xs={12} className={classes.labelResumo}>
																<Typography variant="body1" component="h5" gutterBottom>
																	Meio de pagamento <br />{' '}
																	<span className={classes.span}>
																		{' '}
																		{formattedData.credit_card} {formattedData.pix}{' '}
																		{formattedData.bank_slip}
																	</span>
																</Typography>
															</Grid>
															<Grid item md={6} xs={12} className={classes.labelResumo}>
																<Typography variant="body1" component="h5" gutterBottom>
																	Data de expiração <br />{' '}
																	<span className={classes.span}>
																		{formattedData.until}
																	</span>
																</Typography>
															</Grid>
															<Grid item md={6} xs={12} className={classes.labelResumo}>
																<Typography variant="body1" component="h5" gutterBottom>
																	Juros por conta do cliente <br />{' '}
																	<span className={classes.span}>
																		{formattedData.installments_payer}
																	</span>
																</Typography>
															</Grid>
															<Grid item md={6} xs={12} className={classes.labelResumo}>
																<Typography variant="body1" component="h5" gutterBottom>
																	Exibir Logo <br />{' '}
																	<span className={classes.span}>
																		{formattedData.logo}
																	</span>
																</Typography>
															</Grid>
															<Grid item md={6} xs={12} className={classes.labelResumo}>
																<Typography variant="body1" component="h5" gutterBottom>
																	Máximo de parcelas <br />{' '}
																	<span className={classes.span}>
																		{formattedData.installments}
																	</span>
																</Typography>
															</Grid>
															<Grid item md={6} xs={12} className={classes.labelResumo}>
																<Typography variant="body1" component="h5" gutterBottom>
																	Status <br />{' '}
																	<span className={classes.span}>
																		{getStatusLabel(sellingCampaign)}
																	</span>
																</Typography>
															</Grid>
															<Grid item md={6} xs={12} className={classes.labelResumo}>
																<Typography variant="body1" component="h5" gutterBottom>
																	Total Arrecadado <br />{' '}
																	<span className={classes.span}>
																		{getTotalAmount(sellingCampaign)}
																	</span>
																</Typography>
															</Grid>
															<Grid item md={6} xs={12} className={classes.labelResumo}>
																<Typography variant="body1" component="h5" gutterBottom>
																	Total Aprovado <br />{' '}
																	<span className={classes.span}>
																		{getCountAmount(sellingCampaign)}
																	</span>
																</Typography>
															</Grid>
														</Grid>
														<Grid container item spacing={3} xs={12} md={12} lg={12}>
															<Grid item xs={12} md={3} lg={3}>
																<Button
																	onClick={() => {
																		const text = encodeURIComponent(
																			`Campanha de Vendas - ${
																				sellingCampaign.estabelecimento
																					.nome_fantasia
																			}\n\n*${sellingCampaign.name.trim()}* - ${sellingCampaign.description.trim()}\nhttps://${env}.1pay.com.br/${
																				sellingCampaign.token
																			}`
																		);
																		window.open(
																			`https://wa.me/?text=${text}`,
																			'_blank'
																		);
																	}}
																	variant="contained"
																	fullWidth
																	color="primary"
																>
																	Compartilhar no Whatsapp
																</Button>
															</Grid>
															<Grid item xs={12} md={3} lg={3}>
																<Button
																	onClick={() => {
																		window.open(
																			`https://${env}.1pay.com.br/${sellingCampaign.token}`,
																			'_blank'
																		);
																	}}
																	variant="contained"
																	fullWidth
																	color="primary"
																>
																	Tela de Pagamento
																</Button>
															</Grid>
															<Grid item xs={12} md={3} lg={3}>
																<Button
																	onClick={() =>
																		setEditCampaign({
																			...editCampaign,
																			sellingCampaignId: sellingCampaign.id,
																			open: true,
																		})
																	}
																	variant="contained"
																	fullWidth
																	color="primary"
																>
																	Editar Campanha
																</Button>
															</Grid>
															<Grid item xs={12} md={3} lg={3}>
																<Button
																	onClick={() =>
																		setInactivateCampaign({
																			...inactivateCampaign,
																			sellingCampaignId: sellingCampaign.id,
																			open: true,
																		})
																	}
																	variant="contained"
																	fullWidth
																	color="primary"
																>
																	{`${
																		sellingCampaign.status ? 'INATIVAR' : 'ATIVAR'
																	} Campanha`}
																</Button>
															</Grid>
														</Grid>
													</Grid>
												</Paper>
											</Grid>
										</Grid>
									</Grid>
									{sellingCampaignId && sellingCampaign.selling_campaign_pedidos.length > 0 && (
										<SellingCampaignDetailsList sellingCampaignId={sellingCampaignId} />
									)}
									<ZModalConfirm
										title={`Editar Campanha`}
										message={`Deseja EDITAR essa campanha?`}
										open={editCampaign.open}
										onClose={handleEditCampaign}
									/>
									<ZModalConfirm
										title={`${sellingCampaign.status ? 'Inativar' : 'Ativar'}  Campanha`}
										message={`Deseja ${
											sellingCampaign.status ? 'INATIVAR' : 'ATIVAR'
										} essa campanha?`}
										open={inactivateCampaign.open}
										onClose={handleInactivateCampaign}
									/>
								</Grid>
							</Container>
						</React.Fragment>
					)}
				</>
			)}
		</div>
	);
};

export default SellingCampaignDetails;
