/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import config from 'react-global-configuration';

import axios from 'axios';
import clsx from 'clsx';

import { FaDollarSign, FaPercentage } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, IconButton, Paper, Switch, TextField } from '@material-ui/core';

import ParametroInfo from './ParametroInfo';
import { ZLoader } from '../system';

import { Mask } from '../../utils';
import { addMessage } from '../../store/alert';
import { getUserParams } from '../../store/system';

const useStyles = makeStyles(theme => ({
	parametros: {},
	parametro: {
		position: 'relative',
		padding: 18,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
	},
	parametroTitulo: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		fontWeight: 'bold',
		textAlign: 'right',
	},
	actionButtons: {
		marginTop: 10,
		textAlign: 'center',
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 200,
	},
	moneyPercentValor: {
		'&.porcentagem input': {
			textAlign: 'right',
		},
	},
	moneyPercentIcone: {
		border: 'solid 1px #abc',
		marginRight: 2,
		marginLeft: 2,
		marginTop: 16,
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}`,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
}));

export default memo(props => {
	const { marketplaceId } = props;
	const dispatch = useDispatch();

	const classes = useStyles();

	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(state => state.auth.usuario);

	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [parametros, setParametros] = useState([]);

	const getParametros = async () => {
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}parametros/por_marketplace/${marketplaceId}`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			setParametros(res.data.parametros);
		}
		setLoading(false);
	};

	useEffect(() => {
		getParametros();
	}, []);

	const handleChange = id => e => {
		const result = parametros.map(p => {
			if (p.id === id) {
				switch (p.tipo) {
					case 'moneyPercent': {
						p.valor = Mask.OnlyNumber(e.target.value) / 100;
						if (p.tipoValor === 'percent' && p.valor > 100) {
							p.valor = 100;
						}
						break;
					}
					case 'money': {
						p.valor = Mask.OnlyNumber(e.target.value) / 100;
						break;
					}
					case 'text': {
						p.valor = e.target.value;
						break;
					}
					case 'boolean':
					default:
						p.valor = e.target.checked;
				}
			}
			return p;
		});
		setParametros(result);
	};

	const handleSubmit = async () => {
		setSaving(true);
		const res = await axios.post(
			`${config.get('apiUrl')}parametros/por_marketplace/${marketplaceId}`,
			{ parametros },
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			dispatch(addMessage({ type: 'success', message: 'Parâmetros salvos com sucesso' }));
			dispatch(getUserParams(usuario));
			getParametros();
		}
		setSaving(false);
	};

	const updateParametroTipoValor = (id, value) => {
		const result = parametros.map(p => {
			if (p.id === id) {
				p.tipoValor = value;
			}
			return p;
		});
		setParametros(result);
	};

	if (loading) {
		return <ZLoader height={200} />;
	}

	let active = false;
	return (
		<div className={classes.parametros}>
			{parametros.length > 0 ? (
				<>
					<Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={2}>
						{parametros.map((a, i) => {
							if (a.id === 9) {
								active = a.valor;
							}

							if (a.id === 10 && !active) return '';

							return (
								<Grid key={a.titulo} item xs={12} sm={6} md={3}>
									<Paper className={classes.parametro}>
										<ParametroInfo title={a.descricao} />
										<Grid container spacing={2}>
											<Grid item className={classes.parametroTitulo} xs={4}>
												{a.titulo}
											</Grid>
											<Grid item style={{ display: 'flex', alignItems: 'center' }} xs={8}>
												{a.tipo === 'boolean' && (
													<Switch checked={a.valor} onChange={handleChange(a.id)} />
												)}
												{a.tipo === 'money' && (
													<TextField
														value={Mask.Real(a.valor)}
														onChange={handleChange(a.id)}
													/>
												)}
												{a.tipo === 'text' && (
													<TextField value={a.valor || ''} onChange={handleChange(a.id)} />
												)}
												{a.tipo === 'moneyPercent' && (
													<>
														<IconButton
															className={clsx(classes.moneyPercentIcone, {
																selected: a.tipoValor === 'money',
															})}
															onClick={() => updateParametroTipoValor(a.id, 'money')}
														>
															<FaDollarSign style={{ fontSize: 14 }} />
														</IconButton>
														<TextField
															value={
																a.tipoValor === 'money'
																	? Mask.Real(a.valor)
																	: Mask.Porcentagem(a.valor)
															}
															className={clsx(
																classes.fullWidth,
																classes.moneyPercentValor,
																{
																	porcentagem: a.tipoValor === 'percent',
																}
															)}
															onChange={handleChange(a.id)}
															onFocus={e => {
																const length = e.target.value.length * 2;
																e.target.setSelectionRange(0, length);
															}}
														/>
														<IconButton
															className={clsx(classes.moneyPercentIcone, {
																selected: a.tipoValor === 'percent',
															})}
															onClick={() => updateParametroTipoValor(a.id, 'percent')}
														>
															<FaPercentage style={{ fontSize: 14 }} />
														</IconButton>
													</>
												)}
											</Grid>
										</Grid>
									</Paper>
								</Grid>
							);
						})}
					</Grid>
					<div className={classes.actionButtons}>
						<Button disabled={saving} variant="contained" color="primary" onClick={handleSubmit}>
							{saving ? <ZLoader size={14} height={24} /> : 'Salvar'}
						</Button>
					</div>
				</>
			) : (
				<div className={classes.empty}>Nenhum Parâmetro Encontrado.</div>
			)}
		</div>
	);
});
