/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import config from 'react-global-configuration';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
import { ZText, ZLoader, ZModalConfirm } from '../system';
import { addMessage } from '../../store/alert';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
	paper: {
		padding: 10,
	},
	configUpdateBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		marginBottom: 15,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		'&:hover': {
			fontSize: 13,
		},
	},
	configResetBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		marginBottom: 15,
		fontWeight: 700,
		fontSize: 10,
		cursor: 'pointer',
		color: '#f44336',
		'&:hover': {
			fontSize: 11,
		},
	},
	passed: {
		textAlign: 'right',
		fontSize: 18,
	},
}));

const SMTP = props => {
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [restaurarSmtp, setRestaurarSmtp] = useState(false);
	const [passed, setPassed] = useState(false);
	const [gettingTest, setGettingTest] = useState(true);
	const [data, setData] = useState({
		smtpHost: '',
		smtpPort: '465',
		smtpUser: '',
		smtpPass: '',
	});

	const testConfig = async () => {
		setGettingTest(true);
		const res = await axios.get(`${config.get('apiUrl')}marketplaces/smtp-config/test`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		setPassed(res.data.success);
		setGettingTest(false);
	};

	const getData = async () => {
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}marketplaces/smtp-config`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			setData(res.data.config);
			testConfig();
		}

		setLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);

	const handleAtualizarClick = async () => {
		const res = await axios.post(`${config.get('apiUrl')}marketplaces/smtp-config`, data, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			dispatch(addMessage({ type: 'success', message: 'Configuração salva com sucesso.' }));
			getData();
		} else {
			dispatch(addMessage({ type: 'error', message: res.data.message }));
		}
	};

	const handleRestaurar = async () => {
		const res = await axios.delete(`${config.get('apiUrl')}marketplaces/smtp-config`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			getData();
		} else {
			dispatch(addMessage({ type: 'error', message: res.data.message }));
		}
		setRestaurarSmtp(false);
	};

	if (loading) {
		return <ZLoader height={200} />;
	}

	return (
		<Grid item container justifyContent="center" alignItems="center">
			<Grid item xs={12} sm={4}>
				<Paper className={classes.paper}>
					<div className={classes.passed}>
						{gettingTest ? (
							<ZLoader height={30} size={14} />
						) : (
							<>{passed ? <FaCheckCircle color="green" /> : <FaTimesCircle color="red" />}</>
						)}
					</div>
					<ZText
						label="SMTP Host"
						onBlur={e => setData({ ...data, smtpHost: e.target.value })}
						value={data.smtpHost}
						placeholder="smtp.dominio.com.br"
					/>

					<ZText
						label="SMTP Port"
						onBlur={e => setData({ ...data, smtpPort: e.target.value })}
						value={data.smtpPort}
						placeholder={465}
					/>

					<ZText
						label="SMTP User"
						onBlur={e => setData({ ...data, smtpUser: e.target.value })}
						value={data.smtpUser}
					/>

					<ZText
						label="SMTP Pass"
						onBlur={e => setData({ ...data, smtpPass: e.target.value })}
						value={data.smtpPass}
					/>

					<div className={classes.configUpdateBtn} onClick={handleAtualizarClick}>
						Atualizar
					</div>

					<div className={classes.configResetBtn} onClick={() => setRestaurarSmtp(true)}>
						Restaurar Configuração
					</div>

					<ZModalConfirm
						title={`Restaurar Configuração`}
						message={`Deseja restaurar as configurações de SMTP?`}
						open={restaurarSmtp}
						onClose={handleRestaurar}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default SMTP;
