/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import clsx from 'clsx';

import { Isset } from '../../../utils';

import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, Chip, Tooltip } from '@material-ui/core';
import { FaTimes } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
	filtros: {
		position: 'relative',
		display: 'inline-flex',
	},
	filtroText: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		padding: '5px 10px',
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 30,
		marginLeft: 10,
		fontWeight: 700,
		fontSize: 12,
		color: theme.palette.primary.main,
	},
	filtroTextSelectedMultiple: {
		backgroundColor: `${theme.palette.primary.main}`,
		color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		'& .MuiSelect-select': {
			color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		},
		padding: '2px 10px',
		minHeight: 29,
		maxWidth: 340,
	},
	clearBtn: {
		marginLeft: 6,
	},
	filtroSelect: {
		width: '95%',
		fontWeight: 700,
		fontSize: 12,
		'&:before': {
			borderBottom: 'none !important',
		},
		'&:after': {
			borderBottom: 'none !important',
		},
		'& .MuiSelect-select': {
			padding: '2px 10px !important',
			marginRight: 10,
		},
	},
	chips: {
		display: 'flex',
		flexWrap: 'nowrap',
	},
	chip: {
		margin: 2,
		height: 16,
		maxWidth: 130,
		justifyContent: 'flex-start',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'& .MuiChip-label': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: 'inline-block',
		},
	},
	hasMore: {
		width: 44,
	},
	menuItem: {
		flexDirection: 'column',
	},
}));

const statusSellingCampaigns = [
	{ slug: '1', name: 'Ativo' },
	{ slug: '2', name: 'Inativo' },
];

const SellingCampaign = props => {
	const classes = useStyles();

	let value = Isset(() => props.multiple) && props.multiple ? [] : '';
	if (Isset(() => props.defaultValue) && props.defaultValue) {
		value = props.defaultValue;
	}

	const [statusSellingCampaign, setStatusSellingCampaign] = useState(value);

	const statusSellingCampaignChange = e => {
		let value = e.target.value;
		if (Array.isArray(value)) {
			value = value.map(a => `${a}`).filter((v, i, self) => self.indexOf(v) === i);
		}
		setStatusSellingCampaign(value);
	};

	const statusSellingCampaignClose = e => {
		if (props.onChange) {
			props.onChange(statusSellingCampaign);
		}
	};

	const statusSellingCampaignClear = e => {
		const isMultiple = Isset(() => props.multiple) ? props.multiple : false;
		e.stopPropagation();
		const value = isMultiple ? [] : '';
		setStatusSellingCampaign(value);
		if (props.onChange) {
			props.onChange(value);
		}
	};

	return (
		<div className={classes.statusSellingCampaign}>
			{statusSellingCampaigns && (
				<div
					className={clsx({
						[classes.filtroText]: true,
						[classes.filtroTextSelectedMultiple]: Array.isArray(statusSellingCampaign)
							? statusSellingCampaign.length > 0
							: statusSellingCampaign,
					})}
				>
					<Select
						multiple={props.multiple || false}
						className={classes.filtroSelect}
						value={statusSellingCampaign}
						onChange={statusSellingCampaignChange}
						onClose={statusSellingCampaignClose}
						displayEmpty
						renderValue={selected => {
							if (!selected || selected.length === 0) {
								return <em>Status</em>;
							}

							let aux = statusSellingCampaigns.filter(a => selected.includes(a.slug)).map(a => a.name);
							let hasMore = 0;
							let hasMoreText = '';
							if (aux.length > 3) {
								hasMore = aux.length - 3;
								hasMoreText = aux.slice(3).join(', ');
								aux = aux.slice(0, 3);
							}
							return (
								<div className={classes.chips}>
									{aux.map((value, i) => (
										<Chip key={`${value}${i}`} label={value} className={classes.chip} />
									))}
									{hasMore > 0 && (
										<Tooltip title={hasMoreText}>
											<Chip
												label={`+${hasMore}`}
												className={clsx(classes.chip, classes.hasMore)}
											/>
										</Tooltip>
									)}
								</div>
							);
						}}
					>
						<MenuItem disabled value="">
							<em>Status</em>
						</MenuItem>
						{statusSellingCampaigns.map((statusSellingCampaign, i) => (
							<MenuItem key={i} value={statusSellingCampaign.slug} className={classes.menuItem}>
								{statusSellingCampaign.name}
							</MenuItem>
						))}
					</Select>
					{statusSellingCampaign && (
						<FaTimes className={classes.clearBtn} onClick={statusSellingCampaignClear} />
					)}
				</div>
			)}
		</div>
	);
};

export default SellingCampaign;
