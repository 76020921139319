/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from 'react-global-configuration';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { ZLoader, ZModalConfirm, ZPaginationDiv, ZFiltros } from '../system';
import PlanoDeVenda from './PlanoDeVenda';
import { FaFilter } from 'react-icons/fa';
import { Collapse, Dialog, DialogContent, DialogTitle, Grid, Paper, Zoom } from '@material-ui/core';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { makeStyles } from '@material-ui/styles';
import { Formatter } from '../../utils';
import { Isset } from '../../utils';

const getFormaDePagamento = formaDePagamento => {
	switch (formaDePagamento) {
		case 'boleto':
			return 'Boleto';
		case 'credit':
			return 'Crédito';
		case 'debit':
			return 'Débito';
		default:
			return null;
	}
};

const useStyles = makeStyles(theme => ({
	chevron: {
		position: 'absolute',
		right: 10,
		top: 10,
	},
	brandTitle: {
		textAlign: 'center',
		fontWeight: 700,
	},
	taxa: {
		position: 'relative',
		cursor: 'pointer',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		marginTop: 5,
		borderRadius: 5,
		padding: 6,
		fontWeight: 700,
	},
	taxaDetalhes: {
		width: '98%',
		borderWidth: 1,
		borderColor: theme.palette.primary.main,
		borderStyle: 'solid',
		borderRadius: 5,
		padding: 6,
		margin: 'auto',
		marginTop: -5,
		paddingTop: 10,
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 200,
	},
}));

const Main = props => {
	const { match, location } = useReactRouter();

	const classes = useStyles();

	let { estabelecimentoId } = match.params;

	if (!estabelecimentoId) {
		({ estabelecimentoId } = props);
	}

	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);

	const { pathname } = location;
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');
	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const [loading, setLoading] = useState(true);
	const [planos, setPlanos] = useState([]);
	const [planoAtivoId, setPlanoAtivoId] = useState(null);

	const [viewPlanoId, setViewPlanoId] = useState(null);
	const [viewOpen, setViewOpen] = useState(false);
	const [viewPlano, setViewPlano] = useState(null);
	const [active, setActive] = useState(null);
	const [selectPlan, setSelectPlan] = useState({
		open: false,
		id: null,
	});

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(32);
	const [totalRows, setTotalRows] = useState(0);

	const queryFilter = {
		omni: '',
		...filtersCache,
	};

	const [filtros, setFiltros] = useState({
		...queryFilter,
	});
	const [first, setFirst] = useState([]);

	useEffect(() => {
		setLoading(true);
		const axiosCancelSource = axios.CancelToken.source();

		const getPlanos = async () => {
			let res = await axios.get(`${config.get('apiUrl')}planos_de_vendas`, {
				params: {
					visible: +![1, 7, 10].includes(usuario.grupo_id),
					limit: rowsPerPage,
					page,
					filtros,
				},
				cancelToken: axiosCancelSource.token,
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				setPlanos(res.data.planos);
				setTotalRows(res.data.totalRows);
			}
		};

		const getPlanoEstabelecimento = async () => {
			const axiosCancelSource = axios.CancelToken.source();

			let res = await axios.get(`${config.get('apiUrl')}planos_de_vendas/estabelecimento/${estabelecimentoId}`, {
				cancelToken: axiosCancelSource.token,
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success && Isset(() => res.data.plano.zoop_plan_id)) {
				setPlanoAtivoId(res.data.plano.zoop_plan_id);
			}
		};
		getPlanos();
		getPlanoEstabelecimento();

		return async () => {
			await axiosCancelSource.cancel('Plano de Vendas Unmounted.');
		};
	}, [filtros, page, rowsPerPage]);

	const insert = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)];

	useEffect(() => {
		const getFirst = async () => {
			let res = await axios.get(`${config.get('apiUrl')}planos_de_vendas/${planoAtivoId}`, {
				headers: { Authorization: `Bearer ${token}` },
			});
			if (res.data.success) {
				delete res.data.plano.fees;
				res.data.plano.fees = [];
				const final = planos.filter(item => item.id !== res.data.plano.id);
				const result = insert(final, 0, res.data.plano);
				setFirst(result);
			}
		};
		getFirst();
		setLoading(false);
	}, [planoAtivoId, planos]);
	const onSelect = async planoId => {
		setSelectPlan({
			open: true,
			id: planoId,
		});
	};
	const onChangePage = newPage => {
		setPage(newPage);
	};
	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const filtrosChange = async data => {
		setPage(1);
		setFiltros(data);
	};

	const handleSelectPlan = async confirm => {
		if (confirm) {
			setLoading(true);
			let res = await axios.post(
				`${config.get('apiUrl')}planos_de_vendas/estabelecimento/${estabelecimentoId}/${selectPlan.id}`,
				{},
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				setPlanoAtivoId(selectPlan.id);
			}

			setLoading(false);
		}

		setSelectPlan({
			open: false,
			id: null,
		});
	};

	const onView = async planoId => {
		setViewOpen(true);
		let res = await axios.post(
			`${config.get('apiUrl')}planos_de_vendas/${planoId}`,
			{},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success && res.data.plano) {
			setViewPlanoId(planoId);
			setViewPlano(res.data.plano);
		}
	};

	const handleViewClose = () => {
		setViewOpen(false);
		setViewPlanoId(null);
	};
	return (
		<div>
			<Paper style={{ marginBottom: 10, padding: '5px 10px' }}>
				<div
					style={{
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<FaFilter />
					<ZFiltros
						onChange={filtrosChange}
						omni={{
							// default: filtros.omni,
							placeholder: 'Pesquisa por Plano de Venda',
						}}
					/>
				</div>
			</Paper>

			<Zoom in>
				{loading ? (
					<ZLoader height={200} size={50} />
				) : (
					<Grid container spacing={2}>
						{first.length > 0 ? (
							<>
								{first.map((plano, key) => (
									<Grid item md={3} sm={6} xs={12}>
										<PlanoDeVenda
											onSelect={onSelect}
											onView={onView}
											plano={plano}
											visible={plano.visible}
											ativo={planoAtivoId === plano.id}
										/>
									</Grid>
								))}
							</>
						) : (
							<Grid item xs={12}>
								<div className={classes.empty}>Nenhum Plano de Venda Encontrado.</div>
							</Grid>
						)}
					</Grid>
				)}
			</Zoom>
			<div style={{ width: '100%' }}>
				<ZPaginationDiv
					count={totalRows}
					rowsPerPage={rowsPerPage}
					page={page - 1}
					onChangePage={onChangePage}
					onChangeRowsPerPage={onChangeRowsPerPage}
					rowsPerPageOptions={[16, 32, 64]}
				/>
			</div>

			<Dialog open={viewOpen} onClose={handleViewClose} scroll={'paper'} aria-labelledby="scroll-dialog-title">
				{viewPlanoId !== null && viewPlano !== null ? (
					<>
						<DialogTitle id="scroll-dialog-title">{viewPlano.name}</DialogTitle>
						<DialogContent style={{ width: 300 }} dividers>
							<div className={classes.taxas}>
								{Object.keys(viewPlano.fees).map(feeKey => (
									<React.Fragment key={feeKey}>
										<div
											className={classes.taxa}
											onClick={() => setActive(active === feeKey ? null : feeKey)}
										>
											{getFormaDePagamento(feeKey)}
											<div className={classes.chevron}>
												{active === feeKey ? <FaChevronUp /> : <FaChevronDown />}
											</div>
										</div>

										<Collapse in={active === feeKey}>
											<div className={classes.taxaDetalhes}>
												{Object.keys(viewPlano.fees[feeKey]).map(brand => {
													if (Object.keys(viewPlano.fees[feeKey][brand]).length === 0) {
														return null;
													}

													const installments = viewPlano.fees[feeKey][brand];

													return (
														<div key={brand}>
															{feeKey !== 'boleto' && (
																<div className={classes.brandTitle}>
																	{brand === 'others' ? 'Outras Bandeiras' : brand}
																</div>
															)}

															{Object.keys(installments).map(i => {
																const installment = installments[i];

																return (
																	<Grid key={`${brand}-${i}`} container>
																		<Grid
																			xs={6}
																			item
																			style={{ textAlign: 'center' }}
																		>
																			{i}x
																		</Grid>
																		<Grid
																			xs={6}
																			item
																			style={{ textAlign: 'center' }}
																		>
																			{feeKey === 'boleto'
																				? Formatter.Real(installment.fee / 100)
																				: `${(installment.fee / 100).toFixed(
																						2
																				  )}%`}
																		</Grid>
																	</Grid>
																);
															})}
														</div>
													);
												})}
											</div>
										</Collapse>
									</React.Fragment>
								))}
							</div>
						</DialogContent>
					</>
				) : (
					<>
						<DialogTitle id="scroll-dialog-title">Carregando...</DialogTitle>
						<DialogContent style={{ width: 300 }} dividers>
							Plano Não Encontrado.
						</DialogContent>
					</>
				)}
			</Dialog>
			<ZModalConfirm
				title={`Selecionar Plano de Venda`}
				message={`Deseja realmente selecionar esse plano de venda?`}
				open={selectPlan.open}
				onClose={handleSelectPlan}
			/>
		</div>
	);
};

export default Main;
