/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { ZSaveButton, ZAlert } from '../system';
import { Box, Grid, Link, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import moment from 'moment';
import axios from 'axios';
import validator from 'validator';
import { checkLogin } from '../../store/auth';
import { addMessage } from '../../store/alert';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import config from 'react-global-configuration';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	paper: {
		marginTop: theme.spacing(12),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		width: '100%',
		// backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const EsqueciMinhaSenha = props => {
	const { history } = useReactRouter();
	const dispatch = useDispatch();

	const systemConfig = useSelector(store => store.system.config);
	const logado = useSelector(store => store.auth.logado);
	const message = useSelector(store => store.alert);

	useEffect(() => {
		dispatch(checkLogin());
	}, []);

	const classes = useStyles();

	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);

	const handleSubmit = e => {
		// e.preventDefault();
		setLoading(true);
		dispatch(addMessage({ type: 'error', message: '' }));

		let valid = true;

		if (validator.isEmpty(email)) {
			valid = false;

			dispatch(addMessage({ type: 'error', message: 'Preencha o e-mail.' }));
			// return false;
		} else if (!validator.isEmail(email)) {
			valid = false;

			dispatch(addMessage({ type: 'error', message: 'Insira um e-mail válido.' }));
			// return false;
		}

		if (valid) {
			axios
				.put(config.get('apiUrl') + 'usuarios/recuperar_senha', {
					email: email,
				})
				.then(res => res.data)
				.then(data => {
					if (!data.success) {
						dispatch(addMessage({ type: 'error', message: data.message }));
					} else {
						// dispatch(login(data.usuario));
						dispatch(addMessage({ type: 'success', message: data.message }));
						history.push('/');
					}

					setLoading(false);
				})
				.catch(err => {
					// console.log(err);
				});
		} else {
			setLoading(false);
		}
	};

	if (logado) {
		history.push('/');
	}

	return (
		<div style={{ background: systemConfig.backgroundColor }}>
			<Container maxWidth="md" style={{ height: '100vh' }}>
				<Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
					<Grid item xs={12} style={{ background: systemConfig.backgroundColor, padding: 20 }}>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Grid item xs={12} sm={6} lg={6} style={{}}>
								<Grid container direction="row" justifyContent="center" alignItems="center">
									<Grid item xs={12} style={{ padding: 30 }} align="center">
										<Link href={systemConfig.website} color="inherit">
											<img
												src={systemConfig.logoUrl}
												style={{ maxWidth: '100%' }}
												alt={`Logo ${systemConfig.nome}`}
												title={`Logo ${systemConfig.nome}`}
											/>
										</Link>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								lg={6}
								style={{ background: 'white', padding: 25, borderRadius: 5 }}
							>
								<form
									className={classes.form}
									noValidate
									onSubmit={e => {
										e.preventDefault();
										handleSubmit();
									}}
								>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="email"
										label="E-mail"
										name="email"
										autoComplete="email"
										autoFocus
										onChange={e => {
											setEmail(e.target.value);
										}}
										value={email}
									/>

									<ZSaveButton
										onClick={() => handleSubmit()}
										color={'primary'}
										align="right"
										// type="submit"
										label={'Recuperar senha'}
										loading={loading}
									/>
								</form>
								<Grid container>
									<Grid item xs>
										<Link
											onClick={() => history.push('/')}
											style={{ cursor: 'pointer' }}
											variant="body2"
										>
											Login
										</Link>
									</Grid>
								</Grid>

								<Box mt={5}>
									<Typography variant="body2" color="textSecondary" align="center">
										Sistema de Pagamentos
										<br />
										{`© ${systemConfig.nome} ` + moment().format('YYYY')}
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{message.message.length > 0 && (
					<Grid item xs={12}>
						<ZAlert type={message.type} message={message.message} time={moment().unix()} vertical="top" />
					</Grid>
				)}
			</Container>
		</div>
	);
};

export default EsqueciMinhaSenha;
