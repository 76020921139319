import * as React from 'react';
import { Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table } from '@material-ui/core';

const parcelas = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export default function ZModalTable(props) {
	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 700 }} size="small" aria-label="a dense table">
				<TableHead>
					<TableRow>
						<TableCell>Bandeiras</TableCell>
						{props.bandeira.map(item => {
							return <TableCell align="left">{`${item}`}</TableCell>;
						})}
					</TableRow>
				</TableHead>
				<TableRow>
					<TableCell>Débito</TableCell>
					{props.taxaDebito.map((item, i) => {
						return (
							<TableCell xs={12} item style={{ textAlign: 'center' }}>
								{props.handle(props.valor, props.tipo, item[1].fee / 100)}
							</TableCell>
						);
					})}
				</TableRow>

				<TableBody>
					{parcelas.map((item, index) => {
						return (
							<TableRow>
								<TableCell>{`Crédito ${item}x`}</TableCell>
								{props.taxa.map((item, i) => {
									return (
										<TableCell xs={12} item style={{ textAlign: 'center' }}>
											{props.handle(props.valor, props.tipo, item[index].fee / 100)}
										</TableCell>
									);
								})}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
// console{props.taxa.map((item, index) => {
//return props.handle(props.valor, props.tipo, item.fee);
//})}	{props.taxa[props.bandeira][index].map(item => {
//	return console.log(item);
//})}	{props.taxa.map((item, i) => {
//	return props.handle(props.valor, props.tipo, item[index].fee / 100);
//})}{props.bandeira.map((bandeira, index) => {
/*	return (
		<TableRow>
			<TableCell>{`debito `}</TableCell>
			{props.taxaDebito.map((item, i) => {
				return (
					<TableCell xs={3} item style={{ textAlign: 'center' }}>
						{props.handle(props.valor, props.tipo, item[bandeira].fee / 100)}
					</TableCell>
				);
			})}
		</TableRow>
	);
})}*/
