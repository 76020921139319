import React, { useState } from 'react';
import { Table, TableRow, TableBody, TablePagination } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	pagination: {
		'& .MuiTablePagination-root': {
			borderBottom: 'none',
		},
	},
}));

const ZPaginationDiv = props => {
	const { onChangePage, onChangeRowsPerPage } = props;
	const matches = useMediaQuery(theme => theme.breakpoints.down('xs'));

	const classes = useStyles();

	const [rowsPerPageOptions] = useState(props.rowsPerPageOptions || [15, 30, 60]);

	const handleChangePage = (event, newPage) => {
		onChangePage(newPage + 1);
	};

	const handleChangeRowsPerPage = event => {
		onChangeRowsPerPage(parseInt(event.target.value, 10));
	};

	return (
		<div className={classes.pagination}>
			<Table>
				<TableBody>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={rowsPerPageOptions}
							{...props}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true,
							}}
							labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
							labelRowsPerPage={matches ? '' : 'Resultados por página:'}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
						/>
					</TableRow>
				</TableBody>
			</Table>
		</div>
	);
};

export default ZPaginationDiv;
