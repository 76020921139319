import React from 'react';
import { Card, CardContent, Grid, Avatar, Typography } from '@material-ui/core';
import { Formatter } from '../../utils/';

const Resume = props => {
	let objFlags = {};
	let objIndicators = [];

	const renderObj = () => {
		if (props.obj) {
			let propObj = Object.entries(props.obj)
				.map(item => item[1])
				.map((item, id) => Object.keys(item))
				.map(item => item);
			objFlags = propObj;
		}
	};

	const renderObjIndicators = () => {
		if (props.obj) {
			let propObj = Object.entries(props.obj)
				.map(item => item[1])
				.map((item, id) => item)[0];

			let final = Object.entries(propObj).map(item => item[1]);
			objIndicators.push(final);
		}
	};
	const key1 = props.key1;
	const key2 = props.key2;
	renderObj();
	renderObjIndicators();
	return (
		<Card {...props}>
			<CardContent>
				<Grid container spacing={1}>
					<Grid item xs={2} sm={2}>
						<Avatar
							style={{
								backgroundColor: props.color,
							}}
						>
							<props.icon />
						</Avatar>
					</Grid>
					<Grid item xs={10} sm={10}>
						<Typography color="textPrimary" style={{ textAlign: 'center' }} variant="h6">
							{props.title}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Typography variant="h6" style={{ textAlign: 'center', marginBottom: 10 }}>
							<span
								style={{
									color: props.amountColor,
								}}
							>
								{props.amount}
							</span>
						</Typography>
					</Grid>
				</Grid>

				<Grid container spacing={1} justifyContent="center" className={props.classe}>
					{props.titulo &&
						props.titulo.map(item => {
							return (
								<>
									<Grid item xs={6} sm={6}>
										{item[props.hold]}
									</Grid>
									<Grid item xs={6} sm={6}>
										{` ${Formatter.Real(item[props.value])}`}
									</Grid>
								</>
							);
						})}
				</Grid>
				{props.objeto && (
					<>
						<Grid container justifyContent="center" className={props.classe}>
							<Grid item direction="column" xs={4} sm={4} justifyContent="center" alignItems="center">
								<Grid
									style={{
										color: props.color,
									}}
								>
									Bandeiras
								</Grid>
								{objFlags &&
									objFlags[0].map(item => {
										return <Grid style={{ fontWeigth: 600 }}>{item}</Grid>;
									})}
							</Grid>
							<Grid item xs={4} sm={4} direction="column">
								<Grid
									style={{
										color: props.color,
									}}
								>
									{props.placeholder ? ` ${props.placeholder}` : ` ${key1}`}
								</Grid>
								{objIndicators &&
									objIndicators[0].map(item => {
										return <Grid>{`${props.symbol1}${item[key1].toFixed(2)}`}</Grid>;
									})}
							</Grid>
							<Grid item xs={4} sm={4} direction="column">
								<Grid
									style={{
										color: props.color,
									}}
								>
									{key2}
								</Grid>
								{props.show &&
									objIndicators &&
									objIndicators[0].map((item, index) => {
										return (
											<Grid>
												{props.real
													? `${Formatter.Real(item[key2].toFixed(2))}`
													: `${item[key2].toFixed(2)}${props.symbol2}`}
											</Grid>
										);
									})}
							</Grid>
						</Grid>
					</>
				)}
			</CardContent>
		</Card>
	);
};

export default Resume;
