import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Formatter, Isset } from '../../utils';
import axios from 'axios';
import config from 'react-global-configuration';
import useReactRouter from 'use-react-router';
import queryString from 'query-string';
import FileDownload from 'js-file-download';
import {
	Grid,
	Paper,
	FormControlLabel,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { FaFilter } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import { format } from 'date-fns';
import { addDays } from 'date-fns';
import { addMonths } from 'date-fns';
import { pt } from 'date-fns/locale';
import { addMessage } from '../../store/alert';
import { setFilters } from '../../store/filters';
import { ZFiltros, ZTitle } from '../system';
import { useStyles } from './styles/indexStyle';

const Extrato = () => {
	const { history, location } = useReactRouter();

	const { pathname } = location;
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');
	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const query = queryString.parse(location.search);
	const queryFilter = {
		date: { start: addDays(new Date(), -7), end: new Date() },
		tipoTransacao: '',
		...filtersCache,
	};
	if (Isset(() => query.tt)) {
		switch (+query.tt) {
			case 1:
				queryFilter.tipoTransacao = 'venda';
				break;
			case 2:
				queryFilter.tipoTransacao = 'transferencia';
				break;
			default:
		}
	}

	if (Isset(() => query.d)) {
		switch (query.d) {
			case 'lastMonth':
				queryFilter.date = { default: query.d, start: addMonths(new Date(), -1), end: new Date() };
				break;
			default:
		}
	}

	const classes = useStyles();

	const dispatch = useDispatch();

	const token = useSelector(state => state.auth.token);
	const usuario = useSelector(state => state.auth.usuario);
	const isAdmin = usuario.grupo_id === 1;

	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};

	const [lancamentos, setLancamentos] = useState([]);
	const [filtros, setFiltros] = useState(queryFilter);
	const [detalhado, setDetalhado] = useState(Isset(() => filtersCache.detalhado) ? filtersCache.detalhado : false);

	// * Adicionado estado de loading, o que gerava erro ao compilar, mesmo que não seja utilizado
	const [, setLoading] = useState(true);

	useEffect(() => {
		const getExtrato = async () => {
			setLoading(true);
			const extrato = await axios.get(`${config.get('apiUrl')}estabelecimentos/extrato`, {
				headers: { Authorization: `Bearer ${token}` },
				params: {
					filtros: {
						...filtros,
						estabelecimentoId,
						detalhado,
					},
				},
			});
			if (extrato.data.success) {
				const result = [];
				let lastDate = null;
				extrato.data.extrato.forEach(v => {
					const date = format(v.data, 'YYYY-MM-DD');
					if (lastDate !== date) {
						lastDate = date;
						result.push({ type: 'date', date });
					}
					v.type = 'row';
					result.push(v);
				});

				if (result.length > 0) {
					const lastResult = result[result.length - 1];
					let lastSaldoId = Object.keys(extrato.data.saldo)
						.filter(i => format(i, 'YYYYMMDD') < format(lastResult.data, 'YYYYMMDD'))
						.pop();
					let lastSaldo = extrato.data.saldo[lastSaldoId];
					result.reverse().forEach(v => {
						if (v.type === 'date') {
							const date = format(v.date, 'YYYY-MM-DD');
							const saldo = Isset(() => extrato.data.saldo[date]) ? extrato.data.saldo[date] : lastSaldo;
							v.saldo = saldo;
							lastSaldo = saldo;
						}
					});
				}
				setLancamentos(result.reverse());
			} else {
				setLancamentos([]);
			}
			setLoading(false);
		};

		getExtrato();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtros, estabelecimentoId, detalhado]);

	const filtrosChange = data => {
		setFiltros({
			...filtros,
			...data,
		});
		dispatch(
			setFilters(pathnameSlug, {
				...filtros,
				...data,
				detalhado,
			})
		);
	};

	const exportXls = async () => {
		const extrato = await axios.get(`${config.get('apiUrl')}export/extrato`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params: {
				filtros: {
					...filtros,
					estabelecimentoId,
					detalhado,
				},
			},
		});

		if (extrato.status !== 200 || !Isset(() => extrato.data)) {
			return dispatch(addMessage({ type: 'error', message: 'Erro ao gerar extrato.' }));
		}

		const { 'content-disposition': contentDisposition, 'content-type': contentType } = extrato.headers;
		let filename = 'extrato.xlsx';
		if (contentDisposition.indexOf('filename="') !== -1) {
			filename = contentDisposition.slice(
				contentDisposition.indexOf('filename="') + 10,
				contentDisposition.length - 1
			);
		}

		FileDownload(extrato.data, filename, contentType);
	};

	const changeDetalhado = e => {
		const value = e.target.checked;
		setDetalhado(value);
		dispatch(setFilters(pathnameSlug, { ...filtersCache, detalhado: value }));
	};

	return (
		<Grid className={classes.header}>
			<ZTitle title="Extrato" display="flex" textAlign="center" fontSize="55px" />
			<Paper style={{ marginBottom: 10, padding: '5px 10px' }}>
				<Grid container justifyContent="center" alignContent="center" alignItems="center">
					<Grid item md={8} lg={8} xl={8}>
						<FaFilter />
						<ZFiltros
							onChange={filtrosChange}
							dateRange={{
								default: 'custom',
								startDate: filtros.date.start,
								endDate: filtros.date.end,
							}}
							tipoTransacao={{
								default: filtros.tipoTransacao,
							}}
						/>
					</Grid>
					<Grid item>
						<FormControlLabel
							className={classes.controlSwitchDetalhado}
							control={
								<Switch
									className={classes.switchDetalhado}
									checked={detalhado}
									onChange={changeDetalhado}
									value="detalhado"
									color="primary"
								/>
							}
							label="Detalhado"
						/>
						{usuario.grupo_id !== 7 && <FiDownload className={classes.export} onClick={exportXls} />}
					</Grid>
				</Grid>
			</Paper>

			<Grid>
				<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 45px)', margin: 15 }}>
					<Table>
						{lancamentos.length > 0 &&
							lancamentos.map((lancamento, key) =>
								lancamento.type === 'date' ? (
									<>
										<TableHead>
											<TableRow className={classes.tableHeader}>
												<TableCell className={classes.tableHeader} colSpan={9}>
													{format(lancamento.date, 'DD [de] MMMM [de] YYYY', {
														locale: pt,
													})}
												</TableCell>
											</TableRow>
										</TableHead>
										<TableHead>
											<TableRow>
												{detalhado && (
													<TableCell style={{ minWidth: 180 }} align="center">
														Hora
													</TableCell>
												)}
												<TableCell style={{ minWidth: 180 }} align="center">
													Tipo
												</TableCell>
												<TableCell style={{ minWidth: 180 }} align="center">
													Método
												</TableCell>
												{detalhado && (
														<TableCell style={{ minWidth: 180 }} align="center">
															Cliente
														</TableCell>
													) && (
														<TableCell style={{ minWidth: 180 }} align="center">
															Pedido Venda
														</TableCell>
													)}

												{isAdmin && (
													<TableCell style={{ minWidth: 180 }} align="center">
														Estabelecimento
													</TableCell>
												)}
												<TableCell style={{ minWidth: 180 }} align="center">
													Valor
												</TableCell>
												<TableCell style={{ minWidth: 180 }} align="center">
													Taxa
												</TableCell>
												<TableCell style={{ minWidth: 180 }} align="center">
													Valor Líquido
												</TableCell>
												{detalhado && (
													<TableCell style={{ minWidth: 180 }} align="center">
														Saldo
													</TableCell>
												)}
											</TableRow>
										</TableHead>
									</>
								) : (
									<TableBody key={key}>
										<TableRow
											className={classes.hovers}
											onClick={() => {
												if (!detalhado) {
													return;
												}
												history.push(
													[2, 3].includes(lancamento.tipoId)
														? '/transferencias/detalhes/' + lancamento.id
														: '/vendas/detalhes/' + lancamento.pedido_id
												);
											}}
										>
											{detalhado && (
												<TableCell
													className={classes.row}
													style={{ minWidth: 180 }}
													align="center"
												>
													{format(lancamento.data, 'DD/MM HH:mm')}
												</TableCell>
											)}
											<TableCell className={classes.row} style={{ minWidth: 180 }} align="center">
												{lancamento.tipo}
											</TableCell>
											<TableCell style={{ minWidth: 180 }} align="center">
												{lancamento.metodo}
											</TableCell>
											{detalhado && (
													<TableCell style={{ minWidth: 180 }} align="center">
														{Isset(() => lancamento.cliente.nome)
															? lancamento.cliente.nome
															: ''}
													</TableCell>
												) && (
													<TableCell style={{ minWidth: 180 }} align="center">
														{lancamento.sales_order ? lancamento.sales_order : 'N/A'}
													</TableCell>
												)}
											{isAdmin && (
												<TableCell
													classname={classes.tra}
													style={{ minWidth: 180 }}
													align="center"
												>
													{lancamento.estabelecimento}
												</TableCell>
											)}

											<TableCell
												style={{ minWidth: 180 }}
												align="center"
												className={clsx(classes.valorOnXs, {
													[classes.negativo]: lancamento.tipoId === 2,
												})}
											>
												{Formatter.Real(lancamento.valor)}
											</TableCell>
											<TableCell style={{ minWidth: 180 }} align="center">
												{Formatter.Real(lancamento.taxa)}
											</TableCell>
											<TableCell
												style={{ minWidth: 180 }}
												align="center"
												className={clsx(classes.hideOnXs, {
													[classes.negativo]: lancamento.tipoId === 2,
												})}
											>
												{Formatter.Real(lancamento.valor_liquido)}
											</TableCell>
											{detalhado && (
												<TableCell item xs={2} style={{ minWidth: 180 }}>
													{Formatter.Real(
														Isset(() => lancamento.saldo) ? lancamento.saldo : 0
													)}
												</TableCell>
											)}
										</TableRow>
									</TableBody>
								)
							)}
					</Table>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default Extrato;
