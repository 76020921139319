/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import axios from 'axios';
import config from 'react-global-configuration';

import { Isset, Mask } from '../../../utils';

import { makeStyles } from '@material-ui/core/styles';
// import { Select } from '@material-ui/core/Select';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
// import { MenuItem } from '@material-ui/core/MenuItem';
// import { Chip } from '@material-ui/core/Chip';
// import { FaTimes } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
	filtros: {
		position: 'relative',
		display: 'inline-flex',
	},
	filtroText: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		padding: '3.5px 10px',
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 30,
		marginLeft: 10,
		fontWeight: 700,
		fontSize: 12,
		color: theme.palette.primary.main,
	},
	filtroTextSelectedMultiple: {
		backgroundColor: `${theme.palette.primary.main}`,
		// color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		// '& .MuiSelect-select': {
		// 	color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		// },
		padding: '2px 10px',
		minHeight: 29,
		maxWidth: 340,
	},
	clearBtn: {
		marginLeft: 6,
	},
	filtroSelect: {
		fontWeight: 700,
		fontSize: 12,
		'&:before': {
			borderBottom: 'none !important',
		},
		'&:after': {
			borderBottom: 'none !important',
		},
		'& .MuiSelect-select': {
			// padding: '2px 10px !important',
			marginRight: 10,
		},
	},
	estabelecimentoSelect: {
		// marginLeft: 25,
		fontWeight: 700,
		fontSize: 12,
		// color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			'&:before': {
				borderBottom: 'none !important',
			},
		},
		'&:before': {
			borderBottom: 'none !important',
		},
		'& .MuiInputBase-input': {
			padding: '0px 10px !important',
			width: 100,
		},
		// '& .MuiSelect-select': {
		// 	padding: '2px 10px !important',
		// 	marginRight: 10,
		// },
		'& .MuiSelect-icon': {
			// color: theme.palette.getContrastText(theme.palette.primary.main),
		},
		'& .MuiInputLabel-root': {
			// color: theme.palette.getContrastText(theme.palette.primary.main),
			fontSize: 14,
		},
		// '& .MuiInputLabel-outlined': {
		// 	transform: 'translate(14px, 14px) scale(1)',
		// 	'&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
		// 		transform: 'translate(14px, -6px) scale(0.75)',
		// 	},
		// },
		'& .MuiOutlinedInput-root': {
			// color: theme.palette.getContrastText(theme.palette.primary.main),
			padding: 0,
		},
		'& .MuiAutocomplete-input': {
			flexGrow: 0,
			width: 250,
		},
		'& .MuiSvgIcon-root': {
			// color: theme.palette.getContrastText(theme.palette.primary.main),
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	estabelecimentoNome: {
		width: '100%',
		padding: 0,
		lineHeight: '1.25em',
	},
	estabelecimentoDocumento: {
		width: '100%',
		padding: 0,
		lineHeight: '1.25em',
		fontSize: 10,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
		height: 16,
		maxWidth: 130,
		justifyContent: 'flex-start',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'& .MuiChip-label': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: 'inline-block',
		},
	},
	menuItem: {
		flexDirection: 'column',
	},
}));

const Estabelecimento = props => {
	const classes = useStyles();

	const token = useSelector(store => store.auth.token);
	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};

	let value = '';

	if (Isset(() => props.defaultValue) && props.defaultValue) {
		value = props.defaultValue;
	}

	const [estabelecimento, setEstabelecimento] = useState(value);
	const [ecs, setEcs] = useState([]);

	useEffect(() => {
		const checkEstabelecimento = estabelecimentos => {
			let auxEstabelecimento = estabelecimento;
			if (auxEstabelecimento) {
				if (!Array.isArray(auxEstabelecimento)) {
					auxEstabelecimento = [auxEstabelecimento];
				}

				estabelecimentos = estabelecimentos.map(a => +a.id);

				setEstabelecimento(
					auxEstabelecimento.filter(a => a && estabelecimentos.includes(a) && a !== estabelecimentoId)
				);
			}
		};

		const getMeusECs = async () => {
			let res = await axios.post(
				`${config.get('apiUrl')}graphql`,
				{
					query: `
					query Estabelecimentos {
						estabelecimentos${estabelecimentoId ? `(id: ${estabelecimentoId})` : ''} {
							id, nomeFantasia, documento
						}
					}
				`,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (res.status === 200 && Isset(() => res.data.data.estabelecimentos)) {
				const result = res.data.data.estabelecimentos.map(a => ({ ...a, id: `${a.id}` }));
				setEcs(result);
				checkEstabelecimento(result);
			}
		};

		getMeusECs();
	}, [estabelecimentoId]);

	const estabelecimentoChange = e => {
		let value = e.target.value;

		// if (Array.isArray(value)) {
		// 	value = value.map(a => `${a}`).filter((v, i, self) => self.indexOf(v) === i);
		// }

		setEstabelecimento(value);

		if (props.onChange) {
			props.onChange(value);
		}
	};

	const estabelecimentoClear = e => {
		// const isMultiple = Isset(() => props.multiple) ? props.multiple : false;
		// e.stopPropagation();

		value = '';

		setEstabelecimento(value);

		if (props.onChange) {
			props.onChange(value);
		}
	};

	return (
		<div className={classes.estabelecimento}>
			{ecs.length > 0 && (
				<div
					className={clsx({
						[classes.filtroText]: true,
						// [classes.filtroTextSelectedMultiple]: Array.isArray(estabelecimento)
						// 	? estabelecimento.length > 0
						// 	: estabelecimento,
					})}
				>
					<Autocomplete
						className={classes.estabelecimentoSelect}
						options={ecs}
						getOptionLabel={option =>
							option.nomeFantasia +
							` ` +
							(option.documento.length <= 11 ? Mask.Cpf(option.documento) : Mask.Cnpj(option.documento))
						}
						onChange={(a, value) => {
							const event = { target: { value: estabelecimento.id } };

							if (Isset(() => value.id)) {
								event.target.value = value.id;

								estabelecimentoChange(event);
							} else {
								estabelecimentoClear();
							}
						}}
						style={{ width: 300 }}
						renderInput={params => (
							<TextField {...params} placeholder="Estabelecimentos" variant="standard" />
						)}
					/>
					{/* <Select
						multiple={props.multiple || false}
						className={classes.filtroSelect}
						value={estabelecimento}
						onChange={estabelecimentoChange}
						displayEmpty
						renderValue={selected => {
							if (selected.length === 0) {
								return <em>Estabelecimentos</em>;
							}

							const aux = ecs.filter(a => selected.includes(a.id)).map(a => a.nomeFantasia);
							return (
								<div className={classes.chips}>
									{aux.map((value, i) => (
										<Chip key={`${value}${i}`} label={value} className={classes.chip} />
									))}
								</div>
							);
						}}
					>
						<MenuItem disabled value="">
							<em>Estabelecimentos</em>
						</MenuItem>
						{ecs.map((ec, i) => (
							<MenuItem key={i} value={ec.id} className={classes.menuItem}>
								<div className={classes.estabelecimentoNome}>{ec.nomeFantasia}</div>
								<div className={classes.estabelecimentoDocumento}>
									{ec.documento.length <= 11
										? Formatter.Cpf(ec.documento)
										: Formatter.Cnpj(ec.documento)}
								</div>
							</MenuItem>
						))}
					</Select>
					{estabelecimento && <FaTimes className={classes.clearBtn} onClick={estabelecimentoClear} />} */}
				</div>
			)}
		</div>
	);
};

export default Estabelecimento;
