import React, { useState, useEffect, memo } from 'react';
import clsx from 'clsx';
import { ZTitle, ZLoader, ZText, ZSelect, ZDatePicker, ZSwitch, ZSaveButton } from '../system';
import { Mask, ValidatorForm, Isset } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import config from 'react-global-configuration';
import axios from 'axios';
import useReactRouter from 'use-react-router';
import moment from 'moment';
// import { FaWhatsapp, FaShare } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import ListaDePedidosPreVenda from './ListaDePedidosPreVenda';
import { InputAdornment } from '@material-ui/core';
import CalculadoraValorLiquido from '../Calculadoras/ValorLiquido';
import CalculadoraValorBruto from '../Calculadoras/ValorBruto';
import {
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Slide,
	Paper,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { FaDownload } from 'react-icons/fa';
import { format } from 'date-fns';
import QRCode from 'qrcode.react';
import saveSvgAsPng from 'save-svg-as-png';

const useStyles = makeStyles(theme => ({
	layout: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 900,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		// marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			// marginTop: theme.spacing(6),
			marginBottom: theme.spacing(6),
			padding: theme.spacing(4),
		},
	},
	menuIcon: {
		fontSize: 24,
		cursor: 'pointer',
		marginRight: 4,
	},
	estabelecimento: {
		padding: '6px 6px',
		marginBottom: 10,
	},
	estabelecimentoValor: {
		'&.porcentagem input': {
			textAlign: 'right',
		},
	},
	estabelecimentoIcone: {
		border: 'solid 1px #abc',
		marginRight: 2,
		marginLeft: 2,
		marginTop: 16,
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}`,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	estabelecimentoError: {
		fontSize: 12,
		color: '#e53935',
	},
	label: {
		textAlign: 'center',
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	labelResumo: {
		// marginTop: 16,
	},
	removeButton: {
		minHeight: 26,
		height: 26,
		width: 26,
		backgroundColor: '#e53935',
		color: 'white',
	},
	novaRegraSplit: {
		textAlign: 'center',
		textTransform: 'uppercase',
		margin: 8,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	box: {
		textTransform: 'uppercase',
		fontSize: 12,
		position: 'relative',
		padding: 10,
		height: 110,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}55`,
		},
		'& .value': {
			fontWeight: 700,
			fontSize: 13,
		},
	},
	splitAtivo: {
		position: 'absolute',
		top: -8,
		right: 10,
		fontWeight: 700,
		color: '#e53935',
		'&.ativo': {
			color: 'green',
		},
	},
	divider: {
		margin: 4,
	},
	span: {
		color: '#777',
		fontSize: 14,
	},
	removerButton: {
		backgroundColor: '#e53935',
		color: 'white',
	},
	statusButton: {
		backgroundColor: '#e53935',
		color: 'white',
		'&.ativar': {
			backgroundColor: 'green',
		},
	},
	divButton: {
		marginLeft: 'auto',
		marginTop: 64,
		[theme.breakpoints.down('xs')]: {
			margin: '64px auto 0',
			width: '100%',
		},
	},
	error: {
		padding: 8,
		borderRadius: 5,
		backgroundColor: '#e53935',
		color: 'white',
		marginBottom: 10,
	},
	subheader: {
		fontWeight: 700,
		fontSize: 18,
		fontVariant: 'small-caps',
		letterSpacing: '0.02em',
		margin: '20px 8px',
		marginTop: -30,
	},
	valorLiquido: {
		'& .MuiFormControlLabel-label': {
			fontSize: 11,
			fontWeight: 'bold',
			textAlign: 'center',
		},
	},
	qrcodeHover: {
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		opacity: 0,
		height: '100%',
		position: 'absolute',
		width: '100%',
		background: '#fafafa',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 100,
		top: 0,
		left: 0,
		'&:hover': {
			opacity: 0.7,
		},
	},
	planosTaxas: {
		position: 'relative',
	},
	tiposPagamentos: {
		position: 'absolute',
		paddingTop: 50,
		paddingBottom: 52,
		border: 'solid 1px #abc',
		borderRadius: 10,
		padding: 10,
		width: 'calc(100% - 30px)',
	},
	planos: {
		position: 'relative',
		padding: 14,
		height: 50,
		fontSize: 14,
		fontWeight: 600,
		'& span': {
			fontWeight: 'bold',
			fontSize: 16,
		},
	},
	taxaBoletoDiv: {
		marginTop: 10,
		position: 'relative',
		padding: 10,
		height: 50,
	},
	tipoPagamento: {
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
		'&:not(:last-child)': {
			borderBottom: 'solid 1px #bcd',
		},
	},
	tipoPagamentoBoleto: {
		float: 'left',
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
	},
	bandeirasWrapper: {
		marginLeft: 120,
		width: 'calc(100% - 160px)',
		overflowX: 'auto',
	},
	bandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffffc0',
		height: 490,
		width: 105,
		marginRight: 10,
		float: 'left',
	},
	novaBandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffff',
		width: 160,
		marginRight: 10,
		float: 'left',
	},
	title: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 16,
		height: 22,
	},
	titleLong: {
		fontSize: 11,
	},
	deleteButton: {
		top: 2,
		right: 2,
		cursor: 'pointer',
		position: 'absolute',
		width: 20,
		height: 20,
		color: '#e53935',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		opacity: 0.5,
		'&:hover': {
			opacity: 1,
		},
	},
	fields: {
		marginTop: 8,
		marginBottom: 15,
		borderTop: 'solid 1px #bcd',
	},
	tipoPagamentoFields: {
		borderBottom: 'solid 1px #bcd',
	},
	taxaField: {
		padding: 7.45,
		'&::after': {
			content: '"%"',
			position: 'absolute',
			right: 4,
			fontSize: 12,
		},
		'& .MuiInputBase-input': {
			textAlign: 'right',
			height: '1em',
			padding: 0,
			paddingRight: 15,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -7,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
	},
	saveButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		marginTop: 5,
	},
	adicionarButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		marginTop: 15,
	},
	taxaFieldBoletoReadOnly: {
		marginLeft: 120,
		height: 30.8,
		fontWeight: 'bold',
		fontSize: 15,
		padding: 4,
	},
	taxaFieldReadOnly: {
		height: 30.8,
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 15,
		padding: 4,
	},
	btnCalculadora: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.primary.light,
		},
		marginRight: 10,
		'& .MuiButton-label': {
			display: 'flex',
			flexDirection: 'column',
		},
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 100,
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmDialog = memo(props => {
	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			open={props.open}
			TransitionComponent={Transition}
			keepMounted
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle id="alert-dialog-slide-title">{'Atenção!!!'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">{props.dialogContent}</DialogContentText>
			</DialogContent>
			<DialogActions style={{ textAlign: 'center' }}>
				<Button onClick={props.handleClose} color="primary">
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
});

const ShareDialog = memo(props => {
	return (
		<Dialog
			open={props.open}
			TransitionComponent={Transition}
			keepMounted
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle id="alert-dialog-slide-title">{'Sucesso!'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
					Deseja compartilhar o link no WhatsApp?
				</DialogContentText>
			</DialogContent>
			<DialogActions style={{ textAlign: 'center' }}>
				<Button onClick={props.handleSuccess} color="primary">
					Sim
				</Button>
				<Button onClick={props.handleClose} color="primary">
					Não
				</Button>
			</DialogActions>
		</Dialog>
	);
});

const CadastrarVendaDigitada = props => {
	const classes = useStyles();

	const { history, match } = useReactRouter();
	const dispatch = useDispatch();
	const url = useSelector(store => store.system.config.hostname);
	const token = useSelector(store => store.auth.token);

	const user = useSelector(store => store.auth.usuario);

	const estabelecimentoId = useSelector(store => store.auth.estabelecimento.id);

	const [dialogContent] = useState(`
	As condições de antecipação para esse tipo de venda devem ser verificadas.
	Normalmente essas vendas não podem ser antecipadas.`);

	const qrCodeRef = React.createRef();

	// const [loadingToken, setLoadingToken] = useState(false);
	const loadingToken = false;
	const [loading, setLoading] = useState(false);
	const [onError, setOnError] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [showShareModal, setShowShareModal] = useState(false);
	const [successPreVenda, setSuccessPreVenda] = useState(null);
	const [data, setData] = useState({
		id: null,
		titulo: '',
		descricao: '',
		amount: 0.0,
		parcelamento_ate: 1,
		meio_pagamento: 3,
		data_expiracao: moment().add(30, 'days'),
		logo: true,
		split: false,
		token: null,
		nome_fantasia: '',
		pedidos: [],
		juros: false,
		limit_installments: false,
		percentual: 0,
		juros_a_partir: 2,
		chargeProcessingFee: true,
	});

	const [showCalculadoraValorLiquido, setShowCalculadoraValorLiquido] = useState(false);
	const [showCalculadoraValorBruto, setShowCalculadoraValorBruto] = useState(false);
	const [estabelecimentoNome, setEstabelecimentoNome] = useState('');
	const [taxas, setTaxas] = useState({});
	const [countBandeiras, setCountBandeiras] = useState(1);
	const [erroTaxa, setErroTaxa] = useState(false);

	const validateFields = {
		titulo: { required: true },
		amount: { required: true, min: 10 },
		parcelamento_ate: { required: true },
	};

	const humanizePayment = data => {
		switch (data) {
			case 1:
				return 'Boleto Bancário';
			case 3:
				return 'Cartão de Crédito';
			case 5:
				return 'Pix';
			default:
				return 'Pagamento desconhecido';
		}
	};

	const getPreVenda = async () => {
		// setLoadingToken(true);

		setActiveStep(2);

		const res = await axios.get(config.get('apiUrl') + 'pre_venda/' + match.params.preVendaId, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			const splits = res.data.preVenda.split
				? res.data.preVenda.split.splits_estabelecimentos.map(split => ({
						tipoSplit: split.tipo_split,
						valor: split.valor,
						nome_fantasia: split.estabelecimento.nome_fantasia,
				  }))
				: [];

			setData({
				...data,
				id: res.data.preVenda.id,
				titulo: res.data.preVenda.titulo,
				descricao: res.data.preVenda.descricao,
				amount: res.data.preVenda.amount,
				parcelamento_ate: res.data.preVenda.parcelamento_ate,
				data_expiracao: new Date(res.data.preVenda.data_expiracao),
				logo: !!res.data.preVenda.logo,
				split: res.data.preVenda.split ? !!res.data.preVenda.split : false,
				token: res.data.preVenda.token,
				nome_fantasia: res.data.preVenda.estabelecimento.nome_fantasia,
				pedidos: res.data.preVenda.pre_venda_pedidos,
				juros: res.data.preVenda.juros ? true : false,
				percentual: res.data.preVenda.juros,
				limit_installments: res.data.preVenda.limit_installments,
				meio_pagamento: res.data.preVenda.tipo_pagamento_id,
				juros_a_partir: res.data.preVenda.juros_a_partir,
				splits,
			});
		} else {
			dispatch(addMessage({ type: 'error', message: res.data.error }));
			setLoading(false);
		}
		// setLoadingToken(false);
	};

	useEffect(() => {
		if (match.params.preVendaId) {
			getPreVenda();
		} else {
			setShowConfirmModal(true);
		}

		const getTaxas = async () => {
			if (user.grupo_id === 11) return;
			setLoading(true);
			setErroTaxa(false);
			const res = await axios.get(
				`${config.get('apiUrl')}planos_taxas/porEstabelecimento/${estabelecimentoId}/taxaTotal`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				setTaxas(res.data.taxaTotal);
				setCountBandeiras(Object.keys(res.data.taxaTotal).length);
			} else {
				setErroTaxa(true);
			}
			setLoading(false);
		};

		const getEC = async () => {
			const res = await axios.post(
				`${config.get('apiUrl')}graphql`,
				{
					query: `
                query Estabelecimentos {
                    estabelecimento(id: ${estabelecimentoId}) {
                        nomeFantasia
                    }
                }
            `,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (res.status === 200 && Isset(() => res.data.data.estabelecimento)) {
				const { nomeFantasia: nome } = res.data.data.estabelecimento;
				setEstabelecimentoNome(nome);
			}
		};

		getTaxas();
		getEC();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match.params.preVendaId]);

	const handleSubmitForm = async () => {
		setLoading(true);
		try {
			ValidatorForm(validateFields, data);
		} catch (erro) {
			dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário' }));

			setLoading(false);

			setOnError(true);

			setActiveStep(0);

			return false;
		}

		let res = '';
		let dataLinkPagamento = data;

		dataLinkPagamento.splits = [];
		if (match.params.planoId) {
			res = await axios.put(config.get('apiUrl') + 'pre_venda/' + match.params.planoId, dataLinkPagamento, {
				headers: { Authorization: `Bearer ${token}` },
			});
		} else {
			res = await axios.post(config.get('apiUrl') + 'pre_venda', dataLinkPagamento, {
				headers: { Authorization: `Bearer ${token}` },
			});
		}
		setLoading(false);

		if (res.data.error) {
			dispatch(addMessage({ type: 'error', message: 'Ocorreu um erro ao salvar' }));
		}

		if (Isset(() => res.data.success) && res.data.success === true) {
			dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
			setSuccessPreVenda(res.data.preVenda);
			setShowShareModal(true);
		}
	};

	const handleChange = name => event => {
		var value = event.target.value;

		switch (name) {
			case 'amount':
				value = Mask.Real(value);
				break;
			default:
				break;
		}

		setData({
			...data,
			[name]: value,
		});
	};

	const handleSuccess = () => {
		if (successPreVenda) {
			const text = encodeURIComponent(
				`Link de Pagamento - ${
					successPreVenda.estabelecimento.nome_fantasia
				}\n\n*${data.titulo.trim()}*\nhttps://${url}${config.get('baseName')}/venda/token/${
					successPreVenda.token
				}`
			);
			window.open(`https://wa.me/?text=${text}`, '_blank');
			history.push('/venda-digitada/' + successPreVenda.id);
		}
	};

	const parcelas = [];
	for (let i = 1; i <= 12; i++) {
		const text = i > 1 ? ' vezes' : ' vez';
		parcelas.push({ id: i, name: i + text });
	}

	if (loadingToken) {
		return <ZLoader height={200} />;
	}

	const handleNext = () => {
		setActiveStep(prevStep => prevStep + 1);
	};

	const handleBack = () => {
		setActiveStep(prevStep => prevStep - 1);
	};

	const downloadQRCode = () => {
		const svg = qrCodeRef.current.getElementsByTagName('svg')[0];
		saveSvgAsPng.saveSvgAsPng(svg, 'qrcode.png', {
			scale: 5,
			encoderOptions: 1,
			backgroundColor: 'white',
		});
	};

	return (
		<Container maxWidth="lg">
			<Grid container>
				{!match.params.preVendaId && <ZTitle title={'Link de Pagamento'} textAlign={'center'} />}

				{match.params.preVendaId && (
					<ZTitle
						title="Link de Pagamento"
						button="add"
						handleOnClick={() => history.push(`/venda-digitada`)}
					/>
				)}

				<Grid item xs={12}>
					<Grid container item>
						<Grid item xs={12} md={12} lg={12}>
							<Paper className={classes.paper}>
								{activeStep === 0 && (
									<Grid container spacing={3}>
										<Grid item xs={12} md={6}>
											<ZText
												label="Titulo"
												onError={onError}
												required={true}
												value={data.titulo}
												onBlur={handleChange('titulo')}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<ZText
												label="Descrição"
												// multiline
												// rows="3"
												value={data.descricao}
												onBlur={handleChange('descricao')}
											/>
										</Grid>

										<Grid item xs={12} sm={6} md={6} style={{ marginTop: 15 }}>
											<ZDatePicker
												// error={errors.dataInicio}
												textField
												label="Data de expiração"
												disabled={data.id ? true : false}
												minDate={new Date()}
												date={data.data_expiracao}
												onChange={date => setData({ ...data, data_expiracao: date })}
											/>
										</Grid>

										<Grid item xs={12} md={6} style={{ marginTop: 15 }}>
											<ZSwitch
												onChange={() => setData({ ...data, logo: !data.logo })}
												label="Exibir logo do estabelecimento?"
												checked={data.logo}
											/>
										</Grid>
									</Grid>
								)}

								{activeStep === 1 && (
									<Grid container spacing={4}>
										<Grid item xs={12} sm={6} md={4}>
											<ZSelect
												options={[
													{
														id: 1,
														name: 'Boleto Bancário',
													},
													{
														id: 3,
														name: 'Cartão de Crédito',
													},
													{
														id: 5,
														name: 'Pix',
													},
												]}
												label="Meio de pagamento"
												disabled={data.id ? true : false}
												onError={onError}
												width="100%"
												value={data.meio_pagamento}
												onChange={handleChange('meio_pagamento')}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											<ZText
												fullWidth
												label="Valor"
												margin="normal"
												realMask
												disabled={data.id ? true : false}
												onError={onError}
												min={validateFields.amount.min}
												placeholder="Valor a ser pago"
												value={data.amount / 100}
												onBlur={e => {
													setData({ ...data, amount: Math.round(e.target.value * 100) });
												}}
											/>
										</Grid>

										<Grid item xs={12} sm={6} md={4}>
											<ZSelect
												disabled={data.meio_pagamento !== 3 || data.id ? true : false}
												options={parcelas}
												label={
													data.limit_installments
														? 'Limitar em parcelas'
														: 'Máximo de parcelas'
												}
												onError={onError}
												width="100%"
												value={data.parcelamento_ate}
												onBlur={handleChange('parcelamento_ate')}
											/>
										</Grid>

										{data.meio_pagamento === 3 && (
											<Grid item xs={12} md={3}>
												<ZSwitch
													onChange={() =>
														setData({
															...data,
															limit_installments: !data.limit_installments,
															juros: false,
														})
													}
													label="Limitar Parcelas"
													checked={data.limit_installments}
												/>
											</Grid>
										)}

										<Grid item xs={12} md={3}>
											<ZSwitch
												onChange={() => setData({ ...data, juros: !data.juros })}
												label="Adicionar Juros"
												disabled={
													data.limit_installments &&
													(data.meio_pagamento === 3 || data.meio_pagamento === 5)
												}
												checked={data.juros}
											/>
										</Grid>

										{data.juros && (
											<>
												<Grid item xs={12} sm={6} md={3}>
													<ZText
														label="Juros ao mês"
														disabled={data.id ? true : false}
														onError={onError}
														required={true}
														value={data.percentual}
														endAdornment={<InputAdornment position="end">%</InputAdornment>}
														onChange={(value, setValue) => {
															value = Mask.OnlyNumber(value) / 100;

															setValue(
																value > 100
																	? Mask.Porcentagem(100)
																	: Mask.Porcentagem(value)
															);
														}}
														onBlur={e =>
															setData({
																...data,
																percentual: e.target.value,
															})
														}
													/>
												</Grid>
												<Grid item xs={12} sm={6} md={3}>
													<ZSelect
														disabled={data.id ? true : false}
														options={[
															{ id: 2, name: '2' },
															{ id: 3, name: '3' },
															{ id: 4, name: '4' },
															{ id: 5, name: '5' },
															{ id: 6, name: '6' },
															{ id: 7, name: '7' },
															{ id: 8, name: '8' },
															{ id: 9, name: '9' },
															{ id: 10, name: '10' },
															{ id: 11, name: '11' },
														]}
														label="A partir da parcela"
														onError={onError}
														width="100%"
														value={data.juros_a_partir}
														onBlur={handleChange('juros_a_partir')}
													/>
												</Grid>
											</>
										)}
										{data.id && (
											<>
												<Grid item xs={12} sm={12} md={12}>
													<ZText
														fullWidth
														label="URL"
														readOnly="readOnly"
														margin="normal"
														value={`${window.location.origin.toString()}/app/venda/token/${
															data.token
														}`}
													/>
												</Grid>
											</>
										)}
										<Grid item xs={12} sm={12} md={12}>
											{user.grupo_id !== 11 && (
												<>
													<Tooltip title="Juros por conta do Estabelecimento">
														<Button
															className={classes.btnCalculadora}
															onClick={() => setShowCalculadoraValorLiquido(true)}
														>
															<div>
																<small>Calculadora</small>
															</div>
															<div>Valor Líquido</div>
														</Button>
													</Tooltip>
													<Tooltip title="Juros por conta do Cliente">
														<Button
															className={classes.btnCalculadora}
															onClick={() => setShowCalculadoraValorBruto(true)}
														>
															<div>
																<small>Calculadora</small>
															</div>
															<div>Valor Bruto</div>
														</Button>
													</Tooltip>
												</>
											)}

											{erroTaxa && (
												<div>
													<div className={classes.header}>Taxas - {estabelecimentoNome}</div>
													<div className={classes.empty}>Nenhuma Taxa Encontrada.</div>
												</div>
											)}
											{loading ? (
												<ZLoader height={300} />
											) : (
												<div className={classes.bandeirasWrapper}>
													<div style={{ width: (countBandeiras + 1) * 110 }}>
														{Object.keys(taxas).map(bandeiraId => (
															<div
																key={`Bandeira-${bandeiraId}`}
																className={classes.bandeira}
															>
																<div
																	className={clsx(classes.title, {
																		[classes.titleLong]:
																			taxas[bandeiraId].titulo.length > 10,
																	})}
																>
																	{taxas[bandeiraId].titulo}
																</div>
																<div className={classes.fields}>
																	{Object.keys(taxas[bandeiraId].valores).map(
																		tipoPagamentoId => (
																			<div
																				key={`${bandeiraId}${tipoPagamentoId}`}
																				className={classes.tipoPagamentoFields}
																			>
																				{Object.keys(
																					taxas[bandeiraId].valores[
																						tipoPagamentoId
																					].valores
																				).map(parcela => (
																					<div
																						key={`${bandeiraId}-${parcela}`}
																						className={
																							classes.taxaFieldReadOnly
																						}
																					>
																						{Mask.Porcentagem(
																							taxas[bandeiraId].valores[
																								tipoPagamentoId
																							].valores[parcela]
																						)}{' '}
																						%
																					</div>
																				))}
																			</div>
																		)
																	)}
																</div>
															</div>
														))}
													</div>
												</div>
											)}
										</Grid>
									</Grid>
								)}

								{activeStep === 9 && (
									<>
										<Grid container>
											<Grid style={{ marginRight: 32, marginBottom: 32 }}>
												<Grid>
													<ZSwitch
														onChange={() => setData({ ...data, split: !data.split })}
														// onChange={() =>  true }
														label="Adicionar Split"
														checked={data.split}
													/>
												</Grid>

												{/* {data.split && (
													// <Grid>
													// 	<ZSwitch
													// 		onChange={() =>
													// 			setData(data => ({
													// 				...data,
													// 				chargeProcessingFee: !data.chargeProcessingFee,
													// 			}))
													// 		}
													// 		label={<div>Valor Líquido?</div>}
													// 		// checked={estabelecimento.chargeProcessingFee}
													// 	/>
													// </Grid>
												// )} */}
											</Grid>
										</Grid>
									</>
								)}

								{activeStep === 2 && (
									<Grid container style={{ marginLeft: 32, position: 'relative' }}>
										<div
											ref={qrCodeRef}
											style={{ position: 'absolute', right: 20, height: 128, cursor: 'pointer' }}
											onClick={downloadQRCode}
										>
											<QRCode
												renderAs="svg"
												value={`https://${url}${config.get('baseName')}/lp/${data.token}`}
											/>
											<div className={classes.qrcodeHover}>
												<FaDownload />
											</div>
										</div>
										<Grid item style={{ marginBottom: 16 }}>
											<Typography variant="h5" component="h5" gutterBottom>
												{data.id ? `ID - ${data.id} ` : `Resumo`}
											</Typography>
										</Grid>
										<Grid container spacing={2} item>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Título <br /> <span className={classes.span}>{data.titulo}</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												{/* Não está aparecendo */}
												<Typography variant="body1" component="h5" gutterBottom>
													Valor <br />{' '}
													<span className={classes.span}>
														{new Intl.NumberFormat('pt-BR', {
															style: 'currency',
															currency: 'BRL',
														}).format(+data.amount / 100)}
													</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Descrição <br />{' '}
													<span className={classes.span}>{data.descricao}</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												{/* Não está aparecendo */}
												<Typography variant="body1" component="h5" gutterBottom>
													Meio de pagamento <br />{' '}
													<span className={classes.span}>
														{humanizePayment(data.meio_pagamento)}
													</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												{/* Não está aparecendo */}
												<Typography variant="body1" component="h5" gutterBottom>
													Data de expiração <br />{' '}
													<span className={classes.span}>
														{format(data.data_expiracao, 'DD/MM/YYYY')}
													</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												{/* Não está aparecendo */}
												<Typography variant="body1" component="h5" gutterBottom>
													Percentual de juros <br />{' '}
													<span className={classes.span}>{data.percentual}%</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												{/* Não está aparecendo */}
												<Typography variant="body1" component="h5" gutterBottom>
													Exibir Logo <br />{' '}
													<span className={classes.span}>{data.logo ? 'Sim' : 'Não'}</span>
												</Typography>
											</Grid>

											{data.juros && (
												<Grid item md={6} xs={12} className={classes.labelResumo}>
													<Typography variant="body1" component="h5" gutterBottom>
														Juros a partir <br />{' '}
														<span className={classes.span}>
															{data.juros_a_partir < 2
																? 'N/A'
																: `${data.juros_a_partir} vezes`}
														</span>
													</Typography>
												</Grid>
											)}

											<Grid item md={6} xs={12} className={classes.labelResumo}>
												{/* Não está aparecendo */}
												<Typography variant="body1" component="h5" gutterBottom>
													{data.limit_installments
														? 'Limitar em parcelas'
														: 'Máximo de parcelas'}{' '}
													<br />{' '}
													<span className={classes.span}>
														{data.parcelamento_ate === 1
															? `${data.parcelamento_ate} vez`
															: `${data.parcelamento_ate} vezes`}
													</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Limitar Parcelas <br />{' '}
													<span className={classes.span}>
														{data.limit_installments ? 'Sim' : 'Não'}
													</span>
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								)}
								<Grid container item spacing={3} xs={12} md={6} lg={6} className={classes.divButton}>
									<Grid item xs={12} md={6} lg={6}>
										{activeStep === 2 && data.id ? (
											<Button
												onClick={() => {
													const text = encodeURIComponent(
														`Link de Pagamento - ${
															data.nome_fantasia
														}\n\n*${data.titulo.trim()}*\nhttps://${url}${config.get(
															'baseName'
														)}/lp/${data.token}`
													);
													window.open(`https://wa.me/?text=${text}`, '_blank');
												}}
												variant="contained"
												fullWidth
												color="primary"
											>
												Enviar Via Whatsapp
											</Button>
										) : (
											<Button
												disabled={activeStep === 0}
												onClick={handleBack}
												variant="outlined"
												fullWidth
												color="primary"
											>
												Voltar
											</Button>
										)}
									</Grid>
									{activeStep < 2 && (
										<Grid item xs={12} md={6}>
											<Button
												onClick={handleNext}
												fullWidth
												color={'primary'}
												variant="contained"
											>
												Continuar
											</Button>
										</Grid>
									)}
									{activeStep === 2 && !data.id && (
										<Grid item xs={12} md={6}>
											<ZSaveButton
												onClick={() => handleSubmitForm()}
												color={'primary'}
												align="right"
												label={'Salvar'}
												required={true}
												loading={loading}
											/>
										</Grid>
									)}
									{activeStep === 2 && data.id && (
										<Grid item xs={12} md={6}>
											<ZSaveButton
												onClick={() => window.open(`/lp/${data.token}`, '_blank')}
												color={'primary'}
												align="right"
												label={'Visualizar tela de pagamento'}
											/>
										</Grid>
									)}
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Grid>

				{/* <Grid item xs={12} style={{ marginTop: 10 }}>
					<Grid container justifyContent="center">
						{data.id && (
							<Grid item xs={12} sm={4} md={4}>
								<div style={{ textAlign: 'center' }}>
									<Tooltip title="Compartilhar no Whatsapp">
										<span>
											<FaWhatsapp
												className={classes.menuIcon}
												onClick={() => {
													const text = encodeURIComponent(
														`Link de Pagamento - ${
															data.nome_fantasia
														}\n\n*${data.titulo.trim()}*\nhttps://${url}${config.get(
															'baseName'
														)}/venda/token/${data.token}`
													);
													window.open(`https://wa.me/?text=${text}`, '_blank');
												}}
											/>
										</span>
									</Tooltip>
									<Tooltip title="Enviar por E-mail">
										<span>
											<FaShare
												className={classes.menuIcon}
												onClick={() => {
													const text = encodeURIComponent(
														`Link de Pagamento - ${
															data.nome_fantasia
														}\n\n*${data.titulo.trim()}*\nhttps://${url}${config.get(
															'baseName'
														)}/venda/token/${data.token}`
													);
													window.open(`mailto:?subject=${text}`, '_blank');
												}}
											/>
										</span>
									</Tooltip>
								</div>
							</Grid>
						)}
					</Grid>
				</Grid> */}

				{data.id && data.pedidos.length > 0 && <ListaDePedidosPreVenda preVendaId={data.id} />}
			</Grid>

			{showCalculadoraValorLiquido && (
				<CalculadoraValorLiquido
					open={showCalculadoraValorLiquido}
					estabelecimentoId={estabelecimentoId}
					onClose={() => setShowCalculadoraValorLiquido(false)}
				/>
			)}

			{showCalculadoraValorBruto && (
				<CalculadoraValorBruto
					open={showCalculadoraValorBruto}
					estabelecimentoId={estabelecimentoId}
					onClose={() => setShowCalculadoraValorBruto(false)}
				/>
			)}

			<ConfirmDialog
				open={showConfirmModal}
				dialogContent={dialogContent}
				handleClose={() => setShowConfirmModal(false)}
			/>
			<ShareDialog
				open={showShareModal}
				handleClose={() => {
					setShowShareModal(false);
					history.push('/venda-digitada/' + successPreVenda.id);
				}}
				handleSuccess={handleSuccess}
			/>
		</Container>
	);
};

export default CadastrarVendaDigitada;
