/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import axios from 'axios';
import config from 'react-global-configuration';
import parse from 'date-fns/parse';
import { addDays } from 'date-fns';
import { addMonths } from 'date-fns';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { Formatter, Isset } from '../../utils';
import {
	Button,
	LinearProgress,
	Grid,
	Paper,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogContent,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	DialogActions,
} from '@material-ui/core';
import { ZLoader } from '../system';
import DashboardFiltros from './DashboardFiltros';
import ExtratoChart from './DashboardGraficos/ExtratoChart';
import VendasChart from './DashboardGraficos/VendasChart';
import LancamentosFuturosChart from './DashboardGraficos/LancamentosFuturosChart';
import UltimasVendas from './Dashboard/UltimasVendas';
import UltimasTransferencias from './Dashboard/UltimasTransferencias';
import { useStyles } from './styles/DashboardStyle';
import ZSaveButton from '../system/ZSaveButton';

// const VendasPorMes = memo(props => {
// 	const classes = useStyles();
// 	const token = useSelector(store => store.auth.token);
// 	const estabelecimento = useSelector(store => store.auth.estabelecimento);
// 	let estabelecimentoId = props.estabelecimentoId || estabelecimento.id;

// 	const [vendasPorMesFiltro, setVendasPorMesFiltro] = useState({
// 		startDate: addMonths(new Date(), -6),
// 		endDate: new Date(),
// 	});
// 	const [loading, setLoading] = useState(true);
// 	const [vendas, setVendas] = useState([]);

// 	useEffect(() => {
// 		const getVendas = async () => {
// 			setLoading(true);
// 			const res = await axios.get(`${config.get('apiUrl')}dashboard/vendasPorMes/${estabelecimentoId}`, {
// 				headers: {
// 					Authorization: `Bearer ${token}`,
// 				},
// 				params: {
// 					...vendasPorMesFiltro,
// 				},
// 			});

// 			if (res.data.success) {
// 				setVendas(res.data.vendas);
// 			}
// 			setLoading(false);
// 		};

// 		getVendas();
// 	}, [vendasPorMesFiltro, estabelecimentoId]);

// 	return (
// 		<Paper className={clsx(classes.box)}>
// 			<div className={classes.boxTitle}>Vendas Por Mês</div>
// 			<div className={classes.boxFiltro}>
// 				<DashboardFiltros
// 					default="last6months"
// 					maxDate={new Date()}
// 					onClose={ranges => setVendasPorMesFiltro(ranges)}
// 				/>
// 			</div>
// 			{loading ? (
// 				<ZLoader height={100} size={20} />
// 			) : (
// 				<>
// 					{vendas ? (
// 						<VendasPorMesChart data={vendas} />
// 					) : (
// 						<div className={clsx(classes.empty, classes.extratoEmpty)}>Nenhum Resultado Encontrado.</div>
// 					)}
// 				</>
// 			)}
// 		</Paper>
// 	);
// });

const ConfirmDialog = memo(props => {
	const classes = useStyles();
	// const dispatch = useDispatch();

	const [loading] = useState(false);
	const [value, setValue] = useState('');
	const [open, setOpen] = useState(true);

	// const handleResposta = async () => {
	// 	if (value) {
	// 		setLoading(true);

	// 		const data = {
	// 			resposta: value,
	// 		};

	// 		const response = await axios.post(`${config.get('apiUrl')}estabelecimentos/enquete-onepay`, data, {
	// 			headers: { Authorization: `Bearer ${props.token} ` },
	// 		});

	// 		if (response.data.success) {
	// 			dispatch(addMessage({ type: 'success', message: response.data.message }));

	// 			setLoading(false);
	// 			props.handleClose();
	// 		}
	// 	} else {
	// 		setLoading(false);

	// 		dispatch(addMessage({ type: 'info', message: 'Aviso' }));
	// 	}
	// };

	return (
		<>
			<Dialog
				disableEscapeKeyDown
				open={open}
				keepMounted
				onClose={props.onClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
				scroll="body"
			>
				<DialogTitle id="alert-dialog-slide-title">{'Temos uma notícia!'}</DialogTitle>
				<DialogContent className={classes.dialog}>
					<DialogContentText id="alert-dialog-slide-description" className={classes.contentText}>
						<p> Prezado Cliente, </p>
						<p>
							{' '}
							À medida que nos aproximamos do carnaval, gostaríamos de informar sobre as grades de
							pagamento que afetarão nossos serviços durante as datas festivas.
						</p>
						<p>PAYOUT</p>
						<p>
							10 a 13 de fevereiro - o serviço estará indisponivel. <br />
							14 de fevereiro funcionará normalmente.
						</p>
						<p>COMPENSAÇÃO DE BOLETOS</p>
						<p>
							10 a 13 de fevereiro - Não haverá compensação de boletos!
							<br />
							14 de fevereiro funcionará normalmente.
							<br />
						</p>

						<p>ANTECIPAÇÃO SOB DEMANDA: </p>
						<p>
							<p>
								10 a 13 de fevereiro - o serviço estará indisponivel. <br />
								14 de fevereiro funcionará normalmente.
							</p>
						</p>

						<p>
							Se surgirem dúvidas ou se precisar de assistência adicional, nossa equipe estará à
							disposição nos canais de atendimento : (11) 2626-8036 ou viaWhatsApp (11) 99831-9001
							Agradecemos pela sua compreensão e desejamos a você e seus entes queridos uma temporada
							festiva repleta de alegria e prosperidade.
						</p>

						<p> Atenciosamente, Equipe One Pay </p>

						<FormControl component="fieldset" style={{ marginTop: 16 }}>
							<RadioGroup
								aria-label="resposta"
								name="resposta1"
								value={value}
								onChange={e => setValue(e.target.value)}
							>
								<FormControlLabel
									value="Estou ciente."
									control={<Radio color="primary" />}
									label="Estou ciente."
								/>
							</RadioGroup>
						</FormControl>
					</DialogContentText>
				</DialogContent>
				<DialogActions style={{ textAlign: 'center' }}>
					<ZSaveButton
						onClick={() => {
							setOpen(false);
						}}
						color="primary"
						align="right"
						label="Confirmar"
						loading={loading}
					/>
				</DialogActions>
			</Dialog>
		</>
	);
});

const Dashboard = props => {
	const { history } = useReactRouter();
	const classes = useStyles();
	const token = useSelector(state => state.auth.token);
	const usuario = useSelector(state => state.auth.usuario);
	const adminEstabelecimento = useSelector(state => state.admin.estabelecimento);
	const { id: estabelecimentoId } = adminEstabelecimento || {};
	const axiosCancelSource = axios.CancelToken.source();

	const [chartLoading, setChartLoading] = useState(true);

	// Enquete Onepay
	const [setShowConfirmModal] = useState(false);

	// Dados
	const [extratoData, setExtratoData] = useState({});
	const [vendasData, setVendasData] = useState([]);
	const [lancamentosData, setLancamentosData] = useState([]);
	const [vendasValorTotal, setVendasValorTotal] = useState([]);
	const [extratoDados, setExtratoDados] = useState({
		entrada: { total: 0, porcentagem: 0 },
		saida: { total: 0, porcentagem: 0 },
	});

	// Filtros
	const [extratoFiltro, setExtratoFiltro] = useState({ startDate: addDays(new Date(), -10), endDate: new Date() });
	const [vendasFiltro, setVendasFiltro] = useState({ startDate: new Date(), endDate: new Date() });
	const [lancamentosFiltro, setLancamentosFiltro] = useState({
		startDate: new Date(),
		endDate: addMonths(new Date(), 6),
	});

	// * Controle para mostrar o Dialog
	const [showDialog, setShowDialog] = useState(false);

	useEffect(() => {
		// * Colocar aqui a Data para mostrar o Dialog
		const showDialogUntil = new Date('2024-02-15'); // YYYY-MM-DD
		const now = new Date();

		// * Mostra o Dialog se a data atual for anterior a data de showDialogUntil
		if (now.getTime() < showDialogUntil.getTime()) {
			setShowDialog(true);
		}
	}, [showDialog]);

	if (usuario.grupo_id === 5) {
		history.push('/dashboard/representante');
	}

	if (usuario.grupo_id === 7) {
		history.push('/dashboard/backoffice');
	}

	if (usuario.grupo_id === 8) {
		history.push('/estabelecimentos');
	}
	if (usuario.grupo_id === 9) {
		history.push('/estabelecimentos/pre-cadastro/comercial');
	}

	useEffect(() => {
		// * Pega extrato
		axios
			.get(`${config.get('apiUrl')}dashboard/extrato`, {
				cancelToken: axiosCancelSource.token,
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					...extratoFiltro,
					filters: {
						estabelecimentoId,
					},
				},
			})
			.then(res => res.data)
			.then(data => {
				if (data.success) {
					setExtratoData(data);
					const { entrada, saida } = data;
					const entradaTotal = entrada.reduce((r, v) => r + +v.valorTotal, 0);
					const saidaTotal = saida.reduce((r, v) => r + +v.valorTotal, 0);
					const total = entradaTotal + saidaTotal;

					setExtratoDados({
						entrada: { total: entradaTotal, porcentagem: ((+entradaTotal * 100) / +total).toFixed(1) },
						saida: { total: saidaTotal, porcentagem: ((+saidaTotal * 100) / +total).toFixed(1) },
					});
				}
			});

		// * Pega vendas
		setChartLoading(true);
		axios
			.get(`${config.get('apiUrl')}dashboard/vendas`, {
				cancelToken: axiosCancelSource.token,
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					...vendasFiltro,
					filters: {
						estabelecimentoId,
					},
				},
			})
			.then(res => res.data)
			.then(data => {
				if (data.success) {
					const { valorTotal, formasPagamentos } = data;
					setVendasData(formasPagamentos || []);
					setVendasValorTotal(valorTotal || 0);
				}
				setChartLoading(false);
			});

		// * Pega lançamentos
		axios
			.get(`${config.get('apiUrl')}dashboard/lancamentos`, {
				cancelToken: axiosCancelSource.token,
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					...lancamentosFiltro,
					filters: {
						estabelecimentoId,
					},
				},
			})
			.then(res => res.data)
			.then(data => {
				if (data.success) {
					const { lancamentos } = data;
					let chartData = lancamentos
						.map(v => ({
							mes: format(parse(v.mes, 'YYYYMM', new Date()), 'MMM/YY', { locale: pt }),
							value: v.valorTotal,
						}))
						.map(v => ({ ...v, mes: v.mes.charAt(0).toUpperCase() + v.mes.slice(1) }));

					setLancamentosData(chartData);
				}
			});
		return async () => {
			axiosCancelSource.cancel('Dashboard Unmounted.');
		};
	}, [extratoFiltro, lancamentosFiltro, vendasFiltro, estabelecimentoId]);

	// Enquete Onepay
	useEffect(() => {
		axios
			.get(`${config.get('apiUrl')}estabelecimentos/enquete-onepay`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then(res => res.data)
			.then(data => {
				if (!data.respondeu) {
					setShowConfirmModal(true);
				}
			});
	}, []);

	return (
		<div>
			<Grid container spacing={2} direction="row" justifyContent="center" alignItems="stretch">
				<Grid xs={12} sm={6} md={4} container item>
					<Grid xs={12} className={classes.extrato} item>
						<Paper className={clsx(classes.box)}>
							<div className={classes.boxTitle}>Extrato {format(new Date(), 'MM/YYYY')}</div>
							<div className={classes.boxFiltro}>
								<DashboardFiltros
									default="last10days"
									maxDate={new Date()}
									onClose={ranges => setExtratoFiltro(ranges)}
								/>
							</div>
							{Isset(() => extratoData.entrada[0]) || Isset(() => extratoData.saida[0]) ? (
								<ExtratoChart data={extratoData} />
							) : (
								<div className={clsx(classes.empty, classes.extratoEmpty)}>
									Nenhum Resultado Encontrado.
								</div>
							)}
							{/* <div className={clsx({ [classes.hide]: extratoChart === null, [classes.extratoChart]: true, extratoChart: true})}></div> */}
							<div className={classes.extratoIO}>
								<div>
									Entrada ({Formatter.Real(extratoDados.entrada.total)})
									<div>
										<LinearProgress
											className={clsx(classes.progress, classes.progressEntrada)}
											color="secondary"
											variant="determinate"
											value={+extratoDados.entrada.porcentagem}
										/>
									</div>
								</div>
								<div>
									Saída ({Formatter.Real(extratoDados.saida.total)})
									<div>
										<LinearProgress
											className={clsx(classes.progress, classes.progressSaida)}
											color="secondary"
											variant="determinate"
											value={+extratoDados.saida.porcentagem}
										/>
									</div>
								</div>
							</div>
							<div className={classes.extratoFooter} onClick={() => history.push('/extrato')}>
								<Button size="small">Ver todos os lançamentos</Button>
							</div>
						</Paper>
					</Grid>
					{/* <Grid xs={12} style={{ height: 138, marginTop: 10 }} item>
						<VendasPorMes estabelecimentoId={estabelecimentoId} />
					</Grid> */}
				</Grid>
				<Grid xs={12} sm={6} md={8} className={classes.vendas} item>
					<Paper className={clsx(classes.box)}>
						<div className={classes.boxTitle}>Vendas</div>
						<div className={classes.boxFiltro}>
							<DashboardFiltros
								default="today"
								maxDate={new Date()}
								onClose={ranges => setVendasFiltro(ranges)}
							/>
						</div>
						<Grid container>
							<Grid xs={12} sm={12} md={5} item>
								{chartLoading ? (
									<ZLoader height={200} size={50} />
								) : (
									<VendasChart data={vendasData} valorTotal={vendasValorTotal} />
								)}
							</Grid>
							<Grid xs={12} sm={12} md={7} item className={classes.webOnly}>
								<UltimasVendas estabelecimentoId={estabelecimentoId} />
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12} className={classes.btnUltimasVendas}>
								<div className={classes.ultimasVendasFooter} onClick={() => history.push('/vendas')}>
									<Button size="small">Ver todas as vendas</Button>
								</div>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
			<Grid container spacing={2} direction="row" justifyContent="center" alignItems="stretch">
				<Grid xs={12} md={6} className={classes.transferencias} item>
					{usuario.grupo_id !== 11 && <UltimasTransferencias estabelecimentoId={estabelecimentoId} />}
				</Grid>
				<Grid xs={12} md={6} className={classes.lancamentos} item>
					<Paper className={clsx(classes.box)}>
						<Grid container>
							<Grid item xs={12}>
								<div className={classes.boxTitle}>Lançamentos Futuros</div>
								<div className={classes.boxFiltro}>
									<DashboardFiltros
										default="next6months"
										minDate={new Date()}
										onClose={ranges => setLancamentosFiltro(ranges)}
									/>
								</div>

								{Object.keys(extratoData).length > 0 ? (
									<LancamentosFuturosChart data={lancamentosData} />
								) : (
									<div className={clsx(classes.empty, classes.extratoEmpty)}>
										Nenhum Resultado Encontrado.
									</div>
								)}
							</Grid>
							<Grid item xs={12} style={{ marginTop: 15 }}>
								<div
									className={classes.ultimasTransferenciasFooter}
									onClick={() => history.push('/proximos-lancamentos?tt=2&d=next6months')}
								>
									<Button size="small">Ver todos lançamentos</Button>
								</div>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>

			{/* Abaixo é onde ativamos ou desativamos o Dialog que deve aparecer quando alguém entra na Dash */}
			{showDialog && <ConfirmDialog token={token} />}
		</div>
	);
};

export default Dashboard;
