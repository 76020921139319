import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import config from 'react-global-configuration';

import { ZCard, ZSaveButton, ZLoader } from '../system';
import { Grid, Paper } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { Mask, Formatter, trimChar } from '../../utils';
import { addMessage } from '../../store/alert';

const useStyle = makeStyles(theme => ({
	header: {
		fontSize: 16,
		fontWeight: 'bold',
		marginTop: 10,
		marginBottom: 10,
	},
	paper: {
		padding: 10,
	},
	field: {
		padding: 5,
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	label: {
		fontSize: 14,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
}));

const getFrequency = frequency => {
	switch (frequency) {
		case 'daily':
			return 'Diário';
		case 'monthly':
			return 'Mensal';
		case 'annually':
			return 'Anual';
		default:
			return '';
	}
};

const AssinaturasAlterarCartao = () => {
	const classes = useStyle();
	const { assinaturaId } = useParams();
	const history = useHistory();
	const token = useSelector(store => store.auth.token);
	const dispatch = useDispatch();
	const [flag, setFlag] = useState('');
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [assinatura, setAssinatura] = useState(null);
	const [onError, setOnError] = useState(false);
	const [data, setData] = useState({
		numero: '',
		titular: '',
		validade: '',
		codigoSeguranca: '',
	});

	useEffect(() => {
		const getCartaoAssinatura = async () => {
			setAssinatura(null);
			setLoading(true);
			const res = await axios.get(`${config.get('apiUrl')}planos/assinatura/${assinaturaId}/get_cartao`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				setAssinatura(res.data.assinatura);
			}
			setLoading(false);
		};

		getCartaoAssinatura();
	}, [assinaturaId, token]);

	const handleSubmitForm = async () => {
		setSaving(true);
		setOnError(false);

		const error = Object.values(data).filter(d => d === '').length > 0;
		if (error) {
			setOnError(true);
		} else {
			const res = await axios.put(
				`${config.get('apiUrl')}planos/assinatura/${assinaturaId}/alterar_cartao`,
				{ cartao: data },
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				history.push(`/assinaturas/${assinatura.plano.id}`);
			} else {
				dispatch(addMessage({ type: 'error', message: res.data.message }));
			}
		}
		setSaving(false);
	};

	if (loading) {
		return <ZLoader height={200} />;
	}

	return (
		<div className={classes.changeCard}>
			<Grid container spacing={2} justifyContent="center">
				<Grid item xs={12} md={10}>
					<Paper className={classes.paper}>
						<div className={classes.header}>Assinatura</div>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={4} md={3}>
								<div className={classes.field}>
									<div className={classes.label}>Plano</div>
									<div>{assinatura.plano.name}</div>
								</div>
							</Grid>
							<Grid item xs={12} sm={4} md={3}>
								<div className={classes.field}>
									<div className={classes.label}>Descrição</div>
									<div>{assinatura.plano.description}</div>
								</div>
							</Grid>
							<Grid item xs={12} sm={4} md={3}>
								<div className={classes.field}>
									<div className={classes.label}>Frequência</div>
									<div>{getFrequency(assinatura.plano.frequency)}</div>
								</div>
							</Grid>
							<Grid item xs={12} sm={4} md={3}>
								<div className={classes.field}>
									<div className={classes.label}>Valor</div>
									<div>{Formatter.Real(assinatura.plano.amount / 100)}</div>
								</div>
							</Grid>
						</Grid>

						<div className={classes.header}>Dados do Cartão</div>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={4} md={3}>
								<div className={classes.field}>
									<div className={classes.label}>Bandeira</div>
									<div>{assinatura.cliente.cartao.bandeira}</div>
								</div>
							</Grid>
							<Grid item xs={12} sm={4} md={3}>
								<div className={classes.field}>
									<div className={classes.label}>Número</div>
									<div>
										{Formatter.Cartao(
											`**** **** **** ${assinatura.cliente.cartao.ultimos_digitos}`
										)}
									</div>
								</div>
							</Grid>
							<Grid item xs={12} sm={4} md={3}>
								<div className={classes.field}>
									<div className={classes.label}>Titular</div>
									<div>{assinatura.cliente.cartao.nome_titular}</div>
								</div>
							</Grid>
							<Grid item xs={12} sm={4} md={3}>
								<div className={classes.field}>
									<div className={classes.label}>Vencimento</div>
									<div>{`**/${assinatura.cliente.cartao.ano_expiracao}`}</div>
								</div>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs={12} md={10}>
					<Paper className={classes.paper}>
						<div className={classes.header}>Novo Cartão</div>
						<ZCard
							test={e => {
								if (flag !== e && e !== 'unknown') {
									setFlag(e);
								}
								if (e === 'unknown') {
									setFlag('');
								}
							}}
							number={data.numero}
							name={data.titular}
							expiry={data.validade}
							cvc={data.codigoSeguranca}
							numberOnBlur={e =>
								setData({
									...data,
									numero: Mask.OnlyNumber(e.target.value),
								})
							}
							nameOnBlur={e =>
								setData({
									...data,
									titular: e.target.value,
								})
							}
							expiryOnBlur={e =>
								setData({
									...data,
									validade: e.target.value,
								})
							}
							cvcOnBlur={e =>
								setData({
									...data,
									codigoSeguranca: trimChar(e.target.value, '_'),
								})
							}
							onError={onError}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={4} md={3}>
					<ZSaveButton
						onClick={() => handleSubmitForm()}
						color={'primary'}
						align="right"
						label={'Salvar'}
						required={true}
						loading={saving}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default AssinaturasAlterarCartao;
