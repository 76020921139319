import React, { useState, useEffect, useReducer } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
	MenuItem,
	Grid,
	Select,
	FormControl,
	InputLabel,
	Paper,
	TextField,
	Button,
	InputAdornment,
	IconButton,
	Switch,
	Typography,
} from '@material-ui/core';
import { FaPlus, FaMinus, FaSearch } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useSelector } from 'react-redux';
import ZPlanos from '../system/ZPlanos';
import config from 'react-global-configuration';
import { isMobile } from 'react-device-detect';

const parcelas = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

const bandeiras = [
	{ name: 'American Express' },
	{ name: 'Elo', name2: 'Elo' },
	{ name: 'MasterCard', name2: 'Maestro' },
	{ name: 'Visa', name2: 'Visa Electron' },
	{ name: 'Diners Club', name2: 'Diners Club' },
	{ name: 'Hiper', name2: 'Hiper' },
	{ name: 'HiperCard', name2: 'HiperCard' },
	{ name: 'Banescard', name2: 'Banescard' },
];
const pagamentos = [
	{ name: 'Crédito', slug: 'credit' },
	{ name: 'Débito', slug: 'debit' },
];
const useStyles = makeStyles(theme => ({
	vendas: {
		width: '100%',
		marginBottom: '2rem',
		flexDirection: 'row',
	},
	op: {
		minWidth: 150,
		margin: 0,
	},
	item: {
		minWidth: '150px',
	},
	circle: {
		borderRadius: '50% 50% 50% 50% / 50% 50% 50% 50% ',
		backgroundColor: 'green',
		width: '5px',
	},
	text: {
		minWidth: 150,
		maxWidth: 150,
	},
	icon: {
		margin: 15,
		boxShadow: '0px 0px 26px -9px rgba(0,0,0,0.75)',
	},
	btnContainer: {},
	button: {
		minWidth: 150,
		borderColor: `${theme.palette.primary.main}`,
		color: `${theme.palette.primary.main}`,
		top: 10,
		borderWidth: '2px',
		margin: 15,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}`,
			color: theme.palette.getContrastText(theme.palette.primary.main),
			'&:nth-child(1)': {
				color: 'white',
			},
		},
	},
	container: {
		maxWidth: '80%',
	},
	container2: {
		margin: isMobile ? 0 : 35,
	},
	paper: {
		marginBottom: 25,
	},
	switchC: {
		marginLeft: isMobile ? 0 : 45,
	},
}));

const OnePaySwitch = withStyles(theme => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1),
	},
	switchBase: {
		padding: 1,
		color: theme.palette.primary.main,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				opacity: 1,
				backgroundColor: theme.palette.primary.main,
				borderColor: theme.palette.primary.main,
				border: 'none',
			},
			'&$focusVisible $thumb': {
				color: '#52d869',
				border: '6px solid #fff',
			},
		},
	},
	thumb: {
		width: 24,
		height: 24,
	},
	track: {
		border: `1px solid ${theme.palette.grey[500]}`,
		borderRadius: 26 / 2,
		opacity: 1,
		backgroundColor: theme.palette.common.white,
	},
	checked: {},
}))(Switch);

function reducer(state, action) {
	switch (action.type) {
		case 'on':
			return { value: true, slug: 'sim' };
		case 'off':
			return { value: false, slug: 'não' };
		default:
			return state;
	}
}
const ZFiltrosOptimal = props => {
	const classes = useStyles();
	const [inputList, setInputList] = useState([{ bandeira: null, taxa: null, formaPagamento: null, parcela: null }]);
	const [loading, setLoading] = useState(false);
	const [disable, setDisable] = useState(true);
	const [planos, setPlanos] = useState();
	const token = useSelector(store => store.auth.token);
	const [state, dispatch] = useReducer(reducer, { value: false, slug: 'não' });

	useEffect(() => {
		isDisabled();
	});
	const isDisabled = () => {
		let tra = inputList
			.map(i => i)
			.map(i =>
				i.bandeira !== null && i.formaPagamento !== null && i.taxa !== null && i.parcela !== null ? false : true
			);
		let checker = arr => arr.some(v => v === true);
		let final = checker(tra);
		setDisable(final);
	};

	const handleInputChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...inputList];
		list[index][name] = value;
		setInputList(list);
	};

	const handleRemoveClick = index => {
		const list = [...inputList];
		list.splice(index, 1);
		setInputList(list);
	};

	const handleAddClick = () => {
		setInputList([...inputList, { bandeira: null, taxa: null, formaPagamento: null, parcela: null }]);
	};
	const formatter = async () => {
		let obj = {};
		inputList.map(item => createNestedObject(obj, [item.bandeira, item.formaPagamento, item.parcela], item.taxa));
		let newObj = {
			adiantamento: state.slug,
			taxas: { ...obj },
		};
		let conf = {
			method: 'post',
			url: `${config.get('apiUrl')}/planos_de_vendas/negociacao`,
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			data: {
				...newObj,
			},
		};
		const res = await axios(conf);
		if (res.data) {
			setPlanos(res.data.planos);
			setLoading(true);
		}
	};
	let createNestedObject = function(base, names, value) {
		let lastName = arguments.length === 3 ? names.pop() : false;
		for (let i = 0; i < names.length; i++) {
			base = base[names[i]] = base[names[i]] || {};
		}
		if (lastName) base = base[lastName] = parseFloat(value.replace(',', '.')) * 100;
		return base;
	};
	const handleCheck = event => {
		if (state.value === false) {
			return dispatch({ type: 'on' });
		} else {
			return dispatch({ type: 'off' });
		}
	};
	const clearField = (e, i) => {
		const list = [...inputList];
		list[i] = { bandeira: null, taxa: 0, formaPagamento: null, parcela: null };
		setInputList(list);
	};
	return (
		<>
			<Grid container className={classes.container}>
				<Grid item md={12} xl={12} xs={12} lg={12}>
					<Paper className={classes.paper} elevation={3}>
						<Grid container alignItems="center" justifyContent={isMobile ? 'center' : 'start'}>
							<Grid item>
								<FormControl className={classes.switchC} variant="outlined">
									<Typography component="div">
										<Grid component="label" container alignItems="center" spacing={1}>
											<Grid item>STD</Grid>
											<Grid item>
												<OnePaySwitch value={state.value} onChange={handleCheck} />
											</Grid>
											<Grid item>PRO</Grid>
										</Grid>
									</Typography>
								</FormControl>
							</Grid>
						</Grid>
						<Grid>
							{inputList.map((item, i) => {
								return (
									<>
										<Grid
											container
											className={classes.container2}
											direction={isMobile ? 'column' : 'row'}
											justifyContent="flex-start"
											alignItems="center"
										>
											<Grid item md={2} lg={2}>
												<Button
													variant="outlined"
													onClick={e => clearField(e, i)}
													className={classes.button}
												>
													Limpar Campo
												</Button>
											</Grid>
											<Grid item md={2} lg={2}>
												<FormControl variant="standard">
													<InputLabel>Pagamento</InputLabel>
													<Select
														name="formaPagamento"
														onChange={e => handleInputChange(e, i)}
														value={item.formaPagamento}
														label="Forma De Pagamento"
														className={classes.op}
													>
														<MenuItem disabled value="">
															<em>Forma de Pagamento</em>
														</MenuItem>
														{pagamentos.map((item, index) => (
															<MenuItem key={index} value={item.slug}>
																{item.name}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item md={2} lg={2}>
												<FormControl variant="standard">
													<InputLabel>Bandeira</InputLabel>
													<Select
														name="bandeira"
														onChange={e => handleInputChange(e, i)}
														label="Bandeira"
														value={item.bandeira}
														className={classes.op}
													>
														<MenuItem disabled value="">
															<em>Bandeira</em>
														</MenuItem>
														{bandeiras.map((bandeira, index) =>
															inputList[i].formaPagamento === 'credit' ? (
																<MenuItem key={index} value={bandeira.name}>
																	{bandeira.name}
																</MenuItem>
															) : (
																<MenuItem key={index} value={bandeira.name2}>
																	{bandeira.name2}
																</MenuItem>
															)
														)}
													</Select>
												</FormControl>
											</Grid>
											<Grid item md={2} lg={2}>
												<FormControl variant="standard">
													<InputLabel>Parcela</InputLabel>
													<Select
														name="parcela"
														onChange={e => handleInputChange(e, i)}
														value={item.parcela}
														label="Parcelas"
														className={classes.op}
													>
														<MenuItem disabled value="">
															<em>Escolha a Quantidade</em>
														</MenuItem>
														{inputList[i].formaPagamento === 'credit' &&
															parcelas.map(
																(item, index) =>
																	inputList[i].formaPagamento === 'credit' && (
																		<MenuItem key={index} value={item}>
																			{item}
																		</MenuItem>
																	)
															)}
														{inputList[i].formaPagamento === 'debit' && (
															<MenuItem key={1} value={1}>
																1
															</MenuItem>
														)}
													</Select>
												</FormControl>
											</Grid>
											<Grid item md={2} lg={2}>
												<TextField
													className={classes.text}
													onChange={e => handleInputChange(e, i)}
													value={item.taxa}
													label="Taxa"
													type="number"
													InputProps={{
														endAdornment: <InputAdornment position="end">%</InputAdornment>,
													}}
													variant="standard"
													name="taxa"
												/>
											</Grid>

											{inputList.length - 1 === i && (
												<IconButton className={classes.icon} onClick={() => handleAddClick(i)}>
													<FaPlus
														style={{
															color: 'green',
														}}
													/>
												</IconButton>
											)}
											{inputList.length !== 1 && (
												<IconButton
													className={classes.icon}
													onClick={() => handleRemoveClick(i)}
												>
													<FaMinus
														style={{
															color: 'red',
														}}
													/>
												</IconButton>
											)}
										</Grid>
									</>
								);
							})}
							<Grid container direction="row" justifyContent="center" alignItems="center">
								<Button
									disabled={disable}
									variant="outlined"
									onClick={formatter}
									className={classes.button}
									endIcon={<FaSearch />}
								>
									Buscar Planos
								</Button>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
			<Grid container direction="row" justifyContent="center" alignItems="center">
				{loading && planos && <ZPlanos obj={planos} />}
				{loading && planos.length === 0 && (
					<Typography variant="h4" component="h2">
						Não existem planos que cobrem esse perfil
					</Typography>
				)}
			</Grid>
		</>
	);
};

export default ZFiltrosOptimal;
