/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import config from 'react-global-configuration';
import clsx from 'clsx';
import { addMessage } from '../../store/alert';
import {
	Backdrop,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Slide,
	TextField,
	Typography,
} from '@material-ui/core';
import moment from 'moment';
import Cards from 'react-credit-cards';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { format } from 'date-fns';
import { addDays } from 'date-fns';
// import addYears from 'date-fns/addYears';
import parse from 'date-fns/parse';
import { Mask, Formatter, Isset, dbDateToDate } from '../../utils';
import { ZLoader, ZTitle, ZDatePicker } from '../system';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	fullWidth: {
		width: '100%',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	novaVenda: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 960,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3),
		},
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	subHeader: {
		fontWeight: 700,
		fontSize: 16,
		margin: 8,
		marginTop: 50,
		textAlign: 'center',
	},
	subHeader2: {
		fontWeight: 700,
		fontSize: 14,
		margin: 8,
		marginTop: 20,
		marginBottom: 0,
	},
	content: {
		padding: 10,
	},
	formaPagamento: {
		padding: 20,
		fontSize: 14,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 32,
		fontWeight: 700,
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 6,
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
		[theme.breakpoints.down('sm')]: {
			padding: 10,
			marginBottom: 20,
		},
	},
	stepActions: {
		marginTop: 60,
		textAlign: 'center',
	},
	backButton: {
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		marginRight: 10,
	},
	nextButton: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
	estabelecimentoValor: {
		'&.porcentagem input': {
			textAlign: 'right',
		},
	},
	field: {
		padding: 5,
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	valorTotal: {
		fontSize: 24,
		fontWeight: 700,
	},
	parcelas: {
		fontSize: 18,
		fontWeight: 700,
	},
	parcelasValor: {
		fontSize: 15,
		fontWeight: 600,
	},
	stepTitle: { padding: 16, marginBottom: 32 },
	clientes: {
		border: '1px solid #eee',
		borderRadius: 4,
		overflowY: 'scroll',
		height: 350,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 32,
		},

		'& p': {
			marginLeft: 16,
			marginRight: 16,
			padding: 5,
		},

		'& p:hover': {
			cursor: 'pointer',
			backgroundColor: `${theme.palette.primary.main}2b`,
			// padding: 4,
			borderRadius: 4,
		},
	},
	containerEndereco: {
		display: 'flex',

		'& div + div': {
			marginLeft: 8,
		},
	},
	removeButton: {
		minHeight: 26,
		height: 26,
		width: 26,
		backgroundColor: '#e53935',
		color: 'white',
	},
	error: {
		padding: 8,
		borderRadius: 5,
		backgroundColor: '#e53935',
		color: 'white',
		marginBottom: 10,
	},
	estabelecimentoIcone: {
		border: 'solid 1px #abc',
		marginRight: 2,
		marginLeft: 2,
		marginTop: 16,
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}`,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	estabelecimentoError: {
		fontSize: 12,
		color: '#e53935',
	},
	containerSplit: {
		'& > div ': {
			borderRight: '1px solid #eee',
			marginTop: 32,

			'& div, span': {
				marginLeft: 8,
			},
		},
	},
}));

const initialState = {
	formaPagamento: 1,
	// Boleto
	valorBoleto: 0,
	dataVencimento: addDays(new Date(), 5),
	descricao: '',
	// Cartão
	valorCartao: 0,
	numeroCartao: '',
	titularCartao: '',
	vencimentoCartao: '',
	cvcCartao: '',
	parcelasCartao: '',
	// Pix
	valorPix: 0,
	// Cliente
	id: null,
	cpf: '',
	nome: '',
	email: '',
	dataNascimento: '',
	celular: '',
	// Endereco
	logradouro: '',
	numero: '',
	cep: '',
	cidade: '',
	estado: '',
	complemento: '',
	chargeProcessingFee: false,
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmDialog = memo(props => {
	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			open={props.open}
			TransitionComponent={Transition}
			keepMounted
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle id="alert-dialog-slide-title">{'Atenção!!!'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">{props.dialogContent}</DialogContentText>
			</DialogContent>
			<DialogActions style={{ textAlign: 'center' }}>
				<Button onClick={props.handleClose} color="primary">
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
});

const NovaVenda = props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { history } = useReactRouter();
	const { cpfDoCliente } = useParams();

	const estabelecimento = useSelector(state => state.auth.estabelecimento);
	const estabelecimentoAdmin = useSelector(state => state.admin.estabelecimento);
	const token = useSelector(state => state.auth.token);
	const canVendaOnline = useSelector(store =>
		Isset(() => store.system.params['21'].valor) ? store.system.params['21'].valor : null
	);

	const estabelecimentoId = Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : estabelecimento.id;

	const [sending, setSending] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [data, setData] = useState(initialState);
	const [clientes, setClientes] = useState([]);
	const [focusCartao, setFocusCartao] = useState('number');
	const [errors, setErrors] = useState({});
	const [open, setOpen] = useState(false);
	const [showModal, setModal] = useState({
		messageDialog: '',
		open: false,
	});
	const [cpfCnpjAux, setCpfCnpjAux] = useState(cpfDoCliente || '');
	const [cpfCnpjTypingTimeout, setCpfCnpjTypingTimeout] = useState(null);
	const [loadingCliente, setLoadingCliente] = useState(false);

	const [erroMessage, setErrorMessage] = useState('');
	const location = useLocation();

	// Validate each step form content
	const validate = step => {
		const e = {};

		if (['all', 0].includes(step)) {
			if (data.formaPagamento === 1) {
				if (!data.valorBoleto || data.valorBoleto < 5) {
					e.valorBoleto = true;
				}
				if (!data.dataVencimento) {
					e.dataVencimento = true;
				}
			} else if (data.formaPagamento === 3) {
				if (!data.valorCartao || data.valorCartao <= 0) {
					e.valorCartao = true;
				}
				if (!data.numeroCartao) {
					e.numeroCartao = true;
				}
				if (!data.titularCartao) {
					e.titularCartao = true;
				}
				if (!data.vencimentoCartao) {
					e.vencimentoCartao = true;
				}
				if (!data.cvcCartao) {
					e.cvcCartao = true;
				}
				if (!data.parcelasCartao) {
					e.parcelasCartao = true;
				}
			}
		}

		if (['all', 1].includes(step)) {
			if (!data.cpf) {
				e.cpf = true;
			}
			if (!data.nome) {
				e.nome = true;
			}
			if (!data.email) {
				e.email = true;
			}

			if (!data.logradouro) {
				e.logradouro = true;
			}
			if (!data.numero) {
				e.numero = true;
			}
			if (!data.cep) {
				e.cep = true;
			}
			if (!data.cidade) {
				e.cidade = true;
			}
			if (!data.estado) {
				e.estado = true;
			}
		}

		setErrors(e);

		return Object.keys(e).length > 0;
	};

	// Change current step, if valid
	const changeStep = step => {
		if (step === 0) {
			setActiveStep(0);
		}
		if (step === 1 && !validate(0)) {
			setActiveStep(1);
		}
		if (step === 2 && !validate(1)) {
			setActiveStep(2);
		}
	};

	// When cpf/cnpj is filled, gets and set client's data if it exists in DB
	useEffect(() => {
		if (cpfCnpjAux.length === 11 || cpfCnpjAux.length === 14) {
			const getCliente = async () => {
				setLoadingCliente(true);
				setOpen(true);

				setData({
					...data,
					cpf: cpfCnpjAux,
					id: null,
					nome: '',
					email: '',
					dataNascimento: '',
					celular: '',
					logradouro: '',
					numero: '',
					cep: '',
					cidade: '',
					estado: '',
					complemento: '',
				});

				try {
					const result = await axios.get(`${config.get('apiUrl')}clientes/por_documento/${cpfCnpjAux}`, {
						headers: { Authorization: `Bearer ${token}` },
						params: {
							filters: {
								estabelecimentoId: estabelecimentoId,
							},
						},
					});

					if (result.data.success && result.data.cliente) {
						const cliente = result.data.cliente;

						const checkContato =
							typeof cliente.clientes_contatos !== 'undefined' && cliente.clientes_contatos.length > 0;

						let celular;

						if (checkContato) {
							cliente.clientes_contatos.forEach(clienteData => {
								if (clienteData.tipo_contato.id === 2) {
									celular = clienteData.contato;
								}
							});
						}

						setData({
							...data,
							cpf: cpfCnpjAux,
							id: cliente.id,
							nome: cliente.nome,
							email: cliente.email,
							dataNascimento:
								cliente.data_nascimento !== null ? dbDateToDate(cliente.data_nascimento) : '',
							celular,
							logradouro: _.has(cliente, 'endereco.logradouro') ? cliente.endereco.logradouro : '',
							numero: _.has(cliente, 'endereco.numero') ? cliente.endereco.numero : '',
							cep: _.has(cliente, 'endereco.cep') ? Mask.OnlyNumber(cliente.endereco.cep) : '',
							cidade: _.has(cliente, 'endereco.cidade') ? cliente.endereco.cidade : '',
							estado: _.has(cliente, 'endereco.uf') ? cliente.endereco.uf : '',
							complemento: _.has(cliente, 'endereco.complemento') ? cliente.endereco.complemento : '',
						});
					}
				} catch (error) {
				} finally {
					setOpen(false);
					setLoadingCliente(false);
				}
			};

			getCliente();
		}
	}, [cpfCnpjAux]);

	// Autofill CEP
	useEffect(() => {
		if (data.cep && data.cep.length === 8) {
			const getEndereco = async () => {
				const result = await axios.get(`${config.get('apiUrl')}buscar_cep/${data.cep}`, {
					headers: { Authorization: `Bearer ${token}` },
				});

				if (result.data.success && result.data.endereco && !result.data.endereco.erro) {
					const endereco = result.data.endereco;
					setData({
						...data,
						cep: endereco.cep,
						logradouro: endereco.logradouro,
						cidade: endereco.localidade,
						estado: endereco.uf,
					});
				} else {
					setData({
						...data,
						cep: '',
						logradouro: '',
						cidade: '',
						estado: '',
					});
				}
			};

			getEndereco();
		}
	}, [data.cep]);

	// // AntiFraude - Fingerprint
	useEffect(() => {
		// //Anti-Fraude-------------------------------------------------------------------------------------
		// axios
		// 	.get(`${config.get('apiUrl')}estabelecimentos/antifraude/${estabelecimentoId}`, {
		// 		headers: { Authorization: `Bearer ${token}` },
		// 	})
		// 	.then(res => {
		// 		const script = document.createElement('script');
		// 		let fingerprint_antifraud = '';
		// 		if (res.data.fingerPrintId) {
		// 			fingerprint_antifraud = res.data.fingerPrintId;
		// 		}

		// 		if (fingerprint_antifraud === '') {
		// 			fingerprint_antifraud = 'fycd8xcpzhprqazd7dqk'; //Antifraud Onepay
		// 		}
		// 		script.innerHTML = `(function (a, b, c, d, e, f, g) {
		// 			a['CsdpObject'] = e; a[e] = a[e] || function () {
		// 			(a[e].q = a[e].q || []).push(arguments)
		// 			}, a[e].l = 1 * new Date(); f = b.createElement(c),
		// 			g = b.getElementsByTagName(c)[0]; f.async = 1; f.src = d; g.parentNode.insertBefore(f, g)
		// 			})(window, document, 'script', '//device.clearsale.com.br/p/fp.js', 'csdp');
		// 			csdp('app', '${fingerprint_antifraud}');
		// 			csdp('outputsessionid', 'sessionInputOP');`;
		// 		document.body.appendChild(script);
		// 	});
		// //Anti-Fraude-------------------------------------------------------------------------------------

		const getCliente = async () => {
			const response = await axios.get(`${config.get('apiUrl')}clientes/ultimas-vendas`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (response.data.success && response.data.clientes) {
				setClientes(response.data.clientes);
			}
		};

		getCliente();
	}, []);

	useEffect(() => {
		// Limpa a mensagem de erro quando a localização muda
		setErrorMessage('');
	}, [location]);

	const handleSubmit = async () => {
		setSending(true);
		if (!validate('all')) {
			let vendaData = {
				estabelecimentoId,
				tipoPagamentoId: data.formaPagamento,
				splits: [],
			};

			switch (data.formaPagamento) {
				case 1:
					vendaData = {
						...vendaData,
						valor: data.valorBoleto,
						dataVencimento: format(data.dataVencimento, 'YYYY-MM-DD'),
						descricao: data.descricao,
					};
					break;
				case 3:
					vendaData = {
						...vendaData,
						valor: data.valorCartao,
						parcelas: data.parcelasCartao,
						cartao: {
							titular: data.titularCartao,
							numero: data.numeroCartao,
							codigoSeguranca: data.cvcCartao,
							validade: format(parse(data.vencimentoCartao, 'MMYY', new Date()), 'MM/YYYY'),
						},
					};
					break;
				case 5:
					vendaData = {
						...vendaData,
						valor: data.valorPix,
					};
					break;
				default:
					break;
			}

			if (Isset(() => data.id) && data.id !== null) {
				vendaData.clienteId = data.id;
			} else {
				if (data.cpf.length === 11) {
					vendaData = {
						...vendaData,
						cliente: {
							nome: data.nome,
							cpf: data.cpf,
							dataNascimento: moment(data.dataNascimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
							email: data.email,
							celular: data.celular,
						},
						endereco: {
							logradouro: data.logradouro,
							numero: data.numero,
							cep: data.cep,
							cidade: data.cidade,
							estado: data.estado,
							complemento: data.complemento,
						},
					};
				} else {
					vendaData = {
						...vendaData,
						cliente: {
							nome: data.nome,
							cnpj: data.cpf, // VAI SER UM CNPJ
							dataNascimento: moment(data.dataNascimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
							email: data.email,
							celular: data.celular,
						},
						endereco: {
							logradouro: data.logradouro,
							numero: data.numero,
							cep: data.cep,
							cidade: data.cidade,
							estado: data.estado,
							complemento: data.complemento,
						},
					};
				}
			}
			//vendaData.sessionId = document.getElementById('sessionInputOP').value;
			try {
				const result = await axios.post(`${config.get('apiUrl')}vendas`, vendaData, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				if (result.data.success) {
					dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
					history.push('/vendas/detalhes/' + result.data.id);
				} else {
					dispatch(addMessage({ type: 'error', message: result.data.message }));
				}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response) {
						let errorType = 'default';
						if (
							/Your card was declined.*credit card was declined.*contact your bank/i.test(
								error.response.data.error
							)
						) {
							errorType = 'card_declined';
						}

						if (
							/Associate credit card to buyer - Sorry, *the card zero dollar* validation has failed./i.test(
								error.response.data.error
							)
						) {
							errorType = 'card_zero_dollar';
						}

						if (/Your *\"taxpayer_id\" value is incorrect*./i.test(error.response.data.error)) {
							errorType = 'taxpayer_id';
						}
						switch (errorType) {
							case 'card_declined':
								setErrorMessage(
									'Seu cartão foi recusado. Para obter informações sobre o motivo, entre em contato com seu banco ou fornecedor de cartão de crédito.'
								);
								break;
							case 'card_zero_dollar':
								setErrorMessage(
									'Não foi possível concluir a operação com este cartão. Por favor, revise as informações ou utilize outro cartão.'
								);
								break;
							case 'taxpayer_id':
								setErrorMessage(
									'Parâmetro(s) inválido(s). O valor do seu (CPF/CNPJ) está incorreto. Por favor, verifique os parâmetros da solicitação.'
								);
								break;
							default:
								setErrorMessage(
									'Falha ao realizar pagamento. Caso o erro persista, contate o suporte.'
								);
								break;
						}
					}
					setSending(false);
				}
			}
		}
	};

	const handleCpfCnpjChange = e => {
		const value = e.target.value.length <= 11 ? Mask.Cpf(e.target.value) : Mask.Cnpj(e.target.value);
		setData({ ...data, cpf: Mask.OnlyNumber(value) });

		if (cpfCnpjTypingTimeout) {
			clearTimeout(cpfCnpjTypingTimeout);
		}

		const timeout = setTimeout(() => {
			setCpfCnpjAux(Mask.OnlyNumber(value));
		}, 500);

		setCpfCnpjTypingTimeout(timeout);
	};

	return (
		<div className={classes.novaVenda}>
			<ZTitle title="Nova Venda" marginBottom={8} />
			<Paper className={classes.paper}>
				{cpfDoCliente && (
					<Backdrop className={classes.backdrop} open={open}>
						<CircularProgress color="inherit" />
					</Backdrop>
				)}

				<div className={classes.content}>
					{/* FORMA DE PAGAMENTO */}
					{activeStep === 0 && (
						<div>
							<Typography variant="h5" component="h6" className={classes.stepTitle}>
								Forma de pagamento
							</Typography>
							<Grid container justifyContent="center" spacing={1}>
								<Grid item xs={12} sm={4} md={4} lg={4}>
									<div
										className={clsx(classes.formaPagamento, {
											selected: data.formaPagamento === 1,
										})}
										onClick={() => setData({ ...data, formaPagamento: 1 })}
									>
										Boleto Bancário
									</div>
								</Grid>
								{canVendaOnline && (
									<>
										<Grid item xs={12} sm={4} md={4} lg={4}>
											<div
												className={clsx(classes.formaPagamento, {
													selected: data.formaPagamento === 3,
												})}
												onClick={() => setData({ ...data, formaPagamento: 3 })}
											>
												Cartão de Crédito
											</div>
										</Grid>

										<Grid item xs={12} sm={4} md={4} lg={4}>
											<div
												className={clsx(classes.formaPagamento, {
													selected: data.formaPagamento === 5,
												})}
												onClick={() => setData({ ...data, formaPagamento: 5 })}
											>
												Pix
											</div>
										</Grid>
									</>
								)}
							</Grid>

							{/* BOLETO */}
							{data.formaPagamento === 1 && (
								<Grid container justifyContent="center" spacing={2}>
									<Grid container item xs={12} justifyContent="center" spacing={2}>
										<Grid item xs={12} sm={4} md={4} lg={4}>
											<TextField
												className={classes.fullWidth}
												id="bankSlipValue"
												error={errors.valorBoleto}
												label="Valor"
												type="tel"
												placeholder="Valor mínimo: R$ 5,00"
												value={Mask.Real(data.valorBoleto)}
												onChange={e =>
													setData({
														...data,
														valorBoleto: Mask.OnlyNumber(e.target.value) / 100,
													})
												}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={4} lg={4}>
											<ZDatePicker
												textField
												id="bankSlipExpiryDate"
												label="Data de Vencimento"
												type="tel"
												erro={errors.dataVencimento}
												date={data.dataVencimento}
												minDate={new Date()}
												onChange={date => setData({ ...data, dataVencimento: date })}
											/>
										</Grid>
									</Grid>
									<Grid container item xs={12} justifyContent="center" spacing={2}>
										<Grid item xs={12} sm={12} md={8} lg={8}>
											<TextField
												className={classes.fullWidth}
												id="bankSlipDescription"
												label="Descrição"
												value={data.descricao}
												onChange={e => setData({ ...data, descricao: e.target.value })}
											/>
										</Grid>
									</Grid>
								</Grid>
							)}
							{/* PIX */}
							{data.formaPagamento === 5 && (
								<Grid container justifyContent="center" spacing={2}>
									<Grid container item xs={12} justifyContent="center" spacing={2}>
										<Grid item xs={12} sm={5} md={4} lg={4}>
											<TextField
												className={classes.fullWidth}
												id="pixValue"
												error={errors.valorPix}
												label="Valor"
												type="tel"
												placeholder="Valor mínimo: R$ 5,00"
												value={Mask.Real(data.valorPix)}
												onChange={e =>
													setData({
														...data,
														valorPix: Mask.OnlyNumber(e.target.value) / 100,
													})
												}
											/>
										</Grid>
									</Grid>
								</Grid>
							)}
							{/* Cartão */}
							{data.formaPagamento === 3 && (
								<Grid container justifyContent="center" spacing={2} style={{ marginTop: 30 }}>
									<Grid item xs={6} sm={4} md={4} lg={4} style={{ marginBottom: 35 }}>
										<TextField
											className={classes.fullWidth}
											id="creditCardValue"
											error={errors.valorCartao}
											label="Valor"
											type="tel"
											value={Mask.Real(data.valorCartao)}
											onChange={e =>
												setData({
													...data,
													valorCartao: Mask.OnlyNumber(e.target.value) / 100,
												})
											}
										/>
									</Grid>
									<Grid item xs={6} sm={4} md={4} lg={4} style={{ marginBottom: 35 }}>
										<FormControl className={classes.fullWidth} error={errors.parcelasCartao}>
											<InputLabel>Parcelas</InputLabel>
											<Select
												className={classes.fullWidth}
												id="creditCardInstallments"
												value={data.parcelasCartao}
												onChange={e => setData({ ...data, parcelasCartao: e.target.value })}
											>
												{data.valorCartao > 0 ? (
													[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((a, i) => (
														<MenuItem key={i} value={a}>{`${a}x de ${Formatter.Real(
															data.valorCartao / a
														)}`}</MenuItem>
													))
												) : (
													<MenuItem value="">Informe o Valor</MenuItem>
												)}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12} sm={10} md={6} lg={5}>
										<Cards
											number={data.numeroCartao}
											name={data.titularCartao}
											expiry={data.vencimentoCartao}
											cvc={data.cvcCartao}
											focused={focusCartao}
										/>
									</Grid>
									<Grid item md={6}>
										<Grid container justifyContent="center" spacing={2}>
											<Grid item xs={12} sm={5} md={6} lg={10}>
												<TextField
													className={classes.fullWidth}
													id="creditCardOwner"
													error={errors.titularCartao}
													label="Titular"
													value={data.titularCartao}
													onFocus={() => setFocusCartao('name')}
													onChange={e => setData({ ...data, titularCartao: e.target.value })}
												/>
											</Grid>
											<Grid item xs={12} sm={4} md={6} lg={10}>
												<TextField
													className={classes.fullWidth}
													id="creditCardNumber"
													error={errors.numeroCartao}
													label="Número do Cartão"
													value={Mask.Cartao(data.numeroCartao)}
													onFocus={() => setFocusCartao('number')}
													onChange={e =>
														setData({
															...data,
															numeroCartao: Mask.OnlyNumber(Mask.Cartao(e.target.value)),
														})
													}
												/>
											</Grid>

											<Grid item xs={6} sm={3} md={3} lg={5}>
												<TextField
													className={classes.fullWidth}
													id="creditCardExpiryDate"
													error={errors.vencimentoCartao}
													label="Vencimento"
													value={Mask.ValidadeCartao(data.vencimentoCartao)}
													onFocus={() => setFocusCartao('expiry')}
													onChange={e =>
														setData({
															...data,
															vencimentoCartao: Mask.OnlyNumber(
																Mask.ValidadeCartao(e.target.value)
															),
														})
													}
												/>
											</Grid>
											<Grid item xs={6} sm={3} md={4} lg={5}>
												<TextField
													className={classes.fullWidth}
													id="creditCardSecurityCode"
													error={errors.cvcCartao}
													label={'CVV'}
													value={data.cvcCartao}
													onFocus={() => setFocusCartao('cvc')}
													onBlur={() => setFocusCartao('number')}
													onChange={e =>
														setData({ ...data, cvcCartao: e.target.value.slice(0, 4) })
													}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							)}

							<Grid container justifyContent="flex-end" className={classes.stepActions}>
								<Grid item xs={6} md={3}>
									<Button className={classes.nextButton} fullWidth onClick={() => changeStep(1)}>
										Continuar
									</Button>
								</Grid>
							</Grid>
						</div>
					)}
					{/* Info cliente */}
					{activeStep === 1 && (
						<div>
							<Typography variant="h5" component="h6" className={classes.stepTitle}>
								Dados do cliente
							</Typography>

							<Grid container>
								<Grid item md={6} lg={4}>
									<Grid container item xs={12} justifyContent="center" style={{ marginBottom: 16 }}>
										<Typography variant="body2" component="h6">
											Últimos clientes
										</Typography>
									</Grid>

									<div className={classes.clientes}>
										{clientes.map(cliente => (
											<p
												key={cliente.id}
												style={{ color: '#777' }}
												onClick={() => {
													setCpfCnpjAux(cliente.clientes_documentos[0].documento);
													// inputRef.current.focus();
												}}
											>
												{cliente.nome} -{' '}
												{cliente.clientes_documentos[0].documento.length === 11
													? Mask.Cpf(cliente.clientes_documentos[0].documento)
													: Mask.Cnpj(cliente.clientes_documentos[0].documento)}
											</p>
										))}
									</div>
								</Grid>

								<Grid item md={6} lg={8}>
									<Grid container justifyContent="center" spacing={2}>
										<Grid item xs={12} sm={6} md={4} lg={5}>
											<TextField
												className={classes.fullWidth}
												id="clientCPFCNPJ"
												error={errors.cpf}
												label="CPF / CNPJ"
												value={data.cpf.length <= 11 ? Mask.Cpf(data.cpf) : Mask.Cnpj(data.cpf)}
												onChange={handleCpfCnpjChange}
											/>
										</Grid>
									</Grid>

									{loadingCliente ? (
										<ZLoader height={100} size={20} message="Carregando Cliente" />
									) : (
										<>
											{[11, 14].includes(cpfCnpjAux.length) && (
												<React.Fragment>
													<Grid container spacing={2} justifyContent="center">
														<Grid item xs={12} sm={11} md={8}>
															<Grid container spacing={2} justifyContent="center">
																<Grid item xs={12}>
																	<Typography
																		variant="body2"
																		component="h6"
																		className={classes.subHeaderTitle}
																	>
																		Cliente
																	</Typography>
																</Grid>

																<Grid item xs={12} sm={6} md={4} lg={6}>
																	<TextField
																		className={classes.fullWidth}
																		id="clientName"
																		error={errors.nome}
																		label="Nome"
																		value={data.nome}
																		onChange={e =>
																			setData({ ...data, nome: e.target.value })
																		}
																	/>
																</Grid>
																<Grid item xs={12} sm={6} md={3} lg={6}>
																	<TextField
																		className={classes.fullWidth}
																		id="clientEmail"
																		error={errors.email}
																		label="E-mail"
																		type="email"
																		value={data.email}
																		onChange={e =>
																			setData({ ...data, email: e.target.value })
																		}
																	/>
																</Grid>
																{cpfCnpjAux.length === 11 && (
																	<>
																		<Grid item xs={12} sm={6} md={2} lg={6}>
																			<TextField
																				className={classes.fullWidth}
																				id="clientCellphone"
																				error={errors.celular}
																				label="Celular"
																				type="tel"
																				value={Mask.Celular(data.celular)}
																				onChange={e =>
																					setData({
																						...data,
																						celular: Mask.OnlyNumber(
																							Mask.Celular(e.target.value)
																						),
																					})
																				}
																			/>
																		</Grid>
																		<Grid item xs={12} sm={6} md={3} lg={6}>
																			{/* <ZDatePicker
																			textField
																			label="Data de Nascimento"
																			erro={errors.dataVencimento}
																			date={data.dataNascimento}
																			maxDate={addYears(new Date(), -18)}
																			onChange={date => setData({ ...data, dataNascimento: date })}
																		/> */}
																			<TextField
																				className={classes.fullWidth}
																				id="clientBirthdate"
																				error={errors.dataNascimento}
																				label="Data de Nascimento"
																				type="tel"
																				value={Mask.Date(data.dataNascimento)}
																				onChange={e =>
																					setData({
																						...data,
																						dataNascimento: Mask.Date(
																							e.target.value
																						),
																					})
																				}
																			/>
																		</Grid>
																	</>
																)}
															</Grid>

															<Typography
																variant="body2"
																component="h6"
																style={{ marginTop: 32, marginBottom: 16 }}
															>
																Endereço
															</Typography>

															<Grid container justifyContent="center" spacing={2}>
																<Grid item xs={12} sm={3} lg={6}>
																	<TextField
																		className={classes.fullWidth}
																		id="adressZipCode"
																		error={errors.cep}
																		label="CEP"
																		type="tel"
																		value={Mask.Cep(data.cep)}
																		onChange={e =>
																			setData({
																				...data,
																				cep: Mask.OnlyNumber(
																					Mask.Cep(e.target.value)
																				),
																			})
																		}
																	/>
																</Grid>
																<Grid item xs={12} sm={4} lg={6}>
																	<TextField
																		className={classes.fullWidth}
																		id="adressZipCode"
																		error={errors.logradouro}
																		label="Logradouro"
																		value={data.logradouro}
																		onChange={e =>
																			setData({
																				...data,
																				logradouro: e.target.value,
																			})
																		}
																	/>
																</Grid>
																<Grid item xs={12} sm={2} lg={3}>
																	<TextField
																		className={classes.fullWidth}
																		id="adressNumber"
																		error={errors.numero}
																		label="Número"
																		type="tel"
																		value={data.numero}
																		onChange={e =>
																			setData({ ...data, numero: e.target.value })
																		}
																	/>
																</Grid>
																<Grid item xs={12} sm={3} lg={3}>
																	<TextField
																		className={classes.fullWidth}
																		id="addressAddtional"
																		label="Complemento"
																		value={data.complemento}
																		onChange={e =>
																			setData({
																				...data,
																				complemento: e.target.value,
																			})
																		}
																	/>
																</Grid>
																<Grid item xs={12} sm={3} lg={3}>
																	<TextField
																		className={classes.fullWidth}
																		id="adressCity"
																		error={errors.cidade}
																		label="Cidade"
																		value={data.cidade}
																		onChange={e =>
																			setData({ ...data, cidade: e.target.value })
																		}
																	/>
																</Grid>
																<Grid item xs={12} sm={2} lg={3}>
																	<TextField
																		className={classes.fullWidth}
																		id="adressState"
																		error={errors.estado}
																		label="Estado"
																		value={data.estado}
																		onChange={e =>
																			setData({ ...data, estado: e.target.value })
																		}
																	/>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</React.Fragment>
											)}
										</>
									)}
								</Grid>
							</Grid>

							<Grid
								container
								justifyContent="flex-end"
								spacing={2}
								direction="row"
								className={classes.stepActions}
							>
								<Grid item xs={12} sm={12} md={6} lg={3}>
									<Button className={classes.backButton} fullWidth onClick={() => changeStep(0)}>
										Voltar
									</Button>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={3}>
									<Button
										disabled={![11, 14].includes(cpfCnpjAux.length)}
										className={classes.nextButton}
										fullWidth
										onClick={() => changeStep(2)}
										style={{ color: '#fff' }}
									>
										Continuar
									</Button>
								</Grid>
							</Grid>
						</div>
					)}

					{/* Resumo da transação */}
					{activeStep === 2 && (
						<>
							<Typography variant="h5" component="h6" className={classes.stepTitle}>
								Resumo
							</Typography>
							<Grid container style={{ padding: 16 }}>
								<Grid item xs={12} sm={12} md={12}>
									<Grid container justifyContent="center">
										<Grid item xs={12}>
											<Typography
												variant="body2"
												component="h6"
												className={classes.subHeaderTitle}
											>
												Cliente
											</Typography>
											<Divider />
										</Grid>

										<Grid item xs={12} sm={4} md={4} lg={4}>
											<div className={classes.field}>
												<div className={classes.label}>Nome</div>
												<div>{data.nome}</div>
											</div>
										</Grid>
										<Grid item xs={12} sm={4} md={4} lg={4}>
											<div className={classes.field}>
												<div className={classes.label}>CPF / CNPJ</div>
												<div>
													{data.cpf.length <= 11 ? Mask.Cpf(data.cpf) : Mask.Cnpj(data.cpf)}
												</div>
											</div>
										</Grid>
										<Grid item xs={12} sm={4} md={4} lg={4}>
											<div className={classes.field}>
												<div className={classes.label}>E-mail</div>
												<div>{data.email}</div>
											</div>
										</Grid>
									</Grid>

									<Grid item xs={12}>
										<Typography
											variant="body2"
											component="h6"
											className={classes.subHeaderTitle}
											style={{ marginTop: 32 }}
										>
											Endereço
										</Typography>
										<Divider />
									</Grid>

									<Grid item xs={12} sm={4} md={4} lg={12}>
										<div className={classes.field}>
											<div className={classes.label}>Endereço</div>
											<div className={classes.containerEndereco}>
												<div>{`${data.logradouro}, ${data.numero} - `}</div>
												<div>{Mask.Cep(data.cep)}</div>
												<div>{`${data.cidade} - ${data.estado}`}</div>
												<div>{`${data.complemento}`}</div>
											</div>
										</div>
									</Grid>

									<Grid item xs={12}>
										<Typography
											variant="body2"
											component="h6"
											className={classes.subHeaderTitle}
											style={{ marginTop: 32 }}
										>
											Pagamento
										</Typography>
										<Divider />
									</Grid>

									{data.formaPagamento === 1 && (
										<React.Fragment>
											<Grid container direction="row">
												<Grid item xs={6} lg={3}>
													<div className={classes.field}>
														<div className={classes.label}>Valor</div>
														<div className={classes.valorTotal}>
															{Formatter.Real(data.valorBoleto)}
														</div>
													</div>
												</Grid>
												<Grid item xs={6} lg={3}>
													<div className={classes.field}>
														<div className={classes.label}>Data de Vencimento</div>
														<div>{format(data.dataVencimento, 'DD/MM/YYYY')}</div>
													</div>
												</Grid>
											</Grid>
										</React.Fragment>
									)}

									{data.formaPagamento === 5 && (
										<React.Fragment>
											<Grid container direction="row">
												<Grid item xs={6} lg={3}>
													<div className={classes.field}>
														<div className={classes.label}>Valor</div>
														<div className={classes.valorTotal}>
															{Formatter.Real(data.valorPix)}
														</div>
													</div>
												</Grid>
											</Grid>
										</React.Fragment>
									)}

									{data.formaPagamento === 3 && (
										<React.Fragment>
											<Grid container direction="row">
												<Grid item xs={6} lg={3}>
													<div className={classes.field}>
														<div
															className={classes.parcelas}
														>{`${data.parcelasCartao}x`}</div>
														<div className={classes.parcelasValor}>
															{Formatter.Real(data.valorCartao / data.parcelasCartao)}
														</div>
													</div>
												</Grid>
												<Grid item xs={6} lg={3}>
													<div className={classes.field}>
														<div className={classes.label}>Valor</div>
														<div className={classes.valorTotal}>
															{Formatter.Real(data.valorCartao)}
														</div>
													</div>
												</Grid>
											</Grid>
										</React.Fragment>
									)}

									{/*data.splits[0].cpfcnpj && (
										<>
											<Grid item xs={12}>
												<Typography
													variant="body2"
													component="h6"
													className={classes.subHeaderTitle}
													style={{ marginTop: 32 }}
												>
													Splits
												</Typography>
												<Divider />
											</Grid>

											<Grid container item md={12} xs={12}>
												<Grid container item md={12} xs={12}>
													{data.splits.map((split, key) => (
														<Grid
															key={key}
															item
															md={4}
															xs={3}
															className={classes.containerSplit}
														>
															<div>
																<div className={classes.label}>CPF/CNPJ</div>
																<span>
																	{split.cpfcnpj.length <= 11
																		? Mask.Cpf(split.cpfcnpj)
																		: Mask.Cnpj(split.cpfcnpj)}
																</span>

																<div className={classes.label} style={{ marginTop: 8 }}>
																	{split.tipoSplit === 1
																		? 'Valor'
																		: 'Percentual de split'}
																</div>
																<span className={classes.span}>
																	{split.tipoSplit === 1
																		? Mask.Real(split.valor)
																		: `${Mask.Porcentagem(split.valor)}%`}
																</span>
															</div>
														</Grid>
													))}
												</Grid>
											</Grid>
										</>
																	)*/}
								</Grid>
							</Grid>
							<Grid
								container
								spacing={2}
								direction="row"
								justifyContent="flex-end"
								className={classes.stepActions}
							>
								{sending ? (
									<ZLoader height={20} size={30} />
								) : (
									<React.Fragment>
										<Grid item xs={12} sm={12} md={8} lg={3}>
											<Button
												className={classes.backButton}
												fullWidth
												onClick={() => changeStep(1)}
											>
												Voltar
											</Button>
										</Grid>
										<Grid item xs={12} sm={12} md={8} lg={3}>
											<Button className={classes.nextButton} fullWidth onClick={handleSubmit}>
												Finalizar
											</Button>
										</Grid>
									</React.Fragment>
								)}
							</Grid>
							{erroMessage ? (
								<Grid item md={12} style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 10 }}>
									<Alert severity="error">
										<AlertTitle>Mensagem de erro</AlertTitle>
										{erroMessage}
									</Alert>
								</Grid>
							) : null}
						</>
					)}
				</div>
			</Paper>

			<ConfirmDialog
				open={showModal.open}
				dialogContent={showModal.messageDialog}
				handleClose={() => setModal({ open: false, messageDialog: '' })}
			/>
		</div>
	);
};

export default NovaVenda;
