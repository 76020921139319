/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid, Paper, Tooltip } from '@material-ui/core';
import { ZLoader, ZFiltros } from '../system';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';
import { Isset, Formatter } from '../../utils/';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import _ from 'lodash';
import { addMessage } from '../../store/alert';
import FileDownload from 'js-file-download';

import { FaFilter, FaFileExcel } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	export: {
		position: 'absolute',
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	date: {
		textAlign: 'center',
		fontWeight: 'bold',
		padding: 4,
		borderBottom: 'solid 1px #ddd',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: '#eee',
		},
	},
	tooltip: {
		textAlign: 'center',
		'& div': {
			fontSize: 12,
			'& span': {
				fontSize: 13,
				fontWeight: 'bold',
			},
		},
	},
}));

const RelatoriosVendasPorDia = props => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const { history } = useReactRouter();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};
	const isAdmin = [1, 7, 10].includes(usuario.grupo_id);

	const queryFilter = {
		mes: format(new Date(), 'MM'),
		ano: format(new Date(), 'YYYY'),
		estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
	};

	const [filtros, setFiltros] = useState(queryFilter);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	const filtrosChange = async data => {
		data.estabelecimentoId =
			Isset(() => data.estabelecimento) && Array.isArray(data.estabelecimento) ? [...data.estabelecimento] : [];

		// if (estabelecimentoId) {
		// 	data.estabelecimentoId.push(estabelecimentoId);
		// }

		setFiltros(data);
	};

	useEffect(() => {
		setFiltros({
			...filtros,
			estabelecimentoId,
		});
	}, [estabelecimentoId]);

	const getData = async () => {
		setLoading(true);
		setData([]);
		const params = {};

		if (filtros.estabelecimentoId) {
			params.estabelecimentoId = filtros.estabelecimentoId;
		}
		if (filtros.mes) {
			params.mes = filtros.mes;
		}
		if (filtros.ano) {
			params.ano = filtros.ano;
		}
		if (filtros.omni2) {
			params.omni2 = filtros.omni2;
		}

		const res = await axios.get(`${config.get('apiUrl')}relatorios/vendasPorDia`, {
			params,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (res.data.success) {
			const length = res.data.result.length > 30 ? 16 : 15;
			const result = _.chunk(res.data.result, length);
			setData(result);
		}
		setLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		getData();
	}, [filtros]);

	const exportXls = async () => {
		const params = {};

		if (filtros.estabelecimentoId) {
			params.estabelecimentoId = filtros.estabelecimentoId;
		}
		if (filtros.mes) {
			params.mes = filtros.mes;
		}
		if (filtros.ano) {
			params.ano = filtros.ano;
		}
		if (filtros.omni2) {
			params.omni2 = filtros.omni2;
		}

		const result = await axios.get(`${config.get('apiUrl')}export/relatorios/vendasPorDia`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params,
		});

		if (result.status !== 200 || !Isset(() => result.data)) {
			return dispatch(addMessage({ type: 'error', message: 'Erro ao exportar vendas.' }));
		}

		const { 'content-disposition': contentDisposition, 'content-type': contentType } = result.headers;
		let filename = 'vendasPorDia.xlsx';
		if (contentDisposition.indexOf('filename="') !== -1) {
			filename = contentDisposition.slice(
				contentDisposition.indexOf('filename="') + 10,
				contentDisposition.length - 1
			);
		}

		FileDownload(result.data, filename, contentType);
	};

	const rowClick = date => {
		history.push(`/vendas?startDate=${date}&endDate=${date}`);
	};

	return (
		<Grid className={classes.vendas}>
			<div className={classes.header}>Vendas Por Dia</div>

			<Paper
				style={{
					position: 'relative',
					marginBottom: 10,
					borderRadius: 20,
					padding: '5px 10px',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<FaFilter />
				<ZFiltros
					onChange={filtrosChange}
					mes={{
						defaultValue: filtros.mes,
					}}
					ano={{
						defaultValue: filtros.ano,
					}}
					omni2={
						![2, 3].includes(usuario.grupo_id)
							? {
									default: filtros.omni2,
									placeholder: 'Pesquisa por Estabelecimento',
							  }
							: false
					}
					// estabelecimento={{
					// 	default: filtros.estabelecimentoId,
					// 	multiple: true,
					// }}
				/>
				{[1, 2].includes(usuario.grupo_id) && <FaFileExcel className={classes.export} onClick={exportXls} />}
			</Paper>

			<Grid container justifyContent="center" spacing={3}>
				{loading ? (
					<ZLoader height={200} message="Carregando Vendas" />
				) : (
					<>
						{data.map((column, i) => (
							<Grid key={i} item xs={4}>
								<Paper style={{ padding: 15 }}>
									{column.map(dia => (
										<Tooltip
											key={dia.dia}
											title={
												<div className={classes.tooltip}>
													<div>
														Quantidade <span>{dia.quantidade}</span>
													</div>
													<div>
														Valor Total <span>{Formatter.Real(dia.valor)}</span>
													</div>
													{!usuario.estabelecimento.parent && isAdmin && (
														<>
															<div>
																Taxa <span>{Formatter.Real(dia.taxa)}</span>
															</div>
															<div>
																Split <span>{Formatter.Real(dia.split)}</span>
															</div>
															<div>
																Markup{' '}
																<span>{Formatter.Real(dia.markupCalculado)}</span>
															</div>
														</>
													)}
													{usuario.estabelecimento.parent && (
														<div>
															Taxa <span>{Formatter.Real(+dia.taxa + +dia.split)}</span>
														</div>
													)}
													<div>
														Valor Líquido <span>{Formatter.Real(dia.liquido)}</span>
													</div>
												</div>
											}
										>
											<Grid
												container
												key={dia.dia}
												className={classes.date}
												onClick={() => rowClick(dia.dia)}
											>
												<Grid item xs={6}>
													{format(dia.dia, 'DD/MM/YYYY')}
												</Grid>
												<Grid item xs={6}>
													{Formatter.Real(dia.valor)}
												</Grid>
											</Grid>
										</Tooltip>
									))}
								</Paper>
							</Grid>
						))}
					</>
				)}
			</Grid>
		</Grid>
	);
};

export default RelatoriosVendasPorDia;
