/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import {
	Grid,
	Table,
	Button,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Paper,
	Chip,
	Dialog,
	DialogContent,
	TablePagination,
} from '@material-ui/core';
import ChipAdq from '../system/ChipAdq';
// import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import config from 'react-global-configuration';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedEC } from '../../store/system';
import { useLocation } from 'react-router-dom';
import FileDownload from 'js-file-download';
import moment from 'moment';
import { Formatter, Isset } from '../../utils';
import { FaFilter } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import { addMessage } from '../../store/alert';
import { loginAsEC } from '../../store/auth';
import { ZModalConfirm, ZModalToken, ZLoader, ZFiltros, ZMenuOptions, ZTitle } from '../system';
import { useStyles } from './styles/indexStyle';
import { isMobile } from 'react-device-detect';

const getStatusLabel = status => {
	switch (+status) {
		case 2:
			return (
				<Chip
					size="small"
					label="Habilitado"
					style={{
						backgroundColor: '#43a047',
						color: 'white',
						fontSize: 10,
						textTransform: 'uppercase',
						fontWeight: 700,
					}}
				/>
			);
		case 3:
			return (
				<Chip
					size="small"
					label="Inativo"
					style={{
						backgroundColor: '#e53935',
						color: 'white',
						fontSize: 10,
						textTransform: 'uppercase',
						fontWeight: 700,
					}}
				/>
			);
		default:
			return (
				<Chip
					size="small"
					label="Aguardando Aprovação"
					style={{
						backgroundColor: '#e6c038',
						color: 'black',
						fontSize: 10,
						textTransform: 'uppercase',
						fontWeight: 700,
					}}
				/>
			);
	}
};

const EstabelecimentoRow = memo(props => {
	const { estabelecimento } = props;
	const { history } = useReactRouter();
	const dispatch = useDispatch();
	const usuario = useSelector(store => store.auth.usuario);

	return (
		<TableRow>
			<TableCell style={{ minWidth: 150 }} component="th" scope="row">
				{estabelecimento.nome_fantasia}
				<div style={{ fontSize: 10 }}>
					{`${estabelecimento.razao_social} - `}
					{estabelecimento.identificacao_fatura}
				</div>
			</TableCell>
			<TableCell style={{ minWidth: 150 }} align="center">
				{estabelecimento.documento && (
					<>
						{estabelecimento.documento.length <= 11
							? Formatter.Cpf(estabelecimento.documento)
							: Formatter.Cnpj(estabelecimento.documento)}
					</>
				)}
			</TableCell>
			<TableCell style={{ minWidth: 200 }} align="center">
				{estabelecimento.email}
			</TableCell>
			<TableCell style={{ minWidth: 100 }} align="center">
				{moment(estabelecimento.created, 'YYYY-MM-DD').format('DD/MM/YYYY')}
			</TableCell>
			{[1, 2, 7, 10].includes(usuario.grupo_id) && (
				<TableCell style={{ minWidth: 100 }} align="center">
					{estabelecimento.repMaster}
				</TableCell>
			)}

			<TableCell style={{ minWidth: 100 }} align="center">
				{getStatusLabel(estabelecimento.status_estabelecimento_id)}
			</TableCell>
			{[1, 7].includes(usuario.grupo_id) && (
				<TableCell style={{ minWidth: 100 }} align="center">
					<ChipAdq adq={estabelecimento.marketplaces} />
				</TableCell>
			)}

			<TableCell style={{ minWidth: 80 }} align="center">
				<ZMenuOptions
					data={[
						{
							title: 'Entrar Como Estabelecimento',
							visible: [1].includes(usuario.grupo_id),
							onClick: async () => {
								await dispatch(loginAsEC(estabelecimento.id));
								history.push('/');
								window.location.reload();
							},
						},
						{
							title: 'Visualizar Estabelecimento',
							visible: [5, 8].includes(usuario.grupo_id),
							onClick: () => {
								dispatch(
									setSelectedEC({
										id: estabelecimento.id,
										nome: estabelecimento.nome_fantasia,
									})
								);
								history.push('/estabelecimentos/visualizar/' + estabelecimento.id);
							},
						},
						{
							title: 'Editar Estabelecimento',
							visible: [1, 2, 3, 7, 10].includes(usuario.grupo_id),
							onClick: () => {
								dispatch(
									setSelectedEC({
										id: estabelecimento.id,
										nome: estabelecimento.nome_fantasia,
									})
								);
								history.push('/estabelecimentos/editar/' + estabelecimento.id);
							},
						},
						{
							title: 'Cadastrar Taxa',
							visible: [1, 2, 7, 10].includes(usuario.grupo_id),
							onClick: () => history.push(`estabelecimentos/${estabelecimento.id}/cobranca_recorrente`),
						},
						// {
						// 	title: 'Configurar Split',
						// 	visible: usuario.grupo_id <= 2,
						// 	onClick: () => {
						// 		dispatch(
						// 			setSelectedEC({
						// 				id: estabelecimento.id,
						// 				nome: estabelecimento.nome_fantasia,
						// 			})
						// 		);
						// 		history.push('/estabelecimentos/configurar-split/' + estabelecimento.id);
						// 	},
						// },
						{
							title: 'Configurações',
							visible: [1, 2, 7, 10].includes(usuario.grupo_id),
							onClick: () => {
								dispatch(
									setSelectedEC({
										id: estabelecimento.id,
										nome: estabelecimento.nome_fantasia,
									})
								);
								history.push('/estabelecimentos/configuracoes/' + estabelecimento.id);
							},
						},
						// {
						// 	title: 'Visualizar Taxas',
						// 	visible: usuario.grupo_id <= 2 || usuario.grupo_id === 7,
						// 	onClick: () => {
						// 		dispatch(
						// 			setSelectedEC({
						// 				id: estabelecimento.id,
						// 				nome: estabelecimento.nome_fantasia,
						// 			})
						// 		);
						// 		history.push('/estabelecimentos/taxas/' + estabelecimento.id);
						// 	},
						// },
						{
							title: 'Desabilitar',
							visible: [1, 7].includes(usuario.grupo_id),
							onClick: () => {
								props.onDesabilitarClick(estabelecimento.id);
							},
						},
					]}
				/>
			</TableCell>
		</TableRow>
	);
});

const Estabelecimentos = () => {
	const { history } = useReactRouter();
	const dispatch = useDispatch();
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const estabelecimentoAdmin = useSelector(store => store.admin.estabelecimento);
	const axiosCancelSource = axios.CancelToken.source();

	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const [estabelecimentos, setEstabelecimentos] = useState([]);
	const [showExportMessage, setShowExportMessage] = useState(false);
	const [estabelecimentoId, setEstablecimentoId] = useState(null);
	const [tokenEC, setTokenEC] = useState({
		tokenEstabelecimento: null,
		open: false,
	});

	const [desabilitarEC, setDesabilitarEC] = useState({
		ecId: null,
		open: false,
	});

	const [loading, setLoading] = useState(true);
	// const [page, setPage] = useState(1);
	// const [rowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);
	// const [hasMore, setHasMore] = useState(false);
	const [filtros, setFiltros] = useState({
		omni: '',
		parentId: Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : null,
		...filtersCache,
	});

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(100);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const getData = async () => {
		setLoading(true);
		// setPage(1);
		let res = await axios.get(`${config.get('apiUrl')}estabelecimentos/filhos`, {
			cancelToken: axiosCancelSource.token,
			headers: { Authorization: `Bearer ${token}` },
			params: {
				page,
				limit: rowsPerPage,
				filters: {
					...filtros,
				},
			},
		});

		if (res.data.success) {
			setEstabelecimentos(res.data.estabelecimentos);
			setTotalRows(res.data.quantidade);
			// setHasMore(res.data.quantidade > res.data.estabelecimentos.length);
		}
		setLoading(false);
	};
	useEffect(() => {
		getData();
		return async () => {
			axiosCancelSource.cancel('Transferencias Unmounted.');
		};
	}, [filtros, page, rowsPerPage]);

	useEffect(() => {
		setFiltros({ ...filtros, parentId: Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : null });
	}, [estabelecimentoAdmin]);

	// const getMoreData = async () => {
	// 	// setPage(page + 1);
	// 	setLoading(true);
	// 	let res = await axios.get(`${config.get('apiUrl')}estabelecimentos/filhos`, {
	// 		headers: { Authorization: `Bearer ${token}` },
	// 		params: {
	// 			page: page,
	// 			limit: rowsPerPage,
	// 			filters: {
	// 				...filtros,
	// 			},
	// 		},
	// 	});
	// 	if (res.data.success) {
	// 		const newData = [...estabelecimentos, ...res.data.estabelecimentos];
	// 		setEstabelecimentos(newData);
	// 		// setHasMore(totalRows > newData.length);
	// 	}
	// 	setLoading(false);
	// };

	const filtrosChange = data => {
		setPage(0);
		setFiltros({
			...filtros,
			...data,
		});
	};

	const exportXls = async () => {
		setShowExportMessage(true);
		const estabelecimentos = await axios.get(`${config.get('apiUrl')}export/estabelecimentos`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params: {
				filters: {
					...filtros,
				},
			},
		});

		if (estabelecimentos.status === 200) {
			const { 'content-disposition': contentDisposition, 'content-type': contentType } = estabelecimentos.headers;
			let filename = 'estabelecimentos.xlsx';
			if (contentDisposition.indexOf('filename="') !== -1) {
				filename = contentDisposition.slice(
					contentDisposition.indexOf('filename="') + 10,
					contentDisposition.length - 1
				);
			}

			FileDownload(estabelecimentos.data, filename, contentType);
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao exportar estabelecimentos.' }));
		}
		setShowExportMessage(false);
	};

	const handleDesabilitarEC = result => {
		const ecId = desabilitarEC.ecId;
		setDesabilitarEC({ ...desabilitarEC, open: false, ecId: null });

		if (result) {
			axios
				.delete(`${config.get('apiUrl')}estabelecimentos/${ecId}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Estabelecimento desabilitado com sucesso' }));
						getData();
					}
				});
		}
	};

	return (
		<Grid>
			<ZTitle
				title="Estabelecimentos"
				display="flex"
				textAlign="center"
				fontSize={isMobile ? '23px' : '50px'}
				button="add"
				handleOnClick={() => {
					if ([5, 8].includes(usuario.grupo_id)) {
						history.push('/estabelecimentos/pre-cadastro');
					} else {
						history.push('/estabelecimentos/adicionar');
					}
				}}
			/>
			<Paper
				style={{
					marginBottom: 10,
					borderRadius: 20,
					width: '100%',
				}}
			>
				<Grid container>
					<Grid item xs={12} md={8}>
						<FaFilter />
						<ZFiltros
							onChange={filtrosChange}
							omni={{
								default: filtros.omni,
								placeholder: 'Empresa',
							}}
							searchEstabelecimentoByCpfOrCNPJ={{
								value: filtros.searchEstabelecimentoByCpfOrCNPJ || '',
								placeholder: 'CPF/CNPJ',
							}}
							nomeComprovante={{
								value: filtros.nomeComprovante || '',
								placeholder: 'Nome do comprovante',
							}}
						/>
					</Grid>

					{[1, 2, 7, 10].includes(usuario.grupo_id) && (
						<>
							<Grid item xs={12} md={4}>
								<Button
									variant="contained"
									size="small"
									className={classes.btn}
									onClick={() => history.push('/estabelecimentos/desabilitados')}
								>
									Ver Desabilitados
								</Button>
								<FiDownload className={classes.export} onClick={exportXls} />
							</Grid>
						</>
					)}
				</Grid>
			</Paper>

			<Grid container>
				<Grid item xs={12}>
					<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 30px)' }}>
						{loading ? (
							<ZLoader height={200} message="Carregando Estabelecimentos" />
						) : (
							// <InfiniteScroll
							// 	dataLength={estabelecimentos.length}
							// 	next={getMoreData}
							// 	hasMore={hasMore}
							// 	loader={<h4 style={{ textAlign: 'center' }}>Carregando...</h4>}
							// >
							<>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell style={{ minWidth: 180 }} align="center">
												Nome
											</TableCell>
											<TableCell style={{ minWidth: 200 }} align="center">
												CPF / CNPJ
											</TableCell>
											<TableCell style={{ minWidth: 200 }} align="center">
												E-mail
											</TableCell>
											<TableCell style={{ minWidth: 200 }} align="center">
												Data Criação
											</TableCell>
											{[1, 2, 7, 10].includes(usuario.grupo_id) && (
												<TableCell style={{ minWidth: 200 }} align="center">
													Representante
												</TableCell>
											)}

											<TableCell style={{ minWidth: 130 }} align="center">
												Status
											</TableCell>
											{[1, 7].includes(usuario.grupo_id) && (
												<TableCell style={{ minWidth: 200 }} align="center">
													Adquirente
												</TableCell>
											)}
											<TableCell style={{ minWidth: 120 }} align="center">
												Opções
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{estabelecimentos.map((value, key) => (
											<EstabelecimentoRow
												key={`estab-${key}`}
												estabelecimento={value}
												onDesabilitarClick={ecId => {
													setDesabilitarEC({
														...desabilitarEC,
														ecId,
														open: true,
													});
												}}
											/>
										))}
									</TableBody>
								</Table>
								<TablePagination
									rowsPerPageOptions={[15, 30, 60, 100]}
									component="div"
									count={totalRows}
									labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
									labelRowsPerPage={'Resultados por página:'}
									rowsPerPage={rowsPerPage}
									page={page}
									onChangePage={handleChangePage}
									onChangeRowsPerPage={handleChangeRowsPerPage}
								/>
							</>
							// </InfiniteScroll>
						)}
					</Paper>
				</Grid>
			</Grid>

			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>

			<ZModalConfirm
				title={`Desabilitar Estabelecimento`}
				message={`Deseja desabilitar esse estabelecimento?`}
				open={desabilitarEC.open}
				onClose={handleDesabilitarEC}
			/>

			<ZModalToken
				title={`Token Estabelecimento`}
				open={tokenEC.open}
				value={[
					{ label: 'Estabelecimento', value: estabelecimentoId },
					{ label: 'Token', value: tokenEC.tokenEstabelecimento },
				]}
				handleClose={() => {
					setTokenEC({ open: false, tokenEstabelecimento: null });
					setEstablecimentoId(null);
				}}
			/>
		</Grid>
	);
};

export default Estabelecimentos;
