/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import config from 'react-global-configuration';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Fab, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ZLoader, ZMenuOptions, ZModalConfirm } from '../system';

const useStyles = makeStyles(theme => ({
	planosTaxas: {
		marginTop: 5,
	},
	planosTaxasPaper: {
		padding: 10,
		paddingTop: 40,
		position: 'relative',
	},
	btnAdd: {
		position: 'absolute',
		right: 10,
		top: 10,
	},
	headerTitle: {
		fontWeight: 'bold',
		fontSize: 15,
	},
	ptRow: {
		borderTop: 'solid 1px #abc',
	},
}));

const PlanoTaxa = memo(props => {
	const { marketplaceId, plano, setRemoverPt } = props;
	const classes = useStyles();
	const history = useHistory();

	return (
		<Grid item container alignItems="center" className={classes.ptRow}>
			<Grid item xs>
				{plano.titulo}
			</Grid>
			<Grid item xs>
				{plano.planosVendas.map(p => (
					<div key={p}>{p}</div>
				))}
			</Grid>
			<Grid item xs={1}>
				<ZMenuOptions
					data={[
						{
							title: 'Editar',
							onClick: () => {
								history.push(`/marketplace/${marketplaceId}/planos-taxas/${plano.id}`);
							},
						},
						{
							title: 'Remover',
							onClick: () => {
								setRemoverPt({ ptId: plano.id, open: true });
							},
						},
					]}
				/>
			</Grid>
		</Grid>
	);
});

export default memo(props => {
	const { marketplaceId } = props;

	const classes = useStyles();

	const token = useSelector(store => store.auth.token);
	const history = useHistory();

	const [loading, setLoading] = useState(true);
	const [planosTaxas, setPlanosTaxas] = useState([]);
	const [removerPt, setRemoverPt] = useState({
		ptId: null,
		open: false,
	});

	const getPlanosTaxas = async () => {
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}planos_taxas/porMarketplace/${marketplaceId}`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			setPlanosTaxas(res.data.planosTaxas);
		}
		setLoading(false);
	};

	useEffect(() => {
		getPlanosTaxas();
	}, []);

	const handleRemoverPt = async result => {
		const ptId = removerPt.ptId;

		if (result) {
			const res = await axios.delete(
				`${config.get('apiUrl')}planos_taxas/porMarketplace/${marketplaceId}/${ptId}`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				getPlanosTaxas();
			}
			setRemoverPt({ open: false, ptId: null });
		}
	};

	return (
		<div className={classes.planosTaxas}>
			<Paper className={classes.planosTaxasPaper}>
				<Fab
					className={classes.btnAdd}
					color="primary"
					onClick={() => history.push(`/marketplace/${marketplaceId}/planos-taxas/add`)}
					size="medium"
					aria-label="Add"
				>
					<AddIcon />
				</Fab>
				<Grid container spacing={2} justifyContent="center">
					{loading ? (
						<ZLoader height={200} />
					) : (
						<>
							<Grid item container className={classes.header}>
								<Grid item xs className={classes.headerTitle}>
									Título da Tabela de Markup
								</Grid>
								<Grid item xs className={classes.headerTitle}>
									Planos de Venda
								</Grid>
								<Grid item xs={1} className={classes.headerTitle}></Grid>
							</Grid>
							{planosTaxas.map(planoTaxa => (
								<PlanoTaxa
									key={planoTaxa.id}
									setRemoverPt={setRemoverPt}
									marketplaceId={marketplaceId}
									plano={planoTaxa}
								/>
							))}
							<ZModalConfirm
								title={`Remover Tabela de Markup`}
								message={`Deseja remover essa tabela de markup?`}
								open={removerPt.open}
								onClose={handleRemoverPt}
							/>
						</>
					)}
				</Grid>
			</Paper>
		</div>
	);
});
