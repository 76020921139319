import React, { useState, useEffect } from 'react';
import { Grid, Typography, Container, Paper } from '@material-ui/core';
import { ZSaveButton, ZCard, ZText, ZAlert } from '../system';
import { Mask, ValidatorForm, Isset } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import config from 'react-global-configuration';
import axios from 'axios';
import useReactRouter from 'use-react-router';
import moment from 'moment';

const AssinaturasEditar = props => {
	const { match } = useReactRouter();

	const dispatch = useDispatch();
	const validateFields = {
		cliente: {
			nome: {
				required: true,
			},
			email: {
				required: true,
				email: true,
			},
			dataNascimento: {
				required: true,
			},
			cpf: {
				required: true,
			},
		},
		endereco: {
			logradouro: {
				required: true,
			},
			numero: {
				required: true,
			},
			cep: {
				required: true,
			},
			cidade: {
				required: true,
			},
			estado: {
				required: true,
			},
		},
		cartao: {
			titular: {
				required: true,
			},
			validade: {
				required: true,
			},
			numero: {
				required: true,
			},
			codigoSeguranca: {
				required: true,
			},
		},
	};

	const message = useSelector(state => state.alert);
	const [text, setText] = useState('Carregando...');
	const [loading, setLoading] = useState(false);
	const [onError, setOnError] = useState(false);
	const [dataAssinatura, setAssinatura] = useState({
		planoId: '',
		cliente: {
			nome: '',
			email: '',
			dataNascimento: '',
			cpf: '',
		},
		endereco: {
			logradouro: '',
			numero: '',
			cep: '',
			cidade: '',
			estado: '',
		},
		cartao: {
			titular: '',
			validade: '',
			numero: '',
			codigoSeguranca: '',
			bandeira: '',
		},
	});

	const [data, setData] = useState({
		name: '',
		description: '',
		setup_amount: 0.0,
		amount: 0.0,
		grace_period: '',
		tolerance_period: '',
		frequency: '',
		zoop_plano_id: null,
		interval: 1,
	});

	const token = useSelector(store => store.auth.token);

	useEffect(() => {
		if (match.params.assinaturaId) {
			axios
				.get(config.get('apiUrl') + 'planos/assinatura/' + match.params.assinaturaId, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then(res => {
					if (res.data.success) {
						let data = res.data.data;

						setAssinatura({
							planoId: data.plano_id,
							cliente: {
								nome: data.cliente.nome,
								email: data.cliente.email,
								dataNascimento: data.cliente.data_nascimento,
								cpf: data.cliente.clientes_documentos[0].documento,
							},
							endereco: {
								logradouro: data.cliente.endereco.logradouro,
								numero: data.cliente.endereco.numero,
								cep: data.cliente.endereco.cep,
								cidade: data.cliente.endereco.cidade,
								estado: data.cliente.endereco.estado,
							},
							cartao: {
								titular: data.cliente.clientes_cartoes[0].nome_titular,
								validade: data.cliente.clientes_cartoes[0].ano_expiracao,
								numero: data.cliente.clientes_cartoes[0].ultimos_digitos.toString().padStart(20, '0'),
								bandeira: data.cliente.clientes_cartoes[0].bandeira,
								codigoSeguranca: '',
							},
						});
					} else {
						setText('Não encontramos essa assinatura.');
					}
				});
		}
	}, [match.params.assinaturaId, props.assinaturaId, props.planoId, token]);
	// console.log(dataAssinatura);
	const handleSubmitForm = async () => {
		setLoading(true);
		try {
			ValidatorForm(validateFields, dataAssinatura);
		} catch (erro) {
			// console.log(erro);
			dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário' }));
			setLoading(false);
			setOnError(true);
			return false;
		}

		let res = '';
		res = await axios.post(config.get('apiUrl') + 'planos/assinar', dataAssinatura, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.error) {
			dispatch(addMessage({ type: 'error', message: res.data.error.message }));
			setLoading(false);
		}

		if (Isset(() => res.data.success) && res.data.success === true) {
			dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
			setText('Assinatura realizada com sucesso!');
			setData({ ...data, name: '' });
		}
		setLoading(false);
	};

	const getEndereco = async value => {
		if (value.length === 8) {
			var res = await axios.get(config.get('apiUrl') + 'buscar_cep/' + value);
			if (res.data.success === false || res.data.endereco.erro) {
				setAssinatura({
					...dataAssinatura,
					endereco: {
						...dataAssinatura.endereco,
						cep: value,
						logradouro: '',
						cidade: '',
						estado: '',
						complemento: '',
					},
				});
				return false;
			}

			setAssinatura({
				...dataAssinatura,
				endereco: {
					...dataAssinatura.endereco,
					cep: Mask.OnlyNumber(res.data.endereco.cep),
					logradouro: res.data.endereco.logradouro,
					cidade: res.data.endereco.localidade,
					estado: res.data.endereco.uf,
					complemento: res.data.endereco.complemento,
				},
			});
		}
	};

	if (!dataAssinatura.cliente.nome) {
		return (
			<div>
				<Container maxWidth="md">
					<Grid
						container
						direction="row"
						justifyContent="center"
						alignItems="center"
						style={{ height: '100vh' }}
					>
						<Paper style={{ padding: 45 }}>
							<Grid item xs={12} align="center">
								<Typography variant="h4" gutterBottom>
									{text}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
				</Container>
			</div>
		);
	}

	if (dataAssinatura.cliente.nome) {
		let frequency = '';
		switch (data.frequency) {
			case 'monthly':
				frequency = 'mensalmente';
				break;
			case 'annually':
				frequency = 'anualmente';
				break;
			case 'weekly':
				frequency = 'semanalmente';
				break;
			case 'daily':
				frequency = 'diariamente';
				break;
			default:
				break;
		}

		return (
			<div>
				<Container maxWidth="md">
					<Grid container>
						{message.message && (
							<ZAlert type={message.type} message={message.message} time={moment().unix()} />
						)}
						<Grid item xs={12}>
							<Paper style={{ padding: 20, marginTop: 50, marginBottom: 50 }}>
								<Grid container spacing={2}>
									<Grid item xs={12} style={{ marginBottom: 50 }}>
										<Typography variant="h3" gutterBottom>
											{data.name}
										</Typography>
										<Typography variant="subtitle1" gutterBottom>
											{data.description}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<Typography variant="h5" gutterBottom>
													Cliente
												</Typography>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<ZText
													label="Nome"
													onError={onError}
													required={true}
													value={dataAssinatura.cliente.nome}
													onBlur={e =>
														setAssinatura({
															...dataAssinatura,
															cliente: {
																...dataAssinatura.cliente,
																nome: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<ZText
													label="E-mail"
													onError={onError}
													required={true}
													email
													value={dataAssinatura.cliente.email}
													onBlur={e =>
														setAssinatura({
															...dataAssinatura,
															cliente: {
																...dataAssinatura.cliente,
																email: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<ZText
													label="Data Nascimento"
													mask={'99/99/9999'}
													maskChar={null}
													onError={onError}
													required={true}
													value={moment(dataAssinatura.cliente.dataNascimento).format(
														'DD/MM/YYYY'
													)}
													onBlur={e => {
														let dataNascimento = moment(
															e.target.value,
															'DD/MM/YYYY'
														).format('YYYY-MM-DD');
														setAssinatura({
															...dataAssinatura,
															cliente: {
																...dataAssinatura.cliente,
																dataNascimento: dataNascimento,
															},
														});
													}}
												/>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<ZText
													label="CPF"
													mask={'999.999.999-99'}
													onError={onError}
													required={true}
													value={dataAssinatura.cliente.cpf}
													onBlur={e =>
														setAssinatura({
															...dataAssinatura,
															cliente: {
																...dataAssinatura.cliente,
																cpf: Mask.OnlyNumber(e.target.value),
															},
														})
													}
												/>
											</Grid>

											<Grid item xs={12} style={{ marginTop: 50 }}>
												<Typography variant="h5" gutterBottom>
													Endereço
												</Typography>
											</Grid>
											<Grid item xs={12} sm={6} md={2}>
												<ZText
													label="CEP"
													mask={'99999-999'}
													onError={onError}
													required={true}
													value={dataAssinatura.endereco.cep}
													onBlur={e => {
														let cep = Mask.OnlyNumber(e.target.value);
														setAssinatura({
															...dataAssinatura,
															endereco: { ...dataAssinatura.endereco, cep: cep },
														});
														getEndereco(cep);
													}}
												/>
											</Grid>
											<Grid item xs={12} sm={6} md={2}>
												<ZText
													label="Número"
													onError={onError}
													required={true}
													value={dataAssinatura.endereco.numero}
													onBlur={e =>
														setAssinatura({
															...dataAssinatura,
															endereco: {
																...dataAssinatura.endereco,
																numero: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<ZText
													label="Logradouro"
													onError={onError}
													required={true}
													value={dataAssinatura.endereco.logradouro}
													onBlur={e =>
														setAssinatura({
															...dataAssinatura,
															endereco: {
																...dataAssinatura.endereco,
																logradouro: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<ZText
													label="Cidade"
													onError={onError}
													// required={true}
													disabled
													value={dataAssinatura.endereco.cidade}
													onBlur={e =>
														setAssinatura({
															...dataAssinatura,
															endereco: {
																...dataAssinatura.endereco,
																cidade: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} sm={6} md={2}>
												<ZText
													label="Estado"
													onError={onError}
													disabled
													// required={true}
													value={dataAssinatura.endereco.estado}
													onBlur={e =>
														setAssinatura({
															...dataAssinatura,
															endereco: {
																...dataAssinatura.endereco,
																estado: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} style={{ marginTop: 50 }}>
												<Typography variant="h5" gutterBottom>
													Cartão
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<ZCard
													number={dataAssinatura.cartao.numero}
													name={dataAssinatura.cartao.titular}
													expiry={dataAssinatura.cartao.validade}
													cvc={dataAssinatura.cartao.codigoSeguranca}
													numberOnBlur={e =>
														setAssinatura({
															...dataAssinatura,
															cartao: {
																...dataAssinatura.cartao,
																numero: Mask.OnlyNumber(e.target.value),
															},
														})
													}
													nameOnBlur={e =>
														setAssinatura({
															...dataAssinatura,
															cartao: {
																...dataAssinatura.cartao,
																titular: e.target.value,
															},
														})
													}
													expiryOnBlur={e =>
														setAssinatura({
															...dataAssinatura,
															cartao: {
																...dataAssinatura.cartao,
																validade: e.target.value,
															},
														})
													}
													cvcOnBlur={e =>
														setAssinatura({
															...dataAssinatura,
															cartao: {
																...dataAssinatura.cartao,
																codigoSeguranca: e.target.value,
															},
														})
													}
													onError={onError}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={5} justifyContent="flex-end" style={{ marginTop: 40 }}>
										<Grid item md={9} align="center">
											<Typography variant="subtitle2" gutterBottom style={{ marginTop: 10 }}>
												Será debitado o valor de <b>{Mask.Real(data.amount * 100)}</b> do seu
												cartão de crédito <b>{frequency}</b>
											</Typography>
										</Grid>
										<Grid item xs={12} md={3}>
											<ZSaveButton
												onClick={() => handleSubmitForm()}
												color={'primary'}
												align="right"
												label={'Assinar'}
												required={true}
												loading={loading}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</div>
		);
	}
};

export default AssinaturasEditar;
