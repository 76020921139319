import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	paper: {
		padding: 10,
	},
	configUpdateBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		marginBottom: 15,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		'&:hover': {
			fontSize: 13,
		},
	},
	configResetBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		marginBottom: 15,
		fontWeight: 700,
		fontSize: 10,
		cursor: 'pointer',
		color: '#f44336',
		'&:hover': {
			fontSize: 11,
		},
	},
	passed: {
		textAlign: 'right',
		fontSize: 18,
	},
}));
