import React, { useState, useEffect } from 'react';
import { Grid, Paper, Container } from '@material-ui/core';
import { ZSaveButton, ZSelect, ZTitle, ZText } from '../system';
import { ValidatorForm, Isset } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import config from 'react-global-configuration';
import axios from 'axios';
import useReactRouter from 'use-react-router';

const PlanosAdicionar = props => {
	const { history, match } = useReactRouter();
	const dispatch = useDispatch();
	const validateFields = {
		name: { required: true },
		description: { required: true },
		amount: { required: true, min: 5 },
		setup_amount: { required: true },
		frequency: { required: true },
		payment_methods: { required: true },
		interval: { required: true },
	};

	const [loading, setLoading] = useState(false);
	const [onError, setOnError] = useState(false);

	const [data, setData] = useState({
		name: '',
		description: '',
		setup_amount: 0.0,
		amount: 0.0,
		grace_period: 0,
		tolerance_period: 0,
		frequency: 'monthly',
		interval: 1,
		logo: false,
		currency: 'BRL',
		payment_methods: 'credito',
	});

	const token = useSelector(store => store.auth.token);

	useEffect(() => {
		if (match.params.planoId) {
			axios
				.get(config.get('apiUrl') + 'planos/' + match.params.planoId, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then(res => {
					// console.log(`response`, res.data);

					if (res.data.success) {
						setData({
							name: res.data.plano.name,
							description: res.data.plano.description,
							setup_amount: res.data.plano.setup_amount,
							amount: res.data.plano.amount,
							grace_period: res.data.plano.grace_period,
							tolerance_period: res.data.plano.tolerance_period,
							frequency: res.data.plano.frequency,
							interval: res.data.plano.interval,
							logo: !!res.data.plano.logo,
							currency: res.data.plano.currency,
							payment_methods: res.data.plano.method,
						});
					}
				});
		}
	}, [match.params.planoId, token]);

	const handleSubmitForm = async () => {
		setLoading(true);

		try {
			ValidatorForm(validateFields, data);
		} catch (erro) {
			dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário' }));
			setLoading(false);
			setOnError(true);
			return false;
		}

		let res = '';
		if (match.params.planoId) {
			res = await axios.put(config.get('apiUrl') + 'planos/' + match.params.planoId, data, {
				headers: { Authorization: `Bearer ${token}` },
			});
		} else {
			res = await axios.post(config.get('apiUrl') + 'planos', data, {
				headers: { Authorization: `Bearer ${token}` },
			});
		}
		setLoading(false);

		if (res.data.error) {
			dispatch(addMessage({ type: 'error', message: 'Ocorreu um erro ao salvar o novo plano' }));
		}

		if (Isset(() => res.data.success) && res.data.success === true) {
			dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
			history.push('/planos');
		}
	};

	const handleChange = name => event => {
		const { value } = event.target;

		setData({
			...data,
			[name]: value,
		});
	};

	return (
		<Container maxWidth="lg">
			<Grid container>
				<ZTitle title={match.params.planoId ? 'Editar plano' : 'Adicionar plano'} />
				<Grid item xs={12}>
					<Paper style={{ padding: 20 }}>
						<Grid item xs={12} md={4} lg={3} style={{ marginBottom: 32 }}>
							<ZSelect
								options={[{ id: 'credito', name: 'Cartão de Crédito' }]}
								label="Meio de Pagamento"
								disabled={match.params.planoId}
								onError={onError}
								width="100%"
								value={data.payment_methods}
								onBlur={handleChange('payment_methods')}
							/>
						</Grid>
						<Grid container spacing={4}>
							<Grid item xs={12} md={4} lg={4}>
								<Grid container>
									<Grid item xs={12}>
										<ZText
											label="Nome"
											onError={onError}
											required={true}
											value={data.name}
											onBlur={handleChange('name')}
										/>
									</Grid>
									<Grid item xs={12}>
										<ZText
											label="Descrição"
											multiline
											rows="3"
											onError={onError}
											required={true}
											value={data.description}
											onBlur={handleChange('description')}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} md={8} lg={8}>
								<Grid container spacing={5}>
									<Grid item xs={12} sm={6} md={4}>
										<ZText
											fullWidth
											label="Valor"
											margin="normal"
											realMask
											onError={onError}
											min={validateFields.amount.min}
											placeholder="Valor do plano"
											value={data.amount / 100}
											onBlur={e => {
												setData({ ...data, amount: e.target.value * 100 });
											}}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4}>
										<ZText
											fullWidth
											label="Setup Valor"
											margin="normal"
											realMask
											placeholder="Valor do setup"
											value={data.setup_amount / 100}
											onBlur={e => setData({ ...data, setup_amount: e.target.value * 100 })}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4}>
										<ZText
											label="Período Gratuito"
											placeholder="Período em dias"
											onError={onError}
											required={true}
											value={data.grace_period}
											onlyNumber
											onBlur={handleChange('grace_period')}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4}>
										<ZSelect
											options={[
												{ id: 'daily', name: 'Diaria' },
												{ id: 'weekly', name: 'Semanal' },
												{ id: 'monthly', name: 'Mensal' },
												{ id: 'annually', name: 'Anual' },
											]}
											label="Frequência"
											onError={onError}
											width="100%"
											value={data.frequency}
											onBlur={handleChange('frequency')}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4}>
										<ZText
											label="Intervalo"
											onError={onError}
											required={true}
											value={data.interval}
											onlyNumber
											onBlur={handleChange('interval')}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4}>
										<ZText
											label="Período de tolerância"
											placeholder="Período em dias"
											onError={onError}
											required={true}
											onlyNumber
											value={data.tolerance_period}
											onBlur={handleChange('tolerance_period')}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12} md={12} style={{ marginTop: 32 }}></Grid>
					</Paper>
				</Grid>
				<Grid container justifyContent="flex-end" style={{ marginTop: 28 }}>
					<Grid item xs={12} md={3}>
						<ZSaveButton
							onClick={() => handleSubmitForm()}
							color={'primary'}
							align="right"
							label={'Salvar'}
							required={true}
							loading={loading}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};

export default PlanosAdicionar;
