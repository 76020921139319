import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import ZDatePicker from './ZDatePicker';

export default function ZModalDatePicker(props) {
	const [open, setOpen] = React.useState(props.open || false);
	// const [value, setValue] = React.useState(props.value);

	function handleChange(event) {
		props.handleOnChange(event);
	}

	function handleClose() {
		props.handleClose(false);
		setOpen(false);
	}

	React.useEffect(() => {
		setOpen(props.open);
		// setValue(props.value);
	}, [props]);

	return (
		<div>
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{props.description}</DialogContentText>
					<ZDatePicker modal date={props.date} minDate={props.minDate} onChange={handleChange} />
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancelar
					</Button>
					<Button onClick={() => props.handleOnSave()} color="primary">
						Salvar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
