import React, { useState, useEffect } from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core';
import { ZTitle, ZLoader, ZPagination, ZModalConfirm, ZMenuOptions, ZFiltros } from '../system';
import configuration from 'react-global-configuration';
import { Formatter } from '../../utils';
import axios from 'axios';
import config from 'react-global-configuration';
import { addMessage } from '../../store/alert';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { FaFilter } from 'react-icons/fa';

const Assinaturas = props => {
	const { history } = useReactRouter();
	const dispatch = useDispatch();
	const token = useSelector(store => store.auth.token);
	const estabelecimento = useSelector(store => store.admin.estabelecimento);

	const [loading, setLoading] = useState(true);
	const [assinaturas, setAssinaturas] = useState([]);
	const [removerAssinatura, setRemoverAssinatura] = useState({
		assianturaId: null,
		open: false,
	});
	const [suspenderAssinatura, setSuspenderAssinatura] = useState({
		assianturaId: null,
		open: false,
	});
	const [reativarAssinatura, setReativarAssinatura] = useState({
		assianturaId: null,
		open: false,
	});

	// const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};

	const [filtros, setFiltros] = useState({
		// estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
		omni: '',
		omni2: '',
		status: {
			id: 'status',
			label: 'Status',
			value: [],
			multiple: true,
			options: [
				{ id: 1, name: 'Aguardando' },
				{ id: 2, name: 'Cancelado' },
				{ id: 3, name: 'Pago' },
				{ id: 4, name: 'Suspenso' },
				{ id: 5, name: 'Atrasado' },
			],
		},
		created: {
			start: '',
			end: '',
			label: 'Data de criação',
		},
		proximaCobranca: {
			start: false,
			end: false,
			label: 'Próxima cobrança',
		},
		// planos: {
		// 	label: 'Planos',
		// 	value: '',
		// 	multiple: true,
		// 	options: planos
		// }
	});

	// Paginação

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const getAssinaturas = () => {
		setLoading(true);
		let url = `${config.get('apiUrl')}planos/assinaturas`;
		if (props.match.params.planoId) {
			url = url + `/${props.match.params.planoId}`;
		}

		var dataFiltros = {
			status: filtros.status.value,
			omni: filtros.omni,
			assinaturaId: filtros.omni2,
		};

		if (filtros.created.start) {
			dataFiltros.created = {
				start: moment(filtros.created.start).format('YYYY-MM-DD'),
				end: moment(filtros.created.end).format('YYYY-MM-DD'),
			};
		}

		if (filtros.proximaCobranca.start) {
			dataFiltros.proximaCobranca = {
				start: moment(filtros.proximaCobranca.start).format('YYYY-MM-DD'),
				end: moment(filtros.proximaCobranca.end).format('YYYY-MM-DD'),
			};
		}

		let params = {
			page,
			limit: rowsPerPage,
			...dataFiltros,
		};

		if (estabelecimento) {
			params.estabelecimento = estabelecimento.id;
		}

		axios
			.get(url, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params,
			})
			.then(res => res.data)
			.then(data => {
				setLoading(false);
				if (data.success) {
					setAssinaturas(data.assinaturas);
					setTotalRows(data.quantidade);
				}
			});
	};

	const selectChanged = async (id, data) => {
		setFiltros({
			...filtros,
			[id]: {
				...filtros[id],
				value: data.target.value,
			},
		});
	};

	const filtrosChange = async data => {
		// console.log(data);
		// if( data.proximaCobranca ){
		// 	setFiltros({
		// 		...filtros,
		// 		proximaCobranca: {
		// 			start: data.proximaCobranca.start,
		// 			end: data.proximaCobranca.end,
		// 			label: 'Próxima cobrança'
		// 		}
		// 	});
		// }

		// if(data.created){
		// 	setFiltros({
		// 		...filtros,
		// 		created: {
		// 			start: data.created.start,
		// 			end: data.created.end,
		// 			label: 'Data de criação'
		// 		}
		// 	});
		// }

		// if(data.omni){
		setPage(1);
		setFiltros({
			...filtros,
			...data,
		});

		// }
	};

	useEffect(() => {
		getAssinaturas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtros]);

	useEffect(() => {
		getAssinaturas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(getAssinaturas, [page, rowsPerPage]);

	const handleRemoveAssinatura = result => {
		const assinaturaId = removerAssinatura.assinaturaId;
		setRemoverAssinatura({ ...removerAssinatura, open: false, assinaturaId: null });

		if (result) {
			setLoading(true);
			axios
				.delete(`${config.get('apiUrl')}planos/assinatura/${assinaturaId}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Assinatura removida com sucesso' }));
						getAssinaturas();
					}
				});
		}
	};

	const handleReativarAssinatura = result => {
		const assinaturaId = reativarAssinatura.assinaturaId;
		setReativarAssinatura({ ...reativarAssinatura, open: false, assinaturaId: null });

		if (result) {
			setLoading(true);
			axios
				.post(
					`${config.get('apiUrl')}planos/assinatura/reativar`,
					{
						assinatura_id: assinaturaId,
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Assinatura reativada com sucesso' }));
						getAssinaturas();
					} else {
						dispatch(addMessage({ type: 'error', message: data.message }));
						setLoading(false);
					}
				});
		}
	};

	const handleSuspenderAssinatura = result => {
		const assinaturaId = suspenderAssinatura.assinaturaId;
		setSuspenderAssinatura({ ...suspenderAssinatura, open: false, assinaturaId: null });

		if (result) {
			setLoading(true);
			axios
				.post(
					`${config.get('apiUrl')}planos/assinatura/suspender/`,
					{
						assinatura_id: assinaturaId,
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Assinatura suspensa com sucesso' }));
						getAssinaturas();
					} else {
						dispatch(addMessage({ type: 'error', message: data.message }));
						setLoading(false);
					}
				});
		}
	};

	return (
		<Grid container>
			<ZTitle
				title="Assinantes"
				button={props.match.params.planoId ? 'add' : false}
				handleOnClick={() => history.push('/plano/' + props.match.params.planoId + '/assinar')}
			/>
			<Grid item xs={12}>
				<Paper
					style={{
						marginBottom: 10,
						borderRadius: 20,
						padding: '5px 10px',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<FaFilter />
					<ZFiltros
						onChange={filtrosChange}
						onSelectChange={selectChanged}
						// estabelecimento={{
						// 	default: filtros.estabelecimentoId,
						// 	multiple: true
						// }}
						omni2={{
							default: filtros.omni2,
							placeholder: 'id',
						}}
						omni={{
							default: filtros.omni,
							placeholder: 'Nome/email',
							// onBlur: filtrosChange
						}}
						select={[
							{
								// default: filtros.status.value,
								...filtros.status,
							},
							// {
							// 	default: filtros.planos.value,
							// 	...filtros.planos
							// }
						]}
						created={{ ...filtros.created }}
						proximaCobranca={{ ...filtros.proximaCobranca }}
						// tipoTransacao={{
						// 	default: filtros.tipoTransacao
						// }}
					/>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 45px)' }}>
					{loading ? (
						<Grid item xs={12}>
							<ZLoader height="200px" size={40} />
						</Grid>
					) : (
						<Table>
							<TableHead>
								<TableRow>
									<TableCell style={{ minWidth: 150 }}>ID</TableCell>
									<TableCell style={{ minWidth: 150 }}>Cliente</TableCell>
									<TableCell style={{ minWidth: 150 }}>Plano</TableCell>
									<TableCell align="right" style={{ minWidth: 150 }}>
										Preço
									</TableCell>
									{/* <TableCell align="right">Frequência</TableCell> */}
									{/* <TableCell align="right">Intervalo</TableCell> */}
									<TableCell align="right" style={{ minWidth: 150 }}>
										Criado
									</TableCell>
									<TableCell align="right" style={{ minWidth: 150 }}>
										Próxima cobrança
									</TableCell>
									<TableCell align="right" style={{ minWidth: 150 }}>
										Data de expiração
									</TableCell>
									<TableCell align="right" style={{ minWidth: 150 }}>
										Status
									</TableCell>
									<TableCell align="right"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<>
									{assinaturas.map((assinatura, key) => {
										let status = '';
										switch (assinatura.status_assinatura_id) {
											case 1:
												status = 'Aguardando';
												break;
											case 2:
												status = 'Cancelado';
												break;
											case 3:
												status = 'Pago';
												break;
											case 4:
												status = 'Suspenso';
												break;
											case 5:
												status = 'Atrasada';
												break;
											default:
												break;
										}

										// if( assinatura.ativo === 0 ){
										// 	status = 'Cancelada';
										// }

										var options = [
											{
												title: 'Detalhes',
												onClick: () => {
													history.push('/assinaturas/detalhes/' + assinatura.id);
												},
											},
											// {
											//     title : 'Editar',
											//     onClick: () => { history.push('/assinaturas/editar/'+assinatura.id)}
											// },
										];

										if (assinatura.status_assinatura_id === 4) {
											options.push({
												title: 'Reativar',
												onClick: () =>
													setReativarAssinatura({
														...suspenderAssinatura,
														assinaturaId: assinatura.id,
														open: true,
													}),
											});
										} else if (assinatura.status_assinatura_id !== 2 && assinatura.ativo) {
											options.push({
												title: 'Suspender',
												onClick: () =>
													setSuspenderAssinatura({
														...suspenderAssinatura,
														assinaturaId: assinatura.id,
														open: true,
													}),
											});
										}

										if (assinatura.ativo) {
											if (assinatura.payment_method !== 'boleto') {
												options.push({
													title: 'Alterar Cartão de Crédito',
													onClick: () =>
														history.push('/assinaturas/alterar_cartao/' + assinatura.id),
												});

												options.push({
													title: 'Atualizar Cartão (URL)',
													onClick: () =>
														window.open(
															configuration.get('baseName') +
																'/assinatura/atualizar/' +
																assinatura.subscription_id,
															'_blank'
														),
												});
											}

											options.push({
												title: 'Remover',
												onClick: () =>
													setRemoverAssinatura({
														...removerAssinatura,
														assinaturaId: assinatura.id,
														open: true,
													}),
											});
										}

										return (
											<TableRow hover key={key}>
												<TableCell>{assinatura.id}</TableCell>
												<TableCell component="th" scope="row">
													{assinatura.cliente.nome}
												</TableCell>
												<TableCell component="th" scope="row">
													{assinatura.plano.name}
												</TableCell>
												<TableCell align="right">
													{Formatter.Real(assinatura.amount / 100)}
												</TableCell>
												{/* <TableCell align="right">{frequency}</TableCell> */}
												{/* <TableCell align="right">{assinatura.interval}</TableCell> */}
												<TableCell align="right">
													{moment(assinatura.created).format('DD/MM/YYYY')}
												</TableCell>
												<TableCell align="right">
													{assinatura.due_date
														? moment(assinatura.due_date).format('DD/MM/YYYY')
														: 'N/A'}
												</TableCell>
												<TableCell align="right">
													{assinatura.expiration_date
														? moment(assinatura.expiration_date).format('DD/MM/YYYY')
														: 'N/A'}
												</TableCell>
												<TableCell align="right">{status}</TableCell>
												<TableCell align="right">
													<ZMenuOptions data={options} />
												</TableCell>
											</TableRow>
										);
									})}
								</>
							</TableBody>
							<ZPagination
								count={totalRows}
								rowsPerPage={rowsPerPage}
								page={page - 1}
								onChangePage={onChangePage}
								onChangeRowsPerPage={onChangeRowsPerPage}
							/>
						</Table>
					)}
				</Paper>
			</Grid>

			<ZModalConfirm
				title={`Remover assinatura`}
				message={`Deseja remover essa assinatura? A ação não pode ser desfeita.`}
				open={removerAssinatura.open}
				onClose={handleRemoveAssinatura}
			/>
			<ZModalConfirm
				title={`Suspender assinatura`}
				message={`Deseja suspender essa assinatura?`}
				open={suspenderAssinatura.open}
				onClose={handleSuspenderAssinatura}
			/>
			<ZModalConfirm
				title={`Reativar assinatura`}
				message={`Deseja reativar essa assinatura?`}
				open={reativarAssinatura.open}
				onClose={handleReativarAssinatura}
			/>
		</Grid>
	);
};

export default Assinaturas;
