/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import useReactRouter from 'use-react-router';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
	Grid,
	Paper,
	Dialog,
	DialogContent,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import FileDownload from 'js-file-download';
import queryString from 'query-string';
import LastExport from '../Export/LastExport';
import axios from 'axios';
import config from 'react-global-configuration';
import { Isset, Formatter } from '../../utils/';
import { ZEmpty, ZFiltros, ZLoader, ZPaginationDiv, ZTitle } from '../system';
import { FaFilter, FaTimes } from 'react-icons/fa';
import { addMessage } from '../../store/alert';
import { makeStyles } from '@material-ui/core/styles';
import { FiDownload } from 'react-icons/fi';

const useStyles = makeStyles(theme => ({
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
		textAlign: 'center',
		whiteSpace: 'nowrap',
	},
	valor: {
		fontSize: 16,
		fontWeight: 700,
	},
	qtdPagamentos: {
		fontSize: 28,
	},
	pagamentos: {
		width: '100%',
		flexGrow: 1,
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	filtros: {
		position: 'relative',
		borderRadius: 20,
		marginBottom: 30,
		border: `solid 1px ${theme.palette.primary.main}67`,
		padding: '5px 10px',
		display: 'flex',
		alignItems: 'center',
	},
	export: {
		opacity: 0.5,
		marginTop: 10,
		cursor: 'pointer',
		marginLeft: 20,
		color: '#464ae6',
		fontSize: 25,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 28,
			opacity: 1,
		},
	},
	exportHistory: {
		position: 'absolute',
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#49bb57',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 18,
	},
	tableDateRow: {
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		backgroundColor: `${theme.palette.primary.main}aa`,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	tableRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	truncate: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	hideOnXs: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	dataHoraXs: {
		[theme.breakpoints.down('xs')]: {
			fontSize: 10,
		},
	},
	paymentIcon: {
		fontSize: 20,
	},
	fixedFilter: {
		margin: 4,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		padding: '5px 10px',
		borderRadius: 30,
	},
	fixedFilterTitle: { fontWeight: 'bold', marginRight: 5 },
	fixedFilterValue: { fontSize: 11, marginRight: 10 },
	fixedFilterRemove: {
		cursor: 'pointer',
	},
	valorAprovado: {
		color: '#4caf50',
	},
	valorPendente: {
		color: '#ffc107',
	},
	valorCancelado: {
		color: '#f44336',
	},
}));

const PagamentoRow = memo(props => {
	// const classes = useStyles();

	const [pagamento] = useState(props.pagamento || {});
	// const { agruparTaxas } = props;

	if (!pagamento) {
		return null;
	}

	const sum =
		(+pagamento.authorization || 0) +
		(+pagamento.paid || 0) -
		(+pagamento.charged_back || 0) -
		(+pagamento.void || 0);

	return (
		<TableBody>
			<TableRow>
				<TableCell style={{ minWidth: 200, textAlign: 'center' }}>{pagamento.nome}</TableCell>
				<TableCell style={{ minWidth: 200, textAlign: 'center' }}>
					{pagamento.documento && pagamento.documento.length <= 11
						? Formatter.Cpf(pagamento.documento)
						: Formatter.Cnpj(pagamento.documento)}
				</TableCell>
				<TableCell style={{ minWidth: 200, textAlign: 'center' }}>
					{format(pagamento.date, 'DD/MM/YYYY', { locale: pt })}
				</TableCell>
				<TableCell
					style={
						pagamento.authorization > 0
							? { color: '#f44336', minWidth: 200, textAlign: 'center' }
							: { color: '#f44336', minWidth: 200, textAlign: 'center' }
					}
				>
					{Formatter.Real(pagamento.authorization)}
				</TableCell>
				<TableCell
					style={
						pagamento.paid > 0
							? { color: '#f44336', minWidth: 200, textAlign: 'center' }
							: { color: '#f44336', minWidth: 200, textAlign: 'center' }
					}
				>
					{Formatter.Real(pagamento.paid)}
				</TableCell>
				<TableCell
					style={
						pagamento.charged_back > 0
							? { color: '#f44336', minWidth: 200, textAlign: 'center' }
							: { color: '#f44336', minWidth: 200, textAlign: 'center' }
					}
				>
					{Formatter.Real(pagamento.charged_back)}
				</TableCell>
				<TableCell
					style={
						pagamento.void > 0
							? { color: '#f44336', minWidth: 250, textAlign: 'center' }
							: { color: '#f44336', minWidth: 250, textAlign: 'center' }
					}
				>
					{Formatter.Real(pagamento.void)}
				</TableCell>
				<TableCell
					style={
						sum > 0
							? { color: '#4caf50' }
							: sum < 0
							? { color: '#f44336' }
							: { color: '#f44336', minWidth: 200, textAlign: 'center' }
					}
				>
					{Formatter.Real(sum)}
				</TableCell>
			</TableRow>
		</TableBody>
	);
});

const PagamentosList = memo(props => {
	const classes = useStyles();

	const { agruparTaxas } = props;

	const [pagamentos, setPagamentos] = useState([]);
	const [loading, setLoading] = useState(true);
	// eslint-disable-next-line no-unused-vars
	const [totais, setTotais] = useState({
		geral: {
			quantidade: 0,
			total: 0,
			taxa: 0,
			split: 0,
			liquido: 0,
		},
	});

	//console.log(pagamentos);

	useEffect(() => {
		Isset(() => props.pagamentos) && setPagamentos(props.pagamentos);
		Isset(() => props.loading) && setLoading(props.loading);
		Isset(() => props.totais) && setTotais(props.totais);
		// console.log(totais)
	}, [props]);

	const usuario = useSelector(store => store.auth.usuario);
	const viewEC = [1, 2, 4, 5, 7].includes(usuario.grupo_id);

	if (!pagamentos) {
		return <ZEmpty height={200} text="Nenhuma Pagamento Encontrada." />;
	}

	return (
		<Paper style={{ padding: 15, overflowX: 'auto', width: '100%' }}>
			<Table>
				<Grid item xs={12}>
					{loading ? (
						<ZLoader height={200} />
					) : (
						<>
							<TableHead>
								<TableRow>
									<TableCell style={{ minWidth: 150, textAlign: 'center' }}>
										Estabelecimento
									</TableCell>
									<TableCell style={{ minWidth: 150, textAlign: 'center' }}>Documento</TableCell>
									<TableCell style={{ minWidth: 150, textAlign: 'center' }}>Data</TableCell>
									<TableCell style={{ minWidth: 150, textAlign: 'center' }}>Cartão</TableCell>
									<TableCell style={{ minWidth: 150, textAlign: 'center' }}>Boleto</TableCell>
									<TableCell style={{ minWidth: 150, textAlign: 'center' }}>Estorno</TableCell>
									<TableCell style={{ minWidth: 200, textAlign: 'center' }}>Cancelado</TableCell>
									<TableCell style={{ minWidth: 200, textAlign: 'left' }}>Total</TableCell>
								</TableRow>
							</TableHead>
							{pagamentos.map((pagamento, key) => {
								return (
									<React.Fragment key={key}>
										{pagamento.type === 'date' ? (
											<Grid container item className={classes.tableDateRow}>
												<Grid item xs={6} sm={agruparTaxas ? 5 : 4} style={{ paddingLeft: 20 }}>
													{format(pagamento.date, 'DD [de] MMMM [de] YYYY', {
														locale: pt,
													})}
												</Grid>
											</Grid>
										) : (
											<PagamentoRow
												pagamento={pagamento}
												rowClick={props.rowClick}
												viewEC={viewEC}
												agruparTaxas={agruparTaxas}
											/>
										)}
									</React.Fragment>
								);
							})}
						</>
					)}
				</Grid>
			</Table>
		</Paper>
	);
});

const FixedFilter = props => {
	const classes = useStyles();
	return (
		<div className={classes.fixedFilter}>
			<span className={classes.fixedFilterTitle}>{props.title}</span>
			<span className={classes.fixedFilterValue}>{props.value}</span>
			<span className={classes.fixedFilterRemove} onClick={props.onClick}>
				<FaTimes />
			</span>
		</div>
	);
};

const Conciliacao = props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const query = queryString.parse(useLocation().search);
	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');
	const axiosCancelSource = axios.CancelToken.source();

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const { history } = useReactRouter();
	const usuario = useSelector(store => store.auth.usuario);
	const token = useSelector(store => store.auth.token);
	// const localFilters = useSelector(store => store.system.filters[location.pathname] || {});
	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};
	const agruparTaxas = useSelector(store =>
		Isset(() => store.system.params['1'].valor) ? store.system.params['1'].valor : null
	);

	const queryFilter = {
		startDate: new Date(),
		endDate: new Date(),
		data_pagamento: { de: new Date(), ate: new Date() },
		estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
		status: [],
		omni: '',
		omni2: '',
		...filtersCache,
	};

	const [data, setData] = useState({
		recebiveis: [],
	});
	const [loading, setLoading] = useState(true);
	const [showExportMessage, setShowExportMessage] = useState(false);
	const [showLastExport, setShowLastExport] = useState(false);

	const [totais, setTotais] = useState({
		geral: {
			quantidade: 0,
			quantidadePedidos: 0,
			totalAprovado: 0,
			totalFalhado: 0,
			totalCancelado: 0,
			totalEstornado: 0,
			totalPendente: 0,
			taxa: 0,
			split: 0,
			liquido: 0,
			markup: 0,
		},
	});

	// Paginação

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(200);
	const [totalRows, setTotalRows] = useState(0);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const getData = async () => {
		setData({ recebiveis: [] });
		setTotais({
			geral: {
				quantidade: 0,
				quantidadePedidos: 0,
				totalAprovado: 0,
				totalFalhado: 0,
				totalCancelado: 0,
				totalEstornado: 0,
				totalPendente: 0,
				taxa: 0,
				split: 0,
				liquido: 0,
				markup: 0,
			},
		});
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}conciliacao`, {
			cancelToken: axiosCancelSource.token,
			headers: { Authorization: `Bearer ${token}` },
			params: {
				page,
				limit: rowsPerPage,
				estabelecimentoId,
				...filtros,
			},
		});
		if (res.data.success) {
			const compare = (a, b) => {
				if (a.date > b.date) {
					return -1;
				}
				if (a.date < b.date) {
					return 1;
				}
				return 0;
			};

			setTotais({ geral: res.data.totaisGeral });
			setData({ recebiveis: res.data.recebiveis.sort(compare) });
			setTotalRows(res.data.quantidade);
		}
		setLoading(false);
	};

	const [filtros, setFiltros] = useState({
		...queryFilter,
		pos: query.pos || '',
		omni2: Isset(() => query.estabelecimentoNome) ? query.estabelecimentoNome : queryFilter.omni2,
		startDate: Isset(() => query.startDate) ? query.startDate : queryFilter.startDate,
		endDate: Isset(() => query.endDate) ? query.endDate : queryFilter.endDate,
		data_pagamento: {
			de: Isset(() => query.startDate) ? query.startDate : queryFilter.data_pagamento.de,
			ate: Isset(() => query.endDate) ? query.endDate : queryFilter.data_pagamento.ate,
		},
	});

	useEffect(() => {
		getData();
		// dispatch(setFilters(location.pathname, filtros));
		// eslint-disable-next-line react-hooks/exhaustive-deps
		return async () => {
			axiosCancelSource.cancel('Pagamentos Unmounted.');
		};
	}, [filtros, page, rowsPerPage]);

	const filtrosChange = async data => {
		setPage(1);
		data.startDate = data.date.start;
		data.endDate = data.date.end;

		data.data_pagamento = {
			de: data.date.start,
			ate: data.date.end,
		};

		data.estabelecimentoId = [estabelecimentoId];

		if (Array.isArray(data.estabelecimento)) {
			data.estabelecimentoId = [estabelecimentoId, ...data.estabelecimento];
		}

		data.status = data.statusPagamento;
		data.pos = query.pos || '';

		if (Isset(() => query.estabelecimento) && query.estabelecimento !== '') {
			data.estabelecimentoId = [query.estabelecimento];
		}

		setFiltros(data);
	};

	useEffect(() => {
		setFiltros({
			...filtros,
			estabelecimentoId: Isset(() => query.estabelecimento) ? [query.estabelecimento] : [estabelecimentoId],
		});
	}, [estabelecimentoId]);

	const exportXls = async () => {
		setShowExportMessage(true);
		const pagamentos = await axios.get(`${config.get('apiUrl')}export/conciliacao`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params: {
				rows: totalRows,
				estabelecimentoId,
				...filtros,
			},
		});

		if (pagamentos.status === 200) {
			const { 'content-disposition': contentDisposition, 'content-type': contentType } = pagamentos.headers;
			let filename = 'pagamentos.xlsx';
			if (contentDisposition.indexOf('filename="') !== -1) {
				filename = contentDisposition.slice(
					contentDisposition.indexOf('filename="') + 10,
					contentDisposition.length - 1
				);
			}

			FileDownload(pagamentos.data, filename, contentType);
		} else if (pagamentos.status === 201) {
			dispatch(addMessage({ type: 'warning', message: 'Sua nova exportação está sendo processada.' }));
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao exportar pagamentos.' }));
		}
		setShowExportMessage(false);
	};

	const exportXlsClick = async () => {
		if (totalRows > 20000) {
			setShowLastExport(true);
		} else {
			await exportXls();
		}
	};

	const newExport = async () => {
		await exportXls();
	};

	const rowClick = pedidoId => {
		history.push(`/vendas/detalhes/${pedidoId}`);
	};

	const hideExport = [7, 5];

	return (
		<Grid>
			<ZTitle title="Recebíveis" display="flex" textAlign="center" fontSize="50px" />
			<Paper style={{ marginBottom: 10, padding: '5px 10px', width: '100%' }}>
				<Grid container alignItems="center" justifyContent="center">
					<Grid item xs={12} md={8} lg={8}>
						<FaFilter />

						<ZFiltros
							onChange={filtrosChange}
							dateRange={{
								default: 'custom',
								startDate: filtros.startDate,
								endDate: filtros.endDate,
							}}
							statusPagamento={{
								default: filtros.status,
								multiple: true,
							}}
							omni={
								![2, 3].includes(usuario.grupo_id)
									? {
											default: filtros.omni,
											placeholder: 'Pesquisa por CPF/CNPJ',
									  }
									: false
							}
							omni2={
								![2, 3].includes(usuario.grupo_id)
									? {
											default: filtros.omni2,
											placeholder: 'Pesquisa por Estabelecimento',
									  }
									: false
							}
						/>
					</Grid>

					<Grid item xs={2} md={2} lg={2}>
						{!hideExport.includes(usuario.grupo_id) && (
							<FiDownload className={classes.export} onClick={exportXlsClick} />
						)}
					</Grid>

					{/*{!hideExport.includes(usuario.grupo_id) && (
						<FaHistory className={classes.exportHistory} onClick={() => setShowLastExport(true)} />
					)} */}
					{query.pos && (
						<FixedFilter
							title="POS"
							value={query.pos}
							onClick={async () => {
								delete query.pos;
								await setFiltros({ ...filtros, pos: '' });
								history.push(`/pagamentos?${queryString.stringify(query)}`);
							}}
						/>
					)}
					{query.estabelecimento && (
						<FixedFilter
							title="Estabelecimento"
							value={query.estabelecimentoNome}
							onClick={async () => {
								delete query.estabelecimento;
								await setFiltros({
									...filtros,
									estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
								});
								history.push(`/pagamentos?${queryString.stringify(query)}`);
							}}
						/>
					)}
				</Grid>
			</Paper>

			{/* {usuario.grupo_id !== 7 && (
				<Paper style={{ padding: 15, marginBottom: 10 }}>
					<Grid container spacing={3}>
						<Grid item xs={6} sm={3} md={3}>
							<div className={classes.label}>
								<div className={clsx(classes.valor, classes.qtdPagamentos)}>
									{Formatter.Valor(
										Isset(() => totais.geral.quantidade) ? totais.geral.quantidade : 0,
										0,
										',',
										'.'
									)}
								</div>
								Pagamentos
							</div>
						</Grid>
						<Grid item xs={6} sm={3} md={3}>
							<div className={classes.label}>
								<div className={clsx(classes.valor, classes.qtdPagamentos)}>
									{Formatter.Valor(
										Isset(() => totais.geral.quantidadePedidos)
											? totais.geral.quantidadePedidos
											: 0,
										0,
										',',
										'.'
									)}
								</div>
								Vendas
							</div>
						</Grid>
						<Grid item sm={3} md={3} className={classes.hideOnXs} style={{ textAlign: 'right' }}>
							<div className={classes.label}>
								{`Aprovado `}
								<span className={clsx(classes.valor, classes.valorAprovado)}>
									{Formatter.Real(Isset(() => totais.geral.valorBruto) ? totais.geral.valorBruto : 0)}
								</span>
							</div>
						</Grid>
						<Grid item sm={3} md={3} className={classes.hideOnXs} style={{ textAlign: 'right' }}>
							<div className={classes.label}>
								{`Taxa `}
								<span className={classes.valor}>{Formatter.Real(+totais.geral.valorTaxa)}</span>
							</div>
							<div className={classes.label}>
								{`Líquido `}
								<span className={classes.valor}>
									{Formatter.Real(
										Isset(() => totais.geral.valorLiquido) ? totais.geral.valorLiquido : 0
									)}
								</span>
							</div>
							{usuario.grupo_id === 1 && (
								<>
									<div className={classes.label}>
										{`Markup `}
										<span className={classes.valor}>
											{Formatter.Real(
												Isset(() => totais.geral.valorMarkup) ? totais.geral.valorMarkup : 0
											)}
										</span>
									</div>
								</>
							)}
						</Grid>
					</Grid>
				</Paper>
			)} */}

			<PagamentosList
				pagamentos={data.recebiveis}
				loading={loading}
				totais={totais}
				rowClick={rowClick}
				agruparTaxas={agruparTaxas}
			/>

			<ZPaginationDiv
				count={totalRows}
				rowsPerPage={rowsPerPage}
				page={page - 1}
				onChangePage={onChangePage}
				onChangeRowsPerPage={onChangeRowsPerPage}
				rowsPerPageOptions={[50, 100, 200]}
			/>

			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>

			{showLastExport && (
				<LastExport
					show={showLastExport}
					onClose={() => setShowLastExport(false)}
					tipo={'pagamentos'}
					newExport={newExport}
				/>
			)}
		</Grid>
	);
};

export default Conciliacao;
