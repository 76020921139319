/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { addMessage } from '../../store/alert';
import { bindActionCreators } from 'redux';
import { InputAdornment, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { Mask } from '../../utils';
const useStyles2 = makeStyles(theme => ({
	margin: {
		margin: theme.spacing(1),
	},
}));
const ZInputTaxa = props => {
	const { readOnly, money } = props;
	const [value, setValue] = useState(Mask.Porcentagem(props.value));
	const [isTyping, setIsTyping] = useState(null);
	const classes = useStyles2();

	const onChange = e => {
		const value = e.target.value;
		let valor = Mask.OnlyNumber(e.target.value) / 100;

		if (!money && valor > 100) {
			valor = 100;
		}
		setValue(Mask.Porcentagem(valor));

		if (money) {
			if (isTyping) {
				clearTimeout(isTyping);
			}

			const timeout = setTimeout(() => {
				if (props.onChange) {
					props.onChange({ target: { value } });
				}
			}, 500);

			setIsTyping(timeout);
		} else {
			if (props.onChange) {
				props.onChange(e);
			}
		}
	};

	if (readOnly) {
		if (money) {
			return <div className={classes.taxaFieldReadOnly}>{`R$ ${value}`}</div>;
		}
		return <div className={classes.taxaFieldReadOnly}>{`${value} %`}</div>;
	}

	return (
		<TextField
			{...props}
			value={value}
			fullWidth
			onChange={onChange}
			InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
		/>
	);
};

const mapDispatchToProps = dispatch => bindActionCreators({ addMessage }, dispatch);

export default connect(null, mapDispatchToProps)(ZInputTaxa);
