/* eslint-disable react-hooks/exhaustive-deps */
import { addMessage } from '../../store/alert';
import { FaTimes } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import { Mask, Formatter, Isset } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { addDays } from 'date-fns';
import axios from 'axios';
import config from 'react-global-configuration';
import { Dialog, DialogContent, Grid, Paper, TextField } from '@material-ui/core';
import FileDownload from 'js-file-download';
import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { ZDateRangePicker, ZLoader, ZPaginationDiv, ZTable } from '../system';
import { Select, MenuItem } from '@material-ui/core';
import { ZTitle } from '../system';
import { FiDownload } from 'react-icons/fi';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
	header: {
		display: 'flex',
		justifyContent: 'center',
	},
	export: {
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 25,
		marginLeft: 10,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 28,
			opacity: 1,
		},
	},
	mesReferenciaAtual: {
		fontWeight: 'bold',
		fontSize: 16,
		cursor: 'pointer',
	},
	omniField: {
		'& .MuiInput-input': {
			fontSize: 14,
			padding: 0,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -6,
			transform: 'scaleX(1)',
		},
		'& .MuiInput-underline:not(.Mui-focused):after': {
			borderBottom: 'solid 1px #abc',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
			bottom: -6,
		},
	},
	filtroOmniHasText: {
		backgroundColor: `${theme.palette.primary.main}`,
		color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		'& .MuiInput-input': {
			color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		},
	},
	filtroText: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		padding: '5px 10px',
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 30,
		marginLeft: 10,
		fontWeight: 700,
		fontSize: 12,
		color: theme.palette.primary.main,
	},
	clearBtn: {
		marginLeft: 6,
	},
	filtroSelect: {
		fontWeight: 700,
		fontSize: 12,
		'&:before': {
			borderBottom: 'none !important',
		},
		'&:after': {
			borderBottom: 'none !important',
		},
		'& .MuiSelect-select': {
			padding: '2px 10px !important',
			marginRight: 10,
		},
	},
	menuItem: {
		flexDirection: 'column',
	},
	item: {
		margin: 5,
	},
}));

const RepresentantesComissoes = props => {
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);

	const dispatch = useDispatch();
	const [representantes, setRepresentantes] = useState([]);
	const [loading, setLoading] = useState(true);
	const [dataReferencia, setDataReferencia] = useState({
		start: addDays(new Date(), -8),
		end: addDays(new Date(), -1),
		label: 'Clique para selecionar a data',
	});
	const [omni, setOmni] = useState('');
	const [tipoRepresentante, setTipoRepresentante] = useState('');
	const [omniTypingTimeout, setOmniTypingTimeout] = useState(null);
	const [showExportMessage, setShowExportMessage] = useState(false);

	// Paginação

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const getRepresentantes = async () => {
		setLoading(true);
		const start = format(dataReferencia.start, 'YYYY-MM-DD');
		const end = format(dataReferencia.end, 'YYYY-MM-DD');
		const res = await axios.get(
			`${config.get(
				'apiUrl'
			)}representantes/comissoes?date_range[gte]=${start}&date_range[lt]=${end}&omni=${omni}&limit=${rowsPerPage}&page=${page}`,
			{
				params: { tipoRepresentante },
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			setRepresentantes(res.data.result);
			setTotalRows(res.data.totalRows);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (!omniTypingTimeout) {
			getRepresentantes();
		}
	}, [dataReferencia, omni, page, rowsPerPage, tipoRepresentante]);

	const exportXls = async () => {
		setShowExportMessage(true);
		const start = format(dataReferencia.start, 'YYYY-MM-DD');
		const end = format(dataReferencia.end, 'YYYY-MM-DD');
		const result = await axios.get(
			`${config.get(
				'apiUrl'
			)}export/relatorios/comissoesRepresentantes?date_range[gte]=${start}&date_range[lt]=${end}&omni=${omni}&limit=${rowsPerPage}&page=${page}`,
			{
				params: { tipoRepresentante },
				headers: { Authorization: `Bearer ${token}` },
				responseType: 'blob',
			}
		);

		if (result.status !== 200 || !Isset(() => result.data)) {
			setShowExportMessage(false);
			return dispatch(addMessage({ type: 'error', message: 'Erro ao exportar relatório.' }));
		}

		const { 'content-disposition': contentDisposition, 'content-type': contentType } = result.headers;
		let filename = 'performancePorEstabelecimento.xlsx';
		if (contentDisposition.indexOf('filename="') !== -1) {
			filename = contentDisposition.slice(
				contentDisposition.indexOf('filename="') + 10,
				contentDisposition.length - 1
			);
		}

		FileDownload(result.data, filename, contentType);
		setShowExportMessage(false);
	};

	const omniChange = e => {
		const value = e.target.value;
		setOmni(value);

		if (omniTypingTimeout) {
			clearTimeout(omniTypingTimeout);
		}

		const timeout = setTimeout(() => {
			getRepresentantes();
			setOmniTypingTimeout(null);
		}, 500);

		setOmniTypingTimeout(timeout);
	};

	const omniClear = e => {
		e.stopPropagation();
		setOmni('');
	};

	return (
		<Grid>
			<ZTitle title="Comissão dos Representantes" display="flex" textAlign="center" fontSize="40px" />

			<Grid container alignItems="center" justifyContent={'center'}>
				<Grid item xs={12} lg={4} xl={4}>
					<Paper
						style={{
							position: 'relative',
							marginBottom: 10,
							marginLeft: 10,
							borderRadius: 20,
							padding: '5px 10px',
							display: 'flex',
							alignItems: 'center',
						}}
						className={classes.header}
					>
						<Grid
							container
							alignItems="center"
							justifyContent={'center'}
							direction={isMobile ? 'column' : 'row'}
						>
							<ZDateRangePicker
								onClose={date =>
									setDataReferencia({ ...dataReferencia, start: date.startDate, end: date.endDate })
								}
								className={classes.item}
								startDate={dataReferencia.start}
								endDate={dataReferencia.end}
								label={dataReferencia.label}
								clearDate={() =>
									setDataReferencia({
										start: new Date().setHours(0, 0, 0, 0),
										end: new Date(),
										label: 'Clique para selecionar a data',
									})
								}
							/>
							<Grid item>
								<TextField
									// id="standard-search"
									// label={props.omni.label || 'Busca'}
									placeholder={'Representante'}
									type="text"
									value={omni}
									onChange={omniChange}
									margin="normal"
									className={classes.item}
								/>
								{omni && <FaTimes className={classes.clearBtn} onClick={omniClear} />}
							</Grid>
							<Grid item>
								<Select
									value={tipoRepresentante}
									className={classes.item}
									onChange={e => {
										let value = e.target.value;
										setTipoRepresentante(value);
									}}
									displayEmpty
								>
									<MenuItem disabled value="">
										<em>Tipo Representante</em>
									</MenuItem>
									<MenuItem value={1}>Master</MenuItem>
									<MenuItem value={2}>Secundário</MenuItem>
								</Select>
								{tipoRepresentante && (
									<FaTimes className={classes.clearBtn} onClick={() => setTipoRepresentante('')} />
								)}
							</Grid>
							<Grid item>
								<FiDownload onClick={exportXls} className={classes.export} />
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
			<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 45px)' }}>
				{loading ? (
					<ZLoader height={200} />
				) : (
					<>
						<ZTable
							headCells={[
								{
									id: 'nome',
									numeric: false,
									disablePadding: false,
									label: 'Representante',
								},
								{
									id: 'taxa',
									numeric: false,
									disablePadding: false,
									label: 'Taxa',
									filter: value => `${Mask.Porcentagem(+value)} %`,
								},
								{
									id: 'taxaMedia',
									numeric: false,
									disablePadding: false,
									label: 'Taxa Média',
									filter: value => `${Mask.Porcentagem(+value)} %`,
								},
								{ id: 'quantidadeVendas', numeric: false, disablePadding: false, label: 'Vendas' },
								{
									id: 'valorVendas',
									numeric: false,
									disablePadding: false,
									label: 'Valor Total de Vendas',
									filter: Formatter.Real,
								},
								{
									id: 'markup',
									numeric: false,
									disablePadding: false,
									label: 'Valor Markup',
									filter: Formatter.Real,
								},
								{
									id: 'valorComissao',
									numeric: false,
									disablePadding: false,
									label: 'Comissão',
									filter: Formatter.Real,
								},
							]}
							tableTitle=""
							style={{ minWidth: 10 }}
							toolBar={false}
							rows={representantes}
							densePadding={false}
							checkBox={false}
							rowsPerPage={[15, 30, 60]}
							rowsPerPageDefault={rowsPerPage}
							pagination={false}
						/>

						<ZPaginationDiv
							count={totalRows}
							rowsPerPage={rowsPerPage}
							page={page - 1}
							onChangePage={onChangePage}
							onChangeRowsPerPage={onChangeRowsPerPage}
							rowsPerPageOptions={[15, 30, 60]}
						/>
					</>
				)}
			</Paper>

			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>
		</Grid>
	);
};

export default RepresentantesComissoes;
