import React, { useState } from 'react';

import { TableRow, TableFooter, TablePagination } from '@material-ui/core';
const ZPagination = props => {
	const { onChangePage, onChangeRowsPerPage } = props;

	const [rowsPerPageOptions] = useState(props.rowsPerPageOptions || [15, 30, 60]);

	const handleChangePage = (event, newPage) => {
		onChangePage(newPage + 1);
	};

	const handleChangeRowsPerPage = event => {
		onChangeRowsPerPage(parseInt(event.target.value, 10));
	};

	return (
		<TableFooter>
			<TableRow>
				<TablePagination
					rowsPerPageOptions={rowsPerPageOptions}
					{...props}
					SelectProps={{
						inputProps: { 'aria-label': 'rows per page' },
						native: true,
					}}
					labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
					labelRowsPerPage={'Resultados por página:'}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</TableRow>
		</TableFooter>
	);
};

export default ZPagination;
