import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const ZModalConfirm = props => {
	const [open, setOpen] = useState(false);
	const title = props.title;
	const message = props.message;

	const handleClose = result => {
		setOpen(false);
		props.onClose(result);
	};

	useEffect(() => {
		setOpen(props.open);
	}, [props.open]);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			disableBackdropClick
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleClose(false)} color="primary">
					Não
				</Button>
				<Button onClick={() => handleClose(true)} color="primary" autoFocus>
					Sim
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ZModalConfirm;
