import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	configHeader: {
		fontWeight: 700,
		fontSize: 16,
		margin: 4,
	},
	novaContaBancariaFields: {
		padding: 10,
	},
	box: {
		position: 'relative',
		padding: 10,
		borderTop: `solid 4px transparent`,
	},
	boxAtivo: {
		borderTop: `solid 4px ${theme.palette.primary.main}`,
	},
	ativo: {
		position: 'absolute',
		right: 10,
		top: 5,
		textTransform: 'uppercase',
		fontSize: 11,
		fontWeight: 'bold',
	},
	formControl: {
		width: '100%',
		marginTop: 4,
	},
	salvarBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		margin: 8,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	textField: {
		width: '100%',
		marginTop: 4,
	},
	tipoContaBancaria: {
		padding: 20,
		fontSize: 12,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: 700,
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 6,
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
		[theme.breakpoints.down('md')]: {
			padding: 10,
		},
	},
	icon: {
		color: theme.palette.primary.main,
	},
	updateCheck: {
		position: 'absolute',
		top: 10,
		right: 10,
	},
	field: {
		padding: 5,
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	configUpdateBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		paddingBottom: 15,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
}));
