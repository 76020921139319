/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { Zoom, Tab, Tabs } from '@material-ui/core';
import { Isset } from '../../utils';
import ContaBancaria from './ContaBancaria';
import PlanosDeVendas from '../PlanosDeVendas';
import Taxas from './Taxas';
import Split from './ConfigurarSplit';
import POS from '../POS';
// import PlanosDeTaxas from '../PlanosDeTaxas/';
import Parametros from './Parametros';
import { useStyles } from './styles/ConfiguracoesGeraisStyle';
import { setSelectedECId, resetSelectedEC } from '../../store/system';
import Dados from './Dados';

const EstabelecimentosConfiguracoesGerais = props => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const { match } = useReactRouter();
	const usuario = useSelector(state => state.auth.usuario);
	const selectedEC = useSelector(state => state.system.selectedEC);
	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);

	const isBackoffice = [1, 7, 10].includes(usuario.grupo_id);

	const [tabSelected, setTabSelected] = useState(isBackoffice ? 7 : 1);

	const estabelecimentoId = Isset(() => match.params.estabelecimentoId) ? match.params.estabelecimentoId : null;

	const canView = {
		parametros: [1, 7, 10].includes(usuario.grupo_id) || (usuario.grupo_id === 2 && operarMarketplace),
	};

	if (!selectedEC.id) {
		dispatch(setSelectedECId(estabelecimentoId));
	}

	useEffect(() => {
		return () => {
			dispatch(resetSelectedEC());
		};
	}, []);

	const handleChange = (e, value) => setTabSelected(value);
	return (
		<div className={classes.parametros}>
			<div className={classes.header}>
				Configurações - {selectedEC.nome}
				{selectedEC.nomeComprovante ? <small> ({selectedEC.nomeComprovante})</small> : ''}
			</div>

			<Tabs value={tabSelected} onChange={handleChange} centered>
				{isBackoffice && <Tab label="Dados" value={7} />}
				{canView.parametros && <Tab label="Parâmetros" value={0} />}
				<Tab label="Conta Bancária" value={1} />
				{isBackoffice && <Tab label="Plano de Venda" value={2} />}
				<Tab label="POS" value={3} />
				{/* {<Tab label="Tabelas de Markup" value={4} />} */}
				<Tab label="Taxas" value={5} />
				<Tab label="Split" value={6} />
			</Tabs>
			<div className={classes.tabPanels}>
				{tabSelected === 7 && <Dados estabelecimentoId={estabelecimentoId} />}

				{tabSelected === 0 && <Parametros estabelecimentoId={estabelecimentoId} />}

				{tabSelected === 1 && (
					<Zoom in>
						<ContaBancaria estabelecimentoId={estabelecimentoId} />
					</Zoom>
				)}

				{tabSelected === 2 && <PlanosDeVendas estabelecimentoId={estabelecimentoId} />}

				{tabSelected === 3 && <POS estabelecimentoId={estabelecimentoId} />}

				{/* {tabSelected === 4 && <PlanosDeTaxas estabelecimentoId={estabelecimentoId} />} */}

				{tabSelected === 5 && <Taxas estabelecimentoId={estabelecimentoId} />}

				{tabSelected === 6 && <Split estabelecimentoId={estabelecimentoId} />}
			</div>
		</div>
	);
};

export default EstabelecimentosConfiguracoesGerais;
