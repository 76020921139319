/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import config from 'react-global-configuration';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { Formatter, Mask } from '../../utils';
import ZLoader from '../system/ZLoader';

const CalculadoraValorBruto = props => {
	let { estabelecimentoId } = useParams();

	if (!estabelecimentoId) {
		({ estabelecimentoId } = props);
	}
	const useStyles = makeStyles(theme => ({
		calculadora: {},
		content: {
			minHeight: 700,
		},
		header: {
			fontWeight: 700,
			fontSize: 20,
			margin: 8,
			textAlign: 'center',
		},
		pageTitle: {
			fontWeight: 'bold',
			fontSize: 18,
			marginBottom: 10,
		},
		planosTaxas: {
			position: 'relative',
		},
		tiposPagamentos: {
			position: 'absolute',
			paddingTop: 50,
			paddingBottom: 52,
			border: 'solid 1px #abc',
			borderRadius: 10,
			padding: 10,
			width: 'calc(100% - 30px)',
		},
		planos: {
			position: 'relative',
			padding: 14,
			height: 50,
			fontSize: 14,
			fontWeight: 600,
			'& span': {
				fontWeight: 'bold',
				fontSize: 16,
			},
		},
		taxaBoletoDiv: {
			marginTop: 10,
			position: 'relative',
			padding: 10,
			height: 50,
		},
		tipoPagamento: {
			fontWeight: 'bold',
			fontSize: 16,
			padding: 4,
			'&:not(:last-child)': {
				borderBottom: 'solid 1px #bcd',
			},
		},
		tipoPagamentoBoleto: {
			float: 'left',
			fontWeight: 'bold',
			fontSize: 16,
			padding: 4,
		},
		bandeirasWrapper: {
			position: 'absolute',
			marginLeft: 120,
			width: 'calc(100% - 160px)',
			overflowX: 'auto',
		},
		bandeira: {
			position: 'relative',
			paddingTop: 10,
			paddingBottom: 10,
			marginTop: 10,
			border: 'solid 1px #abc',
			borderRadius: 10,
			backgroundColor: '#ffffffc0',
			height: 490,
			//width: 105,
			marginRight: 5,
			float: 'left',
		},
		novaBandeira: {
			position: 'relative',
			padding: 10,
			marginTop: 10,
			border: 'solid 1px #abc',
			borderRadius: 10,
			backgroundColor: '#ffffff',
			width: 160,
			marginRight: 10,
			float: 'left',
		},
		title: {
			textAlign: 'center',
			fontWeight: 'bold',
			fontSize: 16,
			height: 22,
		},
		titleLong: {
			fontSize: 11,
		},
		deleteButton: {
			top: 2,
			right: 2,
			cursor: 'pointer',
			position: 'absolute',
			width: 20,
			height: 20,
			color: '#e53935',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			opacity: 0.5,
			'&:hover': {
				opacity: 1,
			},
		},
		fields: {
			marginTop: 8,
			marginBottom: 15,
			borderTop: 'solid 1px #bcd',
		},
		tipoPagamentoFields: {
			borderBottom: 'solid 1px #bcd',
		},
		taxaField: {
			padding: 7.45,
			'&::after': {
				content: '"%"',
				position: 'absolute',
				right: 4,
				fontSize: 12,
			},
			'& .MuiInputBase-input': {
				textAlign: 'right',
				height: '1em',
				padding: 0,
				paddingRight: 15,
			},
			'& .MuiInput-underline:before': {
				borderBottom: 'none',
			},
			'& .MuiInput-underline:after': {
				bottom: -7,
			},
			'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
				borderBottom: 'none',
			},
		},
		saveButton: {
			textAlign: 'center',
			textTransform: 'uppercase',
			fontWeight: 700,
			fontSize: 12,
			cursor: 'pointer',
			color: theme.palette.primary.main,
			marginTop: 5,
		},
		adicionarButton: {
			textAlign: 'center',
			textTransform: 'uppercase',
			fontWeight: 700,
			fontSize: 12,
			cursor: 'pointer',
			color: theme.palette.primary.main,
			marginTop: 15,
		},
		taxaFieldBoletoReadOnly: {
			marginLeft: 120,
			height: 30.8,
			fontWeight: 'bold',
			fontSize: 15,
			padding: 4,
		},
		taxaFieldReadOnly: {
			height: 30.8,
			textAlign: 'center',
			fontWeight: 'bold',
			fontSize: 15,
			padding: 4,
		},
		empty: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			fontSize: 18,
			fontWeight: 700,
			height: 100,
		},
		highlight: {
			backgroundColor: '#fdfdc4ad',
		},
	}));
	const token = useSelector(store => store.auth.token);
	const [valorLiquido, setValorLiquido] = useState(0);
	const classes = useStyles();
	// const [planoVenda, setPlanoVenda] = useState({});
	// const [planoTaxa, setPlanoTaxa] = useState({});
	const [taxaBoleto, setTaxaBoleto] = useState(0);
	const [taxas, setTaxas] = useState({});
	const [countBandeiras, setCountBandeiras] = useState(1);
	const [loading, setLoading] = useState(true);
	const [position, setPosition] = useState({ bandeiraId: null, parcela: null });

	useEffect(() => {
		const getTaxas = async () => {
			setLoading(true);
			const res = await Axios.get(
				`${config.get('apiUrl')}planos_taxas/porEstabelecimento/${estabelecimentoId}/taxaTotal`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				const taxa = res.data.taxaTotal;

				Object.keys(taxa).forEach(bandeiraId => {
					Object.keys(taxa[bandeiraId].valores).forEach(tipoPagamentoId => {
						Object.keys(taxa[bandeiraId].valores[tipoPagamentoId].valores).forEach(parcela => {
							taxa[bandeiraId].valores[tipoPagamentoId].valores[parcela] = {
								taxa: taxa[bandeiraId].valores[tipoPagamentoId].valores[parcela],
								valorBruto: 0,
							};
						});
					});
				});
				// setPlanoVenda(res.data.planoVenda);
				// setPlanoTaxa(res.data.planoTaxa);
				setTaxaBoleto({ taxa: res.data.taxaBoleto, valorBruto: 0 });
				setTaxas(taxa);
				setCountBandeiras(Object.keys(taxa).length);
			}
			setLoading(false);
		};

		getTaxas();
	}, []);

	useEffect(() => {
		if (valorLiquido > 0) {
			setLoading(true);
			const taxasAux = taxas;
			Object.keys(taxasAux).forEach(bandeiraId => {
				Object.keys(taxasAux[bandeiraId].valores).forEach(tipoPagamentoId => {
					Object.keys(taxasAux[bandeiraId].valores[tipoPagamentoId].valores).forEach(parcela => {
						const { taxa } = taxasAux[bandeiraId].valores[tipoPagamentoId].valores[parcela];
						taxasAux[bandeiraId].valores[tipoPagamentoId].valores[parcela].valorBruto =
							(valorLiquido * 100) / (100 - taxa);
					});
				});
			});
			setTaxas(taxasAux);
			setTaxaBoleto({ ...taxaBoleto, valorBruto: valorLiquido + taxaBoleto.taxa });
			setLoading(false);
		}
	}, [valorLiquido]);

	// console.log(position);

	return (
		<Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
			<DialogContent className={classes.content}>
				<div className={classes.calculadora}>
					<div className={classes.header}>Calculadora de Valor Bruto</div>
					<div>
						<TextField
							value={Mask.Real(valorLiquido)}
							onChange={e => setValorLiquido(+Mask.OnlyNumber(e.target.value) / 100)}
							label="Valor Líquido"
						/>
					</div>

					{loading ? (
						<ZLoader height={200} message="Calculando" />
					) : (
						<>
							{valorLiquido > 0 ? (
								<>
									<div className={classes.taxaBoleto}>
										<div className={classes.tiposPagamentos}>
											<div className={classes.tipoPagamentoBoleto}>Boleto</div>
											<div className={classes.taxaFieldBoletoReadOnly}>
												{Formatter.Real(taxaBoleto.valorBruto)}
											</div>
										</div>
									</div>
									<div style={{ marginTop: 10 }}>
										<div className={classes.tiposPagamentos}>
											<div
												className={clsx(classes.tipoPagamento, {
													[classes.highlight]: position.parcela === 'credit1',
												})}
											>
												Crédito 1x
											</div>
											<div
												className={clsx(classes.tipoPagamento, {
													[classes.highlight]: position.parcela === 'credit2',
												})}
											>
												Crédito 2x
											</div>
											<div
												className={clsx(classes.tipoPagamento, {
													[classes.highlight]: position.parcela === 'credit3',
												})}
											>
												Crédito 3x
											</div>
											<div
												className={clsx(classes.tipoPagamento, {
													[classes.highlight]: position.parcela === 'credit4',
												})}
											>
												Crédito 4x
											</div>
											<div
												className={clsx(classes.tipoPagamento, {
													[classes.highlight]: position.parcela === 'credit5',
												})}
											>
												Crédito 5x
											</div>
											<div
												className={clsx(classes.tipoPagamento, {
													[classes.highlight]: position.parcela === 'credit6',
												})}
											>
												Crédito 6x
											</div>
											<div
												className={clsx(classes.tipoPagamento, {
													[classes.highlight]: position.parcela === 'credit7',
												})}
											>
												Crédito 7x
											</div>
											<div
												className={clsx(classes.tipoPagamento, {
													[classes.highlight]: position.parcela === 'credit8',
												})}
											>
												Crédito 8x
											</div>
											<div
												className={clsx(classes.tipoPagamento, {
													[classes.highlight]: position.parcela === 'credit9',
												})}
											>
												Crédito 9x
											</div>
											<div
												className={clsx(classes.tipoPagamento, {
													[classes.highlight]: position.parcela === 'credit10',
												})}
											>
												Crédito 10x
											</div>
											<div
												className={clsx(classes.tipoPagamento, {
													[classes.highlight]: position.parcela === 'credit11',
												})}
											>
												Crédito 11x
											</div>
											<div
												className={clsx(classes.tipoPagamento, {
													[classes.highlight]: position.parcela === 'credit12',
												})}
											>
												Crédito 12x
											</div>
											<div
												className={clsx(classes.tipoPagamento, {
													[classes.highlight]: position.parcela === 'debit1',
												})}
											>
												Débito
											</div>
										</div>

										{loading ? (
											<ZLoader height={300} />
										) : (
											<div className={classes.bandeirasWrapper}>
												<div style={{ width: (countBandeiras + 1) * 110 }}>
													{Object.keys(taxas).map(bandeiraId => (
														<div
															key={`Bandeira-${bandeiraId}`}
															className={clsx(classes.bandeira, {
																[classes.highlight]: position.bandeiraId === bandeiraId,
															})}
														>
															<div
																className={clsx(classes.title, {
																	[classes.titleLong]:
																		taxas[bandeiraId].titulo.length > 10,
																})}
															>
																{taxas[bandeiraId].titulo}
															</div>
															<div className={classes.fields}>
																{Object.keys(taxas[bandeiraId].valores).map(
																	tipoPagamentoId => (
																		<div
																			key={`${bandeiraId}${tipoPagamentoId}`}
																			className={classes.tipoPagamentoFields}
																		>
																			{Object.keys(
																				taxas[bandeiraId].valores[
																					tipoPagamentoId
																				].valores
																			).map(parcela => (
																				<div
																					key={`${bandeiraId}-${parcela}`}
																					className={
																						classes.taxaFieldReadOnly
																					}
																					onMouseEnter={() =>
																						setPosition({
																							bandeiraId,
																							parcela: `${tipoPagamentoId}${parcela}`,
																						})
																					}
																					onMouseLeave={() =>
																						setPosition({
																							bandeiraId: null,
																							parcela: null,
																						})
																					}
																				>
																					{Mask.Real(
																						taxas[bandeiraId].valores[
																							tipoPagamentoId
																						].valores[parcela].valorBruto
																					)}
																				</div>
																			))}
																		</div>
																	)
																)}
															</div>
														</div>
													))}
												</div>
											</div>
										)}
									</div>
								</>
							) : (
								<div className={classes.empty}>Informe o Valor Líquido</div>
							)}
						</>
					)}
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default CalculadoraValorBruto;
