/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Switch, FormGroup, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	switch: {
		paddingLeft: 20,
	},
	filtros: {
		position: 'relative',
		display: 'inline-flex',
	},
}));

const ZSwitch = props => {
	useStyles();
	const [checked, setChecked] = React.useState(props.checked || false);
	const labelPlacement = props.labelPlacement || 'end';

	const toggleChecked = e => {
		setChecked(prev => !prev);
		if (props.onChange) {
			props.onChange(e.target.checked);
		}
	};
	React.useEffect(() => {
		setChecked(props.checked);
	}, [props.checked]);

	return (
		<FormGroup style={{ paddingLeft: 12 }}>
			<FormControlLabel
				control={<Switch checked={checked} onChange={toggleChecked} />}
				value={checked}
				label={props.label}
				labelPlacement={labelPlacement}
			/>
		</FormGroup>
	);
};

export default ZSwitch;
