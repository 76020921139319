import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import config from 'react-global-configuration';
import axios from 'axios';
import clsx from 'clsx';
import { IconButton, Button, Grid, Paper, Switch, TextField } from '@material-ui/core';
import { FaDollarSign, FaPercentage } from 'react-icons/fa';
import { Isset } from '../../utils';
import ParametroInfo from '../Marketplace/ParametroInfo';
import { ZLoader } from '../system';
import { Mask } from '../../utils';
import { addMessage } from '../../store/alert';
import { getUserParams } from '../../store/system';
import { useStyles } from './styles/ParametrosStyle';

export default memo(props => {
	const { estabelecimentoId } = props;
	const dispatch = useDispatch();

	const classes = useStyles();

	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(state => state.auth.usuario);
	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);
	const operaAssinaturas = useSelector(store =>
		Isset(() => store.system.params['28']) ? store.system.params['28'] : null
	);
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [parametros, setParametros] = useState([]);
	useEffect(() => {
		const getParametros = async () => {
			setLoading(true);
			const res = await axios.get(`${config.get('apiUrl')}parametros/por_estabelecimento/${estabelecimentoId}`, {
				headers: { Authorization: `Bearer ${token}` },
			});
			if (res.data.success) {
				const parametros = res.data.parametros.filter(parametro => {
					if (operarMarketplace && estabelecimentoId === usuario.estabelecimento.id) {
						return parametro.titulo !== 'Operar como Marketplace?';
					}

					return true;
				});
				parametros.push(operaAssinaturas);
				setParametros(parametros);
			}
			setLoading(false);
		};

		getParametros();
	}, [estabelecimentoId, operarMarketplace, token, usuario.estabelecimento.id, operaAssinaturas]);

	const handleChange = id => e => {
		const result = parametros.map(p => {
			if (p.id === id) {
				switch (p.tipo) {
					case 'moneyPercent': {
						p.valor = Mask.OnlyNumber(e.target.value) / 100;
						if (p.tipoValor === 'percent' && p.valor > 100) {
							p.valor = 100;
						}
						break;
					}
					case 'money': {
						p.valor = Mask.OnlyNumber(e.target.value) / 100;
						break;
					}
					case 'boolean':
					default:
						p.valor = e.target.checked;
				}
			}
			return p;
		});
		setParametros(result);
	};

	const handleSubmit = async () => {
		setSaving(true);
		const res = await axios.post(
			`${config.get('apiUrl')}parametros/por_estabelecimento/${estabelecimentoId}`,
			{ parametros },
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			dispatch(addMessage({ type: 'success', message: 'Parâmetros salvos com sucesso' }));
			dispatch(getUserParams(usuario));
		}
		setSaving(false);
	};

	const updateParametroTipoValor = (id, value) => {
		const result = parametros.map(p => {
			if (p.id === id) {
				p.tipoValor = value;
			}
			return p;
		});
		setParametros(result);
	};

	if (loading) {
		return <ZLoader height={200} />;
	}
	return (
		<div className={classes.parametros}>
			{parametros.length > 0 ? (
				<>
					<Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={2}>
						{parametros.map((a, i) => (
							<Grid
								key={a.titulo}
								item
								xs={12}
								sm={6}
								md={3}
								style={{
									display:
										[7].includes(usuario.grupo_id) && a.titulo === 'Habilitar Link de Pagamento?'
											? 'none'
											: 'inline',
								}}
							>
								<Paper className={classes.parametro}>
									<ParametroInfo title={a.descricao} />
									<Grid container spacing={2}>
										<Grid item className={classes.parametroTitulo} xs={4}>
											{a.titulo}
										</Grid>
										<Grid item style={{ display: 'flex', alignItems: 'center' }} xs={8}>
											{a.tipo === 'boolean' && (
												<Switch checked={a.valor} onChange={handleChange(a.id)} />
											)}
											{a.tipo === 'money' && (
												<TextField value={Mask.Real(a.valor)} onChange={handleChange(a.id)} />
											)}
											{a.tipo === 'moneyPercent' && (
												<>
													<IconButton
														className={clsx(classes.moneyPercentIcone, {
															selected: a.tipoValor === 'money',
														})}
														onClick={() => updateParametroTipoValor(a.id, 'money')}
													>
														<FaDollarSign style={{ fontSize: 14 }} />
													</IconButton>
													<TextField
														value={
															a.tipoValor === 'money'
																? Mask.Real(a.valor)
																: Mask.Porcentagem(a.valor)
														}
														className={clsx(classes.fullWidth, classes.moneyPercentValor, {
															porcentagem: a.tipoValor === 'percent',
														})}
														onChange={handleChange(a.id)}
														onFocus={e => {
															const length = e.target.value.length * 2;
															e.target.setSelectionRange(0, length);
														}}
													/>
													<IconButton
														className={clsx(classes.moneyPercentIcone, {
															selected: a.tipoValor === 'percent',
														})}
														onClick={() => updateParametroTipoValor(a.id, 'percent')}
													>
														<FaPercentage style={{ fontSize: 14 }} />
													</IconButton>
													{a.id === 28 && a.valor && (
														<>
															<Switch checked={a.valor} onChange={handleChange(a.id)} />
														</>
													)}
												</>
											)}
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						))}
					</Grid>
					<div className={classes.actionButtons}>
						<Button disabled={saving} variant="contained" color="primary" onClick={handleSubmit}>
							{saving ? <ZLoader size={14} height={24} /> : 'Salvar'}
						</Button>
					</div>
				</>
			) : (
				<div className={classes.empty}>Nenhum Parâmetro Encontrado.</div>
			)}
		</div>
	);
});
