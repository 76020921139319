import React from 'react';
import { InputLabel, Select, MenuItem, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
	select: {
		minWidth: 150,
		minHeight: 48,
		marginTop: 20,
		marginBottom: 20,
	},
}));
const ZModalSelect = props => {
	const classes = useStyles();
	return (
		<>
			<InputLabel classname={classes.label}>{props.label}</InputLabel>
			<Select
				native={false}
				multiple={props.multiple || false}
				className={classes.select}
				value={props.value}
				label={props.label}
				onChange={props.handleChange}
				renderValue={selected => (
					<div className={classes.chips}>
						{props.multiple
							? props.value.map(value => (
									<Chip
										key={value}
										label={value}
										sx={{ color: 'red' }}
										deleteIcon={
											<CancelIcon
												fontSize="large"
												onMouseDown={event => event.stopPropagation()}
											/>
										}
										onDelete={e => props.handleDelete(e, value)}
										onClick={() => console.log('clicked chip')}
										color="info"
										clickable
										variant="outlined"
									/>
							  ))
							: props.value}
					</div>
				)}
			>
				{props.selects ? (
					props.selects.map(item => (
						<MenuItem key={item} value={item}>
							{item}
						</MenuItem>
					))
				) : (
					<MenuItem> Primeiro Selecione Forma de Pagamento</MenuItem>
				)}
			</Select>
		</>
	);
};
export default ZModalSelect;
