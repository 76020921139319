import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import axios from 'axios';
import config from 'react-global-configuration';
import { useSelector } from 'react-redux';
import { ZLoader } from '../system';
import { Mask, dbDateToDate } from '../../utils';
import { useStyles } from './styles/PreCadastroDadosStyle';

const PreEstabelecimentosDados = props => {
	const [estabelecimentoId] = useState(
		props.estabelecimentoId !== undefined ? props.estabelecimentoId : +props.match.params.estabelecimentoId
	);
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);

	const [estabelecimento, setEstabelecimento] = useState(null);
	const [loading, setLoading] = useState(true);
	const [isPJ, setIsPJ] = useState(false);

	useEffect(() => {
		const getEstabelecimento = async () => {
			setLoading(true);
			const res = await axios.get(
				`${config.get('apiUrl')}estabelecimentos/pre_cadastro/${estabelecimentoId}?mcc=true`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				const { estabelecimento } = res.data;

				estabelecimento.documento = estabelecimento.pre_estabelecimentos_documentos.find(ed =>
					[2, 3].includes(ed.tipo_documento_id)
				).documento;

				estabelecimento.email = estabelecimento.pre_estabelecimentos_contatos.find(
					ed => ed.tipo_contato_id === 3
				).contato;

				estabelecimento.telefone = estabelecimento.pre_estabelecimentos_contatos.find(ed =>
					[1, 2].includes(ed.tipo_contato_id)
				).contato;

				setEstabelecimento(estabelecimento);
				setIsPJ(estabelecimento.tipo_estabelecimento.id === 2);
			}
			setLoading(false);
		};

		getEstabelecimento();
	}, [estabelecimentoId, token]);

	if (!estabelecimento) {
		return 'Carregando...';
	}
	return (
		<div className={classes.estabelecimento}>
			{loading ? (
				<ZLoader height={200} />
			) : (
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4}>
						<Paper className={classes.paper}>
							<div className={classes.sectionHeader}>Estabelecimento</div>
							<div className={classes.field}>
								<div className={classes.label}>{isPJ ? 'Nome Fantasia' : 'Nome'}</div>
								<div>{estabelecimento.nome_fantasia}</div>
							</div>
							{isPJ ? (
								<>
									<div className={classes.field}>
										<div className={classes.label}>Razão Social</div>
										<div>{estabelecimento.razao_social}</div>
									</div>
									<div className={classes.field}>
										<div className={classes.label}>CNPJ</div>
										<div>{Mask.Cnpj(estabelecimento.documento)}</div>
									</div>
								</>
							) : (
								<>
									<div className={classes.field}>
										<div className={classes.label}>CPF</div>
										<div>{Mask.Cpf(estabelecimento.documento)}</div>
									</div>
									<div className={classes.field}>
										<div className={classes.label}>Data de Nascimento</div>
										<div>
											{estabelecimento.data_nascimento
												? dbDateToDate(estabelecimento.data_nascimento)
												: '---'}
										</div>
									</div>
								</>
							)}
							<div className={classes.field}>
								<div className={classes.label}>E-mail</div>
								<div>{estabelecimento.email}</div>
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Telefone</div>
								<div>{Mask.Celular(estabelecimento.telefone)}</div>
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Categoria do Negócio</div>
								<div>{estabelecimento.categoria ? estabelecimento.categoria : '---'}</div>
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Data de Criação</div>
								<div>{dbDateToDate(estabelecimento.created)}</div>
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={4}>
						<Paper className={classes.paper}>
							<div className={classes.sectionHeader}>Endereço</div>
							<div className={classes.field}>
								<div className={classes.label}>CEP</div>
								<div>{Mask.Cep(estabelecimento.endereco.cep)}</div>
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Logradouro</div>
								<div>{estabelecimento.endereco.logradouro}</div>
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Número</div>
								<div>{estabelecimento.endereco.numero}</div>
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Complemento</div>
								<div>
									{estabelecimento.endereco.complemento
										? estabelecimento.endereco.complemento
										: '---'}
								</div>
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Bairro</div>
								<div>{estabelecimento.endereco.bairro ? estabelecimento.endereco.bairro : '---'}</div>
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Cidade</div>
								<div>{estabelecimento.endereco.cidade}</div>
							</div>
							<div className={classes.field}>
								<div className={classes.label}>Estado</div>
								<div>{estabelecimento.endereco.uf}</div>
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12} sm={4}>
						<Grid container>
							{isPJ && estabelecimento.estabelecimento_proprietario && (
								<Grid item xs={12} style={{ marginBottom: 12 }}>
									<Paper className={classes.paper}>
										<div className={classes.sectionHeader}>Proprietário</div>
										<div className={classes.field}>
											<div className={classes.label}>CPF</div>
											<div>
												{estabelecimento.estabelecimento_proprietario.cpf
													? Mask.Cpf(estabelecimento.estabelecimento_proprietario.cpf)
													: '---'}
											</div>
										</div>
										<div className={classes.field}>
											<div className={classes.label}>Nome</div>
											<div>
												{estabelecimento.estabelecimento_proprietario.nome
													? `${estabelecimento.estabelecimento_proprietario.nome} - ${estabelecimento.estabelecimento_proprietario.sobrenome}`
													: '---'}
											</div>
										</div>
										<div className={classes.field}>
											<div className={classes.label}>Data de Nascimento</div>
											<div>
												{estabelecimento.estabelecimento_proprietario.data_nascimento
													? dbDateToDate(
															estabelecimento.estabelecimento_proprietario.data_nascimento
													  )
													: '---'}
											</div>
										</div>
										<div className={classes.field}>
											<div className={classes.label}>Telefone</div>
											<div>
												{estabelecimento.estabelecimento_proprietario.celular
													? estabelecimento.estabelecimento_proprietario.celular
													: '---'}
											</div>
										</div>
										<div className={classes.field}>
											<div className={classes.label}>E-mail</div>
											<div>
												{estabelecimento.estabelecimento_proprietario.email
													? estabelecimento.estabelecimento_proprietario.email
													: '---'}
											</div>
										</div>
									</Paper>
								</Grid>
							)}
							{/* <Grid item xs={12}>
								<Paper className={classes.paper}>
									<div className={classes.sectionHeader}>Informações Adicionais</div>
								</Paper>
							</Grid> */}
						</Grid>
					</Grid>
				</Grid>
			)}
		</div>
	);
};

export default PreEstabelecimentosDados;
