import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Formatter, Isset } from '../../utils';
import axios from 'axios';
import config from 'react-global-configuration';
import useReactRouter from 'use-react-router';
import queryString from 'query-string';
import FileDownload from 'js-file-download';
import { Grid, Paper, Dialog, DialogContent } from '@material-ui/core';
import { FaFilter, FaFileExcel } from 'react-icons/fa';
import { format } from 'date-fns';
import { addMonths } from 'date-fns';
import { pt } from 'date-fns/locale';
import { addMessage } from '../../store/alert';
import { ZFiltros, ZLoader } from '../system';
import { useStyles } from './styles/ProximosLancamentosStyle';
import { ptBR } from 'date-fns/locale';

const ProximosLancamentos = () => {
	const { history, location } = useReactRouter();

	const query = queryString.parse(location.search);

	const queryFilter = {
		date: { start: new Date(), end: addMonths(new Date(), 6) },
		tipoTransacao: '',
	};

	if (Isset(() => query.tt)) {
		switch (+query.tt) {
			case 1:
				queryFilter.tipoTransacao = 'venda';
				break;
			case 2:
				queryFilter.tipoTransacao = 'transferencia';
				break;
			default:
		}
	}

	if (Isset(() => query.d)) {
		switch (query.d) {
			case 'lastMonth':
				queryFilter.date = { default: query.d, start: addMonths(new Date(), -1), end: new Date() };
				break;
			case 'next6months':
				queryFilter.date = { default: query.d, start: new Date(), end: addMonths(new Date(), 6) };
				break;
			default:
		}
	}

	const classes = useStyles();

	const dispatch = useDispatch();

	const token = useSelector(state => state.auth.token);
	const usuario = useSelector(state => state.auth.usuario);
	const isAdmin = usuario.grupo_id === 1;
	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};

	const [lancamentos, setLancamentos] = useState([]);
	const [filtros, setFiltros] = useState(queryFilter);
	const [totais, setTotais] = useState({});
	const [loading, setLoading] = useState(true);
	const [showExportMessage, setShowExportMessage] = useState(false);

	useEffect(() => {
		const getLancamentos = async () => {
			setLoading(true);

			try {
				const res = (
					await axios.get(`${config.get('apiUrl')}estabelecimentos/proximos-lancamentos`, {
						headers: { Authorization: `Bearer ${token}` },
						params: {
							filtros: {
								...filtros,
								estabelecimentoId,
							},
						},
					})
				).data;

				if (res.success) {
					const dates = [];
					const totais = {};

					let lastDate = null;

					for (let i = 0; i < res.lancamentos.length; i++) {
						const v = res.lancamentos[i];
						const date = format(v.dataVenda, 'YYYY-MM-DD', { locale: ptBR });

						if (lastDate !== date) {
							dates.push({ type: 'date', date });
							lastDate = date;

							totais[date] = {
								bruto: 0,
								liquido: 0,
							};
						}

						v.type = 'row';
						dates.push(v);

						totais[date].bruto += +v.valor;
						totais[date].liquido += +v.valor_liquido;
					}

					if (dates.length > 0) {
						const lastDate = dates[dates.length - 1];

						let lastSaldoId = Object.keys(res.saldo)
							.filter(i => format(i, 'YYYYMMDD') < format(lastDate.data, 'YYYYMMDD'))
							.pop();

						let lastSaldo = res.saldo[lastSaldoId];

						dates.reverse().forEach(v => {
							if (v.type === 'date') {
								const date = format(v.date, 'YYYY-MM-DD');
								const saldo = res.saldo[date] || lastSaldo;
								v.saldo = saldo;
								lastSaldo = saldo;
							}
						});
					}
					setTotais(totais);
					setLancamentos(dates.reverse());
				} else {
					setLancamentos([]);
				}
			} catch (err) {
				dispatch(addMessage({ type: 'error', message: 'Erro ao buscar dados.' }));
			} finally {
				setLoading(false);
			}
		};

		getLancamentos();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtros, estabelecimentoId]);

	const filtrosChange = data => {
		setFiltros(data);
	};

	const exportXls = async () => {
		setShowExportMessage(true);
		const extrato = await axios.get(`${config.get('apiUrl')}export/lancamentosFuturos`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params: {
				filtros: {
					...filtros,
					estabelecimentoId,
				},
			},
		});

		if (extrato.status !== 200 || !Isset(() => extrato.data)) {
			return dispatch(addMessage({ type: 'error', message: 'Erro ao gerar lançamentos futuros.' }));
		}

		const { 'content-disposition': contentDisposition, 'content-type': contentType } = extrato.headers;
		let filename = 'lancamentosFuturos.xlsx';
		if (contentDisposition.indexOf('filename="') !== -1) {
			filename = contentDisposition.slice(
				contentDisposition.indexOf('filename="') + 10,
				contentDisposition.length - 1
			);
		}

		FileDownload(extrato.data, filename, contentType);
		setShowExportMessage(false);
	};

	return (
		<div className={classes.extrato}>
			<div className={classes.header}>Próximos lançamentos</div>

			<Paper style={{ padding: 15 }}>
				<div className={classes.filtros}>
					<FaFilter />
					<ZFiltros
						onChange={filtrosChange}
						dateRange={{
							default: filtros.date.default,
						}}
					/>
					<FaFileExcel className={classes.export} onClick={exportXls} />
				</div>

				<div className={classes.grid}>
					{loading ? (
						<ZLoader height={200} message="Carregando Próximos Lançamentos" />
					) : (
						<>
							{lancamentos.length > 0 ? (
								<Grid container className={classes.lancamentos}>
									{/* Table Header */}
									<Grid container item className={classes.tableHeader}>
										<Grid item xs={2} sm={1}>
											Tipo
										</Grid>
										<Grid item xs={2}>
											Método
										</Grid>
										<Grid item xs>
											Cliente
										</Grid>
										{isAdmin && (
											<Grid item xs>
												Estabelecimento
											</Grid>
										)}
										<Grid item xs={1}>
											Valor
										</Grid>
										{isAdmin ? (
											<>
												<Grid item xs={1} className={classes.hideOnXs}>
													Taxa
												</Grid>
												<Grid item xs={1} className={classes.hideOnXs}>
													Split
												</Grid>
											</>
										) : (
											<Grid item xs={1} className={classes.hideOnXs}>
												Taxa
											</Grid>
										)}
										<Grid item xs={1} className={classes.hideOnXs}>
											Valor Líquido
										</Grid>
									</Grid>
									{/* Table rows */}
									{lancamentos.map((lancamento, key) => (
										<React.Fragment key={key}>
											{/* Divisor dos valores por data */}
											{lancamento.type === 'date' ? (
												<Grid container item className={classes.tableDateRow}>
													<Grid item xs={8}>
														{format(lancamento.date, 'DD [de] MMMM [de] YYYY', {
															locale: pt,
														})}
													</Grid>
													<Grid item xs={1} style={{ textAlign: 'center' }}>
														{Isset(() => totais[lancamento.date].bruto)
															? Formatter.Real(totais[lancamento.date].bruto)
															: ''}
													</Grid>
													<Grid item xs={2}></Grid>
													<Grid item xs={1} style={{ textAlign: 'center' }}>
														{Isset(() => totais[lancamento.date].liquido)
															? Formatter.Real(totais[lancamento.date].liquido)
															: ''}
													</Grid>
												</Grid>
											) : (
												<React.Fragment>
													{/* Valores a receber */}
													<Grid
														container
														item
														className={classes.tableRow}
														onClick={() => {
															history.push(
																lancamento.tipoId === 2
																	? '/transferencias/detalhes/' + lancamento.id
																	: '/vendas/detalhes/' + lancamento.pedidoId
															);
														}}
													>
														<Grid item xs={2} sm={1}>
															{lancamento.tipo}
														</Grid>
														<Grid item xs={2} className={classes.truncate}>
															{lancamento.metodo}
														</Grid>
														<Grid item xs className={classes.truncate}>
															{Isset(() => lancamento.cliente.nome)
																? lancamento.cliente.nome
																: ''}
														</Grid>
														{isAdmin && (
															<Grid
																item
																xs
																className={classes.truncate}
																style={{ overflow: 'hidden', maxHeight: 25 }}
															>
																{lancamento.estabelecimento}
															</Grid>
														)}
														<Grid
															item
															xs={1}
															className={clsx({
																[classes.negativo]: lancamento.tipoId === 2,
															})}
														>
															{Formatter.Real(lancamento.valor)}
														</Grid>
														{isAdmin ? (
															<>
																<Grid item xs={1} className={classes.hideOnXs}>
																	{Formatter.Real(lancamento.taxa)}
																</Grid>
																<Grid item xs={1} className={classes.hideOnXs}>
																	{Formatter.Real(lancamento.split)}
																</Grid>
															</>
														) : (
															<Grid item xs={1} className={classes.hideOnXs}>
																{Formatter.Real(+lancamento.taxa + +lancamento.split)}
															</Grid>
														)}
														<Grid
															item
															xs={1}
															className={clsx(classes.hideOnXs, {
																[classes.negativo]: lancamento.tipoId === 2,
															})}
														>
															{Formatter.Real(lancamento.valor_liquido)}
														</Grid>
													</Grid>
												</React.Fragment>
											)}
										</React.Fragment>
									))}
								</Grid>
							) : (
								<div className={classes.empty}>Nenhum lançamento encontrado.</div>
							)}
						</>
					)}
				</div>
			</Paper>

			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default ProximosLancamentos;
