/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';
import clsx from 'clsx';
import { setFilters } from '../../store/filters';
import { useLocation } from 'react-router-dom';
import { Formatter, Isset } from '../../utils';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import { ZLoader, ZDateRangePicker } from '../system';
const useStyles = makeStyles(theme => ({
	indicador: {
		padding: 10,
		height: 120,
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	indicadorTitle: {
		fontVariant: 'small-caps',
		fontSize: 16,
		fontWeight: 'bold',
	},
	indicadorValue: {
		fontSize: 36,
		fontWeight: 'bold',
	},
	lista: {
		padding: 10,
		overflow: 'hidden',
		height: 350,
		textAlign: 'center',
	},
	listaTitle: {
		fontSize: 16,
		fontWeight: 'bold',
	},
	listaHeader: {
		marginTop: 10,
		fontWeight: 'bold',
		borderBottom: 'solid 1px #abc',
	},
	listagemWrapper: {
		marginTop: 10,
		width: '200%',
		minHeight: 280,
		overflow: 'hidden',
		position: 'relative',
	},
	scrollY: {
		height: 280,
		overflowY: 'scroll',
	},
	scrollY2: {
		height: 250,
		overflowY: 'scroll',
	},
	listagem: {
		position: 'absolute',
		top: 30,
		left: '-100%',
		width: '50%',
		textAlign: 'center',
		transition: theme.transitions.create(['left'], {
			duration: theme.transitions.duration.complex,
		}),
	},
	listaRow: {
		padding: 4,
		'&:not(:last-child)': {
			borderBottom: 'solid 1px #abc',
		},
	},
	active: {
		left: 0,
	},
	typeButton: {
		padding: 10,
		fontSize: 16,
		fontWeight: 'bold',
		cursor: 'pointer',
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
	},
	buttonActive: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	mesReferencia: {
		padding: 5,
		fontVariant: 'small-caps',
	},
	mesReferenciaAtual: {
		fontWeight: 'bold',
		fontSize: 16,
		cursor: 'pointer',
	},
	empty: {
		textAlign: 'center',
		fontSize: 18,
		fontWeight: 'bold',
	},
}));

const DashboardRepresentante = props => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const token = useSelector(store => store.auth.token);
	const usuarioId = useSelector(store => store.auth.usuario.id);
	const viewMarkup = useSelector(store =>
		Isset(() => store.system.params['12'].valor) ? store.system.params['12'].valor : true
	);

	// const inputMes = useRef(null);

	// const [ano, setAno] = useState(moment().format('YYYY'));
	// const [mes, setMes] = useState(moment().format('MM'));
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(true);
	const [typeSelected, setTypeSelected] = useState(1);
	// const [mesReferencia, setMesReferencia] = useState(`${mes} / ${ano}`);
	// const [mesReferenciaAtivo, setMesReferenciaAtivo] = useState(false);

	const [dataReferencia, setDataReferencia] = useState({
		start: new Date(),
		end: new Date(),
		label: 'Clique para selecionar a data',
		...filtersCache,
	});

	const getComissoes = async () => {
		setLoading(true);
		const res = await axios.get(
			`${config.get('apiUrl')}vendas/porRepresentante/${usuarioId}?start=${moment(dataReferencia.start).format(
				'YYYY-MM-DD'
			)}&end=${moment(dataReferencia.end).format('YYYY-MM-DD')}`,
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			setData(res.data.result);
		}
		setLoading(false);
	};

	useEffect(() => {
		getComissoes();
	}, [dataReferencia.start, dataReferencia.end]);

	// const onChangeMesReferencia = e => {
	// 	let { value } = e.target;
	// 	value = Mask.OnlyNumber(value);

	// 	const m = value.substr(0, 2);
	// 	const a = value.substr(2, 4);

	// 	if (a) {
	// 		setMesReferencia(`${m} / ${a}`);
	// 	} else {
	// 		setMesReferencia(`${m}`);
	// 	}
	// };

	// const onBlurMesReferencia = () => {
	// 	const value = Mask.OnlyNumber(mesReferencia);

	// 	const m = value.substr(0, 2);
	// 	const a = value.substr(2, 4);

	// 	if (a > 2010 && a < 3000 && m > 0 && m <= 12) {
	// 		setMes(m);
	// 		setAno(a);
	// 		setMesReferenciaAtivo(false);
	// 	}
	// };

	return (
		<div className={classes.dashboard}>
			<div className={classes.mesReferencia}>
				<Grid container>
					<Grid item md={3}>
						Período de Referência
						<div style={{ marginLeft: '-15px' }} align="center">
							<ZDateRangePicker
								onClose={date => {
									const data = { ...dataReferencia, start: date.startDate, end: date.endDate };

									dispatch(setFilters(pathnameSlug, data));

									setDataReferencia(data);
								}}
								startDate={dataReferencia.start}
								endDate={dataReferencia.end}
								label={dataReferencia.label}
								clearDate={() =>
									setDataReferencia({
										start: new Date().setHours(0, 0, 0, 0),
										end: new Date(),
										label: 'Clique para selecionar a data',
									})
								}
							/>
						</div>
					</Grid>
				</Grid>
			</div>
			<Grid container justifyContent="center" spacing={2}>
				<Grid item xs>
					<Paper className={classes.indicador}>
						<div className={classes.indicadorTitle}>Total de Vendas</div>
						<div className={classes.indicadorValue}>{data.totalVendas}</div>
					</Paper>
				</Grid>
				<Grid item xs>
					<Paper className={classes.indicador}>
						<div className={classes.indicadorTitle}>Valor Total de Vendas</div>
						<div className={classes.indicadorValue}>{Formatter.Real(data.valorVendas)}</div>
					</Paper>
				</Grid>
				{viewMarkup && (
					<Grid item xs>
						<Paper className={classes.indicador}>
							<div className={classes.indicadorTitle}>Valor Markup</div>
							<div className={classes.indicadorValue}>
								{Formatter.Real(data.valorMarkup)}
								{/* <small>Em Atualização</small> */}
							</div>
						</Paper>
					</Grid>
				)}
				<Grid item xs>
					<Paper className={classes.indicador}>
						<div className={classes.indicadorTitle}>Valor de Comissão</div>
						<div className={classes.indicadorValue}>
							{Formatter.Real(data.valorComissao)}
							{/* <small>Em Atualização</small> */}
						</div>
					</Paper>
				</Grid>
				{viewMarkup && (
					<Grid item xs>
						<Paper className={classes.indicador}>
							<div className={classes.indicadorTitle}>Comissão</div>
							<div className={classes.indicadorValue}>{`${Formatter.Real(
								data.mediaComissao,
								false
							)} %`}</div>
						</Paper>
					</Grid>
				)}
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<Paper className={classes.lista}>
						<div className={classes.listaTitle}>Por Estabelecimento</div>
						<div className={classes.listaEstabelecimento}>
							<Grid container className={classes.listaHeader}>
								<Grid item xs>
									Estabelecimento
								</Grid>
								<Grid item xs={3}>
									{viewMarkup ? 'Valor Markup' : 'Valor Vendas'}
								</Grid>
								<Grid item xs={3}>
									Valor de Comissão
								</Grid>
								{viewMarkup && (
									<Grid item xs={2}>
										Comissão
									</Grid>
								)}
							</Grid>
							{loading ? (
								<ZLoader height={200} />
							) : (
								<div className={classes.scrollY}>
									{Isset(() => data.porEstabelecimento) && data.porEstabelecimento.length > 0 ? (
										<>
											{data.porEstabelecimento.map(estabelecimento => (
												<Grid
													key={`comissaoEC -${estabelecimento.id}`}
													className={classes.listaRow}
													container
												>
													<Grid item xs>
														{estabelecimento.nome}
													</Grid>
													<Grid item xs={3}>
														{Formatter.Real(
															viewMarkup
																? estabelecimento.valorMarkup
																: estabelecimento.valorVendas
														)}
														{/* <small>Em Atualização</small> */}
													</Grid>
													<Grid item xs={3}>
														{Formatter.Real(estabelecimento.valorComissao)}
														{/* <small>Em Atualização</small> */}
													</Grid>
													{viewMarkup && (
														<Grid item xs={2}>
															{`${Formatter.Real(estabelecimento.taxaComissao, false)} %`}
														</Grid>
													)}
												</Grid>
											))}
										</>
									) : (
										<div className={classes.empty}>Nenhum Resultado</div>
									)}
								</div>
							)}
						</div>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Paper className={classes.lista}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Paper
									className={clsx(classes.typeButton, { [classes.buttonActive]: typeSelected === 1 })}
									onClick={() => setTypeSelected(1)}
								>
									Por Bandeira
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper
									className={clsx(classes.typeButton, { [classes.buttonActive]: typeSelected === 2 })}
									onClick={() => setTypeSelected(2)}
								>
									Por Tipo de Pagamento
								</Paper>
							</Grid>
						</Grid>

						<div className={classes.listagemWrapper}>
							<Grid container className={classes.listaHeader} style={{ width: '50%' }}>
								<Grid item xs={4}></Grid>

								<Grid item xs={4}>
									{viewMarkup ? 'Valor Markup' : 'Valor Vendas'}
								</Grid>
								<Grid item xs={4}>
									Valor de Comissão
								</Grid>
							</Grid>
							<div className={clsx(classes.listagem, { [classes.active]: typeSelected === 1 })}>
								{loading ? (
									<ZLoader height={200} />
								) : (
									<div className={classes.scrollY2}>
										{Isset(() => data.porBandeira) && data.porBandeira.length > 0 ? (
											<>
												{data.porBandeira.map(bandeira => (
													<Grid
														key={`comissaoEC -${bandeira.id}`}
														className={classes.listaRow}
														container
													>
														<Grid item xs={4}>
															{bandeira.titulo}
														</Grid>
														<Grid item xs={4}>
															{Formatter.Real(
																viewMarkup ? bandeira.valorMarkup : bandeira.valorVendas
															)}
															{/* <small>Em Atualização</small> */}
														</Grid>
														<Grid item xs={4}>
															{Formatter.Real(bandeira.valorComissao)}
															{/* <small>Em Atualização</small> */}
														</Grid>
													</Grid>
												))}
											</>
										) : (
											<div className={classes.empty}>Nenhum Resultado</div>
										)}
									</div>
								)}
							</div>
							<div className={clsx(classes.listagem, { [classes.active]: typeSelected === 2 })}>
								{loading ? (
									<ZLoader height={200} />
								) : (
									<div className={classes.scrollY2}>
										{Isset(() => data.porTipoPagamento) && data.porTipoPagamento.length > 0 ? (
											<>
												{data.porTipoPagamento.map(tipoPagamento => (
													<Grid
														key={`comissaoEC -${tipoPagamento.id}`}
														className={classes.listaRow}
														container
													>
														<Grid item xs={4}>
															{tipoPagamento.titulo}
														</Grid>
														<Grid item xs={4}>
															{Formatter.Real(
																viewMarkup
																	? tipoPagamento.valorMarkup
																	: tipoPagamento.valorVendas
															)}
															{/* <small>Em Atualização</small> */}
														</Grid>
														<Grid item xs={4}>
															{Formatter.Real(tipoPagamento.valorComissao)}
															{/* <small>Em Atualização</small> */}
														</Grid>
													</Grid>
												))}
											</>
										) : (
											<div className={classes.empty}>Nenhum Resultado</div>
										)}
									</div>
								)}
							</div>
						</div>
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
};

export default DashboardRepresentante;
