import React from 'react';
import { InputLabel, TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	input: {
		maxWidth: 150,
		verticalAlign: 'bottom',
		marginTop: 20,
		marginBottom: 20,
	},
}));

const ZModalTextField = props => {
	const classes = useStyles();
	return (
		<>
			<InputLabel className={classes.label}>{props.placeholder}</InputLabel>
			<TextField
				InputProps={{
					startAdornment: <InputAdornment position="start">R$</InputAdornment>,
					className: classes.input,
				}}
				type="number"
				onChange={props.handleChange}
			/>
		</>
	);
};

export default ZModalTextField;
