import React, { useState, useEffect } from 'react';
import { Grid, Paper, TableRow, TableHead, TableCell, TableBody, Table } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import config from 'react-global-configuration';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ZTitle, ZFiltros, ZMenuOptions, ZPagination } from '../system';
import { Mask } from '../../utils';
import { FaFilter } from 'react-icons/fa';

const StatusPreCadastro = {
	0: 'Aguardando',
	1: 'Reprovado',
	2: 'Aprovado',
};

const PreCadastro = props => {
	const history = useHistory();

	const token = useSelector(store => store.auth.token);

	const getData = async () => {
		let res = await axios.get(`${config.get('apiV2')}/estabelecimentos/pre_cadastros`, {
			headers: { Authorization: `Bearer ${token}` },
			params: {
				page,
				limit: rowsPerPage,
				filters: {
					status: filtros.statusPreCadastro,
				},
			},
		});

		if (!res.data.success) return false;

		setData({
			paginas: res.data.paginas,
			pre_cadastro: res.data.pre_cadastros,
			quantidade: res.data.quantidade,
			totalRows: res.data.quantidade,
		});
	};

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const [data, setData] = useState({
		pre_cadastro: [],
		quantiade: 0,
		paginas: 0,
		totalRows: 0,
	});
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	// const [totalRows, setTotalRows] = useState(0);
	const [filtros, setFiltros] = useState({
		statusPreCadastro: '0',
	});

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, rowsPerPage, filtros]);

	const filtrosChange = data => {
		setPage(1);
		setFiltros({
			...filtros,
			...data,
		});
	};

	const statusTshield = status => {
		switch (status) {
			case 'Pendente':
				return '#c0c0c0';
			case 'Aprovado':
				return '#5DAD3D';
			case 'Reprovado':
				return '#850514';
			default:
				return '#c0c0c0';
		}
	};

	return (
		<Grid>
			<ZTitle title="Pré Cadastro" />
			<Grid container>
				<Grid item xs={12}>
					<Paper
						style={{
							position: 'relative',
							marginBottom: 10,
							borderRadius: 20,
							padding: '5px 10px',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<FaFilter />
						<ZFiltros
							onChange={filtrosChange}
							statusPreCadastro={{ defaultValue: filtros.statusPreCadastro }}
						/>
					</Paper>
				</Grid>
			</Grid>

			<Grid container>
				<Grid item xs={12}>
					<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 30px)' }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell style={{ minWidth: 100, textAlign: 'center' }}>Usuário</TableCell>
									<TableCell style={{ minWidth: 200, textAlign: 'center' }}>Nome</TableCell>
									<TableCell style={{ minWidth: 220, textAlign: 'center' }}>E-mail</TableCell>
									<TableCell style={{ minWidth: 200, textAlign: 'center' }}>CPF/CNPJ</TableCell>
									<TableCell style={{ minWidth: 180, textAlign: 'center' }}>Telefone</TableCell>
									<TableCell style={{ minWidth: 180, textAlign: 'center' }}>Celular</TableCell>
									<TableCell style={{ minWidth: 200, textAlign: 'center' }}>Data</TableCell>
									<TableCell style={{ minWidth: 120, textAlign: 'center' }}>Status</TableCell>
									<TableCell style={{ minWidth: 100, textAlign: 'center' }}>Opções</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data.pre_cadastro.map((value, key) => {
									return (
										<TableRow
											key={key}
											style={{ borderLeft: `5px solid ${statusTshield(value.status_tshield)}` }}
										>
											<TableCell component="th" scope="row">
												{value.usuario_nome}
											</TableCell>
											<TableCell align="center">
												{value.nome_fantasia}
												{value.razao_social && (
													<div>
														<small>{value.razao_social}</small>
													</div>
												)}
											</TableCell>
											<TableCell align="center">{value.email}</TableCell>
											<TableCell align="center">
												{value.documento.length > 11
													? Mask.Cnpj(value.documento)
													: Mask.Cpf(value.documento)}
											</TableCell>
											<TableCell align="center">{Mask.Telefone(value.telefone || '')}</TableCell>
											<TableCell align="center">{Mask.Celular(value.celular || '')}</TableCell>
											<TableCell align="center">
												{moment(value.created).format('DD/MM/YYYY HH:mm:ss')}
											</TableCell>
											<TableCell align="center">{StatusPreCadastro[value.status]}</TableCell>
											<TableCell>
												<ZMenuOptions
													data={[
														{
															title: 'Visualizar',
															onClick: () =>
																history.push(
																	'/estabelecimentos/pre-cadastro/visualizar/' +
																		value.id
																),
															visible: value.status === 0,
														},
														{
															title: 'Analisar',
															onClick: () =>
																history.push(
																	'/estabelecimentos/pre-cadastro/analisar/' +
																		value.id
																),
															visible: value.status === 0,
														},
														// {
														// 	title: 'Alterar Status',
														// 	onClick: () => {
														// 		handleModalOpen(value.id, value.status);
														// 	},
														// 	visible: value.status !== 2,
														// },
													]}
												/>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
							<ZPagination
								count={data.totalRows}
								rowsPerPage={rowsPerPage}
								page={page - 1}
								onChangePage={onChangePage}
								onChangeRowsPerPage={onChangeRowsPerPage}
							/>
						</Table>
					</Paper>
				</Grid>
			</Grid>
			{/* <ZModalStatus
				title="Alterar Status"
				label="Status"
				options={[
					{ id: 0, name: 'Aguardando' },
					{ id: 1, name: 'Reprovado' },
					{ id: 2, name: 'Aprovado' },
				]}
				description="Selecione o status do pré cadastro."
				open={dataModal.open}
				value={dataModal.status}
				handleClose={() => handleModalClose()}
				handleOnSave={() => handleOnSave()}
				handleOnChange={handleOnChangeStatus}
			/> */}
		</Grid>
	);
};

export default PreCadastro;
