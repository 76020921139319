/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios';
import { Grid, Paper } from '@material-ui/core';
import { ZEmpty, ZFiltros, ZLoader } from '../system';
import { FaFilter, FaFileExcel } from 'react-icons/fa';
import clsx from 'clsx';
import { Isset, Formatter } from '../../utils/';
import FileDownload from 'js-file-download';
import { addMessage } from '../../store/alert';
import config from 'react-global-configuration';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	export: {
		position: 'absolute',
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 18,
	},
	tableSubHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 16,
	},
	tableDateRow: {
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		backgroundColor: `${theme.palette.primary.main}aa`,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		paddingLeft: 20,
	},
	tableRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		marginBottom: 2,
		backgroundColor: `${theme.palette.primary.main}1a`,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	tableSubRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	tableRowSelected: {
		backgroundColor: `${theme.palette.primary.main}3c`,
	},
	vendas: {
		marginBottom: 10,
	},
}));

const RelatoriosMarkupPorEstabelecimento = props => {
	const token = useSelector(store => store.auth.token);
	const classes = useStyles();

	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	const queryFilter = {
		mes: format(new Date(), 'MM'),
		ano: format(new Date(), 'YYYY'),
	};

	const [filtros, setFiltros] = useState(queryFilter);
	const filtrosChange = async data => {
		setFiltros(data);
	};

	useEffect(() => {
		const getMarkup = async () => {
			setLoading(true);
			const res = await Axios.get(`${config.get('apiUrl')}vendas/markupPorEstabelecimento`, {
				params: {
					filtros,
				},
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				setData(res.data.result);
			}
			setLoading(false);
		};

		getMarkup();
	}, [filtros]);

	const exportXls = async () => {
		const result = await Axios.get(`${config.get('apiUrl')}export/relatorios/markupPorEstabelecimento`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params: {
				filtros,
			},
		});

		if (result.status !== 200 || !Isset(() => result.data)) {
			return dispatch(addMessage({ type: 'error', message: 'Erro ao exportar relatório.' }));
		}

		const { 'content-disposition': contentDisposition, 'content-type': contentType } = result.headers;
		let filename = 'markupPorEstabelecimento.xlsx';
		if (contentDisposition.indexOf('filename="') !== -1) {
			filename = contentDisposition.slice(
				contentDisposition.indexOf('filename="') + 10,
				contentDisposition.length - 1
			);
		}

		FileDownload(result.data, filename, contentType);
	};

	return (
		<Grid className={classes.vendas}>
			<div className={classes.header}>Markup Por Estabelecimento</div>

			<Paper
				style={{
					position: 'relative',
					marginBottom: 10,
					borderRadius: 20,
					padding: '5px 10px',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<FaFilter />
				<ZFiltros
					onChange={filtrosChange}
					mes={{
						defaultValue: filtros.mes,
					}}
					ano={{
						defaultValue: filtros.ano,
					}}
				/>
				<FaFileExcel className={classes.export} onClick={exportXls} />
			</Paper>

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper style={{ padding: 15 }}>
						{loading ? (
							<ZLoader height={200} />
						) : (
							<>
								{data.length > 0 ? (
									<Grid container className={classes.vendas}>
										<Grid container item className={classes.tableHeader}>
											<Grid item xs={4}>
												Estabelecimento
											</Grid>
											<Grid item xs={4}>
												Quantidade
											</Grid>
											<Grid item xs={4}>
												Taxa
											</Grid>
										</Grid>
										{data.map((estabelecimento, key) => (
											<React.Fragment key={key}>
												<Grid container item className={clsx(classes.tableRow)}>
													<Grid item xs={4}>
														{estabelecimento.nomeFantasia}
													</Grid>
													<Grid item xs={4}>
														{estabelecimento.quantidadeVendas}
													</Grid>
													<Grid item xs={4}>
														{Formatter.Real(
															+estabelecimento.markup + +estabelecimento.planoTaxa
														)}
													</Grid>
												</Grid>
											</React.Fragment>
										))}
									</Grid>
								) : (
									<ZEmpty height={200} text="Nenhuma Venda Encontrada." />
								)}
							</>
						)}
					</Paper>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default RelatoriosMarkupPorEstabelecimento;
