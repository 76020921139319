import React, { useState, useEffect } from 'react';
import { Chip, Grid, Table, TableBody, TableCell, TableHead, TableRow, Paper, Tooltip } from '@material-ui/core';
import { format } from 'date-fns';
import { ZLoader, ZTitle, ZFiltros, ZPagination } from '../system';
import moment from 'moment';
import { Formatter } from '../../utils';
import { useLocation } from 'react-router-dom';
import { FaFilter, FaEye } from 'react-icons/fa';
import axios from 'axios';
import config from 'react-global-configuration';
import { makeStyles } from '@material-ui/core/styles';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
	menuIcon: {
		cursor: 'pointer',
	},
}));

const Invoices = () => {
	const { history } = useReactRouter();
	const token = useSelector(store => store.auth.token);
	const classes = useStyles();

	const [loading, setLoading] = useState(true);
	const [invoices, setInvoices] = useState([]);

	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	// Paginação

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(30);
	const [totalRows, setTotalRows] = useState(0);

	const [filtros, setFiltros] = useState({
		mes: format(new Date(), 'MM'),
		ano: format(new Date(), 'YYYY'),
		omni: '',
		omni2: '',
		switch: false,
		statusInvoice: '',
		...filtersCache,
	});

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const getInvoices = () => {
		setLoading(true);
		axios
			.get(`${config.get('apiUrl')}invoices`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					page,
					limit: rowsPerPage,
					filters: {
						...filtros,
					},
				},
			})
			.then(res => res.data)
			.then(data => {
				setLoading(false);
				if (data.success) {
					setInvoices(data.invoices);
					setTotalRows(data.quantidade);
				}
			});
	};

	const filtrosChange = async data => {
		setPage(1);
		if (data.omni) {
			data.omni = data.omni.replace(/[^a-z0-9]/gi, '');
		}
		setFiltros(data);
	};

	useEffect(() => {
		getInvoices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtros]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(getInvoices, [page, rowsPerPage]);

	const getStatusLabel = status => {
		switch (+status) {
			case 1:
				return (
					<Chip
						size="small"
						label="Pendente" // amarelo
						style={{
							backgroundColor: '#E9C46A',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
			case 2:
				return (
					<Chip
						size="small"
						label="Pago" // verde
						style={{
							backgroundColor: '#2A9D8F',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
			case 3:
				return (
					<Chip
						size="small"
						label="Cancelado" // vermelho
						style={{
							backgroundColor: '#DF5937',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
			case 4:
				return (
					<Chip
						size="small"
						label="Isento" // azul
						style={{
							backgroundColor: '#154FC7',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
			case 5:
				return (
					<Chip
						size="small"
						label="Estornado" // laranja
						style={{
							backgroundColor: '#F4A261',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
			case 6:
				return (
					<Chip
						size="small"
						label="Erro" // vermelhao
						style={{
							backgroundColor: '#DF5937',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
			default:
				return (
					<Chip
						size="small"
						label="Não Mapeado"
						style={{
							backgroundColor: '#000000',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
		}
	};

	return (
		<Grid container>
			<ZTitle title="Cobranças" display="flex" textAlign="center" fontSize="55px" />

			<Grid item xs={12}>
				<Paper
					style={{
						position: 'relative',
						marginBottom: 10,
						borderRadius: 20,
						padding: '5px 10px',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<FaFilter />
					<ZFiltros
						onChange={filtrosChange}
						omni2={{
							default: filtros.omni2,
							placeholder: 'Estabelecimento',
						}}
						omni={{
							default: filtros.omni,
							placeholder: 'CPF/CNPJ',
						}}
						statusInvoice={{
							defaultValue: filtros.statusInvoice,
							placeholder: 'Status',
							multiple: true,
						}}
						mes={{
							defaultValue: filtros.mes,
							disabled: filtros.switch,
						}}
						ano={{
							defaultValue: filtros.ano,
							disabled: filtros.switch,
						}}
						switch={{
							label: 'Todo Período',
							defaultValue: filtros.switch,
						}}
					/>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				{loading ? (
					<ZLoader height="200px" size={40} />
				) : (
					<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 30px)' }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell style={{ minWidth: 150 }}>Estabelecimento</TableCell>
									<TableCell style={{ minWidth: 150 }} align="right">
										Cobrança Iniciada
									</TableCell>
									<TableCell style={{ minWidth: 150 }} align="right">
										CNPJ
									</TableCell>
									<TableCell style={{ minWidth: 100 }} align="right">
										Referência
									</TableCell>
									<TableCell style={{ minWidth: 100 }} align="right">
										Data de Pagamento
									</TableCell>
									<TableCell style={{ minWidth: 100 }} align="right">
										Cobrado
									</TableCell>
									<TableCell style={{ minWidth: 100 }} align="right">
										Pago
									</TableCell>
									<TableCell style={{ minWidth: 100 }} align="right">
										Status
									</TableCell>
									<TableCell style={{ minWidth: 100 }} align="left">
										Visualizar
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<>
									{invoices.map((invoice, key) => {
										return (
											<TableRow hover key={key}>
												<TableCell component="th" scope="row">
													{invoice.estabelecimento.nome_fantasia}
												</TableCell>
												<TableCell align="left">
													{invoice.chargestart_date
														? moment(invoice.chargestart_date).format('DD/MM/YYYY')
														: ''}
												</TableCell>
												<TableCell align="right">
													{invoice.estabelecimento.estabelecimentos_documentos[0].documento <=
													11
														? Formatter.Cpf(
																invoice.estabelecimento.estabelecimentos_documentos[0]
																	.documento
														  )
														: Formatter.Cnpj(
																invoice.estabelecimento.estabelecimentos_documentos[0]
																	.documento
														  )}
												</TableCell>
												<TableCell align="right">
													{invoice.reference_month}/{invoice.reference_year}
												</TableCell>
												<TableCell align="right">
													{invoice.payment_date
														? moment(invoice.payment_date).format('DD/MM/YYYY')
														: ''}
												</TableCell>
												<TableCell align="right">R$ {invoice.amount}</TableCell>
												<TableCell align="right">R$ {invoice.settled}</TableCell>
												<TableCell align="right">{getStatusLabel(invoice.status)}</TableCell>
												<TableCell>
													{' '}
													<Tooltip title="Visualizar">
														<span>
															<FaEye
																className={classes.menuIcon}
																onClick={() => {
																	history.push('/invoices/' + invoice.id);
																}}
															/>
														</span>
													</Tooltip>
												</TableCell>
											</TableRow>
										);
									})}
								</>
							</TableBody>
							<ZPagination
								count={totalRows}
								rowsPerPage={rowsPerPage}
								page={page - 1}
								onChangePage={onChangePage}
								onChangeRowsPerPage={onChangeRowsPerPage}
							/>
						</Table>
					</Paper>
				)}
			</Grid>
		</Grid>
	);
};

export default Invoices;
