import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	dialog: {},
	title: {
		textAlign: 'center',
		fontSize: 16,
		fontWeight: 'bold',
	},
	downloadButton: {
		background: `${theme.palette.primary.main}67`,
		margin: 2,
		padding: 2,
	},
	newExport: {
		margin: 'auto',
		marginTop: 10,
		textAlign: 'center',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
}));
