import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import axios from 'axios';
import config from 'react-global-configuration';
import { Grid, Paper, Tooltip, Zoom, Divider, IconButton } from '@material-ui/core';
import { ZLoader } from '../system';
import { Isset } from '../../utils';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
	contasBancarias: {},
	novaContaBancaria: {
		textAlign: 'center',
		textTransform: 'uppercase',
		margin: 8,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	novaContaBancariaFields: {
		display: 'none',
		'&.show': {
			display: 'block',
		},
	},
	box: {
		position: 'relative',
		padding: 10,
		borderTop: `solid 4px transparent`,
	},
	boxAtivo: {
		borderTop: `solid 4px ${theme.palette.primary.main}`,
	},
	ativo: {
		position: 'absolute',
		right: 10,
		top: 5,
		textTransform: 'uppercase',
		fontSize: 11,
		fontWeight: 'bold',
	},
	formControl: {
		width: '100%',
		marginTop: 4,
	},
	salvarBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		margin: 8,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	textField: {
		width: '100%',
		marginTop: 4,
	},
	tipoContaBancaria: {
		padding: 20,
		fontSize: 12,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: 700,
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 6,
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
		[theme.breakpoints.down('md')]: {
			padding: 10,
		},
	},
	icon: {
		color: theme.palette.primary.main,
	},
	updateCheck: {
		position: 'absolute',
		top: 10,
		right: 10,
	},
	field: {
		padding: 5,
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	configUpdateBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		paddingBottom: 15,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	politicaRecebimento: {
		padding: 10,
		minHeight: 150,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		cursor: 'pointer',
	},
	politicaRecebimentoAtivo: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	politicaRecebimentoTitle: {
		textAlign: 'center',
		fontWeight: 'bold',
	},
	politicaRecebimentoDescription: {
		fontSize: 13,
		paddingTop: 3,
	},
}));

const ContaBancaria = props => {
	const token = useSelector(state => state.auth.token);
	const estabelecimento = useSelector(state => state.auth.estabelecimento);

	const usuario = useSelector(state => state.auth.usuario);
	const { id: estabelecimentoAdminId } = useSelector(state => state.admin.estabelecimento) || {};

	const canTransferencia = useSelector(store =>
		Isset(() => store.system.params['22'].valor) ? store.system.params['22'].valor : null
	);

	const classes = useStyles();

	const [loading, setLoading] = useState(true);
	const [setShowFields] = useState(false);
	const [setBancos] = useState([]);
	const [contasBancarias, setContasBancarias] = useState([]);
	const [politicaRecebimento, setPoliticaRecebimento] = useState(null);
	const [setEstabelecimentoDocumento] = useState({
		documento: null,
		tipoDocumento: null,
	});

	const canViewPoliticaRecebimento = usuario.grupo_id <= 2 && canTransferencia;

	const estabelecimentoId = Isset(() => props.estabelecimentoId)
		? props.estabelecimentoId
		: estabelecimentoAdminId
		? estabelecimentoAdminId
		: estabelecimento.id;

	const getContas = async () => {
		setLoading(true);
		const result = await axios.get(
			`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/contas_bancarias`,
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (result.data.success) {
			setContasBancarias(result.data.contasBancarias);
		}

		setLoading(false);
	};

	const getPoliticaRecebimento = async () => {
		const res = await axios.get(
			`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/politicaRecebimento`,
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success && Isset(() => res.data.result.transfer_enabled)) {
			setPoliticaRecebimento(res.data.result.transfer_enabled);
		}
	};

	const getEstabelecimento = async () => {
		const res = await axios.post(
			`${config.get('apiUrl')}graphql`,
			{
				query: `
			query Estabelecimentos {
				estabelecimento(id: ${estabelecimentoId}) {
					nomeFantasia, documento, tipoDocumento
				}
			}
		`,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		if (res.status === 200 && Isset(() => res.data.data.estabelecimento)) {
			const { documento, tipoDocumento } = res.data.data.estabelecimento;
			setEstabelecimentoDocumento({ documento, tipoDocumento });
		}
	};

	useEffect(() => {
		const getBancos = async () => {
			const result = await axios.get(`${config.get('apiUrl')}bancos`);

			if (result.data.success) {
				setBancos(result.data.bancos);
				setShowFields(false);
			}
		};

		getContas();
		getBancos();
		getPoliticaRecebimento();
		getEstabelecimento();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {
	//     validate();
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [data]);

	const politicaRecebimentoClick = async automatico => {
		setLoading(true);
		const res = await axios.post(
			`${config.get('apiUrl')}estabelecimentos/${estabelecimentoId}/politicaRecebimento/${automatico ? 1 : 0}`,
			{},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			await getPoliticaRecebimento();
		}
		setLoading(false);
	};

	if (loading) {
		return <ZLoader height={200} />;
	}

	return (
		<div className={classes.contasBancarias}>
			<Zoom in>
				<>
					{process.env.REACT_APP_ENV === 'development' && console.log(isMobile)}
					{canViewPoliticaRecebimento && (
						<>
							<Grid justifyContent="center" container spacing={2}>
								<Grid item xs={4}>
									{isMobile && (
										<Tooltip
											PopperProps={{
												disablePortal: true,
											}}
											placement="top-end"
											TransitionComponent={Zoom}
											TransitionProps={{ timeout: 20 }}
											disableFocusListener
											disableHoverListener
											title="Receba o valor de suas vendas na sua conta bancária ou cartão pré-pago
												de acordo com o calendário de recebimento do seu plano de taxas."
										>
											<IconButton>
												<AiOutlineQuestionCircle />
											</IconButton>
										</Tooltip>
									)}
									<Paper
										className={clsx(classes.politicaRecebimento, {
											[classes.politicaRecebimentoAtivo]: politicaRecebimento,
										})}
										onClick={() => !politicaRecebimento && politicaRecebimentoClick(true)}
									>
										<div className={classes.politicaRecebimentoTitle}>
											{isMobile ? 'Automático' : 'Pagamento Automático'}
										</div>

										{!isMobile && (
											<div className={classes.politicaRecebimentoDescription}>
												Receba o valor de suas vendas na sua conta bancária ou cartão pré-pago
												de acordo com o calendário de recebimento do seu plano de taxas.
											</div>
										)}
									</Paper>
								</Grid>
								<Grid item xs={4}>
									{isMobile && (
										<Tooltip
											PopperProps={{
												disablePortal: true,
											}}
											placement="top-end"
											TransitionComponent={Zoom}
											TransitionProps={{ timeout: 20 }}
											disableFocusListener
											disableHoverListener
											title="O valor de suas vendas fica na OnePay.
											Faça transferências para outras instituições sob demanda quando quiser."
										>
											<IconButton>
												<AiOutlineQuestionCircle />
											</IconButton>
										</Tooltip>
									)}
									<Paper
										className={clsx(classes.politicaRecebimento, {
											[classes.politicaRecebimentoAtivo]: !politicaRecebimento,
										})}
										onClick={() => politicaRecebimento && politicaRecebimentoClick(false)}
									>
										<div className={classes.politicaRecebimentoTitle}>
											{isMobile ? 'Manual' : 'Pagamento Manual'}
										</div>
										{!isMobile && (
											<div className={classes.politicaRecebimentoDescription}>
												O valor de suas vendas fica na OnePay. Faça transferências para outras
												instituições sob demanda quando quiser.
											</div>
										)}
									</Paper>
								</Grid>
							</Grid>

							<Divider style={{ margin: 10 }} />
						</>
					)}

					<Grid container spacing={2}>
						{contasBancarias.map((c, i) => (
							<Grid key={i} item xs={12} sm={6} md={3}>
								<Paper className={clsx(classes.box, { [classes.boxAtivo]: c.ativo })}>
									{c.ativo && <div className={classes.ativo}>Ativo</div>}
									<div className={classes.field}>
										<div className={classes.label}>Nome do Titular</div>
										<div>{c.nomeTitular}</div>
									</div>
									<div className={classes.field}>
										<div className={classes.label}>Tipo de Conta</div>
										<div>{c.tipoContaBancaria === 1 ? 'Conta Corrente' : 'Conta Poupança'}</div>
									</div>
									<div className={classes.field}>
										<div className={classes.label}>Banco</div>
										<div>{c.banco}</div>
									</div>
									<div className={classes.field}>
										<div className={classes.label}>Agência</div>
										<div>{c.agencia}</div>
									</div>
									<div className={classes.field}>
										<div className={classes.label}>Conta</div>
										<div>{c.conta}</div>
									</div>
								</Paper>
							</Grid>
						))}
					</Grid>
				</>
			</Zoom>
		</div>
	);
};

export default ContaBancaria;
