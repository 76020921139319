import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	fullWidth: {
		width: '100%',
	},
	estabelecimento: {
		padding: '6px 6px',
		marginBottom: 10,
	},
	estabelecimentoValor: {
		'&.porcentagem input': {
			textAlign: 'right',
		},
	},
	estabelecimentoIcone: {
		border: 'solid 1px #abc',
		marginRight: 2,
		marginLeft: 2,
		marginTop: 16,
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}`,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	estabelecimentoError: {
		fontSize: 12,
		color: '#e53935',
	},
	label: {
		textAlign: 'center',
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	removeButton: {
		minHeight: 26,
		height: 26,
		width: 26,
		backgroundColor: '#e53935',
		color: 'white',
	},
	novaRegraSplit: {
		textAlign: 'center',
		textTransform: 'uppercase',
		margin: 8,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	box: {
		textTransform: 'uppercase',
		fontSize: 12,
		position: 'relative',
		padding: 10,
		height: 110,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}55`,
		},
		'& .value': {
			fontWeight: 700,
			fontSize: 13,
		},
	},
	splitAtivo: {
		position: 'absolute',
		top: -8,
		right: 10,
		fontWeight: 700,
		color: '#e53935',
		'&.ativo': {
			color: 'green',
		},
	},
	divider: {
		margin: 4,
	},
	removerButton: {
		backgroundColor: '#e53935',
		color: 'white',
	},
	statusButton: {
		backgroundColor: '#e53935',
		color: 'white',
		'&.ativar': {
			backgroundColor: 'green',
		},
	},
	error: {
		padding: 8,
		borderRadius: 5,
		backgroundColor: '#e53935',
		color: 'white',
		marginBottom: 10,
	},
	subheader: {
		fontWeight: 700,
		fontSize: 18,
		fontVariant: 'small-caps',
		letterSpacing: '0.02em',
		margin: '20px 8px',
		marginTop: -30,
	},
	valorLiquido: {
		'& .MuiFormControlLabel-label': {
			fontSize: 11,
			fontWeight: 'bold',
			textAlign: 'center',
		},
	},
}));
