import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import ZText from './ZText';
import ZSelect from '../system/ZSelect';
import { Mask } from '../../utils';
import Cards from 'react-credit-cards';
import creditCardType from 'credit-card-type';
import 'react-credit-cards/es/styles-compiled.css';

const ZCard = props => {
	const [focus, setFocus] = useState(null);
	const [data, setData] = useState({
		titular: props.name,
		numero: props.number,
		codigoSeguranca: props.cvc,
		vencimento: props.expiry,
		parcela: props.installment,
		flag: '',
	});

	const handleChangeFlag = flag => {
		let issuer = creditCardType(data.numero);
		if (flag === 'unknown') {
			flag = issuer[0] ? issuer[0].type : 'unknown';
		}
		props.test(flag);
		data.flag = flag;
	};

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChangeCard = name => value => {
		setData({
			...data,
			[name]: value,
		});
	};

	return (
		<Grid container direction={props.direction || 'row'} justifyContent="center" alignItems="center">
			<Grid
				item
				xs={props.cardGrid ? props.cardGrid.xs : 12}
				sm={props.cardGrid ? props.cardGrid.sm : 12}
				md={props.cardGrid ? props.cardGrid.md : 5}
				lg={props.cardGrid ? props.cardGrid.lg : 5}
				style={{
					marginBottom: props.cardGrid && props.cardGrid.marginBottom ? props.cardGrid.marginBottom : 0,
				}}
			>
				<Cards
					number={data.numero}
					name={data.titular}
					expiry={data.vencimento}
					cvc={data.codigoSeguranca}
					issuer={data.flag}
					focused={focus}
					locale={{ valid: 'Validade' }}
					placeholders={{ name: 'SEU NOME AQUI' }}
					callback={call => handleChangeFlag(call.issuer)}
				/>
			</Grid>
			<Grid
				item
				style={{
					marginTop:
						props.containerInputsGrid && props.containerInputsGrid.marginTop
							? props.containerInputsGrid.marginTop
							: 0,
				}}
				xs={props.containerInputsGrid ? props.containerInputsGrid.xs : 12}
				sm={props.containerInputsGrid ? props.containerInputsGrid.sm : 12}
				md={props.containerInputsGrid ? props.containerInputsGrid.md : 7}
				lg={props.containerInputsGrid ? props.containerInputsGrid.lg : 7}
			>
				<Grid container spacing={3}>
					{props.maxInstallment && props.maxInstallment.length > 1 && (
						<Grid item xs={12}>
							<ZSelect
								options={props.maxInstallment}
								label="Parcelamento"
								required={true}
								value={data.parcela}
								onChange={e => setData({ ...data, parcela: e.target.value })}
								onBlur={e => props.installmentOnBlur(e)}
							/>
						</Grid>
					)}
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<ZText
							required={true}
							id="clientCreditCardNumber"
							label="NÚMERO"
							type="tel"
							maxLength={19}
							placeholder="Número do Cartão"
							onError={props.onError}
							onFocus={() => setFocus('number')}
							onChange={handleChangeCard('numero')}
							variant="outlined"
							onBlur={props.numberOnBlur.bind(this)}
							value={Mask.Cartao(data.numero)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<ZText
							required={true}
							id="clientCreditCardOwner"
							label="TITULAR"
							placeholder="Titular do Cartão"
							onError={props.onError}
							onFocus={() => setFocus('name')}
							variant="outlined"
							onChange={handleChangeCard('titular')}
							value={data.titular}
							onBlur={props.nameOnBlur.bind(this)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<ZText
							required={true}
							id="clientCreditCardExpiryDate"
							placeholder="MM/AA"
							type="tel"
							label="VENCIMENTO"
							onError={props.onError}
							onFocus={() => setFocus('expiry')}
							onChange={handleChangeCard('vencimento')}
							mask="99/99"
							variant="outlined"
							value={data.vencimento}
							onBlur={props.expiryOnBlur.bind(this)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<ZText
							required={true}
							id="clientCreditCardSecurityCode"
							placeholder="XXX"
							type="tel"
							label="CÓDIGO DE SEGURANÇA"
							onError={props.onError}
							variant="outlined"
							onFocus={() => setFocus('cvc')}
							onChange={handleChangeCard('codigoSeguranca')}
							value={data.codigoSeguranca}
							mask={'9999'}
							onBlur={props.cvcOnBlur.bind(this)}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

// const mapStateToProps = store => ({
//   token: store.auth.token,
//   usuario: store.auth.usuario,
// });

// const mapDispatchToProps = dispatch =>
//   bindActionCreators({ addMessage }, dispatch);

export default ZCard;
