import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { FaInfoCircle } from 'react-icons/fa';

const HtmlTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
		right: 0,
	},
}))(Tooltip);

const ParametroInfo = props => {
	return (
		<HtmlTooltip {...props}>
			<div style={{ position: 'absolute', right: 10, top: 10 }}>
				<FaInfoCircle />
			</div>
		</HtmlTooltip>
	);
};

export default ParametroInfo;
