// Action Types

export const Types = {
	MESSAGE: 'alert/MESSAGE',
};

// Reducer

const initialState = {
	type: 'info',
	message: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case Types.MESSAGE:
			// localStorage.setItem('token', action.usuario.token);
			return {
				type: action.message.type,
				message: action.message.message,
			};

		default:
			return state;
	}
};

export default reducer;

export const addMessage = message => ({
	type: Types.MESSAGE,
	message,
});
