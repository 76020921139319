/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { ZTitle, ZMenuOptions, ZPagination, ZModalConfirm, ZModal, ZText, ZLoader, ZFiltros } from '../system';
import axios from 'axios';
import config from 'react-global-configuration';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { addMessage } from '../../store/alert';
import { Isset } from '../../utils';
import { FaFilter } from 'react-icons/fa';

const Usuarios = props => {
	const { history } = useReactRouter();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const estabelecimentoAdmin = useSelector(store => store.admin.estabelecimento);
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({
		usuarios: [],
		quantiade: 0,
		paginas: 0,
	});

	const [removerUsuario, setRemoverUsuario] = useState({
		usuarioId: null,
		open: false,
	});

	const [alterarSenha, setAlterarSenha] = useState({
		usuarioId: null,
		open: false,
		password: '',
		confirmPassword: '',
	});
	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};
	const [filtros, setFiltros] = useState({
		// estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
		omni: '',
		...filtersCache,
	});

	const filtrosChange = async data => {
		data.estabelecimentoId = [estabelecimentoId];

		if (Array.isArray(data.estabelecimento)) {
			data.estabelecimentoId = [estabelecimentoId, ...data.estabelecimento];
		}

		setFiltros(data);
	};

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtros, estabelecimentoAdmin]);

	const getData = async () => {
		setLoading(true);
		let res = await axios.get(`${config.get('apiUrl')}usuarios`, {
			headers: { Authorization: `Bearer ${token}` },
			params: {
				page,
				limit: rowsPerPage,
				filters: {
					estabelecimentoId: Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : null,
					...filtros,
				},
			},
		});

		if (res.data.success) {
			setData(res.data);
			setTotalRows(res.data.quantidade);
		}
		setLoading(false);
	};

	const handleRemoverUsuario = result => {
		const usuarioId = removerUsuario.usuarioId;

		if (result) {
			axios
				.delete(`${config.get('apiUrl')}usuarios/` + usuarioId, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Usuário removido com sucesso' }));
						getData();
					} else {
						dispatch(addMessage({ type: 'error', message: data.message }));
					}

					setRemoverUsuario({ ...removerUsuario, open: false, usuarioId: null });
				});
		}
	};

	const handleOnSave = () => {
		const usuarioId = alterarSenha.usuarioId;

		if (alterarSenha.password === alterarSenha.confirmPassword) {
			axios
				.put(
					`${config.get('apiUrl')}usuarios/` + usuarioId + '/alterar-senha',
					{
						new: alterarSenha.password,
						confirm: alterarSenha.confirmPassword,
					},
					{
						headers: { Authorization: `Bearer ${token}` },
					}
				)
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Senha alterada com sucesso' }));
						// getData();
					} else {
						dispatch(addMessage({ type: 'error', message: data.message }));
					}
					setAlterarSenha({
						...alterarSenha,
						open: false,
						usuarioId: null,
						password: '',
						confirmPassword: '',
					});
				});
		}
	};

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);
	useEffect(() => {
		getData();
	}, [page, rowsPerPage]);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleAdd = () => {
		history.push('/usuarios/adicionar');
	};

	return (
		<Grid>
			<ZTitle
				title="Usuários"
				grupo={![10].includes(usuario.grupo_id)}
				button="add"
				handleOnClick={() => {
					handleAdd();
				}}
			/>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper
						style={{
							position: 'relative',
							marginBottom: 10,
							borderRadius: 20,
							padding: '5px 10px',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<FaFilter />
						<ZFiltros
							onChange={filtrosChange}
							// estabelecimento={{
							// 	default: filtros.estabelecimentoId,
							// 	multiple: true,
							// }}
							omni={{
								default: filtros.omni,
								placeholder: 'Nome/Email',
								// onBlur: filtrosChange
							}}
							// tipoTransacao={{
							// 	default: filtros.tipoTransacao
							// }}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 45px)' }}>
						{loading ? (
							<ZLoader height={200} />
						) : (
							<Table>
								<TableHead>
									<TableRow>
										<TableCell style={{ minWidth: 150, textAlign: 'center' }}>Nome</TableCell>
										<TableCell style={{ minWidth: 230, textAlign: 'center' }}>E-mail</TableCell>
										<TableCell style={{ minWidth: 70, textAlign: 'center' }}>Sexo</TableCell>
										<TableCell style={{ minWidth: 135, textAlign: 'center' }}>Grupo</TableCell>
										{usuario.grupo_id < 3 && (
											<TableCell align="right" style={{ minWidth: 80, textAlign: 'center' }}>
												Opções
											</TableCell>
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{data.usuarios.map((value, key) => {
										return (
											<TableRow key={key}>
												<TableCell component="th" scope="row">
													{value.nome}
												</TableCell>
												<TableCell align="center">{value.email}</TableCell>
												<TableCell align="center">{value.sexo}</TableCell>
												<TableCell align="center">
													{value.grupo_id === 5 && value.representantes ? (
														<Tooltip
															title={
																<>
																	{`${value.representantes}`.split(',').map(n => (
																		<div key={n}>{n}</div>
																	))}
																</>
															}
														>
															<div>{value.grupo.nome}</div>
														</Tooltip>
													) : (
														<div>{value.grupo.nome}</div>
													)}
												</TableCell>
												{usuario.grupo_id < 3 && (
													<TableCell align="right">
														<ZMenuOptions
															data={[
																{
																	title: 'Editar',
																	onClick: () => {
																		history.push('/usuarios/gerenciar/' + value.id);
																	},
																},
																{
																	title: 'Configurar Comissão',
																	visible: [5, 8].includes(value.grupo.id),
																	onClick: () => {
																		history.push(
																			'/representante/comissao/' + value.id
																		);
																	},
																},
																{
																	title: 'Alterar senha',
																	onClick: () => {
																		setAlterarSenha({
																			...alterarSenha,
																			usuarioId: value.id,
																			open: true,
																		});
																	},
																},
																{
																	title: 'Remover',
																	onClick: () => {
																		setRemoverUsuario({
																			...removerUsuario,
																			usuarioId: value.id,
																			open: true,
																		});
																	},
																},
															]}
														/>
													</TableCell>
												)}
											</TableRow>
										);
									})}
								</TableBody>
								<ZPagination
									count={totalRows}
									rowsPerPage={rowsPerPage}
									page={page - 1}
									onChangePage={onChangePage}
									onChangeRowsPerPage={onChangeRowsPerPage}
								/>
							</Table>
						)}
					</Paper>
				</Grid>
			</Grid>
			<ZModalConfirm
				title={`Remover usuário`}
				message={`Deseja remover esse usuário?`}
				open={removerUsuario.open}
				onClose={handleRemoverUsuario}
			/>
			<ZModal
				title={`Alterar senha`}
				description={`Informe a nova senha e confirme no segundo campo.`}
				open={alterarSenha.open}
				handleClose={() => setAlterarSenha({ usuarioId: null, open: false, password: '', confirmPassword: '' })}
				handleOnSave={handleOnSave}
				content={
					<div>
						<ZText
							autoFocus
							margin="dense"
							id="password"
							label="Nova senha"
							type="password"
							fullWidth
							value={alterarSenha.password}
							onBlur={e => setAlterarSenha({ ...alterarSenha, password: e.target.value })}
						/>
						<ZText
							margin="dense"
							id="password-confirm"
							label="Confirme a nova senha"
							type="password"
							fullWidth
							value={alterarSenha.confirmPassword}
							onBlur={e => setAlterarSenha({ ...alterarSenha, confirmPassword: e.target.value })}
						/>

						{alterarSenha.password &&
							alterarSenha.confirmPassword &&
							alterarSenha.confirmPassword !== alterarSenha.password && (
								<div style={{ color: 'red' }}>
									A senha precisa ser igual a confirmação. <br />
									Preencha a mesma senha em ambos campos.
								</div>
							)}
					</div>
				}
			/>
		</Grid>
	);
};

export default Usuarios;
