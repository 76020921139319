import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	planosTaxas: {
		marginTop: 5,
	},
	planosTaxasPaper: {
		padding: 10,
		paddingTop: 40,
		position: 'relative',
	},
	btnAdd: {
		position: 'absolute',
		right: 10,
		top: 10,
	},
	headerTitle: {
		fontWeight: 'bold',
		fontSize: 15,
	},
	ptRow: {
		borderTop: 'solid 1px #abc',
	},
}));
