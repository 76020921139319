/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField } from '@material-ui/core';
import axios from 'axios';
import config from 'react-global-configuration';
import { makeStyles } from '@material-ui/core/styles';
import { addMessage } from '../../store/alert';
import { ZLoader } from '../system';

const useStyles = makeStyles(theme => ({
	fullWidth: {
		width: '100%',
	},
}));

const EditarPos = props => {
	const { posId } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const token = useSelector(store => store.auth.token);
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({
		serial: '',
		chip: '',
	});

	useEffect(() => {
		const getPos = async () => {
			setLoading(true);
			const res = await axios.get(`${config.get('apiUrl')}pos/${posId}`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success) {
				setData({
					serial: res.data.pos.serial,
					chip: res.data.pos.chip,
				});
			}
			setLoading(false);
		};

		getPos();
	}, [posId]);

	const saveBtnClick = async () => {
		const res = await axios.put(`${config.get('apiUrl')}pos/${posId}`, data, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			props.onClose();
			dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
		} else {
			dispatch(addMessage({ type: 'error', message: res.data.message }));
		}
	};

	return (
		<Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
			<DialogTitle>Editar Pos</DialogTitle>
			<DialogContent>
				{loading ? (
					<ZLoader height={200} message="Carregando" />
				) : (
					<Grid container spacing={2} justifyContent="center">
						<Grid item xs={6}>
							<TextField
								className={classes.fullWidth}
								label="Número Serial"
								value={data.serial}
								onChange={e => setData({ ...data, serial: e.target.value })}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								className={classes.fullWidth}
								label="Número Chip"
								value={data.chip}
								onChange={e => setData({ ...data, chip: e.target.value })}
							/>
						</Grid>
					</Grid>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>Cancelar</Button>
				<Button variant="contained" color="primary" onClick={saveBtnClick}>
					Salvar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditarPos;
