import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { ZTitle, ZLoader, ZPagination, ZModalConfirm } from '../system';
import { Mask, Isset } from '../../utils';
import axios from 'axios';
import config from 'react-global-configuration';
import { addMessage } from '../../store/alert';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { FaEye, FaDollarSign, FaWhatsapp, FaShare, FaTrashAlt } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	menuIcon: {
		fontSize: 16,
		cursor: 'pointer',
		marginRight: 4,
	},
}));

const ListarTodas = props => {
	const { history } = useReactRouter();
	const dispatch = useDispatch();
	const token = useSelector(store => store.auth.token);
	const url = useSelector(store => store.system.config.hostname);
	const estabelecimentoAdmin = useSelector(store => store.admin.estabelecimento);
	const classes = useStyles();

	const [loading, setLoading] = useState(true);
	const [preVendas, setPreVendas] = useState([]);

	// Paginação

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);

	const [removePreVenda, setRemovePreVenda] = useState({});
	const [removeModalOpen, setRemoveModalOpen] = useState(false);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const getPreVendas = () => {
		setLoading(true);
		axios
			.get(`${config.get('apiUrl')}pre_venda`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					page,
					limit: rowsPerPage,
					filters: {
						estabelecimentoId: Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : null,
					},
				},
			})
			.then(res => res.data)
			.then(data => {
				setLoading(false);
				if (data.success) {
					setPreVendas(data.pre_vendas);
					setTotalRows(data.quantidade);
				}
			});
	};

	useEffect(() => {
		getPreVendas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [estabelecimentoAdmin]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(getPreVendas, [page, rowsPerPage]);

	const handleRemovePreVenda = async result => {
		setRemoveModalOpen(false);

		const { id: preVendaId } = removePreVenda;

		if (result && preVendaId) {
			const res = await axios.delete(`${config.get('apiUrl')}pre_venda/${preVendaId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (res.data.success) {
				dispatch(addMessage({ type: 'success', message: 'Link de Pagamento removido com sucesso' }));
				getPreVendas();
			}
		}

		setRemovePreVenda({});
	};

	const formaPagamento = useMemo(() => {
		return preVendas.map(preVenda => {
			switch (+preVenda.tipo_pagamento_id) {
				case 1:
					return 'Boleto';
				case 2:
					return 'Débito';
				case 3:
					return 'Cartão de Crédito';
				case 5:
					return 'Pix';
				default:
					return 'Comissão';
			}
		});
	}, [preVendas]);

	return (
		<Grid container>
			<ZTitle
				title="Links de Pagamentos"
				display="flex"
				textAlign="center"
				fontSize="50px"
				button="add"
				handleOnClick={() => history.push(`/venda-digitada`)}
			/>
			<Grid item xs={12}>
				{loading ? (
					<ZLoader height="200px" size={40} />
				) : (
					<Paper style={{ overflowX: 'auto' }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell style={{ minWidth: 80 }}>ID</TableCell>
									<TableCell style={{ minWidth: 150 }}>Titulo</TableCell>
									<TableCell align="center" style={{ minWidth: 80 }}>
										Pedidos
									</TableCell>
									<TableCell align="right" style={{ minWidth: 150 }}>
										Valor
									</TableCell>
									<TableCell align="right" style={{ minWidth: 150 }}>
										Data expiração
									</TableCell>
									<TableCell align="right" style={{ minWidth: 150 }}>
										Criado
									</TableCell>
									<TableCell align="right" style={{ minWidth: 150 }}>
										Forma Pagamento
									</TableCell>
									<TableCell align="right" style={{ minWidth: 150 }}></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<>
									{preVendas.map((preVenda, key) => (
										<TableRow hover key={key}>
											<TableCell component="th" scope="row">
												{preVenda.id}
											</TableCell>
											<TableCell component="th" scope="row">
												{preVenda.titulo}
											</TableCell>
											<TableCell align="center">
												{preVenda.pedido_id ? 1 : preVenda.pre_venda_pedidos.length}
											</TableCell>
											<TableCell align="right">{Mask.Real(preVenda.amount / 100)}</TableCell>
											<TableCell align="right">
												{moment(preVenda.data_expiracao).format('DD/MM/YYYY')}
											</TableCell>
											<TableCell align="right">
												{moment(preVenda.createdAt).format('DD/MM/YYYY')}
											</TableCell>
											<TableCell align="right">{formaPagamento[key]}</TableCell>
											<TableCell align="right">
												<Tooltip title="Visualizar">
													<span>
														<FaEye
															className={classes.menuIcon}
															onClick={() => {
																history.push('/venda-digitada/' + preVenda.id);
															}}
														/>
													</span>
												</Tooltip>
												{!Isset(() => preVenda.pre_venda_pedidos[0].pedido_id) && (
													<>
														<Tooltip title="Tela de Pagamento">
															<span>
																<FaDollarSign
																	className={classes.menuIcon}
																	onClick={() => {
																		window.open(
																			'/venda/token/' + preVenda.token,
																			'__blank'
																		);
																	}}
																/>
															</span>
														</Tooltip>
														<Tooltip title="Compartilhar no Whatsapp">
															<span>
																<FaWhatsapp
																	className={classes.menuIcon}
																	onClick={() => {
																		const text = encodeURIComponent(
																			`Link de Pagamento - ${
																				preVenda.estabelecimento.nome_fantasia
																			}\n\n*${preVenda.titulo.trim()}*\nhttps://${url}${config.get(
																				'baseName'
																			)}/lp/${preVenda.token}`
																		);
																		window.open(
																			`https://wa.me/?text=${text}`,
																			'_blank'
																		);
																	}}
																/>
															</span>
														</Tooltip>
														<Tooltip title="Enviar por E-mail">
															<span>
																<FaShare
																	className={classes.menuIcon}
																	onClick={() => {
																		const text = encodeURIComponent(
																			`Link de Pagamento - ${
																				preVenda.estabelecimento.nome_fantasia
																			}\n\n*${preVenda.titulo.trim()}*\nhttps://${url}${config.get(
																				'baseName'
																			)}/venda/token/${preVenda.token}`
																		);
																		window.open(
																			`mailto:?subject=${text}`,
																			'_blank'
																		);
																	}}
																/>
															</span>
														</Tooltip>
														<Tooltip title="Remover Link de Pagamento">
															<span>
																<FaTrashAlt
																	className={classes.menuIcon}
																	onClick={() => {
																		setRemovePreVenda(preVenda);
																		setRemoveModalOpen(true);
																	}}
																/>
															</span>
														</Tooltip>
													</>
												)}
												{/* {Isset(() => preVenda.pre_venda_pedidos[0].pedido_id) && (
													<Tooltip title="Visualizar Pedido">
														<span>
															<FaReceipt
																className={classes.menuIcon}
																onClick={() => {
																	history.push(
																		'/vendas/detalhes/' +
																			preVenda.pre_venda_pedidos[0].pedido_id
																	);
																}}
															/>
														</span>
													</Tooltip>
												)} */}
											</TableCell>
										</TableRow>
									))}
								</>
							</TableBody>
							<ZPagination
								count={totalRows}
								rowsPerPage={rowsPerPage}
								page={page - 1}
								onChangePage={onChangePage}
								onChangeRowsPerPage={onChangeRowsPerPage}
							/>
						</Table>
					</Paper>
				)}
			</Grid>
			<ZModalConfirm
				title={`Remover Link de Pagamento`}
				message={`Deseja remover esse Link de Pagamento (${removePreVenda.titulo || ''})?`}
				open={removeModalOpen}
				onClose={handleRemovePreVenda}
			/>
		</Grid>
	);
};

export default ListarTodas;
