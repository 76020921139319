import React, { useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Grid,
	Typography,
	Paper,
	Button,
	SvgIcon,
	CircularProgress,
	Step,
	StepButton,
	Stepper,
} from '@material-ui/core';
import { ZCard, ZText, ZAlert, ZLoader, ZSelect } from '../system';
import { Mask, ValidatorForm, Isset } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import { FaBarcode, FaRegCreditCard } from 'react-icons/fa';
import { FiShoppingBag } from 'react-icons/fi';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import config from 'react-global-configuration';
import axios, { AxiosError } from 'axios';
import moment from 'moment';
import ELO from '../../assets/images/ELO.svg';
import VISA from '../../assets/images/VISA.svg';
import DINERS from '../../assets/images/DINERS.svg';
import AMEX from '../../assets/images/AMEX.svg';
import HIPER from '../../assets/images/HIPER.svg';
import HIPERCARD from '../../assets/images/HIPERCARD.svg';
import MASTER_CARD from '../../assets/images/MASTER_CARD.svg';
import AURA from '../../assets/images/AURA.svg';
import JCB from '../../assets/images/JCB.svg';
import BANESCARD from '../../assets/images/BANESCARD.svg';
import BILLET from '../../assets/images/BILLET.svg';
import LOGOONEPAY from '../../assets/images/onepay-pagamentos.png';
import LOGOONEPAYWHITE from '../../assets/images/onepay-pagamentos-white.png';

const useStyles = makeStyles(theme => ({
	a: {
		textDecoration: 'none',
	},
	label: {
		marginTop: 12,
	},
	paper: {
		borderRadius: 5,
		padding: 32,
		marginLeft: 8,
		marginRight: 8,
		marginBottom: 16,
		backgroundColor: '#F7F7F7',
	},
	containerPaper: {
		margin: '32px auto',
		[theme.breakpoints.down('xs')]: {
			margin: '16px 0',
		},
	},
	redContainer: {
		backgroundColor: '#D9272D',
		width: '100%',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		color: '#FFF',
	},
	greenContainer: {
		backgroundColor: '#50c878',
		width: '100%',
		minHeight: '50vh',
		display: 'flex',

		'& .container__icon': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: '64px',

			position: 'relative',
			backgroundColor: '#FFF',
			borderRadius: '50%',
			width: '100px',
			height: '100px',
		},

		'& .container__icon-check': {
			position: 'absolute',
			top: '66px',
			left: '79px',
		},

		'& .paper': {
			borderRadius: 5,
			padding: 32,
			position: 'absolute',
			'& .paper__content-title': {
				color: '#424242',
				fontSize: '18px',
				fontWeight: 'bold',
				margin: 0,
			},

			'& .paper__content-text': {
				color: '#757575',
				fontSize: '14px',
				lineHeight: '24px',
				margin: 0,
			},
		},
	},
	flags: {
		width: 45,
		height: 26,
		padding: 2,
	},
	button: {
		border: '2px solid #777',
		color: '#777',
		padding: '12px 8px',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: '#EEEEEE',
		},
		'&:disabled': {
			border: '2px solid #187bcd',
			//backgroundColor: '#187bcd',
			color: '#187bcd',
		},
	},
	finishButton: {
		textTransform: 'none',
		backgroundColor: '#051587',
		color: '#fff',
		padding: '12px 8px',
		'&:hover': {
			color: '#fff',
			backgroundColor: '#2a9df4',
		},
	},
	backButton: {
		textTransform: 'none',
		backgroundColor: 'transparent',
		color: '#777',
		border: '2px solid #051587',
		padding: '12px 8px',
		'&:hover': {
			border: '2px solid #2a9df4',
			color: '#fff',
			backgroundColor: '#2a9df4',
		},
	},
	nextButton: {
		textTransform: 'none',
		backgroundColor: '#051587',
		color: '#fff',
		padding: '12px 8px',
		'&:hover': {
			color: '#fff',
			backgroundColor: '#2a9df4',
		},
	},
	title: {
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
		},
	},
	description: {
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
		},
	},
	displayMobile: {
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column-reverse',
		},
	},
}));

const CheckOut = props => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const validateFields = {
		cliente: {
			nome: {
				required: true,
			},
			email: {
				required: true,
				email: true,
			},
			cpf: {
				required: true,
			},
		},
		cartao: {
			titular: {
				required: true,
			},
			validade: {
				required: true,
			},
			numero: {
				required: true,
			},
			codigoSeguranca: {
				required: true,
			},
		},
	};

	const message = useSelector(state => state.alert);
	const [text, setText] = useState('Carregando...');
	const [operacao, setOperacao] = useState('');
	const [loading, setLoading] = useState(false);
	const [flag, setFlag] = useState('');
	const [onError, setOnError] = useState(false);
	const [pagamentoAprovado, setPagamentoAprovado] = useState(false);
	const [dataPagamento, setDataPagamento] = useState({
		// token: props.token,
		token: props.match.params?.sellingCampaignId,
		tipoPagamentoId: null,
		parcelas: 1,
		valor: 0,
		cliente: {
			nome: '',
			email: '',
			dataNascimento: '',
			cpf: '',
			cnpj: '',
			celular: '',
		},
		endereco: {
			logradouro: '',
			numero: '',
			cep: '',
			cidade: '',
			estado: '',
			complemento: '',
		},
		cartao: {
			titular: '',
			validade: '',
			numero: '',
			codigoSeguranca: '',
			flags: '',
		},
		valorParcela: 0,
		valorTotal: 0,
		//sessionId: '',
	});
	const [data, setData] = useState({
		name: '',
		description: '',
		amount: 0.0,
		//sessionId: '',
	});
	const [parcelas] = useState([]);
	const [fees, setFees] = useState(null);
	const token = useSelector(store => store.auth.token);

	const setParcelas = flag => {
		parcelas.splice(0, parcelas.length);
		var localFlag = getCreditCardName(flag);
		for (var x = 0; x < data.parcelas; x++) {
			let textParcela = 'x ';
			let valueParcela = null;
			let valueTotal = null;
			if (x === 0) {
				textParcela += ` ${Mask.Real(data.amount / 100)}`;
				valueParcela = data.amount / 100;
				valueTotal = data.amount / 100;
			} else {
				if (data.juros_cliente) {
					textParcela += ` ${Mask.Real(fees[localFlag].credit[x + 1] / (x + 1))}`;
					valueParcela = (fees[localFlag].credit[x + 1] / (x + 1)).toFixed(2);
					valueTotal = fees[localFlag].credit[x + 1].toFixed(2);
				} else {
					textParcela += ` ${Mask.Real(data.amount / 100 / (x + 1))}`;
					valueParcela = (data.amount / 100 / (x + 1)).toFixed(2);
					valueTotal = (data.amount / 100).toFixed(2);
				}
			}
			parcelas.push({
				id: x + 1,
				name:
					x +
					1 +
					textParcela +
					(x > 0 && data.juros_cliente ? ` c/ Juros (${Mask.Real(valueTotal)})` : ' s/ Juros'),
				valueTotal: valueTotal,
				value: valueParcela,
			});
		}
	};

	//AntiFraude
	//var sessionId = '';

	const [activeStep, setActiveStep] = React.useState(0);
	const [completed] = React.useState({});
	const steps = ['Dados Pessoais', 'Pagar', 'Fim!'];

	const totalSteps = () => {
		return steps.length;
	};

	const completedSteps = () => {
		return Object.keys(completed).length;
	};

	const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

	const allStepsCompleted = () => {
		return completedSteps() === totalSteps();
	};

	const handleNext = () => {
		dispatch(addMessage({ type: 'error', message: '' }));
		const newActiveStep =
			isLastStep() && !allStepsCompleted() ? steps.findIndex((_, i) => !(i in completed)) : activeStep + 1;
		setActiveStep(newActiveStep);
	};

	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	};

	const handleStep = step => () => {
		setActiveStep(step);
	};

	useEffect(() => {
		// if (!props.token) {
		// 	setText(null);
		// }
		if (!props?.match?.params?.sellingCampaignId) {
			setText(null);
		}
		document.body.style.backgroundColor = '#FBFBFB';
		axios
			.get(`${config.get('apiUrl')}sellingCampaign/token/${props.match.params.sellingCampaignId}`, {})
			.then(res => {
				if (res && res.data && res.data.calculator && res.data.calculator.success) {
					setFees(res.data.calculator.valorFinal);
				}
				if (res.data.success) {
					setData({
						titulo: res.data.sellingCampaign.name,
						descricao: res.data.sellingCampaign.description,
						amount: res.data.sellingCampaign.amount,
						parcelas: res.data.sellingCampaign.installments,
						credito: res.data.sellingCampaign.credit_card,
						boleto: res.data.sellingCampaign.bank_slip,
						juros_cliente: res.data.sellingCampaign.installments_payer,
						logo: !!res.data.sellingCampaign.logo,
						logoUrl: Isset(() => res.data.sellingCampaign.estabelecimento.arquivo.url)
							? res.data.sellingCampaign.estabelecimento.arquivo.url
							: null,
						nome_fantasia: res.data.sellingCampaign.estabelecimento.nome_fantasia,
						razao_social: res.data.sellingCampaign.estabelecimento.razao_social,
						estabelecimentoId: res.data.sellingCampaign.estabelecimento.id,
						tipo_pagamento_id: res.data.sellingCampaign.credit_card === false ? 1 : 3,
					});
					setDataPagamento({
						...dataPagamento,
						valor: res.data.sellingCampaign.amount / 100,
						valorTotal: res.data.sellingCampaign.amount / 100,
						valorParcela: res.data.sellingCampaign.amount / 100,
						parcelas: 1,
					});
					// //Anti-Fraude-------------------------------------------------------------------------------------
					// const script = document.createElement('script');
					// let fingerprint_antifraud = '';
					// if (res.data.sellingCampaign.estabelecimento.estabelecimento_antifraude_configuration) {
					// 	fingerprint_antifraud =
					// 		res.data.sellingCampaign.estabelecimento.estabelecimento_antifraude_configuration
					// 			.fingerprint_id;
					// }

					// if (fingerprint_antifraud === '') {
					// 	fingerprint_antifraud = 'fycd8xcpzhprqazd7dqk'; //Antifraud Onepay
					// }
					// script.innerHTML = `(function (a, b, c, d, e, f, g) {
					// a['CsdpObject'] = e; a[e] = a[e] || function () {
					// (a[e].q = a[e].q || []).push(arguments)
					// }, a[e].l = 1 * new Date(); f = b.createElement(c),
					// g = b.getElementsByTagName(c)[0]; f.async = 1; f.src = d; g.parentNode.insertBefore(f, g)
					// })(window, document, 'script', '//device.clearsale.com.br/p/fp.js', 'csdp');
					// csdp('app', '${fingerprint_antifraud}');
					// csdp('outputsessionid', 'sessionInputOP');`;
					// document.body.appendChild(script);
					// //Anti-Fraude-------------------------------------------------------------------------------------
				} else {
					setText(res.data.message);
					setData({
						message: res.data.error,
						success: res.data.success,
					});
				}
			});
	}, [dataPagamento, props.match.params.sellingCampaignId, props.token]);

	const getCreditCardName = flag => {
		switch (flag) {
			case 'amex':
				return 'American Express';
			case 'aura':
				return 'Aura';
			case 'banescard':
				return 'Banescard';
			case 'dinersclub':
				return 'Diners Club';
			case 'discover':
				return 'Discover';
			case 'elo':
				return 'Elo';
			case 'hiper':
				return 'Hiper';
			case 'hipercard':
				return 'Hipercard';
			case 'jcb':
				return 'JCB';
			case 'maestro':
				return 'Maestro';
			case 'mastercard':
				return 'MasterCard';
			case 'visa':
				return 'Visa';
			case 'visa electron':
				return 'Visa Electron';
			default:
				return 'erro: ' + flag;
		}
	};

	const handleSubmitForm = async () => {
		dispatch(addMessage({ type: 'error', message: '' }));
		setLoading(true);

		try {
			validateFields.cliente.cpf = {
				required: true,
			};
			validateFields.cliente.dataNascimento = {
				required: true,
			};

			if (data.tipo_pagamento_id === 1) {
				delete validateFields.cartao;
			}
			ValidatorForm(validateFields, dataPagamento);
		} catch (erro) {
			console.log({ erro });
			setActiveStep(0);
			dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário' }));
			setLoading(false);
			setOnError(true);
			return false;
		}

		const taxpayer = dataPagamento.cliente.cpf.replace(/\D+/g, '');

		const dadosPagamento = {
			...dataPagamento,
			type: 1,
			juros: 0,
			tipoEstabelecimentoId: 1,
			tipoPagamentoId: data.tipo_pagamento_id,
			estabelecimento_id: data.estabelecimentoId,
			//sessionId,
			cliente: {
				...dataPagamento.cliente,
				cpf: taxpayer.length < 14 ? taxpayer : '',
				cnpj: taxpayer.length >= 14 ? taxpayer : '',
			},
			valor: data.tipo_pagamento_id === 3 ? +dataPagamento.valorTotal : data.amount / 100,
		};

		console.log({ dadosPagamentoCheckout: dadosPagamento });

		try {
			const res = (
				await axios.post(config.get('apiUrl') + 'vendas/token', dadosPagamento, {
					headers: { Authorization: `Bearer ${token}` },
				})
			).data;

			console.log({ res });

			if (res.success) {
				// Dispara msg de sucesso
				dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));

				// Ajusta os setters
				setPagamentoAprovado(true);
				setOperacao('Operação: ' + res.pedido.id);
				setData({ ...data });
			}
		} catch (error) {
			console.log({ error });
			// @TODO - validar os erros
			if (error instanceof AxiosError) {
				dispatch(addMessage({ type: 'error', message: error.response.data.error }));
				return;
			}
			// varios erros
			if (error?.errors.length > 0) {
				dispatch(addMessage({ type: 'error', message: error.errors.join(' - ') }));
			}
			// 1 erro
			dispatch(addMessage({ type: 'error', message: error.message }));
		} finally {
			setLoading(false);
		}
	};

	const getEndereco = async value => {
		if (value.length === 8) {
			var res = await axios.get(config.get('apiUrl') + 'buscar_cep/' + value);
			if (res.data.success === false || res.data.endereco.erro) {
				setDataPagamento({
					...dataPagamento,
					endereco: {
						...dataPagamento.endereco,
						cep: value,
						logradouro: '',
						cidade: '',
						estado: '',
						complemento: '',
					},
				});
				return false;
			}
			setDataPagamento({
				...dataPagamento,
				endereco: {
					...dataPagamento.endereco,
					cep: Mask.OnlyNumber(res.data.endereco.cep),
					logradouro: res.data.endereco.logradouro,
					cidade: res.data.endereco.localidade,
					estado: res.data.endereco.uf,
					complemento: res.data.endereco.complemento,
				},
			});
		}
	};

	const maskCreditCard = useMemo(() => {
		const regex = /\b(?:\d{4}[ -]?){3}(?=\d{4}\b)/gm;
		const subst = `**** **** **** `;

		return dataPagamento.cartao.numero.replace(regex, subst);
	}, [dataPagamento.cartao.numero]);

	if (text && text !== 'Carrregando...' && !data.titulo) {
		return text;
	}

	if (pagamentoAprovado) {
		return (
			<>
				{pagamentoAprovado ? (
					<Grid container className={classes.greenContainer}>
						<Grid container item justifyContent="center">
							<div className="container__icon">
								<div>
									<FiShoppingBag color="#059142" size="50px" strokeWidth="1px" />
								</div>
								<div className="container__icon-check">
									<SvgIcon
										component={CheckCircleIcon}
										fontSize="large"
										style={{ color: '#043927' }}
									/>
								</div>
							</div>
							<Grid container item justifyContent="center">
								<section>
									<p
										style={{
											color: '#fff',
											fontSize: '32px',
											margin: 0,
											padding: 10,
											textAlign: 'center',
										}}
									>
										{data.tipo_pagamento_id === 1
											? 'Pronto, seu boleto foi gerado!'
											: 'Pronto, seu pagamento foi aprovado!'}
									</p>
									<p
										style={{
											color: '#fff',
											fontSize: '18px',
											textAlign: 'center',
											marginBottom: '64px',
											marginTop: 8,
										}}
									>
										{operacao}
									</p>
								</section>
							</Grid>
						</Grid>
						<Grid container justifyContent="center" alignItems="center" style={{ marginTop: 64 }}>
							<Paper className="paper">
								<Grid className="paper__container-content" item container direction="row" xs={12}>
									<Grid item sm={1} xs={12} style={{ marginRight: 16 }}>
										{data.tipo_pagamento_id === 1 ? (
											<FaBarcode size="32px" />
										) : (
											<FaRegCreditCard size="32px" />
										)}
									</Grid>

									<Grid item sm={10}>
										<p className="paper__content-title">
											{data.tipo_pagamento_id === 1 ? 'Você vai pagar' : 'Você pagou'}{' '}
											{data.tipo_pagamento_id === 3
												? Mask.Real(dataPagamento.valorTotal)
												: Mask.Real(data.amount / 100)}
										</p>
										<p className="paper__content-text">
											{`${data.tipo_pagamento_id === 3 ? dataPagamento.parcelas : 1} ${
												dataPagamento.parcelas > 1 && data.tipo_pagamento_id === 3
													? 'vezes'
													: 'vez'
											} de ${
												data.tipo_pagamento_id === 3
													? `${Mask.Real(dataPagamento.valorParcela)} no cartão de crédito `
													: `${Mask.Real(data.amount / 100)} no boleto bancário `
											}`}
											{data.tipo_pagamento_id !== 1 ? maskCreditCard : ''}
										</p>
										<p className="paper__content-text">
											{data.tipo_pagamento_id === 1
												? 'O boleto será enviado para o seu e-mail, faça o pagamento o quanto antes pois a compensação pode demorar até 3 dias úteis'
												: `Na fatura do seu cartão você verá o pagamento em nome de ${data.nome_fantasia.toUpperCase()}`}
										</p>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				) : (
					<ZLoader />
				)}
			</>
		);
	}

	if (data.titulo) {
		return (
			<Grid container className={classes.displayMobile}>
				{/* <input type="hidden" id="MeuCampoComValor" value={sessionId} /> */}
				{message.message && <ZAlert type={message.type} message={message.message} time={moment().unix()} />}
				<Grid item xs={12} md={8} className={classes.containerPaper}>
					<Grid item xs={12} id="paper">
						<Stepper style={{ backgroundColor: '#FBFBFB' }} nonLinear activeStep={activeStep}>
							{steps.map((label, index) => (
								<Step key={label}>
									<StepButton
										onClick={handleStep(index)}
										completed={completed[index]}
										disabled={index === 2 ? true : false}
									>
										{label}
									</StepButton>
								</Step>
							))}
						</Stepper>
					</Grid>
					<Grid id="paper" item xs={12}>
						<Paper className={classes.paper}>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Grid container spacing={2}>
											{activeStep === 0 && (
												<>
													<>
														<Grid item xs={12} sm={6} md={12}>
															<ZText
																label="SEU NOME COMPLETO"
																onError={onError}
																variant="outlined"
																required={true}
																placeholder="Digite seu nome completo"
																value={dataPagamento.cliente.nome}
																onBlur={e =>
																	setDataPagamento({
																		...dataPagamento,
																		cliente: {
																			...dataPagamento.cliente,
																			nome: e.target.value,
																		},
																	})
																}
															/>
														</Grid>
														<Grid item xs={12} sm={6} md={6}>
															<ZText
																label="CPF/CNPJ"
																placeholder="___.___.___-__"
																onError={onError}
																type="tel"
																variant="outlined"
																required={true}
																value={dataPagamento.cliente.cpf}
																onChange={e =>
																	setDataPagamento({
																		...dataPagamento,
																		cliente: {
																			...dataPagamento.cliente,
																			cpf:
																				e.length <= 14
																					? Mask.Cpf(e)
																					: Mask.Cnpj(e),
																		},
																	})
																}
															/>
														</Grid>
													</>

													{
														<Grid item xs={12} sm={6} md={6}>
															<ZText
																label="CELULAR"
																placeholder="(__) _ ____-____"
																mask={'(99) 9 9999-9999'}
																maskChar={null}
																onError={onError}
																variant="outlined"
																required={true}
																pattern="[0-9]*"
																type="tel"
																value={dataPagamento.cliente.celular}
																onBlur={e =>
																	setDataPagamento({
																		...dataPagamento,
																		cliente: {
																			...dataPagamento.cliente,
																			celular: Mask.OnlyNumber(e.target.value),
																		},
																	})
																}
															/>
														</Grid>
													}
													{
														<Grid item xs={12} sm={6} md={3}>
															<ZText
																label="DATA DE NASCIMENTO"
																placeholder="DD/MM/AAAA"
																mask={'99/99/9999'}
																maskChar={null}
																onError={onError}
																variant="outlined"
																required={true}
																pattern="[0-9]*"
																type="tel"
																value={moment(
																	dataPagamento.cliente.dataNascimento
																).format('DD/MM/YYYY')}
																onBlur={e => {
																	let dataNascimento = moment(
																		e.target.value,
																		'DD/MM/YYYY'
																	).format('YYYY-MM-DD');
																	setDataPagamento({
																		...dataPagamento,
																		cliente: {
																			...dataPagamento.cliente,
																			dataNascimento: dataNascimento,
																		},
																	});
																}}
															/>
														</Grid>
													}
													<Grid item xs={12} sm={6} md={9}>
														<ZText
															label="SEU E-MAIL"
															onError={onError}
															required={true}
															variant="outlined"
															email
															type="email"
															placeholder="Digite o seu e-mail"
															value={dataPagamento.cliente.email}
															onBlur={e =>
																setDataPagamento({
																	...dataPagamento,
																	cliente: {
																		...dataPagamento.cliente,
																		email: e.target.value,
																	},
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6} md={3}>
														<ZText
															label="CEP"
															mask={'99999-999'}
															placeholder="_____-___"
															onError={onError}
															variant="outlined"
															pattern="[0-9]*"
															type="tel"
															required={true}
															value={dataPagamento.endereco.cep}
															onChange={e => {
																let cep = Mask.OnlyNumber(e);
																setDataPagamento({
																	...dataPagamento,
																	endereco: {
																		...dataPagamento.endereco,
																		cep: cep,
																	},
																});
																getEndereco(cep);
															}}
														/>
													</Grid>
													{dataPagamento.endereco.cep !== '' && (
														<Grid item xs={12} sm={6} md={9}>
															<ZText
																label="LOGRADOURO"
																placeholder="Digite o logradouro de sua residência"
																onError={onError}
																variant="outlined"
																required={true}
																value={dataPagamento.endereco.logradouro}
																onBlur={e =>
																	setDataPagamento({
																		...dataPagamento,
																		endereco: {
																			...dataPagamento.endereco,
																			logradouro: e.target.value,
																		},
																	})
																}
															/>
														</Grid>
													)}
													{dataPagamento.endereco.cep !== '' && (
														<Grid item xs={12} sm={6} md={3}>
															<ZText
																label="NÚMERO"
																placeholder="Número de sua residência"
																onError={onError}
																variant="outlined"
																required={true}
																pattern="[0-9]*"
																type="tel"
																value={dataPagamento.endereco.numero}
																onBlur={e =>
																	setDataPagamento({
																		...dataPagamento,
																		endereco: {
																			...dataPagamento.endereco,
																			numero: e.target.value,
																		},
																	})
																}
															/>
														</Grid>
													)}
													{dataPagamento.endereco.cep !== '' && (
														<Grid item xs={12} sm={6} md={9}>
															<ZText
																label="Complemento (opcional)"
																placeholder="Digite o complemento de sua residência"
																value={dataPagamento.endereco.complemento}
																variant="outlined"
																onBlur={e =>
																	setDataPagamento({
																		...dataPagamento,
																		endereco: {
																			...dataPagamento.endereco,
																			complemento: e.target.value,
																		},
																	})
																}
															/>
														</Grid>
													)}
													{dataPagamento.endereco.cep !== '' && (
														<Grid item xs={12} sm={6} md={8}>
															<ZText
																label="CIDADE"
																placeholder="Digite a cidade de sua residência"
																onError={onError}
																variant="outlined"
																required={true}
																value={dataPagamento.endereco.cidade}
																onBlur={e =>
																	setDataPagamento({
																		...dataPagamento,
																		endereco: {
																			...dataPagamento.endereco,
																			cidade: e.target.value,
																		},
																	})
																}
															/>
														</Grid>
													)}
													{dataPagamento.endereco.cep !== '' && (
														<Grid item xs={12} sm={6} md={4}>
															<ZSelect
																label="Estado (UF)"
																variant="outlined"
																onError={onError}
																placeholder="Digite o estado de sua residência"
																value={dataPagamento.endereco.estado}
																onBlur={e =>
																	setDataPagamento({
																		...dataPagamento,
																		endereco: {
																			...dataPagamento.endereco,
																			estado: e.target.value,
																		},
																	})
																}
																required={true}
																options={[
																	{ id: 'AC', name: 'AC' },
																	{ id: 'AL', name: 'AL' },
																	{ id: 'AP', name: 'AP' },
																	{ id: 'AM', name: 'AM' },
																	{ id: 'BA', name: 'BA' },
																	{ id: 'CE', name: 'CE' },
																	{ id: 'ES', name: 'ES' },
																	{ id: 'GO', name: 'GO' },
																	{ id: 'MA', name: 'MA' },
																	{ id: 'MT', name: 'MT' },
																	{ id: 'MS', name: 'MS' },
																	{ id: 'MG', name: 'MG' },
																	{ id: 'PA', name: 'PA' },
																	{ id: 'PB', name: 'PB' },
																	{ id: 'PR', name: 'PR' },
																	{ id: 'PE', name: 'PE' },
																	{ id: 'PI', name: 'PI' },
																	{ id: 'RJ', name: 'RJ' },
																	{ id: 'RN', name: 'RN' },
																	{ id: 'RS', name: 'RS' },
																	{ id: 'RO', name: 'RO' },
																	{ id: 'RR', name: 'RR' },
																	{ id: 'SC', name: 'SC' },
																	{ id: 'SP', name: 'SP' },
																	{ id: 'SE', name: 'SE' },
																	{ id: 'TO', name: 'TO' },
																	{ id: 'DF', name: 'DF' },
																]}
															/>
														</Grid>
													)}
												</>
											)}
											{activeStep === 1 && (
												<>
													<Grid spacing={2} container direction="row" justifyContent="center">
														{data.credito && (
															<Grid item xs={12} sm={6} md={3}>
																<Button
																	className={classes.button}
																	disabled={data.tipo_pagamento_id === 3}
																	fullWidth
																	onClick={() =>
																		setData({ ...data, tipo_pagamento_id: 3 })
																	}
																>
																	<FaRegCreditCard
																		size="24px"
																		style={{ marginRight: 9 }}
																	/>
																	Cartão de Crédito
																</Button>
															</Grid>
														)}
														{data.boleto && (
															<Grid item xs={12} sm={6} md={3}>
																<Button
																	className={classes.button}
																	disabled={data.tipo_pagamento_id === 1}
																	fullWidth
																	onClick={() =>
																		setData({ ...data, tipo_pagamento_id: 1 })
																	}
																>
																	<FaBarcode size="24px" style={{ marginRight: 9 }} />{' '}
																	Boleto Bancário
																</Button>
															</Grid>
														)}
													</Grid>
													{data.tipo_pagamento_id === 3 && (
														<Grid item xs={12} style={{ paddingTop: 30 }}>
															<ZCard
																test={e => {
																	if (flag !== e && e !== 'unknown') {
																		setFlag(e);
																		setParcelas(e);
																	}
																	if (e === 'unknown') {
																		setFlag('');
																	}
																}}
																number={dataPagamento.cartao.numero}
																name={dataPagamento.cartao.titular}
																expiry={dataPagamento.cartao.validade}
																cvc={dataPagamento.cartao.codigoSeguranca}
																numberOnBlur={e => {
																	setDataPagamento({
																		...dataPagamento,
																		cartao: {
																			...dataPagamento.cartao,
																			numero: Mask.OnlyNumber(e.target.value),
																		},
																	});
																}}
																nameOnBlur={e =>
																	setDataPagamento({
																		...dataPagamento,
																		cartao: {
																			...dataPagamento.cartao,
																			titular: e.target.value,
																		},
																	})
																}
																expiryOnBlur={e =>
																	setDataPagamento({
																		...dataPagamento,
																		cartao: {
																			...dataPagamento.cartao,
																			validade: e.target.value,
																		},
																	})
																}
																cvcOnBlur={e =>
																	setDataPagamento({
																		...dataPagamento,
																		cartao: {
																			...dataPagamento.cartao,
																			codigoSeguranca: e.target.value,
																		},
																	})
																}
																onError={onError}
																maxInstallment={parcelas}
																installment={dataPagamento.parcelas}
																installmentOnBlur={e => {
																	setDataPagamento({
																		...dataPagamento,
																		parcelas: e.target.value,
																		valorParcela:
																			parcelas[e.target.value - 1].value,
																		valorTotal:
																			parcelas[e.target.value - 1].valueTotal,
																	});
																}}
																cardGrid={{
																	xs: 12,
																	sm: 12,
																	md: 12,
																	lg: 12,
																}}
																containerInputsGrid={{
																	xs: 12,
																	sm: 12,
																	md: 12,
																	lg: 12,
																	marginTop: 30,
																}}
															/>
														</Grid>
													)}
													{data.tipo_pagamento_id === 1 && (
														<Grid item xs={12} style={{ paddingTop: 30 }}>
															<Typography variant="h6" gutterBottom>
																Boleto Bancário
															</Typography>
															<ul>
																<li>Boleto (somente à vista)</li>
																<li>
																	Pagamentos com Boleto Bancário levam{' '}
																	<b>até 3 dias úteis</b> para serem compensados e
																	então terem os produtos liberados
																</li>
																<li>
																	Atente-se ao vencimento do boleto. Você pode pagar o
																	boleto em qualquer banco ou casa lotérica até o dia
																	do vencimento
																</li>
															</ul>
															<p>
																O boleto será enviado para o seu e-mail logo após
																concluir o processo.
															</p>
															<ZText
																label="Opções de Parcelamento"
																disabled
																variant="outlined"
																onError={onError}
																value={`1 vez de ${Mask.Real(data.amount / 100)}`}
															/>
														</Grid>
													)}
												</>
											)}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
						<Grid item xs={12}>
							<Grid
								spacing={2}
								container
								direction="row"
								justifyContent={window.innerWidth >= 568 ? 'flex-end' : 'center'}
							>
								{activeStep === 1 && (
									<Grid item xs={10} sm={6} md={3}>
										<a href="#paper" style={{ textDecoration: 'none' }}>
											<Button
												disabled={activeStep === 0}
												className={classes.backButton}
												onClick={handleBack}
												fullWidth
											>
												Voltar
											</Button>
										</a>
									</Grid>
								)}
								{activeStep < 1 && (
									<Grid item xs={10} sm={6} md={3}>
										<a href="#paper" style={{ textDecoration: 'none' }}>
											<Button className={classes.nextButton} fullWidth onClick={handleNext}>
												Próximo Passo
											</Button>
										</a>
									</Grid>
								)}
								{activeStep === 1 && (
									<Grid item xs={10} md={3}>
										<Button
											onClick={handleSubmitForm}
											className={classes.finishButton}
											disabled={loading}
											fullWidth
										>
											{loading ? (
												<CircularProgress size={24} style={{ color: '#fff' }} />
											) : (
												'Concluir'
											)}
										</Button>
									</Grid>
								)}
							</Grid>
						</Grid>
						<Grid item xs={12} md={12} style={{ maxWidth: '100%' }}>
							<hr style={{ marginTop: '15px' }}></hr>
							<Grid
								container
								className={[classes.description, classes.displayMobile]}
								style={{ fontSize: 12 }}
							>
								<p style={{ marginLeft: 8, marginRight: 8 }}>
									<a
										href="https://www.1pay.com.br/termos-de-uso/"
										style={{ textDecoration: 'none' }}
										target="_blank"
										rel="noreferrer noopener"
									>
										Preciso de ajuda para preencher esta tela
									</a>
									.<br />
									<br />
									Onepay está processando este pedido a serviço de {data.nome_fantasia}, ao continuar,
									você concorda com os{' '}
									<a
										href="https://www.1pay.com.br/termos-de-uso/"
										target="_blank"
										rel="noreferrer noopener"
									>
										Termos de Compra
									</a>
									.<br />
									<br />
									Onepay © {moment().year()} - Todos os direitos reservados
								</p>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={3} className={classes.containerPaper}>
					<Paper className={classes.paper}>
						<Grid container spacing={9} direction="row" justifyContent="center" alignItems="center">
							<Grid item xs={12} md={12} align="center">
								<a href="https://1pay.com.br" target="_blank" rel="noreferrer noopener">
									<img
										src={LOGOONEPAY}
										alt="Onepay Pagamentos"
										title="Onepay Pagamentos"
										style={{ maxWidth: '100%', maxHeight: '90px' }}
									/>
								</a>
							</Grid>
							{data.logo && data.logoUrl && (
								<img
									src={data.logoUrl}
									alt={data.nome_fantasia}
									title={data.nome_fantasia}
									style={{ maxWidth: '100%', maxHeight: '100px' }}
								/>
							)}
							<Grid item xs={12} md={12}>
								<Typography className={classes.description} variant="subtitle1" gutterBottom>
									<ZText
										label="VALOR TOTAL"
										disabled
										variant="outlined"
										value={
											data.tipo_pagamento_id === 3
												? Mask.Real(dataPagamento.valorTotal)
												: Mask.Real(data.amount / 100)
										}
									/>
									<ZText
										label="PARCELAMENTO"
										disabled
										variant="outlined"
										value={`${data.tipo_pagamento_id === 3 ? dataPagamento.parcelas : 1} ${
											dataPagamento.parcelas > 1 && data.tipo_pagamento_id === 3 ? 'vezes' : 'vez'
										} de ${
											data.tipo_pagamento_id === 3
												? Mask.Real(dataPagamento.valorParcela)
												: Mask.Real(data.amount / 100)
										}`}
									/>

									<ZText label="NOME DO PRODUTO" disabled variant="outlined" value={data.titulo} />

									<ZText
										label="DESCRIÇÃO"
										disabled
										multiline
										rows="3"
										variant="outlined"
										value={data.descricao}
										style={{ height: 200 }}
									/>
								</Typography>
								{window.innerWidth < 600 && (
									<a href="#paper" style={{ textDecoration: 'none' }}>
										<Button className={classes.nextButton} fullWidth>
											Comprar Agora
										</Button>
									</a>
								)}
								{window.innerWidth > 600 && (
									<Typography className={classes.description} variant="subtitle1" gutterBottom>
										{data.boleto && (
											<img
												src={BILLET}
												alt="Boleto Bancário"
												title="Boleto Bancário"
												className={classes.flags}
											/>
										)}
										{data.credito && (
											<span>
												<img
													src={MASTER_CARD}
													alt="Mastercard"
													title="Mastercard"
													className={classes.flags}
												/>
												<img src={VISA} alt="Visa" title="Visa" className={classes.flags} />
												<img src={ELO} alt="Elo" title="Elo" className={classes.flags} />
												<img
													src={DINERS}
													alt="Diners Club"
													title="Diners Club"
													className={classes.flags}
												/>
												<img
													src={AMEX}
													alt="American Express"
													title="American Express"
													className={classes.flags}
												/>
												<img src={HIPER} alt="Hiper" title="Hiper" className={classes.flags} />
												<img
													src={HIPERCARD}
													alt="Hipercard"
													title="Hipercard"
													className={classes.flags}
												/>
												<img src={JCB} alt="JCB" title="JCB" className={classes.flags} />
												<img
													src={BANESCARD}
													alt="Banescard"
													title="Banescard"
													className={classes.flags}
												/>
												<img
													src={AURA}
													alt="Aura"
													title="Aura"
													className={classes.flags}
													style={{ borderRadius: '15%' }}
												/>
											</span>
										)}
									</Typography>
								)}
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		);
	} else {
		return (
			<Grid container className={classes.redContainer}>
				<Grid container item justifyContent="center">
					<Grid item xs={12} md={12} align="center" className={classes.hideLogo}>
						<a href="https://1pay.com.br" target="_blank" rel="noreferrer noopener">
							<img
								src={LOGOONEPAYWHITE}
								alt="Onepay Pagamentos"
								title="Onepay Pagamentos"
								style={{ maxWidth: '100%', maxHeight: '90px' }}
							/>
						</a>
					</Grid>
					<Grid style={{ fontSize: 30, fontWeight: 'bold' }}>
						{' '}
						<p>Ops! Algo deu errado....</p>
					</Grid>
				</Grid>
				<Grid style={{ fontSize: 18, border: 'solid', borderColor: '#FFF', borderRadius: '9px' }}>
					<p style={{ padding: '30px' }}>{data.message ? data.message : 'Campanha não encontrada.'}</p>
				</Grid>
			</Grid>
		);
	}
};

export default CheckOut;
