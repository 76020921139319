/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, TextField, Grid, Button } from '@material-ui/core';
import { addMessage } from '../../store/alert';
import { ZLoader } from '../system';
import Spread from './Spread';
import axios from 'axios';
import config from 'react-global-configuration';
import { useStyles } from './styles/PlanosTaxasEditStyle';

const EstabelecimentoPlanosTaxasEdit = () => {
	const { estabelecimentoId, planoTaxaId } = useParams();
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const dispatch = useDispatch();

	const [titulo, setTitulo] = useState('');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const getPlanoTaxa = async () => {
			setLoading(true);
			const res = await axios.get(
				`${config.get('apiUrl')}planos_taxas/porMarketplaceFilho/${estabelecimentoId}/${planoTaxaId}`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				const { plano } = res.data;
				setTitulo(plano.titulo);
			}
			setLoading(false);
		};

		getPlanoTaxa();
	}, []);

	const btnSaveClick = async () => {
		if (!titulo) {
			dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário.' }));
			return false;
		}

		const res = await axios.post(
			`${config.get('apiUrl')}planos_taxas/porMarketplaceFilho/${estabelecimentoId}/${planoTaxaId}`,
			{
				titulo,
			},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			dispatch(addMessage({ type: 'success', message: 'Tabela de Markup salvo com sucesso.' }));
		}
	};

	if (loading) {
		return <ZLoader height={200} />;
	}

	return (
		<div className={classes.planosTaxasAdd}>
			<div className={classes.header}>Editar Tabela de Markup</div>

			<Paper className={classes.planoTaxaPaper}>
				<Grid container spacing={2} justifyContent="center" alignItems="center">
					<Grid item xs={3}>
						<TextField
							label="Título"
							value={titulo}
							onChange={e => setTitulo(e.target.value)}
							style={{ width: '100%' }}
						/>
					</Grid>
					<Grid item xs={3}>
						<Button variant="contained" color="primary" className={classes.btnAdd} onClick={btnSaveClick}>
							Salvar
						</Button>
					</Grid>
				</Grid>
			</Paper>

			<Spread planoTaxaId={planoTaxaId} />
		</div>
	);
};

export default EstabelecimentoPlanosTaxasEdit;
