/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import { Grid, Dialog, DialogContent, Paper, Tooltip } from '@material-ui/core';
import useReactRouter from 'use-react-router';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import FileDownload from 'js-file-download';
import queryString from 'query-string';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import LastExport from '../Export/LastExport';
// import moment from 'moment';
import axios from 'axios';
import config from 'react-global-configuration';
import { Isset, Formatter } from '../../utils/';
import { ZEmpty, ZLoader, ZFiltros, ZPaginationDiv, ZTitle } from '../system';
import { green, red, yellow, orange } from '@material-ui/core/colors';
import { FiDownload } from 'react-icons/fi';

import {
	FaFilter,
	FaHistory,
	FaBarcode,
	FaCcVisa,
	FaCcMastercard,
	FaCcAmex,
	FaCreditCard,
	FaCheckCircle,
	FaTimesCircle,
	FaExclamationCircle,
	FaRegCreditCard,
	FaDollarSign,
	FaTimes,
	FaStopCircle,
} from 'react-icons/fa';
import LOGOPIX from '../../assets/images/pix-256.png';
import { addMessage } from '../../store/alert';
import { pt } from 'date-fns/locale';

const getStatusIcon = statusId => {
	switch (+statusId) {
		case 2:
			return (
				<Tooltip title="Aprovada">
					<div>
						<FaCheckCircle style={{ color: green[500] }} />
					</div>
				</Tooltip>
			);
		case 3:
			return (
				<Tooltip title="Falhado">
					<div>
						<FaStopCircle style={{ color: red[400] }} />
					</div>
				</Tooltip>
			);
		case 4:
			return (
				<Tooltip title="Cancelada">
					<div>
						<FaTimesCircle style={{ color: red[700] }} />
					</div>
				</Tooltip>
			);
		case 6:
			return (
				<Tooltip title="Estornado">
					<div>
						<FaTimesCircle style={{ color: orange[700] }} />
					</div>
				</Tooltip>
			);
		case 1:
		default:
			return (
				<Tooltip title="Pendente">
					<div>
						<FaExclamationCircle style={{ color: yellow[700] }} />
					</div>
				</Tooltip>
			);
	}
};

const TooltipTitle = props => {
	return (
		<div>
			<div>{props.type}</div>
			<div>{props.brand}</div>
		</div>
	);
};

const getPaymentIndicator = paymentType => {
	switch (paymentType) {
		case 1:
			return 'B';
		case 2:
			return 'D';
		case 3:
			return 'C';
		default:
			return '';
	}
};

const getBandeira = bandeira => {
	switch (bandeira) {
		case 'american express':
			return 'American Express';
		case 'elo':
			return 'Elo';
		case 'maestro':
			return 'Maestro';
		case 'mastercard':
			return 'Mastercard';
		case 'visa':
			return 'Visa';
		case 'visa electron':
			return 'Visa Electron';
		case 'diners club':
			return 'Diners Club';
		case 'hiper':
			return 'Hiper';
		case 'hipercard':
			return 'HiperCard';
		case 'banescard':
			return 'Banescard';
		default:
			return bandeira;
	}
};

const getPaymentIcon = (paymentType, brand, className = null) => {
	brand = getBandeira(brand);
	if (paymentType === 'boleto') {
		return (
			<Tooltip title="Boleto Bancário">
				<div>
					<FaBarcode className={className} />
				</div>
			</Tooltip>
		);
	}

	if (paymentType === 'debit') {
		return (
			<Tooltip title={<TooltipTitle type="Cartão de Débito" brand={brand} />}>
				<div>
					<FaRegCreditCard className={className} />
				</div>
			</Tooltip>
		);
	}

	if (paymentType === 'credit') {
		switch (brand) {
			case 'Mastercard':
			case 'Maestro':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<FaCcMastercard className={className} />
						</div>
					</Tooltip>
				);
			case 'Visa':
			case 'Visa Electron':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<FaCcVisa className={className} />
						</div>
					</Tooltip>
				);
			case 'American Express':
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<FaCcAmex className={className} />
						</div>
					</Tooltip>
				);
			default:
				return (
					<Tooltip title={<TooltipTitle type="Cartão de Crédito" brand={brand} />}>
						<div>
							<FaCreditCard className={className} />
						</div>
					</Tooltip>
				);
		}
	}

	if (paymentType === 'commissao') {
		return (
			<Tooltip title="Comissão">
				<div>
					<FaDollarSign />
				</div>
			</Tooltip>
		);
	}

	if (paymentType === 'pix') {
		return (
			<Tooltip title="Pix">
				<img src={LOGOPIX} alt="Pix" title="Pix" style={{ maxWidth: '100%', maxHeight: '20px' }} />
			</Tooltip>
		);
	}
};

const useStyles = makeStyles(theme => ({
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
		textAlign: 'center',
		whiteSpace: 'nowrap',
	},
	valor: {
		fontSize: 16,
		fontWeight: 700,
	},
	qtdVendas: {
		fontSize: 28,
	},
	vendas: {
		width: '100%',
		flexGrow: 1,
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	filtros: {
		position: 'relative',
		borderRadius: 20,
		marginBottom: 30,
		border: `solid 1px ${theme.palette.primary.main}67`,
		padding: '5px 10px',
		display: 'flex',
		alignItems: 'center',
	},
	export: {
		margin: 5,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 25,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 28,
			opacity: 1,
		},
	},
	exportHistory: {
		margin: 5,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#49bb57',
		fontSize: 25,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 28,
			opacity: 1,
		},
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 18,
	},
	tableDateRow: {
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		marginTop: 16,
		borderRadius: 10,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	tableRow: {
		// textAlign: 'center',
		// fontWeight: 700,
		// height: 30,
		// display: 'flex',
		// alignItems: 'center',
		// borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	rowTitle: {
		margin: 0,
		textAlign: 'center',
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			textAlign: 'left',
			marginTop: 24,
		},
	},
	rowText: {
		display: 'inline-block',
		fontWeight: 400,
		marginTop: 8,
	},
	truncate: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		alignItems: 'center',
	},
	hideOnXs: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	dataHoraXs: {
		[theme.breakpoints.down('xs')]: {
			fontSize: 10,
		},
	},
	paymentIcon: {
		fontSize: 20,
	},
	fixedFilter: {
		margin: 4,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		padding: '5px 10px',
		borderRadius: 30,
	},
	fixedFilterTitle: { fontWeight: 'bold', marginRight: 5 },
	fixedFilterValue: { fontSize: 11, marginRight: 10 },
	fixedFilterRemove: {
		cursor: 'pointer',
	},
	valorAprovado: {
		color: '#4caf50',
	},
	valorPendente: {
		color: '#ffc107',
	},
	valorCancelado: {
		color: '#f44336',
	},
}));

const VendaRow = memo(props => {
	const classes = useStyles();
	const [venda] = useState(props.venda || {});

	const { isMaster, isRestrito } = props;

	if (!venda) {
		return null;
	}

	return (
		<Grid item xs={12} sm={12} style={{ margin: '8px 0' }}>
			<Paper className={classes.tableRow} onClick={() => props.rowClick(venda.id)}>
				<Grid container style={{ padding: 20 }}>
					<Grid item xs={6} sm={1}>
						<div
							style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
						>
							<div style={{ marginRight: 10, fontSize: 20 }}>{getStatusIcon(venda.status.id)}</div>
							<div style={{ marginRight: 5, fontSize: 20 }}>
								{getPaymentIcon(venda.tipo_pagamento, venda.bandeira, classes.paymentIcon)}
							</div>
							<div style={{ marginRight: 2, fontSize: 10, width: 20, textAlign: 'left' }}>
								{getPaymentIndicator(venda.tipo_pagamento_id)}
								{venda.tipo_pagamento_id === 3 ? ` ${venda.parcelas}x` : ' '}
							</div>
						</div>
					</Grid>
					{props.viewEC && (
						<Grid item xs={6} sm className={classes.truncate}>
							<p className={classes.rowTitle} style={{ margin: 0 }}>
								{venda.estabelecimento.nome_fantasia}
								<br />
								<span className={classes.rowText}>
									{' '}
									{isMaster && !venda.markupCalculado && 'ㆍ'}
									{format(venda.created, 'DD/MM HH:mm')}
								</span>
							</p>
						</Grid>
					)}
					<Grid item xs={4} sm={1}>
						<p className={classes.rowTitle}>
							Valor <br />
							<span className={classes.rowText}>{Formatter.Real(venda.valores.total)} </span>
						</p>
					</Grid>
					{/* {agruparTaxas ? ( */}
					<Grid item xs={4} sm={1}>
						{!isRestrito && ( // Usuários restritos não podem ver valor liquido
							<p className={classes.rowTitle}>
								Taxas <br />{' '}
								<span className={classes.rowText}>
									{venda.valores.taxaTotal
										? Formatter.Real(venda.valores.taxaTotal)
										: Formatter.Real(venda.valores.total - venda.valores.liquido)}
								</span>
							</p>
						)}
					</Grid>
					{/* ) : (
						<>
							<Grid item xs={4} sm={1}>
								<p className={classes.rowTitle}>
									{' '}
									Taxa <br />{' '}
									<span className={classes.rowText}>{Formatter.Real(venda.valores.taxa)}</span>
								</p>
							</Grid>
							<Grid item xs={4} sm={1}>
								<p className={classes.rowTitle}>
									Split
									<br />
									<span className={classes.rowText}>{Formatter.Real(venda.valores.split)}</span>
								</p>
							</Grid>
						</>
					)} */}
					<Grid item xs={4} sm={1}>
						{!isRestrito && ( // Usuários restritos não podem ver valor liquido
							<p className={classes.rowTitle}>
								Liquido <br />
								<span className={classes.rowText}>{Formatter.Real(venda.valor_liquido)}</span>
							</p>
						)}
					</Grid>
					<Grid item xs={2} className={classes.hideOnXs}>
						<p className={classes.rowTitle}>
							Vencimento
							<br />
							<span className={classes.rowText}>
								{Isset(() => venda.tipo_pagamento) &&
									venda.tipo_pagamento === 'boleto' &&
									venda.dataRecebimento &&
									format(venda.dataRecebimento.substring(0, 10), 'DD/MM/YYYY')}
							</span>
						</p>
					</Grid>
					<Grid item xs={4} sm={1}>
						<p className={classes.rowTitle}>
							Pedido Venda <br />{' '}
							<span className={classes.rowText}>{venda.pedido_venda ? venda.pedido_venda : 'N/A'}</span>
						</p>
					</Grid>
					<Grid item xs={6} sm={2} className={classes.truncate}>
						<p className={classes.rowTitle}>
							Cliente <br />
							<span className={classes.rowText}>
								{Isset(() => venda.cliente.nome) ? venda.cliente.nome : <em>Sem Cliente</em>}
							</span>
						</p>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
});

const VendasList = memo(props => {
	const classes = useStyles();
	const matches = useMediaQuery(theme => theme.breakpoints.down('xs'));

	const { agruparTaxas } = props;

	const [vendas, setVendas] = useState([]);
	const [loading, setLoading] = useState(true);

	/**
	 * Faltam os valores:
	 * 
	 * - cliente.nome <string>
	 * - markupCalculado <string>
o	 * - markupEC <string>
	 * - markupFilho <string>
	 * - planoTaxa <string>
	 * - quantidadeAprovada <string>
	 * - quantidadeCancelada <string>
	 * - quantidadeEstornada <string>
	 * - quantidadeFalhada <string>
	 * - quantidadePendente <string>
	 * - split2 <string>
	 * - totalAprovado <number>
	 * - totalCancelado <string>
	 * - totalEstornado <string>
	 * - totalFalhado <string>
	 * - totalPendente <string>
	 * - valor <string>
	 */
	const [totais, setTotais] = useState({
		geral: {
			quantidade: 0,
			total: 0,
			taxa: 0,
			split: 0,
			liquido: 0,
		},
	});

	useEffect(() => {
		Isset(() => props.vendas) && setVendas(props.vendas);
		Isset(() => props.loading) && setLoading(props.loading);
		Isset(() => props.totais) && setTotais(props.totais);
	}, [props]);

	const usuario = useSelector(store => store.auth.usuario);
	const isMaster = useSelector(store => store.auth.isMaster);
	const isRestrito = usuario.grupo_id === 11;
	const viewEC = [1, 2, 4, 5, 7, 10].includes(usuario.grupo_id);

	if (!vendas) {
		return <ZEmpty height={200} text="Nenhuma Venda Encontrada." />;
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				{/* <Paper style={{ padding: 15 }}> */}
				{loading ? (
					<ZLoader height={200} />
				) : (
					<Grid container className={classes.vendas}>
						{/* <Grid container item className={classes.tableHeader}>
							<Grid item xs={1}>
								Hora
							</Grid>
							<Grid item xs={1}></Grid>
							{viewEC && (
								<Grid item xs>
									Estabelecimento
								</Grid>
							)}

							<Grid item sm={1} className={classes.hideOnXs}>
								Valor
							</Grid>
							<Grid item sm={1} className={classes.hideOnXs}>
								Taxa
							</Grid>
							{!agruparTaxas && (
								<Grid item sm={1} className={classes.hideOnXs}>
									Split
								</Grid>
							)}
							<Grid item xs={3} sm={1} style={{ fontSize: 16 }}>
								Líquido
							</Grid>
							<Grid item xs={2}>
								Vencimento
							</Grid>
							<Grid item xs={3} sm={2}>
								Cliente
							</Grid>
						</Grid> */}
						{vendas.map((venda, key) => {
							// if (
							// 	venda.tipo_pagamento_id === 1 &&
							// 	venda.status.id === 1 &&
							// 	moment(venda.dataRecebimento).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
							// 	(!props.filter.omni && !props.filter.omni2 && !props.filter.status.length && !props.filter.statusVenda.length)

							// ) {
							// 	return '';
							// }

							return (
								<React.Fragment key={key}>
									{venda.type === 'date' ? (
										<Grid container item className={classes.tableDateRow}>
											<Grid item xs={6} sm={agruparTaxas ? 5 : 4} style={{ paddingLeft: 20 }}>
												{format(
													venda.date,
													`${matches ? 'DD/MM/YYYY' : 'DD [de] MMMM [de] YYYY'}`,
													{
														locale: pt,
													}
												)}
											</Grid>
											{usuario.grupo_id !== 7 && (
												<>
													<Grid
														item
														xs={1}
														className={classes.hideOnXs}
														style={{ textAlign: 'center' }}
													>
														{Isset(() => totais[venda.date].bruto)
															? Formatter.Real(totais[venda.date].bruto)
															: ''}
													</Grid>
													<Grid
														item
														xs={agruparTaxas ? 1 : 2}
														className={classes.hideOnXs}
													></Grid>
													<Grid item xs={3} sm={1} style={{ textAlign: 'center' }}>
														{Isset(() => totais[venda.date].liquido)
															? Formatter.Real(totais[venda.date].liquido)
															: ''}
													</Grid>
												</>
											)}
										</Grid>
									) : (
										<VendaRow
											venda={venda}
											rowClick={props.rowClick}
											viewEC={viewEC}
											agruparTaxas={agruparTaxas}
											isMaster={isMaster}
											isRestrito={isRestrito}
										/>
									)}
								</React.Fragment>
							);
						})}
					</Grid>
				)}
				{/* </Paper> */}
			</Grid>
		</Grid>
	);
});

const FixedFilter = props => {
	const classes = useStyles();
	return (
		<div className={classes.fixedFilter}>
			<span className={classes.fixedFilterTitle}>{props.title}</span>
			<span className={classes.fixedFilterValue}>{props.value}</span>
			<span className={classes.fixedFilterRemove} onClick={props.onClick}>
				<FaTimes />
			</span>
		</div>
	);
};

const Vendas = props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const query = queryString.parse(useLocation().search);
	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');
	const axiosCancelSource = axios.CancelToken.source();

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const { history } = useReactRouter();
	const usuario = useSelector(store => store.auth.usuario);
	const token = useSelector(store => store.auth.token);
	// const localFilters = useSelector(store => store.system.filters[location.pathname] || {});
	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};
	const agruparTaxas = useSelector(store =>
		Isset(() => store.system.params['1'].valor) ? store.system.params['1'].valor : null
	);
	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);

	const queryFilter = {
		data_venda: { de: new Date().setHours(0, 0, 0, 0), ate: new Date() },
		estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
		status: [],
		omni: '',
		omni2: '',
		pedidoVenda: '',
		...filtersCache,
	};

	const [data, setData] = useState({
		vendas: [],
	});
	const [loading, setLoading] = useState(true);
	const [showExportMessage, setShowExportMessage] = useState(false);
	const [showLastExport, setShowLastExport] = useState(false);

	const [totais, setTotais] = useState({
		geral: {
			quantidade: 0,
			totalAprovado: 0,
			totalLiquido: 0,
			totalFalhado: 0,
			totalCancelado: 0,
			totalEstornado: 0,
			totalPendente: 0,
			taxa: 0,
			split: 0,
			liquido: 0,
			markup: 0,
		},
	});




	// Paginação

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(200);
	const [totalRows, setTotalRows] = useState(0);

	const isRestrito = usuario.grupo_id === 11;

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const getData = async () => {
		setData({ vendas: [] });
		setTotais({
			geral: {
				quantidade: 0,
				totalAprovado: 0,
				totalFalhado: 0,
				totalCancelado: 0,
				totalEstornado: 0,
				totalPendente: 0,
				taxa: 0,
				split: 0,
				liquido: 0,
				markup: 0,
			},
		});
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}vendas`, {
			cancelToken: axiosCancelSource.token,
			headers: { Authorization: `Bearer ${token}` },
			params: {
				page,
				limit: rowsPerPage,
				filters: {
					estabelecimentoId,
					...filtros,
				},
			},
		});
		// console.log({
		// 	page,
		// 	limit: rowsPerPage,
		// 	filters: {
		// 		estabelecimentoId,
		// 		...filtros,
		// 	},
		// });
		if (res.data.success) {
			setTotais(res.data.totais);
			setData({ vendas: res.data.vendas });
			setTotalRows(res.data.quantidade);
		}
		setLoading(false);
	}


	const [filtros, setFiltros] = useState({
		...queryFilter,
		pos: query.pos || '',
		omni2: Isset(() => query.estabelecimentoNome) ? query.estabelecimentoNome : queryFilter.omni2,
		//pedido_venda: Isset(() => query.estabelecimentoNome) ? query.estabelecimentoNome : queryFilter.pedido_venda,
		data_venda: {
			de: Isset(() => query.startDate) ? query.startDate : queryFilter.data_venda.de,
			ate: Isset(() => query.endDate) ? query.endDate : queryFilter.data_venda.ate,
		},
	});

	useEffect(() => {
		getData();
		// dispatch(setFilters(location.pathname, filtros));
		// eslint-disable-next-line react-hooks/exhaustive-deps
		return async () => {
			axiosCancelSource.cancel('Vendas Unmounted.');
		};
	}, [filtros, page, rowsPerPage]);

	const filtrosChange = async data => {
		setPage(1);
		data.data_venda = {
			de: data.date.start,
			ate: data.date.end,
		};

		data.estabelecimentoId = [estabelecimentoId];

		if (Array.isArray(data.estabelecimento)) {
			data.estabelecimentoId = [estabelecimentoId, ...data.estabelecimento];
		}

		data.status = data.statusVenda;
		data.pos = query.pos || '';

		if (Isset(() => query.estabelecimento) && query.estabelecimento !== '') {
			data.estabelecimentoId = [query.estabelecimento];
		}

		setFiltros(data);
	};

	useEffect(() => {
		setFiltros({
			...filtros,
			estabelecimentoId: Isset(() => query.estabelecimento) ? [query.estabelecimento] : [estabelecimentoId],
		});
	}, [estabelecimentoId]);

	const exportXls = async () => {
		setShowExportMessage(true);
		const vendas = await axios.get(`${config.get('apiUrl')}export/vendas`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params: {
				rows: totalRows,
				filters: {
					estabelecimentoId,
					...filtros,
				},
			},
		});

		if (vendas.status === 200) {
			const { 'content-disposition': contentDisposition, 'content-type': contentType } = vendas.headers;
			let filename = 'vendas.xlsx';
			if (contentDisposition.indexOf('filename="') !== -1) {
				filename = contentDisposition.slice(
					contentDisposition.indexOf('filename="') + 10,
					contentDisposition.length - 1
				);
			}

			FileDownload(vendas.data, filename, contentType);
		} else if (vendas.status === 201) {
			dispatch(addMessage({ type: 'warning', message: 'Sua nova exportação está sendo processada.' }));
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao exportar vendas.' }));
		}
		setShowExportMessage(false);
	};

	const exportXlsClick = async () => {
		if (totalRows > 20000) {
			setShowLastExport(true);
		} else {
			await exportXls();
		}
	};

	const newExport = async () => {
		await exportXls();
	};

	const rowClick = vendaId => {
		history.push(`/vendas/detalhes/${vendaId}`);
	};

	const hideExport = [5, 11];

	return (
		<Grid className={classes.vendas}>
			<ZTitle title="Vendas" display="flex" textAlign="center" fontSize="55px" />
			<Paper style={{ marginBottom: 10, padding: '5px 10px' }}>
				<Grid container>
					<FaFilter />
					<ZFiltros
						onChange={filtrosChange}
						dateRange={{
							default: 'custom',
							startDate: filtros.data_venda.de,
							endDate: filtros.data_venda.ate,
						}}
						// estabelecimento={{
						// 	defaultValue: filtros.estabelecimentoId,
						// 	multiple: true,
						// }}
						bandeira={{
							defaultValue: filtros.bandeira,
							multiple: true,
						}}
						formaPagamento={{
							defaultValue: filtros.formaPagamento,
							multiple: true,
						}}
						statusVenda={{
							default: filtros.status,
							multiple: true,
						}}
						omni={
							![2, 3].includes(usuario.grupo_id)
								? {
										default: filtros.omni,
										placeholder: 'Pesquisa por CPF/CNPJ',
								  }
								: false
						}
						omni2={
							![2, 3].includes(usuario.grupo_id)
								? {
										default: filtros.omni2,
										placeholder: 'Pesquisa por Estabelecimento',
								  }
								: false
						}
						omni3={{ default: filtros.omni3, placeholder: 'Pesquisa por Cliente' }}
						pedidoVenda={{
							default: filtros.pedido_venda,
							placeholder: 'Pesquisa por Pedido Venda',
						}}
					/>
					{query.pos && (
						<FixedFilter
							title="POS"
							value={query.pos}
							onClick={async () => {
								delete query.pos;
								await setFiltros({ ...filtros, pos: '' });
								history.push(`/vendas?${queryString.stringify(query)}`);
							}}
						/>
					)}
					{query.estabelecimento && (
						<FixedFilter
							title="Estabelecimento"
							value={query.estabelecimentoNome}
							onClick={async () => {
								delete query.estabelecimento;
								await setFiltros({
									...filtros,
									estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
								});
								history.push(`/vendas?${queryString.stringify(query)}`);
							}}
						/>
					)}
					{!hideExport.includes(usuario.grupo_id) && (
						<FiDownload className={classes.export} onClick={exportXlsClick} />
					)}
					{!hideExport.includes(usuario.grupo_id) && (
						<FaHistory className={classes.exportHistory} onClick={() => setShowLastExport(true)} />
					)}
				</Grid>
			</Paper>
			{usuario.grupo_id !== 7 && (
				<Paper style={{ padding: 15, marginBottom: 10 }}>
					<Grid container spacing={3}>
						<Grid item xs={6} sm={3} md={3}>
							<div className={classes.label}>
								Quantidade de Vendas
								<div className={clsx(classes.valor, classes.qtdVendas)}>
									{Formatter.Valor(
										Isset(() => totais.geral.quantidade) ? totais.geral.quantidade : 0,
										0,
										',',
										'.'
									)}
								</div>
							</div>
						</Grid>
						<Grid item sm={3} md={3} className={classes.hideOnXs} style={{ textAlign: 'right' }}>
							<div className={classes.label}>
								{`Aprovado `}
								<span className={clsx(classes.valor, classes.valorAprovado)}>
									{Formatter.Real(
										Isset(() => totais.geral.totalAprovado) ? totais.geral.totalAprovado : 0
									)}
								</span>
							</div>
							<div className={classes.label}>
								{`Pendente `}
								<span className={clsx(classes.valor, classes.valorPendente)}>
									{Formatter.Real(
										Isset(() => totais.geral.totalPendente) ? totais.geral.totalPendente : 0
									)}
								</span>
							</div>
							<div className={classes.label}>
								{`Falhado `}
								<span className={clsx(classes.valor, classes.valorCancelado)}>
									{Formatter.Real(
										Isset(() => totais.geral.totalFalhado) ? totais.geral.totalFalhado : 0
									)}
								</span>
							</div>
							<div className={classes.label}>
								{`Cancelado `}
								<span className={clsx(classes.valor, classes.valorCancelado)}>
									{Formatter.Real(
										Isset(() => totais.geral.totalCancelado) ? totais.geral.totalCancelado : 0
									)}
								</span>
							</div>
							<div className={classes.label}>
								{`Estornado `}
								<span className={clsx(classes.valor, classes.valorCancelado)}>
									{Formatter.Real(
										Isset(() => totais.geral.totalEstornado) ? totais.geral.totalEstornado : 0
									)}
								</span>
							</div>
						</Grid>
						{!isRestrito && ( // Usuários restritos não podem ver valor liquido
							<Grid item sm={3} md={3} className={classes.hideOnXs} style={{ textAlign: 'right' }}>
								{agruparTaxas ? (
									<div className={classes.label}>
										{`Taxa `}
										<span className={classes.valor}>
											{Formatter.Real(
												+totais.geral.taxa + +totais.geral.split2 + +totais.geral.planoTaxa
											)}
										</span>
									</div>
								) : (
									<>
										<div className={classes.label}>
											{`Taxa `}
											<span className={classes.valor}>
												{Formatter.Real(+totais.geral.taxa + +totais.geral.planoTaxa)}
											</span>
										</div>
										<div className={classes.label}>
											{`Split `}
											<span className={classes.valor}>
												{Formatter.Real(
													Isset(() => totais.geral.split2) ? totais.geral.split2 : 0
												)}
											</span>
										</div>
									</>
								)}
								<div className={classes.label}>
									{`Líquido `}
									<span className={classes.valor}>{Formatter.Real(totais.geral.totalLiquido ?? 0)}</span>
								</div>
							</Grid>
						)}
						<Grid item sm={3} md={3} className={classes.hideOnXs} style={{ textAlign: 'right' }}>
							{[1, 10].includes(usuario.grupo_id) && (
								<>
									<div className={classes.label}>
										{`Markup `}
										<span className={classes.valor}>
											{Formatter.Real(
												(Isset(() => totais.geral.markupCalculado)
													? +totais.geral.markupCalculado
													: 0) + totais.geral.markupEC // Não está definido no state, porém vem do backend
											)}
										</span>
									</div>
								</>
							)}
							{usuario.grupo_id === 2 && operarMarketplace && (
								<>
									<div className={classes.label}>
										{`Markup `}
										<span className={classes.valor}>{Formatter.Real(+totais.geral.markupEC)}</span>
									</div>
								</>
							)}
						</Grid>
					</Grid>
				</Paper>
			)}
			<VendasList
				vendas={data.vendas}
				loading={loading}
				totais={totais}
				rowClick={rowClick}
				agruparTaxas={agruparTaxas}
				filter={queryFilter}
			/>
			<ZPaginationDiv
				count={totalRows}
				rowsPerPage={rowsPerPage}
				page={page - 1}
				onChangePage={onChangePage}
				onChangeRowsPerPage={onChangeRowsPerPage}
				rowsPerPageOptions={[50, 100, 200]}
			/>
			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>
			{showLastExport && (
				<LastExport
					show={showLastExport}
					onClose={() => setShowLastExport(false)}
					tipo={'vendas'}
					newExport={newExport}
				/>
			)}
		</Grid>
	);
};

export default Vendas;
