/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import axios from 'axios';
import clsx from 'clsx';
import config from 'react-global-configuration';
import { Mask, Isset } from '../../utils';
import { TextField } from '@material-ui/core';
import { ZLoader } from '../system';

const useStyles = makeStyles(theme => ({
	pageTitle: {
		fontWeight: 'bold',
		fontSize: 18,
		marginBottom: 10,
	},
	planosTaxas: {
		position: 'relative',
	},
	tiposPagamentos: {
		position: 'absolute',
		paddingTop: 50,
		paddingBottom: 52,
		border: 'solid 1px #abc',
		borderRadius: 10,
		padding: 10,
		width: 'calc(100% - 30px)',
	},
	tipoPagamento: {
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
		'&:not(:last-child)': {
			borderBottom: 'solid 1px #bcd',
		},
	},
	tipoPagamentoBoleto: {
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
		width: 120,
	},
	bandeirasWrapper: {
		position: 'absolute',
		marginLeft: 120,
		width: 'calc(100% - 160px)',
		overflowX: 'auto',
	},
	bandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffffc0',
		height: 490,
		width: 105,
		marginRight: 10,
		float: 'left',
	},
	novaBandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffff',
		width: 160,
		marginRight: 10,
		float: 'left',
	},
	title: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 16,
		height: 22,
	},
	titleLong: {
		fontSize: 11,
	},
	deleteButton: {
		top: 2,
		right: 2,
		cursor: 'pointer',
		position: 'absolute',
		width: 20,
		height: 20,
		color: '#e53935',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		opacity: 0.5,
		'&:hover': {
			opacity: 1,
		},
	},
	fields: {
		marginTop: 8,
		marginBottom: 15,
		borderTop: 'solid 1px #bcd',
	},
	tipoPagamentoFields: {
		borderBottom: 'solid 1px #bcd',
	},
	taxaField: {
		padding: 7.45,
		'&::after': {
			content: '"%"',
			position: 'absolute',
			right: 4,
			fontSize: 12,
		},
		'& .MuiInputBase-input': {
			textAlign: 'right',
			height: '1em',
			padding: 0,
			paddingRight: 15,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -7,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
	},
	taxaFieldBoleto: {
		width: 100,
		padding: 7.45,
		'&::after': {
			content: '"R$"',
			position: 'absolute',
			left: 4,
			fontSize: 12,
		},
		'& .MuiInputBase-input': {
			textAlign: 'right',
			height: '1em',
			padding: 0,
			paddingRight: 15,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -7,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
	},
	saveButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		marginTop: 5,
	},
	adicionarButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		marginTop: 15,
	},
	taxaFieldReadOnly: {
		height: 30.8,
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 15,
		padding: 4,
	},
	boleto: {
		marginTop: 10,
		display: 'flex',
		border: 'solid 1px #abc',
		borderRadius: 10,
		padding: 10,
	},
}));

const TaxaField = memo(props => {
	const { readOnly, money } = props;
	const [value, setValue] = useState(Mask.Porcentagem(props.value));
	const [isTyping, setIsTyping] = useState(null);
	const classes = useStyles();

	const onChange = e => {
		const value = e.target.value;
		let valor = Mask.OnlyNumber(e.target.value) / 100;
		if (!money && valor > 100) {
			valor = 100;
		}
		setValue(Mask.Porcentagem(valor));

		if (money) {
			if (isTyping) {
				clearTimeout(isTyping);
			}

			const timeout = setTimeout(() => {
				if (props.onChange) {
					props.onChange({ target: { value } });
				}
			}, 500);

			setIsTyping(timeout);
		} else {
			if (props.onChange) {
				props.onChange(e);
			}
		}
	};

	if (readOnly) {
		if (money) {
			return <div className={classes.taxaFieldReadOnly}>{`R$ ${value}`}</div>;
		}
		return <div className={classes.taxaFieldReadOnly}>{`${value} %`}</div>;
	}

	return <TextField {...props} value={value} onChange={onChange} />;
});

export default memo(props => {
	const { planoTaxaId, readOnly } = props;

	const classes = useStyles();

	const token = useSelector(store => store.auth.token);

	const [taxas, setTaxas] = useState({});
	const [countBandeiras, setCountBandeiras] = useState(1);
	const [loading, setLoading] = useState(true);
	const [savingId, setSavingId] = useState(null);

	const [taxaBoleto, setTaxaBoleto] = useState(0);
	const [taxaBoletoChanged, setTaxaBoletoChanged] = useState(false);
	const [savingBoleto, setSavingBoleto] = useState(false);

	const getTaxas = async () => {
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}planos_taxas/getTaxaByPlanoTaxaId/${planoTaxaId}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (res.data.success) {
			setTaxas(res.data.taxas);
			setTaxaBoleto(res.data.taxaBoleto);
			setCountBandeiras(Object.keys(res.data.taxas).length);
		}
		setSavingId(null);
		setLoading(false);
	};

	useEffect(() => {
		getTaxas();
	}, [planoTaxaId]);

	const salvarButton = async bandeiraId => {
		const taxasBandeiras = taxas[bandeiraId].valores;
		setSavingId(bandeiraId);

		const parcelas = [];
		Object.keys(taxasBandeiras).forEach(tipoPagamentoId => {
			Object.keys(taxasBandeiras[tipoPagamentoId].valores).forEach(parcela => {
				const valor = taxasBandeiras[tipoPagamentoId].valores[parcela];
				parcelas.push({
					tipoPagamentoId,
					parcela,
					valor,
				});
			});
		});

		const data = {
			bandeiraId: taxas[bandeiraId].id,
			taxas: parcelas,
		};

		const res = await axios.post(`${config.get('apiUrl')}planos_taxas/setTaxaByPlanoTaxaId/${planoTaxaId}`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (res.data.success) {
			getTaxas();
		}
		setSavingId(null);
	};

	const salvarBoletoButton = async bandeiraId => {
		setSavingBoleto(true);

		const data = {
			taxaBoleto,
		};

		const res = await axios.post(
			`${config.get('apiUrl')}planos_taxas/setTaxaBoletoByPlanoTaxaId/${planoTaxaId}`,
			data,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		if (res.data.success) {
			getTaxas();
		}
		setTaxaBoletoChanged(false);
		setSavingBoleto(false);
	};

	const updateTaxa = (bandeiraId, tipoPagamentoId, parcela, valor) => {
		const valorAtual = taxas[bandeiraId].valores[tipoPagamentoId].valores[parcela];
		if (valorAtual !== valor) {
			setTaxas({
				...taxas,
				[bandeiraId]: {
					...taxas[bandeiraId],
					changed: true,
					valores: {
						...taxas[bandeiraId].valores,
						[tipoPagamentoId]: {
							...taxas[bandeiraId].valores[tipoPagamentoId],
							valores: {
								...taxas[bandeiraId].valores[tipoPagamentoId].valores,
								[parcela]: valor,
							},
						},
					},
				},
			});
		}
	};

	return (
		<>
			<div className={classes.boleto}>
				<div className={classes.tipoPagamentoBoleto}>Boleto</div>
				<div>
					{loading ? (
						<ZLoader height={30} size={14} />
					) : (
						<TaxaField
							money={1}
							readOnly={readOnly}
							className={classes.taxaFieldBoleto}
							value={taxaBoleto}
							// onChange={() => setTaxaBoletoChanged(true)}
							onChange={e => {
								setTaxaBoletoChanged(true);
								setTaxaBoleto(Mask.OnlyNumber(e.target.value) / 100);
							}}
							onFocus={e => {
								const length = e.target.value.length * 2;
								e.target.setSelectionRange(0, length);
							}}
						/>
					)}
				</div>
				{taxaBoletoChanged && (
					<div className={classes.saveButton} onClick={() => salvarBoletoButton()}>
						{savingBoleto ? <ZLoader height={30} size={14} /> : 'Salvar'}
					</div>
				)}
			</div>
			<div style={{ marginTop: 10 }}>
				<div className={classes.tiposPagamentos}>
					<div className={classes.tipoPagamento}>Crédito 1x</div>
					<div className={classes.tipoPagamento}>Crédito 2x</div>
					<div className={classes.tipoPagamento}>Crédito 3x</div>
					<div className={classes.tipoPagamento}>Crédito 4x</div>
					<div className={classes.tipoPagamento}>Crédito 5x</div>
					<div className={classes.tipoPagamento}>Crédito 6x</div>
					<div className={classes.tipoPagamento}>Crédito 7x</div>
					<div className={classes.tipoPagamento}>Crédito 8x</div>
					<div className={classes.tipoPagamento}>Crédito 9x</div>
					<div className={classes.tipoPagamento}>Crédito 10x</div>
					<div className={classes.tipoPagamento}>Crédito 11x</div>
					<div className={classes.tipoPagamento}>Crédito 12x</div>
					<div className={classes.tipoPagamento}>Débito</div>
				</div>

				{loading ? (
					<ZLoader height={300} />
				) : (
					<div className={classes.bandeirasWrapper}>
						<div style={{ width: (countBandeiras + 1) * 110 }}>
							{Object.keys(taxas).map(bandeiraId => (
								<div
									key={`Bandeira-${bandeiraId}`}
									className={classes.bandeira}
									style={{ backgroundColor: taxas[bandeiraId].changed ? '#fffdf2c0' : '#ffffffc0' }}
								>
									<div
										className={clsx(classes.title, {
											[classes.titleLong]:
												Isset(() => taxas[bandeiraId].titulo.length) &&
												taxas[bandeiraId].titulo.length > 10,
										})}
									>
										{taxas[bandeiraId].titulo}
									</div>
									<div className={classes.fields}>
										{Object.keys(taxas[bandeiraId].valores).map(tipoPagamentoId => (
											<div
												key={`${bandeiraId}${tipoPagamentoId}`}
												className={classes.tipoPagamentoFields}
											>
												{Object.keys(taxas[bandeiraId].valores[tipoPagamentoId].valores).map(
													parcela => (
														<TaxaField
															readOnly={readOnly}
															className={classes.taxaField}
															key={`${bandeiraId}${tipoPagamentoId}${parcela}`}
															value={
																taxas[bandeiraId].valores[tipoPagamentoId].valores[
																	parcela
																]
															}
															onBlur={e =>
																updateTaxa(
																	bandeiraId,
																	tipoPagamentoId,
																	parcela,
																	Mask.OnlyNumber(e.target.value) / 100
																)
															}
															onFocus={e => {
																const length = e.target.value.length * 2;
																e.target.setSelectionRange(0, length);
															}}
														/>
													)
												)}
											</div>
										))}
									</div>
									{taxas[bandeiraId].changed && (
										<div className={classes.saveButton} onClick={() => salvarButton(bandeiraId)}>
											{savingId === bandeiraId ? <ZLoader height={30} size={14} /> : 'Salvar'}
										</div>
									)}
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</>
	);
});
