import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
	chart: {
		position: 'relative',
		height: 300,
		width: '100%',
	},
	legendas: {
		width: 270,
		margin: 'auto',
	},
	percent: {
		fontSize: 11,
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	valorTotal: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
	},
}));
