import React, { useEffect, useState } from 'react';
import { Dialog, Grid, TableCell, FormControl, Paper } from '@material-ui/core';
import axios from 'axios';
import config from 'react-global-configuration';
import { ZLoader } from '../system';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ZModalSelect from './ZModalSelect';
import ZModalTextField from './ZModalTextField';
import ZModalTable from './ZModalTable';
import _without from 'lodash/without';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 500,
		fontSize: 30,
		margin: 8,
		display: 'flex',
		justifyContent: 'space-around',
	},

	vendas: {
		width: '100%',
		marginBottom: '2rem',
		flexDirection: 'row',
	},
	brandTitle: {
		textAlign: 'center',
		fontWeight: 700,
	},

	rows: {
		marginBottom: 10,
		marginTop: 10,
		transition: '0.4s 0.3s',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	button: {
		borderColor: `${theme.palette.primary.main}`,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		backgroundColor: `${theme.palette.primary.main}`,

		borderWidth: '2px',
		'&:hover': {
			'&:nth-child(1)': {
				backgroundColor: `${theme.palette.primary.main}`,
				color: theme.palette.getContrastText(theme.palette.primary.main),
			},
		},
	},
}));

const tipos = ['Valor Liquido', 'Valor Bruto'];
const ZCalculadoraModal = props => {
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const [loading, setLoading] = useState(true);
	const [plano, setPlano] = useState();
	const [tipo, setTipo] = useState();
	const [valor, setValor] = useState();
	const [bandeira, setBandeira] = useState([]);
	const [formaPagamento] = useState([]);
	const [taxa, setTaxa] = useState();
	const [table, setTable] = useState(false);
	const [taxaDebito, setTaxaDebito] = useState();

	const handle = (e, setter) => {
		const {
			target: { value },
		} = e;
		if (setter === setBandeira) {
			return setter(value);
		}
		setter(value);
	};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getPlano = async () => {
		setLoading(true);
		let res = await axios.get(`${config.get('apiUrl')}planos_de_vendas/${props.id}`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			setPlano(res.data.plano);
			setLoading(false);
		}
	};
	useEffect(() => {
		getPlano();
	}, [getPlano]);

	const handleCalc = (valor, tipo, taxa) => {
		if (valor) {
			if (tipo === 'Valor Liquido') {
				const result = parseFloat(valor) * ((100 - parseFloat(taxa)) / 100);
				return <TableCell>{`R$${result.toFixed(2)}`}</TableCell>;
			} else {
				const result = (parseFloat(valor) * 100) / (100 - parseFloat(taxa));
				return <TableCell> {`R$${result.toFixed(2)}`}</TableCell>;
			}
		}
	};

	const handleDelete = (e, value) => {
		e.preventDefault();
		setBandeira(current => _without(current, value));
		Calcular();
	};
	useEffect(() => {
		return Calcular();
	});

	const checker = bandeira => {
		if (bandeira === 'MasterCard') {
			return 'Maestro';
		}
		if (bandeira === 'Visa') {
			return 'Visa Electron';
		}
		if (bandeira === 'American Express') {
			return 'Visa Electron';
		} else {
			return bandeira;
		}
	};

	const Calcular = () => {
		if (bandeira.length) {
			const firstObject = [];
			let secondObject = [];
			for (let i = 0; i < bandeira.length; i++) {
				firstObject[bandeira[i]] = plano.fees.credit[bandeira[i]];
			}
			Object.keys(firstObject).forEach((item, index) => {
				secondObject[index] = Object.values(firstObject[item]);
				setTaxa(secondObject);
			});

			let debitObject = [];

			for (let i = 0; i < bandeira.length; i++) {
				let trychecker = checker(bandeira[i]);

				debitObject[i] = plano.fees.debit[trychecker];
			}
			setTaxaDebito(debitObject);

			setTable(true);
		}
	};

	return (
		<>
			{loading && <ZLoader height={200} />}
			{plano && (
				<>
					<Dialog open={props.open} onClose={props.onClose} maxWidth="md" fullWidth>
						<Grid className={classes.vendas}>
							<Paper style={{ overflowX: 'auto' }}>
								<Grid className={classes.header} alignItems="center">
									{plano.name || 'Carregando...'}
									<Grid item>
										<FormControl variant="standard">
											<ZModalSelect
												label={'Tipo Calculo'}
												selects={tipos}
												value={tipo}
												handleChange={e => handle(e, setTipo)}
											/>
										</FormControl>

										<ZModalTextField
											label="Insira Valor"
											placeholder="Valor"
											value={valor}
											handleChange={e => handle(e, setValor)}
										/>
										{formaPagamento && (
											<ZModalSelect
												multiple={true}
												value={bandeira}
												label="Bandeiras"
												handleDelete={handleDelete}
												selects={
													plano?.fees?.credit
														? Object.keys(plano?.fees?.credit)
														: ['primeiro']
												}
												handleChange={e => handle(e, setBandeira)}
											/>
										)}
									</Grid>
								</Grid>

								{table && bandeira.length ? (
									<ZModalTable
										taxa={taxa}
										bandeira={bandeira}
										forma={formaPagamento}
										tipo={tipo}
										valor={valor}
										taxaDebito={taxaDebito}
										handle={handleCalc}
									/>
								) : (
									<></>
								)}
							</Paper>
						</Grid>
					</Dialog>
				</>
			)}
		</>
	);
};

export default ZCalculadoraModal;
