/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// import { hot } from 'react-hot-loader/root';
import React, { useEffect } from 'react';
import Main from './components/Main';
import { RecoilRoot } from 'recoil';

import configuration from 'react-global-configuration';
import config from './Config';

import { Provider } from 'react-redux';
import Store from './store';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

configuration.set(config, { freeze: false });

if (!('toJSON' in Error.prototype)) {
	// eslint-disable-next-line no-extend-native
	Object.defineProperty(Error.prototype, 'toJSON', {
		value() {
			const alt = {};

			Object.getOwnPropertyNames(this).forEach(function aux(key) {
				alt[key] = this[key];
			}, this);

			return alt;
		},
		configurable: true,
		writable: true,
	});
}

function App() {
	const [snackVisibility, setSnackVisibility] = React.useState(false);
	useEffect(() => {
		const version = localStorage.getItem('1pay-version');

		if (version !== '1.0.0') {
			localStorage.removeItem('1pay-filters');
			localStorage.setItem('1pay-version', '1.0.0');
		}
		if (process.env.REACT_APP_ENV !== 'production') setSnackVisibility(true);
	}, []);

	return (
		<div className="App">
			<Router basename={process.env.REACT_APP_BASENAME}>
				<Provider store={Store}>
					<RecoilRoot>
						<Main />
					</RecoilRoot>
				</Provider>
			</Router>
			{/* <Snackbar open={snackVisibility}>
				<Alert severity="warning">
					Environment: {process.env.NODE_ENV} / {process.env.REACT_APP_ENV}
					<p>API URL: {process.env.REACT_APP_API_URL}</p>
				</Alert>
			</Snackbar> */}
		</div>
	);
}

export default App;
