/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import { Isset } from '../../../utils';

import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem } from '@material-ui/core';
import { format } from 'date-fns';

const useStyles = makeStyles(theme => ({
	filtros: {
		position: 'relative',
		display: 'inline-flex',
	},
	filtroText: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		padding: '5px 10px',
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 30,
		marginLeft: 10,
		fontWeight: 700,
		fontSize: 12,
		color: theme.palette.primary.main,
	},
	filtroTextSelectedMultiple: {
		backgroundColor: `${theme.palette.primary.main}`,
		color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		'& .MuiSelect-select': {
			color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		},
		padding: '2px 10px',
		minHeight: 29,
		maxWidth: 340,
	},
	clearBtn: {
		marginLeft: 6,
	},
	filtroSelect: {
		fontWeight: 700,
		fontSize: 12,
		'&:before': {
			borderBottom: 'none !important',
		},
		'&:after': {
			borderBottom: 'none !important',
		},
		'& .MuiSelect-select': {
			padding: '2px 10px !important',
			marginRight: 10,
		},
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
		height: 16,
		maxWidth: 130,
		justifyContent: 'flex-start',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'& .MuiChip-label': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: 'inline-block',
		},
	},
	menuItem: {
		flexDirection: 'column',
	},
}));

const anoAtual = format(new Date(), 'YYYY');
const anos = Array.apply(null, Array(10)).map((a, i) => +anoAtual - i);

const Ano = props => {
	const classes = useStyles();

	let value = '';
	if (Isset(() => props.defaultValue) && props.defaultValue) {
		value = props.defaultValue;
	}

	const [ano, setAno] = useState(value);

	const anoChange = e => {
		let value = e.target.value;
		setAno(value);
		if (props.onChange) {
			props.onChange(value);
		}
	};

	return (
		<div className={classes.estabelecimento}>
			<div className={classes.filtroText}>
				<Select
					multiple={props.multiple || false}
					className={classes.filtroSelect}
					value={ano}
					disabled={props.disabled}
					onChange={anoChange}
					displayEmpty
				>
					{anos.map((a, i) => (
						<MenuItem key={i} value={`${a}`} className={classes.menuItem}>
							{a}
						</MenuItem>
					))}
				</Select>
			</div>
		</div>
	);
};

export default Ano;
