import axios from 'axios';
import config from 'react-global-configuration';
import { Isset } from '../utils';

// Action Types

export const Types = {
	LOGIN: 'auth/LOGIN',
	LOGOUT: 'auth/LOGOUT',
	LOGIN_AS_EC: 'auth/LOGIN_AS_EC',
};

// Reducer

const initialState = {
	logado: false,
	token: null,
	mainToken: localStorage.getItem('mainToken'),
	usuario: {},
	estabelecimento: {},
	isMaster: localStorage.getItem('isMaster'),
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case Types.LOGIN_AS_EC:
			localStorage.setItem('mainToken', state.token);
			localStorage.setItem('token', action.ecUserToken);
			return {
				...state,
				mainToken: state.token,
				token: action.ecUserToken,
			};
		case Types.LOGIN:
			localStorage.setItem('token', action.usuario.token);
			if (Isset(() => action.usuario.isMaster)) {
				localStorage.setItem('isMaster', action.usuario.isMaster || false);
			}

			return {
				...state,
				logado: true,
				token: action.usuario.token,
				usuario: action.usuario,
				estabelecimento: action.usuario.estabelecimento || {},
				isMaster: action.usuario.isMaster || state.isMaster,
			};

		case Types.LOGOUT:
			if (state.mainToken !== null) {
				localStorage.setItem('token', state.mainToken);
				localStorage.setItem('mainToken', null);
			} else {
				localStorage.setItem('token', null);
				localStorage.setItem('mainToken', null);
				localStorage.setItem('isMaster', false);
			}

			return {
				...state,
				...initialState,
			};

		default:
			return state;
	}
};

export default reducer;

// Action Creators

export const checkLogin = () => async dispatch => {
	// link personalizado
	let userCadastro = window.location.pathname;
	const urlCadastro = `/estabelecimentos/pre-cadastro/comercial/53868a2b864a5ece0876a735af6ca26cda14d068`;

	if (userCadastro === urlCadastro) {
		// Verifica se o token foi encontrado na URL
		localStorage.setItem('token', '53868a2b864a5ece0876a735af6ca26cda14d068');
		let tokenCadastro = localStorage.getItem('token');
		const urlSemToken = urlCadastro.replace('/53868a2b864a5ece0876a735af6ca26cda14d068', '');
		window.location.pathname = urlSemToken;
		if (localStorage.setItem('token', '53868a2b864a5ece0876a735af6ca26cda14d068')) {
			if (tokenCadastro) {
				let res = await axios.post(`${config.get('apiUrl')}autenticar`, {
					tokenCadastro,
				});

				if (res.data.success) {
					return dispatch(login(res.data.usuario));
				} else {
					return dispatch(logout());
				}
			} else {
				return false;
			}
		}
	}

	let token = localStorage.getItem('token');

	if (token) {
		let res = await axios.post(`${config.get('apiUrl')}autenticar`, {
			token,
		});

		if (res.data.success) {
			return dispatch(login(res.data.usuario));
		} else {
			return dispatch(logout());
		}
	} else {
		return false;
	}
};

export const login = usuario => ({
	type: Types.LOGIN,
	usuario,
});

export const logout = () => ({
	type: Types.LOGOUT,
});

export const loginAsEC = ecId => async dispatch => {
	let token = localStorage.getItem('token');

	if (token) {
		let res = await axios.get(`${config.get('apiUrl')}login-as-ec/${ecId}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (res.data.success) {
			return dispatch(authenticateAsEC(res.data.usuario.token));
		} else {
			return false;
		}
	}
	return false;
};

export const authenticateAsEC = newToken => ({
	type: Types.LOGIN_AS_EC,
	ecUserToken: newToken,
});
