import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import config from 'react-global-configuration';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';

const useStyles = makeStyles(theme => ({
	dialogOK: {
		fontSize: 16,
		color: 'white',
		backgroundColor: '#1b901b',
		'&:hover': {
			backgroundColor: '#1b901bdd',
		},
	},
}));

const TermoOnePay = props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const token = useSelector(store => store.auth.token);

	const aceitarTermo = async () => {
		const res = await axios.post(
			`${config.get('apiUrl')}estabelecimentos/aceitar-termos`,
			{},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		if (res.data.success) {
			if (props.onAceitar) {
				props.onAceitar();
			}
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao aceitar os termos.' }));
		}
	};
	return (
		<>
			<Dialog
				fullWidth
				maxWidth="md"
				open={props.open}
				onClose={() => {}}
				disableBackdropClick
				disableEscapeKeyDown
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{'Termos e Condições'}</DialogTitle>
				<DialogContent>
					<div>
						<p>
							<strong>CONTRATO DE CREDENCIAMENTO E ADESÃO DE ESTABELCIMENTOS AO SISTEMA ONE PAY</strong>
						</p>
						<p>
							Pelo presente instrumento particular e na melhor forma de direito, feito em duas vias de
							igual teor e firmado juntamente com duas testemunhas instrumentárias e presenciais, os
							signatários adiante nomeados e qualificados, têm, entre si, certa e ajustada o presente que
							se regerá pelas cláusulas e condições mutuamente aceita e reciprocamente outorgadas, a saber
						</p>
						<p>I - DAS PARTES</p>
						<p>ONE PAY</p>
						<p>
							ONE PAY INTERMEDIAÇÕES EM PAGAMENTOS LTDA., pessoa jurídica de direito privado, inscrita no
							CNPJ/MF sob o nº 33.599.330/0001-02, com sede na Rua do Rocio, nº 220, cj. 101, CEP:
							04552-000, Vila Olimpia, São Paulo – SP, doravante denominado simplesmente como ONE PAY.
						</p>
						<p>ESTABELECIMENTO COMERCIAL</p>
						<p>
							Devidamente qualificado na Proposta de Credenciamento assinada ONE PAY, doravante denominado
							simplesmente como ESTABELECIMENTO.
						</p>
						<p>CONSIDERANDO QUE:</p>
						<p>
							(i) A ONE PAY se dedica, dentre outras atividades, à prestação de serviços a pessoas
							jurídicas e/ou pessoas físicas, visando à realização de Transações mediante a utilização de
							meios de pagamento eletrônicos, tais como cartões de crédito e de débito; e (ii) o
							Estabelecimento pretende contratar os serviços prestados pela ONE PAY para realização de
							Transações mediante a utilização de meios de pagamento eletrônicos; RESOLVEM as Partes
							firmar este Contrato de Credenciamento e Adesão de Estabelecimentos ao Sistema ONE PAY
							(“Contrato”), que se regerá pelas seguintes cláusulas e condições:
						</p>
						<p>1. DEFINIÇÕES</p>
						<p>
							1.1. Os termos grafados em maiúscula usados, mas não definidos neste Contrato, deverão ter o
							significado atribuído como Anexo
						</p>
						<p>
							1.1, o qual é parte integrante deste Contrato para todos os fins legais. 1.2. Conforme
							utilizados neste Contrato: os termos (a) “ou” não é exclusivo (a menos que o contexto exija
							interpretação diversa); (b) “incluindo” significa “incluindo, mas não se limitando a”; (c)
							palavras no singular incluem o plural, e vice versa; (d) palavras aplicáveis a um gênero se
							aplicam a todos os gêneros; (e) os termos “deste instrumento”, “neste instrumento”, “por
							este instrumento”, “a este instrumento” e expressões derivadas ou similares se referem ao
							Contrato na íntegra, incluindo seus Anexos; (f) os termos “Cláusula” e “Anexo” se referem a
							uma Cláusula ou Anexo específico deste Contrato; e (g) a expressão “de acordo com”,
							“conforme descrito em”, “observados os termos de” uma Cláusula específica deste Contrato, ou
							palavras de significado similar, deverão se referir à Cláusula em questão. 1.3. Os títulos
							foram inseridos apenas por conveniência, não devendo ser considerados para limitar,
							restringir e/ou afetar qualquer das disposições previstas. 1.4. Uma referência a qualquer
							pessoa inclui os sucessores e cessionários autorizados dessa pessoa. 1.5. Qualquer
							referência a “dias” significa dias corridos, a menos que “dias úteis” esteja expressamente
							previsto. 1.6. Uma referência a qualquer lei ou norma significa a referida lei ou norma
							conforme alterada, modificada, codificada ou substituída, no todo ou em parte. 1.7. Os
							Anexos identificados neste Contrato são parte integrante deste instrumento para todos os
							fins (“Anexos”).
						</p>
						<p>2. OBJETO</p>
						<p>
							2.1. Este Contrato tem por objeto a prestação dos seguintes serviços ao Estabelecimento: (i)
							Captura, roteamento, transmissão e processamento das Transações, mediante o credenciamento
							do Estabelecimento para integrar o Sistema ONE PAY, habilitando-o para aceitação de Cartões
							e Meios de Pagamento; (ii) Administração das Transações e liquidação financeira de seu Valor
							Líquido, desde que cumpridos os termos e condições deste Contrato; (iii) O fornecimento de
							Materiais e o aluguel de Equipamentos que possibilitem a Captura eletrônica das Transações;
							(iv) A coordenação e a manutenção adequada da operacionalidade do Sistema ONE PAY; (v) A
							disponibilização do Sistema ONE PAY ao Estabelecimento; e (vi) Fornecimento de Produtos.
							2.2. Os serviços relacionados acima são prestados ao Estabelecimento pela ONE PAY e/ou pelos
							integrantes dos Instituidores de Arranjo de Pagamento dos quais o ONE PAY faça ou venha a
							fazer parte.
						</p>
						<p>3. CREDENCIAMENTO AO SISTEMA ONE PAY</p>
						<p>
							3.1. Canais de acesso. O credenciamento do Estabelecimento ao Sistema ONE PAY poderá ocorrer
							pelos seguintes canais: bancos, telemarketing, empresas terceiras ou parceiras, área
							comercial da ONE PAY, auto credenciamento ou ainda por outros canais que vierem a ser
							disponibilizados pela ONE PAY.
						</p>
						<p>
							3.2. Documentação. A proposta de adesão a este Contrato consiste no preenchimento das
							informações indicadas na Proposta de Credenciamento e entrega dos documentos solicitados
							pela ONE PAY.
						</p>
						<p>
							3.3. Requisitos. O credenciamento e manutenção do Contrato com o Estabelecimento e
							participação do Estabelecimento no Sistema ONE PAY está condicionado à sua análise
							cadastral, bem como à análise cadastral de seus
							sócios/representantes/proprietários/acionistas, dentre outros critérios de análise adotados
							pela ONE PAY, sendo que esta poderá recusar o credenciamento do Estabelecimento que não
							esteja em conformidade com os seus critérios de admissibilidade.
						</p>
						<p>
							3.4. Adesão ao Contrato. O Estabelecimento manifesta sua vontade de aderir a este Contrato
							por meio da Proposta de Credenciamento e/ou realização de qualquer Transação e/ou uso dos
							Produtos. A adesão do Estabelecimento a este Contrato passa a produzir efeitos com a
							aceitação da Proposta de Credenciamento pela ONE PAY. A adesão do Estabelecimento ao
							Contrato implica a sua automática e irrevogável aceitação de pagar a Remuneração e os
							encargos definidos na Proposta de Credenciamento e no Contrato. A partir da aceitação do
							Estabelecimento pela ONE PAY poderão ser gerados automaticamente (i) o número de
							identificação de cada Unidade Comercial do Estabelecimento e ou do Equipamento fornecido ao
							Estabelecimento e (ii) a ordem de instalação ou homologação do Equipamento contratado.
						</p>
						<p>
							3.5. O credenciamento do Estabelecimento pela ONE PAY não confere ao Estabelecimento
							atestado de regularidade para qualquer finalidade, nem tampouco dispensa a realização de
							verificações adicionais, caso a ONE PAY assim entender necessário.
						</p>
						<p>
							3.6. Vinculação de Unidades Comerciais. O Estabelecimento poderá requerer a vinculação de
							uma ou mais unidades comerciais sob sua responsabilidade ao seu cadastro no sistema ONE PAY,
							sendo que, neste caso, a ONE PAY avaliará o pedido conforme critérios próprios podendo
							aprová-lo ou recusá-lo, e a vinculação estará sujeita à cobrança para sua realização.
						</p>
						<p>
							3.7. Produtos. Será permitido ao Estabelecimento, a qualquer momento, solicitar a
							contratação ou o cancelamento de determinados Produtos, desde que cumpridos os requisitos
							específicos para tanto, detalhados nos respectivos Anexos e/ou aditivos ao Contrato. O
							Estabelecimento reconhece que a contratação de um Produto será realizada quando solicitada a
							ONE PAY e/ou quando for realizada qualquer Transação relacionada ao Produto, o que ocorrer
							primeiro, o que resultará na aceitação de todos os termos e condições do Anexo e/ou aditivo
							específico relacionado a tal Produto.
						</p>
						<p>
							3.8. Usuários. O Estabelecimento indicará na Proposta de Credenciamento o usuário master e
							seus dados para contato. A ONE PAY fornecerá ao usuário master instruções para primeiro
							acesso ao Portal do Cliente e cadastramento de sua senha pessoal. O usuário master poderá
							cadastrar outros usuários (usuários dependentes) e seus perfis de acesso.
						</p>
						<p>
							3.8.1. O Estabelecimento é responsável pelos dados do usuário master e dos usuários
							dependentes, os quais serão considerados representantes do Estabelecimento no âmbito do
							Contrato, reconhecendo desde já o Estabelecimento como existentes, válidos e eficazes todos
							os atos que forem praticados pelo usuário master e pelos usuários dependentes, por qualquer
							que seja o canal utilizado, inclusive por meio do Portal do Cliente e da Central de
							Atendimento, vinculando o Estabelecimento para todos os fins e efeitos de direito;
						</p>
						<p>
							3.8.2. O usuário master e os demais usuários dependentes se identificarão perante a ONE PAY
							por meio de suas respectivas senhas pessoais, que serão por eles cadastradas via Portal do
							Cliente. O Estabelecimento assume inteira responsabilidade por todas as instruções e
							autorizações passadas a ONE PAY com o uso das senhas, isentando o ONE PAY de qualquer
							responsabilidade, seja a que título for, decorrente de eventuais utilizações inadequadas ou
							indevidas das senhas, inclusive por alguém que não seja o usuário master ou um usuário
							dependente;
						</p>
						<p>4. OBRIGAÇÕES DO ESTABELECIMENTO</p>
						<p>
							4.1. O Estabelecimento se obriga a: (i) fornecer a ONE PAY todas as informações que lhe
							sejam solicitadas, incluindo, mas não se limitando a, dados cadastrais e/ou bancários; (ii)
							manter tais informações atualizadas durante toda a vigência deste Contrato; e (iii) informar
							a ONE PAY a respeito de qualquer alienação de seus ativos ou ponto comercial. O
							Estabelecimento deverá fornecer tais informações no prazo de 5 (cinco) dias após solicitação
							do ONE PAY ou após a ocorrência de alteração nas informações, conforme aplicável. O
							Estabelecimento responde, nos termos da lei, pela veracidade das informações prestadas a ONE
							PAY e por eventual divergência entre os dados informados a ONE PAY e os dados reais e/ou
							oficiais;
						</p>
						<p>
							4.2. O Estabelecimento não poderá, sem autorização prévia e expressa da ONE PAY, efetuar
							Transações em segmentos ou ramos de atividade diferentes daquele(s) constante(s) na sua
							Proposta de Credenciamento, ainda que esses segmentos constem de seu objeto social;
						</p>
						<p>
							4.3. O Estabelecimento compromete-se, ainda, a seguir todas as regras e exigências
							determinadas pelos Instituidores de Arranjo de Pagamento, pelo mercado de meios de
							pagamento, pelo Sistema de Pagamentos Brasileiro e pela legislação;
						</p>
						<p>
							4.4. O Estabelecimento é responsável e obriga-se a reembolsar, defender e manter a ONE PAY
							integralmente indene por quaisquer Perdas incorridas pela ONE PAY ou qualquer terceiro, a
							qualquer tempo, inclusive após o decurso do prazo deste Contrato, em decorrência direta ou
							indireta deste Contrato e/ou do descumprimento de quaisquer obrigações atribuídas ao
							Estabelecimento de acordo com o presente Contrato, as regulamentações, exigências e regras
							operacionais determinadas pelos Instituidores de Arranjo de Pagamento, pelo mercado de meios
							de pagamento, pelo Sistema de Pagamentos Brasileiro, pelo BACEN, órgãos reguladores e/ou
							pela legislação, bem como em decorrência de falhas, suspeita de fraude e/ou fraude
							comprovada provocada pelo Estabelecimento, sendo permitido a ONE PAY, em qualquer caso, a
							retenção e/ou compensação de valores devidos ao Estabelecimento para cobrir eventuais
							Perdas. A ONE PAY não será solidária ou subsidiariamente responsável pelo cumprimento de
							quaisquer obrigações imputáveis ao Estabelecimento, sendo este o único responsável pelas
							consequências decorrentes do descumprimento de qualquer obrigação desta natureza;
						</p>
						<p>
							4.5. O Estabelecimento autoriza a ONE PAY a, sempre que este julgar necessário, diretamente
							ou por terceiros designados pela ONE PAY, vistoriar o atendimento ao disposto neste
							Contrato;
						</p>
						<p>
							4.6. Reembolso. O Estabelecimento se obriga a reembolsar a ONE PAY de quaisquer despesas que
							a ONE PAY venha a incorrer para o cumprimento de ordem de terceiro com relação ao
							Estabelecimento, incluindo, sem limitar-se, atendimento de ofícios judiciais, bloqueios,
							penhoras e arrestos.
						</p>
						<p>5. DECLARAÇÕES E GARANTIAS</p>
						<p>
							5.1. O Estabelecimento declara e garante a ONE PAY, no ato de assinatura da Proposta de
							Credenciamento, que: (i) é sociedade, empresário ou microempresário individual e ou pessoa
							física, devidamente constituído com existência válida e em situação regular segundo as leis
							do Brasil, bem como está devidamente autorizado a desempenhar as atividades descritas neste
							Contrato, na Proposta de Credenciamento e em seu objeto social; (ii) nenhum registro,
							consentimento, autorização, aprovação, licença ou qualificação é exigido para a celebração e
							cumprimento, pelo Estabelecimento, de suas obrigações nos termos deste Contrato, tendo sido
							satisfeitos todos os requisitos legais e estatutários necessários para tanto; (iii) as
							obrigações assumidas neste Contrato constituem obrigações legalmente válidas e vinculantes
							do Estabelecimento, exequíveis de acordo com os seus termos e condições; (iv) os documentos
							e informações fornecidos a ONE PAY são verídicos, corretos, completos e estão atualizados
							até a data em que foram fornecidos a ONE PAY; (v) não omitiu nem omitirá nenhum fato, de
							qualquer natureza, que seja de seu conhecimento e que possa afetar a ONE PAY e/ou este
							Contrato; e (vi) não desenvolver atividades ilícitas e/ou diversas das previamente
							informadas a ONE PAY na Proposta de Credenciamento;
						</p>
						<p>
							5.2. As declarações e garantias dispostas na Cláusula 5.1 são completas e precisas, devendo
							assim permanecer ao longo da vigência deste Contrato. O Estabelecimento compromete-se a
							notificar, na mesma data, a ONE PAY caso quaisquer declarações ou garantias aqui prestadas
							se tornem total ou parcialmente inverídicas, incompletas ou incorretas.
						</p>
						<p>6. EQUIPAMENTOS</p>
						<p>
							6.1. O Estabelecimento obriga-se, com relação aos Equipamentos, a cumprir os termos e
							condições estabelecidos pela ONE PAY.
						</p>
						<p>
							6.2. A ONE PAY poderá fornecer ao Estabelecimento Equipamentos, bem como poderá, a seu único
							e exclusivo critério, homologar os Equipamentos de propriedade do Estabelecimento ou de
							terceiros para integração ao Sistema ONE PAY;
						</p>
						<p>
							6.3. A ONE PAY não terá qualquer responsabilidade com relação aos Equipamentos ou outros
							Materiais adquiridos ou contratados pelo Estabelecimento com terceiros, ainda que
							credenciados ou homologados pela ONE PAY.
						</p>
						<p>7. TRANSAÇÕES</p>
						<p>
							7.1. O Estabelecimento obriga-se, por si e por toda e qualquer pessoa que, em seu nome,
							venha a ter acesso, por meio do Sistema ONE PAY, armazenar, transmitir ou processar
							pagamentos, a observar todos os limites, condições de segurança e operacionais determinadas
							neste Contrato ou que venham a ser adotadas pela ONE PAY, para evitar erros e fraudes nas
							Transações, incluindo, mas não se limitando as obrigações listadas abaixo: (i) Checar todas
							as informações constantes do Cartão, incluindo: (a) o prazo de validade; (b) se o Cartão não
							está adulterado ou rasurado; (c) nome do Portador; (d) a assinatura do Portador (quando for
							o caso); (e) as identificações utilizadas pelos Instituidores de Arranjo de Pagamento, tais
							como hologramas tridimensionais, marcas de segurança, letras estilizadas, dentre outras; (f)
							os últimos 4 (quatro) dígitos do número do Cartão que devem coincidir com os dígitos
							impressos no Comprovante de Vendas; (g) o código de segurança formado por 3 (três) dígitos,
							no verso do Cartão; e (h) os Códigos de Autorização apresentados pela ONE PAY. (ii) Entregar
							ao Portador, a “via do cliente” do Comprovante de Venda, quando emitido pelo Equipamento.
							(iii) Cumprir todos os procedimentos, padrões e normas exigidos no Contrato, sendo que a ONE
							PAY não se responsabilizará pelas Transações concluídas em desacordo com o Contrato. (iv)
							Exigir e conferir, nos casos de Cartão sem Chip e/ou quando não houver digitação de senha, o
							nome e a assinatura do Portador lançada no Comprovante de Venda com o nome e a assinatura
							constantes do Cartão e com documento de identificação do Portador. (v) Garantir nas
							Transações realizadas com Cartões com Chip, a leitura do Chip no Equipamento previamente
							habilitado. (vi) Cumprir todos os demais procedimentos, padrões e normas exigidas neste
							Contrato. (vii) Não realizar o processamento de uma única compra em duas ou mais Transações
							no mesmo Cartão. (viii) Não aceitar Cartões de titularidade de terceiros. (ix) Comunicar
							imediatamente à Central de Atendimento qualquer tentativa ou indício de fraude, conforme
							estabelecido no Contrato. (x) Orientar os Portadores sobre as condições de pagamento para
							aquisição de bens e/ou serviços, de forma clara e objetiva. (xi) Não fornecer ou restituir
							aos Portadores, por qualquer motivo, sem autorização prévia e expressa da ONE PAY, quantias
							em dinheiro (moeda nacional ou estrangeira, cheques, ordens de pagamento ou títulos de
							crédito). (xii) Não realizar qualquer outro tipo ou forma de Transação considerada irregular
							e/ou decorrente de atividades consideradas ilegais ou indesejáveis, conforme critérios
							estabelecidos pela ONE PAY, pelos Emissores e pelos Instituidores de Arranjo de Pagamento.
							(xiii) Garantir a ONE PAY a disponibilização de toda e qualquer informação que venha a ser
							solicitada relativamente ao acesso, armazenamento, transmissão ou processamento de dados do
							Portador de Cartões por si ou por qualquer terceiro. A ONE PAY poderá assistir tecnicamente
							o Estabelecimento, mediante prévia solicitação, desde que a equipe responsável da ONE PAY
							tenha conhecimento técnico sobre os sistemas do Estabelecimento, para fins de acessar os
							dados que venham a ser requeridos pela própria ONE PAY ou em razão de qualquer autoridade,
							demanda judicial ou qualquer outra necessidade do Estabelecimento. Nos casos em que a ONE
							PAY não seja solicitante, a assistência tecnológica prestada pela ONE PAY poderá implicar a
							cobrança de taxas pelos serviços prestados. (xiv) A ONE PAY poderá recusar os dados de
							Transações enviadas pelo Estabelecimento, quando os requerimentos e especificações técnicas
							solicitados pela ONE PAY não forem observados pelo Estabelecimento. Tais instruções podem
							ser alteradas sempre que a ONE PAY, os Instituidores de Arranjo de Pagamento, assim como
							qualquer autoridade competente, vier a solicitar alterações nos processos, objetivando a
							melhoria das condições de segurança da informação. A ONE PAY poderá requerer que as
							informações relativas as Transações recusadas sejam corrigidas, reenviadas ou mesmo
							deletadas dos arquivos de dados dos Estabelecimentos. (xv) Não utilizar recursos
							tecnológicos, software, hardware ou qualquer outra tecnologia não homologada ou não
							autorizada pela ONE PAY e/ou que venha a trazer riscos de fraude ou segurança para o Sistema
							ONE PAY e que estejam em desacordo com as normas e padrões internacionais da indústria de
							Cartões. (xvi) Cumprir integralmente todas as regras de segurança da informação da ONE PAY,
							dos Instituidores de Arranjo de Pagamento, das autoridades governamentais reguladoras e/ou
							emanadas pelo PCI ou qualquer norma posterior que venha a regular a segurança de dados dos
							Portadores de Cartão no mercado de meios de pagamento, durante a vigência deste Contrato,
							conforme prazos e condições definidas pela ONE PAY. As regras do PCI, bem como a listagem de
							empresas homologadas para realização de verificação de conformidades às normas encontram-se
							disponíveis no endereço eletrônico: www.pcisecuritystandards.org. A obrigatoriedade de
							segurança de dados dispostas nesta cláusula e definidas pelo PCI, e/ou outros programas de
							segurança estabelecidos pelos Instituidores de Arranjo de Pagamento, pelas autoridades
							reguladoras da indústria de Meios de Pagamento e/ou pela ONE PAY, se estendem aos
							colaboradores e terceiros contratados pelo Estabelecimento, cuja atividade seja passível de
							tráfego, processamento ou armazenamento dos dados do Portador de Cartão. (xvii) Executar por
							meios próprios ou a permitir a condução de auditorias pela ONE PAY ou terceiro indicados
							pela ONE PAY, para fins de revisão dos procedimentos de segurança do Estabelecimento e
							terceiros contratados ou colaboradores, respeitando as leis, regulamentos e quaisquer
							obrigações perante as autoridades competentes. (xviii) Realizar as adequações técnicas
							solicitadas pela ONE PAY, tais como homologações e atualizações de sistemas e software, nos
							prazos acordados, com o intuito de garantir a segurança de seu ambiente de processamento das
							informações. (xix) Comunicar imediatamente o ONE PAY a respeito de qualquer suspeita sobre a
							segurança dos dados relativos às Transações capturadas pelo Sistema ONE PAY, assim como
							qualquer vazamento de dados que, eventualmente, venham a ocorrer em seus sistemas. O
							Estabelecimento deve descrever os processos e procedimentos implementados para corrigir as
							falhas e/ ou para recuperar os dados eventualmente violados. A ONE PAY poderá requerer
							exames ou a realização de procedimentos para averiguar e medir a extensão de tais vazamentos
							ou exigir a contratação de especialista para tais finalidades. (xx) Responder pela
							legitimidade e legalidade das Transações que originaram os créditos negociados ou pré-pagos
							e sua regularidade de acordo com este Contrato, sob pena de estorno, débito, cancelamento e
							Contestação;
						</p>
						<p>
							7.2. O Estabelecimento obriga-se a observar o disposto no Anexo 7.2 nas Transações de
							Crédito;
						</p>
						<p>
							7.3. O Estabelecimento obriga-se a observar o disposto no Anexo 7.3 nas Transações de
							Débito;
						</p>
						<p>
							7.4. O Estabelecimento obriga-se a observar o disposto no Anexo 7.4 nas Transações sem
							Cartão presente;
						</p>
						<p>
							7.5. O Estabelecimento obriga-se a observar o disposto no Anexo 7.5 para os serviços de rede
							prestados pela ONE PAY;
						</p>
						<p>
							7.6. O Estabelecimento fica obrigado a não impor condições e/ou restrições discriminatórias
							ao pleno e legítimo uso de Cartões e Meios de Pagamento pelo Portador, bem como a quaisquer
							Emissores ou Instituidores de Arranjo de Pagamento;
						</p>
						<p>
							7.7. O Estabelecimento assumirá integral responsabilidade por (i) eventuais erros nos dados
							das Transações, (ii) Contestação e/ou (iii) cancelamentos de Transações enviados ao Sistema
							ONE PAY;
						</p>
						<p>
							7.8. O Estabelecimento deve utilizar os Equipamentos somente para realizar Transações
							regulares e resultantes de suas próprias atividades, sendo expressamente vedada a realização
							de Transações de terceiros, ainda que empresas do mesmo grupo econômico do Estabelecimento,
							obrigando-se, outrossim, o Estabelecimento a observar rigorosamente as disposições do Manual
							ONE PAY, a legislação aplicável e as condições deste Contrato;
						</p>
						<p>
							7.9. Estão sujeitas ao não processamento, ao não pagamento e/ou cancelamento, Transações
							irregularmente realizadas, sob quaisquer modalidades, de forma conivente ou não, em
							circunstâncias que caracterizem indício ou suspeita de fraude e/ou irregularidade;
						</p>
						<p>
							7.10. O Estabelecimento está ciente que poderá ser suspenso e/ou descredenciado, sob
							exclusivo critério do ONE PAY, caso se verifique a recorrência de Transações suspeitas ou
							irregulares, conforme políticas definidas pelos Instituidores de Arranjo de Pagamento e/ou
							regras de monitoramento de fraude da ONE PAY;
						</p>
						<p>
							7.11. O Estabelecimento está ciente e concorda com os métodos que a ONE PAY vier a adotar
							para identificar e prevenir fraudes e práticas ilícitas. O Estabelecimento se obriga a
							monitorar e orientar seus funcionários, bem como cooperar e colaborar, principalmente com
							fornecimento das informações solicitadas;
						</p>
						<p>
							7.12. O Estabelecimento é o exclusivo responsável por responder e solucionar, diretamente
							com os Portadores, toda e qualquer eventual controvérsia sobre as características,
							qualidade, quantidade, propriedades, origem, preço, funcionamento, garantias, defeitos e/ou
							avarias dos bens e produtos adquiridos e/ou dos serviços prestados, incluindo casos de
							defeito ou devolução, problemas de entrega, entre outros, mantendo a ONE PAY, os
							Instituidores de Arranjo de Pagamentos e o Emissor inteiramente isentos de quaisquer
							responsabilidades convencionais ou legais em relação aos fatos mencionados nesta cláusula,
							inclusive com relação a quaisquer ocorrência e/ou obrigações decorrentes do Código de Defesa
							do Consumidor;
						</p>
						<p>
							7.13. O Estabelecimento está ciente e concorda expressamente que a responsabilidade da ONE
							PAY está limitada à execução das obrigações descritas neste Contrato, sendo certo que
							quaisquer obrigações ou ônus decorrentes, direta ou indiretamente, de quaisquer
							procedimentos administrativos ou judiciais, resultantes de eventual descumprimento de
							qualquer obrigação principal ou acessória por parte do Estabelecimento e promovida por
							qualquer terceiro, inclusive órgãos federais, estaduais ou municipais competentes, deverão
							ser suportados integralmente pelo Estabelecimento;
						</p>
						<p>
							7.14. O Estabelecimento está ciente e concorda expressamente que a ONE PAY não é responsável
							por eventuais falhas, atrasos ou interrupções na prestação de serviço, uma vez que,
							tratando-se de serviços de tecnologia, interrupções são previsíveis, inclusive decorrentes
							de caso fortuito ou motivos de força maior, bem como por limitações impostas por parte do
							poder público ou da atuação de operadoras de serviço de telecomunicações interconectadas à
							rede do Estabelecimento, ou, ainda, por má utilização do serviço pelo Estabelecimento ou por
							qualquer outro fato alheio a ONE PAY.
						</p>
						<p>8. PAGAMENTO DAS TRANSAÇÕES PARA O ESTABELECIMENTO</p>
						<p>
							8.1. O Estabelecimento autoriza a ONE PAY a fazer o pagamento do Valor Líquido das
							Transações, de acordo com os termos e condições previstos neste contrato e ajustados na
							Proposta de Credenciamento e posteriores alterações, mediante crédito do respectivo valor no
							Domicílio Bancário indicado pelo Estabelecimento, ou por outro meio de pagamento acordado
							entre as Partes, desde que a Transação tenha sido realizada de acordo com o previsto neste
							Contrato. O Estabelecimento é o único responsável pela indicação dos seus dados para
							pagamento, ficando a ONE PAY eximida de qualquer responsabilidade a esse respeito.
						</p>
						<p>
							8.2. O Estabelecimento reconhece que as Transações estão sujeitas a Contestação (chargeback)
							e cancelamento, na forma prevista na Cláusula 9 deste Contrato, hipóteses em que deixarão de
							ser pagas pela ONE PAY ao Estabelecimento, ou então objeto de estorno, caso já tenham sido
							liquidadas. 8.3. Caso a data prevista para o pagamento do Valor Líquido da Transação seja
							feriado ou dia de não funcionamento bancário na praça do Domicílio Bancário indicado pelo
							Estabelecimento ou na praça da sede do ONE PAY, o pagamento será realizado no primeiro dia
							útil subsequente.
						</p>
						<p>
							8.4. O pagamento dos valores das Transações pela ONE PAY ao Estabelecimento estará sujeito a
							condições normais de operacionalidade do sistema de pagamentos em questão, sendo que
							eventuais interrupções ou falhas do sistema poderão impactar a agenda de pagamento ao
							Estabelecimento, sem que impliquem quaisquer ônus ou penalidades ao ONE PAY. 8.5. Ressalvado
							o disposto na Cláusula 8.4 anterior, bem como nas hipóteses de impossibilidade de efetivação
							do pagamento por problemas ou restrições do Domicílio Bancário do Estabelecimento ou por
							motivos de caso fortuito ou de força maior, a falta ou atraso imotivado do pagamento do
							Valor Líquido das Transações superior a 1 (um) dia útil sujeitará o ONE PAY à incidência dos
							seguintes encargos adicionais sobre os valores devidos, calculados pro rata die: (i) juros
							de mora de 1% (um por cento) ao mês; e (ii) atualização monetária com base no IPC/FGV. 8.6.
							O Estabelecimento terá o prazo de até 30 (trinta) dias a contar da data prevista para a
							realização do pagamento pela ONE PAY, para apontar qualquer divergência em relação aos
							valores pagos. Findo tal prazo, não caberá qualquer reclamação por parte do Estabelecimento
							e ocorrerá a quitação automática, irrevogável, irretratável e definitiva quanto aos
							referidos valores. A ONE PAY disponibilizará ao Estabelecimento o Extrato das Transações,
							mediante acesso ao Portal do Cliente;
						</p>
						<p>
							8.8. O Estabelecimento poderá, observadas as condições previstas no Capítulo 11 deste
							Contrato, solicitar a ONE PAY a Antecipação do Recebimento de Vendas (ARV);
						</p>
						<p>
							8.9. Nos casos em que se verificar a iliquidez, insolvência, pedido de recuperação judicial
							ou extrajudicial, estado pré-falimentar, encerramento de atividades ou qualquer outra
							hipótese em que ficar caracterizada ou houver indícios razoáveis, a exclusivo juízo do ONE
							PAY, segundo critérios razoáveis, da dificuldade do Estabelecimento em cumprir suas
							obrigações contratuais e/ou legais, o ONE PAY reserva-se o direito de reter os créditos a
							ele devidos, a fim de garantir o cumprimento de suas obrigações perante o ONE PAY e a
							segurança do mercado de meios de pagamento.
						</p>
						<p>9. CONTESTAÇÃO E CANCELAMENTO DAS TRANSAÇÕES</p>
						<p>
							9.1. A autorização da Transação com a liberação do Código de Autorização pela ONE PAY não
							caracteriza qualquer tipo de declaração da ONE PAY quanto à regularidade da Transação, sendo
							possível, posteriormente, que sejam detectadas irregularidades na Transação que ensejem seu
							estorno ou o não pagamento ao Estabelecimento.
						</p>
						<p>
							9.2. A ONE PAY informará ao Estabelecimento de eventuais Contestações por meio do Portal do
							Cliente;
						</p>
						<p>
							9.3. A Transação, mesmo após ser autorizada, poderá não ser processada ou ser estornada pela
							ONE PAY, a seu exclusivo critério e a qualquer tempo, nas seguintes hipóteses: (i) se for
							constatada a ocorrência de irregularidades e/ou de circunstâncias que caracterizem indícios
							ou suspeita de fraudes e/ou a violação das obrigações previstas neste Contrato; (ii) não
							reconhecimento da Transação pelo Portador, em razão de suspeita de fraude; ou (iii) não
							cumprimento, pelo Estabelecimento, dos termos deste Contrato, e/ou dos manuais emitidos
							pelos Instituidores de Arranjo de Pagamento e suas respectivas atualizações, bem como a
							legislação aplicável;
						</p>
						<p>
							9.4. O Estabelecimento deve, sempre que lhe for solicitado, enviar a ONE PAY cópias legíveis
							e sem rasuras dos Comprovantes de Vendas, bem como qualquer documentação adicional de
							comprovação da entrega dos bens adquiridos ou da prestação de serviços realizada, dentro do
							prazo de 5 (cinco) dias a contar da data da solicitação. Se o Estabelecimento não apresentar
							a documentação solicitada no prazo fixado, estará sujeito à não liquidação da respectiva
							Transação. Fica esclarecido, outrossim, que o envio dos Comprovantes de Venda e dos demais
							documentos pelo Estabelecimento não significará a aceitação da Transação pela ONE PAY, que
							analisará a sua regularidade de acordo com as regras dos Instituidores de Arranjo de
							Pagamento. 9.5. Para cumprimento do disposto na Cláusula 9.4 anterior, o Estabelecimento
							deverá manter em arquivo a via original dos Comprovantes de Venda e os documentos que
							comprovem a entrega dos bens adquiridos ou a prestação de serviços realizada pelo prazo
							mínimo de 12 (doze) meses a contar da data de Captura da Transação;
						</p>
						<p>
							9.6. O Estabelecimento poderá solicitar a ONE PAY o cancelamento das Transações na
							modalidade crédito no prazo de 120 (cento e vinte) dias e o cancelamento das Transações na
							modalidade débito no prazo de 7 (sete) dias, a contar da data do processamento da respectiva
							Transação. O cancelamento poderá ser solicitado (i) por meio do Portal do Cliente ou Central
							de Atendimento, em qualquer hipótese; ou (ii) por meio do próprio Equipamento, se for
							solicitado no mesmo dia em que a Transação foi realizada. Caberá a ONE PAY aprovar ou não o
							pedido de cancelamento e estabelecer os meios e procedimentos para a sua realização;
						</p>
						<p>
							9.7. A efetivação do cancelamento da Transação solicitado pelo Estabelecimento ficará
							condicionada à existência de créditos suficientes na Agenda Financeira, para que seja
							possível a compensação do valor do cancelamento, sendo certo que, no caso de inexistência
							e/ou insuficiência de créditos a compensar, o Estabelecimento deverá, a sua própria conta e
							risco, resolver diretamente com os Portadores a devolução de eventuais valores decorrentes
							do cancelamento não realizado. O cancelamento da Transação autoriza a ONE PAY a,
							automaticamente e independentemente de aviso ou notificação, deixar de promover à liquidação
							financeira da Transação;
						</p>
						<p>
							9.8. No caso de Contestação e/ou de cancelamento de Transação, por qualquer que seja o
							motivo, a ONE PAY deixará de efetuar o pagamento do respectivo Valor Líquido ao
							Estabelecimento. Na hipótese da Transação objeto da Contestação e/ou cancelamento já ter
							sido previamente liquidada pela ONE PAY ao Estabelecimento, inclusive por meio de
							Antecipação de Recebimento de Vendas (ARV), o Valor Bruto da Transação, acrescido dos
							encargos previstos na Cláusula 9.9 a seguir, será imediatamente restituído pelo
							Estabelecimento a ONE PAY, independentemente de aviso prévio ou qualquer formalidade, (i)
							através de compensação com quaisquer créditos, presentes ou futuros, que venham a ser
							devidos ao Estabelecimento, ou (ii) mediante débito no Domicílio Bancário do
							Estabelecimento, que poderá ser comandado pela ONE PAY à respectiva instituição financeira,
							ou (iii) mediante débito em conta de depósito que o Estabelecimento possua junto a ONE PAY,
							se houver, ou, ainda, (iv) por outros meios de cobrança legalmente admitidos que a ONE PAY
							venha a adotar, inclusive emissão de boleto;
						</p>
						<p>
							9.9. O Valor Bruto da Transação a ser restituído pelo Estabelecimento a ONE PAY nos termos
							da Cláusula 9.8 anterior será devidamente atualizado, desde a data de pagamento da Transação
							em questão pela ONE PAY ao Estabelecimento, pela variação positiva do IPC/FGV (ou outro
							índice que o substitua) mais juros de 1% (um por cento) ao mês ou fração pro-rata, acrescido
							da Tarifa de Reversão de Transação e dos encargos operacionais e Perdas e danos incorridos;
						</p>
						<p>
							9.10. Mesmo em caso de desfazimento da Transação, por qualquer motivo, inclusive por
							Contestação ou cancelamento, a Remuneração relacionada à Transação e a Tarifa de Reversão de
							Transação poderão ser exigidas pela ONE PAY. Na hipótese de Transações pendentes nos
							Equipamentos, o Estabelecimento deve confirmá-las ou desfazê-las no prazo de 30 (trinta)
							dias, quando se tratar de Transações de Crédito e 5 (cinco) dias para Transações de Débito,
							por uma das formas de comunicação prevista do Contrato. O descumprimento desse prazo pelo
							Estabelecimento implicará o desfazimento automático da respectiva Transação,
							independentemente de qualquer comunicação por parte do ONE PAY.
						</p>
						<p>10. DOMICÍLIO BANCÁRIO</p>
						<p>
							10.1. O Estabelecimento indicará na Proposta de Credenciamento um Domicílio Bancário para o
							recebimento do Valor Líquido das Transações;
						</p>
						<p>
							10.2. É de integral responsabilidade do Estabelecimento zelar pela regularidade do Domicílio
							Bancário, bem como pela correta informação prestada a ONE PAY. O Estabelecimento neste ato
							isenta a ONE PAY de toda e qualquer responsabilidade relacionada a tais informações, sua
							legitimidade e legalidade, devendo ressarcir a ONE PAY, por quaisquer Perdas que venha a
							incorrer em decorrência do aqui disposto;
						</p>
						<p>
							10.3. O Estabelecimento expressamente autoriza, de forma irrevogável e irretratável, que o
							banco de seu Domicílio Bancário efetue, por ordem da ONE PAY e a exclusivo critério deste,
							lançamentos a crédito e débito, estorno de valores e outros previstos neste Contrato em seu
							Domicílio Bancário, independentemente de prévia consulta do Estabelecimento ou de qualquer
							outro ato ou formalidade legal ou documental, utilizando-se, para tanto, inclusive de
							limites de crédito concedidos em referida conta bancária. O Estabelecimento se obriga a
							suprir seu Domicílio Bancário de fundos suficientes para suportar os lançamentos que forem
							determinados pela ONE PAY em virtude do Contrato;
						</p>
						<p>
							10.4. Em caso de término do Contrato por qualquer motivo, o Estabelecimento compromete-se a
							manter ativo seu Domicílio Bancário até que todas as Transações sejam quitadas pela ONE PAY,
							incluindo Transações de crédito parceladas;
						</p>
						<p>
							10.5. Caso o banco depositário do Domicílio Bancário se declare impedido, por qualquer
							motivo, de dar cumprimento às ordens de crédito emitidas pela ONE PAY, deverá o
							Estabelecimento providenciar a regularização do Domicílio Bancário ou ainda, indicar novo
							Domicílio Bancário. Neste caso, o Estabelecimento deverá informar tal providência a ONE PAY,
							que estará autorizada a reter o pagamento dos créditos até o recebimento do pedido de
							mudança e regularização do Domicílio Bancário pelo Estabelecimento ou o respectivo
							processamento, sem quaisquer ônus, penalidades ou encargos;
						</p>
						<p>
							10.6. Com exceção do disposto na Cláusula 10.7 abaixo e/ou outras limitações aplicáveis, o
							Estabelecimento poderá solicitar a alteração de seu Domicílio Bancário a ONE PAY por meio do
							Portal do Cliente. A ONE PAY efetuará a alteração do Domicílio Bancário do Estabelecimento
							no prazo de até 15 (quinze) dias contados da regular formalização da solicitação pelo
							Estabelecimento, observado o procedimento informado pela ONE PAY através do Portal do
							Cliente. Fica estabelecido que o pagamento do Valor Líquido das Transações capturadas
							anteriormente à alteração efetuada poderá ser realizado no Domicílio Bancário então vigente;
						</p>
						<p>
							10.7. O Estabelecimento declara-se plenamente ciente de que, caso tenha firmado ou venha a
							firmar com alguma instituição financeira Termo de Autorização para Manutenção de Domicílio
							Bancário, a ONE PAY será obrigada a realizar o pagamento das Transações no Domicílio
							Bancário que for determinado em referido Termo de Autorização, não podendo acatar qualquer
							solicitação de alteração do Domicílio Bancário enquanto estiver em vigor tal Termo de
							Autorização, exceto nas seguintes hipóteses: (i) mediante prévia e expressa autorização da
							instituição financeira a favor de quem a Manutenção de Domicílio Bancário foi concedida pelo
							Estabelecimento; (ii) se o Estabelecimento solicitar o término da Manutenção de Domicílio
							Bancário e a instituição financeira não apresentar o Termo de Autorização no prazo
							estabelecido nas regras do SCG; ou (c) se houver ordem judicial determinando o término da
							Manutenção de Domicílio Bancário e/ou a alteração do Domicílio Bancário;
						</p>
						<p>
							10.8. A informação acerca da existência de eventual Manutenção de Domicílio Bancário a que
							esteja sujeito o Estabelecimento será comunicada a ONE PAY pela entidade centralizadora do
							SCG. O Estabelecimento concorda que a ONE PAY envie à entidade centralizadora do SCG as
							informações relativas à Manutenção de Domicílio Bancário, bem como comunique a existência ou
							não de Antecipação do Recebimento de Vendas (ARV). O Estabelecimento isenta desde já o ONE
							PAY de qualquer responsabilidade pelo cumprimento da Manutenção de Domicílio Bancário;
						</p>
						<p>
							10.9. O Estabelecimento expressamente autoriza e reconhece que o ONE PAY poderá
							disponibilizar informações da sua Agenda Financeira para a instituição financeira designada
							como Domicílio Bancário.
						</p>
						<p>11. ANTECIPAÇÃO DO RECEBIMENTO DE VENDAS (ARV)</p>
						<p>
							11.1. O Estabelecimento poderá solicitar a ONE PAY a Antecipação do Recebimento de Vendas
							(ARV) relativa às Transações, ficando ao exclusivo critério da ONE PAY, pré-pagar ou não os
							valores solicitados. As condições comerciais de cada pré-pagamento serão ajustadas entre as
							Partes. A ONE PAY verificará e informará ao Estabelecimento, mediante solicitação deste, se
							o Estabelecimento está apto a receber antecipadamente seus direitos creditórios oriundos das
							Transações, bem como seus termos e condições. A existência de Manutenção de Domicílio
							Bancário registrada no SCG impede a realização de ARV;
						</p>
						<p>
							11.2. O Estabelecimento poderá contratar a ARV de forma automática, por meio dos canais de
							atendimento que forem disponibilizados pela ONE PAY para o Produto. Nessa modalidade o Valor
							Líquido das Transações geradas pelo Estabelecimento passará a ser pago no dia útil seguinte
							ao da sua Captura, aplicando-se a Taxa de Antecipação praticada pela ONE PAY na data de cada
							antecipação, a qual poderá ser consultada pelo Estabelecimento no Portal do Cliente e/ou na
							Central de Atendimento, deduzida ainda a Tarifa de Contratação de ARV;
						</p>
						<p>
							11.3. Quando o Estabelecimento optar por não continuar com a operação de ARV automático,
							deverá comunicar tal decisão a ONE PAY via Central de Atendimento ou via Portal do Cliente,
							responsabilizando-se a ONE PAY por efetuar a mudança no cadastro do Estabelecimento dentro
							do prazo por ele estipulado;
						</p>
						<p>
							11.4. Para as negociações do ARV com a ONE PAY, devem ser observados os seguintes termos,
							sem prejuízo de outras regras determinadas pela ONE PAY: (i) As operações de ARV terão como
							objeto exclusivamente Transações já realizadas; (ii) O ARV será calculado com base no Valor
							Líquido das Transações objeto do pré-pagamento, aplicando-se sobre este a Taxa de
							Antecipação e a Tarifa de Contratação de ARV; e (iii) O depósito do valor acordado com a ONE
							PAY, no Domicilio Bancário do Estabelecimento, na data também negociada com a ONE PAY,
							representa a quitação pela ONE PAY das Transações incluídas na negociação de ARV, dando o
							Estabelecimento a quitação de forma irrevogável e irretratável.
						</p>
						<p>12. NEGOCIAÇÃO DOS CRÉDITOS</p>
						<p>
							12.1. O Estabelecimento reconhece e concorda, expressamente, que serão vedadas e não
							produzem nenhum efeito relativamente a ONE PAY a caução, cessão ou transferência de
							titularidade, negociações envolvendo quaisquer direitos creditórios presentes ou futuros que
							o Estabelecimento detenha junto aa ONE PAY, oriundos das Transações, ou o oferecimento em
							garantia dos créditos decorrentes de Transações, salvo na hipótese de prévia e escrita
							concordância ou anuência da ONE PAY.
						</p>
						<p>13. REMUNERAÇÃO</p>
						<p>
							13.1. Pela adesão e utilização do Sistema ONE PAY, esta poderá cobrar do Estabelecimento uma
							Remuneração, a qual incluirá os seguintes encargos e taxas, sem prejuízo da cobrança de
							outros valores previstos no presente Contrato, seus Anexos e/ou na Proposta de
							Credenciamento: • Taxa de Adesão/Renovação; • Tarifa de Instalação de Equipamento; • Taxa de
							Desconto (MDR); • Tarifa por Transação; • Taxa de Conectividade; • Aluguel de Equipamento; •
							Tarifa de Disponibilização de Extrato; • Taxa de Inatividade; • Taxas Operacionais; • Tarifa
							de Monitoramento e Investigação; • Tarifa de Reversão de Transação; • Tarifa de
							Desinstalação de Equipamento; • Tarifa de Contratação de ARV;
						</p>
						<p>
							13.2. A ONE PAY poderá instituir, mediante prévia comunicação por escrito, novas modalidades
							de Remuneração pelos seus serviços prestados, tais como tarifas ou taxas;
						</p>
						<p>
							13.3. A Remuneração poderá ser cobrada pela ONE PAY por evento, diária, semanal, mensalmente
							ou em outra periodicidade a ser determinada pela ONE PAY a seu exclusivo critério. O
							Estabelecimento poderá consultar o valor da Remuneração por meio do Portal do Cliente e/ou
							Central de Atendimento;
						</p>
						<p>
							13.4. A ONE PAY poderá alterar os valores da Remuneração na forma prevista na Cláusula 19.2,
							sendo certo, outrossim, que os valores da Remuneração serão, automaticamente e independente
							de qualquer comunicação ao Estabelecimento, reajustados anualmente ou na menor periodicidade
							permitida em lei, pela variação positiva do IPC/FGV no período, ou por qualquer outro índice
							que vier a substituí-lo;
						</p>
						<p>
							13.5. O valor da Remuneração, de acordo com a sua natureza, será abatido automaticamente do
							Valor Bruto da Transação e/ou compensado na Agenda Financeira do Estabelecimento, sem
							prejuízo ainda das demais formas de cobrança previstas no capítulo a seguir, e poderá ser
							diferente em função do tipo de Transação, tipo de Meio de Pagamento, segmento de atuação do
							Estabelecimento, e/ou forma de Captura de dados, se eletrônica ou presencial/manual.
						</p>
						<p>14. FORMA DE COBRANÇA DO ESTABELECIMENTO, MORA E INADIMPLEMENTO</p>
						<p>
							14.1. Para efetuar a cobrança e/ou se reembolsar dos valores devidos pelo Estabelecimento, a
							ONE PAY poderá adotar, a seu exclusivo critério, qualquer das seguintes alternativas,
							isolada ou cumulativamente, para o que fica a ONE PAY, desde já, expressamente autorizada
							pelo Estabelecimento, de forma irrevogável e irretratável: (i) a compensar o valor do débito
							com outros créditos, presentes ou futuros, devidos ao Estabelecimento, relacionados ou não a
							este Contrato; e/ou (ii) a realizar lançamentos a débito no Domicílio Bancário do
							Estabelecimento, nos termos da Cláusula 10.3 supra, e/ou na(s) conta(s) de depósito que o
							Estabelecimento porventura possua junto a ONE PAY, obrigando-se o Estabelecimento a suprir o
							Domicílio Bancário e/ou a(s) conta(s) de depósito de recursos suficientes para suportar tais
							débitos; e/ou (iii) permitir que o Estabelecimento, no caso de ausência de créditos a
							compensar, ou na impossibilidade de lançamento a débito no Domicílio Bancário e/ou na(s)
							conta(s) de depósito do Estabelecimento na ONE PAY e/ou de insuficiência de fundos
							disponíveis, efetue, desde que acordado com a ONE PAY, o pagamento mediante cheque, ordem de
							pagamento, DOC, TED, boleto bancário ou depósito identificado; e/ou (iv) se valer de
							quaisquer outros meios legalmente permitidos;
						</p>
						<p>
							14.2. Ocorrendo falta ou eventual atraso no pagamento, total ou parcial, de qualquer
							obrigação do Estabelecimento decorrente deste Contrato, o valor devido será acrescido de (i)
							multa moratória de 2% (dois por cento), incidente sobre o valor devido e não pago; (ii)
							juros de mora de 1% (um por cento) ao mês; e (iii) atualização monetária com base na
							variação positiva do IPC/FGV, calculados pro rata die;
						</p>
						<p>
							14.3. No caso de qualquer inadimplemento do Estabelecimento, total ou parcial, e/ou de
							vencimento antecipado de obrigações do Estabelecimento, decorrentes de qualquer instrumento
							ou título, inclusive, sem limitação, do presente Contrato, serão consideradas extintas, de
							pleno de direito, as obrigações líquidas e vencidas (antecipadamente ou não) do ONE PAY para
							com o Estabelecimento serão compensadas com as obrigações líquidas e vencidas
							(antecipadamente ou não) do Estabelecimento para com o ONE PAY e decorrentes dos mesmos
							instrumentos ou títulos, inclusive, sem limitação, deste Contrato, tudo independentemente de
							aviso prévio ou notificação de qualquer natureza. Fica desde já esclarecido que a
							compensação parcial não exonerará o Estabelecimento, que continuará responsável pelo saldo
							remanescente de suas obrigações e respectivos acréscimos, até a quitação total junto ao ONE
							PAY;
						</p>
						<p>
							14.4. Para a liquidação da totalidade das obrigações do Estabelecimento, caso não ocorra a
							compensação de que trata a cláusula anterior, ou do saldo remanescente referido na parte
							final da mesma cláusula, se parcial a compensação, poderão ser utilizados pela ONE PAY todos
							os créditos que o Estabelecimento seja titular perante a ONE PAY. Todos os tributos,
							despesas ou encargos de qualquer natureza incidentes sobre tal(is) operação(ões) correrão
							por conta do Estabelecimento.
						</p>
						<p>15. FRAUDE OU SUSPEITA DE FRAUDE</p>
						<p>
							15.1. A ONE PAY adotará providências para identificação e prevenção de fraudes e práticas
							ilícitas, comprometendo-se o Estabelecimento a orientar seus funcionários e contratados
							acerca do cumprimento das políticas de prevenção, bem como a fornecer as informações
							solicitadas pela ONE PAY; 15.2. A ONE PAY ficará isenta de quaisquer responsabilidades
							relativas a fraudes, indícios ou suspeitas de fraude, em todas as Transações com Cartões e
							Meios de Pagamento realizadas pelo Estabelecimento, as quais serão de responsabilidade
							exclusiva do Estabelecimento;
						</p>
						<p>
							15.3. Em caso de suspeita de realização de Transações irregulares, a ONE PAY fica desde logo
							autorizado pelo Estabelecimento a iniciar procedimento investigativo para a respectiva
							apuração, independentemente do envio de comunicação ao Estabelecimento;
						</p>
						<p>
							15.4. A ONE PAY, a seu exclusivo critério, poderá cobrar do Estabelecimento, a Tarifa de
							Monitoramento e Investigação, em função do monitoramento de Transações suspeitas e/ou
							procedimento investigativo;
						</p>
						<p>
							15.5. Caso o Estabelecimento venha a ser comunicado pela ONE PAY acerca de qualquer
							procedimento investigativo, o Estabelecimento deverá cooperar integralmente com o
							Instituidores de Arranjo de Pagamento e/ou com a ONE PAY, obrigando-se a fornecer todos os
							Comprovantes de Vendas e quaisquer outros documentos solicitados pelo Instituidores de
							Arranjo de Pagamento e/ou pela ONE PAY e adotar todas e quaisquer recomendações para
							regularizar as suas operações no prazo que vier a ser indicado pelos Instituidores de
							Arranjo de Pagamento e/ou pela ONE PAY, podendo a ONE PAY realizar inspeção nos Equipamentos
							utilizados pelo Estabelecimento, em qualquer data e em horário comercial, independentemente
							de prévia comunicação ao Estabelecimento;
						</p>
						<p>
							15.6. Em caso de suspeita e/ou constatação de fraude ou qualquer outra atividade ilícita e
							durante a realização do procedimento investigativo, a ONE PAY poderá suspender a
							disponibilização ao Estabelecimento do Sistema ONE PAY para a realização de novas Transações
							e a liquidação financeira das Transações ao Estabelecimento ou para reter eventuais repasses
							a serem realizados ao Estabelecimento, desde a data do início do procedimento investigativo
							até o seu término, sem que isto gere para a ONE PAY qualquer multa, penalidade e/ou encargos
							moratórios. Caso não reste comprovada a ocorrência de irregularidades nas Transações este
							Contrato continuará em vigor, e os valores das Transações deverão ser pagos pela ONE PAY ao
							Estabelecimento, sem qualquer acréscimo ou penalidade. Na hipótese de constatação de fraude
							ou suspeita de fraude ou irregularidade na operação do Sistema ONE PAY por parte do
							Estabelecimento, poderá a ONE PAY imediatamente bloquear as Transações, descredenciar o
							Estabelecimento e/ou rescindir este Contrato e/ou, ainda, reter todo e qualquer valor que
							porventura tivesse que ser repassado pela ONE PAY ao Estabelecimento pelo prazo necessário
							para concluir a apuração de todas as Perdas.
						</p>
						<p>16. CONFIDENCIALIDADE</p>
						<p>
							16.1. O Estabelecimento compromete-se a manter em absoluto sigilo e confidencialidade todas
							as Informações Confidenciais. No caso de sua eventual violação ou divulgação, inclusive por
							atos de seus funcionários ou terceiros, o Estabelecimento será responsável pelo
							ressarcimento das Perdas e danos ocasionados, incluindo danos emergentes, lucros cessantes,
							custas judiciais e honorários advocatícios;
						</p>
						<p>
							16.2. O Estabelecimento se compromete a manter, conservar e guardar todas as Informações
							Confidenciais, Equipamentos e materiais que lhe sejam entregues ou a que tenha acesso em
							decorrência deste Contrato, em local absolutamente seguro, inacessível a terceiros, salvo
							quanto às pessoas devidamente autorizadas e cientes da obrigação de sigilo aqui definida, as
							quais se obrigam também a observar as restrições previstas;
						</p>
						<p>
							16.3. O Estabelecimento obriga-se a utilizar as Informações Confidenciais e Produtos que lhe
							são disponibilizados nos termos do Contrato, exclusivamente para as finalidades e serviços
							contratados, ficando vedada qualquer alteração de sua forma ou substância;
						</p>
						<p>
							16.4. A ONE PAY compromete-se a manter a confidencialidade de dados das Transações efetuadas
							pelo Estabelecimento, exceto quando solicitados por ordem judicial, exigidos por lei e/ou
							prestadores de serviço da ONE PAY. A ONE PAY poderá prestar às autoridades competentes, tais
							como o Ministério da Fazenda, o BACEN, a Receita Federal, as Secretarias da Fazenda
							Estaduais e Municipais, Comissões Parlamentares de Inquérito, Órgãos de Controle de
							Atividades Financeiras, Polícia Federal etc., todas as informações que forem solicitadas em
							relação ao Estabelecimento ou quaisquer dados relativos às Transações efetuadas pelo
							Estabelecimento;
						</p>
						<p>
							16.5. O Estabelecimento, de forma irrevogável e irretratável, autoriza a ONE PAY e/ou as
							empresas pertencentes ao seu conglomerado a: (i) trocarem entre si as Informações
							Confidenciais e demais informações, assim como consultar e/ou confirmar a exatidão das
							mesmas em websites e bancos de dados em geral; (ii) compartilhar as Informações
							Confidenciais e demais informações com os Emissores, com o banco depositário do Domicílio
							Bancário e os Instituidores de Arranjo de Pagamento; (iii) compartilhar Informações
							Confidenciais e demais informações com seus parceiros comerciais e prestadores de serviços,
							no Brasil ou no exterior, para fins de avaliação de crédito, verificação e gestão de risco e
							fraude; (iv) utilizar suas Informações Confidenciais e demais informações para formação de
							banco de dados, bem como sua divulgação a qualquer título, desde que de forma generalizada,
							agrupada com informações de outros Estabelecimentos e não individualizada; e (v)
							compartilhar as Informações Confidenciais e demais informações com as autoridades
							reguladoras da indústria de Meios de Pagamento, com autoridades tributárias federais,
							estaduais e municipais, com agências de crédito oficiais ou privadas, por determinação em
							legislação, regulamentação de mercado, em medidas judiciais, entre outras; e (vi) comunicar
							ao BACEN e ao COAF – Conselho de Controle de Atividades Financeiras as Transações que possam
							estar configuradas no disposto na Lei 9.613/98 e demais normas relativas à lavagem de
							dinheiro, incluindo as normas e políticas internas da ONE PAY nesse sentido; (vii) informar
							a órgãos de proteção de crédito, tais como SERASA e SPC, os dados relativos à falta de
							pagamento de obrigações assumidas pelo Estabelecimento junto a ONE PAY; (viii) consultar
							informações em nome do Estabelecimento que constem ou venham a constar (a) do Sistema de
							Informações de Crédito (SCR), de que tratam os normativos editados pelo Conselho Monetário
							Nacional e/ou BACEN, e/ou outros sistemas que, em virtude de norma legal os complementem ou
							substituam, (b) dos sistemas geridos pelo BACEN relativamente a operações realizadas pelo
							Estabelecimento no mercado de câmbio com outras instituições financeiras e demais
							instituições autorizadas a funcionar pelo BACEN, e, bem como, (c) de quaisquer outros bancos
							de dados, públicos ou privados;
						</p>
						<p>
							16.6. A obrigação de sigilo se manterá válida inclusive quando do término por qualquer
							motivo deste Contrato. A não observância dos requerimentos mencionados nesta cláusula
							sujeitará o Estabelecimento ao pagamento de indenização compatível com os prejuízos
							incorridos pela ONE PAY e às sanções e pagamento das multas e/ou Perdas específicas
							previstas nas normas e regulamentos operacionais da ONE PAY e dos Instituidores de Arranjo
							de Pagamento, sem prejuízo das demais medidas asseguradas em lei às Partes e aos terceiros
							prejudicados.
						</p>
						<p>17. PRAZO</p>
						<p>
							17.1. Este Contrato vigorará por tempo indeterminado, a contar da aceitação e inclusão do
							Estabelecimento ao Sistema ONE PAY, nos termos da Cláusula 3 deste Contrato.
						</p>
						<p>18. TÉRMINO E RESPONSABILIDADES</p>
						<p>
							18.1. A ONE PAY ou o Estabelecimento poderão, a qualquer tempo e sem qualquer motivação,
							mediante comunicação por qualquer meio de transmissão ou comunicação físico ou eletrônico,
							com 30 (trinta) dias de antecedência, terminar este Contrato ou a contratação específica de
							quaisquer dos Produtos, sem a incidência de quaisquer ônus, encargos ou penalidades,
							ressalvado o cumprimento obrigações contratuais ainda pendentes;
						</p>
						<p>
							18.2. Este Contrato poderá ser imediatamente rescindido, por justa causa, por qualquer das
							Partes, nos seguintes casos: (i) infração pela outra parte de qualquer das cláusulas, termos
							ou condições deste Contrato; (ii) decretação de falência, deferimento de pedido de
							recuperação judicial, proposição de recuperação extrajudicial ou declaração de insolvência
							da outra parte; ou (iii) não aceitação pelo Estabelecimento de eventuais alterações
							efetuadas pela ONE PAY no Contrato, nos termos da Cláusula 19.2. Caso qualquer das Partes
							incorra em qualquer das hipóteses previstas acima, a Parte que tiver a intenção de rescindir
							o Contrato deverá comunicar a outra, por qualquer meio de transmissão ou comunicação físico
							ou eletrônico, com o objetivo de formalizar o término do Contrato, o que produzirá seus
							efeitos a partir da referida comunicação;
						</p>
						<p>
							18.3. Este Contrato poderá ainda ser imediatamente rescindido pela ONE PAY, a exclusivo
							critério deste, mediante simples comunicação realizada ao Estabelecimento por qualquer meio,
							sem prejuízo do ressarcimento das Perdas e danos eventualmente acarretados, se o
							Estabelecimento praticar ou sofrer medidas tais como, mas não limitadas às seguintes: (i)
							realizar Transações irregulares ou em desacordo parcial ou total com os termos deste
							Contrato, ou Transações suspeitas ou irregulares conforme definição dos Instituidores de
							Arranjo de Pagamento ou regras de monitoramento da ONE PAY; (ii) se o Instituidor de Arranjo
							de Pagamento determinar o descredenciamento do Estabelecimento; (iii) exercer atividades
							consideradas ilegais e/ou indesejáveis; (iv) realizar Transações e/ou adotar conduta
							fraudulenta ou com suspeita de fraude; (v) se, sem expressa autorização da ONE PAY,
							intermediar Transações de terceiros ou ceder, transferir, emprestar, dar em garantia ou
							entregar a terceiros os Equipamentos, Periféricos ou Materiais de propriedade do ONE PAY, ou
							quaisquer direitos e obrigações decorrentes deste Contrato; (vi) se adotar ou permitir
							práticas que resultem, parcial ou totalmente, em preferência por qualquer outro instrumento
							de pagamento, exclusão, condicionamento ou limitação da utilização dos Cartões e Meios de
							Pagamento; (vii) ficar impedido de abrir ou manter ou ter encerrado o Domicílio Bancário em
							qualquer instituição financeira; (viii) praticar ou tentar praticar quaisquer atos que
							tenham por objetivo, direto ou indireto, burlar o Contrato, quaisquer regras ou requisitos
							operacionais ou de segurança da ONE PAY ou dos Instituidores de Arranjo de Pagamento ou
							qualquer lei ou regulamentação aplicáveis; (ix) tiver índice de Transações canceladas ou não
							reconhecidas em volume considerado elevado, segundo critérios da ONE PAY e/ou dos
							Instituidores de Arranjo de Pagamento, após o decurso de prazo estipulado pela ONE PAY para
							esclarecimentos e soluções; (x) tornar-se inativo ou manter-se inativo, considerando-se
							inativo o Estabelecimento que não realizar qualquer Transação dentro de período determinado,
							a exclusivo critério da ONE PAY; (xi) se for apurada a falsidade, insuficiência ou
							imprecisão de qualquer declaração, informação ou documento que houver sido firmado, prestado
							ou entregue pelo Estabelecimento; (xii) tornar-se inadimplente junto a qualquer empresa
							pertencente ao conglomerado da ONE PAY, em razão de outras contratações; (xiii) sofrer
							mudança adversa relevante em sua situação patrimonial, condições econômico-financeiras e/ou
							resultados operacionais; (xiv) tiver sua situação reputacional afetada negativa e
							relevantemente, inclusive em decorrência direta ou indireta de ação ou omissão de qualquer
							de seus administradores e/ou acionistas; (xv) se não forem renovadas ou forem canceladas,
							revogadas ou suspensas as autorizações, concessões, alvarás e licenças necessárias para o
							regular exercício das respectivas atividades do Estabelecimento; (xvi) for responsabilizado,
							judicial ou administrativamente, por dano causado ao meio ambiente; (xvii) se for apurada
							violação ou for iniciado procedimento investigatório visando à apuração de violação, pelo
							Estabelecimento e/ou seus administradores e/ou acionistas, de dispositivo legal ou
							regulatório relativo à prática de corrupção ou de atos lesivos à administração pública, sob
							qualquer jurisdição, incluindo, sem limitação, a Lei nº 12.846/13;
						</p>
						<p>
							18.4. O término do Contrato, motivado ou não, não exonera as Partes do cumprimento pleno e
							irrestrito de todas as obrigações decorrentes do Contrato, tais como, mas não limitadas às
							seguintes: (i) A ONE PAY pagar, no prazo contratual e no Domicílio Bancário do
							Estabelecimento ou por qualquer outro meio de pagamento acordado entre as Partes, os valores
							das Transações legítimas e regulares devidas ao Estabelecimento, ficando plenamente quitada
							das suas obrigações decorrentes desde Contrato; e (ii) O Estabelecimento (a) pagar a ONE
							PAY, ou a terceiro indicado pela ONE PAY, os valores eventualmente devidos na forma do
							Contrato, sem prejuízo das Perdas e danos aplicáveis, (b) manter ativo o seu Domicílio
							Bancário até a liquidação total e final de todo o Valor Líquido das Transações devido ao
							Estabelecimento, e (c) permitir a imediata retirada e/ou entregar os Equipamentos,
							Periféricos e Materiais operacionais pertencentes a ONE PAY e/ou terceiros indicados pela
							ONE PAY que estejam sob sua guarda, pagando ainda a respectiva Tarifa de Desinstalação de
							Equipamento, quando aplicável, conforme valores vigentes à época. Na hipótese de não
							devolução dos Equipamentos, Periféricos e Materiais, fica facultado a ONE PAY descontar ou
							debitar, quando aplicável, o valor dos Equipamentos, Periféricos ou Materiais não devolvidos
							de qualquer quantia a ser paga ao Estabelecimento;
						</p>
						<p>
							18.5. O ONE PAY poderá reter qualquer pagamento devido ao Estabelecimento até o cumprimento
							integral, pelo Estabelecimento, de todas as obrigações previstas neste Contrato;
						</p>
						<p>
							18.6. O cancelamento de qualquer um dos Anexos não implicará o término do Contrato,
							permanecendo em pleno vigor, exceto se as Partes acordarem de maneira diversa.
						</p>
						<p>19. ALTERAÇÕES DO CONTRATO</p>
						<p>
							19.1. Nenhuma alteração deste Contrato será considerada válida, exceto se realizada na forma
							indicada nesta cláusula ou se acordada expressamente por meio de aditivo contratual escrito
							e assinado pelas Partes;
						</p>
						<p>
							19.2. O ONE PAY poderá, por documento físico ou eletrônico, enviado ao Estabelecimento por
							qualquer meio de transmissão ou comunicação ou, ainda, disponibilizado no Portal do Cliente,
							alterar ou aditar cláusulas e condições do Contrato, alterar os valores da Remuneração,
							incluir novos anexos e/ou aditivos. O Estabelecimento terá o prazo de 15 (quinze) dias para
							solicitar esclarecimentos sobre as alterações do Contrato e/ou terminar o Contrato, sem
							qualquer tipo de ônus. A ausência de manifestação do Estabelecimento no prazo previsto
							implicará a aceitação plena e irrestrita dos novos termos e condições pelo Estabelecimento.
						</p>
						<p>20. MARCA</p>
						<p>
							20.1. O Estabelecimento autoriza o ONE PAY a incluir, sem qualquer ônus ou encargos, seu
							nome, marcas e logotipos e endereço, bem como os de suas unidades comerciais e filiais, em
							ações de marketing, comunicados, catálogos e/ou quaisquer materiais promocionais do Sistema
							ONE PAY. Autoriza, ainda, a comunicação de seus dados comerciais aos Emissores, Portadores,
							Instituidores de Arranjo de Pagamento e demais participantes do Sistema ONE PAY;
						</p>
						<p>
							20.2. O Estabelecimento declara ciência de que a ONE PAY é titular e/ou licenciado de
							diversos direitos de propriedade intelectual, incluindo vários direitos de propriedade
							industrial e direitos autorais sobre as marcas e domínios de internet contendo relacionados
							a ONE PAY e ao Sistema ONE PAY (doravante conjuntamente denominados "SINAIS DISTINTIVOS"),
							dentre outros sinais distintivos não autorizados neste instrumento, no âmbito da Lei nº
							9.279/96 (Lei da Propriedade Industrial), Lei nº 9.610/98 (Lei de Direitos Autorais) e
							disposições da Lei nº 10.406/02 (Código Civil). 20.3. Com relação aos SINAIS DISTINTIVOS e
							às marcas dos Instituidores de Arranjo de Pagamento, o Estabelecimento obriga-se a
							utilizá-las, nos estritos termos deste Contrato, nas formas, cores e modelos indicados e
							aprovados previamente pela ONE PAY, não podendo alterá-las ou usá-las de forma diversa da
							forma prévia e expressamente aprovada pela ONE PAY. Todo e qualquer uso pelo Estabelecimento
							SEM PRÉVIO E EXPRESSO CONSENTIMENTO DO ONE PAY ensejará indenização a ser paga pelo
							Estabelecimento aos detentores dos direitos sobre os SINAIS DISTINTIVOS;
						</p>
						<p>
							20.4. A presente autorização, concedida de forma não exclusiva, tem por finalidade única e
							exclusivamente a reprodução dos SINAIS DISTINTIVOS, relacionada à identificação da prestação
							de serviços financeiros pela ONE PAY, e não deve ser interpretada como (i) licença de uso
							dos SINAIS DISTINTIVOS; ou (ii) atividade de qualquer uma das empresas das Organizações ONE
							PAY no ramo de atividade do Estabelecimento;
						</p>
						<p>
							20.5. A presente autorização também não permite o exercício de qualquer outro direito em
							relação aos SINAIS DISTINTIVOS, sendo inclusive vedado o direito de usar qualquer variação
							dos SINAIS DISTINTIVOS, isoladamente ou em combinação com outras palavras sem o prévio e
							expressamente consentimento da ONE PAY;
						</p>
						<p>
							20.6. É responsabilidade do Estabelecimento zelar pelos SINAIS DISTINTIVOS na utilização
							descrita na Cláusula 20 do presente Contrato. TODO E QUALQUER MATERIAL DO ESTABELECIMENTO,
							INCLUSIVE MATERIAL DE PROPAGANDA, CONTENDO OS SINAIS DISTINTIVOS DEVERÁ SER PRÉVIA E
							EXPRESSAMENTE APROVADO PELA ONE PAY, A QUALQUE TERÁ PODER DE VETO;
						</p>
						<p>
							20.7. O Estabelecimento deve informar imediatamente a ONE PAY qualquer utilização indevida
							dos SINAIS DISTINTIVOS por terceiros que venha a ter conhecimento, sendo que o direito de
							defesa dos SINAIS DISTINTIVOS caberá sempre exclusivamente a ONE PAY. O Estabelecimento
							assume desde já o compromisso de cooperar com a ONE PAY na defesa dos interesses desta nos
							SINAIS DISTINTIVOS. 20.8. Fica vedado ao Estabelecimento a cessão, sub-licenciamento ou
							transferência dos direitos e obrigações advindos do presente Contrato;
						</p>
						<p>
							20.9. O Contrato não transfere para o Estabelecimento qualquer direito de propriedade
							intelectual que a ONE PAY possua sobre os seus processos e sistemas e/ou qualquer direito
							intelectual que venha a criar, construir ou adquirir. O Estabelecimento não deve, a qualquer
							tempo, reivindicar ou adquirir qualquer direito, título ou interesse sobre os SINAIS
							DISTINTIVOS. O Estabelecimento reconhece e compromete-se a respeitar todos os direitos,
							títulos e interesses da ONE PAY sobre os SINAIS DISTINTIVOS, obrigando-se a não intentar
							qualquer ação que possa prejudicar ou questionar ou anular tais direitos, no Brasil ou no
							exterior;
						</p>
						<p>
							20.10. Após o término deste Contrato por qualquer motivo, o Estabelecimento deverá cessar o
							uso dos SINAIS DISTINTIVOS e dos Instituidores de Arranjo de Pagamento, de forma
							irrevogável, irretratável e imediata, bem como dos Equipamentos, aparelhos, software e
							materiais cedidos pela ONE PAY.
						</p>
						<p>21. DISPOSIÇÕES GERAIS</p>
						<p>
							21.1. O Estabelecimento expressamente autoriza e reconhece que a ONE PAY poderá realizar a
							gravação dos telefone mas relacionados às Transações e/ou a este Contrato;
						</p>
						<p>
							21.2. As Partes reconhecem como válidas e eficazes as comunicações por meio da Central de
							Atendimento, do Portal do Cliente e por meio do correio eletrônico (e-mail) indicado na
							Proposta de Credenciamento. Considera-se recebido pelo Estabelecimento qualquer comunicação,
							notificação ou aviso disponibilizado no Portal do Cliente e/ou enviado para o endereço
							físico ou eletrônico indicado a ONE PAY;
						</p>
						<p>
							21.3. Durante a vigência do Contrato, o Estabelecimento poderá receber mensagens eletrônicas
							da ONE PAY, de modo a assegurar a execução contratual e pós-contratual de obrigações
							referentes a este Contrato, tais como avisos relacionados a alterações contratuais,
							atualização de tecnologias, situação do Sistema ONE PAY, entre outros. Essas mensagens não
							serão consideradas indesejadas, abusivas, spam, nem e-mail marketing, tendo em vista que sua
							finalidade é manter o Estabelecimento informado a respeito de sua relação contratual com a
							ONE PAY;
						</p>
						<p>
							21.4. A ONE PAY não será responsável por quaisquer falhas, interrupções ou atrasos no
							cumprimento de suas obrigações, bem como quando decorrentes de caso fortuito ou de força
							maior, sendo estes excludentes de responsabilidade nos termos do artigo 393 do Código Civil
							Brasileiro, incluindo, entre outros, atos governamentais, limitações impostas por parte do
							Poder Público, interrupção na prestação de serviços sob licença, autorização, permissão ou
							concessão governamental (como fornecimento de energia elétrica e serviços de telefonia,
							atuação de operadoras de serviço de telecomunicações interconectadas à rede do
							Estabelecimento, entre outros), catástrofes, greves, perturbações da ordem pública e demais
							eventos de semelhante natureza;
						</p>
						<p>
							21.5. O não exercício, por qualquer das Partes, de pretensão ou direito que lhe assegure
							este Contrato ou a Lei não significará alteração ou novação de suas disposições e condições,
							não impedindo o exercício da mesma pretensão ou direito em época subsequente ou em idêntica
							ou análoga ocorrência posterior. 21.6. Caso qualquer disposição ou obrigação resultante
							deste Contrato seja considerada ou se torne inválida, ilegal ou inexequível em qualquer
							aspecto, a validade, legalidade e exequibilidade das demais disposições ou obrigações não
							serão afetadas de nenhuma forma. As Partes se comprometem a negociar de boa-fé uma alteração
							ou substituição da disposição ou obrigação considera inválida, ilegal ou inexequível,
							conforme necessário, para atender a intenção original das Partes;
						</p>
						<p>
							21.7. Não se estabelece, por força deste Contrato, qualquer vínculo societário, trabalhista
							ou empregatício entre as Partes, correndo por conta exclusiva de cada Parte todas as
							despesas com seus empregados, prepostos, contratados e subcontratados, inclusive encargos
							decorrentes da legislação vigente, seja trabalhista, previdenciária, securitária ou qualquer
							outra;
						</p>
						<p>
							21.8. A relação entre as Partes versa única e exclusivamente sobre o objeto deste Contrato,
							não podendo, em nenhuma circunstância, ser interpretada como relação de associação, de
							sociedade a qualquer título, de empregado empregador, ou de qualquer outra forma que não a
							prevista neste Contrato; 21.9. Este Contrato não gera nenhum direito de exclusividade para
							qualquer das Partes, podendo o Estabelecimento firmar contratos semelhantes com outras
							empresas que possuam atividades semelhantes a ONE PAY;
						</p>
						<p>
							21.10. Os tributos e contribuições que incidam ou venham a incidir sobre as importâncias
							pagas a ONE PAY e/ou ao Estabelecimento em decorrência direta ou indireta deste Contrato
							serão suportados pelo seu contribuinte, assim definido na legislação que instituir e/ou
							regular referidos tributos e contribuições;
						</p>
						<p>
							21.11. O Estabelecimento concorda que as gravações magnéticas, digitalizadas ou telefônicas,
							de negociações envolvendo qualquer Produto, qualquer negociação específica ou qualquer
							termo, cláusula ou condição deste Contrato, poderão ser utilizadas como meio de prova,
							inclusive em Juízo, por qualquer das Partes;
						</p>
						<p>
							21.12. Ocorrendo fatos não previstos pela ONE PAY que possam prejudicar o equilíbrio
							econômico-financeiro do Contrato, afetando a adequada manutenção da operacionalidade do
							Sistema ONE PAY, os preços e encargos referidos no Contrato poderão sofrer alterações, a
							serem prévia e expressamente comunicadas ao Estabelecimento, de forma a restaurar o
							equilíbrio contratual entre as Partes e a eficiência do Sistema ONE PAY;
						</p>
						<p>
							21.13. A ONE PAY poderá ceder ou transferir, total ou parcialmente, os seus direitos e
							obrigações decorrentes deste Contrato para sociedades pertencentes ao seu grupo econômico,
							ou quaisquer terceiros, independente de consentimento, comunicação ou aviso ao
							Estabelecimento;
						</p>
						<p>
							21.14. Os direitos e obrigações do Estabelecimento previstos neste Contrato não poderão ser
							cedidos ou transferidos, no a todo ou em parte, direta ou indiretamente, sem o prévio
							consentimento escrito da ONE PAY;
						</p>
						<p>
							21.15. O Estabelecimento obriga-se a respeitar a legislação que dispõe sobre o combate à
							corrupção (especialmente a Lei 12.846/13), a legislação trabalhista, e a legislação
							ambiental em vigor no Brasil, declarando ainda que: a) não foi condenado definitivamente, na
							esfera judicial ou administrativa, pelas práticas listadas no artigo 5º da Lei 12.846/13;
							por questões trabalhistas envolvendo a saúde, segurança ocupacional, trabalho em condição
							análoga a de escravo ou trabalho infantil; ou ainda por questões relacionadas a questões
							socioambientais; e b) suas atividades e propriedades estão em conformidade com a legislação
							ambiental brasileira;
						</p>
						<p>
							21.16. Este Contrato beneficiará os respectivos sucessores das Partes, e as obrigações e
							responsabilidades assumidas no âmbito deste Contrato pelas Partes vincularão seus
							respectivos sucessores e cessionários;
						</p>
						<p>
							21.17. Este Contrato constitui título executivo extrajudicial, nos termos do art. 784,
							inciso II, da Lei nº 13.105, de 16 de março de 2015.
						</p>
						<p>22. FORO</p>
						<p>22.1. Este Contrato é regido pelas leis da República Federativa do Brasil.</p>
						<p>
							22.2. Fica eleito o Foro da Comarca da Cidade de São Paulo para dirimir eventuais questões
							ou litígios entre as Partes, sendo facultado a ONE PAY optar pelo foro do domicílio do
							Estabelecimento.
						</p>
						<p>
							Faz parte integrante deste instrumento a PROPOSTA DE CREDENCIAMENTO ONE PAY, a qual contém
							as assinaturas das partes e testemunhas.
						</p>
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						className={classes.dialogOK}
						onClick={aceitarTermo}
						color="primary"
						// variant="contained"
						autoFocus
					>
						Aceito
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default TermoOnePay;
