// Action Types

export const Types = {
	SET_FILTERS: 'filters/SET_FILTERS',
	RESET_FILTERS: 'filters/RESET_FILTERS',
};

// Reducer

const initialState = {};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case Types.SET_FILTERS:
			return {
				...state,
				[action.slug]: action.filters,
			};

		case Types.RESET_FILTERS:
			return initialState;

		default:
			return state;
	}
};

export default reducer;

export const setFilters = (slug, filters) => ({
	type: Types.SET_FILTERS,
	slug,
	filters,
});

export const resetFilter = () => ({
	type: Types.RESET_FILTERS,
});
