import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Card, CardContent, Avatar, Typography } from '@material-ui/core';
import { FaCheck } from 'react-icons/fa';
import ZCalculadoraModal from './ZCalculadoraModal';
import { CircularProgress } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
	filtros: {
		borderRadius: 50,
		marginBottom: 30,
		color: `${theme.palette.primary.main}`,
		padding: '5px 10px',
	},
	main: {
		minWidth: 150,
		borderColor: `${theme.palette.primary.main}`,
		backgroundColor: `${theme.palette.primary.main}`,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:nth-child(1)': {
			color: 'white',
		},
		top: 10,
		borderWidth: '2px',
		margin: 15,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}`,
			color: theme.palette.getContrastText(theme.palette.primary.main),
			'&:nth-child(1)': {
				color: 'white',
			},
		},
	},
	green: {
		color: 'green',
		marginLeft: 4,
	},
	red: {
		color: 'red',
		marginLeft: 4,
	},
	items: {
		margin: 5,
	},
	blue: { backgroundColor: `${theme.palette.primary.main}`, color: 'white' },
	description: {
		color: `${theme.palette.primary.main}`,
		fontSize: 20,
	},
}));
const ZCards = props => {
	const classes = useStyles();
	const [open, setOpen] = useState();
	const [loading, setLoading] = useState(false);

	return (
		<>
			<Grid key={props.item.id} container justifyContent="center" xs={12} sm={4} md={4} lg={4}>
				<Card variant="outline" style={{ borderRadius: 20, margin: 15 }}>
					<CardContent>
						<Grid container justifyContent="center" xs={12} sm={12} md={12}>
							<Grid item xs={2} sm={2}>
								<Avatar className={classes.blue}>
									<FaCheck />
								</Avatar>
							</Grid>
							<Grid align="center" item xs={10} sm={10}>
								<Typography variant="h4" align="center" className={classes.filtros}>
									{props.item.name}
								</Typography>
							</Grid>
							{props.item.description.map((item, index) => {
								return (
									<>
										<Grid align="center" className={classes.description} item xs={10} sm={10}>
											<span className={classes.items}>{`${item} -  `}</span>
											{props.item.diff[index] > 0 ? (
												<>
													{`${props.item.diff[index] / 100}%`}
													<ArrowDownward className={classes.green} />
												</>
											) : (
												<>
													{`${(props.item.diff[index] * -1) / 100}%`}
													<ArrowUpward className={classes.red} />
												</>
											)}
										</Grid>
									</>
								);
							})}
							{/*	<Grid align="center" className={classes.description} item xs={10} sm={10}>
								Score: {props.item.score}
						</Grid> */}
							<Grid item xs={12} sm={12} md={12} align="center">
								<Button
									className={classes.main}
									onClick={() => {
										setOpen(true);
										setLoading(true);
									}}
									variant="outlined"
								>
									{loading ? <CircularProgress /> : <p> Ver Mais Detalhes</p>}
								</Button>

								{loading && (
									<ZCalculadoraModal
										open={open}
										onClose={() => {
											setOpen(false);
											setLoading(false);
										}}
										id={props.item.id}
									/>
								)}
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</>
	);
};

export default ZCards;
