import React, { useState, useMemo, useEffect } from 'react';
import {
	Grid,
	Typography,
	Container,
	Paper,
	Button,
	SvgIcon,
	CircularProgress,
	Step,
	StepButton,
	Stepper,
	Link,
} from '@material-ui/core';
import { ZCard, ZText, ZAlert, ZLoader } from '../system';
import { Mask, ValidatorForm, Isset } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import { makeStyles } from '@material-ui/core/styles';
import { FiShoppingBag } from 'react-icons/fi';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import config from 'react-global-configuration';
import axios, { AxiosError } from 'axios';
import moment from 'moment';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaBarcode, FaRegCopy, FaRegCreditCard } from 'react-icons/fa';
import ONEPAYBLACK from '../../assets/images/onepay_black.svg';
import ELO from '../../assets/images/ELO.svg';
import VISA from '../../assets/images/VISA.svg';
import DINERS from '../../assets/images/DINERS.svg';
import AMEX from '../../assets/images/AMEX.svg';
import HIPER from '../../assets/images/HIPER.svg';
import HIPERCARD from '../../assets/images/HIPERCARD.svg';
import MASTER_CARD from '../../assets/images/MASTER_CARD.svg';
import AURA from '../../assets/images/AURA.svg';
import JCB from '../../assets/images/JCB.svg';
import BANESCARD from '../../assets/images/BANESCARD.svg';
import BILLET from '../../assets/images/BILLET.svg';
import PIX from '../../assets/images/PIX.svg';
import LOGOONEPAY from '../../assets/images/onepay-pagamentos.png';
import LOGOONEPAYWHITE from '../../assets/images/onepay-pagamentos-white.png';

const tiposPagamento = {
	1: 'Boleto Bancário',
	2: 'Débito',
	3: 'Cartão de Crédito',
	4: 'Comissão',
	5: 'PIX',
};

const useStyles = makeStyles(theme => ({
	paper: {
		borderRadius: 5,
		padding: 32,
		marginLeft: 8,
		marginRight: 8,
		marginBottom: 16,
	},
	containerPaper: {
		margin: '32px auto',
		[theme.breakpoints.down('xs')]: {
			margin: '16px 0',
		},
	},
	greenContainer: {
		backgroundColor: '#50c878',
		width: '100%',
		minHeight: '50vh',
		display: 'flex',

		'& .container__icon': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: '32px',
			position: 'relative',
			backgroundColor: '#FFF',
			borderRadius: '50%',
			width: '100px',
			height: '100px',
		},

		'& .container__icon-check': {
			position: 'absolute',
			top: '66px',
			left: '79px',
		},

		'& .paper': {
			borderRadius: 5,
			padding: 32,
			position: 'absolute',
			// top: '55%',

			'& .paper__content-title': {
				color: '#424242',
				fontSize: '18px',
				fontWeight: 'bold',
				margin: 0,
			},

			'& .paper__content-text': {
				color: '#757575',
				fontSize: '14px',
				lineHeight: '24px',
				margin: 0,
			},
		},
	},
	flags: {
		width: 45,
		height: 26,
		padding: 2,
	},
	button: {
		border: '2px solid #777',
		color: '#777',
		padding: '12px 8px',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: '#EEEEEE',
		},
		'&:disabled': {
			border: '2px solid #187bcd',
			color: '#187bcd',
		},
	},
	nextButton: {
		textTransform: 'none',
		backgroundColor: '#051587',
		border: '2px solid #051587',
		color: '#fff',
		padding: '12px 8px',
		'&:hover': {
			color: '#fff',
			backgroundColor: '#2a9df4',
			border: '2px solid #2a9df4',
		},
	},
	finishButton: {
		textTransform: 'none',
		backgroundColor: '#051587',
		border: '2px solid #051587',
		color: '#fff',
		padding: '12px 8px',
		'&:hover': {
			color: '#fff',
			border: '2px solid #2a9df4',
			backgroundColor: '#2a9df4',
		},
	},
	backButton: {
		textTransform: 'none',
		backgroundColor: 'transparent',
		color: '#777',
		border: '2px solid #051587',
		padding: '12px 8px',
		'&:hover': {
			border: '2px solid #2a9df4',
			color: '#fff',
			backgroundColor: '#2a9df4',
		},
	},
	pixCopyPasteButton: {
		textTransform: 'none',
		border: '2px solid #000',
	},
	title: {
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
		},
	},
	label: {
		[theme.breakpoints.down('xs')]: {
			marginTop: 32,
		},
	},
	description: {
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
		},
	},
	hideMobile: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	redContainer: {
		backgroundColor: '#D9272D',
		width: '100%',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		color: '#FFF',
	},
	greenContainerUtilizedLink: {
		backgroundColor: '#50c878',
		width: '100%',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		color: '#FFF',
	},
	displayMobile: {
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column-reverse',
		},
	},
	hideLogo: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	gridQrCode: {
		margin: 'auto',
		width: '256px',
		height: '256px',
		marginBottom: '15px',
		marginTop: '15px',
	},
}));

const PagarVendaDigitada = props => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const validateFields = {
		cliente: {
			nome: {
				required: true,
			},
			email: {
				required: true,
				email: true,
			},
			// celular: {
			// 	required: true,
			// },
			cpf: {
				required: true,
			},
		},
		endereco: {
			logradouro: {
				required: true,
			},
			numero: {
				required: true,
			},
			cep: {
				required: true,
			},
			cidade: {
				required: true,
			},
			estado: {
				required: true,
			},
		},
		cartao: {
			titular: {
				required: true,
			},
			validade: {
				required: true,
			},
			numero: {
				required: true,
			},
			codigoSeguranca: {
				required: true,
			},
		},
	};

	const message = useSelector(state => state.alert);
	const [text, setText] = useState('Carregando...');
	const [operacao, setOperacao] = useState({});
	const [loading, setLoading] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [completed] = React.useState({});
	const steps = ['Dados Pessoais', 'Pagar', 'Fim!'];
	// eslint-disable-next-line no-unused-vars
	const [copy, setCopy] = useState(false);

	const [onError, setOnError] = useState(false);
	const [pagamentoAprovado, setPagamentoAprovado] = useState(false);
	// const [dataPagamento, setDataPagamento] = useState({"planoId":"4","cliente":{"nome":"Teste2","email":"dafdsf@hotmail.com","dataNascimento":"1991-12-26","cpf":"19860489009","telefone":"1421421241","celular":"14241241241"},"endereco":{"logradouro":"Rua Leoneta","numero":"4","cep":"03380235","cidade":"São Paulo","estado":"SP","complemento":"", "bairro": "Das estelas"},"cartao":{"titular":"Dayglor Campos","validade":"02/07","numero":"5234211181847112","codigoSeguranca":"069"}});
	const [dataPagamento, setDataPagamento] = useState({
		token: props.token,
		tipoPagamentoId: 3,
		parcelas: 1,
		valor: 0,
		juros: 0,
		limit_installments: 0,
		cliente: {
			nome: '',
			email: '',
			dataNascimento: '',
			cpf: '',
			cnpj: '',
			telefone: '',
			celular: '',
		},
		endereco: {
			logradouro: '',
			numero: '',
			cep: '',
			cidade: '',
			estado: '',
			complemento: '',
			bairro: '',
		},
		cartao: {
			titular: '',
			validade: '',
			numero: '',
			codigoSeguranca: '',
		},
		valorParcela: 0,
		valorTotal: 0,
		//sessionId: document.getElementById('sessionInputOP').value,
	});
	// const [dataPagamento, setDataPagamento] = useState({"planoId":"3","cliente":{"nome":"f dsa fdsaf","email":"fdafdsa@hotmail.com","dataNascimento":"1991-12-26","cpf":"74575174050","telefone":"1124141412","celular":"41241241212"},"endereco":{"logradouro":"Rua Leoneta","numero":"421","cep":"03380235","cidade":"São Paulo","estado":"SP","complemento":""},"cartao":{"titular":"Titular Cartão","validade":"12/23","numero":"5346955803456653","codigoSeguranca":"124"}});

	const [data, setData] = useState({
		name: '',
		description: '',
		setup_amount: 0.0,
		amount: 0.0,
		limit_installments: 0,
		grace_period: '',
		tolerance_period: '',
		frequency: '',
		zoop_plano_id: null,
		interval: 1,
		juros: 0,
		tipo_pagamento_id: 3,
		//sessionId: document.getElementById('sessionInputOP').value,
	});

	const [erroMessage, setErrorMessage] = useState('');

	const token = useSelector(store => store.auth.token);
	// const estabelecimento = useSelector(store => store.auth.estabelecimento);

	const handleStep = step => () => {
		document.getElementById('paper').scrollIntoView();
		setActiveStep(step);
	};

	const handleNext = () => {
		dispatch(addMessage({ type: 'error', message: '' }));
		setActiveStep(prevActiveStep => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	};

	useEffect(() => {
		if (!props.token) {
			setText(null);
		}
		axios
			.get(`${config.get('apiUrl')}pre_venda/${props.token}/token`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then(res => {
				if (res.data.success) {
					setData({
						titulo: res.data.preVenda.titulo,
						descricao: res.data.preVenda.descricao,
						amount: res.data.preVenda.amount / 100,
						data_expiracao: res.data.preVenda.data_expiracao,
						parcelamento_ate: res.data.preVenda.parcelamento_ate,
						logo: !!res.data.preVenda.logo,
						limit_installments: res.data.preVenda.limit_installments,
						logoUrl: Isset(() => res.data.preVenda.estabelecimento.arquivo.url)
							? res.data.preVenda.estabelecimento.arquivo.url
							: null,
						nome_fantasia: res.data.preVenda.estabelecimento.nome_fantasia,
						razao_social: res.data.preVenda.estabelecimento.razao_social,
						identificacao_fatura: res.data.preVenda.estabelecimento.identificacao_fatura,
						estabelecimentoId: res.data.preVenda.estabelecimento.id,
						juros: res.data.preVenda.juros,
						juros_a_partir: res.data.preVenda.juros_a_partir,
						tipo_pagamento_id: res.data.preVenda.tipo_pagamento_id,
						//sessionId: document.getElementById('sessionInputOP').value,
					});
					setDataPagamento({
						...dataPagamento,
						valor: res.data.preVenda.amount / 100,
						tipoPagamentoId: res.data.preVenda.tipo_pagamento_id,
						valorParcela: res.data.preVenda.amount / 100,
						valorTotal: res.data.preVenda.amount / 100,
						parcelas: res.data.preVenda.limit_installments ? res.data.preVenda.parcelamento_ate : 1,
						//sessionId: document.getElementById('sessionInputOP').value,
					});
					// //Anti-Fraude-------------------------------------------------------------------------------------
					// const script = document.createElement('script');
					// let fingerprint_antifraud = '';
					// if (res.data.fingerPrintId) {
					// 	fingerprint_antifraud = res.data.fingerPrintId;
					// }

					// if (fingerprint_antifraud === '') {
					// 	fingerprint_antifraud = 'fycd8xcpzhprqazd7dqk'; //Antifraud Onepay
					// }
					// script.innerHTML = `(function (a, b, c, d, e, f, g) {
					// 	a['CsdpObject'] = e; a[e] = a[e] || function () {
					// 	(a[e].q = a[e].q || []).push(arguments)
					// 	}, a[e].l = 1 * new Date(); f = b.createElement(c),
					// 	g = b.getElementsByTagName(c)[0]; f.async = 1; f.src = d; g.parentNode.insertBefore(f, g)
					// 	})(window, document, 'script', '//device.clearsale.com.br/p/fp.js', 'csdp');
					// 	csdp('app', '${fingerprint_antifraud}');
					// 	csdp('outputsessionid', 'sessionInputOP');`;
					// document.body.appendChild(script);
					// //Anti-Fraude-------------------------------------------------------------------------------------
				} else {
					setText(res.data.message);
					setData({
						message: res.data.error,
						success: res.data.success,
					});
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.token]);

	useEffect(() => {
		if (erroMessage) {
			const timer = setTimeout(() => setErrorMessage(''), 5000);
			return () => clearTimeout(timer);
		}
	}, [erroMessage]);

	const handleSubmitForm = async () => {
		dispatch(addMessage({ type: 'error', message: '' }));
		setLoading(true);

		if (!props.token) {
			dispatch(addMessage({ type: 'error', message: 'Token inválido' }));
			setLoading(false);
			return false;
		}

		try {
			if (dataPagamento.cliente.cnpj.length === 14) {
				validateFields.cliente.dataNascimento = {
					required: false,
				};
			}
			if (data.tipo_pagamento_id === 1 || data.tipo_pagamento_id === 5) {
				delete validateFields.cartao;
			}

			ValidatorForm(validateFields, dataPagamento);
		} catch (erro) {
			if (axios.isAxiosError(erro)) {
				console.log(erro.message);
			}
			setActiveStep(0);
			dispatch(addMessage({ type: 'error', message: erro.message }));
			setLoading(false);
			setOnError(true);
			return false;
		}

		const taxpayer = dataPagamento.cliente.cpf.replace(/\D+/g, '');

		const dadosPagamento = {
			...dataPagamento,
			// type: 1,
			// juros: 0,
			// tipoEstabelecimentoId: 1,
			tipoPagamentoId: data.tipo_pagamento_id,
			estabelecimento_id: data.estabelecimentoId,
			// sessionId,
			cliente: {
				...dataPagamento.cliente,
				cpf: taxpayer.length < 14 ? taxpayer : '',
				cnpj: taxpayer.length >= 14 ? taxpayer : '',
			},
			valor: dataPagamento.valorTotal > data.amount ? +dataPagamento.valorTotal.toFixed(2) : dataPagamento.valor,
			token: props.token,
		};

		console.log({ dadosPagamentoVendaDigitada: dadosPagamento });

		try {
			const res = (
				await axios.post(config.get('apiUrl') + 'vendas/token', dadosPagamento, {
					headers: { Authorization: `Bearer ${token}` },
				})
			).data;

			if (res.success) {
				// Dispara msg de sucesso
				dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));

				// Ajusta os setters
				setPagamentoAprovado(true);
				setOperacao(res.pedido);
				setData({ ...data });
			}
		} catch (error) {
			if (axios.isAxiosError(error)) {
				if (error.response) {
					let errorType = 'default';
					if (
						/Your card was declined.*credit card was declined.*contact your bank/i.test(
							error.response.data.message
						)
					) {
						errorType = 'card_declined';
					}
					if (/Sorry, the *card zero dollar* validation has failed./i.test(error.response.data.message)) {
						errorType = 'card_zero_dollar';
					}

					if (/Your *\"taxpayer_id\" value is incorrect*./i.test(error.response.data.message)) {
						errorType = 'taxpayer_id';
					}
					switch (errorType) {
						case 'card_declined':
							setErrorMessage(
								'Seu cartão foi recusado. Para obter informações sobre o motivo, entre em contato com seu banco ou fornecedor de cartão de crédito.'
							);
							break;
						case 'card_zero_dollar':
							setErrorMessage(
								'Não foi possível concluir a operação com este cartão. Por favor, tente novamente ou utilize outro cartão.'
							);
							break;
						case 'taxpayer_id':
							setErrorMessage(
								'Parâmetro(s) inválido(s). O valor do seu (CPF/CNPJ) está incorreto. Por favor, verifique os parâmetros da solicitação.'
							);
							break;
						default:
							setErrorMessage('Falha ao realizar pagamento. Caso o erro persista, contate o suporte.');
							break;
					}
				}
			}

			setLoading(false);
		}
	};

	const getEndereco = async value => {
		if (value.length === 8) {
			var res = await axios.get(config.get('apiUrl') + 'buscar_cep/' + value);
			if (res.data.success === false || res.data.endereco.erro) {
				setDataPagamento({
					...dataPagamento,
					endereco: {
						...dataPagamento.endereco,
						cep: value,
						logradouro: '',
						cidade: '',
						estado: '',
						complemento: '',
						bairro: '',
					},
				});
				return false;
			}

			setDataPagamento({
				...dataPagamento,
				endereco: {
					...dataPagamento.endereco,
					cep: Mask.OnlyNumber(res.data.endereco.cep),
					logradouro: res.data.endereco.logradouro,
					cidade: res.data.endereco.localidade,
					estado: res.data.endereco.uf,
					complemento: res.data.endereco.complemento,
					bairro: res.data.endereco.bairro,
				},
			});
		}
	};

	const parcelas = [];
	let textParcela = '';
	if (data.amount > 10) {
		if (!data.limit_installments) {
			for (var i = 1; i <= data.parcelamento_ate; i++) {
				let value = Mask.Real(data.amount / i);
				let amount = data.amount / i;
				if (data.juros > 0 && i > 1) {
					if (i >= data.juros_a_partir) {
						let amountJuros = +((data.amount / 100) * (i * data.juros)).toFixed(2);
						amount = data.amount + amountJuros;
						value = Mask.Real(+(amount / i).toFixed(2));
					}
				}
				textParcela = ` x ${value}`;
				if (data.juros && i !== 1 && i >= data.juros_a_partir) {
					textParcela += ` c/ juros (${Mask.Real(+amount)})`;
				} else {
					textParcela += ` s/ juros`;
				}
				parcelas.push({
					id: i, //number of installments
					name: i + textParcela,
					value: amount,
				});
			}
		} else {
			textParcela = `${+data.parcelamento_ate} x ${Mask.Real(
				+data.amount / +data.parcelamento_ate
			)} s/ juros (${Mask.Real(+data.amount)})`;
			parcelas.push({
				id: +data.parcelamento_ate, //number of installments
				name: textParcela,
				value: +data.amount / +data.parcelamento_ate,
			});
		}
	} else {
		parcelas.push({ id: 1, name: '1 vez' });
	}

	const maskCreditCard = useMemo(() => {
		const regex = /\b(?:\d{4}[ -]?){3}(?=\d{4}\b)/gm;
		const subst = `**** **** **** `;

		return dataPagamento.cartao.numero.replace(regex, subst);
	}, [dataPagamento.cartao.numero]);

	if (text && text === 'Carregando...' && !data.titulo) {
		return text;
	}

	if (pagamentoAprovado) {
		return (
			<>
				{pagamentoAprovado ? (
					<Grid container className={classes.greenContainer}>
						<Grid container item justifyContent="center">
							<div className="container__icon">
								<div>
									<FiShoppingBag color="#059142" size="50px" strokeWidth="1px" />
								</div>

								<div className="container__icon-check">
									<SvgIcon
										component={CheckCircleIcon}
										fontSize="large"
										style={{ color: '#043927' }}
									/>
								</div>
							</div>
							<Grid container item justifyContent="center">
								<section>
									<p
										style={{
											color: '#fff',
											fontSize: '32px',
											margin: 0,
											padding: 10,
											textAlign: 'center',
										}}
									>
										{(() => {
											switch (data.tipo_pagamento_id) {
												case 1:
													return 'Pronto, seu boleto foi gerado!';
												case 3:
													return 'Pronto, seu pagamento está em análise e já será processado!';
												case 5:
													return 'Pronto, agora você só precisa finalizar seu pagamento!';
												default:
													return '';
											}
										})()}
									</p>
									<p
										style={{
											color: '#fff',
											fontSize: '18px',
											textAlign: 'center',
											marginBottom: data.tipo_pagamento_id === 3 ? '64px' : '128px',
											fontWeight: '500',
										}}
									>
										{`Operação: #${operacao.id}`}
									</p>
								</section>
							</Grid>
						</Grid>
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							style={{ marginTop: data.tipo_pagamento_id === 5 ? 152 : 64 }}
						>
							<Paper className="paper">
								<Grid justifyContent="center" alignItems="center">
									{(() => {
										switch (data.tipo_pagamento_id) {
											// BOLETO
											case 1:
												return (
													<Grid item container direction="row">
														<Grid item sm={1} xs={12}>
															<FaBarcode size="32px" />
														</Grid>
														<Grid item sm={11} xs={12}>
															<Typography variant="h6" gutterBottom>
																Você vai pagar {Mask.Real(dataPagamento.valorTotal)} via
																Boleto Bancário
															</Typography>
															<Typography variant="body2" gutterBottom>
																<p className="paper__content-text">
																	O boleto será enviado para o seu e-mail.
																</p>
																Faça o pagamento o quanto antes pois a compensação pode
																demorar até 3 dias úteis.
																{operacao.boleto
																	? 'Vencimento do boleto ' +
																	  moment(operacao.boleto.data_vencimento).format(
																			'DD/MM/YYYY'
																	  )
																	: ''}
																<p>
																	Código de barras do boleto:{' '}
																	{operacao.boleto
																		? operacao.boleto.codigo_barras
																		: ''}
																	<br />
																	<Link
																		href={operacao.urlBoleto}
																		target="_blank"
																		rel="noreferrer"
																	>
																		Visualizar Boleto
																	</Link>
																</p>
																<CopyToClipboard
																	text={
																		operacao.boleto
																			? operacao.boleto.codigo_barras
																			: ''
																	}
																	onCopy={() => setCopy(true)}
																>
																	<Button className={classes.pixCopyPasteButton}>
																		<FaRegCopy
																			size="12px"
																			style={{ marginRight: '5px' }}
																		/>
																		Copiar código de barras do Boleto
																	</Button>
																</CopyToClipboard>
															</Typography>
														</Grid>
													</Grid>
												);
											// CARTÃO
											case 3:
												return (
													<Grid item container direction="row">
														<Grid item sm={1} xs={12}>
															<FaRegCreditCard size="32px" />
														</Grid>
														<Grid item sm={11} xs={12}>
															<Typography variant="h6" gutterBottom>
																Você pagou {Mask.Real(dataPagamento.valorTotal)} via
																Cartão de Crédito
															</Typography>
															<Typography variant="body2" gutterBottom>
																<p className="paper__content-text">{maskCreditCard}</p>
																Na fatura do seu Cartão de Crédito você verá o pagamento
																em nome de{' '}
																{data && data.identificacao_fatura
																	? data.identificacao_fatura.toUpperCase()
																	: data.nome_fantasia.toUpperCase()}
																.
																<br />
															</Typography>
														</Grid>
													</Grid>
												);
											// PIX
											case 5:
												return (
													<>
														<Typography variant="body2" gutterBottom>
															Sua compra está reservada e aguardando o pagamento
														</Typography>
														<Grid
															item
															sm={12}
															style={{
																margin: 'auto',
																width: '256px',
																marginTop: '15px',
															}}
														>
															<CopyToClipboard
																text={operacao.pix ? operacao.pix.qrcode_env : ''}
																onCopy={() => setCopy(true)}
															>
																<Button className={classes.pixCopyPasteButton}>
																	<FaRegCopy
																		size="12px"
																		style={{ marginRight: '5px' }}
																	/>{' '}
																	Copiar código (Pix Copia e Cola)
																</Button>
															</CopyToClipboard>
														</Grid>
														<Grid item sm={12} className={classes.gridQrCode}>
															<QRCode
																size={256}
																imageSettings={{
																	src: ONEPAYBLACK,
																	x: null,
																	y: null,
																	height: 48,
																	width: 48,
																	excavate: true,
																}}
																renderAs="svg"
																value={operacao.pix ? operacao.pix.qrcode_env : ''}
															/>
														</Grid>
														<Typography variant="body2" gutterBottom>
															- Abra o aplicativo do seu banco no celular
															<br />
															- Selecione a opção de pagar com Pix / escanear QR code
															<br />- Você receberá por email a confirmação do pagamento
														</Typography>
													</>
												);
											default:
												return <></>;
										}
									})()}
									<br />
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				) : (
					<ZLoader />
				)}
			</>
		);
	}

	if (data.titulo) {
		return (
			<div style={{ background: 'rgb(230 231 236)', position: 'aboslute', minHeight: '100vh' }}>
				<Container maxWidth="md">
					<Grid container className={classes.displayMobile}>
						{erroMessage && <ZAlert type={message.type} message={erroMessage} time={moment().unix()} />}
						<Grid item xs={12} md={7} className={classes.containerPaper} id="paper">
							<Stepper style={{ backgroundColor: '#E6E7EC' }} nonLinear activeStep={activeStep}>
								{steps.map((label, index) => (
									<Step key={label}>
										<StepButton
											onClick={handleStep(index)}
											completed={completed[index]}
											disabled={index === 2 ? true : false}
										>
											{label}
										</StepButton>
									</Step>
								))}
							</Stepper>
							<Grid item xs={12}>
								<Paper className={classes.paper}>
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<Grid container spacing={2}>
													{activeStep === 0 && (
														<>
															<Grid item xs={12} sm={6} md={12}>
																<ZText
																	label="NOME COMPLETO"
																	id="clientName"
																	placeholder="Digite seu nome completo"
																	variant="outlined"
																	onError={onError}
																	required={true}
																	value={dataPagamento.cliente.nome}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			cliente: {
																				...dataPagamento.cliente,
																				nome: e.target.value,
																			},
																		})
																	}
																/>
															</Grid>
															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="CPF / CNPJ"
																	id="clientDocumentId"
																	type="tel"
																	placeholder="___.___.___-__"
																	variant="outlined"
																	onError={onError}
																	required={true}
																	value={dataPagamento.cliente.cpf}
																	onChange={e =>
																		setDataPagamento({
																			...dataPagamento,
																			cliente: {
																				...dataPagamento.cliente,
																				cpf:
																					e.length <= 14
																						? Mask.Cpf(e)
																						: Mask.Cnpj(e),
																			},
																		})
																	}
																/>
															</Grid>
															{
																<Grid item xs={12} sm={6} md={6}>
																	<ZText
																		label="DATA DE NASCIMENTO"
																		id="clientBirthDate"
																		placeholder="DD/MM/AAAA"
																		variant="outlined"
																		pattern="[0-9]*"
																		type="tel"
																		disabled={
																			dataPagamento.cliente.cpf.length > 14
																				? true
																				: false
																		}
																		mask={
																			dataPagamento.cliente.cpf.length > 14
																				? null
																				: '99/99/9999'
																		}
																		maskChar={null}
																		onError={onError}
																		required={
																			dataPagamento.cliente.cpf.length <= 14
																				? true
																				: false
																		}
																		value={
																			dataPagamento.cliente.cpf.length <= 14
																				? moment(
																						dataPagamento.cliente
																							.dataNascimento
																				  ).format('DD/MM/YYYY')
																				: ''
																		}
																		onBlur={e => {
																			let dataNascimento = moment(
																				e.target.value,
																				'DD/MM/YYYY'
																			).format('YYYY-MM-DD');
																			setDataPagamento({
																				...dataPagamento,
																				cliente: {
																					...dataPagamento.cliente,
																					dataNascimento: dataNascimento,
																				},
																			});
																		}}
																	/>
																</Grid>
															}
															{
																<Grid item xs={12} sm={6} md={6}>
																	<ZText
																		label="CELULAR"
																		id="clientCellphone"
																		pattern="[0-9]*"
																		type="tel"
																		placeholder="(__) _ ____-____"
																		variant="outlined"
																		mask={'(99) 9 9999-9999'}
																		maskChar={null}
																		onError={onError}
																		required={true}
																		value={dataPagamento.cliente.celular}
																		onBlur={e =>
																			setDataPagamento({
																				...dataPagamento,
																				cliente: {
																					...dataPagamento.cliente,
																					celular: Mask.OnlyNumber(
																						e.target.value
																					),
																				},
																			})
																		}
																	/>
																</Grid>
															}
															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="E-MAIL"
																	id="clientEmail"
																	type="email"
																	placeholder="Digite o seu e-mail"
																	variant="outlined"
																	email
																	onError={onError}
																	required={true}
																	value={dataPagamento.cliente.email}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			cliente: {
																				...dataPagamento.cliente,
																				email: e.target.value,
																			},
																		})
																	}
																/>
															</Grid>

															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="CEP"
																	id="addresZipCode"
																	mask={'99999-999'}
																	placeholder="_____-___"
																	onError={onError}
																	variant="outlined"
																	pattern="[0-9]*"
																	type="tel"
																	required={true}
																	value={dataPagamento.endereco.cep}
																	onChange={e => {
																		let cep = Mask.OnlyNumber(e);
																		setDataPagamento({
																			...dataPagamento,
																			endereco: {
																				...dataPagamento.endereco,
																				cep: cep,
																			},
																		});
																		getEndereco(cep);
																	}}
																/>
															</Grid>
															{dataPagamento.endereco.cep !== '' && (
																<>
																	<Grid item xs={12} sm={6} md={6}>
																		<ZText
																			label="NÚMERO"
																			id="addressNumber"
																			onError={onError}
																			required={true}
																			variant="outlined"
																			placeholder="Ex: 36"
																			pattern="[0-9]*"
																			type="tel"
																			value={dataPagamento.endereco.numero}
																			onBlur={e =>
																				setDataPagamento({
																					...dataPagamento,
																					endereco: {
																						...dataPagamento.endereco,
																						numero: e.target.value,
																					},
																				})
																			}
																		/>
																	</Grid>
																	<Grid item xs={12} sm={12} md={12}>
																		<ZText
																			label="LOGRADOURO"
																			id="addressStreet"
																			variant="outlined"
																			placeholder="Digite o logradouro de sua residência"
																			onError={onError}
																			required={true}
																			value={dataPagamento.endereco.logradouro}
																			onBlur={e =>
																				setDataPagamento({
																					...dataPagamento,
																					endereco: {
																						...dataPagamento.endereco,
																						logradouro: e.target.value,
																					},
																				})
																			}
																		/>
																	</Grid>

																	<Grid item xs={12} sm={6} md={6}>
																		<ZText
																			label="Complemento (opcional)"
																			id="addressAddtional"
																			value={dataPagamento.endereco.complemento}
																			variant="outlined"
																			placeholder="Ex: Bl 12 Ap 42"
																			onBlur={e =>
																				setDataPagamento({
																					...dataPagamento,
																					endereco: {
																						...dataPagamento.endereco,
																						complemento: e.target.value,
																					},
																				})
																			}
																		/>
																	</Grid>

																	<Grid item xs={12} sm={6} md={6}>
																		<ZText
																			label="BAIRRO"
																			id="addressNeighborhood"
																			variant="outlined"
																			placeholder="Digite seu bairro"
																			onError={onError}
																			required={true}
																			value={dataPagamento.endereco.bairro}
																			onBlur={e =>
																				setDataPagamento({
																					...dataPagamento,
																					endereco: {
																						...dataPagamento.endereco,
																						bairro: e.target.value,
																					},
																				})
																			}
																		/>
																	</Grid>

																	<Grid item xs={12} sm={9} md={9}>
																		<ZText
																			label="CIDADE"
																			id="addressCity"
																			onError={onError}
																			variant="outlined"
																			placeholder="Digite a cidade de sua residência"
																			required={true}
																			value={dataPagamento.endereco.cidade}
																			onBlur={e =>
																				setDataPagamento({
																					...dataPagamento,
																					endereco: {
																						...dataPagamento.endereco,
																						cidade: e.target.value,
																					},
																				})
																			}
																		/>
																	</Grid>
																	<Grid item xs={12} sm={3} md={3}>
																		<ZText
																			label="Estado (UF)"
																			id="addressState"
																			variant="outlined"
																			placeholder="Ex: SP"
																			mask={'aa'}
																			onError={onError}
																			required={true}
																			value={dataPagamento.endereco.estado}
																			onBlur={e =>
																				setDataPagamento({
																					...dataPagamento,
																					endereco: {
																						...dataPagamento.endereco,
																						estado: e.target.value,
																					},
																				})
																			}
																		/>
																	</Grid>
																</>
															)}
														</>
													)}

													{activeStep === 1 && (
														<>
															{data.amount > 10 && ''}
															{/* <Grid
																item
																xs={12}
																align="center"
																className={classes.displayMobile}
															>
																<Typography variant="h5" gutterBottom>
																	
																</Typography>
															</Grid> */}

															{data.tipo_pagamento_id === 3 && (
																<Grid item xs={12}>
																	<ZCard
																		test={e => {
																			// console.log(e)
																		}}
																		number={dataPagamento.cartao.numero}
																		name={dataPagamento.cartao.titular}
																		expiry={dataPagamento.cartao.validade}
																		cvc={dataPagamento.cartao.codigoSeguranca}
																		numberOnBlur={e => {
																			setDataPagamento({
																				...dataPagamento,
																				cartao: {
																					...dataPagamento.cartao,
																					numero: Mask.OnlyNumber(
																						e.target.value
																					),
																				},
																			});
																		}}
																		nameOnBlur={e =>
																			setDataPagamento({
																				...dataPagamento,
																				cartao: {
																					...dataPagamento.cartao,
																					titular: e.target.value,
																				},
																			})
																		}
																		expiryOnBlur={e =>
																			setDataPagamento({
																				...dataPagamento,
																				cartao: {
																					...dataPagamento.cartao,
																					validade: e.target.value,
																				},
																			})
																		}
																		cvcOnBlur={e =>
																			setDataPagamento({
																				...dataPagamento,
																				cartao: {
																					...dataPagamento.cartao,
																					codigoSeguranca: e.target.value,
																				},
																			})
																		}
																		onError={onError}
																		maxInstallment={parcelas}
																		installment={dataPagamento.parcelas}
																		installmentOnBlur={e => {
																			let valorParcela = data.amount;
																			let amount = data.amount;
																			if (e.target.value > 1 && data.juros) {
																				let amountJuros = +(
																					(data.amount / 100) *
																					(e.target.value * data.juros)
																				).toFixed(2);
																				amount = data.amount + amountJuros;
																				valorParcela = +(
																					amount / e.target.value
																				).toFixed(2);
																			}
																			setDataPagamento({
																				...dataPagamento,
																				parcelas: e.target.value,
																				valorParcela,
																				valorTotal: amount,
																			});
																		}}
																		cardGrid={{
																			xs: 12,
																			sm: 12,
																			md: 12,
																			lg: 12,
																		}}
																		containerInputsGrid={{
																			xs: 12,
																			sm: 12,
																			md: 12,
																			lg: 12,
																			marginTop: 30,
																		}}
																	/>
																</Grid>
															)}

															{data.tipo_pagamento_id === 1 && (
																<Grid item xs={12}>
																	<Typography variant="h6" gutterBottom>
																		Boleto Bancário
																	</Typography>
																	<Typography variant="body2" gutterBottom>
																		O boleto será enviado para o seu e-mail logo
																		após concluir o processo. <br />
																		Clique em continuar para prosseguir
																	</Typography>
																</Grid>
															)}

															{data.tipo_pagamento_id === 5 && (
																<Grid
																	container
																	item
																	xs={12}
																	direction="row"
																	justifyContent="center"
																>
																	<Grid
																		item
																		xs={12}
																		md={12}
																		sm={12}
																		justifyContent="center"
																	>
																		<Typography variant="h6" gutterBottom>
																			<strong>Pague com Pix</strong>
																		</Typography>
																	</Grid>
																	<p>
																		<strong>Imediato: </strong> faça seu pagamento
																		em poucos segundos e sem taxas adicionais.
																	</p>

																	<p>
																		<strong>Simples: </strong> para pagar basta
																		abrir o aplicativo do seu banco, procurar pelo
																		PIX e escanear o QRcode.
																	</p>

																	<p>
																		<strong>Seguro: </strong> o pagamento com PIX
																		foi desenvolvido pelo Banco Central para
																		facilitar suas compras.
																	</p>

																	<p>
																		Clique em <strong>Próximo Passo</strong> para
																		prosseguir sua compra
																	</p>
																</Grid>
															)}
														</>
													)}

													{activeStep === 2 && (
														<Grid container direction="row">
															<Grid item xs={12} md={6}>
																<strong>CLIENTE:</strong>
																<Typography variant="subtitle2" gutterBottom>
																	<p>
																		Nome: <br />
																		<b>{dataPagamento.cliente.nome}</b>
																	</p>
																	<p>
																		CPF / CNPJ: <br />
																		<b>{dataPagamento.cliente.cpf}</b>
																	</p>
																	<p>
																		E-mail: <br />
																		<b>{dataPagamento.cliente.email}</b>
																	</p>
																</Typography>
															</Grid>
															<Grid item xs={12} md={6} className={classes.label}>
																<strong>PAGAMENTO:</strong>
																<Typography variant="subtitle2" gutterBottom>
																	<p>{tiposPagamento[data.tipo_pagamento_id]}</p>
																	<p>
																		<span>
																			{' '}
																			{dataPagamento.parcelas} ve
																			{dataPagamento.parcelas > 1 ? 'zes ' : 'z '}
																			de{' '}
																			{Mask.Real(
																				dataPagamento.valorTotal /
																					dataPagamento.parcelas
																			)}
																		</span>
																	</p>
																	{dataPagamento.valorTotal > data.amount && (
																		<>
																			<p>
																				{' '}
																				Valor total: <br />
																				<b>
																					{Mask.Real(
																						dataPagamento.valorTotal
																					)}
																				</b>
																			</p>
																		</>
																	)}
																</Typography>
															</Grid>
														</Grid>
													)}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Paper>
								<Grid item xs={12}>
									<Grid
										spacing={2}
										container
										direction="row"
										justifyContent={window.innerWidth >= 568 ? 'flex-end' : 'center'}
									>
										{activeStep > 0 && (
											<Grid item xs={12} sm={6} md={5}>
												<a href="#paper" style={{ textDecoration: 'none' }}>
													<Button
														disabled={activeStep === 0}
														className={classes.backButton}
														onClick={handleBack}
														fullWidth
													>
														Voltar
													</Button>
												</a>
											</Grid>
										)}
										{activeStep < 2 && (
											<Grid item xs={12} sm={6} md={5}>
												<a href="#paper" style={{ textDecoration: 'none' }}>
													<Button
														className={classes.nextButton}
														fullWidth
														onClick={handleNext}
													>
														Próximo Passo
													</Button>
												</a>
											</Grid>
										)}
										{activeStep === 2 && (
											<Grid item xs={12} sm={6} md={5}>
												<Button
													onClick={() => handleSubmitForm()}
													className={classes.finishButton}
													disabled={loading}
													fullWidth
												>
													{loading ? (
														<CircularProgress size={24} style={{ color: '#fff' }} />
													) : (
														'Concluir'
													)}
												</Button>
											</Grid>
										)}
									</Grid>
									<Grid item xs={12} md={12} style={{ maxWidth: '100%' }}>
										<hr style={{ marginTop: '15px' }}></hr>
										<Grid
											container
											className={[classes.description, classes.displayMobile]}
											style={{ fontSize: 12 }}
										>
											<p style={{ marginLeft: 8, marginRight: 8 }}>
												<a
													href="https://www.1pay.com.br/termos-de-uso/"
													style={{ textDecoration: 'none' }}
													target="_blank"
													rel="noreferrer noopener"
												>
													Preciso de ajuda para preencher esta tela
												</a>
												.<br />
												<br />
												Onepay está processando este pedido a serviço de {data.nome_fantasia},
												ao continuar, você concorda com os{' '}
												<a
													href="https://www.1pay.com.br/termos-de-uso/"
													target="_blank"
													rel="noreferrer noopener"
												>
													Termos de Compra
												</a>
												.<br />
												<br />
												Onepay © {moment().year()} - Todos os direitos reservados
											</p>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={4} className={classes.containerPaper}>
							<Paper className={classes.paper}>
								<Grid container spacing={9} direction="row" justifyContent="center" alignItems="center">
									<Grid item xs={12} md={12} align="center">
										<a href="https://www.1pay.com.br" target="_blank" rel="noreferrer noopener">
											<img
												src={LOGOONEPAY}
												alt="Onepay Pagamentos"
												title="Onepay Pagamentos"
												style={{ maxWidth: '100%', maxHeight: '90px' }}
											/>
										</a>
									</Grid>
									{data.logo && data.logoUrl && (
										<img
											src={data.logoUrl}
											alt={data.nome_fantasia}
											title={data.nome_fantasia}
											style={{ maxWidth: '100%', maxHeight: '100px' }}
										/>
									)}
									<Grid item xs={12} md={12}>
										<Typography className={classes.description} variant="subtitle1" gutterBottom>
											<ZText
												label="VALOR TOTAL"
												disabled
												variant="outlined"
												value={Mask.Real(dataPagamento.valorTotal)}
											/>

											<ZText
												label="PARCELAMENTO"
												disabled
												variant="outlined"
												value={`${data.tipo_pagamento_id === 3 ? dataPagamento.parcelas : 1} ${
													dataPagamento.parcelas > 1 && data.tipo_pagamento_id === 3
														? 'vezes'
														: 'vez'
												} de ${
													data.tipo_pagamento_id === 3
														? data.limit_installments
															? Mask.Real(+data.amount / +data.parcelamento_ate)
															: Mask.Real(
																	+dataPagamento.valorParcela /
																		+dataPagamento.parcelas
															  )
														: Mask.Real(dataPagamento.valorTotal)
												}`}
											/>

											<ZText
												label="NOME DO PRODUTO"
												disabled
												variant="outlined"
												value={data.titulo}
											/>

											<ZText
												label="DESCRIÇÃO"
												disabled
												multiline
												rows="3"
												variant="outlined"
												value={data.descricao}
												style={{ height: 200 }}
											/>
										</Typography>
										{window.innerWidth < 600 && (
											<a href="#paper" style={{ textDecoration: 'none' }}>
												<Button className={classes.nextButton} fullWidth>
													Comprar Agora
												</Button>
											</a>
										)}
										{window.innerWidth > 600 && (
											<Typography
												className={classes.description}
												variant="subtitle1"
												gutterBottom
											>
												{data.tipo_pagamento_id === 1 && (
													<img
														src={BILLET}
														alt="Boleto Bancário"
														title="Boleto Bancário"
														className={classes.flags}
													/>
												)}
												{data.tipo_pagamento_id === 3 && (
													<span>
														<img
															src={MASTER_CARD}
															alt="Mastercard"
															title="Mastercard"
															className={classes.flags}
														/>
														<img
															src={VISA}
															alt="Visa"
															title="Visa"
															className={classes.flags}
														/>
														<img
															src={ELO}
															alt="Elo"
															title="Elo"
															className={classes.flags}
														/>
														<img
															src={DINERS}
															alt="Diners Club"
															title="Diners Club"
															className={classes.flags}
														/>
														<img
															src={AMEX}
															alt="American Express"
															title="American Express"
															className={classes.flags}
														/>
														<img
															src={HIPER}
															alt="Hiper"
															title="Hiper"
															className={classes.flags}
														/>
														<img
															src={HIPERCARD}
															alt="Hipercard"
															title="Hipercard"
															className={classes.flags}
														/>
														<img
															src={JCB}
															alt="JCB"
															title="JCB"
															className={classes.flags}
														/>
														<img
															src={BANESCARD}
															alt="Banescard"
															title="Banescard"
															className={classes.flags}
														/>
														<img
															src={AURA}
															alt="Aura"
															title="Aura"
															className={classes.flags}
															style={{ borderRadius: '15%' }}
														/>
													</span>
												)}
												{data.tipo_pagamento_id === 5 && (
													<img src={PIX} alt="Pix" title="Pix" className={classes.flags} />
												)}
											</Typography>
										)}
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</div>
		);
	} else if (data.message === 'Link de Pagamento já utilizado.') {
		return (
			<Grid container className={classes.greenContainerUtilizedLink}>
				<Grid container item justifyContent="center">
					<Grid item xs={12} md={12} align="center" className={classes.hideLogo}>
						<a href="https://www.1pay.com.br" target="_blank" rel="noreferrer noopener">
							<img
								src={LOGOONEPAYWHITE}
								alt="Onepay Pagamentos"
								title="Onepay Pagamentos"
								style={{ maxWidth: '100%', maxHeight: '90px' }}
							/>
						</a>
					</Grid>
					<Grid style={{ fontSize: 30, fontWeight: 'bold' }}></Grid>
				</Grid>
				<Grid style={{ fontSize: 18, border: 'solid', borderColor: '#FFF', borderRadius: '9px' }}>
					<p style={{ padding: '30px' }}>{data.message}</p>
				</Grid>
			</Grid>
		);
	} else {
		return (
			<Grid container className={classes.redContainer}>
				<Grid container item justifyContent="center">
					<Grid item xs={12} md={12} align="center" className={classes.hideLogo}>
						<a href="https://www.1pay.com.br" target="_blank" rel="noreferrer noopener">
							<img
								src={LOGOONEPAYWHITE}
								alt="Onepay Pagamentos"
								title="Onepay Pagamentos"
								style={{ maxWidth: '100%', maxHeight: '90px' }}
							/>
						</a>
					</Grid>
					<Grid style={{ fontSize: 30, fontWeight: 'bold' }}>
						{' '}
						<p>Ops! Algo deu errado....</p>
					</Grid>
				</Grid>
				<Grid style={{ fontSize: 18, border: 'solid', borderColor: '#FFF', borderRadius: '9px' }}>
					<p style={{ padding: '30px' }}>
						{data.message ? data.message : 'Link de Pagamento não encontrado.'}
					</p>
				</Grid>
			</Grid>
		);
	}
};

export default PagarVendaDigitada;
