import React, { useState, useEffect } from 'react';
import { Chip, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { Formatter } from '../../utils';
import moment from 'moment';
import { addMessage } from '../../store/alert';
import { useLocation } from 'react-router-dom';
import { ZFiltros, ZPagination, ZLoader, ZTitle, ZModalConfirm } from '../system';
import { FaFilter, FaDollarSign, FaEye, FaShare, FaWhatsapp, FaTrashAlt } from 'react-icons/fa';
import axios from 'axios';
import config from 'react-global-configuration';
import { makeStyles } from '@material-ui/core/styles';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
	menuIcon: {
		fontSize: 16,
		cursor: 'pointer',
		marginRight: 4,
	},
}));

const SellingCampaign = props => {
	const { history } = useReactRouter();
	const token = useSelector(store => store.auth.token);
	const classes = useStyles();

	const [loading, setLoading] = useState(true);
	const [sellingCampaign, setSellingCampaign] = useState([]);

	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const dispatch = useDispatch();

	// Paginação
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(30);
	const [totalRows, setTotalRows] = useState(0);

	const [removeSellingCampaign, setRemoveSellingCampaign] = useState({});
	const [removeModalOpen, setRemoveModalOpen] = useState(false);

	const [filtros, setFiltros] = useState({
		omni: '',
		omni2: '',
		omni3: '',
		statusSellingCampaigns: '',
		...filtersCache,
	});

	var env = '';
	switch (process.env.REACT_APP_ENV) {
		case 'development':
			env = 'devpay';
			break;
		case 'qas':
			env = 'qaspay';
			break;
		case 'production':
			env = 'pay';
			break;
		default:
			env = 'pay';
			break;
	}

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const getSellingCampaign = () => {
		setLoading(true);
		axios
			.get(`${config.get('apiUrl')}sellingCampaign`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					page,
					limit: rowsPerPage,
					filters: {
						...filtros,
					},
				},
			})
			.then(res => res.data)
			.then(data => {
				setLoading(false);
				if (data.success) {
					setSellingCampaign(data.sellingCampaign);
					setTotalRows(data.quantidade);
				}
			});
	};

	const handleAdd = () => {
		history.push('/sellingCampaign/create');
	};

	const filtrosChange = async data => {
		setPage(1);
		if (data.omni) {
			data.omni = data.omni.replace(/[^a-z0-9]/gi, '');
		}
		setFiltros(data);
	};

	useEffect(() => {
		getSellingCampaign();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtros]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(getSellingCampaign, [page, rowsPerPage]);

	const handleRemoveSellingCampaign = async result => {
		setRemoveModalOpen(false);
		const { id: sellingCampaignId } = removeSellingCampaign;

		if (result && sellingCampaignId) {
			const res = await axios.delete(`${config.get('apiUrl')}sellingCampaign/${sellingCampaignId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (res.data.success) {
				dispatch(addMessage({ type: 'success', message: 'Campanha de Vendas removida com sucesso' }));
				getSellingCampaign();
			}
		}
		setRemoveSellingCampaign({});
	};

	const getStatusLabel = sellingcampaign => {
		if (!moment(sellingcampaign.until).isAfter(moment(), 'day')) {
			return (
				<Chip
					size="small"
					label="Inativo" // vermelho
					style={{
						backgroundColor: '#DF5937',
						color: 'white',
						fontSize: 10,
						textTransform: 'uppercase',
						fontWeight: 700,
					}}
				/>
			);
		}
		switch (+sellingcampaign.status) {
			case 0:
				return (
					<Chip
						size="small"
						label="Inativo" // vermelho
						style={{
							backgroundColor: '#DF5937',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
			case 1:
				return (
					<Chip
						size="small"
						label="Ativo" // verde
						style={{
							backgroundColor: '#2A9D8F',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
			default:
				return (
					<Chip
						size="small"
						label="Não Mapeado" //preto
						style={{
							backgroundColor: '#000000',
							color: 'white',
							fontSize: 10,
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					/>
				);
		}
	};

	const getCountAmount = sellingCampaign => {
		let totalCount = 0;
		if (sellingCampaign.selling_campaign_pedidos) {
			for (let x = 0; x < sellingCampaign.selling_campaign_pedidos.length; x++) {
				if (
					sellingCampaign.selling_campaign_pedidos[x].pedido &&
					sellingCampaign.selling_campaign_pedidos[x].pedido.status_pedido_id === 2
				) {
					totalCount++;
				}
			}
		}
		return totalCount;
	};

	const getTotalAmount = sellingCampaign => {
		let totalAmount = 0;
		if (sellingCampaign.selling_campaign_pedidos) {
			for (let x = 0; x < sellingCampaign.selling_campaign_pedidos.length; x++) {
				if (
					sellingCampaign.selling_campaign_pedidos[x].pedido &&
					sellingCampaign.selling_campaign_pedidos[x].pedido.status_pedido_id === 2
				) {
					totalAmount = totalAmount + sellingCampaign.amount / 100;
				}
			}
		}
		return Formatter.Real(totalAmount);
	};

	return (
		<Grid container>
			<ZTitle
				title="Campanha de Vendas"
				button="add"
				display="flex"
				textAlign="center"
				fontSize="40px"
				handleOnClick={() => {
					handleAdd();
				}}
			/>
			<Grid item xs={12}>
				<Paper
					style={{
						position: 'relative',
						marginBottom: 10,
						borderRadius: 20,
						padding: '5px 10px',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<FaFilter />
					<ZFiltros
						onChange={filtrosChange}
						omni={{
							default: filtros.omni,
							placeholder: 'CPF/CNPJ',
						}}
						omni2={{
							default: filtros.omni2,
							placeholder: 'Estabelecimento',
						}}
						omni3={{
							default: filtros.omni3,
							placeholder: 'Nome da Campanha',
						}}
						statusSellingCampaigns={{
							defaultValue: filtros.statusSellingCampaigns,
							placeholder: 'Status',
							multiple: true,
						}}
					/>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				{loading ? (
					<ZLoader height="200px" size={40} />
				) : (
					<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 30px)' }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell style={{ minWidth: 250 }}>Nome da Campanha</TableCell>
									<TableCell align="left" style={{ minWidth: 250 }}>
										Estabelecimento
									</TableCell>
									<TableCell align="right" style={{ minWidth: 80 }}>
										Boleto
									</TableCell>
									<TableCell align="right" style={{ minWidth: 70 }}>
										Pix
									</TableCell>
									<TableCell align="right" style={{ minWidth: 80 }}>
										Cartão de Crédito
									</TableCell>
									<TableCell align="right" style={{ minWidth: 150 }}>
										Data de Término
									</TableCell>
									<TableCell align="right" style={{ minWidth: 80 }}>
										Vendas
									</TableCell>
									<TableCell align="right" style={{ minWidth: 150 }}>
										Valor Unitário
									</TableCell>
									<TableCell align="right" style={{ minWidth: 150 }}>
										Total Arrecadado
									</TableCell>
									<TableCell align="right" style={{ minWidth: 100 }}>
										Status
									</TableCell>
									<TableCell align="right" style={{ minWidth: 150 }}></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<>
									{sellingCampaign.map((sellingCampaign, key) => {
										return (
											<TableRow hover key={key}>
												<TableCell component="th" scope="row">
													{sellingCampaign.name}
												</TableCell>
												<TableCell align="left">
													{sellingCampaign.estabelecimento.nome_fantasia}
												</TableCell>
												<TableCell align="right">
													{sellingCampaign.bank_slip ? 'Sim' : 'Não'}
												</TableCell>
												<TableCell align="right">
													{sellingCampaign.pix ? 'Sim' : 'Não'}
												</TableCell>
												<TableCell align="right">
													{sellingCampaign.credit_card ? 'Sim' : 'Não'}
												</TableCell>
												<TableCell align="right">
													{sellingCampaign.until
														? moment(sellingCampaign.until).format('DD/MM/YYYY')
														: ''}
												</TableCell>
												<TableCell align="right">{getCountAmount(sellingCampaign)}</TableCell>
												<TableCell align="right">
													{Formatter.Real(sellingCampaign.amount / 100)}
												</TableCell>
												<TableCell align="right">{getTotalAmount(sellingCampaign)}</TableCell>
												<TableCell align="right">{getStatusLabel(sellingCampaign)}</TableCell>
												<TableCell align="right">
													<Tooltip title="Detalhes">
														<span>
															<FaEye
																className={classes.menuIcon}
																onClick={() => {
																	history.push(
																		'/sellingCampaign/details/' + sellingCampaign.id
																	);
																}}
															/>
														</span>
													</Tooltip>
													{moment() < moment(sellingCampaign.until) && (
														<>
															<Tooltip title="Visualizar tela de pagamento">
																<span>
																	<a
																		style={{ color: 'black' }}
																		href={`https://${env}.1pay.com.br/${sellingCampaign.token}`}
																		target="_blank"
																		rel="noopener noreferrer"
																	>
																		<FaDollarSign className={classes.menuIcon} />
																	</a>
																</span>
															</Tooltip>
															<Tooltip title="Compartilhar no Whatsapp">
																<span>
																	<FaWhatsapp
																		className={classes.menuIcon}
																		onClick={() => {
																			const text = encodeURIComponent(
																				`Campanha de Vendas - ${
																					sellingCampaign.estabelecimento
																						.nome_fantasia
																				}\n\n*${sellingCampaign.name.trim()}*\nhttps://${env}.1pay.com.br/${
																					sellingCampaign.token
																				}`
																			);
																			window.open(
																				`https://wa.me/?text=${text}`,
																				'_blank'
																			);
																		}}
																	/>
																</span>
															</Tooltip>
															<Tooltip title="Enviar por E-mail">
																<span>
																	<FaShare
																		className={classes.menuIcon}
																		onClick={() => {
																			const text = encodeURIComponent(
																				`Campanha de Vendas - ${
																					sellingCampaign.estabelecimento
																						.nome_fantasia
																				}\n\n*${sellingCampaign.name.trim()}*\nhttps://${env}.1pay.com.br/${
																					sellingCampaign.token
																				}`
																			);
																			window.open(
																				`mailto:?subject=${text}`,
																				'_blank'
																			);
																		}}
																	/>
																</span>
															</Tooltip>
														</>
													)}
													{!(sellingCampaign.selling_campaign_pedidos.length > 0) && (
														<Tooltip title="Excluir Campanha de Vendas">
															<span>
																<FaTrashAlt
																	className={classes.menuIcon}
																	onClick={() => {
																		setRemoveSellingCampaign(sellingCampaign);
																		setRemoveModalOpen(true);
																	}}
																/>
															</span>
														</Tooltip>
													)}
												</TableCell>
											</TableRow>
										);
									})}
								</>
							</TableBody>
							<ZPagination
								count={totalRows}
								rowsPerPage={rowsPerPage}
								page={page - 1}
								onChangePage={onChangePage}
								onChangeRowsPerPage={onChangeRowsPerPage}
							/>
						</Table>
					</Paper>
				)}
			</Grid>
			<ZModalConfirm
				title={`Remover Campanha de Vendas`}
				message={`Deseja remover essa Campanha (${removeSellingCampaign.name || ''})?`}
				open={removeModalOpen}
				onClose={handleRemoveSellingCampaign}
			/>
		</Grid>
	);
};

export default SellingCampaign;
