/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import config from 'react-global-configuration';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Grid, Paper, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ZLoader, ZMenuOptions, ZModalConfirm } from '../system';
import { useStyles } from './styles/PlanosTaxasStyle';

const PlanoTaxa = memo(props => {
	const { estabelecimentoId, plano, setRemoverPt } = props;
	const classes = useStyles();
	const history = useHistory();

	return (
		<Grid item container alignItems="center" className={classes.ptRow}>
			<Grid item xs>
				{plano.titulo}
			</Grid>
			<Grid item xs={1}>
				<ZMenuOptions
					data={[
						{
							title: 'Editar',
							onClick: () => {
								history.push(`/estabelecimentos/${estabelecimentoId}/planos-taxas/${plano.id}`);
							},
						},
						{
							title: 'Remover',
							onClick: () => {
								setRemoverPt({ ptId: plano.id, open: true });
							},
						},
					]}
				/>
			</Grid>
		</Grid>
	);
});

export default memo(props => {
	const { estabelecimentoId } = props;

	const classes = useStyles();

	const token = useSelector(store => store.auth.token);
	const history = useHistory();

	const [loading, setLoading] = useState(true);
	const [planosTaxas, setPlanosTaxas] = useState([]);
	const [removerPt, setRemoverPt] = useState({
		ptId: null,
		open: false,
	});

	const getPlanosTaxas = async () => {
		setLoading(true);
		const res = await axios.get(`${config.get('apiUrl')}planos_taxas/porMarketplaceFilho/${estabelecimentoId}`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			setPlanosTaxas(res.data.planosTaxas);
		}
		setLoading(false);
	};

	useEffect(() => {
		getPlanosTaxas();
	}, []);

	const handleRemoverPt = async result => {
		const ptId = removerPt.ptId;

		if (result) {
			const res = await axios.delete(
				`${config.get('apiUrl')}planos_taxas/porMarketplaceFilho/${estabelecimentoId}/${ptId}`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				getPlanosTaxas();
			}
			setRemoverPt({ open: false, ptId: null });
		}
	};

	return (
		<div className={classes.planosTaxas}>
			<Paper className={classes.planosTaxasPaper}>
				<Fab
					className={classes.btnAdd}
					color="primary"
					onClick={() => history.push(`/estabelecimentos/${estabelecimentoId}/planos-taxas/add`)}
					size="medium"
					aria-label="Add"
				>
					<AddIcon />
				</Fab>
				<Grid container spacing={2} justifyContent="center">
					{loading ? (
						<ZLoader height={200} />
					) : (
						<>
							<Grid item container className={classes.header}>
								<Grid item xs className={classes.headerTitle}>
									Título da Tabela de Markup
								</Grid>
								<Grid item xs={1} className={classes.headerTitle}></Grid>
							</Grid>
							{planosTaxas.map(planoTaxa => (
								<PlanoTaxa
									key={planoTaxa.id}
									setRemoverPt={setRemoverPt}
									estabelecimentoId={estabelecimentoId}
									plano={planoTaxa}
								/>
							))}
							<ZModalConfirm
								title={`Remover Tabela Markup`}
								message={`Deseja remover essa tabela de markup?`}
								open={removerPt.open}
								onClose={handleRemoverPt}
							/>
						</>
					)}
				</Grid>
			</Paper>
		</div>
	);
});
