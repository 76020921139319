import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	dialog: {},
	title: {
		textAlign: 'center',
		fontSize: 16,
		fontWeight: 'bold',
	},
	header: {
		flexGrow: 1,
	},
	export: {
		opacity: 0.5,
		marginLeft: 8,
		marginTop: 15,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 25,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 28,
			opacity: 1,
		},
	},
	table: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
	},
	downloadButton: {
		background: `${theme.palette.primary.main}67`,
		margin: 2,
		padding: 2,
	},
	newExport: {
		margin: 'auto',
		marginTop: 10,
		textAlign: 'center',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		fontSize: '18px',
	},
	hovers: {
		color: 'blue',
		'&:hover td': {
			backgroundColor: '#F3F4F6',
			color: 'blue',
		},
	},
	controlSwitchDetalhado: {
		minWidth: 90,
		margin: 5,
		fontSize: '16px',
	},

	row: {},
}));
