import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	paper: {
		backgroundColor: '#f1f1f1',
		padding: 10,
	},
	sectionHeader: {
		textAlign: 'center',
		fontVariant: 'small-caps',
		fontSize: 16,
		fontWeight: 'bold',
	},
	sectionHeader2: {
		textAlign: 'center',
		fontVariant: 'small-caps',
		fontSize: 14,
		fontWeight: 'bold',
	},
	field: {
		padding: 5,
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	label: {
		fontSize: 10,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	previewDocumento: {
		width: 'calc(100% - 20px)',
		height: 200,
		fontVariant: 'small-caps',
		fontWeight: 'bold',
		cursor: 'pointer',
		borderRadius: 10,
		border: 'solid 1px #efefef',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		'&:hover': {
			border: 'solid 1px #abc',
		},
	},
	options: {
		display: 'flex',
		justifyContent: 'center',
	},
	optionBtn: {
		margin: 4,
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	editing: {
		backgroundColor: '#fff',
	},
}));
