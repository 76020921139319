import React from 'react';
import ZCards from './ZCards';

const ZPlanos = props => {
	return (
		<>
			{props.obj &&
				props.obj.map((item, index) => {
					return <ZCards item={item} key={index} />;
				})}
		</>
	);
};

export default ZPlanos;
