/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { pt } from 'react-date-range/src/locale/index';
import { format } from 'date-fns';
import { addDays } from 'date-fns';
import { addMonths } from 'date-fns';

import { Isset, Mask } from '../../utils';

import { makeStyles } from '@material-ui/core/styles';

import { DateRange } from 'react-date-range';
import { FaTimes } from 'react-icons/fa';
import { Paper, Grid, Select, MenuItem, Chip, Tooltip, TextField } from '@material-ui/core';
import ZDateRangePicker from './ZDateRangePicker';

import FiltroEstabelecimento from './ZFiltros/Estabelecimento';
import FiltroBandeira from './ZFiltros/Bandeira';
import FiltroSwitch from './ZFiltros/Switch';
import FiltroStatusInvoice from './ZFiltros/Status';
import FiltroStatusSellingCampaign from './ZFiltros/SellingCampaign';
import FiltroFormaPagamento from './ZFiltros/FormaPagamento';
import FiltroStatusTransferencia from './ZFiltros/StatusTransferencia';
import FiltroTipoTransferencia from './ZFiltros/TipoTransferencia';
import FiltroStatusPreCadastro from './ZFiltros/StatusPreCadastro';
import FiltroMes from './ZFiltros/Mes';
import FiltroAno from './ZFiltros/Ano';

import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setFilters } from '../../store/filters';

const useStyles = makeStyles(theme => ({
	filtros: {
		position: 'relative',
		display: 'flex',
		flexWrap: 'wrap',
	},
	fChild: {
		flexGrow: 1,
	},
	filtroText: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		padding: '5px 10px',
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 30,
		marginLeft: 10,
		fontWeight: 700,
		fontSize: 12,
		color: theme.palette.primary.main,
	},
	filtroTextSelected: {
		backgroundColor: `${theme.palette.primary.main}`,
		color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		'& .MuiSelect-select': {
			color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		},
	},
	filtroTextSelectedMultiple: {
		backgroundColor: `${theme.palette.primary.main}`,
		color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		'& .MuiSelect-select': {
			color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		},
		padding: '2px 10px',
		minHeight: 29,
		maxWidth: 340,
	},
	filterSelected: {},
	filterBox: {
		position: 'absolute',
		top: 30,
		left: 0,
		padding: 5,
		zIndex: 1210,
	},
	clearBtn: {
		marginLeft: 6,
	},
	backdrop: {
		position: 'fixed',
		zIndex: 1200,
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: 'rgba(0,0,0,0.3)',
	},
	staticOptions: {
		textAlign: 'center',
	},
	staticOption: {
		textAlign: 'center',
		cursor: 'pointer',
	},
	optionSelected: {
		fontWeight: 700,
	},
	filtroSelect: {
		width: '95%',
		fontWeight: 700,
		fontSize: 12,
		'&:before': {
			borderBottom: 'none',
		},
		'& .MuiSelect-select': {
			padding: '2px 10px !important',
			marginRight: 10,
		},
	},
	chips: {
		display: 'flex',
		flexWrap: 'nowrap',
	},
	chip: {
		// margin: 2,
		height: 20,
		maxWidth: 130,
		justifyContent: 'flex-start',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'& .MuiChip-label': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: 'inline-block',
		},
	},
	hasMore: {
		width: 44,
	},
	menuItem: {
		flexDirection: 'column',
	},
	omniField: {
		'& .MuiInput-input': {
			fontSize: 14,
			padding: 0,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -6,
			transform: 'scaleX(1)',
		},
		'& .MuiInput-underline:not(.Mui-focused):after': {
			borderBottom: 'solid 1px #abc',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
			bottom: -6,
		},
	},
	filtroOmniHasText: {
		backgroundColor: `${theme.palette.primary.main}`,
		color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		'& .MuiInput-input': {
			color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		},
	},
}));

const selectedOptions = {
	today: 'Hoje',
	lastMonth: 'Último Mês',
	last7days: 'Últimos 7 Dias',
	next6months: 'Próximos 6 Meses',
	nextMonth: 'Próximo Mês',
};

const statusVendaData = [
	{ id: 1, titulo: 'Pendente' },
	{ id: 2, titulo: 'Aprovado' },
	{ id: 3, titulo: 'Falhado' },
	{ id: 4, titulo: 'Cancelado' },
	// { id: 5, titulo: 'Parcialmente Pago' },
	{ id: 6, titulo: 'Estornado' },
];

const ZFiltros = props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const [filtros, setFiltros] = useState({
		date: {
			start: null,
			end: null,
		},
		estabelecimento: null,
		omniEstabelecimento: '',
		bandeira: null,
		statusInvoice: null,
		statusSellingCampaign: null,
		formaPagamento: null,
		statusTransferencia: null,
		tipoTransferencia: null,
		statusPreCadastro: null,
		tipoCobranca: null,
		mes: null,
		ano: null,
		chek: null,
	});

	// DatePicker
	const [openDate, setOpenDate] = useState(false);
	const [selectedDate, setSelectedDate] = useState(
		Isset(() => props.dateRange.default) ? props.dateRange.default : 'last7days'
	);
	const [startDate, setStartDate] = useState(
		Isset(() => props.dateRange.startDate) ? props.dateRange.startDate : new Date()
	);
	const [endDate, setEndDate] = useState(Isset(() => props.dateRange.endDate) ? props.dateRange.endDate : new Date());
	const [dataCreated, setDataCreated] = useState({
		start: Isset(() => props.created.start) ? props.created.start : new Date(),
		end: Isset(() => props.created.end) ? props.created.end : new Date(),
		label: Isset(() => props.created.label) ? props.created.label : 'Clique para selecionar a data',
	});

	const [dataProximaCobranca, setDataProximaCobranca] = useState({
		start: Isset(() => props.proximaCobranca.start) ? props.proximaCobranca.start : new Date(),
		end: Isset(() => props.proximaCobranca.end) ? props.proximaCobranca.end : new Date(),
		label: Isset(() => props.proximaCobranca.label) ? props.proximaCobranca.label : 'Clique para selecionar a data',
	});
	const [minDate] = useState(Isset(() => props.dateRange.minDate) ? props.dateRange.minDate : undefined);
	const [maxDate] = useState(Isset(() => props.dateRange.maxDate) ? props.dateRange.maxDate : undefined);
	const selectionRange = {
		startDate,
		endDate,
		key: 'selection',
	};

	// Tipo de Pagamento
	const [tipoPagamento, setTipoPagamento] = useState(
		Isset(() => props.tipoPagamento.default) ? props.tipoPagamento.default : ''
	);

	// Omni
	const [omni, setOmni] = useState(Isset(() => props.omni.default) ? props.omni.default : '');
	const [omniTypingTimeout, setOmniTypingTimeout] = useState(null);

	//EstabelecimentoText
	const [omniEstabelecimento, setOmniEstabelecimento] = useState(
		Isset(() => props.omniEstabelecimento.default) ? props.omniEstabelecimento.default : ''
	);

	// Omni 2
	const [omni2, setOmni2] = useState(Isset(() => props.omni2.default) ? props.omni2.default : '');
	const [omni2TypingTimeout, setOmni2TypingTimeout] = useState(null);

	// Omni 3
	const [omni3, setOmni3] = useState(Isset(() => props.omni3.default) ? props.omni3.default : '');
	const [omni3TypingTimeout, setOmni3TypingTimeout] = useState(null);

	// Pedido Venda
	const [pedido_venda, setPedidoVenda] = useState(
		Isset(() => props.pedido_venda.default) ? props.pedido_venda.default : ''
	);
	const [pedidoVendaTypingTimeout, setPedidoVendaTypingTimeout] = useState(null);

	// Valor
	const [valor, setValor] = useState(Isset(() => props.valor.default) ? props.valor.default : 0);

	// searchEstabelecimentoByCpfOrCNPJ
	const [searchEstabelecimentoByCpfOrCNPJ, setSearchEstabelecimentoByCpfOrCNPJ] = useState(
		Isset(() => props.searchEstabelecimentoByCpfOrCNPJ.value) ? props.searchEstabelecimentoByCpfOrCNPJ.value : ''
	);

	// nomeComprovante
	const [nomeComprovante, setNomeComprovante] = useState(
		Isset(() => props.nomeComprovante.value) ? props.nomeComprovante.value : ''
	);

	// Tipo de Transacao
	const [tipoTransacao, setTipoTransacao] = useState(
		Isset(() => props.tipoTransacao.default) ? props.tipoTransacao.default : ''
	);

	// Tipo da Cobrança
	const [tipoCobranca, setTipoCobranca] = useState(
		Isset(() => props.tipoCobranca.defaultValue) ? props.tipoCobranca.defaultValue : ''
	);

	// Status Venda
	const [statusVenda, setStatusVenda] = useState(
		Isset(() => props.statusVenda.default) ? props.statusVenda.default : ''
	);

	useEffect(() => {
		// Seta filtros iniciais que estao separados em outro componente
		setFiltros({
			...filtros,
			estabelecimento: Isset(() => props.estabelecimento.defaultValue)
				? props.estabelecimento.defaultValue
				: null,
			omniEstabelecimento: Isset(() => props.omniEstabelecimento.default)
				? props.omniEstabelecimento.default
				: '',
			bandeira: Isset(() => props.bandeira.defaultValue) ? props.bandeira.defaultValue : null,
			switch: Isset(() => props.switch.defaultValue) ? props.switch.defaultValue : null,
			formaPagamento: Isset(() => props.formaPagamento.defaultValue) ? props.formaPagamento.defaultValue : null,
			statusTransferencia: Isset(() => props.statusTransferencia.defaultValue)
				? props.statusTransferencia.defaultValue
				: null,
			statusInvoice: Isset(() => props.statusInvoice.defaultValue) ? props.statusInvoice.defaultValue : null,
			statusSellingCampaign: Isset(() => props.statusSellingCampaign.defaultValue)
				? props.statusSellingCampaign.defaultValue
				: null,
			tipoTransferencia: Isset(() => props.tipoTransferencia.defaultValue)
				? props.tipoTransferencia.defaultValue
				: null,
			statusPreCadastro: Isset(() => props.statusPreCadastro.defaultValue)
				? props.statusPreCadastro.defaultValue
				: null,
			mes: Isset(() => props.mes.defaultValue) ? props.mes.defaultValue : null,
			ano: Isset(() => props.ano.defaultValue) ? props.ano.defaultValue : null,
		});
	}, []);

	const getFiltrosData = data => {
		const result = {};

		if (props.dateRange) {
			result.date = {
				start: startDate,
				end: endDate,
			};
		}

		if (props.tipoPagamento) {
			result.tipoPagamento = tipoPagamento;
		}
		if (props.mes) {
			result.mes = filtros.mes;
		}
		if (props.ano) {
			result.ano = filtros.ano;
		}
		if (props.estabelecimento) {
			result.estabelecimento = filtros.estabelecimento;
		}
		if (props.bandeira) {
			result.bandeira = filtros.bandeira;
		}
		if (props.switch) {
			result.switch = filtros.switch;
		}
		if (props.formaPagamento) {
			result.formaPagamento = filtros.formaPagamento;
		}
		if (props.statusInvoice) {
			result.statusInvoice = filtros.statusInvoice;
		}
		if (props.statusSellingCampaign) {
			result.statusSellingCampaign = filtros.statusSellingCampaign;
		}
		if (props.statusTransferencia) {
			result.statusTransferencia = filtros.statusTransferencia;
		}
		if (props.tipoTransferencia) {
			result.tipoTransferencia = filtros.tipoTransferencia;
		}
		if (props.tipoCobranca) {
			result.tipoCobranca = filtros.tipoCobranca;
		}
		if (props.statusPreCadastro) {
			result.statusPreCadastro = filtros.statusPreCadastro;
		}
		if (props.tipoTransacao) {
			result.tipoTransacao = tipoTransacao;
		}
		if (props.statusVenda) {
			result.statusVenda = statusVenda;
		}
		if (props.omni) {
			result.omni = omni;
		}
		if (props.omni2) {
			result.omni2 = omni2;
		}
		if (props.omni3) {
			result.omni3 = omni3;
		}
		if (props.pedido_venda) {
			result.pedido_venda = pedido_venda;
		}
		if (props.valor) {
			result.valor = valor;
		}
		if (props.searchEstabelecimentoByCpfOrCNPJ) {
			result.searchEstabelecimentoByCpfOrCNPJ = searchEstabelecimentoByCpfOrCNPJ;
		}
		if (props.nomeComprovante) {
			result.nomeComprovante = nomeComprovante;
		}

		if (props.created) {
			result.created = {
				...dataCreated,
			};
		}

		const r = { ...result, ...data };

		dispatch(setFilters(pathnameSlug, r));

		return r;
	};

	const setFiltroData = (index, value) => {
		const newFiltro = { [index]: value };

		setFiltros({
			...filtros,
			...newFiltro,
		});

		if (props.onChange) {
			props.onChange(getFiltrosData(newFiltro));
		}
	};

	const handleCreatedSelected = ranges => {
		setDataCreated({
			...dataCreated,
			start: ranges.startDate,
			end: ranges.endDate,
		});

		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					created: {
						...dataCreated,
						start: ranges.startDate,
						end: ranges.endDate,
					},
				})
			);
		}
	};

	const handleProximaCobranca = ranges => {
		setDataProximaCobranca({
			...dataProximaCobranca,
			start: ranges.startDate,
			end: ranges.endDate,
		});

		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					proximaCobranca: {
						...dataProximaCobranca,
						start: ranges.startDate,
						end: ranges.endDate,
					},
				})
			);
		}
	};

	const handleDateSelect = ranges => {
		setStartDate(ranges.selection.startDate);
		setEndDate(ranges.selection.endDate);
		setSelectedDate('custom');

		// if (props.onChange) {
		//     props.onChange(getFiltrosData({
		//         date: {
		//             start: ranges.selection.startDate,
		//             end: ranges.selection.endDate,
		//         }
		//     }));
		// }
	};

	const handleDateBackdropClick = () => {
		setOpenDate(false);

		if (props.onChange) {
			props.onChange(getFiltrosData());
		}
	};

	const handleDateClear = e => {
		e.stopPropagation();
		const today = new Date();
		setSelectedDate('today');
		setStartDate(today);
		setEndDate(today);

		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					date: {
						start: today,
						end: today,
					},
				})
			);
		}
	};

	const staticDateClick = option => {
		switch (option) {
			case 'today':
				setStartDate(new Date());
				setEndDate(new Date());
				break;
			case 'lastMonth':
				setStartDate(addMonths(new Date(), -1));
				setEndDate(new Date());
				break;
			case 'last7days':
				setStartDate(addDays(new Date(), -7));
				setEndDate(new Date());
				break;
			case 'next6months':
				setStartDate(new Date());
				setEndDate(addMonths(new Date(), 6));
				break;
			case 'nextMonth':
				setStartDate(new Date());
				setEndDate(addMonths(new Date(), 1));
				break;
			default:
		}
	};

	useEffect(() => {
		const checkSelectedDate = () => {
			if (selectedDate === 'custom' && startDate && endDate) {
				const today = new Date();
				const possibilities = [
					{ start: today, end: new Date(), name: 'today' },
					{ start: addMonths(today, -1), end: today, name: 'lastMonth' },
					{ start: addDays(today, -7), end: today, name: 'last7days' },
					{ start: today, end: addMonths(today, 1), name: 'nextMonth' },
					{ start: today, end: addMonths(today, 6), name: 'next6months' },
				];
				const exists = possibilities.find(v => {
					return (
						format(v.start, 'YYYYMMDD') === format(startDate, 'YYYYMMDD') &&
						format(v.end, 'YYYYMMDD') === format(endDate, 'YYYYMMDD')
					);
				});
				if (exists) {
					setSelectedDate(exists.name);
				}
			}
		};

		checkSelectedDate();
	}, []);

	useEffect(() => {
		staticDateClick(selectedDate);
	}, [selectedDate]);

	useEffect(() => {
		setDataCreated({
			...props.created,
		});
	}, [props.created]);

	useEffect(() => {
		setDataProximaCobranca({
			...props.proximaCobranca,
		});
	}, [props.proximaCobranca]);

	const tipoPagamentoChange = e => {
		const value = e.target.value;
		setTipoPagamento(value);

		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					tipoPagamento: value,
				})
			);
		}
	};

	const omniChange = e => {
		const value = e.target.value;
		setOmni(value);

		if (omniTypingTimeout) {
			clearTimeout(omniTypingTimeout);
		}

		const timeout = setTimeout(() => {
			if (props.onChange && !props.omni.onBlur) {
				props.onChange(
					getFiltrosData({
						omni: value,
					})
				);
			}
		}, 500);

		setOmniTypingTimeout(timeout);
	};

	const omniEstabelecimentoChange = e => {
		const value = e.target.value;
		setOmniEstabelecimento(value);

		// const timeout = setTimeout(() => {
		// }, 500);

		// setestabelecimentoTypingTimeout(timeout);
	};

	const omni2Change = e => {
		const value = e.target.value;
		setOmni2(value);

		if (omni2TypingTimeout) {
			clearTimeout(omni2TypingTimeout);
		}

		const timeout = setTimeout(() => {
			if (props.onChange && !props.omni2.onBlur) {
				props.onChange(
					getFiltrosData({
						omni2: value,
					})
				);
			}
		}, 500);

		setOmni2TypingTimeout(timeout);
	};

	const omni3Change = e => {
		const value = e.target.value;
		setOmni3(value);

		if (omni3TypingTimeout) {
			clearTimeout(omni3TypingTimeout);
		}

		const timeout = setTimeout(() => {
			if (props.onChange && !props.omni3.onBlur) {
				props.onChange(
					getFiltrosData({
						omni3: value,
					})
				);
			}
		}, 500);

		setOmni3TypingTimeout(timeout);
	};

	const pedidoVendaChange = e => {
		const value = e.target.value;
		setPedidoVenda(value);

		if (pedidoVendaTypingTimeout) {
			clearTimeout(pedidoVendaTypingTimeout);
		}

		const timeout = setTimeout(() => {
			if (props.onChange && !props.pedido_venda.onBlur) {
				props.onChange(
					getFiltrosData({
						pedido_venda: value,
					})
				);
			}
		}, 500);

		setPedidoVendaTypingTimeout(timeout);
	};

	const tipoPagamentoClear = e => {
		e.stopPropagation();
		setTipoPagamento('');
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					tipoPagamento: '',
				})
			);
		}
	};

	const handleOnChangeStatus = (id, e) => {
		if (props.onSelectChange) {
			props.onSelectChange(id, e);
		}
	};

	// const statusChange = e => {
	//     const value = e.target.value;

	//     if (props.onChange) {
	//         props.onChange(getFiltrosData({
	//             estabelecimento: value
	//         }));
	//     }
	// }

	const handleValorChange = e => {
		const value = Mask.OnlyNumber(e.target.value) / 100;

		setValor(value);

		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					valor: value,
				})
			);
		}
	};

	const tipoTransacaoChange = e => {
		const value = e.target.value;
		setTipoTransacao(value);

		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					tipoTransacao: value,
				})
			);
		}
	};

	const tipoTransacaoClear = e => {
		e.stopPropagation();
		setTipoTransacao('');
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					tipoTransacao: '',
				})
			);
		}
	};

	const tipoCobrancaChange = e => {
		const { value } = e.target;

		setTipoCobranca(value);

		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					tipoCobranca: value,
				})
			);
		}
	};

	const tipoCobrancaClear = e => {
		e.stopPropagation();
		setTipoCobranca('');
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					tipoTransacao: '',
				})
			);
		}
	};

	const statusVendaChange = e => {
		const value = e.target.value;
		setStatusVenda(value);
	};

	const statusVendaClose = e => {
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					statusVenda,
				})
			);
		}
	};

	const statusVendaClear = e => {
		const isMultiple = Isset(() => props.statusVenda.multiple) ? props.statusVenda.multiple : false;
		e.stopPropagation();
		const value = isMultiple ? [] : '';
		setStatusVenda(value);
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					statusVenda: value,
				})
			);
		}
	};

	const dataCreatedClear = e => {
		// e.stopPropagation();
		// setDataCreated({
		//     label: dataCreated.label,
		//     start: '',
		//     end: '',
		// });

		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					created: {
						...dataCreated,
						start: '',
						end: '',
					},
				})
			);
		}
	};

	const clearDataProximoCobranca = e => {
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					proximaCobranca: {
						...dataProximaCobranca,
						start: '',
						end: '',
					},
				})
			);
		}
	};

	const omniClear = e => {
		e.stopPropagation();
		setOmni('');
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					omni: '',
				})
			);
		}
	};

	const valorClear = e => {
		e.stopPropagation();
		setValor(0);

		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					valor: 0,
				})
			);
		}
	};

	const omniEstabelecimentoClear = e => {
		e.stopPropagation();
		setOmniEstabelecimento('');
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					omniEstabelecimento: '',
				})
			);
		}
	};

	const omni2Clear = e => {
		e.stopPropagation();
		setOmni2('');
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					omni2: '',
				})
			);
		}
	};

	const omni3Clear = e => {
		e.stopPropagation();
		setOmni3('');
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					omni3: '',
				})
			);
		}
	};

	const pedidoVendaClear = e => {
		e.stopPropagation();
		setPedidoVenda('');
		if (props.onChange) {
			props.onChange(
				getFiltrosData({
					pedido_venda: '',
				})
			);
		}
	};

	return (
		<div className={classes.filtros}>
			{props.omni && (
				<div className={clsx(classes.fChild, classes.omni)}>
					<div className={clsx(classes.filtroText, { [classes.filtroOmniHasText]: omni })}>
						<TextField
							// id="standard-search"
							// label={props.omni.label || 'Busca'}
							placeholder={props.omni.placeholder || 'Digite um termo de busca'}
							type="text"
							className={classes.omniField}
							value={omni}
							onChange={omniChange}
							margin="normal"
							style={{ margin: 0, height: 21, padding: 0 }}
						/>
						{omni && <FaTimes className={classes.clearBtn} onClick={omniClear} />}
					</div>
				</div>
			)}

			{props.omniEstabelecimento && (
				<div className={clsx(classes.fChild, classes.estabelecimento)}>
					<div className={clsx(classes.filtroText, { [classes.filtroOmniHasText]: omniEstabelecimento })}>
						<TextField
							// id="standard-search"
							// label={props.estabelecimento.label || 'Busca'}
							placeholder={props.omniEstabelecimento.placeholder || 'Nome Fantasia'}
							type="text"
							className={classes.omniField}
							value={omniEstabelecimento}
							onChange={omniEstabelecimentoChange}
							onBlur={e =>
								props.onChange(
									getFiltrosData({
										omniEstabelecimento: e.target.value,
									})
								)
							}
							margin="normal"
							style={{ margin: 0, height: 21, padding: 0 }}
						/>
						{omniEstabelecimento && (
							<FaTimes className={classes.clearBtn} onClick={omniEstabelecimentoClear} />
						)}
					</div>
				</div>
			)}

			{props.omni2 && (
				<div className={clsx(classes.fChild, classes.omni)}>
					<div className={clsx(classes.filtroText, { [classes.filtroOmniHasText]: omni2 })}>
						<TextField
							// id="standard-search"
							// label={props.omni2.label || 'Busca'}
							placeholder={props.omni2.placeholder || 'Digite um termo de busca'}
							type="text"
							className={classes.omniField}
							value={omni2}
							onChange={omni2Change}
							margin="normal"
							style={{ margin: 0, height: 21, padding: 0 }}
						/>
						{omni2 && <FaTimes className={classes.clearBtn} onClick={omni2Clear} />}
					</div>
				</div>
			)}

			{props.omni3 && (
				<div className={clsx(classes.fChild, classes.omni)}>
					<div className={clsx(classes.filtroText, { [classes.filtroOmniHasText]: omni3 })}>
						<TextField
							// id="standard-search"
							// label={props.omni3.label || 'Busca'}
							placeholder={props.omni3.placeholder || 'Digite um termo de busca'}
							type="text"
							className={classes.omniField}
							value={omni3}
							onChange={omni3Change}
							margin="normal"
							style={{ margin: 0, height: 21, padding: 0 }}
						/>
						{omni3 && <FaTimes className={classes.clearBtn} onClick={omni3Clear} />}
					</div>
				</div>
			)}

			{props.valor && (
				<div className={clsx(classes.fChild, classes.omni)}>
					<div className={clsx(classes.filtroText, { [classes.filtroOmniHasText]: valor })}>
						<TextField
							placeholder={props.valor.placeholder || 'Digite um valor de busca'}
							type="text"
							className={classes.omniField}
							value={Mask.Real(valor)}
							onChange={handleValorChange}
							margin="normal"
							style={{ margin: 0, height: 21, padding: 0 }}
						/>
						{valor !== 0 && <FaTimes className={classes.clearBtn} onClick={valorClear} />}
					</div>
				</div>
			)}

			{props.pedido_venda && (
				<div className={clsx(classes.fChild, classes.omni)}>
					<div className={clsx(classes.filtroText, { [classes.filtroOmniHasText]: pedido_venda })}>
						<TextField
							// id="standard-search"
							// label={props.pedido_venda.label || 'Busca'}
							placeholder={props.pedido_venda.placeholder || 'Digite um termo de busca'}
							type="text"
							className={classes.omniField}
							value={pedido_venda}
							onChange={pedidoVendaChange}
							margin="normal"
							style={{ margin: 0, height: 21, padding: 0 }}
						/>
						{pedido_venda && <FaTimes className={classes.clearBtn} onClick={pedidoVendaClear} />}
					</div>
				</div>
			)}

			{props.statusInvoice && (
				<FiltroStatusInvoice
					{...props.statusInvoice}
					onChange={value => setFiltroData('statusInvoice', value)}
				/>
			)}

			{props.mes && <FiltroMes {...props.mes} onChange={value => setFiltroData('mes', value)} />}

			{props.ano && <FiltroAno {...props.ano} onChange={value => setFiltroData('ano', value)} />}

			{props.dateRange && (
				<div className={clsx(classes.fChild, classes.datePicker)} style={{ minWidth: 150 }}>
					<div
						className={clsx({
							[classes.filtroText]: true,
							[classes.filtroTextSelected]: selectedDate !== null,
						})}
						onClick={() => setOpenDate(!openDate)}
					>
						{selectedDate !== null ? (
							<React.Fragment>
								<span className={classes.filterSelected}>
									{Isset(() => selectedOptions[selectedDate])
										? selectedOptions[selectedDate]
										: `${format(startDate, 'DD/MM/YYYY')} - ${format(endDate, 'DD/MM/YYYY')}`}
								</span>
								<FaTimes className={classes.clearBtn} onClick={handleDateClear} />
							</React.Fragment>
						) : (
							<span>Nenhuma Data Selecionada</span>
						)}
					</div>

					{openDate && (
						<div className={clsx(classes.fChild, classes.backdrop)} onClick={handleDateBackdropClick}></div>
					)}

					{openDate && (
						<Paper className={clsx(classes.fChild, classes.filterBox)}>
							<DateRange
								locale={pt}
								ranges={[selectionRange]}
								onChange={handleDateSelect}
								dateDisplayFormat={'DD/MM/YYYY'}
								minDate={minDate}
								maxDate={maxDate}
								showSelectionPreview={false}
								moveRangeOnFirstSelection={false}
							/>
							<Grid container className={classes.staticOptions}>
								{minDate === undefined && (
									<Grid xs item>
										<div
											onClick={() => setSelectedDate('lastMonth')}
											className={clsx({
												[classes.staticOption]: true,
												[classes.optionSelected]: selectedDate === 'lastMonth',
											})}
										>
											{selectedOptions['lastMonth']}
										</div>
										<div
											onClick={() => setSelectedDate('last7days')}
											className={clsx({
												[classes.staticOption]: true,
												[classes.optionSelected]: selectedDate === 'last7days',
											})}
										>
											{selectedOptions['last7days']}
										</div>
									</Grid>
								)}
								{maxDate === undefined && (
									<Grid xs item>
										<div
											onClick={() => setSelectedDate('next6months')}
											className={clsx({
												[classes.staticOption]: true,
												[classes.optionSelected]: selectedDate === 'next6months',
											})}
										>
											{selectedOptions['next6months']}
										</div>
										<div
											onClick={() => setSelectedDate('nextMonth')}
											className={clsx({
												[classes.staticOption]: true,
												[classes.optionSelected]: selectedDate === 'nextMonth',
											})}
										>
											{selectedOptions['nextMonth']}
										</div>
									</Grid>
								)}
							</Grid>
						</Paper>
					)}
				</div>
			)}

			{props.created && (
				<div className={classes.fChild}>
					<ZDateRangePicker
						onClose={handleCreatedSelected}
						startDate={dataCreated.start}
						endDate={dataCreated.end}
						label={dataCreated.label}
						clearDate={dataCreatedClear}
					/>
				</div>
			)}

			{props.proximaCobranca && (
				<div className={classes.fChild}>
					<ZDateRangePicker
						onClose={handleProximaCobranca}
						startDate={dataProximaCobranca.start}
						endDate={dataProximaCobranca.end}
						label={dataProximaCobranca.label}
						clearDate={clearDataProximoCobranca}
					/>
				</div>
			)}

			{props.tipoTransacao && (
				<div className={clsx(classes.fChild, classes.tipoTransacao)}>
					<div className={clsx({ [classes.filtroText]: true, [classes.filtroTextSelected]: tipoTransacao })}>
						<Select
							className={classes.filtroSelect}
							value={tipoTransacao}
							onChange={tipoTransacaoChange}
							displayEmpty
						>
							<MenuItem value="">
								<em>Tipo de Transação</em>
							</MenuItem>
							<MenuItem value="venda">Venda</MenuItem>
							<MenuItem value="transferencia">Transferência</MenuItem>
						</Select>
						{tipoTransacao && <FaTimes className={classes.clearBtn} onClick={tipoTransacaoClear} />}
					</div>
				</div>
			)}

			{props.tipoCobranca && (
				<div className={clsx(classes.fChild, classes.tipoCobranca)}>
					<div className={clsx({ [classes.filtroText]: true, [classes.filtroTextSelected]: tipoCobranca })}>
						<Select
							className={classes.filtroSelect}
							value={tipoCobranca}
							onChange={tipoCobrancaChange}
							displayEmpty
						>
							<MenuItem disabled value={tipoCobranca}>
								<em>Tipo da Cobrança</em>
							</MenuItem>
							{props.tipoCobranca.tipo.map((tipoCobranca, i) => (
								<MenuItem key={i} value={tipoCobranca.id} className={classes.menuItem}>
									{tipoCobranca.name}
								</MenuItem>
							))}
						</Select>

						{tipoCobranca && <FaTimes className={classes.clearBtn} onClick={tipoCobrancaClear} />}
					</div>
				</div>
			)}

			{props.tipoPagamento && (
				<div className={clsx(classes.fChild, classes.tipoPagamento)}>
					<div className={clsx({ [classes.filtroText]: true, [classes.filtroTextSelected]: tipoPagamento })}>
						<Select
							multiple={true}
							className={classes.filtroSelect}
							value={tipoPagamento}
							onChange={tipoPagamentoChange}
							displayEmpty
						>
							<MenuItem value="">
								<em>Tipo de Pagamento</em>
							</MenuItem>
							<MenuItem value="credit_card">Cartão de Crédito</MenuItem>
							<MenuItem value="billet">Boleto Bancário</MenuItem>
						</Select>
						{tipoPagamento && <FaTimes className={classes.clearBtn} onClick={tipoPagamentoClear} />}
					</div>
				</div>
			)}

			{props.estabelecimento && (
				<FiltroEstabelecimento
					{...props.estabelecimento}
					onChange={value => setFiltroData('estabelecimento', value)}
				/>
			)}

			{props.bandeira && (
				<FiltroBandeira {...props.bandeira} onChange={value => setFiltroData('bandeira', value)} />
			)}

			{props.switch && <FiltroSwitch {...props.switch} onChange={value => setFiltroData('switch', value)} />}

			{props.statusSellingCampaign && (
				<FiltroStatusSellingCampaign
					{...props.statusSellingCampaign}
					onChange={value => setFiltroData('statusSellingCampaign', value)}
				/>
			)}

			{props.formaPagamento && (
				<FiltroFormaPagamento
					{...props.formaPagamento}
					onChange={value => setFiltroData('formaPagamento', value)}
				/>
			)}

			{props.statusTransferencia && (
				<FiltroStatusTransferencia
					{...props.statusTransferencia}
					onChange={value => setFiltroData('statusTransferencia', value)}
				/>
			)}

			{props.tipoTransferencia && (
				<FiltroTipoTransferencia
					{...props.tipoTransferencia}
					onChange={value => setFiltroData('tipoTransferencia', value)}
				/>
			)}

			{props.statusPreCadastro && (
				<FiltroStatusPreCadastro
					{...props.statusPreCadastro}
					onChange={value => setFiltroData('statusPreCadastro', value)}
				/>
			)}

			{props.statusVenda && (
				<div className={clsx(classes.fChild, classes.statusVenda)}>
					<div
						className={clsx({
							[classes.filtroText]: true,
							[classes.filtroTextSelectedMultiple]: Array.isArray(statusVenda)
								? statusVenda.length > 0
								: statusVenda,
						})}
					>
						<Select
							multiple={props.statusVenda.multiple || false}
							className={classes.filtroSelect}
							value={statusVenda}
							onChange={statusVendaChange}
							onClose={statusVendaClose}
							displayEmpty
							renderValue={selected => {
								if (selected.length === 0) {
									return <em>Status da Venda</em>;
								}

								let aux = statusVendaData.filter(a => selected.includes(a.id)).map(a => a.titulo);
								let hasMore = 0;
								let hasMoreText = '';
								if (aux.length > 3) {
									hasMore = aux.length - 3;
									hasMoreText = aux.slice(3).join(', ');
									aux = aux.slice(0, 3);
								}
								return (
									<div className={classes.chips}>
										{aux.map((value, i) => (
											<Chip key={`${value}${i}`} label={value} className={classes.chip} />
										))}
										{hasMore > 0 && (
											<Tooltip title={hasMoreText}>
												<Chip
													label={`+${hasMore}`}
													className={clsx(classes.chip, classes.hasMore)}
												/>
											</Tooltip>
										)}
									</div>
								);
							}}
						>
							<MenuItem disabled value="">
								<em>Status da Venda</em>
							</MenuItem>
							{statusVendaData.map((status, i) => (
								<MenuItem key={i} value={status.id} className={classes.menuItem}>
									<div className={classes.statusVendaTitulo}>{status.titulo}</div>
								</MenuItem>
							))}
						</Select>
						{statusVenda && <FaTimes className={classes.clearBtn} onClick={statusVendaClear} />}
					</div>
				</div>
			)}

			{props.select && props.select.length && (
				<>
					{props.select.map((value, key) => {
						return (
							<div
								className={clsx({
									[classes.filtroText]: true,
								})}
								key={key}
							>
								<Select
									multiple={true}
									className={classes.filtroSelect}
									value={value.value}
									onChange={e => {
										handleOnChangeStatus(value.id, e);
									}}
									displayEmpty
									renderValue={selected => {
										if (selected.length === 0) {
											return <em>{value.label}</em>;
										}
										return (
											<div className={classes.chips}>
												{value.value.map((selectValue, i) => {
													var data = {};

													value.options.filter(item => {
														if (item.id === selectValue) {
															data = { id: item.id, name: item.name };
														}
														return true;
													});

													if (data.id) {
														return (
															<Chip
																key={`${data.id}`}
																label={data.name}
																className={classes.chip}
															/>
														);
													} else {
														return '';
													}
												})}
											</div>
										);
									}}
								>
									<MenuItem disabled value="">
										<em>{value.label}</em>
									</MenuItem>
									{value.options.map((value, i) => (
										<MenuItem key={i} value={value.id} className={classes.menuItem}>
											<div>{value.name}</div>
											{/* <div className={classes.estabelvalueimentoDocumento}>{value.documento.length <= 11 ? Formatter.Cpf(value.documento) : Formatter.Cnpj(value.documento)}</div> */}
										</MenuItem>
									))}
								</Select>
								{value.value.length > 0 && (
									<FaTimes
										className={classes.clearBtn}
										onClick={() => {
											if (props.onSelectChange) {
												props.onSelectChange(value.id, { target: { value: [] } });
											}
										}}
									/>
								)}
							</div>
						);
					})}
				</>
			)}

			{props.searchEstabelecimentoByCpfOrCNPJ && (
				<div className={clsx(classes.fChild, classes.omni)}>
					<div
						className={clsx(classes.filtroText, {
							[classes.filtroOmniHasText]: searchEstabelecimentoByCpfOrCNPJ,
						})}
					>
						<TextField
							// id="standard-search"
							// label={props.omni.label || 'Busca'}
							placeholder={props.searchEstabelecimentoByCpfOrCNPJ.placeholder || 'Pesquisa por CPF/CNPJ'}
							type="text"
							className={classes.omniField}
							value={
								searchEstabelecimentoByCpfOrCNPJ.length <= 11
									? Mask.Cpf(searchEstabelecimentoByCpfOrCNPJ)
									: Mask.Cnpj(searchEstabelecimentoByCpfOrCNPJ)
							}
							onChange={e => {
								const value = e.target.value;
								setSearchEstabelecimentoByCpfOrCNPJ(Mask.OnlyNumber(value));

								props.onChange(
									getFiltrosData({
										documento: Mask.OnlyNumber(value),
									})
								);
							}}
							margin="normal"
							style={{ margin: 0, height: 21, padding: 0 }}
						/>
						{searchEstabelecimentoByCpfOrCNPJ && (
							<FaTimes
								className={classes.clearBtn}
								onClick={() => {
									setSearchEstabelecimentoByCpfOrCNPJ('');
									props.onChange(
										getFiltrosData({
											documento: '',
										})
									);
								}}
							/>
						)}
					</div>
				</div>
			)}

			{props.nomeComprovante && (
				<div className={clsx(classes.fChild, classes.omni)}>
					<div className={clsx(classes.filtroText, { [classes.filtroOmniHasText]: nomeComprovante })}>
						<TextField
							// id="standard-search"
							// label={props.omni.label || 'Busca'}
							placeholder={props.nomeComprovante.placeholder || 'Nome do comprovante'}
							type="text"
							className={classes.omniField}
							value={nomeComprovante}
							onChange={e => {
								setNomeComprovante(e.target.value);
								props.onChange(
									getFiltrosData({
										nomeComprovante: e.target.value,
									})
								);
							}}
							margin="normal"
							style={{ margin: 0, height: 21, padding: 0 }}
						/>
						{nomeComprovante && (
							<FaTimes
								className={classes.clearBtn}
								onClick={() => {
									setNomeComprovante('');
									props.onChange(
										getFiltrosData({
											nomeComprovante: '',
										})
									);
								}}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default ZFiltros;
