/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setColor, setLogo } from '../../store/system';
import axios from 'axios';
import { addMessage } from '../../store/alert';
import { Grid, Paper, Grow, Tabs, Tab, Typography } from '@material-ui/core';
import ColorPicker from 'material-ui-color-picker';
import config from 'react-global-configuration';
import { Check } from '@material-ui/icons';
import ZText from '../system/ZText';
import useReactRouter from 'use-react-router';
import queryString from 'query-string';
import { useStyles } from './styles/ConfiguracoesStyle';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { Isset, getGrupoPermission } from '../../utils';

import ContaBancaria from './ContaBancaria';
import Dados from './Dados';
// import { POS } from '../POS';
import Parametros from './Parametros';
import Taxas from './Taxas';
import PlanosDeVendas from '../PlanosDeVendas';
import PlanosTaxas from './PlanosTaxas';
import SMTP from './SMTP';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateType);

const tabsPermissions = {
	layout: ['admin', 'gerencial', 'moderador'],
	estabelecimento: ['admin', 'gerencial', 'moderador'],
	contaBancaria: ['admin', 'gerencial', 'moderador'],
	conta: ['admin', 'gerencial', 'moderador'],
	parametros: ['admin', 'gerencial', 'moderador'],
	pos: ['admin', 'gerencial', 'moderador'],
	taxas: ['admin', 'gerencial', 'moderador'],
	planoVenda: ['admin', 'gerencial', 'moderador'],
};

const EstabelecimentosConfiguracoes = props => {
	const dispatch = useDispatch();
	const { location } = useReactRouter();
	const color = useSelector(state => state.system.color);
	const url_webhook = useSelector(state => state.system.url_webhook);
	const token = useSelector(state => state.auth.token);
	const usuario = useSelector(state => state.auth.usuario);
	const estabelecimento = useSelector(state => state.auth.estabelecimento);
	const estabelecimentoAdmin = useSelector(state => state.admin.estabelecimento);
	const operarMarketplace = useSelector(store =>
		Isset(() => store.system.params['4'].valor) ? store.system.params['4'].valor : null
	);
	const viewLayout = useSelector(store =>
		Isset(() => store.system.params['14'].valor) ? store.system.params['14'].valor : null
	);
	const viewPlanosVendas = useSelector(store =>
		Isset(() => store.system.params['17'].valor) ? store.system.params['17'].valor : null
	);

	const estabelecimentoId = Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : estabelecimento.id;

	const query = queryString.parse(location.search);

	const [permissions, setPermissions] = useState({});

	const [logoData, setLogoData] = useState([]);
	const [logoBoletoData, setLogoBoletoData] = useState([]);
	const [urlWebhook, setUrlWebhook] = useState({
		value: url_webhook || '',
		errors: false,
	});

	const [senha, setSenha] = useState({
		current: '',
		new: '',
		confirm: '',
		errors: false,
	});

	const [slugUpdated, setSlugUpdated] = useState(null);
	const [tabSelected, setTabSelected] = useState(viewLayout ? 0 : 1);

	const canInsertLogo = useSelector(store =>
		Isset(() => store.system.params['18'].valor) ? store.system.params['18'].valor : null
	);

	const classes = useStyles();

	const handleAtualizarClick = async (slug, value) => {
		const commonSlugs = ['primary_color'];
		if (commonSlugs.includes(slug)) {
			axios
				.post(
					`${config.get(
						'apiUrl'
					)}estabelecimentos/configuracoes/${estabelecimentoId}/${slug.toLowerCase()}/${encodeURIComponent(
						value
					)}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(_ => {
					setSlugUpdated(slug);
					setTimeout(_ => {
						setSlugUpdated(null);
					}, 2000);
				});
		} else {
			switch (slug) {
				case 'logo':
				case 'logo_boleto':
					const formData = new FormData();

					if (!Array.isArray(value)) {
						value = [value];
					}

					value.forEach(a => {
						if (!a.type.match('image.*')) return;

						formData.append('logo', a, a.name);
					});

					const result = await axios.post(
						`${config.get(
							'apiUrl'
						)}estabelecimentos/configuracoes/${estabelecimentoId}/${slug.toLowerCase()}/_`,
						formData,
						{
							headers: {
								Authorization: `Bearer ${token}`,
								'Access-Control-Allow-Origin': '*',
							},
						}
					);

					if (result.data.success) {
						setSlugUpdated(slug);
						setTimeout(result => {
							setSlugUpdated(null);
						}, 3000);
					}

					if (result.data.success && Isset(() => result.data.url) && slug === 'logo') {
						dispatch(setLogo(result.data.url));
					}
					break;
				case 'password':
					if (senha.new === '' || senha.current === '' || senha.confirm === '') {
						setSenha({ ...senha, errors: ['Preencha todos os campos'] });
						return false;
					}

					if (senha.new.length < 6) {
						setSenha({ ...senha, errors: ['A senha precisa ter no mínimo 6 digitos'] });
						return false;
					}

					if (senha.new !== senha.confirm) {
						setSenha({ ...senha, errors: ['As senhas não coincidem'] });
						return false;
					}

					let res = await axios.put(`${config.get('apiUrl')}usuarios/alterar_senha/`, senha, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					});

					let type = 'success';
					if (res.data.success !== true) {
						type = 'error';
					}

					if (res.data.success) {
						setSenha({
							errors: false,
							current: '',
							new: '',
							confirm: '',
						});
					}

					dispatch(addMessage({ type: type, message: res.data.message }));
					break;
				case 'url_webhook':
					let regex = /((?:https|http|ftp)?:\/\/)?([^/,\s]+\.[^/,\s]+?)(?=\/|,|\s|$|\?|#)(.*)/g;
					if (regex.exec(urlWebhook.value)) {
						setUrlWebhook({ ...urlWebhook, errors: false });

						const result = await axios.post(
							`${config.get('apiUrl')}estabelecimentos/configuracoes/`,
							{
								slug: slug.toLowerCase(),
								value: urlWebhook.value,
							},
							{
								headers: {
									Authorization: `Bearer ${token}`,
									'Access-Control-Allow-Origin': '*',
								},
							}
						);

						let type = 'success';
						if (result.data.success !== true) {
							type = 'error';
						}
						dispatch(addMessage({ type: type, message: result.data.message }));
					} else {
						setUrlWebhook({ ...urlWebhook, errors: ['URL inválida.'] });
					}
					break;
				default:
				// console.log(`${slug} não encontrado.`);
			}
		}
	};

	useEffect(() => {
		if (Isset(() => estabelecimentoAdmin.id)) {
			if (Isset(() => estabelecimentoAdmin.arquivo.url)) {
				setLogoData(estabelecimentoAdmin.arquivo.url);
			} else {
				setLogoData([]);
			}
		} else if (Isset(() => estabelecimento.logo.url)) {
			setLogoData(estabelecimento.logo.url);
		} else {
			setLogoData([]);
		}

		if (Isset(() => estabelecimento.logoBoleto.url)) {
			setLogoBoletoData(estabelecimento.logoBoleto.url);
		} else {
			setLogoBoletoData([]);
		}

		if (Isset(() => query.t)) {
			switch (query.t) {
				case 'layout':
					setTabSelected(0);
					break;
				case 'contaBancaria':
					setTabSelected(1);
					break;
				case 'dados':
					setTabSelected(2);
					break;
				case 'conta':
					setTabSelected(3);
					break;
				case 'pos':
					setTabSelected(4);
					break;
				case 'taxas':
					setTabSelected(5);
					break;
				default:
			}
		}
	}, [estabelecimentoAdmin]);

	useEffect(() => {
		const usuarioPermission = getGrupoPermission(usuario.grupo_id);

		const auxPermissions = Object.keys(tabsPermissions).map(key => {
			const permission = tabsPermissions[key];
			return [key, permission.filter(value => usuarioPermission.includes(value)).length > 0];
		});

		let perm = {};
		auxPermissions.forEach(a => {
			perm[a[0]] = a[1];
		});

		// if (!viewLayout) {
		// 	perm.layout = false;
		// }

		// if (!viewPlanosVendas) {
		// 	perm.planoVenda = false;
		// }
		// console.log(perm);

		perm.planoTaxa = operarMarketplace;
		perm.smtp = [1, 2].includes(usuario.grupo_id) && operarMarketplace;

		const onePay = usuario.estabelecimento.id !== 782;
		if (onePay) {
			perm = {
				contaBancaria: true,
				pos: true,
				taxas: true,
				estabelecimento: true,
			};

			if (operarMarketplace) {
				perm.layout = true;
			}

			setTabSelected(1);
		}

		if (!Isset(() => query.t) && !onePay) {
			setTabSelected(Object.values(perm).findIndex(b => b));
		}
		perm.conta = [1, 2].includes(usuario.grupo_id);

		setPermissions(perm);
	}, [usuario, operarMarketplace]);

	// console.log('Logo: ', logoData);
	// console.log('Boleto: ', logoBoletoData);

	const handleChange = (e, value) => setTabSelected(value);
	return (
		<div className={classes.configuracoes}>
			<div className={classes.header}>Configurações</div>

			<Tabs
				variant="scrollable"
				scrollButtons="auto"
				centered
				value={tabSelected}
				onChange={handleChange}
				indicatorColor="primary"
			>
				{permissions.layout && (viewLayout || operarMarketplace) && <Tab label="Layout" value={0} />}
				{permissions.estabelecimento && <Tab label="Dados" value={1} />}
				{permissions.contaBancaria && <Tab label="Conta Bancária" value={2} />}
				{permissions.conta && <Tab label="WebHooks" value={3} />}
				{permissions.parametros && <Tab label="Parâmetros" value={4} />}
				{permissions.taxas && <Tab label="Taxas" value={5} />}
				{permissions.planoVenda && viewPlanosVendas && <Tab label="Plano de Venda" value={6} />}
				{permissions.planoTaxa && <Tab label="Tabela de Markup" value={7} />}
				{permissions.smtp && <Tab label="SMTP" value={8} />}
			</Tabs>
			<div className={classes.tabPanels}>
				{permissions.layout && (viewLayout || operarMarketplace) && tabSelected === 0 && (
					<Typography component="div" role="tabpanel">
						<Grid container spacing={2}>
							{(canInsertLogo || operarMarketplace) && (
								<>
									<Grid item xs={12} sm={6} md={3} lg={3}>
										<Paper>
											<div className={classes.configBox}>
												<div className={classes.updateCheck}>
													<Grow in={slugUpdated === 'logo'}>
														<Check className={classes.icon} />
													</Grow>
												</div>

												<div className={classes.configHeader}>Logo</div>
												<div>
													<FilePond
														server={{
															fetch: (a, b, c) => {
																fetch(a, {
																	// headers: [
																	// ['Origin', 'https://developer.mozilla.org'],
																	// 'Allow-Origin': '*',
																	// Origin: '/'
																	// ],
																	method: 'get',
																	cache: 'no-cache',
																	headers: {
																		Origin: 'http://localhost:3001',
																	},
																	mode: 'cors',
																})
																	.then(res => res.blob())
																	.then(b);
															},
															process: null,
														}}
														acceptedFileTypes="image/*"
														labelIdle='Arraste e solte seus arquivos aqui ou <span class="filepond--label-action">Selecione</span>'
														files={logoData}
														onupdatefiles={fileItems => {
															setLogoData(fileItems.map(f => f.file));
														}}
													/>
												</div>
											</div>
											{/* {logoData.length > 0 && ( */}
											<div
												className={classes.configUpdateBtn}
												onClick={() => handleAtualizarClick('logo', logoData)}
											>
												Atualizar
											</div>
											{/* )} */}
										</Paper>
									</Grid>
									<Grid item xs={12} sm={6} md={3} lg={3}>
										<Paper>
											<div className={classes.configBox}>
												<div className={classes.updateCheck}>
													<Grow in={slugUpdated === 'logo_boleto'}>
														<Check className={classes.icon} />
													</Grow>
												</div>

												<div className={classes.configHeader}>Logo Boleto</div>
												<div>
													<FilePond
														server={{
															fetch: (a, b, c) => {
																fetch(a, {
																	headers: { Origin: 'https://zspay.com.br' },
																})
																	.then(res => res.blob())
																	.then(b);
															},
															process: null,
														}}
														acceptedFileTypes="image/*"
														labelIdle='Arraste e solte seus arquivos aqui ou <span class="filepond--label-action">Selecione</span>'
														files={logoBoletoData}
														onupdatefiles={fileItems => {
															if (fileItems.map(f => f.file instanceof File)[0]) {
																setLogoBoletoData(fileItems.map(f => f.file));
															}
														}}
													/>
												</div>
											</div>
											{/* {logoBoletoData.length > 0 && ( */}
											<div
												className={classes.configUpdateBtn}
												onClick={() => handleAtualizarClick('logo_boleto', logoBoletoData)}
											>
												Atualizar
											</div>
											{/* )} */}
										</Paper>
									</Grid>
								</>
							)}

							<Grid item xs={12} sm={6} md={3} lg={3}>
								<Paper>
									<div className={classes.configBox}>
										<div className={classes.updateCheck}>
											<Grow in={slugUpdated === 'primary_color'}>
												<Check className={classes.icon} />
											</Grow>
										</div>

										<div className={classes.configHeader}>Cor</div>
										<div className={classes.colorPicker} style={{ backgroundColor: color }}>
											<ColorPicker
												defaultValue={color}
												onChange={color => (color ? dispatch(setColor(color)) : null)}
											/>
										</div>
									</div>
									<div
										className={classes.configUpdateBtn}
										onClick={() => handleAtualizarClick('primary_color', color)}
									>
										Atualizar
									</div>
								</Paper>
							</Grid>
						</Grid>
					</Typography>
				)}

				{permissions.estabelecimento && tabSelected === 1 && (
					<Typography component="div" role="tabpanel">
						<Dados estabelecimentoId={estabelecimentoId} />
					</Typography>
				)}

				{permissions.contaBancaria && tabSelected === 2 && (
					<Typography component="div" role="tabpanel">
						<ContaBancaria estabelecimentoId={estabelecimentoId} />
					</Typography>
				)}

				{permissions.conta && tabSelected === 3 && (
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Typography component="div" role="tabpanel">
								<Grid item xs={12}>
									<Paper>
										<div className={classes.configBox}>
											<div className={classes.updateCheck}>
												<Grow in={slugUpdated === 'primary_color'}>
													<Check className={classes.icon} />
												</Grow>
											</div>

											<div className={classes.configHeader}>URL de retorno (Webhook - API)</div>
											<Grid
												container
												direction="row"
												justifyContent="center"
												alignItems="center"
												style={{ minHeight: 215 }}
											>
												<Grid item xs={12}>
													<ZText
														label="URL para receber o webhook"
														onBlur={e =>
															setUrlWebhook({ ...urlWebhook, value: e.target.value })
														}
														placeholder="https://meusite.com.br/webhook"
														value={urlWebhook.value}
													/>
													{urlWebhook.errors.length && (
														<Grid item align="center" xs={12}>
															<small style={{ color: 'red' }}>
																{' '}
																{urlWebhook.errors[0]}
															</small>
														</Grid>
													)}
												</Grid>
											</Grid>
										</div>
										<div
											className={classes.configUpdateBtn}
											onClick={() => handleAtualizarClick('url_webhook', urlWebhook.value)}
										>
											Salvar
										</div>
									</Paper>
								</Grid>
							</Typography>
						</Grid>
					</Grid>
				)}

				{permissions.parametros && tabSelected === 4 && <Parametros estabelecimentoId={estabelecimentoId} />}

				{/* {permissions.pos && tabSelected === 4 && <POS estabelecimentoId={estabelecimentoId} />} */}

				{permissions.taxas && tabSelected === 5 && <Taxas estabelecimentoId={estabelecimentoId} />}

				{permissions.planoVenda && viewPlanosVendas && tabSelected === 6 && (
					<PlanosDeVendas estabelecimentoId={estabelecimentoId} />
				)}

				{permissions.planoTaxa && tabSelected === 7 && <PlanosTaxas estabelecimentoId={estabelecimentoId} />}

				{permissions.smtp && tabSelected === 8 && <SMTP />}
			</div>
		</div>
	);
};

export default EstabelecimentosConfiguracoes;
