import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	estabelecimentoInfo: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	title: {
		fontSize: 18,
		fontWeight: 'bold',
	},
	message: {
		fontSize: 14,
		fontWeight: 'bold',
	},
	paper: {
		marginTop: 10,
		padding: 10,
		paddingRight: 20,
	},
	table: {
		textAlign: 'center',
		maxHeight: 300,
		overflowY: 'scroll',
		boxShadow: '2px 2px 6px 0px #ccc',
		position: 'relative',
		marginTop: 5,
		padding: 2,
	},
	tableHeader: {
		position: 'sticky',
		top: 0,
		left: 0,
		width: '100%',
		margin: 0,
		fontWeight: 'bold',
		fontSize: 15,
		background: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		borderRadius: 10,
		textAlign: 'center',
		'& .MuiGrid-item': {
			padding: 2,
		},
	},
	tableRow: {
		width: '100%',
		margin: 1,
		borderRadius: 10,
		cursor: 'pointer',
		textAlign: 'center',
		'& .MuiGrid-item': {
			padding: 2,
		},
		'&:hover': {
			background: `${theme.palette.primary.main}33`,
			// color: theme.palette.getContrastText(`${theme.palette.primary.main}aa`),
		},
	},
	isSelected: {
		background: `${theme.palette.primary.main}66`,
	},
	antecipacaoOptions: {
		textAlign: 'center',
	},
	antecipacaoOption: {
		margin: '10px 30px',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.palette.primary.main,
		padding: 10,
		borderRadius: 20,
		cursor: 'pointer',
	},
	antecipacaoOptionSelected: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 100,
	},
	check: {
		fontSize: 14,
		color: 'green',
	},
}));
