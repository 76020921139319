import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import ListIcon from '@material-ui/icons/List';
import { makeStyles } from '@material-ui/core/styles';
import { Isset } from '../../utils';

const useStyles = makeStyles(theme => ({
	button: {
		cursor: 'pointer',
	},
}));

function closeMenu(popupState, onClick) {
	popupState.close();
	onClick();
}

function ZMenuOptions(props) {
	const classes = useStyles();
	const options = props.data.filter(a => !Isset(() => a.visible) || a.visible);

	if (!options.length) {
		return null;
	}
	return (
		<PopupState variant="popover" popupId="demo-popup-menu">
			{popupState => (
				<React.Fragment>
					<ListIcon className={classes.button} {...bindTrigger(popupState)} />
					<Menu {...bindMenu(popupState)}>
						{options.map(function(data, key) {
							if (Isset(() => data.visible) && !data.visible) {
								return null;
							}
							return (
								<MenuItem
									key={key}
									onClick={() => {
										closeMenu(popupState, data.onClick);
									}}
								>
									{data.title}
								</MenuItem>
							);
						})}
					</Menu>
				</React.Fragment>
			)}
		</PopupState>
	);
}

export default ZMenuOptions;
