import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	subheader: {
		fontWeight: 700,
		fontSize: 17,
		margin: 4,
	},
	field: {
		padding: 5,
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	valor: {
		fontSize: 18,
		fontWeight: 700,
	},
	paper: {
		padding: 10,
		height: '100%',
	},
	pedidoRow: {
		borderBottom: 'solid 1px #abc',
	},
	notFound: {
		textAlign: 'center',
		fontSize: 20,
		fontWeight: 'bold',
		fontVariant: 'small-caps',
	},
	backButton: {
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		marginRight: 10,
	},
	cancelButton: {
		backgroundColor: '#e53935',
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
	refundButton: {
		backgroundColor: '#F24F1B',
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
	exemptButton: {
		backgroundColor: '#2572E6',
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
}));
