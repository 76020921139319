import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core/';

export default function ZModal(props) {
	const [open, setOpen] = React.useState(props.open || false);
	const [disabled, setDisabled] = React.useState(false);

	// function handleChange( event ){
	//   props.handleOnChange(event);
	// }

	function handleClose() {
		props.handleClose(false);
		setOpen(false);
	}

	React.useEffect(() => {
		setOpen(props.open);
		setDisabled(false);
	}, [props]);

	return (
		<div>
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{props.description}</DialogContentText>
					{props.content}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancelar
					</Button>
					<Button
						onClick={() => {
							props.handleOnSave();
							setDisabled(true);
						}}
						disabled={disabled}
						color="primary"
					>
						Salvar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
