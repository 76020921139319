import React, { useState, useEffect, memo } from 'react';
import { ZTitle, ZLoader, ZText, ZSelect, ZDatePicker, ZSwitch, ZSaveButton } from '../system';
import CalculadoraValorLiquido from '../Calculadoras/ValorLiquido';
import CalculadoraValorBruto from '../Calculadoras/ValorBruto';
import { Mask, ValidatorForm, Isset } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import config from 'react-global-configuration';
import axios from 'axios';
import clsx from 'clsx';
import useReactRouter from 'use-react-router';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Paper,
	Slide,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { format } from 'date-fns';

const useStyles = makeStyles(theme => ({
	layout: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 900,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginBottom: theme.spacing(6),
			padding: theme.spacing(4),
		},
	},
	menuIcon: {
		fontSize: 24,
		cursor: 'pointer',
		marginRight: 4,
	},
	estabelecimento: {
		padding: '6px 6px',
		marginBottom: 10,
	},
	estabelecimentoValor: {
		'&.porcentagem input': {
			textAlign: 'right',
		},
	},
	estabelecimentoIcone: {
		border: 'solid 1px #abc',
		marginRight: 2,
		marginLeft: 2,
		marginTop: 16,
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}`,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	estabelecimentoError: {
		fontSize: 12,
		color: '#e53935',
	},
	label: {
		textAlign: 'center',
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	removeButton: {
		minHeight: 26,
		height: 26,
		width: 26,
		backgroundColor: '#e53935',
		color: 'white',
	},
	box: {
		textTransform: 'uppercase',
		fontSize: 12,
		position: 'relative',
		padding: 10,
		height: 110,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}55`,
		},
		'& .value': {
			fontWeight: 700,
			fontSize: 13,
		},
	},
	divider: {
		margin: 4,
	},
	span: {
		color: '#777',
		fontSize: 14,
	},
	removerButton: {
		backgroundColor: '#e53935',
		color: 'white',
	},
	statusButton: {
		backgroundColor: '#e53935',
		color: 'white',
		'&.ativar': {
			backgroundColor: 'green',
		},
	},
	divButton: {
		marginLeft: 'auto',
		marginTop: 64,
		[theme.breakpoints.down('xs')]: {
			margin: '64px auto 0',
			width: '100%',
		},
	},
	error: {
		padding: 8,
		borderRadius: 5,
		backgroundColor: '#e53935',
		color: 'white',
		marginBottom: 10,
	},
	subheader: {
		fontWeight: 700,
		fontSize: 18,
		fontVariant: 'small-caps',
		letterSpacing: '0.02em',
		margin: '20px 8px',
		marginTop: -30,
	},
	planosTaxas: {
		position: 'relative',
	},
	tiposPagamentos: {
		position: 'absolute',
		paddingTop: 50,
		paddingBottom: 52,
		border: 'solid 1px #abc',
		borderRadius: 10,
		padding: 10,
		width: 'calc(100% - 30px)',
	},
	planos: {
		position: 'relative',
		padding: 14,
		height: 50,
		fontSize: 14,
		fontWeight: 600,
		'& span': {
			fontWeight: 'bold',
			fontSize: 16,
		},
	},
	taxaBoletoDiv: {
		marginTop: 10,
		position: 'relative',
		padding: 10,
		height: 50,
	},
	tipoPagamento: {
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
		'&:not(:last-child)': {
			borderBottom: 'solid 1px #bcd',
		},
	},
	tipoPagamentoBoleto: {
		float: 'left',
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
	},
	bandeirasWrapper: {
		marginLeft: 120,
		width: 'calc(100% - 160px)',
		overflowX: 'auto',
	},
	bandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffffc0',
		height: 490,
		width: 105,
		marginRight: 10,
		float: 'left',
	},
	novaBandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffff',
		width: 160,
		marginRight: 10,
		float: 'left',
	},
	title: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 16,
		height: 22,
	},
	titleLong: {
		fontSize: 11,
	},
	deleteButton: {
		top: 2,
		right: 2,
		cursor: 'pointer',
		position: 'absolute',
		width: 20,
		height: 20,
		color: '#e53935',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		opacity: 0.5,
		'&:hover': {
			opacity: 1,
		},
	},
	fields: {
		marginTop: 8,
		marginBottom: 15,
		borderTop: 'solid 1px #bcd',
	},
	tipoPagamentoFields: {
		borderBottom: 'solid 1px #bcd',
	},
	taxaField: {
		padding: 7.45,
		'&::after': {
			content: '"%"',
			position: 'absolute',
			right: 4,
			fontSize: 12,
		},
		'& .MuiInputBase-input': {
			textAlign: 'right',
			height: '1em',
			padding: 0,
			paddingRight: 15,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -7,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
	},
	saveButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		marginTop: 5,
	},
	adicionarButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		marginTop: 15,
	},
	taxaFieldBoletoReadOnly: {
		marginLeft: 120,
		height: 30.8,
		fontWeight: 'bold',
		fontSize: 15,
		padding: 4,
	},
	taxaFieldReadOnly: {
		height: 30.8,
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 15,
		padding: 4,
	},
	btnCalculadora: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.primary.light,
		},
		marginRight: 10,
		'& .MuiButton-label': {
			display: 'flex',
			flexDirection: 'column',
		},
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 100,
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ShareDialog = memo(props => {
	return (
		<Dialog
			open={props.open}
			TransitionComponent={Transition}
			keepMounted
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle id="alert-dialog-slide-title">{'Sucesso!'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
					Deseja acessar o endereço da Campanha?
				</DialogContentText>
			</DialogContent>
			<DialogActions style={{ textAlign: 'center' }}>
				<Button onClick={props.handleSuccess} color="primary">
					Sim
				</Button>
				<Button onClick={props.handleClose} color="primary">
					Não
				</Button>
			</DialogActions>
		</Dialog>
	);
});

const SellingCampaignCreate = props => {
	const classes = useStyles();

	const { history } = useReactRouter();
	const dispatch = useDispatch();
	const estabelecimentoId = useSelector(store => store.auth.estabelecimento.id);

	const token = useSelector(store => store.auth.token);

	const loadingToken = false;
	const [loading, setLoading] = useState(false);
	const [onError, setOnError] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [showShareModal, setShowShareModal] = useState(false);
	const [successSellingCampaign, setsuccessSellingCampaign] = useState(null);

	const [estabelecimentoNome, setEstabelecimentoNome] = useState('');
	const [taxas, setTaxas] = useState({});
	const [countBandeiras, setCountBandeiras] = useState(1);
	const [erroTaxa, setErroTaxa] = useState(false);

	const [showCalculadoraValorLiquido, setShowCalculadoraValorLiquido] = useState(false);
	const [showCalculadoraValorBruto, setShowCalculadoraValorBruto] = useState(false);

	const [data, setData] = useState({
		id: null,
		titulo: '',
		descricao: '',
		amount: 0.0,
		parcelamento_ate: 12,
		meio_pagamento: [1, 3, 5],
		credit_card: true,
		bank_slip: true,
		pix: true,
		status: true,
		data_expiracao: moment().add(30, 'days'),
		logo: false,
		token: null,
		nome_fantasia: '',
		pedidos: [],
	});

	const validateFields = {
		titulo: { required: true },
		amount: { required: true, min: 10 },
		parcelamento_ate: { required: true },
	};

	var env = '';
	switch (process.env.REACT_APP_ENV) {
		case 'development':
			env = 'devpay';
			break;
		case 'qas':
			env = 'qaspay';
			break;
		case 'production':
			env = 'pay';
			break;
		default:
			env = 'pay';
			break;
	}

	useEffect(() => {
		const getTaxas = async () => {
			setLoading(true);
			setErroTaxa(false);
			const res = await axios.get(
				`${config.get('apiUrl')}planos_taxas/porEstabelecimento/${estabelecimentoId}/taxaTotal`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				setTaxas(res.data.taxaTotal);
				setCountBandeiras(Object.keys(res.data.taxaTotal).length);
			} else {
				setErroTaxa(true);
			}
			setLoading(false);
		};
		const getEC = async () => {
			const res = await axios.post(
				`${config.get('apiUrl')}graphql`,
				{
					query: `
                query Estabelecimentos {
                    estabelecimento(id: ${estabelecimentoId}) {
                        nomeFantasia
                    }
                }
            `,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (res.status === 200 && Isset(() => res.data.data.estabelecimento)) {
				const { nomeFantasia: nome } = res.data.data.estabelecimento;
				setEstabelecimentoNome(nome);
			}
		};

		getTaxas();
		getEC();
	}, [estabelecimentoId, token]);

	const handleSubmitForm = async () => {
		setLoading(true);
		try {
			ValidatorForm(validateFields, data);
		} catch (erro) {
			dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário' }));

			setLoading(false);
			setOnError(true);
			setActiveStep(0);

			return false;
		}

		let res = '';
		let dataSellingCampaign = data;

		res = await axios.post(config.get('apiUrl') + 'sellingCampaign', dataSellingCampaign, {
			headers: { Authorization: `Bearer ${token}` },
		});

		setLoading(false);

		if (res.data.error) {
			dispatch(addMessage({ type: 'error', message: 'Ocorreu um erro ao salvar' }));
		}

		if (Isset(() => res.data.success) && res.data.success === true) {
			dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
			setsuccessSellingCampaign(res.data.sellingCampaign);
			setShowShareModal(true);
		}
	};

	const handleChange = name => event => {
		var value = event.target.value;

		switch (name) {
			case 'amount':
				value = Mask.Real(value);
				break;
			case 'meio_pagamento':
				value.includes(1) ? (data.bank_slip = true) : (data.bank_slip = false);
				value.includes(3) ? (data.credit_card = true) : (data.credit_card = false);
				value.includes(5) ? (data.pix = true) : (data.pix = false);
				break;
			default:
				break;
		}
		setData({
			...data,
			[name]: value,
		});
	};

	const handleSuccess = () => {
		if (successSellingCampaign) {
			window.location.href = `https://${env}.1pay.com.br/${successSellingCampaign.token}`;
		}
	};

	const parcelas = [];
	for (let i = 1; i <= 12; i++) {
		const text = i > 1 ? ' vezes' : ' vez';
		parcelas.push({ id: i, name: i + text });
	}

	if (loadingToken) {
		return <ZLoader height={200} />;
	}
	const handleNext = () => {
		setActiveStep(prevStep => prevStep + 1);
	};

	const handleBack = () => {
		setActiveStep(prevStep => prevStep - 1);
	};

	const formattedData = () => {
		return {
			date: format(data.data_expiracao, 'DD/MM/YYYY'),
			amount: Mask.Real(data.amount / 100),
			logo: data.logo ? 'Sim' : 'Não',
			credit_card: data.credit_card ? 'Cartão de Crédito' : '',
			bank_slip: data.bank_slip ? 'Boleto Bancário' : '',
			pix: data.pix ? 'Pix' : '',
			payment: data.meio_pagamento === 3 ? 'Cartão de Crédito' : 'Boleto Bancário',
			parcel: data.parcelamento_ate === 1 ? `${data.parcelamento_ate} vez` : `${data.parcelamento_ate} vezes`,
			status: data.status ? 'Ativa' : 'Inativa',
			installments_payer: data.installments_payer ? 'Sim' : 'Não',
		};
	};

	return (
		<Container maxWidth="lg">
			<Grid container>
				<ZTitle title={'Nova Campanha de Vendas'} textAlign={'center'} />

				<Grid item xs={12}>
					<Grid container item>
						<Grid item xs={12} md={12} lg={12}>
							<Paper className={classes.paper}>
								{activeStep === 0 && (
									<Grid container spacing={3}>
										<Grid item xs={12} md={6}>
											<ZText
												label="Titulo"
												onError={onError}
												required={true}
												value={data.titulo}
												onBlur={handleChange('titulo')}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<ZText
												label="Descrição"
												// multiline
												// rows="2"
												value={data.descricao}
												onBlur={handleChange('descricao')}
											/>
										</Grid>

										<Grid item xs={12} sm={6} md={6} style={{ marginTop: 15 }}>
											<ZDatePicker
												// error={errors.dataInicio}
												textField
												label="Data de expiração"
												disabled={data.id ? true : false}
												minDate={new Date()}
												date={data.data_expiracao}
												onChange={date => setData({ ...data, data_expiracao: date })}
											/>
										</Grid>

										<Grid item xs={12} md={6} style={{ marginTop: 15 }}>
											<ZSwitch
												onChange={() => setData({ ...data, status: !data.status })}
												label="Ativar Campanha"
												checked={data.status}
											/>
											<ZSwitch
												onChange={() =>
													setData({ ...data, installments_payer: !data.installments_payer })
												}
												label="Juros por conta do cliente"
												checked={data.installments_payer}
											/>

											<ZSwitch
												onChange={() => setData({ ...data, logo: !data.logo })}
												label="Exibir logo do estabelecimento"
												checked={data.logo}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											<ZSelect
												options={[
													{
														id: 1,
														name: 'Boleto Bancário',
													},
													{
														id: 3,
														name: 'Cartão de Crédito',
													},
													{
														id: 5,
														name: 'Pix',
													},
												]}
												label="Meio de pagamento"
												disabled={data.id ? true : false}
												onError={onError}
												width="100%"
												multiple
												value={data.meio_pagamento}
												onChange={handleChange('meio_pagamento')}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											<ZText
												fullWidth
												label="Valor"
												margin="normal"
												realMask
												disabled={data.id ? true : false}
												onError={onError}
												min={validateFields.amount.min}
												placeholder="Valor a ser pago"
												value={data.amount / 100}
												onBlur={e => {
													setData({ ...data, amount: Math.round(e.target.value * 100) });
												}}
											/>
										</Grid>

										<Grid item xs={12} sm={6} md={4}>
											<ZSelect
												disabled={!data.meio_pagamento.includes(3) || data.id ? true : false}
												options={parcelas}
												label="Máximo de parcelas"
												onError={onError}
												width="100%"
												value={
													data.parcelamento_ate > 1 && !data.meio_pagamento.includes(3)
														? (data.parcelamento_ate = 1)
														: data.parcelamento_ate
												}
												onBlur={handleChange('parcelamento_ate')}
											/>
										</Grid>
										<Tooltip title="Juros por conta do Estabelecimento">
											<Button
												className={classes.btnCalculadora}
												onClick={() => setShowCalculadoraValorLiquido(true)}
											>
												<div>
													<small>Calculadora</small>
												</div>
												<div>Valor Líquido</div>
											</Button>
										</Tooltip>
										<Tooltip title="Juros por conta do Cliente">
											<Button
												className={classes.btnCalculadora}
												onClick={() => setShowCalculadoraValorBruto(true)}
											>
												<div>
													<small>Calculadora</small>
												</div>
												<div>Valor Bruto</div>
											</Button>
										</Tooltip>
										{erroTaxa && (
											<div>
												<div className={classes.header}>Taxas - {estabelecimentoNome}</div>
												<div className={classes.empty}>Nenhuma Taxa Encontrada.</div>
											</div>
										)}
										{loading ? (
											<ZLoader height={300} />
										) : (
											<div className={classes.bandeirasWrapper}>
												<div style={{ width: (countBandeiras + 1) * 110 }}>
													{Object.keys(taxas).map(bandeiraId => (
														<div
															key={`Bandeira-${bandeiraId}`}
															className={classes.bandeira}
														>
															<div
																className={clsx(classes.title, {
																	[classes.titleLong]:
																		taxas[bandeiraId].titulo.length > 10,
																})}
															>
																{taxas[bandeiraId].titulo}
															</div>
															<div className={classes.fields}>
																{Object.keys(taxas[bandeiraId].valores).map(
																	tipoPagamentoId => (
																		<div
																			key={`${bandeiraId}${tipoPagamentoId}`}
																			className={classes.tipoPagamentoFields}
																		>
																			{Object.keys(
																				taxas[bandeiraId].valores[
																					tipoPagamentoId
																				].valores
																			).map(parcela => (
																				<div
																					key={`${bandeiraId}-${parcela}`}
																					className={
																						classes.taxaFieldReadOnly
																					}
																				>
																					{Mask.Porcentagem(
																						taxas[bandeiraId].valores[
																							tipoPagamentoId
																						].valores[parcela]
																					)}{' '}
																					%
																				</div>
																			))}
																		</div>
																	)
																)}
															</div>
														</div>
													))}
												</div>
											</div>
										)}

										{showCalculadoraValorLiquido && (
											<CalculadoraValorLiquido
												open={showCalculadoraValorLiquido}
												estabelecimentoId={estabelecimentoId}
												onClose={() => setShowCalculadoraValorLiquido(false)}
											/>
										)}

										{showCalculadoraValorBruto && (
											<CalculadoraValorBruto
												open={showCalculadoraValorBruto}
												estabelecimentoId={estabelecimentoId}
												onClose={() => setShowCalculadoraValorBruto(false)}
											/>
										)}
									</Grid>
								)}

								{activeStep === 1 && (
									<Grid container style={{ marginLeft: 32, position: 'relative' }}>
										<Grid item style={{ marginBottom: 16 }}>
											<Typography variant="h5" component="h5" gutterBottom>
												{data.id ? `ID - ${data.id} ` : `Resumo`}
											</Typography>
										</Grid>
										<Grid container spacing={2} item>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Titulo <br /> <span className={classes.span}>{data.titulo}</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Valor <br />{' '}
													<span className={classes.span}>{formattedData.amount}</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Descrição <br />{' '}
													<span className={classes.span}>{data.descricao}</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Meio de pagamento <br />{' '}
													<span className={classes.span}>
														{formattedData.credit_card} {formattedData.bank_slip}{' '}
														{formattedData.pix}
													</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Data de expiração <br />{' '}
													<span className={classes.span}>{formattedData.date}</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Exibir Logo <br />{' '}
													<span className={classes.span}>{formattedData.logo}</span>
												</Typography>
											</Grid>

											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Maximo de parcelas <br />{' '}
													<span className={classes.span}>{formattedData.parcel}</span>
												</Typography>
											</Grid>

											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Campanha Ativa <br />{' '}
													<span className={classes.span}>{formattedData.status}</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Juros por conta do cliente <br />{' '}
													<span className={classes.span}>
														{formattedData.installments_payer}
													</span>
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								)}
								<Grid container item spacing={3} xs={12} md={6} lg={6} className={classes.divButton}>
									<Grid item xs={12} md={6} lg={6}>
										<Button
											disabled={activeStep === 0}
											onClick={handleBack}
											variant="outlined"
											fullWidth
											color="primary"
										>
											Voltar
										</Button>
									</Grid>
									{activeStep < 1 && (
										<Grid item xs={12} md={6}>
											<Button
												onClick={handleNext}
												fullWidth
												color={'primary'}
												variant="contained"
											>
												Continuar
											</Button>
										</Grid>
									)}
									{activeStep === 1 && !data.id && (
										<Grid item xs={12} md={6}>
											<ZSaveButton
												onClick={() => handleSubmitForm()}
												color={'primary'}
												align="right"
												label={'Salvar'}
												required={true}
												loading={loading}
											/>
										</Grid>
									)}
									{activeStep === 1 && data.id && (
										<Grid item xs={12} md={6}>
											<ZSaveButton
												onClick={() =>
													window.open(
														`https://${env}.1pay.com.br/${successSellingCampaign.token}`,
														'_blank'
													)
												}
												color={'primary'}
												align="right"
												label={'Visualizar tela de pagamento?'}
											/>
										</Grid>
									)}
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<ShareDialog
				open={showShareModal}
				handleClose={() => {
					setShowShareModal(false);
					history.push('/sellingCampaign/');
				}}
				handleSuccess={handleSuccess}
			/>
		</Container>
	);
};

export default SellingCampaignCreate;
